import React, { useState, useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getGroup, getConnectedGroup, setOverlay } from '../../../actions'
import './styles.scss'

const ClientGroups = ({ type, id }) => {
  const dispatch = useDispatch()
  const [group, setGroup] = useState()
  useEffect(() => {
    const payload = { data: id, onSuccess: (data) => setGroup(data) }
    if (type === 'group') dispatch(getGroup(payload))
    else if (type === 'connectedGroup') dispatch(getConnectedGroup(payload))
  }, [])

  return (
    <div className="card-client-groups-container">
      <div className="card-client-groups-header row">
        <h3>{group?.name}</h3>
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setOverlay({ isOpen: false }))} />
      </div>
      <div className="card-client-groups-content">
        <div className="border"></div>
        <div className="client-container">
          <p>Клиенти:</p>
        </div>
        {group?.clients?.map(({ _id, fullName }, i) => (
          <div key={`client-${i}`} className="client-container row">
            <NavLink to={`/clients/${_id}`}>
              <div
                className="icon icon-folder-custom"
                onClick={() => dispatch(setOverlay({ isOpen: false }))}
              />
            </NavLink>
            <p>{fullName}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ClientGroups
