import React, { useEffect, useState } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"
import { transformURL, uploadFile } from "utilities"
import { getCommission, startLoading, stopLoading, updateCommissionSimple } from "actions"
import { useDispatch } from "react-redux"
import { useQuery } from "hooks"

const CommissionsUpload = ({ }) => {
  const dispatch = useDispatch()
  const { _id, user } = useQuery()

  const [error, setError] = useState(!_id || !user ? 'Невалиден линк' : '')
  const [commission, setCommission] = useState()
  const [file, setFile] = useState()

  useEffect(() => {
    if (!_id || !user) return
    dispatch(startLoading())
    dispatch(getCommission({ payload: { _id, user }, onSuccess: setCommission, onError: setError }))
  }, [_id, dispatch])


  const handleUpload = async (files) => {
    dispatch(startLoading())
    const { location } = await uploadFile({ file: files[0], createId: true })
    setFile(location)
    dispatch(stopLoading())
  }

  const handleUpdate = () => {
    dispatch(startLoading())
    dispatch(updateCommissionSimple({ path: '/uploadedFile', payload: { _id, uploadedFile: file, uploadedFileDate: new Date(), status: 'uploadedFile' }, onSuccess: setCommission, stopLoading: true }))
  }
  if (error) return <div className="row" style={{ height: '100vh', width: '100vw', justifyContent: 'center', fontWeight: 300, fontSize: 30, textTransform: 'uppercase' }}>{error}</div>

  return <div className="screen-commissions-upload-container col">
    <div className="screen-commissions-upload-header row">
      <img alt="Logo" src={process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : require('../../assets/images/logo.png')} />
    </div>
    <div className="screen-commissions-upload-content row">
      <div className="col col-preview">
        <p>{commission?.uploadedFile ? 'Фактура' : 'Справка'}</p>
        <object data={transformURL(commission?.uploadedFile ?? commission?.xlsxUrl)} type="application/pdf" width="100%" height="100%" />
      </div>
      <div style={{ width: '2vw' }} />
      <div className="col">
        <p>&nbsp;</p>
        <div className={`col col-uploaded-container${commission?.uploadedFile ? ' uploaded' : ''}`}>
          {commission?.uploadedFile
            ? <div className="col col-uploaded-content">
              <div className="icon icon-success" />
              <p className="success">Вашият файл е изпратен</p>
              <div className="row row-fileName">
                <p className="fileName">Име на файл: <span>{commission?.uploadedFile?.split('/').pop().split('_').slice(1).join('_')}</span></p>
              </div>
            </div>
            : file
              ? <div className="col col-uploaded-content">
                <div className="icon icon-upload-orange" />
                <div className="row row-fileName">
                  <p className="fileName">Име на файл: <span>{file.split('/').pop()}</span></p>
                  <div className="icon icon-remove" onClick={() => setFile(null)} />
                </div>
              </div>
              : <Input.DragAndDrop handleDrop={(files) => handleUpload(files)}>
                <div className="col col-uploaded-content">
                  <div className="icon icon-upload" />
                  <div className="row row-button">
                    <p>Постави файл тук</p>
                    <span>ИЛИ</span>
                    <Button.UploadButton
                      text="Прикачи"
                      accept='*'
                      onChange={({ target: { files } }) => handleUpload(files)}
                    />
                  </div>
                </div>
              </Input.DragAndDrop>
          }
        </div>
        {!commission?.uploadedFile && <Button.Raised text="Запази" onClick={handleUpdate} disabled={!file} />}
      </div>
    </div>
  </div >
}

export default CommissionsUpload