import { default as Status } from './Status'
import { default as Comments } from './Comments'
import { default as Objects } from './Objects'
import { default as Document } from './Document'
import { default as Annex } from './Annex'
import { default as Groups } from './Groups'
import { default as Tasks } from './Tasks'
import { default as EditCell } from './EditCell'
import { default as DocumentsERP } from './DocumentsERP'
import { default as ObjectConfirmed } from './ObjectConfirmed'
import { default as ObjectAdditionalStatus } from './ObjectAdditionalStatus'
import { default as ObjectStatus } from './ObjectStatus'
import { default as ObjectPreviousClient } from './ObjectPreviousClient'
import { default as ObjectLeavingReason } from './ObjectLeavingReason'
import { default as InvoiceGroups } from './InvoiceGroups'
import { default as ExecutionDate } from './ExecutionDate'
import { default as ObjectExecutionDate } from './ObjectExecutionDate'
import { default as EditCommission } from './EditCommission'
import { default as ObjectIssue } from './ObjectIssue'

const Cells = {
  Status,
  Comments,
  Objects,
  Document,
  Annex,
  Groups,
  Tasks,
  EditCell,
  DocumentsERP,
  ObjectConfirmed,
  ObjectAdditionalStatus,
  ObjectStatus,
  ObjectPreviousClient,
  ObjectLeavingReason,
  InvoiceGroups,
  ExecutionDate,
  ObjectExecutionDate,
  EditCommission,
  ObjectIssue
}

export default Cells
