import React from 'react'
import { Shared } from 'components'
import './styles.scss'
import { transformURL } from 'utilities'

const Resources = ({ }) => {
  return (
    <div className="screen-resources-container">
      <div className="screen-resources-header row">
        <h2>Ресурси</h2>
      </div>
      <div className="screen-resources-content">
        <Shared.Table
          fixedWidth
          columns={[{ label: 'Файл', value: 'file' }]}
          data={[
            {
              file: 'Масово добавяне на обекти - шаблон',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%9C%D0%B0%D1%81%D0%BE%D0%B2%D0%BE_%D0%94%D0%BE%D0%B1%D0%B0%D0%B2%D1%8F%D0%BD%D0%B5.xlsx'
            },
            {
              file: 'Масова редакция фактури - шаблон',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%9C%D0%B0%D1%81%D0%BE%D0%B2%D0%B0+%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%86%D0%B8%D1%8F.xlsx'
            },
            {
              file: 'Номенклатури',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/nomenclatures.xlsx',
            },
            {
              file: 'Лихви',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/Лихви.xlsx',
            },
            {
              file: 'Лихви Плащания',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/%D0%9B%D0%B8%D1%85%D0%B2%D0%B8+%D0%BF%D0%BB%D0%B0%D1%89%D0%B0%D0%BD%D0%B8%D1%8F.xlsx',
            },
            {
              file: 'Депозити',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/Депозити.xlsx',
            },
            {
              file: 'Коментари',
              url: 'https://synergon-energy.s3.eu-central-1.amazonaws.com/resources/Коментари.xlsx',
            },
          ]}
          renderCell={(row, field) => (
            <div className="row" style={{ width: '100%', justifyContent: 'space-between', paddingRight: 30 }}>
              <span>{row[field]}</span>
              <a href={transformURL(row?.url || '')} target="_blank" rel="noopener noreferrer">
                <div className="icon icon-open" />
              </a>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export default Resources
