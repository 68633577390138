import React, { useState } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal, startLoading, stopLoading, updateClient, updateObject } from '../../../actions'
import { Inputs } from '../../'
import { clientsStatus, allRegistrationStatuses } from '../../../config/constants'
import './styles.scss'

const ClientsStatus = ({ object, fetch }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(object.status)

  return (
    <div className="modal-clients-status-container">
      <div className="modal-clients-status-header row">
        <h2>Промяна статус</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-clients-status-content">
        <Inputs.Dropdown
          options={clientsStatus.filter(({ value }) => allRegistrationStatuses.includes(value))}
          value={data}
          onChange={({ value }) => setData(value)}
        />
      </div>
      <div className="modal-clients-status-footer">
        <Button.Raised
          text="Запази"
          disabled={!data}
          onClick={() => {
            dispatch(startLoading())
            dispatch(
              updateObject({
                data: { _id: object._id, status: data },
                onSuccess: () => {
                  fetch({ pageOne: true })
                  dispatch(stopLoading())
                  dispatch(setModal({ isOpen: false }))
                }
              })
            )
          }}
        />
      </div>
    </div>
  )
}

export default ClientsStatus
