// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-collapseable-row-container .collapseable-row-header {
  position: relative;
}
.shared-collapseable-row-container .collapseable-row-header > * {
  flex: 1 1;
}
.shared-collapseable-row-container .collapseable-row-header .icon-arrow-right {
  position: absolute;
  right: 1vw;
  top: 50%;
  height: 20px;
  width: 20px;
  transition: all 0.5s ease-in-out;
  transform: translateY(-50%) rotate(90deg);
}
.shared-collapseable-row-container .collapseable-row-header .icon-arrow-right.active {
  transform: translateY(-50%) rotate(270deg);
}
.shared-collapseable-row-container .collapseable-row-inner-content {
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  height: 0;
}
.shared-collapseable-row-container .collapseable-row-inner-content.active {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/CollapsableRow/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AACI;EACE,SAAA;AACN;AACI;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;EACA,gCAAA;EACA,yCAAA;AACN;AAAM;EACE,0CAAA;AAER;AAEE;EACE,gBAAA;EACA,gCAAA;EACA,SAAA;AAAJ;AACI;EACE,YAAA;AACN","sourcesContent":[".shared-collapseable-row-container {\r\n  .collapseable-row-header {\r\n    position: relative;\r\n    > * {\r\n      flex: 1;\r\n    }\r\n    .icon-arrow-right {\r\n      position: absolute;\r\n      right: 1vw;\r\n      top: 50%;\r\n      height: 20px;\r\n      width: 20px;\r\n      transition: all 0.5s ease-in-out;\r\n      transform: translateY(-50%) rotate(90deg);\r\n      &.active {\r\n        transform: translateY(-50%) rotate(270deg);\r\n      }\r\n    }\r\n  }\r\n  .collapseable-row-inner-content {\r\n    overflow: hidden;\r\n    transition: all 0.5s ease-in-out;\r\n    height: 0;\r\n    &.active {\r\n      height: auto;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
