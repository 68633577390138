import React, { useMemo, useState } from "react"
import { Portal } from 'react-overlays'
import { Button } from '@makedonski/admin-ui'
import moment from "moment"
import { useDispatch } from 'react-redux'
import { setModal, startLoading, exportEasypay } from 'actions'
import { Inputs } from 'components'
import "./styles.scss"


const ExportEasypay = ({ month, type }) => {
    const dispatch = useDispatch()
    const close = () => dispatch(setModal({ isOpen: false }))

    const [period, setPeriod] = useState({
        from: moment(month).startOf('month').toDate(),
        to: moment(month).endOf('month').toDate()
    })
    const { from, to } = useMemo(() => period, [period])

    return <div className="modal-export-easypay-container">
        <div className="modal-export-easypay-header row">
            <h2>Генериране на документи - EasyPay</h2>
            <Button.Icon name="plus" size="26px" className="btn-close" onClick={close} />
        </div>
        <div className="modal-export-easypay-content row">
            <div className="col">
                <span>Период</span>
                <Inputs.DatePicker
                    selected={from || null}
                    startDate={from || null}
                    endDate={to || null}
                    onChange={([from, to]) => setPeriod({
                        from: moment(from).startOf('day').toDate(),
                        to: to ? moment(to).endOf('day').toDate() : undefined
                    })}
                    selectsRange
                    shouldCloseOnSelect={false}
                    popperContainer={({ children }) => (
                        <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                    )}
                />
            </div>
        </div>
        <div className="modal-export-easypay-footer row">
            <Button.Raised text='Откажи' className="cancel" onClick={close} />
            <div style={{ width: 30 }} />
            <Button.Raised text='Генерирай' disabled={!from || !to} onClick={() => {
                dispatch(startLoading())
                dispatch(exportEasypay({
                    payload: { type, createdAtFrom: from, createdAtTo: to },
                    onSuccess: () => close()
                }))
            }} />
        </div>
    </div>
}

export default ExportEasypay