import { Button, Input } from "@makedonski/admin-ui"
import { createCompensationSettings, updateCompensationSettings } from "actions"
import { Inputs } from "components"
import moment from "moment"
import React, { useState } from "react"
import { Portal } from "react-overlays"
import { useDispatch } from "react-redux"
import { mSubtract } from "utilities"
import "./styles.scss"

const CompensationSettings = ({ compensationSettings, onSuccess, close }) => {
    const dispatch = useDispatch()

    const [data, setData] = useState({
        text: 'Компенсация по РМС No 654 от 18.09.2024',
        enabledForMonth: true,
        version: 2,
        ...compensationSettings
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))

    const [showRequired, setShowRequired] = useState(false)
    const isValid = () => {
        if (['text', 'amount', 'forMonth'].some(f => !data?.[f])) return false
        if (!data.text.startsWith('Компенсация')) return false
        if (data?.version === 2 && ['bnebAverage', 'baseAmount'].some(f => !data?.[f])) return false
        if (data?.version === 2 && Number(data?.bnebAverage) < Number(data?.baseAmount)) return false
        if (data?.version === 2 && mSubtract(data?.bnebAverage, data?.baseAmount) !== Number(data?.amount)) return false
        return true
    }

    const handleCreate = () => {
        if (!isValid()) {
            setShowRequired(true)
            return
        } else if (compensationSettings) dispatch(updateCompensationSettings({ payload: data, onSuccess: () => onSuccess(`Успешно редактирана компенсация`) }))
        else dispatch(createCompensationSettings({ payload: data, onSuccess: () => onSuccess(`Успешно добавена компенсация`) }))
    }

    return <div className="modal-compensation-settings-container">
        <div className="modal-compensation-settings-header row">
            <h2>{compensationSettings ? "Редакция" : "Добавяне"} на компенсация</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-compensation-settings-content">
            <div className="row">
                <div className="col col-large">
                    <span>Текст</span>
                    <Input.Text
                        value={data?.text}
                        onChange={({ target: { value } }) => {
                            if (value.startsWith('Компенсация')) handleChange({ text: value })
                            else handleChange({ text: 'Компенсация' })
                        }}
                        inputClassName={`${showRequired && !data.text && 'required'}`}
                    />
                </div>
                <div className="col col-small">
                    <span>Максимална стойност компенсация</span>
                    <Input.Text
                        value={data?.amount || ""}
                        onChange={({ target: { value } }) => {
                            if (!/^\d{0,5}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                            handleChange({ amount: value })
                        }}
                        inputClassName={`${showRequired && (!data.amount || (data?.version === 2 && Number(data?.bnebAverage) - Number(data?.baseAmount) !== Number(data?.amount))) && 'required'}`}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span>Месец</span>
                    <Inputs.DatePicker
                        value={data?.forMonth ? moment(data.forMonth, 'MM/YYYY').toDate() : null}
                        onChange={(value) => handleChange({ forMonth: moment(value).format('MM/YYYY') })}
                        className={`month-picker ${showRequired && !data.forMonth && 'required'}`}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        popperContainer={({ children }) => (
                            <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                        )}
                    />
                </div>
                <div className="col">
                    <span>Включено</span>
                    <Button.Switch
                        isOn={data?.enabledForMonth}
                        onChange={() => handleChange({ enabledForMonth: !data?.enabledForMonth })}
                    />
                </div>
                <div className="col">
                    <span>Нова версия</span>
                    <Button.Switch
                        isOn={data?.version === 2}
                        onChange={() => handleChange({ version: data?.version === 1 ? 2 : 1, bnebAverage: undefined, baseAmount: undefined })}
                    />
                </div>
            </div>
            {data?.version === 2 && <div className="row">
                <div className="col">
                    <span>Таван на цената</span>
                    <Input.Text
                        value={data?.baseAmount || ""}
                        onChange={({ target: { value } }) => {
                            if (!/^\d{0,5}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                            handleChange({ baseAmount: value })
                        }}
                        inputClassName={`${showRequired && (!data.baseAmount || Number(data?.bnebAverage) < Number(data?.baseAmount) || Number(data?.bnebAverage) - Number(data?.baseAmount) !== Number(data?.amount)) && 'required'}`}
                    />
                </div>
                <div className="col">
                    <span>БНЕБ средна цена база</span>
                    <Input.Text
                        value={data?.bnebAverage || ""}
                        onChange={({ target: { value } }) => {
                            if (!/^\d{0,5}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                            handleChange({ bnebAverage: value })
                        }}
                        inputClassName={`${showRequired && (!data.bnebAverage || Number(data?.bnebAverage) < Number(data?.baseAmount) || Number(data?.bnebAverage) - Number(data?.baseAmount) !== Number(data?.amount)) && 'required'}`}
                    />
                </div>
            </div>}
        </div>
        <div className="modal-compensation-settings-footer">
            <Button.Raised text="Запази" onClick={handleCreate} />
        </div>
    </div>
}

export default CompensationSettings