// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-loader-container svg {
  width: 480px;
  height: 240px;
}
.modal-loader-container svg #plug,
.modal-loader-container svg #socket {
  fill: #e87a1e;
}
.modal-loader-container svg #loop-normal {
  fill: none;
  stroke: #e87a1e;
  stroke-width: 12;
}
.modal-loader-container svg #loop-offset {
  display: none;
}
.modal-loader-container .text {
  text-align: center;
}
.modal-loader-container .text h2 {
  color: #e87a1e;
  font-size: 28px;
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/Loader/styles.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,aAAA;AAAR;AAGI;;EAEI,aAAA;AADR;AAII;EACI,UAAA;EACA,eAAA;EACA,gBAAA;AAFR;AAKI;EACI,aAAA;AAHR;AAMI;EACI,kBAAA;AAJR;AAOI;EACI,cAAA;EACA,eAAA;AALR","sourcesContent":[".modal-loader-container {\r\n    svg {\r\n        width: 480px;\r\n        height: 240px;\r\n    }\r\n\r\n    svg #plug,\r\n    svg #socket {\r\n        fill: #e87a1e;\r\n    }\r\n\r\n    svg #loop-normal {\r\n        fill: none;\r\n        stroke: #e87a1e;\r\n        stroke-width: 12;\r\n    }\r\n\r\n    svg #loop-offset {\r\n        display: none;\r\n    }\r\n\r\n    .text {\r\n        text-align: center;\r\n    }\r\n\r\n    .text h2 {\r\n        color: #e87a1e;\r\n        font-size: 28px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
