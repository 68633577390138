import React, { useState, useEffect, forwardRef } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { startLoading, createOffer } from '../../../../actions'
import { Inputs, Client } from '../../../'
import './styles.scss'

const prefixFields = [
  { value: 'и г-н', label: 'и г-н' },
  { value: 'а г-жо', label: 'а г-жо' },
  { value: 'и господа', label: 'и господа' },
  { value: 'а', label: 'а' },
  { value: 'и', label: 'и' },
  { value: 'и Дами и Господа', label: 'и Дами и Господа' },
]
const offerTypesFields = [
  { value: 'Доставка на ел. енергия', label: 'Доставка на ел. енергия' },
  { value: 'Цена по Борса', label: 'Цена по Борса' },
  { value: 'Цена Оферта или Борса', label: 'Цена Оферта или Борса' },
  { value: 'Борса с таван на цената', label: 'Борса с таван на цената' },
  { value: 'Цена Оферта или Борса..', label: 'Цена Оферта или Борса..' },
]

//prettier-ignore
const pricesFields = [
  { types: ['Доставка на ел. енергия', 'Цена Оферта или Борса', 'Цена Оферта или Борса..'], label: 'Цена', value: 'base' },
  { types: ['Цена по Борса', 'Цена Оферта или Борса', 'Борса с таван на цената', 'Цена Оферта или Борса..'], label: 'Цена Борса Надбавка', value: 'marketMargin' },
  { types: ['Борса с таван на цената', 'Цена Оферта или Борса..'], label: 'Таван', value: 'marketCeiling' },
  { types: ['Цена Оферта или Борса..'], label: 'Под', value: 'marketFloor' },
]

//prettier-ignore
const requiredFields = ["clientName", "attentionOf", "term", "offerType", 'paymentDaysType', "paymentDays", "startOfDelivery", "validTill"]

const Offer = forwardRef(({ client, handleSuccess, data: dataProp, editable: editableProp }, ref) => {
  const editable = editableProp === undefined ? true : editableProp
  const initialData = dataProp || {
    paymentDaysType: 'Календарни',
    peopleIn: [{}, {}],
    startOfDelivery: new Date(),
    validTill: moment().add(1, 'years').subtract(1, 'days').toDate(),
    partners: false,
    intermediateInvoicing: false,
  }
  const [data, setData] = useState(initialData)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handlePriceChange = (field, value) => handleChange('metadata', { ...data?.metadata, [field]: value })
  const handlePeopleChange = (field, value, i) => {
    const newPeople = (data?.peopleIn || []).slice()
    newPeople.splice(i, 1, { ...data?.peopleIn?.[i], [field]: value })
    handleChange('peopleIn', newPeople)
  }
  const [showRequired, setShowRequired] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setData(initialData)
  }, [dataProp])

  const isValid = () => {
    if (requiredFields.some((field) => !data?.[field])) return false
    if (
      pricesFields
        .filter(({ types }) => types.includes(data?.offerType))
        .map(({ value }) => value)
        .some((field) => !data?.metadata?.[field])
    )
      return false
    return true
  }
  const isRequired = (field) => showRequired && !data?.[field] && !data?.metadata?.[field] && 'required'

  const onClear = () => {
    setData(initialData)
    setShowRequired(false)
  }
  const onSave = () => {
    if (!isValid()) {
      setShowRequired(true)
      return
    } else {
      dispatch(startLoading())
      dispatch(createOffer({ data: { client, ...data }, onSuccess: (data) => handleSuccess(data) }))
    }
  }

  if (ref) ref.current = { data }

  return (
    <div className="client-forms-offer-container">
      <h2>Оферта</h2>
      <div className="row">
        <div className="col col-double">
          <span>Име на клиент</span>
          <Input.Text
            value={data?.clientName || ''}
            onChange={({ target: { value } }) => handleChange('clientName', value)}
            inputClassName={`${isRequired('clientName')}`}
            disabled={!editable}
          />
        </div>
        <div className="col col-double">
          <span>На вниманието на</span>
          <Input.Text
            value={data?.attentionOf || ''}
            onChange={({ target: { value } }) => handleChange('attentionOf', value)}
            inputClassName={`${isRequired('attentionOf')}`}
            disabled={!editable}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span>Уважаем</span>
          <Inputs.Dropdown
            options={prefixFields}
            onChange={({ value }) => handlePeopleChange('prefix', value, 0)}
            value={data?.peopleIn?.[0]?.prefix}
            disabled={!editable}
          />
        </div>
        <div className="col col-double">
          <span>Имена</span>
          <Input.Text
            value={data?.peopleIn?.[0]?.names || ''}
            onChange={({ target: { value } }) => handlePeopleChange('names', value, 0)}
            disabled={!editable}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span>Уважаем</span>
          <Inputs.Dropdown
            options={prefixFields}
            onChange={({ value }) => handlePeopleChange('prefix', value, 1)}
            value={data?.peopleIn?.[1]?.prefix}
            disabled={!editable}
          />
        </div>
        <div className="col col-double">
          <span>Имена</span>
          <Input.Text
            value={data?.peopleIn?.[1]?.names || ''}
            onChange={({ target: { value } }) => handlePeopleChange('names', value, 1)}
            disabled={!editable}
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <span>Срок</span>
          <Input.Text
            value={data?.term || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('term', value)
            }}
            inputClassName={`${isRequired('term')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Тип оферта</span>
          <Inputs.Dropdown
            options={offerTypesFields}
            onChange={({ value }) => handleChange('offerType', value)}
            value={data?.offerType}
            className={`${isRequired('offerType')}`}
            disabled={!editable}
          />
        </div>
        {pricesFields.map(({ types, value, label }) => {
          if (!data?.offerType || !types?.includes(data?.offerType)) return null
          return (
            <div className="col col-small" key={`price-field-${value}`}>
              <span>{label}</span>
              <Inputs.TextLabeled
                label="лв./МВтч"
                value={data?.metadata?.[value] ? data?.metadata[value] : ''}
                onChange={({ target }) => {
                  if (!/^\d+(\.||\.\d{0,2})?$/.test(target.value) && target.value !== '') return
                  handlePriceChange(value, target.value)
                }}
                inputClassName={`${isRequired(value)}`}
                disabled={!editable}
              />
            </div>
          )
        })}
      </div>

      <div className="row row-expire">
        <div className="col col-double">
          <span>Дни падеж</span>
          <Inputs.RadioButtons
            buttons={['Календарни', 'Работни']}
            value={data?.paymentDaysType}
            onClick={(data) => handleChange('paymentDaysType', data)}
            disabled={!editable}
          />
        </div>

        <div className="col">
          <span></span>
          <Input.Text
            value={data?.paymentDays || ''}
            onChange={({ target: { value } }) => {
              if (!/^\d+$/.test(value) && value !== '') return
              handleChange('paymentDays', value)
            }}
            inputClassName={`${isRequired('paymentDays')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Начало на доставка</span>
          <Inputs.DatePicker
            value={data?.startOfDelivery}
            onChange={(value) => handleChange('startOfDelivery', value)}
            className={`${isRequired('startOfDelivery')}`}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>Точка “Партньори</span>
          <Button.Switch isOn={data?.partners} onChange={() => editable && handleChange('partners', !data?.partners)} />
        </div>

        <div className="col">
          <span>Валидна до</span>
          <Inputs.DatePicker
            value={data?.validTill}
            onChange={(value) => handleChange('validTill', value)}
            className={`${isRequired('validTill')}`}
            disabled={!editable}
          />
        </div>
      </div>
      <div className="row row-buttons">
        <Button.Raised className="btn-cancel" text="Откажи" onClick={onClear} />
        <Button.Raised className="btn-save" text="Запази и продължи" onClick={onSave} />
      </div>
    </div>
  )
})
export default Offer
