import React, { useEffect, useMemo } from "react"
import "./styles.scss"
import { communicationTypes } from "config/constants"
import { useQuery } from "hooks"
import { Inputs, Communication as CommunicationComp } from "components"
import { useSelector } from "react-redux"
import { flatten, uniq } from 'lodash'


const TypeSelector = () => {
    const { roles } = useSelector(({ general }) => general?.currentUser) || {}
    const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({ ...acc, ...Object.fromEntries(Object.entries(permissions).map(([key]) => [key, uniq(flatten(roles.map(({ permissions }) => permissions[key])))])) }), {}), [roles])
    const typesFiltered = useMemo(() => communicationTypes.filter(({ value }) => !permissions || ['all', value].some(p => permissions?.communication?.includes(p))), [permissions])

    const { type, clear, handleUrlChangeMultiple } = useQuery({ type: typesFiltered })
    return <Inputs.Dropdown
        options={typesFiltered}
        value={type}
        onChange={({ value }) => { clear(); handleUrlChangeMultiple({ type: value }) }}
    />
}

const Communication = () => {
    const { type } = useQuery()
    const props = { TypeSelector }

    const renderContent = () => {
        switch (type) {
            case 'period':
                return <CommunicationComp.Period {...props} />
            case 'network':
                return <CommunicationComp.Network {...props} />
            case 'corrections':
                return <CommunicationComp.Corrections {...props} />
            case 'objects':
                return <CommunicationComp.Objects {...props} />
            default:
                return <div>
                    <div className="screen-communication-header row">
                        <TypeSelector />
                    </div>
                    <div className="screen-communication-content row">
                        <p className="empty">Изберете вида данни, които искате да визуализирате</p>
                    </div>
                </div>
        }
    }

    const { roles } = useSelector(({ general }) => general?.currentUser) || {}
    if (!roles) return null
    return <div className="screen-communication-container">
        {renderContent()}
    </div>
}

export default Communication