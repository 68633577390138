import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import './styles.scss'

const Checklist = ({
  checklist = [
    { name: 'task1', completedOn: "a" },
    { name: 'task2', completedOn: "a" },
    { name: 'task3' },
    { name: 'task4' },
    { name: 'task5' },
    { name: 'task6' },
    { name: 'task7' },
    { name: 'task8' },
  ],
}) => {
  const [add, setAdd] = useState(false)
  const [name, setName] = useState('')

  const getCompleter = () =>
    (checklist || []).reduce(
      (acc, cur) => (cur?.completedOn ? acc + 1 : acc),
      0
    )

  return (
    <div className="card-checklist-container">
      <div className="card-checklist-header">
        <div className="row">
          Прогрес ({getCompleter()}/{checklist?.length ?? 0})
          <Button.Icon
            name="plus"
            color="#00CB5E"
            onClick={() => setAdd(true)}
          />
        </div>
        <div className="progress">
          <div
            className="progress-inner"
            style={{
              width: `${(getCompleter() / checklist?.length ?? 0) * 100}%`,
            }}
          />
        </div>
      </div>
      <div className={`card-checklist-content ${add && 'collapse'}`}>
        {checklist?.map((item, i) => (
          <div key={`single-checklist-${i}`} className="single-item row">
            <Inputs.Checkboxes
              buttons={['']}
              value={item?.completedOn}
              onClick={() => {}}
              reversed
            />
            <p>{item?.name}</p>
          </div>
        ))}
      </div>

      {add && (
        <div className="card-checklist-input row">
          <Input.Text
            value={name}
            placeholder="Добави"
            onChange={({ target: { value } }) => setName(value)}
          />
          <Button.Raised
            text="Add"
            onClick={() => {
              setAdd(false)
              setName('')
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Checklist
