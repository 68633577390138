import { default as Employees } from './Employees'
import { default as ReportsTrade } from './ReportsTrade'
import { default as ReportsPortfolio } from './ReportsPortfolio'
import { default as ReportsRegistration } from './ReportsRegistration'
import { default as Market } from './Market'
import { default as Invoices } from './Invoices'
import { default as Imports } from './Imports'
import { default as Nomenclatures } from './Nomenclatures'
import { default as IntermediateInvoices } from './IntermediateInvoices'
import { default as Commissions } from './Commissions'
import { default as CompensationSettings } from './CompensationSettings'
import { default as InvoiceNumber } from './InvoiceNumber'
import { default as ObjectsIgnored } from './ObjectsIgnored'
import { default as ClientsGroups } from './ClientsGroups'
import { default as Permissions } from './Permissions'

const References = {
  Employees,
  ReportsTrade,
  ReportsPortfolio,
  ReportsRegistration,
  Market,
  Invoices,
  Imports,
  Nomenclatures,
  IntermediateInvoices,
  Commissions,
  CompensationSettings,
  InvoiceNumber,
  ObjectsIgnored,
  ClientsGroups,
  Permissions
}

export default References
