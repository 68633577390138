import React, { useState } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { updateObject, setModal } from 'actions'
import { Inputs } from 'components'
import './styles.scss'

const ClientsLeaving = ({ object, fetch, field, options }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState(object?.[field])

  return (
    <div className="modal-clients-leaving-container">
      <div className="modal-clients-leaving-header row">
        <h2>Редакция</h2>
        <Button.Icon name="plus" onClick={() => dispatch(setModal({ isOpen: false }))} />
      </div>
      <div className="modal-clients-leaving-content">
        <Inputs.RadioButtons col buttons={options} value={data} onClick={(value) => setData(value)} />
      </div>
      <div className="row">
        <Button.Raised
          text="Запази"
          onClick={() =>
            dispatch(
              updateObject({
                data: { _id: object?._id, [field]: data },
                onSuccess: () => {
                  fetch({ pageOne: true })
                  dispatch(setModal({ isOpen: false }))
                },
              })
            )
          }
        />
      </div>
    </div>
  )
}

export default ClientsLeaving
