export const userTypes = {
    GET_USER: "users/GET_USER",
    GET_USERS: "users/GET_USERS",
    GET_USER_ROLES: "users/GET_USER_ROLES",
    UPDATE_USER_ROLE: "users/UPDATE_USER_ROLE",

    CREATE_USER: 'users/CREATE_USER',
    UPDATE_USER: 'users/UPDATE_USER',

    GET_USER_FILTER_PRESETS: 'users/GET_USER_FILTER_PRESETS',
    GET_USER_FILTER_PRESETS_SUCCESS: 'users/GET_USER_FILTER_PRESETS_SUCCESS',
    CREATE_USER_FILTER_PRESET: 'users/CREATE_USER_FILTER_PRESET',
    APPLY_USER_FILTER_PRESET: 'users/APPLY_USER_FILTER_PRESET',
    DELETE_USER_FILTER_PRESET: 'users/DELETE_USER_FILTER_PRESET',
}


export const getUser = (payload) => ({
    type: userTypes.GET_USER,
    ...payload
})

export const getUsers = (payload) => ({
    type: userTypes.GET_USERS,
    ...payload
})

export const getUserRoles = (payload) => ({
    type: userTypes.GET_USER_ROLES,
    ...payload
})

export const updateUserRole = (payload) => ({
    type: userTypes.UPDATE_USER_ROLE,
    ...payload
})

export const createUser = (payload) => ({
    type: userTypes.CREATE_USER,
    ...payload
})

export const updateUser = (payload) => ({
    type: userTypes.UPDATE_USER,
    ...payload
})

export const getUserFilterPresets = (payload) => ({
    type: userTypes.GET_USER_FILTER_PRESETS,
    ...payload
})

export const createUserFilterPreset = (payload) => ({
    type: userTypes.CREATE_USER_FILTER_PRESET,
    ...payload
})

export const applyUserFilterPreset = (payload) => ({
    type: userTypes.APPLY_USER_FILTER_PRESET,
    ...payload
})

export const deleteUserFilterPreset = (payload) => ({
    type: userTypes.DELETE_USER_FILTER_PRESET,
    ...payload
})