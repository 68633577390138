import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts, Headers } from '@makedonski/socourt-utilities'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, pick, uniqBy } from 'lodash'
import {
  setModal,
  getData,
  getGroups,
  getConnectedGroups,
  getClientsForDropdown,
  updateClient,
  startLoading,
  updateGroup,
  updateConnectedGroup,
  createConnectedGroup,
  getUsers,
  startApisFetch,
  setDataFields,
} from 'actions'
import { Inputs, Client, Popup as PopupComponents, Shared } from 'components'
import { IBANRegex, defaultContractSettings, defaultContractVersionProducers, defaultProducerSettings, emailRegex, productsPricesFields } from 'config/constants'
import { useQuery } from 'hooks'
import './styles.scss'
import moment from 'moment'
import { checkIsDealer, genericFetch, isValidProducerSettings, loadDropdownOptions } from 'utilities'
import { URL } from 'config/settings'
import Popup from 'reactjs-popup'

const requiredFields = ['type', 'fullName', 'bulstat', 'mol', 'city', 'address', 'phoneNumber', 'email']
const requiredContractFields = ['version', 'paymentDays']
const MainProfile = forwardRef(({ data: dataProp, editable: editableProp, setEditable, restricted }, ref) => {
  const { currentUser } = useSelector(({ general }) => general) || {}
  const isDealer = checkIsDealer()
  const editable = editableProp === undefined ? true : editableProp
  const [editableContacts, setEditableContacts] = useState(false)
  const initialData = useMemo(() => ({
    ...(dataProp || {}),
    ...(restricted && {
      ...(dataProp?.clientType !== 'Консуматор' && { producerSettings: {} }),
      ...(dataProp?.clientType !== 'Производител' && { contractSettings: {} }),
    })
  }), [dataProp, restricted])
  const [data, setData] = useState(initialData)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handlePriceChange = (payload) => handleChange('price', { ...data?.price, ...payload })
  const [showRequired, setShowRequired] = useState(false)
  const { businessTypes, products, groups, connectedGroups, clients, versions } = useSelector(({ data }) => data)
  const { inactive } = useQuery()

  useEffect(() => {
    setData(initialData)
  }, [dataProp])

  const dispatch = useDispatch()
  useEffect(() => {
    !businessTypes && dispatch(getData('businessTypes'))
    !products && dispatch(getData('products'))
    !versions && dispatch(getData('versions'))
    !groups && dispatch(getGroups())
    !connectedGroups && dispatch(getConnectedGroups())
    dispatch(getClientsForDropdown({ search: "", getAllDetails: true }))
  }, [editable])

  const isValid = () => {
    if (requiredFields.some((field) => !data?.[field])) {
      Alerts.error('Моля попълнете всички задължителни полета в основна информация')
      return false
    } else if (!data?.eic && !data?.pin) {
      Alerts.error('Моля попълнете ЕИК или ЕГН')
      return false
    } else if (data?.contacts?.length && data?.contacts?.some(({ email, phoneNumber }) => !email && !phoneNumber)) {
      Alerts.error('Моля попълнете всички задължителни полета в допълнителен контакт')
      return false
    } else if ([data?.email, ...(data?.contacts || [])?.map(({ email }) => email)].filter(Boolean).some((val) => !emailRegex.test(val))) {
      Alerts.error('Моля попълнете валиден имейл')
      return false
    } else if (process.env.REACT_APP_PLATFORM === 'Synergon' && isEmpty(data?.dealers)) {
      Alerts.error('Моля добавете търговец')
      return false
    }

    if (data.clientType !== 'Производител') {
      // const files = data?.contractSettings?.files?.map(({ type }) => type)
      if (
        !data?.product ||
        productsPricesFields
          .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
          .map(({ value }) => value)
          .some((field) => [undefined, null, 0].includes(data?.price?.[field]))
      ) {
        Alerts.error('Моля попълнете продукт и всички цени')
        return false
      } else if (
        requiredContractFields.some((field) => !data?.contractSettings?.[field]) ||
        (!data?.contractSettings?.contractDurationMonths && !data?.contractSettings?.deliveryEnd) ||
        // (data?.existingClient && !data?.contractSettings?.deliveryStart) ||
        (data?.contractSettings?.autoSign && !data?.contractSettings?.autoSignMonths) ||
        (data?.contractSettings?.depositLeaving && !data?.contractSettings?.depositLeavingPercent) ||
        (data?.contractSettings?.penalty && !data?.contractSettings?.penaltyPercent) ||
        (data?.contractSettings?.notice && !data?.contractSettings?.noticeDays) ||
        (data?.contractSettings?.donation && !data?.contractSettings?.donationFond)
      ) {
        Alerts.error('Моля попълнете всички задължителни полета в документи и договор')
        return false
      }
      // else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && ['letter of attorney', 'contract'].some((val) => !files?.includes(val))) {
      //   Alerts.error('Моля добавете пълномощно и договор')
      //   return false
      // } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && data?.contractSettings?.type === 'Комбиниран' && !files?.includes('declaration')) {
      //   Alerts.error('При комбиниран договор е необходимо да прикачите Декларация')
      //   return false
      // }
    }

    if (
      process.env.REACT_APP_PLATFORM === 'Synergon' &&
      data?.clientType !== 'Консуматор' &&
      (!data?.invoiceSettings?.producerBank || !IBANRegex.test(data?.invoiceSettings?.producerIBAN))
    ) {
      Alerts.error('Невалидна Банка/IBAN')
      return false
    }

    if (data?.clientType !== 'Консуматор' && data?.producerSettings && !data?.producerSettings?.disabled) {
      const { isValid, error } = isValidProducerSettings({ data, products, isDealer })
      if (!isValid) {
        Alerts.error(error)
        return false
      }
    }

    return true
  }

  const isRequired = (field) => {
    if (!showRequired) return ''
    if (data?.[field] || data?.price?.[field]) return ''
    if (field === 'businessType' && data?.businessType && !isEmpty(data?.businessType)) return ''
    return 'required'
  }
  const isEmail = (email) => showRequired && !emailRegex.test(email) && 'required'

  const onRequired = (value) => setShowRequired(value)
  const onClear = () => {
    if (data?._id) setEditable(false)
    setData(initialData)
    setShowRequired(false)
  }

  const hasPriceOrContractChanged = useMemo(() => {
    if (!dataProp || !data) return false
    return ['type', 'terms', 'paymentDaysType', 'paymentDays'].some(f => dataProp?.contractSettings?.[f] !== data?.contractSettings?.[f]) ||
      ['price', 'product', 'declaration'].some(f => dataProp?.[f] !== data?.[f])
  }, [dataProp, data])

  const hasProducerPriceOrContractChanged = useMemo(() => {
    if (!dataProp || !data) return false
    return ['product', 'price', 'paymentDays', 'balancingMethod', 'balancingCeiling', 'balancingFloor', 'invoicingMethod'].some(f => dataProp?.producerSettings?.[f] !== data?.producerSettings?.[f]) ||
      (dataProp.clientType !== 'Консуматор' && ['bulstat'].some(f => dataProp?.[f] !== data?.[f]))
  }, [dataProp, data])

  const isClientInactive = useMemo(() => dataProp?.objects?.every(({ status }) => ['inactive'].includes(status)), [dataProp])
  useEffect(() => { if (isClientInactive) handleChange('priceActiveFrom', hasPriceOrContractChanged ? moment().startOf('month').toDate() : undefined) }, [isClientInactive, hasPriceOrContractChanged])
  useEffect(() => { if (isClientInactive) handleChange('producerPriceActiveFrom', hasProducerPriceOrContractChanged ? moment().startOf('month').toDate() : undefined) }, [isClientInactive, hasProducerPriceOrContractChanged])


  const onSave = () => {
    if ((data?.clientType !== 'Консуматор' || inactive) && !isValid()) { setShowRequired(true); return }

    const forConsumers = hasPriceOrContractChanged && !data.priceActiveFrom
    const forProducers = hasProducerPriceOrContractChanged && !data.producerPriceActiveFrom
    if (forConsumers || forProducers) dispatch(setModal({ isOpen: true, type: 'priceActiveFrom', props: { onSaveClient, forConsumers, forProducers } }))
    else onSaveClient()
  }

  const onSaveClient = async ({ priceActiveFrom, producerPriceActiveFrom } = {}) => {
    dispatch(startLoading())

    if (data?.group?.length) {
      const groupsToBeRemovedFrom = dataProp?.group?.filter(({ _id }) => !data?.group?.map(({ _id }) => _id)?.includes(_id))
      if (groupsToBeRemovedFrom.length) {
        groupsToBeRemovedFrom.forEach(({ _id }) => {
          const originalGroup = groups?.find((g) => g._id === _id)
          dispatch(updateGroup({ payload: { _id, clients: originalGroup?.clients?.filter(({ _id }) => _id !== data?._id).map(({ _id }) => _id) } }))
        })
      }
      const groupsToBeAddedTo = data?.group?.filter(({ _id }) => !dataProp?.group?.map(({ _id }) => _id)?.includes(_id))
      if (groupsToBeAddedTo.length) {
        groupsToBeAddedTo.forEach(({ _id }) => {
          const originalGroup = groups?.find((g) => g._id === _id)
          dispatch(updateGroup({ payload: { _id, clients: [...(originalGroup?.clients ?? [])?.map(({ _id }) => _id), data?._id] } }))
        })
      }
    }

    if ((typeof data?.connectedGroup === 'string' || data?.connectedGroup === null) && data?.connectedGroup !== dataProp?.connectedGroup?._id) {
      const { groups: connectedGroups } = await genericFetch('/connected-groups', { method: 'GET' })
      dispatch(setDataFields({ connectedGroups }))
      const originalGroup = connectedGroups?.find(({ _id }) => _id === dataProp?.connectedGroup?._id)
      const newGroup = connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)
      if (dataProp?.connectedGroup?._id) dispatch(updateConnectedGroup({ _id: dataProp?.connectedGroup?._id, clients: originalGroup?.clients?.filter(({ _id }) => _id !== data?._id)?.map(({ _id }) => _id), }))
      if (data?.connectedGroup) dispatch(updateConnectedGroup({ _id: data?.connectedGroup, clients: [...(newGroup?.clients || [])?.map(({ _id }) => _id), data?._id], }))
    }

    dispatch(updateClient({
      data: {
        ...data,
        eic: ["Юридическо лице"].includes(data.type) ? data?.eic || data?.pin : null,
        pin: ["Физическо лице", "Битов"].includes(data.type) ? data?.eic || data?.pin : null,
        dealers: data?.dealers?.map(({ _id }) => _id),
        priceActiveFrom,
        producerPriceActiveFrom,
        ...(['Консуматор'].includes(dataProp?.clientType) && ['Смесен'].includes(data?.clientType) && { invoiceSettings: { ...data.invoiceSettings, templatedInvoice: true, invoiceReference: { ...data.invoiceSettings.invoiceReference, producerSimpleByDays: true } } })
      },
      onSuccess: () => {
        setShowRequired(false)
        setEditable(false)
      },
    }))
  }

  if (ref) ref.current = { onRequired, onClear, onSave }

  const [dealers, setDealers] = useState([])
  const getUsersPayload = { select: 'fullName coverPhoto', role: 'dealer' }
  const updateDealers = (fullName) => dispatch(getUsers({
    payload: { ...getUsersPayload, fullName },
    onSuccess: (res) => setDealers((dealers) => uniqBy([...(dealers || []), ...(res || [])], '_id'))
  }))
  useEffect(() => {
    if (isDealer !== undefined) dispatch(getUsers({
      payload: getUsersPayload, onSuccess: (res) => {
        if (isDealer && inactive && editable && initialData) {
          handleChange('dealers', [pick(currentUser, ['_id', 'fullName'])])
          const dealers = uniqBy([...(res || []), currentUser], '_id')
          setDealers(dealers)
        } else setDealers(res)
      }
    }))
  }, [isDealer, inactive, editable, initialData])

  const handleClientFetch = ([{ identifier, vat }]) => {
    const bulstat = vat ? `BG${identifier}` : identifier
    if (bulstat === data?.bulstat) dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Няма промени по ИН по ЗДДС' } }))
    else dispatch(setModal({
      isOpen: true, type: 'confirmation', props: {
        title: 'Има промени по ИН по ЗДДС',
        children: <p style={{ textAlign: 'center' }}>{bulstat}</p>,
        onClick: () => handleChange('bulstat', bulstat),
        onRequestClose: () => handleChange('bulstat', bulstat)
      }
    }))
  }

  return (
    <>
      <div className="client-forms-main-profile-container">
        <h2>Основна информация - {data?.clientType}</h2>
        {editable && <>
          <div className="row">
            {dataProp?.clientType === 'Смесен' && <div className='col'>
              <span>Вид клиент</span>
              <Inputs.Dropdown
                options={['Консуматор', 'Производител', 'Смесен']
                  .filter((value) =>
                    value === 'Смесен' ||
                    (value === 'Консуматор' && dataProp?.objects?.every(({ isProducer }) => !isProducer)) ||
                    (value === 'Производител' && dataProp?.objects?.every(({ isProducer }) => isProducer)))
                  .map((value) => ({ value, label: value }))}
                value={data?.clientType}
                onChange={({ value }) => setData({ ...data, clientType: value, ...(value === 'Консуматор' && { fromMixedToConsumer: true }), ...(value === 'Производител' && { fromMixedToProducer: true }) })}
              />
            </div>}
            {dataProp?.clientType !== 'Смесен' && <div className="col">
              <span>Вид клиент</span>
              <Inputs.Dropdown
                options={[dataProp?.clientType, 'Смесен'].map((value) => ({ value, label: value }))}
                value={data?.clientType}
                onChange={({ value }) => {
                  if (dataProp?.clientType === 'Консуматор') setData({ ...data, clientType: value, producerSettings: { ...defaultProducerSettings, version: versions?.find(({ name }) => name === defaultContractVersionProducers)?._id, } })
                  else if (dataProp?.clientType === 'Производител') setData({ ...data, clientType: value, contractSettings: defaultContractSettings })
                }}
              />
            </div>}
            <div className={`col ${dataProp?.clientType === 'Смесен' && 'col-double'}`}>
              <span>Тип клиент</span>
              <Inputs.Dropdown
                options={['Юридическо лице', 'Физическо лице', 'Битов']
                  .filter((value) => data?.clientType !== 'Производител' || value !== 'Битов')
                  .map((value) => ({ value, label: value }))
                }
                value={data?.type}
                onChange={({ value }) => handleChange('type', value)}
              />
            </div>
            <div className="col">
              <span>ЕИК/ЕГН</span>
              <Input.Text
                value={data?.eic || data?.pin || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  handleChange('eic', value)
                }}
                inputClassName={`${isRequired('eic')}`}
              />
            </div>
            <div className="col">
              <span>Име на клиент</span>
              <Input.Text
                value={data?.fullName || ''}
                onChange={({ target: { value } }) => handleChange('fullName', value)}
                inputClassName={`${isRequired('fullName')}`}
              />
            </div>
            <div className="col col-bulstat">
              <span>ИН по ЗДДС</span>
              <Input.Text
                value={data?.bulstat || ''}
                onChange={({ target: { value } }) => {
                  if (!/^[A-Za-z\d]+$/.test(value) && value !== '') return
                  handleChange('bulstat', value)
                }}
                inputClassName={`${isRequired('bulstat')}`}
              />
              <span
                className='icon icon-refresh-blue'
                onClick={() => {
                  dispatch(startLoading())
                  dispatch(startApisFetch({ data: data?.eic || data?.pin, onSuccess: handleClientFetch }))
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-double col-mol">
              <span>МОЛ</span>
              <Input.Text
                value={data?.mol?.map(({ fullName }) => fullName)?.join('; ') ?? ''}
                disabled
              />
              <span
                className="icon icon-edit"
                onClick={() => dispatch(setModal({
                  isOpen: true, type: 'clientEditMOL', props: {
                    client: data,
                    handleChange: (value) => handleChange('mol', value)
                  }
                }))}
              />
            </div>

          </div>
        </>
        }
        <div className="row">
          <div className="col">
            <span>Град</span>
            <Input.Text
              value={data?.city || ''}
              onChange={({ target: { value } }) => handleChange('city', value)}
              inputClassName={`${isRequired('city')}`}
              disabled={!editable}
            />
          </div>
          <div className="col col-double">
            <span>Адрес</span>
            <Input.Text
              value={data?.address || ''}
              onChange={({ target: { value } }) => handleChange('address', value)}
              inputClassName={`${isRequired('address')}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Телефон</span>
            <Input.Text
              value={data?.phoneNumber || ''}
              onChange={({ target: { value } }) => handleChange('phoneNumber', value)}
              inputClassName={`${isRequired('phoneNumber')}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Имейл</span>
            <Input.Text
              value={data?.email || ''}
              onChange={({ target: { value } }) => {
                if (/[\u0400-\u04FF]+/.test(value) && value !== '') return
                handleChange('email', value.trim())
              }}
              inputClassName={`${isRequired('email') || isEmail(data?.email)}`}
              disabled={!editable}
            />
          </div>
        </div>
        <div className="row">
          {!isDealer && <div className="col col-activity">
            <span>Дейности</span>
            <Inputs.Select
              className="activity-container"
              editable={editableProp || editable}
              options={businessTypes}
              value={data?.businessType?.map((obj) => obj?._id || obj)}
              handleChange={(value) => handleChange('businessType', value)}
              showRequired={false}
              createDataType="businessTypes"
              text={{ main: 'Дейности', secondary: 'Избери дейност/и' }}
              search
            />
          </div>}
          <div className="col">
            <span>Клиентски номер</span>
            <Input.Text
              value={data?.clientNumber || ''}
              onChange={({ target: { value } }) => handleChange('clientNumber', value)}
              disabled={!editable}
            />
          </div>
          {editable && !data.clientNumber && <div className="col col-clientNumber-generate">
            <Button.Raised
              className='blue'
              text='Генерирай'
              onClick={async () => {
                const { clientNumber } = await fetch(`${URL}/clientNumber/request`, {
                  method: 'POST',
                  headers: Headers.getWithAuth(),
                  body: JSON.stringify({ "requestedFor": "Clients" }),
                }).then(res => res.json())
                handleChange('clientNumber', clientNumber)
              }}
            />
          </div>}
          {!isDealer && <div className={`col col-additional-client ${!editable && 'disabled'}`}>
            <span>Автоматични имейли</span>
            <Button.Switch
              isOn={data?.autoEmails}
              onChange={() => editable && handleChange("autoEmails", !data?.autoEmails)}
            />
          </div>}
          {!isDealer && <div className={`col col-additional-client ${!editable && 'disabled'}`}>
            <span>Група клиенти</span>
            <Inputs.DropdownSelect
              async paginate cacheOptions defaultOptions isMulti
              loadOptions={(name, _, { page = 1 } = {}) => loadDropdownOptions({ path: '/groups/browse', payload: { name, page, limit: 10 }, paginate: true })}
              rawValue={data?.group ?? []}
              onChange={(value) => handleChange('group', value)}
              getOptionValue={({ _id }) => _id}
              getOptionLabel={({ name }) => name}
              additional={{ page: 1 }}
              disabled={!editable}
            />
          </div>}
          <div className="col col-connected-client">
            <span>Свързани клиенти</span>
            {connectedGroups !== undefined
              ? data?.connectedGroup
                ? <div className="row row-client">
                  {editable && <Button.Icon name="plus" color="red" onClick={() => handleChange('connectedGroup', null)} />}
                  <div className='row' style={{ height: '38px', margin: '10px 0' }}>
                    <span>{data?.connectedGroup?.name || connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}</span>
                  </div>
                  <div
                    className="icon icon-open"
                    onClick={() => dispatch(setModal({
                      isOpen: true, type: 'confirmation', props: {
                        title: `Група ${data?.connectedGroup?.name || connectedGroups?.find(({ _id }) => _id === data?.connectedGroup)?.name}`,
                        children: (
                          <div className="" style={{ maxHeight: 500, overflow: 'auto' }}>
                            {(data?.connectedGroup?.clients || connectedGroups?.find(({ _id }) => _id === data?.connectedGroup).clients)
                              ?.map(({ fullName, eic, pin }) => <div className=" row" key={eic || pin}>
                                <p>{fullName} - {eic || pin}</p>
                              </div>)}
                          </div>
                        ),
                        hideButton: true,
                      },
                    }))}
                  />
                </div>
                : editable
                  ? <Popup
                    position='left center'
                    contentStyle={{ width: 'auto', maxWidth: 400 }}
                    trigger={<div className="icon icon-add" style={{ margin: '14px 0' }} />}
                  >
                    {close => (
                      <PopupComponents.Select
                        single
                        search
                        handleSearch={(value) => dispatch(getClientsForDropdown({ search: value, getAllDetails: true }))}
                        hide={close}
                        handleChange={(value) => {
                          const client = clients.find(({ _id }) => _id === value)
                          if (!client) return
                          else if (client?.connectedGroup) handleChange('connectedGroup', client?.connectedGroup?._id)
                          else dispatch(setModal({
                            isOpen: true, type: 'connectedClientGroup',
                            props: { onCreate: (name) => { dispatch(createConnectedGroup({ data: { name, clients: [value] }, onSuccess: (group) => handleChange('connectedGroup', group), })) }, },
                          }))
                        }}
                        options={clients?.map(({ eic, pin, fullName, _id }) => ({ label: `${fullName} | ${eic || pin}`, value: _id, }))}
                        text={{ main: 'Търси по име/ЕИК/ЕГН', secondary: 'Клиенти' }}
                      />
                    )}
                  </Popup>
                  : <div style={{ height: '58px' }} />
              : <Shared.LoaderInline />}
          </div>
        </div>
        {!isDealer && data?.clientType !== 'Производител' && <div className="row">
          <div className="col col-payment-type">
            <Client.Forms.IntermediatePayments
              editable={editable}
              data={data.invoiceSettings}
              setData={(payload) => setData({ ...data, invoiceSettings: { ...data?.invoiceSettings, ...payload } })}
            />
          </div>
        </div>}
        <div className="row">
          {(!isDealer || (isDealer && data?.dealers?.map(({ _id }) => _id)?.includes(currentUser?._id))) && <div className={`col col-triple col-merchant ${!editable && 'disabled'}`}>
            <span>Търговци</span>
            <div className="row">
              {editable && (<Popup
                position='right center'
                contentStyle={{ width: 'auto', maxWidth: 400 }}
                trigger={<div className="icon icon-add" />}
              >
                {close => (
                  <PopupComponents.Select
                    hide={close}
                    search
                    handleSearch={updateDealers}
                    value={data?.dealers?.map(({ _id }) => _id)}
                    options={dealers?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
                    handleChange={(value) => {
                      const payload = value.map(_id => dealers.find((d) => d._id === _id))
                      handleChange('dealers', payload)
                      // setClientData({ dealers: payload })
                    }}
                    text={{ main: 'Търси по име', secondary: 'Търговци' }}
                  />
                )}
              </Popup>
              )}
              <div className="row">
                {data?.dealers?.map(({ _id, fullName, coverPhoto }) => (
                  <div key={_id} className="single-person-container row">
                    <div
                      className="profile-image-container"
                      style={{ backgroundImage: `url(${coverPhoto || require('../../../../assets/images/default-user-avatar.jpg')})` }}
                    />
                    <p>{fullName}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>}
        </div>
      </div>
      {(data?.contacts || editableContacts || editable) && (
        <Client.Forms.AdditionalContact
          clientType={data?.clientType}
          data={data?.contacts}
          handleRemoveContact={(i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1)
            handleChange('contacts', newContacts)
          }}
          handleChangeContact={(field, value, i) => {
            const newContacts = [...(data?.contacts || [])]
            newContacts.splice(i, 1, { ...newContacts?.at(i), [field]: value })
            handleChange('contacts', newContacts)
          }}
          handleChange={(field, value) => handleChange(field, value)}
          showRequired={showRequired}
          editable={editableContacts || editable}
        >
          {isDealer
            ? (editableContacts
              ? <>
                <Button.Raised text='Откажи' className='cancel' onClick={() => { handleChange('contacts', dataProp?.contacts); setEditableContacts(false) }} />
                <Button.Raised text='Запази' className='blue' onClick={() => { onSave(); setEditableContacts(false) }} />
              </>
              : <>
                <Button.Raised text='Редактирай' className='blue' onClick={() => { if (!data?.contacts?.length) handleChange('contacts', [{}]); setEditableContacts(true) }} />
              </>)
            : null}
        </Client.Forms.AdditionalContact>
      )}
      {data?.clientType !== 'Производител' && (
        !isDealer || data.showContractSettings
          ? <Client.Forms.DocumentsAndContract
            data={data}
            setData={(payload) => setData({ ...data, ...payload })}
            contractSettings={data?.contractSettings}
            setContractSettings={(newData) => handleChange('contractSettings', newData)}
            handleChange={(field, value) => handleChange('contractSettings', { ...data?.contractSettings, [field]: value })}

            versions={versions}
            products={products}
            handlePriceChange={handlePriceChange}

            showRequired={showRequired}
            editable={editable}
            isProfile
            originalData={dataProp}
            isInactive={inactive}
          />
          : inactive && editable ? <div className='client-forms-dealer-documents-and-contract-container row'>
            <h2>Документи и договор</h2>
            <Button.Raised text='Добави' onClick={() => dispatch(setModal({
              isOpen: true,
              type: 'updateClientTypeMixed',
              props: {
                title: 'Документи и договор',
                client: { clientType: 'Производител' },
                onSubmit: ({ contractSettings, product, price }) => {
                  setData({ ...data, contractSettings, product, price, showContractSettings: true })
                  dispatch(setModal({ isOpen: false }))
                }
              }
            }))} />
          </div> : null
      )}
      {data?.clientType !== 'Консуматор' && (
        !isDealer || data.showProducerSettings
          ? <Client.Forms.ProducerSettings
            producerSettings={data?.producerSettings}
            setProducerSettings={(newData) => handleChange('producerSettings', newData)}
            handleChange={(field, value) => handleChange('producerSettings', { ...data?.producerSettings, [field]: value })}

            versions={versions}
            products={products}

            showRequired={showRequired}
            editable={editable}

            isClient
            isProfile
            isInactive={inactive}

            showInvoiceSettings={!isDealer || data.showProducerSettings}
            invoiceSettings={data?.invoiceSettings}
            handleChangeInvoiceSettings={(field, value) => handleChange('invoiceSettings', { ...data?.invoiceSettings, [field]: value })}

            originalData={dataProp}
          />
          : inactive && editable ? <div className='client-forms-dealer-producer-settings-container row'>
            <h2>Производство</h2>
            <Button.Raised text='Добави' onClick={() => dispatch(setModal({
              isOpen: true,
              type: 'updateClientTypeMixed',
              props: {
                title: 'Производство',
                client: { clientType: 'Консуматор' },
                onSubmit: ({ producerSettings, invoiceSettings: { producerBank, producerIBAN } }) => {
                  setData({ ...data, producerSettings, invoiceSettings: { ...data?.invoiceSettings, producerBank, producerIBAN }, showProducerSettings: true })
                  dispatch(setModal({ isOpen: false }))
                }
              }
            }))} />
          </div> : null
      )}

      {!isDealer && !isEmpty(data?.clientPricing) && <Client.Forms.ClientPricing data={data?.clientPricing} />}
      {!isDealer && !isEmpty(data?.producerPricing) && <Client.Forms.ProducerPricing data={data?.producerPricing} />}
    </>
  )
})

export default MainProfile
