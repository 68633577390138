import React, { useCallback, useEffect, useState } from "react"
import io from "socket.io-client"
import "./styles.scss"
import { emit, useQuery } from "../helpers"
import TaskForm from "../TaskForm"
import Task from "../Task"
import Board from "./Board"
import BoardForm from "./BoardForm"
import { isEmpty, pick } from "lodash"
import Swal from "sweetalert2"
import moment from "moment"
import Filter from "./Filter"
import { Emoji } from 'emoji-picker-react';

const TaskManagement = ({ URL }) => {
    const mapPriority = { 'Нисък': '#00CB5E', 'Среден': '#FFB612', 'Висок': '#FA4444', 'completed': '#8E8E8E' }

    const { board, _id, boardId, showForm, handleUrlChange } = useQuery()

    const [myTasks, setMyTasks] = useState({ docs: [] })
    const [boards, setBoards] = useState([])
    const [columns, setColumns] = useState([])
    const [availableFilters, setAvailableFilters] = useState({})
    const [task, setTask] = useState({})
    const [boardForm, setBoardForm] = useState({})

    const [socket, setSocket] = useState(null)
    const socketInit = useCallback(() => {
        const newSocket = io(URL, { query: `token=${localStorage.getItem('token')}`, transports: ['websocket'] })
        if (newSocket) {
            const controller = {
                'boards/getAll': ({ data }) => setBoards(data),
                'boards/create': ({ data }) => {
                    setBoards((boards) => [...boards, data])
                    handleUrlChange({ board: data._id, showForm: undefined })
                },
                'boards/updateOne': ({ data }) => {
                    handleUrlChange({ board: data._id, showForm: undefined })
                },
                'boards/getOne': ({ data }) => setBoardForm(data),
                'tasks/getMine': ({ data }) => {
                    setMyTasks((state) => {
                        if (data.page > 1) data.docs = [...(state.docs ?? []), ...data.docs]
                        return data
                    })
                    setIsLoadingMyTasks(false)
                },
                'tasks/getMany': ({ data }) => setColumns(data),
                'tasks/getOne': ({ data }) => setTask(data),
                'tasks/create': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], [...c[1], data]]))
                    setTask(data)
                    handleUrlChange({ _id: data._id, category: undefined, showForm: undefined })
                },
                'tasks/updateOne': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], c[1].map((t) => t._id !== data._id ? t : data)]))
                    setTask(data)
                    handleUrlChange({ _id: data._id, showForm: undefined })
                },
                'tasks/archiveOne': () => { },
                'tasks/unarchiveOne': () => { },
                'tasks/reorder': ({ data }) => setColumns((columns) => columns.map((c) => c[0]._id !== data.categoryId ? c : [c[0], data.newOrder.map((_id) => c[1].find((t) => t._id === _id))])),
                'tasks/changeCategory': ({ data }) => setColumns((columns) => columns.map((c) => {
                    if (c[0]._id === data.oldCategoryId) return [c[0], c[1].filter(({ _id }) => _id !== data.task._id)]
                    else if (c[0]._id === data.newCategoryId) return [c[0], data.newOrder.map((_id) => c[1].find((t) => t._id === _id) || data.task)]
                    else return c
                })),
                'tasks/search': () => { },
                'tasks/startWork': () => { },
                'tasks/stopWork': () => { },
                'tasks/toggleStatus': ({ data }) => {
                    setColumns((columns) => columns.map((c) => c[0]._id !== data.category._id ? c : [c[0], c[1].map((t) => t._id !== data._id ? t : data)]))
                    setTask(data)
                },
                'tasks/newActivity': ({ data }) => setTask((task) => ({ ...task, activity: [data, ...(task?.activity || [])] })),

                'categories/getAll': () => { },
                'categories/create': ({ data }) => setColumns((columns) => [...columns, [data, []]]),
                'categories/updateOne': ({ data }) => setColumns((columns) => columns.map(([c, t]) => c._id !== data._id ? [c, t] : [data, t])),
                'categories/deleteOne': ({ data }) => setColumns((columns) => columns.filter(([c]) => c._id !== data._id)),
                'categories/reorder': ({ data }) => setColumns((columns) => data.newOrder.map(_id => columns.find(([c]) => c._id === _id))),

                'comments/create': ({ data }) => setTask((task) => ({ ...task, comments: [data, ...(task?.comments || [])] })),
                'comments/delete': () => { },

                'files/create': ({ data }) => setTask((task) => ({ ...task, files: [data, ...(task?.files || [])] })),
                'files/deleteOne': ({ data }) => setTask((task) => ({ ...task, files: task.files.filter(({ _id }) => _id !== data?._id) })),

                'steps/create': ({ data }) => setTask((task) => ({ ...task, steps: [...(task?.steps || []), data] })),
                'steps/toggle': ({ data }) => setTask((task) => ({ ...task, steps: task?.steps?.map((s) => s._id !== data._id ? s : data) })),
                'steps/deleteOne': ({ data }) => setTask((task) => ({ ...task, steps: task.steps.filter(({ _id }) => _id !== data?._id) })),


                'filters/getAll': ({ data: { users, userGroups } }) => setAvailableFilters({ assignedTo: users, userGroups }),
                'tasks/error': ({ message }) => Swal.fire({ title: 'Грешка!', text: message, icon: 'error', confirmButtonText: 'Ок' })
            }
            newSocket.on('tasks', ({ type, payload }) => {
                console.log('Socket in:', { type, payload });
                if (controller[type]) controller[type](payload)
            })
            setSocket(() => newSocket)
        }
    }, [])
    useEffect(() => {
        if (!socket) socketInit()
        return () => { if (socket) socket.disconnect() }
    }, [socket, socketInit])

    useEffect(() => {
        if (!socket) return
        if (isEmpty(availableFilters)) emit(socket, { type: 'filters/getAll' })
        if (_id && _id !== task?._id) emit(socket, { type: 'tasks/getOne', payload: { taskId: _id } })
        if (boardId && boardId !== boardForm?._id) emit(socket, { type: 'boards/getOne', payload: { boardId } })
        if (board && board !== boardForm?._id) emit(socket, { type: 'boards/getOne', payload: { boardId: board } })
        if (!board && isEmpty(boards)) emit(socket, { type: 'boards/getAll' })
    }, [socket, board, _id, boardId])

    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    const fetchMyTasks = useCallback((params = {}) => {
        const payload = pick(filters, ['status', 'priority', 'assignedTo', 'dateStart', 'dateEnd'])
        emit(socket, { type: 'tasks/getMine', payload: { filters: payload, ...params } })
    }, [socket, filters])
    useEffect(() => { fetchMyTasks() }, [fetchMyTasks])
    const [isLoadingMyTasks, setIsLoadingMyTasks] = useState(false)
    const handlePaginationMyTasks = (scrollTop, height, scrollHeight) => {
        if (!isLoadingMyTasks && scrollTop + height >= scrollHeight - 50 && myTasks.hasNextPage) {
            setIsLoadingMyTasks(true)
            fetchMyTasks({ page: myTasks.nextPage })
        }
    }


    const props = { socket, task, boardForm, setTask, availableFilters, columns, setColumns }


    if (showForm === 'task') return <TaskForm {...props} assignees={availableFilters?.assignedTo} />
    if (showForm === 'board') return <BoardForm {...props} assignees={availableFilters?.assignedTo} userGroups={availableFilters?.userGroups} />
    else if (_id) return <Task {...props} />
    else if (board) return <Board {...props} />
    return <div className="task-management-container row">
        <div className="task-management-my-tasks-container card col">
            <div className="task-management-my-tasks-header row">
                <h2>Моите задачи</h2>
                <div className="tasks-icon-filter-container">
                    <div className="tasks-icon tasks-icon-filter" onClick={() => setShowFilter(true)} />
                    {!isEmpty(filters) && <div className="filter-indicator" />}
                </div>
            </div>
            <div className="task-management-my-tasks-content" onScroll={({ target }) => handlePaginationMyTasks(target.scrollTop, target.getBoundingClientRect().height, target.scrollHeight)}>
                {myTasks.docs.map(({ _id, title, board, category, dateEnd, priority, }) => <div
                    key={_id}
                    onClick={() => handleUrlChange({ _id })}
                    className="single-my-task-container card"
                    style={{ borderColor: mapPriority[priority] }}
                >
                    <p className="category">{board.title} - {category.title}</p>
                    <h2>{title}</h2>
                    <p>Срок:
                        <span> {moment(dateEnd).format('DD.MM.YYYY')}</span>
                        {moment(dateEnd).isBefore(moment()) && <span className="orange"> (просрочен с {moment().startOf('day').diff(moment(dateEnd).startOf('day'), 'days')} дни)</span>}
                    </p>
                </div>)}
            </div>
            {!myTasks.docs.length && <div className="task-management-my-tasks-empty col">
                <div className="image" />
                <span>Няма задачи назначени към Вас!</span>
            </div>}
        </div>
        <div className="task-management-boards-container card col">
            <div className="task-management-boards-header row">
                <h2>Групи</h2>
                {boards.length > 0 && <div className="tasks-icon tasks-icon-plus" onClick={() => handleUrlChange({ showForm: 'board' })} />}
            </div>
            <div className="task-management-boards-content row">
                {boards.map(({ _id, title, icon, permissions, activeTasks, lastTask }) => <div key={_id} onClick={() => handleUrlChange({ board: _id, })} className="single-board-container card">
                    <div className="upper-container card">
                        <div className="single-board-header row">
                            <div className="icon-container card"><Emoji unified={icon} size="25" /></div>
                            <h3>{title}</h3>
                            <div className="tasks-icon tasks-icon-arrow-orange" />
                        </div>
                        <div className="single-board-additional-header row">
                            <div className="tasks-icon tasks-icon-participants" />
                            <p className="permissions">Участват: <span>{permissions.length} {permissions.length === 1 ? 'потребител' : 'потребители'}</span></p>
                            <div className="divider" />
                            <div className="tasks-icon tasks-icon-active-tasks" />
                            <p>Активни: <span>{activeTasks} {permissions.length === 1 ? 'задача' : 'задачи'}</span></p>
                        </div>
                    </div>
                    {lastTask && <div className="bottom-container">
                        <p>Последно добавена задача: <span>{moment(lastTask).format('HH:mm | DD.MM.YYYY')}</span></p>
                        <p className="title"><span>{lastTask.title}</span></p>
                        <div className="row">
                            <p className="author">От: <span>{lastTask?.author?.fullName}</span></p>
                            {lastTask?.assignedTo.length > 0 && <p>До: <span>{lastTask?.assignedTo?.map(({ fullName }) => fullName)?.join(', ')}</span></p>}
                        </div>
                    </div>}
                </div>)}
            </div>
            {!boards.length && <div className="task-management-boards-empty col">
                <div className="image" />
                <span>Създайте първата група, например: Финанси и счетоводство, Търговия, Маркетинг...</span>
                <div className="raised-button-container" onClick={() => handleUrlChange({ showForm: 'board' })}>Добави група</div>
            </div>}
        </div>
        <Filter isOpen={showFilter} hide={() => setShowFilter(false)} filters={filters} setFilters={setFilters} assignees={availableFilters?.assignedTo} />
    </div>
}

export default TaskManagement