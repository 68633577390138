import React from "react"
import { Input, Button } from '@makedonski/admin-ui'
import { Inputs } from "components"
import "./styles.scss"
import { mAdd, bn } from "utilities"

const Compensations = ({ invoice, additionalCompensations, setAdditionalCompensations, showRequired }) => {
    const { pricing } = invoice || {}
    const { additionalInformation = {} } = pricing || {}

    return <div className="invoices-forms-compensations-container">
        <div className="invoices-forms-compensations-header row">
            <h2>Компенсации: </h2>
            <div className="icon icon-add" onClick={() => setAdditionalCompensations([...additionalCompensations, {}])} />
        </div>
        <div className="invoices-forms-compensations-content">
            <div className="invoices-forms-compensations-form-header row">
                {["Наименование", "Стойност"].map(label => <span key={`compensation-label-${label}`}>{label}</span>)}
                <div className="placeholder"></div>
            </div>
            {/* {Object.entries(additionalInformation)?.map(([key, value], i) => (
                <div key={`compensation-${i}`} className="invoices-forms-compensations-single row">
                    <div className="col"><Input.Text value={key} disabled /></div>
                    <div className="col"><Inputs.TextLabeled label='лв.' value={value} disabled /></div>
                    <div className="placeholder"></div>
                </div>
            ))} */}
            {additionalCompensations?.map(({ key, value }, i) => (
                <div key={`additional-compensation-${i}`} className="invoices-forms-compensations-single row">
                    <div className="col"><Input.Text
                        value={key || ''}
                        inputClassName={showRequired && !key && 'required'}
                        onChange={({ target: { value } }) => {
                            const newAdditionalCompensations = [...additionalCompensations]
                            newAdditionalCompensations.splice(i, 1, { ...additionalCompensations.at(i), key: value })
                            setAdditionalCompensations(newAdditionalCompensations)
                        }}
                    /></div>
                    <div className="col"><Inputs.TextLabeled
                        label='лв.' value={value || ''}
                        inputClassName={showRequired && !value && 'required'}
                        onChange={({ target }) => {
                            if (!/^-?\d*(\.||\.\d{0,6})?$/.test(target.value) && target.value !== '') return
                            const newAdditionalCompensations = [...additionalCompensations]
                            newAdditionalCompensations.splice(i, 1, { ...additionalCompensations.at(i), value: target.value })
                            setAdditionalCompensations(newAdditionalCompensations)
                        }}
                    /></div>
                    <Button.Icon name="plus" color='red' onClick={() => {
                        const newAdditionalCompensations = [...additionalCompensations]
                        newAdditionalCompensations.splice(i, 1)
                        setAdditionalCompensations(newAdditionalCompensations)
                    }} />
                </div>
            ))}
        </div>
        <div className="invoices-forms-compensations-footer row">
            <p>{`Стойност на компенсации`}</p>
            <Inputs.TextLabeled
                label="лв."
                value={additionalCompensations?.reduce((acc, { value }) => mAdd(acc, bn(value)), 0) ?? '0'}
                disabled
            />
            <div className="placeholder"></div>
        </div>
    </div>
}

export default Compensations