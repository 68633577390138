
import { Alerts, Api } from "@makedonski/socourt-utilities"
import { reportsTypes, stopLoading } from "actions"
import { URL } from "config/settings"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"

export const getReportsRegistrations = (action$) => action$.pipe(
    ofType(reportsTypes.GET_REPORTS_REGISTRATIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/reports/registrations`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const getReportsTrade = (action$) => action$.pipe(
    ofType(reportsTypes.GET_REPORTS_TRADE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/reports/trade`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const getReportsPortfolio = (action$) => action$.pipe(
    ofType(reportsTypes.GET_REPORTS_PORTFOLIO),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/reports/portfolio`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)