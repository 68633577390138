import React, { useEffect, useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDaysOffByUser, setModal, startLoading, stopLoading, updateUser } from 'actions'
import './styles.scss'
import { uploadFile } from 'utilities'
import moment from 'moment'

const Profile = ({ close }) => {
  const dispatch = useDispatch()
  const { currentUser: profile } = useSelector(({ general }) => general)

  const [state, setState] = useState(null)
  useEffect(() => { dispatch(getUserDaysOffByUser({ payload: { user: profile._id, month: moment().format('MM/YYYY') }, onSuccess: setState })) }, [])

  const handleUpload = (files) => {
    dispatch(startLoading())
    uploadFile({ file: files[0], createId: true }).then(({ location }) => {
      dispatch(updateUser({
        payload: { _id: profile._id, coverPhoto: location },
        isCurrentUser: true,
        onSuccess: () => dispatch(stopLoading())
      }))
    })
  }

  return (
    <div className="modal-profile-container">
      <div className="modal-profile-header row">
        <h2>Личен профил</h2>
        <div className="icon icon-close" onClick={close} />
      </div>
      <div className="profile-image-container col">
        <div
          className="profile-image icon no-active no-pointer"
          style={{ backgroundImage: `url(${profile?.coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})`, }}
        >
          <Button.UploadButton
            text={<div className={`profile-status icon ${profile?.coverPhoto ? 'icon-edit' : 'icon-export'}`} />}
            onChange={({ target: { files } }) => handleUpload(files)}
          />
        </div>
        <div className="col-days-off">
          <p>Отсъствия</p>
          <div className="card-user-days-off-subheader row">
            <p>Общо: <span>{Object.entries(state?.types ?? {}).filter(([key]) => !['homeOffice'].includes(key)).reduce((acc, [, cur]) => acc + cur, 0)}</span></p>
            <p>П: <span>{state?.types?.paid ?? '0'}</span></p>
            <p>Н: <span>{state?.types?.unpaid ?? '0'}</span></p>
            <p>Б: <span>{state?.types?.sick ?? '0'}</span></p>
            <p>Х: <span>{state?.types?.homeOffice ?? '0'}</span></p>
            <p>Д: <span>{state?.types?.other ?? '0'}</span></p>
          </div>
          <Button.Raised text='Заяви отсъствие' onClick={() => dispatch(setModal({ isOpen: true, type: 'userDaysOff', props: { onClose: () => dispatch(setModal({ isOpen: true, type: 'profile' })) } }))} />
        </div>
      </div>
      <div className="profile-data-container col">
        <p>Данни за вход в системата</p>
        <Input.Text
          disabled
          placeholder='Име:'
          value={profile?.fullName ?? ''}
        />
        <div className="row row-account-data">
          <Input.Text disabled={true} value={profile?.email} />
          <Button.Raised
            text="Промени парола"
            className="btn-change-pass"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'changePassword', props: {} }))}
          />
        </div>
      </div>
    </div>
  )
}

export default Profile
