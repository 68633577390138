import moment from "moment"
import React from "react"
import "./styles.scss"

const Activity = ({ task }) => {
    const { activity } = task || {}
    return <div className="task-management-activity-container col">
        {activity?.map(({ _id, createdAt, message, user }) => <div key={_id} className="task-management-activity-single-container">
            <p className="time">{moment(createdAt).format("HH:mm | DD.MM.YYYY")}</p>
            <p className="activity">{message}</p>
            <p className="author">От: <span>{user?.fullName}</span></p>
        </div>)}


    </div>
}

export default Activity