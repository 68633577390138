import { Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment'
import { isEmpty, mapKeys, mapValues, groupBy, omit, transform, countBy } from 'lodash'
import { startLoading, stopLoading, setModal, checkObjectExists } from 'actions'
import { createObjectsFields, allRegistrationStatuses, clientsStatus, requiredCreateObjectFields, requiredCreateObjectProducerFields, productsPricesFields, createObjectsProducerFields, producerTypeOptions } from 'config/constants'
import { store } from 'config/store'
import { checkIsDealer, fileParser } from 'utilities'

const errorText = {
    'active': 'Тази точка е активна, и фигурира в системата. Може да продължите към пререгистрация или да преустановите действията',
    'leaving': 'Тази точка е напускаща, и фигурира в системата. Може да продължите към пререгистрация или да преустановите действията',
    'inactive': 'Тази точка е неактивна, но фигурира в системата. Точката ще бъде регистрирана като нов обект',
    'potential': 'Тази точка е потенциална, но фигурира в системата. Точката ще бъде регистрирана като нов обект',
}
const statusText = {
    'active': 'активна, и',
    'leaving': 'напускаща, и',
    'inactive': 'неактивна, но',
    'potential': 'потенциална, но',
}

export const mapExcelToObjects = async ({ forProducers, files, onSuccess, client, data } = {}) => {
    const fields = forProducers ? createObjectsProducerFields : createObjectsFields
    const { dispatch } = store || {}
    dispatch(startLoading())
    try {
        const file = [...files].at(0)
        let parsed = await new Promise((resolve) => {
            fileParser.createObjects(file, async (parsedData) => {
                if (isEmpty(parsedData)) return resolve(null)
                else return resolve(parsedData)
            })
        })
        if (!isEmpty(parsed)) {
            const errors = []
            const repeating = transform(countBy(parsed, 'ИТН'), function (result, count, value) {
                if (count > 1) result.push(value);
            }, [])
            if (repeating.length) Alerts.error('Повтарящи се ИТН-та', repeating.join(", "))
            parsed = parsed.map((row, rowIndex) => {
                let newRow = mapKeys(row, (value, key) => {
                    if (['Коментар'].includes(key)) return 'comments'
                    else return fields.find(({ label }) => label === key).value
                })
                newRow = mapValues(newRow, (value, key) => {
                    if (['existingObject', 'transfer', 'requiresNetworkData', 'producerForecasting', 'producerAfter2021', 'producerNewPlant', 'producerPreferences', 'producerOwnNeed', 'consumerOwnPlant'].includes(key)) return value === 'Да' ? true : value === 'Не' ? false : undefined
                    else if (['itn'].includes(key)) {
                        if (
                            (['ЕР Юг'].includes(row?.['ЕРП']) && ((!value.startsWith('BG5521900') || value.length !== 33) && ((process.env.REACT_APP_PLATFORM === 'Synergon' && !forProducers) || value.length !== 7))) ||
                            (['ЕСО'].includes(row?.['ЕРП']) && (!value.startsWith('32X') || value.length !== 16)) ||
                            (!['ЕР Юг', 'ЕСО'].includes(row?.['ЕРП']) && (!value.startsWith('32Z') || value.length !== 16))
                        ) errors.push(`Невалидно ИТН: ${newRow.itn}`)
                        return value.toString()
                    }
                    else if ([
                        'voltage',
                        'seasoning',
                        'forecast',
                        'measureType',
                        'erp',
                        'profile'
                    ].includes(key)) {
                        const _id = data[`${key}s`]?.find(({ name }) => name === value)?._id
                        if (!_id) errors.push(`ИТН: ${newRow.itn} - ${fields.find(({ value }) => value === key).label}: ${value}`)
                        return _id || ""
                    }
                    else if (['producerType'].includes(key)) {
                        const producerType = producerTypeOptions.find((opt) => opt === value)
                        if (!producerType) errors.push(`ИТН: ${newRow.itn} - ${fields.find(({ value }) => value === key).label}: ${value}`)
                        return producerType
                    }
                    else if ([
                        'averageConsumption',
                        'deliveredPower',
                        'producerProductionPower',
                        'consumerProductionPower',
                    ].includes(key)) return value.toString().replace(',', '.')
                    else if (["activeFrom", "entryEnd"].includes(key)) {
                        if (value?.split(".")?.length !== 3) return undefined
                        const d = moment(value, 'DD.MM.YYYY').toDate()
                        if (d instanceof Date && !isNaN(d)) return d
                        else return undefined
                    }
                    else if (['producerDirectMember'].includes(key)) return value === 'Пряк'
                    else if (['comments'].includes(key)) return [{ text: value }]
                    else return value.toString()
                })
                if (data.measureTypes.find(({ name }) => name === 'Почасово')._id === parsed.measureType) newRow.STPMeasure = undefined
                if (!client?.declaration) newRow.requiresNetworkData = undefined
                if (newRow.transfer) newRow.status = 'transfer'
                if (!newRow.existingObject) newRow.activeFrom = undefined
                if (forProducers) newRow.isProducer = true
                return newRow
            })
            if (errors.length) Alerts.error("Невалидни стойности", errors.join("\n"))
            onSuccess(parsed)
        }
    } catch (e) {
        console.error(e);
        Alerts.error('Грешка при обработването на файла!')
        dispatch(stopLoading())
    }
}

export const displayMassCheckError = ({ objects, setShowRequired = () => { } } = {}) => {
    const { dispatch } = store || {}
    const grouped = omit(groupBy(objects, 'error'), 'undefined')
    if (Object.keys(grouped).length) {
        setShowRequired(true)
        dispatch(setModal({
            isOpen: true,
            type: 'confirmation',
            props: {
                title: 'Масова Проверка',
                children: (
                    <div className="col" style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                        {Object.keys(grouped).map(key => (
                            <p key={`error-${key}`} style={{ fontWeight: '700', whiteSpace: "pre-wrap" }}>{key}:{`\n`}
                                <span style={{ fontWeight: '400' }}>{grouped[key].map(({ itn }) => itn).join(", ")}</span>
                            </p>
                        ))}
                    </div>
                ),
                buttonText: 'Разбрах',
            }
        }))
    }
}

export const handleCheck = ({
    client,
    data,
    index,
    onSuccess,
    massCheck,
    handleChangeMultiple = () => { },
    forProducers
} = {}) => {
    const { dispatch } = store || {}
    const isDealer = checkIsDealer()
    const omitFields = ['client', 'status', 'createdAt', 'updatedAt', '__v', 'existingObject']
    dispatch(checkObjectExists({
        payload: { itn: data?.itn },
        onSuccess: (obj) => {
            if (!massCheck) dispatch(stopLoading())
            const { _id, client: previousClient, status } = obj || {}
            if (!obj) {
                if (!massCheck) Alerts.success({ title: 'Точката не фигурира в системата!' })
                if (onSuccess) onSuccess(index)
                return
            } else if (!previousClient?._id) {
                const error = 'Точката фигурира в системата, но липсва клиент! Моля обърни се към администратор.'
                if (!massCheck) Alerts.error(error)
                else handleChangeMultiple({ error }, index)
                if (onSuccess) onSuccess(index)
                return
            } else if ((forProducers ?? data?.isProducer ?? false) === true && !obj.isProducer) {
                const error = 'Точката фигурира в системата като консумация!'
                if (massCheck) handleChangeMultiple({ error }, index)
                else Alerts.error(error)
            } else if ((forProducers ?? data?.isProducer ?? false) === false && obj.isProducer) {
                const error = 'Точката фигурира в системата като производство!'
                if (massCheck) handleChangeMultiple({ error }, index)
                else Alerts.error(error)
            } else if (isDealer && previousClient?._id === client?._id && status === 'potential') {
                if (massCheck) handleChangeMultiple({
                    requiresNetworkData: false,
                    ...omit(obj, omitFields),
                    status: 'registration',
                    previousStatus: status,
                    client: client?._id,
                    error: 'Точката е вече добавена. Ще бъде преместена за регистрация'
                }, index)
                else dispatch(
                    setModal({
                        isOpen: true,
                        type: 'confirmation',
                        props: {
                            title: 'Точката е вече добавена!',
                            children: 'Ще бъде преместена за регистрация.',
                            buttonText: 'Разбрах',
                            onClick: () => {
                                handleChangeMultiple({
                                    requiresNetworkData: false,
                                    ...omit(obj, omitFields),
                                    status: 'registration',
                                    previousStatus: status,
                                    client: client?._id,
                                }, index)
                            },
                        },
                    })
                )

            } else if (previousClient?._id === client?._id && status !== 'inactive') {
                let error = 'Точката е вече добавена към този клиент!'
                if (status === 'active') error = 'Точката е вече добавена към този клиент и е активна!'
                else if (allRegistrationStatuses.includes(status)) error = 'Точката е вече добавена към този клиент и е в регистрация!'
                if (massCheck) handleChangeMultiple({ error }, index)
                else Alerts.success({ title: error })
            } else if (previousClient?._id !== client?._id || status === 'inactive') {
                if (['active', 'leaving', 'inactive', 'potential']?.includes(status)) {
                    if (massCheck) handleChangeMultiple({
                        _id,
                        ...omit(obj, omitFields),
                        requiresNetworkData: false,
                        status: ['active', 'leaving'].includes(status) ? 'transfer' : 'registration',
                        previousStatus: status,
                        client: client?._id,
                        previousClient: previousClient?._id,
                        entryEnd: moment()
                            .startOf('month')
                            .add(['active', 'leaving'].includes(status) ? 24 : 9, 'days')
                            .add(new Date().getDate() <= (['active', 'leaving'].includes(status) ? 25 : 10) ? 0 : 1, 'months')
                            .toDate(),
                        error: errorText?.[status] ?? ""
                    }, index)
                    else dispatch(
                        setModal({
                            isOpen: true,
                            type: 'confirmation',
                            props: {
                                title: 'Добавен към друг клиент',
                                children: (
                                    <div className="col" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <p>
                                            Тази точка е {statusText?.[status] ?? ""} фигурира в системата към:
                                        </p>
                                        <p>
                                            Клиент: <span style={{ fontWeight: '700' }}>{previousClient?.fullName}</span>
                                        </p>
                                        <p>
                                            ЕИК/ЕГН:{' '}
                                            <span style={{ fontWeight: '700' }}>{previousClient?.eic || previousClient?.pin || ''}</span>
                                        </p>
                                        <p>
                                            {['active', 'leaving'].includes(status)
                                                ? 'Може да продължите към пререгистрация или да преустановите действията'
                                                : 'Точката ще бъде регистрирана като нов обект'}
                                        </p>
                                    </div>
                                ),
                                buttonText: 'Разбрах',
                                onClick: () => {
                                    handleChangeMultiple({
                                        requiresNetworkData: false,
                                        ...omit(obj, omitFields),
                                        status: ['active', 'leaving'].includes(status) ? 'transfer' : 'registration',
                                        previousStatus: status,
                                        client: client?._id,
                                        previousClient: previousClient?._id,
                                        entryEnd: moment()
                                            .startOf('month')
                                            .add(['active', 'leaving'].includes(status) ? 24 : 9, 'days')
                                            .add(new Date().getDate() <= (['active', 'leaving'].includes(status) ? 25 : 10) ? 0 : 1, 'months')
                                            .toDate()
                                    }, index)
                                },
                            },
                        })
                    )
                } else {
                    if (massCheck) handleChangeMultiple({ _id, previousStatus: status, error: 'Точката не може да бъде регистрирана като нов обект' }, index)
                    else dispatch(
                        setModal({
                            isOpen: true,
                            type: 'confirmation',
                            props: {
                                title: 'Добавен към друг клиент',
                                children: (
                                    <div className="col" style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <p>Тази точка фигурира в системата към:</p>
                                        <p>
                                            Клиент: <span style={{ fontWeight: '700' }}>{previousClient?.fullName}</span>
                                        </p>
                                        <p>
                                            ЕИК/ЕГН:{' '}
                                            <span style={{ fontWeight: '700' }}>{previousClient?.eic || previousClient?.pin || ''}</span>
                                        </p>
                                        <p>
                                            Статус:{' '}
                                            <span style={{ fontWeight: '700' }}>
                                                {clientsStatus.find(({ value }) => value === status).label}
                                            </span>
                                        </p>
                                        <p>Точката не може да бъде регистрирана като нов обект</p>
                                    </div>
                                ),
                                buttonText: 'Разбрах',
                                onClick: () => handleChangeMultiple({ previousStatus: status }, index),
                            },
                        })
                    )
                }
            }
            if (onSuccess) onSuccess(index)
        }
    }))
}

export const isValidProducerSettings = ({ data, products, isDealer }) => {
    const isEdit = !!data?._id

    const requiredProducerFields = ['version', 'contractDate', 'paymentDays', 'invoicingMethod', 'balancingMethod'].filter((f) => !isDealer || f !== 'balancingMethod')
    const files = data?.producerSettings?.files?.map(({ type }) => type)

    if (
        !data?.producerSettings?.product ||
        productsPricesFields
            .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.producerSettings?.product === _id)?.name))
            .map(({ value }) => value)
            .some((field) => [undefined, null, 0].includes(data?.producerSettings?.price?.[field]))
    ) {
        return { isValid: false, error: 'Моля попълнете продукт и всички цени в производство' }
    } else if (
        requiredProducerFields.some((field) => !data?.producerSettings?.[field]) ||
        (data?.existingClient && !data?.producerSettings?.deliveryStart) ||
        (data?.producerSettings?.autoSign && !data?.producerSettings?.autoSignMonths) ||
        (data?.producerSettings?.notice && !data?.producerSettings?.noticeMonths) ||
        (['Фикс в лв. / МВТч', 'Таван в лв./МВТч'].includes(data?.balancingMethod) && !data?.balancingCeiling) ||
        (['Под и Таван в лв./МВТч'].includes(data?.balancingMethod) && (!data?.balancingCeiling || !data?.balancingFloor))
    ) {
        return { isValid: false, error: 'Моля попълнете всички задължителни полета в производство' }
    } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && !isEdit && ['letter of attorney', 'contract'].some((val) => !files?.includes(val))) {
        return { isValid: false, error: 'Моля добавете пълномощно и договор' }
    }
    return { isValid: true }
}

export const isValidSingleObject = ({ object, data, skipAlerts, validateProducerSettings, isMassAdd } = {}) => {
    const isDealer = checkIsDealer()
    const importantErrors = [
        "Точката е вече добавена към този клиент!",
        "Точката е вече добавена към този клиент и е активна!",
        "Точката е вече добавена към този клиент и е в регистрация!",
        "Точката не може да бъде регистрирана като нов обект"
    ]
    const measureType = data?.measureTypes?.find(({ _id }) => _id === object?.measureType)?.name || object?.measureType?.name
    const erp = data.erps.find(({ _id }) => _id === (object?.erp?._id || object?.erp))?.name
    if (object?.existingObject && !object?.activeFrom) return false
    else if (importantErrors.includes(object?.error)) return false
    else if ((object?.isProducer ? requiredCreateObjectProducerFields : requiredCreateObjectFields).some((field) => !object?.[field]) && !object?.existingObject) return false
    else if (object?.isProducer && erp !== 'ЕСО' && object?.producerDirectMember && !object.itnMMS) return false
    else if (['СТП', 'Битово'].includes(measureType) && !object.STPMeasure) return false
    else if (object?.consumerOwnPlant && !object?.consumerProductionPower) return false
    else if (!isMassAdd && ['active', 'leaving'].includes(object?.previousStatus) && ['transfer', 'registration'].includes(object.status) && !object?.comments?.at(0)?.text) {
        if (!skipAlerts) Alerts.error('За да добавите точка със статус "пререгистрация", моля добавете коментар')
        return false
    } else if (object?.isProducer && validateProducerSettings) {
        const { isValid, error } = isValidProducerSettings({ data: object, products: data?.products, isDealer })
        if (!isValid) {
            if (!skipAlerts) Alerts.error(error)
            return false
        }
    } else if (erp === 'ЕР Юг') {
        if ((!object?.itn?.startsWith('BG5521900') || object?.itn?.length !== 33) && ((process.env.REACT_APP_PLATFORM === 'Synergon' && !object?.isProducer) || object?.itn?.length !== 7)) return false
    } else if (erp === 'ЕСО') {
        if (!object?.itn?.startsWith('32X') || object?.itn?.length !== 16) return false
    } else {
        if (!object?.itn?.startsWith('32Z') || object?.itn?.length !== 16) return false
    }
    return true
}