// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-cells-comments-container {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Cells/Comments/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".client-cells-comments-container{\r\n  width: 100%;\r\n  justify-content: space-between;\r\n  padding-right: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
