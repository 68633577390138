import { default as Cells } from './Cells'
import { default as Forms } from './Forms'
import { default as Documents } from './Documents'
import { default as Invoices } from './Invoices'

const Client = {
  Cells,
  Forms,
  Documents,
  Invoices,
}

export default Client
