import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment'
import { invoicesTypes, producersTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { history } from '../config/store'
import { exportInvoicesAIGeneratedStatsFields, exportInvoicesGeneratedInfoFields, exportInvoicesGeneratedStatsFields } from 'config/constants'
import { exportBatchFiles, xlsxExport, renderCell } from 'utilities'
import { pick } from 'lodash'

export const getInvoicesFilters = (action$) => action$.pipe(
  ofType(invoicesTypes.GET_INVOICES_FILTERS),
  switchMap(({ payload }) => Api.get(`${URL}/invoices/filters`).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      if (payload?.type === 'producer') obs.next({ type: producersTypes.SET_FIELDS, payload: { ...pick(response, ['filters', 'enableSortFor']) } })
      else obs.next({ type: invoicesTypes.GET_INVOICES_FILTERS_SUCCESS, payload: response })
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      Alerts.error('Error!', err?.response?.error?.message || '')
      obs.next(stopLoading())
      obs.complete()
    }))
  ))
)

export const getInvoices = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.GET_INVOICES),
    debounceTime(100),
    switchMap(({ payload: { type, month = new Date(), ...payload } }) =>
      Api.post(
        `${URL}/invoices/browse`,
        JSON.stringify({
          type: type === 'equalizing' ? undefined : type,
          from: moment(month).startOf('month').toDate(),
          to: moment(month).endOf('month').add(1, 'days').startOf('month').toDate(),
          ...payload
        })
      ).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.forExport) {
              exportBatchFiles(response?.data?.map(({ url, name }) => ({ url, name: process.env.REACT_APP_PLATFORM === 'Synergon' ? `${name.split("_").at(-2)}.pdf` : name })))
            } else {
              obs.next(stopLoading())
              obs.next({ type: invoicesTypes.GET_INVOICES_SUCCESS, payload: response, })
            }
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getInvoice = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.GET_INVOICE),
    switchMap(({ payload, onSuccess }) =>
      Api.get(`${URL}/invoices/browse/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.invoice)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.error(err);
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createInvoice = (action$) => action$.pipe(
  ofType(invoicesTypes.CREATE_INVOICE),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/invoices`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (onSuccess) onSuccess(response)
        else {
          history.goBack()
          Alerts.success({ title: "Успешно създадена фактура!" })
        }
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const updateInvoice = (action$) => action$.pipe(
  ofType(invoicesTypes.UPDATE_INVOICE),
  switchMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/invoices`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (onSuccess) onSuccess(response)
        else {
          history.goBack()
          Alerts.success({ title: payload.deletedAt ? "Успешно анулирана фактура!" : "Успешно редактирана фактура!" })
        }
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const updateInvoiceSimple = (action$) => action$.pipe(
  ofType(invoicesTypes.UPDATE_INVOICE_SIMPLE),
  switchMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/invoices/simple`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (onSuccess) onSuccess(response)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const updateInvoiceMany = (action$) => action$.pipe(
  ofType(invoicesTypes.UPDATE_INVOICE_MANY),
  switchMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/invoices/many`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (onSuccess) onSuccess(response)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const updateInvoiceManyBonuses = (action$) => action$.pipe(
  ofType(invoicesTypes.UPDATE_INVOICE_MANY_BONUSES),
  switchMap(({ payload, onSuccess }) =>
    Api.put(`${URL}/invoices/bonuses`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        if (onSuccess) onSuccess(response)
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const generateInvoice = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.GENERATE_INVOICE),
    switchMap(({ payload, onSuccess }) =>
      Api.post(`${URL}/invoices/generation`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

// export const sendInvoice = (action$) =>
//   action$.pipe(
//     ofType(invoicesTypes.SEND_INVOICE),
//     switchMap(({ payload, onSuccess }) =>
//       Api.post(`${URL}/invoices/generate`, JSON.stringify(payload)).pipe(
//         switchMap(({ response }) =>
//           ActionsObservable.create((obs) => {
//             if (onSuccess) onSuccess(response)
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         ),
//         catchError((err) =>
//           ActionsObservable.create((obs) => {
//             Alerts.error('Error!', err?.response?.error?.message || '')
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         )
//       )
//     )
//   )

// export const uploadNetworkFile = (action$) =>
//   action$.pipe(
//     ofType(invoicesTypes.UPLOAD_NETWORK_FILE),
//     mergeMap(({ payload: { data, onSuccess } }) =>
//       Api.post(`${URL}/parser/upload-network`, JSON.stringify(data)).pipe(
//         switchMap(({ response }) =>
//           ActionsObservable.create((obs) => {
//             if (onSuccess) onSuccess()
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         ),
//         catchError((err) =>
//           ActionsObservable.create((obs) => {
//             Alerts.error('Error!', err?.response?.error?.message || '')
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         )
//       )
//     )
//   )
//
// export const uploadPeriodDataFile = (action$) =>
//   action$.pipe(
//     ofType(invoicesTypes.UPLOAD_PERIOD_DATA_FILE),
//     mergeMap(({ payload: { data, onSuccess } }) =>
//       Api.post(`${URL}/parser/period-data`, JSON.stringify(data)).pipe(
//         switchMap(({ response }) =>
//           ActionsObservable.create((obs) => {
//             if (onSuccess) onSuccess()
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         ),
//         catchError((err) =>
//           ActionsObservable.create((obs) => {
//             Alerts.error('Error!', err?.response?.error?.message || '')
//             obs.next(stopLoading())
//             obs.complete()
//           })
//         )
//       )
//     )
//   )

export const getInvoicesClient = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.GET_INVOICES_CLIENT),
    switchMap(({ payload: { client, page = 1, size = 10000 } }) =>
      Api.get(`${URL}/invoices/${client}/${page ?? 1}/${size}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: invoicesTypes.GET_INVOICES_CLIENT_SUCCESS, payload: response.invoices })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateInvoiceProgress = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.UPDATE_INVOICE_PROGRESS),
    switchMap(({ payload, onSuccess }) =>
      Api.put(`${URL}/progress`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.progress)
            // obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const exportInvoicesGeneratedStats = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.EXPORT_INVOICES_GENERATED_STATS),
    switchMap(({ payload }) =>
      Api.post(`${URL}/invoices/invoiceStatsObject`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            xlsxExport({
              fields: exportInvoicesGeneratedStatsFields,
              data: response.data,
              render: renderCell.exportInvoicesGeneratedStats,
            })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const exportInvoicesGeneratedInfo = (action$) => action$.pipe(
  ofType(invoicesTypes.EXPORT_INVOICES_GENERATED_INFO),
  switchMap(({ payload }) =>
    Api.post(`${URL}/invoices/invoiceStatsInfo`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) =>
        ActionsObservable.create((obs) => {
          xlsxExport({
            fields: exportInvoicesGeneratedInfoFields,
            data: response.data,
            render: renderCell.exportInvoicesGeneratedStats,
          })
          obs.next(stopLoading())
          obs.complete()
        })
      ),
      catchError((err) =>
        ActionsObservable.create((obs) => {
          Alerts.error('Error!', err?.response?.error?.message || '')
          obs.next(stopLoading())
          obs.complete()
        })
      )
    )
  )
)

export const exportInvoicesAIGeneratedStats = (action$) => action$.pipe(
  ofType(invoicesTypes.EXPORT_INVOICES_AI_GENERATED_STATS),
  switchMap(({ payload }) =>
    Api.post(`${URL}/invoices/invoiceAIStatsObject`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        xlsxExport({
          fields: exportInvoicesAIGeneratedStatsFields,
          data: response.data,
          render: renderCell.exportInvoicesGeneratedStats,
        })
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const ignoreObjectForMonth = (action$) =>
  action$.pipe(
    ofType(invoicesTypes.IGNORE_OBJECT_FOR_MONTH),
    switchMap(({ payload, onSuccess }) =>
      Api.put(`${URL}/invoices/itnIgnoreForMonth`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.progress)
            // obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )