import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../../actions'
import './styles.scss'

const Annex = ({ value, client, onButtonClick }) => {
  const dispatch = useDispatch()

  return (
    <div className="client-cells-annex-container row">
      {value ? (
        <>
          Да
          <div
            className="icon icon-arrow-right-2"
            onClick={() => {
              dispatch(
                setOverlay({
                  isOpen: true,
                  type: 'document',
                  props: { client, title: 'Анекси', documents: [] },
                })
              )
            }}
          />
        </>
      ) : (
        <Button.Raised text="Създай" onClick={() => onButtonClick && onButtonClick()} />
      )}
    </div>
  )
}

export default Annex
