import React, { useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { Inputs } from "components"
import { clientsTypesButtons, communicationTypes, financialTabs, headerModules, referencesTypes } from "config/constants"
import { useDispatch } from "react-redux"
import { startLoading, updateUserRole } from "actions"
import { mapKeys } from "lodash"

const Permissions = ({ hide, permission, onSuccess = () => { } }) => {
    const dispatch = useDispatch()
    const tabs = [
        { label: 'Модули', value: 'modules', options: headerModules },
        { label: 'Финанси', value: 'financial', options: financialTabs },
        { label: 'Клиенти', value: 'clients', options: clientsTypesButtons },
        { label: 'Справки', value: 'references', options: referencesTypes },
        { label: 'Данни', value: 'communication', options: communicationTypes },
    ]
    const [selectedTabLabel, setSelectedTabLabel] = useState(tabs[0].label)
    const selectedTab = tabs.find(({ label }) => label === selectedTabLabel)

    const [state, setState] = useState({ _id: permission._id, friendlyName: permission.friendlyName, ...permission.permissions })

    const handleSubmit = () => {
        dispatch(startLoading())
        dispatch(updateUserRole({
            payload: mapKeys(state, (value, key) => ['_id', 'friendlyName'].includes(key) ? key : `permissions.${key}`),
            onSuccess
        }))
    }

    return <div className="card-permissions-container">
        <div className="card-permissions-header row">
            <h2>Редакция на роля</h2>
            <div className="icon icon-close" onClick={hide} />
        </div>
        <div className="card-permissions-content">
            <div className="col">
                <span>Роля</span>
                <Input.Text
                    value={state.friendlyName ?? ''}
                    onChange={({ target: { value } }) => setState((state) => ({ ...state, friendlyName: value }))}
                />
            </div>
            <Button.UnderlinedTab buttons={tabs.map(({ label }) => label)} onChange={setSelectedTabLabel} value={selectedTabLabel} />
            <Inputs.Checkboxes
                col
                buttons={selectedTab.options}
                value={state[selectedTab.value]}
                onClick={(value) => setState((state) => ({ ...state, [selectedTab.value]: value }))}
            />
        </div>
        <div className="card-permissions-footer">
            <Button.Raised text='Запази' onClick={handleSubmit} />
        </div>
    </div>
}

export default Permissions