import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

const DatePickerComp = ({ value, placeholder, dateFormat = 'dd.MM.yyyy', className = '', ...props }) => {
  try {
    new Date(value).toISOString()
  } catch (e) {
    value = null
  }

  return (
    <div className={`inputs-datepicker-container ${className}`}>
      <DatePicker
        placeholderText={placeholder}
        dateFormat={dateFormat}
        selected={value ? moment(value).toDate() : null}
        calendarStartDay={1}
        {...props}
        renderCustomHeader={({
          date,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          decreaseMonth,
          increaseMonth,

          prevYearButtonDisabled,
          nextYearButtonDisabled,
          decreaseYear,
          increaseYear,
        }) => {
          const { showMonthYearPicker, showYearPicker } = props || {}
          if (showYearPicker) return null
          return (<div className="react-datepicker__header__row">
            <button
              aria-label="Previous Month"
              className={"react-datepicker__navigation react-datepicker__navigation--previous"}
              style={prevMonthButtonDisabled || (showMonthYearPicker && prevYearButtonDisabled) ? { visibility: "hidden" } : null}
              onClick={showMonthYearPicker ? decreaseYear : decreaseMonth}
            >
              <div className="icon icon-arrow-right" />
            </button>
            <span className="react-datepicker__current-month">
              {moment(date).format(showMonthYearPicker ? 'YYYY' : 'MMMM YYYY')}
            </span>
            <button
              aria-label="Next Month"
              className={"react-datepicker__navigation react-datepicker__navigation--next"}
              style={nextMonthButtonDisabled || (showMonthYearPicker && nextYearButtonDisabled) ? { visibility: "hidden" } : null}
              onClick={showMonthYearPicker ? increaseYear : increaseMonth}
            >
              <div className="icon icon-arrow-right" />
            </button>
          </div>)
        }}
      />
      {!props.inline && <div className={`icon icon-${props?.showTimeSelect ? 'clock-custom' : 'calendar-custom'}`} />}
    </div>
  )
}

export default DatePickerComp
