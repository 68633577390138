import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { userDaysOffTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const createUserDaysOff = (action$) => action$.pipe(
    ofType(userDaysOffTypes.CREATE_USER_DAYS_OFF),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/userDaysOff`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const updateUserDaysOff = (action$) => action$.pipe(
    ofType(userDaysOffTypes.UPDATE_USER_DAYS_OFF),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/userDaysOff`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const deleteUserDaysOff = (action$) => action$.pipe(
    ofType(userDaysOffTypes.DELETE_USER_DAYS_OFF),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/userDaysOff`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const getUserDaysOffByMonth = (action$) => action$.pipe(
    ofType(userDaysOffTypes.GET_USER_DAYS_OFF_BY_MONTH),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/userDaysOff/browse/month`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const getUserDaysOffByUser = (action$) => action$.pipe(
    ofType(userDaysOffTypes.GET_USER_DAYS_OFF_BY_USER),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/userDaysOff/browse/user`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)