import React from 'react'
import ReactPopperDropdown from 'react-popper-dropdown'
import './styles.scss'

const Dropdown = ({ options = [], onChange = () => {}, disabled = false, ...props }) => {
  return (
    <div className="inputs-dropdown-container">
      <ReactPopperDropdown
        choices={options?.map(({ value, label }) => ({ id: value, label }))}
        onValueChange={({ id }) => onChange(options.find(({ value }) => value === id))}
        enabled={!disabled}
        filterable={false}
        enableReset={false}
        maxHeight={300}
        {...props}
      />
    </div>
  )
}

export default Dropdown
