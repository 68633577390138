import { Button } from "@makedonski/admin-ui"
import { Alerts, Headers } from "@makedonski/socourt-utilities";
import { exportAjurAlternativeProducers, exportAjurProducersTemplated, getData, producersReferenceDelete, producersReferencesBrowse, producersReferencesSendEmails, setModal, setProducersFields, startLoading, stopLoading } from "actions";
import { Inputs, Shared } from "components";
import { producersReferencesFields as defaultFields, producersReferencesOptions } from "config/constants";
import { URL } from "config/settings";
import { useCollapsable, useQuery, useTableColumnsManager } from "hooks";
import { debounce, groupBy } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fileRequest, getTextForConfirmationModal, handleUploadedData, mapQuerySearch, renderCell } from "utilities";
import "./styles.scss"
import { ScrollSync } from "react-scroll-sync";
import Popup from "reactjs-popup";

const References = ({ TypeSelector }) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const { showCheckboxes, selectedCheckboxes, docs, totalDocs, nextPage, hasNextPage } = useSelector(({ producers }) => producers) || {}
  const {
    monthYear = moment().subtract(1, 'months').format('MM/YYYY'),
    sort: sortParam = '{}',
    filter: filterParam = '{"type":["producerSimpleByDays","producerSimpleByHours"]}',
    searchBy = '',
    handleUrlChangeMultiple
  } = useQuery()

  const { erps } = useSelector(({ data }) => data)
  useEffect(() => { if (!erps?.length) dispatch(getData('erps')) }, [erps])

  const availableSort = ['createdAt', 'data.sheet1.summary1.totalProduction', 'data.sheet1.summary1.singleUnitPrice', 'data.sheet1.summary1.total',]
  const availableFilters = {
    'type': { type: 'idDropdown', values: producersReferencesOptions.filter(({ value }) => process.env.REACT_APP_PLATFORM !== 'Synergon' || !['producerAdditionalPeriodData'].includes(value)).map(({ value, label }) => ({ _id: value, name: label })) },
    'clientIdentifier': { type: 'regex' },
    'fullName': { type: 'regex' },
    'itn': { type: 'regex' },
    'object': { type: 'regex' },
    'file': { type: 'boolean' },
    'htmlFile': { type: 'boolean' },
    'statsSent': { type: 'boolean' },
    'invoice': { type: 'boolean' },
    'additionalStat': { type: 'boolean' },
    'createdAt': { type: 'date' },
    'templatedInvoice': { type: 'boolean' },
    'templatedInvoiceConfirmed': { type: 'dropdown', values: ['Не', 'Проверена', 'Системна', 'Върната'], single: true },
    'erp': { type: 'idDropdown', values: erps, single: true },
    'timestamp': { type: 'date', includeTime: true },
    'invoiceNumber': { type: 'regex' },
    'fileUploadDate': { type: 'date', includeTime: true },
  }

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = useCallback((payload) => {
    dispatch(startLoading())
    dispatch(producersReferencesBrowse({ payload: { monthYear, ...searchQuery, ...payload } }))
  }, [dispatch, monthYear, searchQuery])

  const fetchDebounced = debounce(fetch, 300)
  useEffect(() => { fetch() }, [fetch])


  const handlePregenerate = async () => {
    try {
      dispatch(startLoading())
      const selected = selectedCheckboxes.map((_id) => docs.find((doc) => doc._id === _id))
      const grouped = groupBy(selected, 'type')
      const response = await Promise.all((Object.entries(grouped ?? {}).map(([type, docs]) => new Promise(async (resolve, reject) => {
        try {
          const response = await window.fetch(`${URL}/producers/generation`, {
            method: 'POST',
            headers: Headers.getWithAuth(),
            body: JSON.stringify({ monthYear, type, ids: docs?.map(({ objects }) => objects.join(",")) }),
          })
          if (!response.ok) resolve({ success: false, error: (await response.json()).error, type, docs })
          else resolve({ success: true })
        } catch (error) { return resolve({ success: false, error, type, docs }) }
      }))))
      const errors = response.filter(({ error }) => error).map(({ error, type, docs }) => ({ text: `${error?.message} - ${producersReferencesOptions.find(({ value }) => value === type)?.label}`, inner: docs?.map(({ clientData }) => clientData?.itn)?.join(' | ') }))
      if (errors.length) dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Грешка', children: getTextForConfirmationModal(errors), }, }))
      else Alerts.success({ title: 'Успешно прегенериране!' })
    } catch (error) { Alerts.error(error?.message) }
    finally {
      dispatch(stopLoading())
      dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
      fetch()
    }
  }

  const handleSend = ({ showNotPaid } = {}) => {
    dispatch(startLoading())
    dispatch(producersReferencesSendEmails({
      payload: { ids: selectedCheckboxes, showNotPaid },
      onSuccess: () => {
        fetch();
        dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
        Alerts.success({ title: 'Успешно изпратени имейли' })
      }
    }))
  }

  const handleTemplatedInvoiceConfirmed = () => dispatch(setModal({
    isOpen: true, type: 'templatedInvoiceConfirmed', modalStyles: { height: '95vh', width: '95vw' }, props: {
      ids: selectedCheckboxes, stats: selectedCheckboxes.map((_id) => docs.find((d) => d._id === _id)), monthYear, onSuccess: () => {
        fetch()
        dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
        Alerts.success({ title: 'Успешно потвърдени' })
      }
    }
  }))

  const handleExportAjurProducersTemplated = () => dispatch(setModal({
    isOpen: true,
    type: 'exportAjurProducersTemplated',
    props: {
      useInitialNumber: true,
      onSuccess: ({ invoiceMinDate, invoiceMaxDate, initialNumber }) => {
        dispatch(startLoading())
        dispatch(exportAjurProducersTemplated({
          payload: { monthYear, timestamp: { $gte: invoiceMinDate, $lte: invoiceMaxDate }, initialNumber },
          onSuccess: () => dispatch(setModal({ isOpen: false }))
        }))
      }
    }
  }))

  const handleExportAjurProducersAlternative = () => dispatch(setModal({
    isOpen: true,
    type: 'exportAjurProducersTemplated',
    props: {
      onSuccess: ({ invoiceMinDate, invoiceMaxDate }) => {
        dispatch(startLoading())
        dispatch(exportAjurAlternativeProducers({
          payload: { monthYear, timestamp: { $gte: invoiceMinDate, $lte: invoiceMaxDate } },
          onSuccess: () => dispatch(setModal({ isOpen: false }))
        }))
      }
    }
  }))

  const handleExportProducersPayments = () => dispatch(setModal({
    isOpen: true,
    type: 'exportAjurProducersTemplated',
    props: {
      onSuccess: async ({ invoiceMinDate, invoiceMaxDate }) => await fileRequest({
        URL: '/producers/exportStatsInvoices',
        payload: { monthYear, timestamp: { $gte: invoiceMinDate, $lte: invoiceMaxDate } },
      })
    }
  }))

  const handleExportProducersPaymentsAggregated = () => dispatch(setModal({
    isOpen: true,
    type: 'exportAjurProducersTemplated',
    props: {
      onSuccess: async ({ invoiceMinDate, invoiceMaxDate }) => await fileRequest({
        URL: '/producers/exportStatsInvoicesAggregated',
        payload: { monthYear, timestamp: { $gte: invoiceMinDate, $lte: invoiceMaxDate } },
      })
    }
  }))

  const handleExportTemplatedInvoices = ({ templatedInvoiceConfirmed }) => dispatch(setModal({
    isOpen: true,
    type: 'inputDateRange',
    props: {
      title: `Фактура Е - ${templatedInvoiceConfirmed} - Файлове`,
      onSuccess: ({ from, to }) => {
        fetch({
          forExport: 'templatedInvoice',
          pagination: false,
          templatedInvoiceConfirmed,
          timestamp: { $gte: from, $lte: to },
          select: 'templatedInvoice clientData.fullName monthYear',
          sort: { createdAt: -1 }
        })
        dispatch(setModal({ isOpen: false }))
      }
    }
  }))

  const handleDelete = () => dispatch(setModal({
    isOpen: true,
    type: 'confirmation',
    props: {
      title: 'Внимание - Изтриване на справки',
      children: <p style={{ textAlign: 'center' }}>Сигурни ли сте, че искате да изтриете избраните справки</p>,
      onClick: () => dispatch(producersReferenceDelete({
        payload: selectedCheckboxes,
        onSuccess: () => {
          fetch()
          dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          Alerts.success({ title: 'Успешно изтриване' })
        }
      }))
    }
  }))

  const { handleColumnsManager, currentFields: producersReferencesFields, lockedColumn, setLockedColumn, handleFilterPresets } = useTableColumnsManager({ defaultFields, type: 'producersReferences' })

  const collapsableRef = useRef(null)
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  const sortingComponents = producersReferencesFields.reduce((acc, { value, ...rest }) => ({
    ...acc,
    [value]: <Popup
      activeFilters={searchQuery}
      trigger={<div className="icon icon-arrow-down" />}
      closeOnDocumentClick={!['timestamp'].includes(value)}
      contentStyle={{ width: 'auto', ...(['timestamp', 'fileUploadDate'].includes(value) && { zIndex: 10 }) }}
    >
      {close => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={availableSort}
        availableFilters={availableFilters}
        sortProp={sortParam}
        filterProp={filterParam}
        lockedColumn={lockedColumn}
        setLockedColumn={setLockedColumn}
        handleColumnsManager={handleColumnsManager}
      />}
    </Popup>
  }), {})

  return <div className="references-producers-references-container">
    <div className="references-producers-references-header row">
      <TypeSelector />
      <Inputs.DatePicker
        customInput={<div className='row'>
          <h2 className="row">{moment(monthYear, 'MM/YYYY').format('м. MMMM')}</h2>
          <div className="icon icon-calendar-custom" />
        </div>}
        className="month-picker"
        showMonthYearPicker
        showFullMonthYearPicker
        value={moment(monthYear, 'MM/YYYY')}
        onChange={(monthYear) => handleUrlChangeMultiple({ 'monthYear': moment(monthYear).format('MM/YYYY') })}
        minDate={moment().subtract(10, 'years').toDate()}
        maxDate={moment().add(1, 'months').endOf('month').toDate()}
      />
      <div>
        <Inputs.SearchMulti
          search={filter?.[searchBy] || ''}
          searchBy={searchBy || 'clientIdentifier'}
          handleChange={({ search, searchBy: newSearchBy }) => {
            const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
            handleUrlChangeMultiple({
              searchBy: search ? newSearchBy : undefined,
              filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
            })
          }}
          options={[
            { value: 'clientIdentifier', label: 'ЕИК/ЕГН', client: true },
            { value: 'fullName', label: 'Име на фирма', shortLabel: 'Фирма', client: true },
          ]}
          popupText={{ secondary: ' ' }}
        />
      </div>
      <div className="row">
        <Inputs.RoundedButtons
          value={['producerSimpleByDays', 'producerSimpleByHours'].some((type) => filter?.type?.includes(type)) && 'producerSimple'}
          buttons={[{ label: 'Опростена', value: 'producerSimple' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerSimpleByDays', 'producerSimpleByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
        <Inputs.RoundedButtons
          value={['producerFullFByDays', 'producerFullFByHours'].some((type) => filter?.type?.includes(type)) && 'producerFullF'}
          buttons={[{ label: 'Пълна Ф', value: 'producerFullF' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerFullFByDays', 'producerFullFByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
        <Inputs.RoundedButtons
          value={['producerFullRBByDays', 'producerFullRBByHours'].some((type) => filter?.type?.includes(type)) && 'producerFullRB'}
          buttons={[{ label: 'Пълна РБ', value: 'producerFullRB' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerFullRBByDays', 'producerFullRBByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
        <Inputs.RoundedButtons
          value={['producerSimpleBalancingByDays', 'producerSimpleBalancingByHours'].some((type) => filter?.type?.includes(type)) && 'producerSimpleBalancing'}
          buttons={[{ label: 'ОБ', value: 'producerSimpleBalancing' }]}
          onChange={() => {
            const newFilter = { ...filter, type: ['producerSimpleBalancingByDays', 'producerSimpleBalancingByHours'] }
            handleUrlChangeMultiple({ filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined })
          }}
        />
      </div>
      {showCheckboxes
        ? <div className="row row-buttons">
          <Button.Raised text='Откажи' className="btn-other-actions" onClick={() => dispatch(setProducersFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
          {showCheckboxes?.action === 'send' ? <Button.Raised text="Изпрати" onClick={() => handleSend()} /> :
            showCheckboxes?.action === 'sendNotPaid' ? <Button.Raised text="Изпрати" onClick={() => handleSend({ showNotPaid: true })} /> :
              showCheckboxes?.action === 'delete' ? <Button.Raised text="Изтрий" onClick={handleDelete} /> :
                showCheckboxes?.action === 'pregenerate' ? <Button.Raised text="Прегенерирай" onClick={handlePregenerate} /> :
                  showCheckboxes?.action === 'templatedInvoiceConfirmed' ? <Button.Raised text="Потвърди" onClick={handleTemplatedInvoiceConfirmed} /> : null}
        </div>
        : <div className="row row-buttons">
          {handleFilterPresets()}
          <Popup
            contentStyle={{ width: 'auto' }}
            trigger={<div><Button.Raised text="Други действия" className="btn-other-actions" /></div>}
          >
            {close => <div className="popup-send-container">
              <div className="row">
                <h4>Изберете действие</h4>
                <Button.Icon name="plus" onClick={close} />
              </div>
              {process.env.REACT_APP_PLATFORM === 'Synergon' && <>
                <p onClick={() => {
                  dispatch(setModal({
                    isOpen: true, type: 'producersReferencesGenerate', props: {
                      title: 'Масова редакция - справки производители',
                      text: <p style={{ textAlign: 'center' }} >Тази функционалност работи само за продукт "Борса минус лв.". Качете екселски файл с колони "ИТН" и "Цена"</p>,
                      data: { monthYear: monthYear || moment().format('MM/YYYY') },
                      requireFile: true,
                      onClick: ({ monthYear, type, files }) => handleUploadedData({ files, dispatch, history, type: 'massEditProducerReferences', additionalProps: { monthYear, referenceType: type } })
                    }
                  }))
                  close()
                }}>Масова редакция</p>
              </>}
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'send' } })); close() }}>Изпрати имейл</p>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'sendNotPaid' } })); close() }}>Изпрати напомняне</p>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'pregenerate' } })); close() }}>Прегенерирай</p>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'templatedInvoiceConfirmed' } })); close() }}>Фактура Е - Потвърди</p>
              <p onClick={() => { fetch({ pagination: false }); dispatch(setProducersFields({ showCheckboxes: { action: 'delete' } })); close() }}>Изтрий справки</p>
              <p onClick={() => setExpanded(!isExpanded)}>Експорти</p>
              <div className="collapsable-exports" ref={collapsableRef}>
                {process.env.REACT_APP_AJUR_ALTERNATIVE
                  ? <p onClick={() => { handleExportAjurProducersAlternative(); close() }}>Счетоводен софтуер</p>
                  : <p onClick={() => { handleExportAjurProducersTemplated(); close() }}>Ажур Доставчици</p>}
                <p onClick={() => { handleExportProducersPayments(); close() }}>Плащания</p>
                {process.env.REACT_APP_PLATFORM === 'Proakt' && <p onClick={() => { handleExportProducersPaymentsAggregated(); close() }}>Плащания 2</p>}
                <p onClick={() => { fetch({ forExport: 'file', pagination: false, file: true, select: 'file', sort: { createdAt: -1 } }); close() }}>PDF-и</p>
                <p onClick={() => { fetch({ forExport: 'htmlFile', pagination: false, htmlFile: true, select: 'htmlFile', sort: { createdAt: -1 } }); close() }}>HTML-и</p>
                <p onClick={() => { fetch({ forExport: 'excel_1', pagination: false }); close() }}>Excel 1</p>
                <p onClick={() => { fetch({ forExport: 'excel_2', pagination: false }); close() }}>Excel 2</p>
                <p onClick={() => { handleExportTemplatedInvoices({ templatedInvoiceConfirmed: "Системна" }); close() }}>Фактура Е - Системна - Файлове</p>
                <p onClick={() => { handleExportTemplatedInvoices({ templatedInvoiceConfirmed: "Проверена" }); close() }}>Фактура Е - Проверена - Файлове</p>
              </div>
              <p onClick={() => { handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, }); close() }}>Изчисти всички филтри</p>
            </div>}
          </Popup>
          <Button.Raised text="Импорт" className="btn-import" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoicesFileUpload', props: { forProducers: true } }))} />
          <Button.Raised text='Генерирай' onClick={() => dispatch(setModal({
            isOpen: true, type: 'producersReferencesGenerate', props: {
              title: 'Издаване на справки производители',
              data: { monthYear: monthYear || moment().format('MM/YYYY') },
              onClick: ({ monthYear, type, }) => {
                const url = new URLSearchParams()
                url.append('type', type)
                url.append('monthYear', monthYear)
                history.push('/references-producers/references-generate?' + url.toString())
              }
            }
          }))} />
        </div>}
    </div>
    <div className="references-producers-references-content">
      <Shared.ConditionalWrapper
        condition={lockedColumn}
        wrapper={(children) => <ScrollSync>
          <div className="row row-locked">
            <Shared.Table
              columns={[defaultFields.find(({ value }) => value === lockedColumn.value)]}
              data={docs}
              renderCell={(row, field, options) => renderCell.producersReferences(row, field, { ...options, dispatch, fetch })}
              selectedCheckboxes={selectedCheckboxes}
              sortingComponent={sortingComponents}
              joinRightSide
              containerStyle={{ position: 'sticky', top: 0, left: 0, backgroundColor: '#fff', zIndex: 1 }}
              useSyncScroll

            />
            {children}
          </div>
        </ScrollSync>}
      >
        <Shared.Table
          columns={producersReferencesFields?.filter(({ value }) => value !== lockedColumn?.value)}
          data={docs}
          renderCell={(row, field, options) => renderCell.producersReferences(row, field, { ...options, dispatch, fetch })}
          handlePagination={() => hasNextPage && fetchDebounced({ page: nextPage })}
          useCheckboxes={!!showCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          onCheckboxChange={(value) => dispatch(setProducersFields({ selectedCheckboxes: value }))}
          disabledCheckboxes={['send'].includes(showCheckboxes?.action) ? docs?.filter(({ statsSent }) => statsSent).map(({ _id }) => _id) :
            ['sendNotPaid'].includes(showCheckboxes?.action) ? docs?.filter(({ statsSent, templatedInvoice }) => !statsSent || !!templatedInvoice?.pdfFile).map(({ _id }) => _id) :
              ['templatedInvoiceConfirmed'].includes(showCheckboxes?.action) ? docs?.filter(({ templatedInvoice }) => templatedInvoice).map(({ _id }) => _id) :
                []}
          sortingComponent={sortingComponents}
          joinLeftSide={!!lockedColumn}
          useSyncScroll={!!lockedColumn}
        />
      </Shared.ConditionalWrapper>
    </div>
    <div className="references-producers-references-footer row">
      <div className="references-producers-references-inner-footer row">
        <p className="row">Общо: {totalDocs || 0}</p>
        {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0}</p>}
      </div>
    </div>
  </div>
}

export default References