import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'
import "./styles.scss"
import moment from "moment";
import { uniq } from "lodash";
import { useQuery } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserDaysOff, getUserDaysOffByMonth, setModal, setOverlay, startLoading } from "actions";
import { Inputs, Shared } from "components";
import { holidays, screenEmployeesDetailsFields, screenEmployeesFields, userDaysOffOptions } from "config/constants";
import { fileRequest, mapQuerySearch, renderCell } from "utilities";
import { Button } from '@makedonski/admin-ui'
import Popup from "reactjs-popup";

const Employees = ({ }) => {
  const dispatch = useDispatch()

  const { currentUser } = useSelector(({ general }) => general) || {}
  const isMaster = useMemo(() => currentUser?.roles?.map(({ name }) => name).includes('master'), [currentUser])

  const { month = moment().format('MM/YYYY'), filter: filterParam = '{}', searchBy = '', handleUrlChangeMultiple } = useQuery()
  const [selected, setSelected] = useState(moment().format('YYYY-MM-DD'))
  useEffect(() => { if (moment(selected, 'YYYY-MM-DD').format('MM/YYYY') !== month) setSelected(moment(month, 'MM/YYYY').format('YYYY-MM-DD')) }, [month, selected])

  const availableFilters = {
    'fullName': { type: 'regex' },
    'userGroups': { type: 'regex' },
  }
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(undefined, filter, undefined, availableFilters) }), [filterParam])

  const [state, setState] = useState(null)
  const { users = [], dates = [], byDates = {} } = state ?? {}
  const fetch = useCallback(() => { dispatch(startLoading()); dispatch(getUserDaysOffByMonth({ payload: { month, ...searchQuery }, onSuccess: setState })) }, [month, searchQuery])
  useEffect(() => { fetch() }, [fetch])

  const handleOverlay = (user) => dispatch(setOverlay({ isOpen: true, type: 'userDaysOff', props: { month, user } }))

  const handleExport = async () => await fileRequest({ URL: '/userDaysOff/export', payload: { month } })

  const tableRef = useRef(null)
  const DetailsTable = useCallback(({ row }) => <Shared.Table
    rowHeight={60}
    columns={screenEmployeesDetailsFields}
    data={row.original.daysOff}
    renderCell={(currentRow, field, options) => renderCell.employeesDetails(currentRow, field, { ...options, fetch, isMaster, currentUser, length: row.original.daysOff.length, toggleRowExpanded: tableRef.current.toggleRowExpanded, })}
    containerStyle={{ maxHeight: 150 }}
    fixedWidth
  />, [users, fetch, isMaster, currentUser])

  return <div className="screen-employees-container">
    <div className="screen-employees-header row">
      <Inputs.DatePicker
        customInput={<div className='row'>
          <h2 className="row">{moment(month, 'MM/YYYY').format('м. MMMM YYYY')}</h2>
          <div className="icon icon-calendar-custom" />
        </div>}
        className="month-picker"
        showMonthYearPicker
        showFullMonthYearPicker
        value={moment(month, 'MM/YYYY')}
        onChange={(value) => handleUrlChangeMultiple({ month: moment(value).format('MM/YYYY') })}
        minDate={isMaster ? null : moment().startOf('month').toDate()}
        maxDate={isMaster ? null : moment().add(1, 'months').endOf('month').toDate()}
      />
      <Inputs.SearchMulti
        search={filter?.[searchBy] || ''}
        searchBy={searchBy || 'fullName'}
        handleChange={({ search, searchBy: newSearchBy }) => {
          const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
          handleUrlChangeMultiple({
            searchBy: search ? newSearchBy : undefined,
            filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
          })
        }}
        options={[{ value: 'fullName', label: 'Потребител' }, ...(isMaster ? [{ value: 'userGroups', label: 'Група/екип' }] : [])]}
        popupText={{ secondary: ' ' }}
      />
      <div className="row row-buttons">
        {isMaster && <div className="icon icon-export" onClick={handleExport} />}
        <Button.Raised text='Заяви отсъствие' onClick={() => dispatch(setModal({ isOpen: true, type: 'userDaysOff', props: { onClose: () => dispatch(setModal({ isOpen: false })), onSuccess: fetch } }))} />
      </div>
    </div>
    <div className="screen-employees-content row">
      <div className="screen-employees-left-container">
        <Shared.Table
          ref={tableRef}
          rowHeight={60}
          columns={screenEmployeesFields}
          data={users}
          renderCell={(row, field, options) => renderCell.employees(row, field, { ...options, toggleRowExpanded: tableRef.current.toggleRowExpanded, handleOverlay, isMaster, currentUser })}
          maxExpandHeight={350}
          fixedWidth
          expandField='daysOff'
        >{DetailsTable}</Shared.Table>
      </div>
      <div className="screen-employees-right-container">
        <Calendar
          key={`calender-${selected}`}
          className='screen-employees-calendar-container'
          locale="bg-BG"
          value={moment(selected, 'YYYY-MM-DD').toISOString()}
          onChange={(value) => setSelected(moment(value).format('YYYY-MM-DD'))}
          tileClassName={({ date }) => {
            const className = []
            if (dates.includes(moment(date).format('YYYY-MM-DD'))) className.push('marked')
            if (moment(date).format('YYYY-MM-DD') === selected) className.push('selected')
            if ([0, 6].includes(moment(date).day()) || holidays.includes(moment(date).format())) className.push('disabled')
            return className.join(' ')
          }}
          showNavigation={false}
          showNeighboringMonth={false}
        />
        <div className="screen-employees-right-content col">
          <h2>{moment(selected, 'YYYY-MM-DD').format('dddd DD.MM')}</h2>
          <div className="scroll-container">
            {byDates[selected]?.map((data) => {
              const { from, to, dates, type, description, user: { _id, fullName, coverPhoto }, substitute } = data
              return <div className="single-user-container row" key={_id}>
                <div className="icon icon-avatar no-pointer no-active" style={{ backgroundImage: `url(${coverPhoto || require('../../assets/images/default-user-avatar.jpg')})` }} />
                <div className="col col-main">
                  <p>
                    {fullName}
                    {(isMaster || (currentUser?._id === _id && moment().isBefore(moment(from), 'days'))) && <>
                      <span
                        className="icon icon-edit"
                        onClick={() => dispatch(setModal({
                          isOpen: true,
                          type: 'userDaysOff',
                          props: { data, onClose: () => dispatch(setModal({ isOpen: false })), onSuccess: () => fetch() }
                        }))}
                      />
                      <span
                        className="icon icon-delete"
                        onClick={() => dispatch(setModal({
                          isOpen: true,
                          type: 'confirmation',
                          props: {
                            title: 'Сигурни ли сте, че искате да изтриете отсъствие?',
                            onClick: () => { dispatch(startLoading()); dispatch(deleteUserDaysOff({ payload: { _id: data._id, }, onSuccess: () => fetch() })) }
                          }
                        }))}
                      />
                    </>}
                  </p>
                  <span>{`${dates.length} ${dates.length === 1 ? 'ден' : 'дни'}`}</span>
                  <div className="row">
                    <div className="icon icon-calendar-custom no-active no-pointer" />
                    <span>{moment(from).format('DD.MM.YYYY')}</span>
                  </div>
                  <div className="row">
                    <div className="icon icon-calendar-custom no-active no-pointer" />
                    <span>{moment(to).format('DD.MM.YYYY')}</span>
                  </div>
                </div>
                <div className="col col-secondary">
                  <div className="label-container row">
                    <p className="type">{userDaysOffOptions.find(({ value }) => value === type)?.label}</p>
                    {type === 'other' && description && <Popup
                      trigger={<div className="icon icon-info-custom" />}
                      on={['hover']}
                      position='left center'
                      contentStyle={{ width: 'auto', maxWidth: 200, padding: '4px 8px' }}
                    ><span style={{ wordWrap: 'break-word' }}>{description}</span></Popup>}
                  </div>
                  {substitute && <div className="col col-substitute">
                    <span>Заместващ:</span>
                    <div className="row">
                      <p>{substitute.fullName}</p>
                      <div className="icon icon-avatar no-pointer no-active" style={{ backgroundImage: `url(${substitute.coverPhoto || require('../../assets/images/default-user-avatar.jpg')})` }} />
                    </div>
                  </div>}
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Employees