import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import moment from 'moment'
import { Button } from '@makedonski/admin-ui'
import { ScrollSync } from 'react-scroll-sync'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty, flatten, mapKeys } from 'lodash'
import {
  startLoading,
  setClientsField,
  setClientsFields,
  getClientsStart,
  updateObject,
  setModal,
  setObjectsFields,
  exportClients,
  createComment,
  updateClient,
  getClientsFilters,
  confirmStatusesRegister
} from 'actions'
import { Inputs, Popup as PopupComponents, Shared } from 'components'
import { clientsTypesButtons, clientsSearchOptions, clientsObjectsFields, reasonsForLeaving, hideDealerFields, clientsStatus, defaultEntryEnd, invoiceReferencesOptions } from 'config/constants'
import { useQuery, usePrevious } from 'hooks'
import { asyncForEach, checkIsDealer, getStatusConfirmationModal, mapQuerySearch, renderCell } from 'utilities'
import './styles.scss'
import Popup from 'reactjs-popup'

const Clients = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    clients,
    clientsFields: clientsFieldsInitial,
    lockedColumn,
    hasNextPage,
    nextPage: page,
    showCheckboxes,
    selectedCheckboxes,
    availableFilters,
    availableSort,
    clientsCount,
  } = useSelector((state) => state.clients)
  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const isDealer = checkIsDealer()
  const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({
    ...acc,
    ...Object.fromEntries(Object.entries(permissions).map(([key, value]) => {
      const values = flatten(roles.map(({ permissions }) => permissions[key]))
      return [key, values]
    }))
  }), {}), [roles])
  const { showCheckboxes: showCheckboxesObjects, selectedCheckboxes: selectedCheckboxesObjects } = useSelector(({ objects }) => objects)
  const { type: clientsType, searchBy = '', sort: sortParam = '{}', filter: filterParam = '{}', clear, handleUrlChangeMultiple, } = useQuery({ type: clientsTypesButtons })

  const prevType = usePrevious(clientsType)
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])

  const clientsFields = useMemo(() => {
    return clientsFieldsInitial
      .filter(({ value }) => !isDealer || !hideDealerFields.includes(value))
      .filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType))
      .filter(({ platform }) => !platform || platform.includes(process.env.REACT_APP_PLATFORM))
  }, [clientsFieldsInitial, clientsType, isDealer])

  const hasAvailableFilters = useMemo(() => !isEmpty(availableFilters), [availableFilters])
  const searchQuery = useMemo(() => {
    return { enableSortFor: availableSort, filters: availableFilters, ...mapQuerySearch(sort, filter, clientsType, availableFilters) }
  }, [sortParam, filterParam, clientsType, hasAvailableFilters])

  const clearCheckBoxes = () => {
    dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
    dispatch(setObjectsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
  }

  const fetch = (options) => {
    const { pageOne, getFilters } = options || {}
    if (!hasAvailableFilters && getFilters) {
      dispatch(startLoading())
      dispatch(getClientsFilters())
    }
    else dispatch(getClientsStart({ page: prevType !== clientsType || pageOne ? 1 : page, ...searchQuery }))
  }

  useEffect(() => () => dispatch(setClientsFields({ clients: null, nextPage: 1, hasNextPage: false, showCheckboxes: false, selectedCheckboxes: [] })), [])
  useEffect(() => { fetch({ pageOne: true, getFilters: true }) }, [searchQuery])
  useEffect(() => { if (showCheckboxes) clearCheckBoxes() }, [clientsType])

  const { filterPresets = [] } = useSelector(({ general }) => general?.currentUser) || {}
  const selectedFilterPreset = useMemo(() => filterPresets.filter(({ type }) => type === 'clients').find(({ applied }) => applied), [filterPresets])
  useEffect(() => {
    if (selectedFilterPreset) {
      const { lockedColumn, clientsFields, searchBy, search, sort, filter } = selectedFilterPreset?.preset || {}
      dispatch(setClientsFields({ lockedColumn, clientsFields }))
      handleUrlChangeMultiple({ searchBy, search, sort, filter })
    }
  }, [selectedFilterPreset])


  const toggleAllRowsExpanded = (payload) => {
    tableRef?.current?.toggleAllRowsExpanded(payload)
    tableFrozenRef?.current?.toggleAllRowsExpanded(payload)
  }

  const handleSendDocumentsToERP = () => {
    dispatch(setModal({
      isOpen: true, type: 'inputDate', props: {
        title: "Дата на входиране",
        defaultValue: new Date(),
        onSuccess: (documentsSentDate, close) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => dispatch(updateObject({
            data: { _id, status: 'documents-sent', documentsSentDate },
            onSuccess: () => {
              if (i !== selectedCheckboxesObjects.length - 1) return
              fetch({ pageOne: true })
              close()
              clearCheckBoxes()
            }
          })))
        }
      }
    }))
  }

  const handleTerminateRegister = () => {
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Прекратяване на регистрация",
        textInput: true,
        dateInput: false,
        validate: (data) => data?.text?.length,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: { _id, status: 'potential' },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    payload: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: `Прекратена регистрация\nПричина: ${data.text}\nДата: ${moment().format('DD.MM.YYYY')}\nТочки: ${clients
                        .find(({ _id }) => selectedCheckboxes.includes(_id))
                        .objects.filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                        .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleActivateObjects = () => {
    const client = clients.find(({ _id }) => _id === selectedCheckboxes.at(0))
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Активиране на клиент/точки",
        textInput: true,
        validate: (data) => data?.text?.length && data?.date,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: moment(data.date).isBefore(moment())
                  ? { _id, status: 'active', activeFrom: data.date }
                  : { _id, activeFrom: data.date, confirmedOn: new Date() },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    payload: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: selectedCheckboxesObjects.length === client.objects.length
                        ? `Активиране на клиент\nПричина: ${data.text}\nДата: ${moment(data.date).format('DD.MM.YYYY')}`
                        : `Активиране на точки\nПричина: ${data.text}\nДата: ${moment(data.date).format('DD.MM.YYYY')}\nТочки: ${client.objects
                          .filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                          .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      if (client.objects.every(({ status }) => status === 'inactive')) dispatch(updateClient({
                        data: { ...client, contractSettings: { ...client.contractSettings, contractDate: data.date } }
                      }))
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleTerminateObjects = () => {
    const client = clients.find(({ _id }) => _id === selectedCheckboxes.at(0))
    dispatch(setModal({
      isOpen: true, type: 'objectStatus', props: {
        title: "Прекратяване на договор/точки",
        options: reasonsForLeaving.map((value) => ({ value, label: value })),
        validate: (data) => data?.dropdown && data?.date,
        onClick: (data) => {
          dispatch(startLoading())
          selectedCheckboxesObjects.forEach((_id, i) => {
            dispatch(
              updateObject({
                data: moment(data.date).isBefore(moment())
                  ? { _id, status: 'inactive', inactiveFrom: data.date }
                  : { _id, status: 'leaving', inactiveFrom: data.date, confirmedOn: new Date() },
                onSuccess: () => {
                  if (i !== selectedCheckboxesObjects.length - 1) return
                  dispatch(createComment({
                    payload: {
                      refType: 'Clients',
                      ref: selectedCheckboxes.at(0),
                      text: selectedCheckboxesObjects.length === client.objects.length
                        ? `Прекратяване на договор\nПричина: ${data.dropdown}\nДата: ${moment(data.date).format('DD.MM.YYYY')}`
                        : `Прекратяване на точки\nПричина: ${data.dropdown}\nДата: ${moment(data.date).format('DD.MM.YYYY')}\nТочки: ${client.objects
                          .filter(({ _id }) => selectedCheckboxesObjects.includes(_id))
                          .map(({ itn }) => itn).join(", ")}`
                    },
                    onSuccess: () => {
                      fetch({ pageOne: true })
                      clearCheckBoxes()
                      toggleAllRowsExpanded(false)
                    }
                  }))
                },
              })
            )
          })
        }
      }
    }))
  }

  const handleToActive = async () => {
    dispatch(setModal({
      isOpen: true, type: 'inputDate', props: {
        title: 'Активиране на точки',
        text: 'Моля изберете дата, от която точките ще започнат да получават енергия. Това действие ще промени статусите на "Активни", ще добави дати "Активна от" и ще одобри точките',
        startOfDay: true,
        startOfMonth: ['producersRegistration'].includes(clientsType),
        defaultValue: moment().startOf('month').toDate(),
        onSuccess: async (activeFrom) => {
          dispatch(startLoading())
          await asyncForEach(selectedCheckboxesObjects, async (_id) => await new Promise((resolve) => {
            dispatch(updateObject({ data: { _id, status: 'documents-received', activeFrom }, onSuccess: resolve }))
          }))
          dispatch(confirmStatusesRegister({
            payload: { ids: selectedCheckboxesObjects },
            onSuccess: ({ toActive, toBeActive, toPreregistration, toBePreregistration }) => {
              try {
                getStatusConfirmationModal({ toActive, toBeActive, toPreregistration, toBePreregistration, fetch })
              } catch (error) { console.error(error); }
            }
          }))
        }
      }
    }))
  }

  const handleResponsibleForRegistration = () => {
    const producers = clientsType.includes('producers')
    dispatch(setModal({
      isOpen: true, type: 'responsibleForRegistration', props: {
        producers,
        clients: selectedCheckboxes,
        currentlyResponsible: clients
          .filter(({ _id }) => selectedCheckboxes.includes(_id))
          .reduce((acc, { _id, responsibleForRegistration, responsibleForRegistrationProducers }) => ({ ...acc, [_id]: producers ? responsibleForRegistrationProducers : responsibleForRegistration }), {}),
        onSuccess: () => {
          fetch({ pageOne: true })
          clearCheckBoxes()
        }
      }
    }))
  }
  const handleForRegistration = () => {
    dispatch(startLoading())
    selectedCheckboxesObjects.forEach((_id, i) => dispatch(updateObject({
      data: {
        _id,
        status: 'registration',
        entryEnd: defaultEntryEnd,
        timesInPreregistration: 0,
        documentsSentDate: null,
        dpiGeneratedDate: null,
        dpiSignedDate: null,
        issue: null,
        additionalStatus: null,
        confirmedOn: null,
        activeFrom: null,
      },
      onSuccess: () => {
        if (i !== selectedCheckboxesObjects.length - 1) return
        fetch({ pageOne: true })
        clearCheckBoxes()
      }
    })))
  }

  const handleCreateGroup = () => {
    dispatch(setModal({ isOpen: true, type: 'createClientsGroup', props: { clients: selectedCheckboxes } }))
    clearCheckBoxes()
  }

  const handleRedistributeDealers = () => dispatch(setModal({
    isOpen: true, type: 'redistributeDealers', props: {
      clients: clients.filter(({ _id }) => selectedCheckboxes.includes(_id)),
      onSuccess: () => {
        fetch({ pageOne: true })
        clearCheckBoxes()
      }
    }
  }))


  const handleChangeStatus = () => {
    // dispatch(setModal({
    //   isOpen: true,
    //   type: 'clientsStatus',
    //   props: { clients: selectedCheckboxes, fetch: () => fetch({ isInitial: true }) },
    // }))
    // clearCheckBoxes()
  }

  const handleAddDocuments = () => {
    const url = new URLSearchParams(location.search)
    url.append('docs', showCheckboxes?.payload)
    url.append('client', selectedCheckboxes[0])
    history.push(`clients/create?` + url.toString())
  }

  const handleCancelAction = () => {
    clearCheckBoxes()
    toggleAllRowsExpanded(false)
  }

  const handleExportClients = ({ multi, contacts, invoiceSettings, invoiceSettingsConsumers }) => {
    const appendField = (appendAfter, newField, type = 'client') => {
      const newClientFields = type === 'client' ? [...payload.fields.clientsFields] : [payload.fields.objectsFields]
      const columnIndex = newClientFields.findIndex(({ value }) => value === appendAfter)
      newClientFields.splice(columnIndex + 1, 0, newField)
      return newClientFields
    }

    let payload = {
      payload: { ...searchQuery, pagination: false },
      fields: {
        clientsFields: [
          { label: 'ClientID', value: "_id" },
          ...clientsFields
        ],
        objectsFields: [
          { label: 'ObjectID', value: "_id" },
          ...clientsObjectsFieldsFiltered,
          { label: 'Група фактури', value: 'invoiceGroup' },
          { label: 'СТП Профил', value: 'STPMeasure' },
          { label: 'Прогнозна консумация', value: 'averageConsumption' },
          ...(process.env.REACT_APP_PLATFORM === 'Proakt' ? [{ label: 'Коментар', value: 'comment' }] : [])
        ]
      }
    }

    if (payload.fields.clientsFields.some(({ value }) => value === 'annex')) payload.fields.clientsFields = appendField('annex', { label: 'Файл', value: 'annexFile' })
    if (clientsType === 'list') {
      payload.fields.clientsFields = appendField('city', { label: 'Адрес', value: 'address' })
      payload.fields.clientsFields = appendField('version', { label: 'Номер на договор', value: 'contractNumber' })
      payload.fields.clientsFields = appendField('version', { label: 'Дата на договор', value: 'contractDate' })
    }

    if (process.env.REACT_APP_PLATFORM === 'Synergon' && !multi && ['active', 'register'].includes(clientsType)) payload.fields.clientsFields = [...payload.fields.clientsFields, { label: 'Енергия за добро', value: 'donationFond' }]

    if (invoiceSettingsConsumers) payload = {
      payload: {
        type: '',
        exportInvoiceReference: invoiceReferencesOptions.map(({ value }) => value),
        pagination: false,
      },
      fields: {
        clientsFields: [
          { label: 'ClientID', value: "_id" },
          { label: 'Статус', value: 'status' },
          { label: 'ЕИК', value: 'eic' },
          { label: 'ИН по ЗДДС', value: 'bulstat' },
          { label: 'Име клиент', value: 'fullName' },
          ...invoiceReferencesOptions
        ]
      }
    }

    dispatch(exportClients({ payload, multi, contacts, invoiceSettings }))
  }

  const handleDepositsLeaving = () => {
    const url = new URLSearchParams()
    url.append('ids', selectedCheckboxes)
    url.append('monthYear', moment().format('MM/YYYY'))
    history.push(`/deposits/create?` + url.toString())
  }

  const handleConsumptionAnalysis = () => {
    const url = new URLSearchParams()
    url.append('clients', selectedCheckboxes)
    history.push(`/consumption_analysis?` + url.toString())
  }

  const tableRef = useRef()
  const tableFrozenRef = useRef()
  const disabledObjects = useCallback((objects, action) => {
    switch (action) {
      case 'forRegistration':
        return objects.filter(({ status }) => ![
          'potential'
        ].includes(status)).map(({ _id }) => _id)
      case "sendDocumentsToERP":
        return objects?.filter(({ status }) => ![
          'registration',
          'preregistration',
          'transfer'
        ].includes(status)).map(({ _id }) => _id)
      case 'terminateRegister':
        return objects?.filter(({ status, confirmedOn }) => ![
          'registration',
          'documents-sent',
          'documents-received',
          'documents-received-missing',
          'preregistration',
          'transfer'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      case 'terminateObjects':
        return objects?.filter(({ status, confirmedOn }) => ![
          'active'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      case 'toActive':
        return objects?.filter(({ status }) => ![
          'registration',
          'documents-sent',
          'documents-received',
          'documents-received-missing',
          'preregistration',
          'transfer'
        ].includes(status)).map(({ _id }) => _id)
      case 'activateObjects':
        return objects?.filter(({ status, confirmedOn }) => ![
          'inactive'
        ].includes(status) || confirmedOn).map(({ _id }) => _id)
      default:
        return []
    }
  }, [])

  const clientsObjectsFieldsFiltered = useMemo(() => {
    return clientsObjectsFields
      .filter(({ includedIn }) => !includedIn || includedIn.includes(clientsType))
      .filter(({ value }) => !isDealer || !hideDealerFields.includes(value))
  }, [clientsType, isDealer])

  const ObjectsTable = useCallback(({ row }) => {
    return (<Shared.Table
      columns={clientsObjectsFieldsFiltered}
      data={row.original.objects || []}
      renderCell={(object, field) => renderCell.objects(object, field, { fetch, client: row.original })}
      containerStyle={{ maxHeight: 350 }}
      headerWidth={clientsObjectsFieldsFiltered.reduce((a, c) => a + (c.size || 300), 0)}
      outlines={row.original.objects?.reduce((acc, { _id, status, inactiveFrom }) => {
        return ['documents-received-missing']?.includes(status) || (status === 'leaving' && inactiveFrom) ? { ...acc, [_id]: '#fa4444' } : acc
      }, {})}
      useCheckboxes={!!showCheckboxesObjects}
      hideSelectAll={process.env.REACT_APP_PLATFORM === 'Energy Operations' ? !['terminateObjects'].includes(showCheckboxes.action) : true}
      selectedCheckboxes={selectedCheckboxesObjects}
      disabledCheckboxes={disabledObjects(row.original.objects, showCheckboxes.action)}
      onCheckboxChange={(value) => dispatch(setObjectsFields({ selectedCheckboxes: value }))}
    />)
  }, [clientsObjectsFieldsFiltered, showCheckboxesObjects, selectedCheckboxesObjects, fetch])


  const keyMap = {
    paymentType: 'intermediateInvoicingType',
    objectsCount: 'objects',
    dealer: 'dealers',
    'contractSettings.contractType': 'contractType',
    'contractSettings.deliveryStart': 'deliveryStart',
    'contractSettings.deliveryEnd': 'deliveryEnd',
    'contractVersion': "version",
    "contractSettings.penalty": 'penalty',
    "contractSettings.depositLeaving": 'depositLeaving',
    "contractSettings.notice": 'notice',
    "contractSettings.terms": 'terms',
    "price.base": "base",
    "price.market": "market",
    "price.taxPerObject": "taxPerObject",
    "price.night": "night",
    "leavingDeposit": "deposit"
  }

  const sortingComponents = clientsFields
    // .filter(({ sortable }) => sortable)
    // .filter(({ value }) => ['register', 'leaving'].includes(clientsType) || value !== 'status')
    .reduce((acc, { value }) => ({
      ...acc,
      [value]: <Popup
        contentStyle={{ width: 'auto' }}
        keepTooltipInside='.table-container'
        trigger={<div className="icon icon-arrow-down" />}
        activeFilters={{ ...mapKeys(searchQuery, (_, key) => keyMap[key] ?? key), sort: mapKeys(searchQuery.sort, (_, key) => keyMap[key] ?? key) }}
      >
        {close => <PopupComponents.ClientsFieldFilter
          hide={close}
          column={value}
          lockColumn={() => dispatch(setClientsField({ field: 'lockedColumn', value: lockedColumn?.value === value ? null : clientsFields.find((f) => f.value === value) }))}
          isLocked={lockedColumn?.value === value}
          keyMap={keyMap}
        />}
      </Popup>
    }), {})

  const clientsTypesButtonsFilter = useMemo(() => {
    return clientsTypesButtons
      .filter(({ value }) => !permissions || ["all", value].some(p => permissions?.clients?.includes(p)))
      .map((opt) => isDealer && opt.value === 'active' ? { ...opt, label: 'Успешно регистрирани' } : opt)
  }, [permissions])

  const clientsFiltered = useMemo(() => {
    const orderIndex = clientsStatus.reduce((acc, { value }, i) => ({ ...acc, [value]: i }), {})
    return clients
      ?.filter(({ objects }) => !filter.status || ['register', 'producersRegistration'].includes(clientsType) || (['leaving', 'producersLeaving'].includes(clientsType) && objects.every(({ status }) => filter.status.some(s => s === status))))
      ?.map(({ objects, ...c }) => ({
        ...c,
        objects: objects
          .filter(({ status, isClone }) => !filter.status || filter.status.includes(status) || (filter.status.includes('transfer') && isClone))
          .filter(({ isProducer }) => !((clientsType === 'register' && isProducer) || (clientsType === 'producersRegistration' && !isProducer)))
          .sort((a, b) => orderIndex[a.status] - orderIndex[b.status])
      }))
  }, [clients, filter])

  return (
    <div className="screen-clients-container">
      <div className="screen-clients-inner-container">
        <div className='screen-clients-header-types row'>
          <Inputs.RoundedButtons
            buttons={clientsTypesButtonsFilter}
            value={clientsType}
            onChange={({ value }) => {
              if (!selectedFilterPreset) clear()
              handleUrlChangeMultiple({ type: value, searchBy })
            }}
          />
        </div>
        <div className="screen-clients-header row">
          <Inputs.SearchMulti
            search={filter?.[searchBy] || ''}
            searchBy={searchBy || 'eic'}
            handleChange={({ search, searchBy: newSearchBy }) => {
              const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
              handleUrlChangeMultiple({
                searchBy: search ? newSearchBy : undefined,
                filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
              })
            }}
            options={clientsSearchOptions}
            popupText={{ secondary: ' ' }}
          />
          <Popup
            contentStyle={{ width: 'auto' }}
            trigger={<div className="row">
              <span className='selectedFilterPreset'>{selectedFilterPreset?.name}</span>
              <div className='btn-other-filters icon icon-filter' />
            </div>}
          >
            {close => <PopupComponents.ClientsFilterOptions hide={close} />}
          </Popup>
          <div className="row row-right">
            {showCheckboxes?.action && (<Button.Raised text="Откажи" className="btn-cancel" onClick={handleCancelAction} />)}
            {showCheckboxes?.action === 'addDocuments' ? (
              <Button.Raised text="Започни" onClick={handleAddDocuments} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'changeStatus' ? (
              <Button.Raised text="Промени" disabled={isEmpty(selectedCheckboxes)} onClick={handleChangeStatus} />
            ) : showCheckboxes?.action === 'createGroup' ? (
              <Button.Raised text="Създай група" disabled={isEmpty(selectedCheckboxes)} onClick={handleCreateGroup} />
            ) : showCheckboxes?.action === 'redistributeDealers' ? (
              <Button.Raised text="Преразпредели" disabled={isEmpty(selectedCheckboxes)} onClick={handleRedistributeDealers} />
            ) : showCheckboxes?.action === 'forRegistration' ? (
              <Button.Raised text="За регистрация" onClick={handleForRegistration} />
            ) : showCheckboxes?.action === 'terminateRegister' ? (
              <Button.Raised text="Прекратяване" onClick={handleTerminateRegister} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'activateObjects' ? (
              <Button.Raised text="Активиране" onClick={handleActivateObjects} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'terminateObjects' ? (
              <Button.Raised text="Прекратяване" onClick={handleTerminateObjects} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'toActive' ? (
              <Button.Raised text="Активирай" onClick={handleToActive} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'sendDocumentsToERP' ? (
              <Button.Raised text="Входирай" onClick={handleSendDocumentsToERP} disabled={isEmpty(selectedCheckboxesObjects)} />
            ) : showCheckboxes?.action === 'responsibleForRegistration' ? (
              <Button.Raised text="Разпредели" onClick={handleResponsibleForRegistration} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'depositsLeaving' ? (
              <Button.Raised text="Депозити" onClick={handleDepositsLeaving} disabled={isEmpty(selectedCheckboxes)} />
            ) : showCheckboxes?.action === 'consumptionAnalysis' ? (
              <Button.Raised text="Анализ" onClick={handleConsumptionAnalysis} disabled={isEmpty(selectedCheckboxes)} />
            ) : (
              <>
                <Popup
                  contentStyle={{ width: 'auto' }}
                  trigger={<div><Button.Raised className="btn-other-actions" text="Други действия" /></div>}
                >
                  {close => <PopupComponents.ClientsOptions hide={close} fetch={fetch} toggleAllRowsExpanded={toggleAllRowsExpanded} exportClients={handleExportClients} />}
                </Popup>
                <Popup
                  contentStyle={{ width: 'auto' }}
                  trigger={<div><Button.Raised className="btn-add-client" text="Добави клиент" /></div>}
                >
                  {close => <div className="popup-clients-options-container">
                    <div className="row">
                      <p>Изберете действие</p>
                      <Button.Icon name="plus" onClick={close} />
                    </div>
                    <p onClick={() => history.push('/clients/create?clientType=Консуматор')}>Консуматор</p>
                    <p onClick={() => history.push('/clients/create?clientType=Производител')}>Производител</p>
                    <p onClick={() => history.push('/clients/create?clientType=Смесен')}>Смесен</p>
                  </div>}
                </Popup>
              </>
            )}
          </div>
        </div>
        <div className="screen-clients-content">
          <Shared.ConditionalWrapper
            condition={lockedColumn}
            wrapper={(children) =>
              <ScrollSync>
                <div className="row row-locked">
                  <Shared.Table
                    ref={tableFrozenRef}
                    columns={[lockedColumn]}
                    data={clientsFiltered || []}
                    renderCell={(row, field) => renderCell.clients(row, field, { type: clientsType, fetch })}
                    sortingComponent={sortingComponents}
                    joinRightSide
                    containerStyle={{ position: 'sticky', top: 0, left: 0, backgroundColor: '#fff', zIndex: 1 }}
                    selectedCheckboxes={selectedCheckboxes}
                    outlines={{
                      ...(clients || []).reduce((acc, { _id, objects }) => {
                        return objects.some(({ status, inactiveFrom }) => {
                          return ['documents-received-missing'].includes(status) || (status === 'leaving' && inactiveFrom)
                        }) ? ({ ...acc, [_id]: '#fa4444' }) : acc
                      }, {})
                    }}
                    useSyncScroll
                  />
                  {children}
                </div>
              </ScrollSync>
            }
          >
            <Shared.Table
              ref={tableRef}
              columns={clientsFields?.filter(({ value }) => value !== lockedColumn?.value)}
              data={clientsFiltered || []}
              renderCell={(row, field, options) => renderCell.clients(row, field, {
                type: clientsType,
                fetch,
                toggleRowExpanded: tableRef.current.toggleRowExpanded,
                toggleFrozenRowExpanded: tableFrozenRef?.current?.toggleRowExpanded,
                isExpanded: options?.isExpanded,
              })}
              maxExpandHeight={550}
              useCheckboxes={!!showCheckboxes}
              sortingComponent={sortingComponents}
              handlePagination={() => hasNextPage && fetch()}
              joinLeftSide={!!lockedColumn}
              outlines={(clients || []).reduce((acc, { _id, objects, isDetailsConfirmed }) => {
                return objects.some(({ status, inactiveFrom }) => ['documents-received-missing'].includes(status) || (status === 'leaving' && inactiveFrom)) ? ({ ...acc, [_id]: '#fa4444' })
                  : ['register', 'producersRegistration',].includes(clientsType) && !isDetailsConfirmed ? ({ ...acc, [_id]: '#ffb612' })
                    : acc
              }, {})}
              useSyncScroll={!!lockedColumn}
              hideSelectAll={['consumptionAnalysis'].includes(showCheckboxes?.action)}
              allowSingle={['sendDocumentsToERP', 'forRegistration', 'addDocuments', 'terminateRegister', 'terminateObjects', 'toActive', 'activateObjects'].includes(showCheckboxes?.action)}
              disabledCheckboxes={
                ['sendDocumentsToERP'].includes(showCheckboxes.action) ? clients?.filter(({ objects }) => objects?.every(({ status }) => !['registration', 'preregistration', 'transfer'].includes(status))).map(({ _id }) => _id)
                  : ['terminateRegister', 'terminateObjects', 'activateObjects'].includes(showCheckboxes.action) ? clients?.filter(({ objects }) => objects.every(({ confirmedOn }) => confirmedOn)).map(({ _id }) => _id)
                    : ['depositsLeaving'].includes(showCheckboxes.action) ? clients.filter(({ invoices, penalties, contractSettings: { depositLeaving, penalty } }) => {
                      if (depositLeaving && invoices.length && penalty && penalties.length) return true
                      if (!depositLeaving && penalty && penalties.length) return true
                      if (depositLeaving && invoices.length && !penalty) return true
                      if (!depositLeaving && !penalty) return true
                      return false
                    }).map(({ _id }) => _id)
                      : ['consumptionAnalysis'].includes(showCheckboxes.action) ? clients.filter(({ price: { marketIsBase } }) => !selectedCheckboxes?.length ? false : marketIsBase !== selectedCheckboxes.map((selected) => clients.find(({ _id }) => selected === _id)?.price?.marketIsBase).includes(true)).map(({ _id }) => _id)
                        : []
              }
              selectedCheckboxes={selectedCheckboxes}
              onCheckboxChange={(value) => dispatch(setClientsFields({ selectedCheckboxes: value }))}
              onCheckboxClick={(client) => {
                if (['forRegistration', 'sendDocumentsToERP', 'terminateRegister', 'terminateObjects', 'toActive', 'activateObjects'].includes(showCheckboxes.action)) {
                  tableRef.current.toggleAllRowsExpanded(false)
                  tableRef.current.setRowExpanded(client._id, true)
                  const payload = selectedCheckboxes.includes(client?._id) ? null : client?._id
                  if (payload) {
                    dispatch(
                      setObjectsFields({
                        showCheckboxes: { action: showCheckboxes.action },
                        selectedCheckboxes: ['forRegistration', 'sendDocumentsToERP', 'terminateRegister'].includes(showCheckboxes.action)
                          ? client?.objects?.filter(({ _id }) => !disabledObjects(client?.objects, showCheckboxes.action).includes(_id)).map(({ _id }) => _id)
                          : []
                      })
                    )
                  } else {
                    if (['forRegistration', 'sendDocumentsToERP', 'terminateRegister'].includes(showCheckboxes.action)) dispatch(setObjectsFields({ selectedCheckboxes: [] }))
                    else {
                      tableRef.current.setRowExpanded(client._id, false)
                      dispatch(setObjectsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                    }
                  }
                }
              }}
            >
              {ObjectsTable}
            </Shared.Table>
          </Shared.ConditionalWrapper >
        </div>
        <div className="screen-clients-footer row">
          <div className="screen-clients-inner-footer row">
            <p className="row">
              Общо клиенти: <span> {clientsCount ?? <span className="inline-loader"></span>}</span>
            </p>
            {showCheckboxes && <p className="row">
              Избрани: <span> {selectedCheckboxes.length}</span>
            </p>}
          </div>
        </div>
      </div >
    </div >
  )
}

export default Clients
