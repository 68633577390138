import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { setOverlay } from 'actions'
import { getClientStatus } from 'utilities'
import { useQuery } from 'hooks'
import './styles.scss'

const Status = ({ client }) => {
  const dispatch = useDispatch()
  const { type } = useQuery()
  return (
    <div className="client-calls-status-container row status">
      <NavLink to={`/clients/${client?._id}`}>
        <div className="icon icon-folder-custom" />
      </NavLink>
      <div
        className="icon icon-details"
        onClick={() => dispatch(setOverlay({ isOpen: true, type: 'history', props: { client } }))}
      />
      {getClientStatus(client, type)}
    </div>
  )
}

export default Status
