// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-calls-status-container .icon {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Cells/Status/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ","sourcesContent":[".client-calls-status-container {\r\n  .icon {\r\n    margin-right: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
