import React, { useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from '../../../actions'
import "./styles.scss"

const InvoiceComment = ({ title = "Анулиране", buttonText = 'Продължи', onClick }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState()

  return <div className="modal-invoice-comment-container">
    <div className="modal-invoice-comment-header row">
      <h2>{title}</h2>
      <Button.Icon
        name="plus"
        size="26px"
        className="btn-close"
        onClick={() => dispatch(setModal({ isOpen: false }))}
      />
    </div>

    <div className="modal-invoice-comment-content">
      <span>Коментар</span>
      <Input.Text value={data || ''} onChange={({ target: { value } }) => setData(value)} />
    </div>
    <div className="modal-invoice-comment-footer">
      <Button.Raised
        text={buttonText}
        onClick={() => {
          if (onClick) onClick(data)
          dispatch(setModal({ isOpen: false }))
        }}
      />
    </div>
  </div>
}

export default InvoiceComment