import React, { useEffect, useRef } from "react"
import "./styles.scss"

const UnderlinedTab = ({ buttons, onChange }) => {
  const selector = useRef(null)
  let linkDetails = useRef({})

  const detectRoute = (name) => {
    let currentLoc = linkDetails.current[name]
    if (currentLoc) {
      currentLoc.classList.add('underlined-tab-active')
      let left = currentLoc.offsetLeft
      let width = currentLoc.getBoundingClientRect().width
      selector.current.setAttribute('style', `left: ${left + 16}px; width: ${width - 32}px;`)
      if (selector.current.offsetHeight === 0) setTimeout(() => { selector.current.classList.add('selector-add-height') }, 200)
      if (onChange) onChange(name)
    }
  }

  useEffect(() => {
    detectRoute(buttons[0])
  }, [])


  return <div className="task-management-underlined-tab-container">
    <div className="row">
      {buttons.map((el, i) => <div
        key={i}
        ref={ref => { linkDetails.current[el] = ref }}
        className='underlined-tab'
        onClick={() => detectRoute(el)}
      >
        {el}
      </div>)}
    </div>
    <div className="row active-underlined-selector-container">
      <div ref={selector} className='active-underlined-selector' />
    </div>
  </div>

}

export default UnderlinedTab