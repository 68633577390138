import { default as Period } from './Period'
import { default as Network } from './Network'
import { default as Corrections } from './Corrections'
import { default as Objects } from './Objects'

const Communication = {
    Period,
    Network,
    Corrections,
    Objects,
}
export default Communication