import React, { memo, useCallback, useEffect, useMemo, useState } from "react"
import "./styles.scss"
import io from "socket.io-client"
import { URL } from 'config/settings'
import moment from "moment"
import Shared from "components/Shared"
import Popup from "reactjs-popup"
import { useDispatch, useSelector } from "react-redux"
import { setModal, setOverlay, startLoading, stopLoading } from "actions"
import { Auth, Headers } from '@makedonski/socourt-utilities'

const AutoData = memo(() => {
  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null)
  const [state, setState] = useState({ autoData: 'disabled', files: [], newFileDownloaded: false, esoLoadData: [] })

  const socketInit = useCallback(() => {
    const controller = {
      'socket/init': ({ autoData, esoLoadData = [] }) => { setState((state) => ({ ...state, autoData, esoLoadData })) },
      'socket/autoDataUpdate': ({ autoData }) => { setState((state) => ({ ...state, autoData })) },
      'socket/newDownload': (payload) => {
        setState((state) => ({ ...state, files: [{ ...payload, timestamp: moment().format('HH:mm:ss') }, ...state.files.slice(0, 9)], newFileDownloaded: true }))
        setTimeout(() => setState((state) => ({ ...state, newFileDownloaded: false })), 1000)
      },
      'socket/esoLoadData': (payload) => { setState((state) => ({ ...state, esoLoadData: [...state.esoLoadData.slice(1), payload] })) }
    }
    const newSocket = io(URL, { query: `token=${localStorage.getItem('token')}`, transports: ['websocket'] })
    if (newSocket) {
      // console.log("Connecting", newSocket);
      newSocket.on("socket-message", ({ type, payload }) => {
        // console.log('Socket in:', { type, payload });
        if (controller[type]) controller[type](payload, newSocket)
      })
      setSocket(() => newSocket)
    }
  }, [])

  useEffect(() => {
    if (!socket) socketInit()
    return () => {
      if (socket?.connected) {
        // console.log("Disconnecting"); 
        socket.disconnect();
      }
    }
  }, [socket, socketInit])

  const { currentUser, overlay } = useSelector(({ general }) => general) || {}
  // const isMaster = useMemo(() => currentUser?.roles?.map(({ name }) => name).includes('master'), [currentUser])
  const permissionAutoData = useMemo(() => currentUser?.roles?.map(({ name }) => name)?.some((r) => ['master', 'directorSales', 'billing'].includes(r)), [currentUser])

  const handleEnableAutoData = () => {
    if (state.autoData === 'enabled' || !permissionAutoData) return;
    dispatch(setModal({
      isOpen: true,
      type: 'confirmation',
      props: {
        title: 'Включване на обмен данни',
        children: 'Сигурни ли сте, че искате да включите обмена на данни',
        onClick: async () => {
          dispatch(startLoading())
          try {
            const response = await (await fetch(`${URL}/data/enableAutoData`, { method: 'GET', headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(), })).json()
            setState((state) => ({ ...state, autoData: response.value }))
            dispatch(stopLoading())
          } catch (e) {
            console.error(e)
          }
        }
      }
    }))
  }

  useEffect(() => {
    if (overlay.isOpen && overlay.type === 'esoLoadData' && JSON.stringify(overlay.props) !== JSON.stringify({ esoLoadData: state.esoLoadData })) dispatch(setOverlay({ isOpen: true, type: 'esoLoadData', props: { esoLoadData: state.esoLoadData } }))
  }, [overlay, state.esoLoadData])

  return <div className="popup-auto-data-container row">
    <p>Обмен данни:</p>
    <Popup
      position='bottom right'
      contentStyle={{ width: 'auto', padding: '0 8px' }}
      on={['hover']}
      trigger={<div className={`auto-data-indicator ${state.autoData}`} onClick={handleEnableAutoData} />}
    >
      <p>{state.autoData === 'enabled' ? "Включен" : "Изключен"}</p>
    </Popup>
    <Shared.ConditionalWrapper
      condition={state.files.length}
      wrapper={(children) => <Popup
        position='bottom right'
        contentStyle={{ width: 'auto', padding: '0 8px' }}
        on={['hover']}
        trigger={children}
      >
        <div>
          {state.files.map(({ timestamp, erpName, filename, messageType }) => <p key={filename}>{timestamp} - {erpName} - {messageType}</p>)}
        </div>
      </Popup>}
    >
      <div className={`new-file-downloaded ${state.autoData} ${state.newFileDownloaded && 'green'}`} style={{ ...(state.files.length && { 'cursor': 'pointer' }) }} />
    </Shared.ConditionalWrapper>
    {state.esoLoadData.length > 0 && <div className="icon icon-statistics-custom" onClick={() => dispatch(setOverlay({ isOpen: true, type: 'esoLoadData', props: { esoLoadData: state.esoLoadData } }))} />}
  </div>
})

export default AutoData