import React, { useState, useEffect } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import './styles.scss'

const clients = [
  { _id: '1', fullName: 'aaa' },
  { _id: '2', fullName: 'bbb' },
  { _id: '3', fullName: 'ccc' },
  { _id: '4', fullName: 'ddd' },
  { _id: '5', fullName: 'eee' },
  { _id: '6', fullName: 'fff' },
  { _id: '7', fullName: 'ggg' },
  { _id: '8', fullName: 'hhh' },
  { _id: '9', fullName: 'kkk' },
  { _id: '10', fullName: 'lll' },
]

const teams = [
  {
    _id: '1',
    teamName: 'Team 1',
    teamMembers: [
      { _id: '1', fullName: 'aaa' },
      { _id: '2', fullName: 'bbb' },
    ],
  },
  {
    _id: '2',
    teamName: 'Team 2',
    teamMembers: [
      { _id: '3', fullName: 'ccc' },
      { _id: '4', fullName: 'ddd' },
    ],
  },
]

const NewTaskAssignees = ({ hide, onLoad }) => {
  const [view, setView] = useState('Отделен служител')
  const [showNewTeam, setShowNewTeam] = useState(false)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    onLoad && onLoad()
  }, [])

  useEffect(() => {
    setSelected([])
  }, [view])

  const renderTeam = (teamMembers) => (
    <div className="row row-assignees">
      {teamMembers?.map(({ coverPhoto, fullName }, i) => (
        <div key={`assignee-${i}`} className="single-assignee row">
          <div
            className="icon no-active no-pointer profile-image"
            style={{
              backgroundImage: `url(${
                coverPhoto ||
                require('../../../assets/images/default-user-avatar.jpg')
                  
              })`,
            }}
          />
          <p className="assignee-fullName">{fullName}</p>
        </div>
      ))}
    </div>
  )

  return (
    <div className="popup-new-task-assignees-container">
      <div className="row">
        <h4>Изпълнител:</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <Inputs.RadioButtons
        buttons={['Отделен служител', 'Екип']}
        value={view}
        onClick={(value) => setView(value)}
        col
      />
      <Input.Text placeholder="Търси" />
      <div className="scroll-container">
        {view === 'Отделен служител' ? (
          <>
            <Inputs.Checkboxes
              buttons={clients?.map(({ _id, fullName }) => ({
                value: _id,
                label: fullName,
              }))}
              value={selected}
              onClick={(value) => setSelected(value)}
              col
              includeCoverPhotos
            />
          </>
        ) : (
          <>
            <Inputs.Checkboxes
              buttons={teams?.map(({ _id, teamName, teamMembers }) => ({
                value: _id,
                label: `${teamName} (${teamMembers?.length ?? 0})`,
                children: renderTeam(teamMembers),
              }))}
              value={selected}
              onClick={(value) => {
                if (value.length) setSelected([value[value.length - 1]])
                else setSelected([])
              }}
              col
            />
          </>
        )}
      </div>
      <div className={`row row-footer ${showNewTeam && 'show-new-team'}`}>
        <Button.Raised
          text="Създай екип"
          className="btn-create-team"
          onClick={() => setShowNewTeam(true)}
        />
        <Button.Raised text="Избери" className="btn-apply" onClick={() => {}} />

        <div className={`new-team-container`}>
          <h4>Име на Екип</h4>
          <Input.Text />
          <div className="row row-new-team-buttons">
            <Button.Raised
              text="Откажи"
              className="btn-cancel"
              onClick={() => {
                setShowNewTeam(false)
              }}
            />
            <Button.Raised
              text="Запази"
              className="btn-save"
              onClick={() => {
                setShowNewTeam(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewTaskAssignees
