import React, { forwardRef, useMemo } from "react"
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async';
import Creatable from "react-select/creatable";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';

const CustomInput = props => < components.Input {...props} onPaste={(e) => { if (props.selectProps.onPaste) { props.selectProps.onPaste(e, props) } }} />
const DropdownSelect = forwardRef(function DropdownSelect({ async, paginate, disabled, invalid, fixedHight = true, options, rawValue, value, isMulti, creatable, placeholder = '', components, onPaste, ...props }, ref) {
  const { getOptionValue } = props

  const selectedValue = useMemo(() => {
    if (isMulti) return value?.map((value) => options?.find((opt) => (getOptionValue ? getOptionValue(opt) : opt?.value) === value)) ?? null
    else return options?.find((opt) => (getOptionValue ? getOptionValue(opt) : opt?.value) === value) ?? null
  }, [getOptionValue, isMulti, options, value])

  const styles = {
    container: (provided) => ({ ...provided, pointerEvents: 'auto' }),
    multiValueRemove: (provided) => ({ ...provided, pointerEvents: disabled ? 'none' : 'auto' }),
    control: (provided) => ({
      ...provided,
      fontSize: 14,
      height: fixedHight ? 36 : 'auto',
      minHeight: fixedHight ? 36 : 'auto',
      borderRadius: 6,
      borderColor: 'rgba(0,52,112,0.3)',
      '&:hover': { borderColor: 'rgba(0,52,112,0.3)' },
      boxShadow: 'none',
      ...(invalid && {
        borderColor: '#ffffff',
        '&:hover': { borderColor: '#ffffff' },
        boxShadow: '0 0 3px #fa4444',
      }),
      ...(disabled && {
        backgroundColor: 'rgba(239, 239, 239, 0.3)'
      })
    }),
    menu: (provided) => ({ ...provided, margin: 0, fontSize: 14, }),
    menuPortal: (provided) => ({ ...provided, zIndex: 100 }),
    valueContainer: (provider) => ({ ...provider, ...(fixedHight && { flexWrap: 'no-wrap', overflow: 'auto', ...(disabled && { color: 'rgba(0,52,112,0.3)' }) }) }),
    multiValue: (provider) => ({ ...provider, ...(fixedHight && { minWidth: 'none', ...(disabled && { color: 'rgba(0,52,112,0.3)' }) }) }),
    singleValue: (provider) => ({ ...provider, ...(disabled && { color: 'rgba(0,52,112,0.3)' }) }),
  }

  const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

  const onPasteDefault = async (e, props) => {
    const paste = (e.clipboardData || window.clipboardData).getData('text')

    let selected = props.options.find((opt) => opt?.[props.selectProps?.onPasteKey ?? 'value'] === paste)
    if (selected) {
      e.preventDefault()
      props.selectOption(selected)
      return
    }

    const { options } = await props.selectProps.loadOptions(paste)
    if (options.length) selected = options.find((opt) => opt?.[props.selectProps?.onPasteKey ?? 'value'] === paste)
    if (selected) {
      e.preventDefault()
      props.setValue(selected, 'create-option', selected)
    }
  }

  const render = (children) => <div className="inputs-dropdown-select-container" style={{ margin: '10px 0' }}>{children}</div>
  if (async && paginate && creatable) return render(<CreatableAsyncPaginate
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    additional={{ page: 1 }}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)

  if (async && paginate) return render(<AsyncPaginate
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    additional={{ page: 1 }}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)

  if (async && creatable) return render(<AsyncCreatableSelect
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    additional={{ page: 1 }}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)

  if (creatable) return render(<Creatable
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    additional={{ page: 1 }}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)

  if (async) return render(<AsyncSelect
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)

  return render(<Select
    ref={ref}
    styles={styles}
    menuPlacement="auto"
    noOptionsMessage={() => 'Няма информация'}
    options={options}
    value={rawValue || selectedValue}
    isMulti={isMulti}
    isDisabled={disabled}
    menuPortalTarget={document.body}
    placeholder={placeholder}
    components={{ Input: CustomInput, ...components }}
    onPaste={onPaste ?? onPasteDefault}
    {...props}
  />)
})

export default DropdownSelect