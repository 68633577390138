import React, { useEffect, useState, useRef } from "react"
import { Button } from '@makedonski/admin-ui'
import { Alerts } from "@makedonski/socourt-utilities/"
import { useDispatch } from "react-redux"
import { mapValues, omit, pick } from "lodash"
import { getInvoice, startLoading, createInvoice, updateInvoice, setModal, createComment } from "actions"
import { Invoices } from 'components'
import { intermediateInvoicesTypes } from 'config/constants'
import { useQuery } from "hooks"
import { toNumber } from "utilities"
import "./styles.scss"

const requiredFields = ["invoiceDate", "dateOfPayment", "invoicePeriodStart", "invoicePeriodEnd"]
const defaultResultsFields = ["tzoPrice", "excisePrice", "energy", "delivery", "energyPrice", "percentLastMonth", "invoiceLastMonthTotalWithoutVat", "energyPricePercent"]
const leavingFields = ['invoiceLastMonthConsumption', 'invoiceLastMonthTotal', 'leavingRatio', 'invoiceLastMonthDeposit', 'total', 'totalWithoutVat']

const IntermediateInvoicesCreate = ({ }) => {
    const dispatch = useDispatch()
    const clientFormRef = useRef(null)

    const [invoice, setInvoice] = useState({})
    const [originalResults, setOriginalResults] = useState({})
    const handleChange = (payload) => setInvoice({ ...invoice, ...payload })

    const { invoice: _id, leaving } = useQuery()
    const resultsFields = leaving ? leavingFields : defaultResultsFields
    useEffect(() => { if (_id) fetch() }, [])
    const fetch = () => {
        dispatch(startLoading())
        dispatch(getInvoice({
            payload: _id, onSuccess: (res) => {
                setInvoice({
                    ...pick(res, ["_id", "deletedAt", "type", "consecutiveNumber", ...requiredFields, 'pricing', 'exportedAjur', 'monthYear', 'defaultInvoiceGroup']),
                    client: res.client._id,
                    results: pick(res.results, resultsFields)
                })
                setOriginalResults(omit(res.results, resultsFields))
                clientFormRef?.current?.setClient({ ...res.client })
            }
        }))
    }




    const [showRequired, setShowRequired] = useState(false)
    const isValid = (payload) => {
        let flag = true
        const { client, results } = payload || {}
        if (requiredFields.some(f => !invoice?.[f])) {
            Alerts.error('Моля попълнете всички полета в "Основна информация"')
            flag = false
        } else if (!client) {
            Alerts.error('Моля изберете клеинт')
            flag = false
        } else if (resultsFields.some(f => [null, undefined, ""].includes(results?.[f]) || isNaN(Number(results?.[f])))) {
            Alerts.error('Моля попълнете всички полета в "Междинно плащане за ел.енергия"')
            flag = false
        }
        return flag
    }

    const onClear = () => {
        if (_id) fetch()
        else {
            setInvoice({})
            clientFormRef?.current?.setClient({})
        }
        setShowRequired(false)
    }

    const onSave = () => {
        const payload = { ...invoice, results: { ...originalResults, ...mapValues(invoice.results, (v) => isNaN(Number(v)) ? v : toNumber(v)) } }
        if (!isValid(payload)) {
            setShowRequired(true)
            return
        } else {
            dispatch(startLoading())
            if (_id) dispatch(updateInvoice({ payload }))
        }
    }

    const onDelete = () => {
        dispatch(startLoading())
        dispatch(updateInvoice({ payload: { _id, deletedAt: new Date() } }))
    }

    const onReverseDelete = () => {
        dispatch(startLoading())
        dispatch(updateInvoice({ payload: { _id, $unset: { deletedAt: 1 } } }))
    }

    const onDeleteAndSave = () => {
        const payload = { ...invoice, results: { ...originalResults, ...mapValues(invoice.results, (v) => isNaN(Number(v)) ? v : toNumber(v)) } }
        if (!isValid(payload)) {
            setShowRequired(true)
            return
        } else {
            dispatch(startLoading())
            dispatch(updateInvoice({
                payload: { _id, deletedAt: new Date(), },
                onSuccess: () => {
                    dispatch(startLoading())
                    dispatch(createInvoice({ payload: { ...omit(payload, ['_id', 'exportedAjur']), invoiceDate: new Date(), canceledInvoice: _id } }))
                }
            }))
        }
    }


    const handleComment = ({ comment, onSuccess } = {}) => {
        if (comment) dispatch(createComment({
            payload: { refType: 'Invoices', ref: _id, text: comment, type: 'default' },
            onSuccess
        }))
        else onSuccess()
    }

    const props = { invoice, handleChange, showRequired }
    const typeMap = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})

    return <div className="screen-intermediate-invoices-create-container">
        <div className="screen-intermediate-invoices-create-header row">
            <h2>{_id ? "Редакция" : "Създаване"}{leaving ? ` на депозитна фактура за напускане` : ` на междинна фактура - ${typeMap[invoice?.type]} - ${invoice?.consecutiveNumber}`}</h2>
            <div className="row row-buttons">
                <Button.Raised className="btn-clear" text="Откажи" onClick={onClear} />
                {invoice.deletedAt
                    ? <Button.Raised text='Отанулирай' onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { title: 'Отанулиране', onClick: (comment) => handleComment({ comment, onSuccess: onReverseDelete }) } }))} />
                    : <>
                        {_id && <>
                            <Button.Raised text="Анулирай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDelete }) } }))} />
                            <Button.Raised text="Анулирай и преиздай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDeleteAndSave }) } }))} />
                        </>}
                        <Button.Raised text={_id ? "Редактирай" : "Генерирай"} onClick={onSave} />
                    </>}
            </div>
        </div>
        <div className={`screen-intermediate-invoices-create-content col${invoice.deletedAt ? ' disabled' : ''}`}>
            <Invoices.Forms.Main {...props} />
            <Invoices.Forms.Client ref={clientFormRef} {...props} />
            {invoice.client && <>
                {leaving
                    ? <Invoices.Forms.Leaving {...props} />
                    : <Invoices.Forms.Intermediate  {...props} />}
                <Invoices.Forms.IntermediatePricing {...props} />
            </>}
        </div>
    </div>
}

export default IntermediateInvoicesCreate


