import { default as CollapsableRow } from './CollapsableRow'
import { default as ConditionalWrapper } from './ConditionalWrapper'
import { default as Table } from './Table'
import { default as BackButton } from './BackButton'
import { default as SortingComponent } from './SortingComponent'
import { default as Loader } from './Loader'
import { default as LoaderInline } from './LoaderInline'

const Shared = {
  CollapsableRow,
  ConditionalWrapper,
  Table,
  BackButton,
  SortingComponent,
  Loader,
  LoaderInline
}

export default Shared
