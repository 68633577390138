import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getData, getReferenceImports, setReferencesFields, startLoading } from 'actions'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import { referenceImportsFields, referencesInvoicesLength } from 'config/constants'
import { mapQuerySearch, renderCell } from 'utilities'
import './styles.scss'
import Popup from 'reactjs-popup'

const Imports = ({ }) => {
  const dispatch = useDispatch()
  const { reference } = useSelector((state) => state.references)

  const { erps } = useSelector(({ data }) => data)
  useEffect(() => { !erps && dispatch(getData('erps')) }, [])

  const { startDate: month, sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery({ length: referencesInvoicesLength })
  const availableSort = ["imported", "existing", "missing", "noPrice", "totalConsumption", "missingConsumption", "totalPrice", "createdAt",]
  const availableFilters = {
    name: { type: 'regex' },
    uploadedFrom: { type: 'regex' },
    erp: { type: "idDropdown", values: erps, single: true },
  }

  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = useCallback(() => {
    dispatch(startLoading())
    dispatch(getReferenceImports({ month, ...searchQuery }))
  }, [month, searchQuery, dispatch])

  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [dispatch, fetch])



  const sortingComponents = referenceImportsFields['consumption']?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
    ...acc,
    [value]: <Popup
      contentStyle={{ width: 'auto' }}
      keepTooltipInside='.table-container'
      trigger={<div className="icon icon-arrow-down" />}
      activeFilters={searchQuery}
    >
      {close => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={availableSort}
        availableFilters={availableFilters}
      />}
    </Popup>
  }), {})


  return (
    <div className="references-imports-container">
      <Shared.Table
        // fixedWidth
        columns={referenceImportsFields['consumption']}
        data={reference}
        renderCell={(row, value) => renderCell.references.imports(row, value, { fetch })}
        sortingComponent={sortingComponents}
      />
    </div>
  )
}

export default Imports
