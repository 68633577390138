import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../actions'
import './styles.scss'

const ClientSingleOptions = ({ hide, client }) => {
  const dispatch = useDispatch()
  return (
    <div className="popup-client-single-options-container">
      <div className="row">
        <h4>Преглед на</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'history', props: { client } }))
          hide()
        }}
      >
        История на дейността
      </p>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'files', props: { client } }))
          hide()
        }}
      >
        Файлове
      </p>
    </div>
  )
}

export default ClientSingleOptions
