import { Button } from "@makedonski/admin-ui"
import { ignoreObjectForMonth, producersReferencesGeneration, producersReferencesReady, setModal, startLoading } from "actions"
import { Shared } from "components"
import { producersReferencesOptions } from "config/constants"
import { useQuery } from "hooks"
import { uniqBy } from "lodash"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { NavLink, useHistory } from "react-router-dom"
import "./styles.scss"
import { generationOnSuccess, xlsxExport } from "utilities"

const errorsFields = [
    { label: 'ИТН', value: 'itn', size: 200 },
    { label: 'Име', value: 'fullName' },
    { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
    { label: 'Грешка', value: 'error', size: 400 },
    { label: '', value: 'button', size: 150 },
]

const ProducersReferencesGenerate = ({ }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { monthYear, type, show, handleUrlChangeMultiple } = useQuery()
    const [data, setData] = useState({})
    const { ready, missing, errors, zeroConsumptionObjects } = data || {}
    const [selected, setSelected] = useState([])

    const fetch = useCallback((payload = {}) => {
        dispatch(startLoading());
        dispatch(producersReferencesReady({
            payload: { monthYear, type, ...payload }, onSuccess: (res) => {
                setData((prevState) => {
                    const ready = payload.clientsToCheck ? [...(prevState?.ready ?? []), ...(res?.ready ?? [])] : res.ready
                    const zeroConsumptionObjects = payload.clientsToCheck ? [...(prevState?.zeroConsumptionObjects ?? []), ...(res?.zeroConsumptionObjects ?? [])] : res.zeroConsumptionObjects
                    return {
                        ...res,
                        ready: uniqBy(ready.reduce((acc, { mergeObjectsGroup, ...obj }) => {
                            if (mergeObjectsGroup) return [...acc, { ...obj, _id: mergeObjectsGroup?.objects.join(','), itn: mergeObjectsGroup?.name }]
                            return [...acc, obj]
                        }, []), '_id'),
                        zeroConsumptionObjects: uniqBy(zeroConsumptionObjects.reduce((acc, { mergeObjectsGroup, ...obj }) => {
                            if (mergeObjectsGroup) return [...acc, { ...obj, _id: mergeObjectsGroup?.objects.join(','), itn: mergeObjectsGroup?.name }]
                            return [...acc, obj]
                        }, []), '_id')
                    }
                })
                handleUrlChangeMultiple({ show: undefined })
                setSelected([])
            }
        }))
    }, [monthYear, type, dispatch])
    useEffect(() => { fetch() }, [fetch])

    const handleGeneration = () => {
        dispatch(startLoading())
        dispatch(producersReferencesGeneration({
            payload: { monthYear, type, ids: selected },
            onSuccess: generationOnSuccess
        }))
    }

    return <div className="screen-producers-references-generate-container">
        <div className="screen-producers-references-generate-header row">
            <h2>Издаване на справки производители</h2>
            <p>Тип: <span>{producersReferencesOptions.find(({ value }) => value === type)?.label ?? '--'}</span></p>
            <p>Месец: <span>{monthYear ? moment(monthYear, 'MM/YYYY').format('MMMM YYYY') : '--'}</span></p>
            <div className="icon icon-edit" onClick={() => dispatch(setModal({
                isOpen: true, type: 'producersReferencesGenerate', props: {
                    title: 'Издаване на справки производители',
                    data: { type, monthYear: monthYear || moment().format('MM/YYYY') },
                    onClick: handleUrlChangeMultiple
                }
            }))}>
            </div>
            <div className="row row-buttons">
                {show === 'errors' && <div className="icon icon-export" onClick={() => xlsxExport({
                    fields: errorsFields.filter(({ value }) => !['button'].includes(value)),
                    data: errors,
                    render: (row, field) => field === 'eic' ? (row?.client?.eic ?? row?.client?.pin ?? '--') : (row?.client?.[field] ?? row?.[field] ?? '--')
                })} />}
                <Button.Raised text="Генерирай" disabled={!selected.length} onClick={handleGeneration} />
            </div>
        </div>
        <div className="screen-producers-references-generate-content">
            {show === 'missing' && <Shared.Table
                fixedWidth
                data={missing}
                columns={[
                    { label: 'ИТН', value: 'itn' },
                    { label: 'Периодични данни', value: 'PeriodData' },
                    { label: 'Прогноза', value: 'ProducerForecast' },
                    { label: 'Цени на група', value: 'CustomGroupData' },
                    { label: 'Почасова цена клиент', value: 'CustomProducerPricing' },
                ]}
                renderCell={(row, field) => {
                    if (field === 'itn') return row?.[field] ?? '--'
                    else return row?.foundIn?.includes(field) ? "Да" : "Не"
                }}
            />}
            {show === 'errors' && <Shared.Table
                fixedWidth
                data={errors}
                columns={errorsFields}
                renderCell={(row, field, { index }) => {
                    if (field === 'button') return <Button.Raised
                        text="Игнорирай"
                        className="cancel"
                        onClick={() => dispatch(setModal({
                            isOpen: true,
                            type: 'confirmation',
                            props: {
                                title: "Игнорирай",
                                children: `За точката няма да бъдат издадени справки за месец ${moment(monthYear, 'MM/YYYY').format('MMMM YYYY')}`,
                                buttons: <div className="row" style={{ justifyContent: 'space-between' }}>
                                    <Button.Raised text="Откажи" className="cancel" onClick={() => dispatch(setModal({ isOpen: false }))} />
                                    <div style={{ heigh: 1, width: 10 }}></div>
                                    <Button.Raised text="Потвърди" onClick={() => dispatch(ignoreObjectForMonth({
                                        payload: { itn: row.itn, ignoreMonth: monthYear },
                                        onSuccess: () => {
                                            const newErrors = [...errors]
                                            newErrors.splice(index, 1)
                                            dispatch(setModal({ isOpen: false }))
                                            setData((prev) => ({ ...prev, errors: newErrors }))
                                        }
                                    }))} />
                                </div>
                            }
                        }))} />
                    else if (field === 'eic') return row?.client?.eic ?? row?.client?.pin ?? '--'
                    else if (field === 'fullName') return <div className="row">
                        <NavLink to={`/clients/${row?.client?._id}`}>
                            <div className="icon icon-folder-custom" style={{ marginRight: 14 }} />
                        </NavLink>
                        {row?.client?.[field] ?? '--'}
                    </div>
                    else return row?.client?.[field] ?? row?.[field] ?? '--'
                }}
            />}
            {[undefined, 'zeroConsumptionObjects'].includes(show) && <Shared.Table
                fixedWidth
                data={show === undefined ? ready : show === 'zeroConsumptionObjects' ? zeroConsumptionObjects : []}
                columns={[
                    { label: 'ИТН', value: 'itn' },
                    { label: 'Име', value: 'fullName' },
                    { label: 'ЕИК/ЕГН', value: 'eic' },
                ]}
                renderCell={(row, field) => {
                    if (field === 'eic') return row?.client?.eic ?? row?.client?.pin ?? '--'
                    else if (field === 'fullName') return <div className="row">
                        <NavLink to={`/clients/${row?.client?._id}`}>
                            <div className="icon icon-folder-custom" style={{ marginRight: 14 }} />
                        </NavLink>
                        {row?.client?.[field] ?? '--'}
                    </div>
                    else return row?.client?.[field] ?? row?.[field] ?? '--'
                }}
                useCheckboxes
                selectedCheckboxes={selected}
                onCheckboxChange={setSelected}
            />}
        </div>
        <div className="screen-producers-references-generate-footer row">
            {[
                { label: `Общо: ${ready?.length ?? 0}`, value: undefined },
                { label: `Мерене 0: ${zeroConsumptionObjects?.length ?? 0}`, value: 'zeroConsumptionObjects' },
                { label: `Грешки: ${errors?.length ?? 0}`, value: 'errors' },
                { label: `Липсващи: ${missing?.length ?? 0}`, value: 'missing' },
            ].map(({ value, label }, index) => <div
                key={index}
                className="row"
                style={{ ...(show === value && { backgroundColor: '#e87a1e' }) }}
                onClick={() => { handleUrlChangeMultiple({ show: value }); setSelected([]) }}
            >
                <p>{label}</p>
                <div className="icon icon-arrow-right-2-white" />
            </div>)}
            {[undefined, 'zeroConsumptionObjects'].includes(show) && <div className="row" >
                <p>Избрани: {selected.length}</p>
            </div>}
            <div className="row" onClick={() => fetch()}>
                <p>Обнови всичко</p>
                <span className='icon icon-refresh-white' />
            </div>
            <div className="row" onClick={() => fetch({ clientsToCheck: errors.map(({ client: { _id } }) => _id) })}>
                <p>Обнови само грешки</p>
                <span className='icon icon-refresh-white' />
            </div>

        </div>
    </div>
}

export default ProducersReferencesGenerate