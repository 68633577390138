import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { importsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const checkImportsExist = (action$) =>
  action$.pipe(
    ofType(importsTypes.CHECK_IMPORTS_EXIST),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/imported-files/check-by-name`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const checkNetworkImportsExist = (action$) =>
  action$.pipe(
    ofType(importsTypes.CHECK_NETWORK_IMPORTS_EXIST),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/parser/check-network`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteImport = (action$) =>
  action$.pipe(
    ofType(importsTypes.DELETE_IMPORT),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.delete(`${URL}/imported-files`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            else obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteNetworkImport = (action$) =>
  action$.pipe(
    ofType(importsTypes.DELETE_NETWORK_IMPORT),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.delete(`${URL}/parser/network`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            else obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getObjectsCount = (action$) =>
  action$.pipe(
    ofType(importsTypes.GET_OBJECTS_COUNT),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/parser/count-objects`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            // obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const importMarketData = (action$) =>
  action$.pipe(
    ofType(importsTypes.IMPORT_MARKET_DATA),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/parser/upload-stock`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const importSTPData = (action$) =>
  action$.pipe(
    ofType(importsTypes.IMPORT_STP_DATA),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/stp-data/upload`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const importLeaving = (action$) =>
  action$.pipe(
    ofType(importsTypes.IMPORT_LEAVING),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/clients/leaving`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response?.leaving || {})
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const confirmLeaving = (action$) =>
  action$.pipe(
    ofType(importsTypes.CONFIRM_LEAVING),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/clients/leaving/confirm`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response || [])
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )



export const importDeposits = (action$) =>
  action$.pipe(
    ofType(importsTypes.IMPORT_DEPOSITS),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/imports/deposits`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )



export const confirmInterests = (action$) =>
  action$.pipe(
    ofType(importsTypes.IMPORT_INTERESTS),
    switchMap(({ payload, onSuccess, onFail }) =>
      Api.post(`${URL}/imports/interests`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (onFail) onFail(err.response)
            else Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const confirmInterestsPayments = (action$) => action$.pipe(
  ofType(importsTypes.IMPORT_INTERESTS_PAYMENTS_OLD),
  switchMap(({ payload, onSuccess, onFail }) =>
    Api.post(`${URL}/imports/interests/payments`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.payload)
        obs.next(stopLoading())
        obs.complete()
      })), catchError((err) =>
        ActionsObservable.create((obs) => {
          if (onFail) onFail(err.response)
          else Alerts.error('Error!', err?.response?.error?.message || '')
          obs.next(stopLoading())
          obs.complete()
        })
      ))
  )
)

export const importProducersData = (action$) => action$.pipe(
  ofType(importsTypes.IMPORT_PRODUCERS_DATA),
  switchMap(({ path, payload, onSuccess }) =>
    Api.post(`${URL}/parser/${path}`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.payload)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      })))
  )
)

export const importAjurNumbers = (action$) => action$.pipe(
  ofType(importsTypes.IMPORT_AJUR_NUMBERS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/uploadMissing`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      })))
  )
)

export const importAjurProducersNumbers = (action$) => action$.pipe(
  ofType(importsTypes.IMPORT_AJUR_PRODUCERS_NUMBERS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/uploadMissingProducers`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      })))
  )
)