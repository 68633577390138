export const contractsTypes = {
  CREATE_CONTRACT: 'contracts/CREATE_CONTRACT',
  UPDATE_CONTRACT: 'contracts/UPDATE_CONTRACT',
}

export const createContract = (payload) => ({
  type: contractsTypes.CREATE_CONTRACT,
  payload,
})

export const updateContract = (payload) => ({
  type: contractsTypes.UPDATE_CONTRACT,
  payload,
})
