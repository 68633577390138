export const producersTypes = {
    SET_FIELDS: 'producers/SET_FIELDS',
    PRODUCERS_REFERENCES_READY: 'producers/GET_PRODUCERS_REFERENCES_READY',
    PRODUCERS_REFERENCES_GENERATION: 'producers/GET_PRODUCERS_REFERENCES_GENERATION',
    PRODUCERS_REFERENCES_BROWSE: 'producers/PRODUCERS_REFERENCES_BROWSE',
    PRODUCERS_REFERENCES_BROWSE_SUCCESS: 'producers/PRODUCERS_REFERENCES_BROWSE_SUCCESS',

    PRODUCERS_REFERENCES_SEND_EMAILS: 'producers/PRODUCERS_REFERENCES_SEND_EMAILS',

    PRODUCERS_REFERENCE_UPDATE: 'producers/PRODUCERS_REFERENCE_UPDATE',
    PRODUCERS_REFERENCE_UPDATE_RESTRICTED: 'producers/PRODUCERS_REFERENCE_UPDATE_RESTRICTED',
    PRODUCERS_REFERENCE_UPDATE_MANY: 'producers/PRODUCERS_REFERENCE_UPDATE_MANY',

    PRODUCERS_REFERENCE_SINGLE: 'producers/PRODUCERS_REFERENCE_SINGLE',

    PRODUCERS_REFERENCE_DELETE: 'producers/PRODUCERS_REFERENCE_DELETE',

    PRODUCERS_INVOICES_BROWSE: 'producers/PRODUCERS_INVOICES_BROWSE',
    PRODUCERS_INVOICES_BROWSE_SUCCESS: 'producers/PRODUCERS_INVOICES_BROWSE_SUCCESS',

    PRODUCERS_INVOICE_CREATE: 'producers/PRODUCERS_INVOICE_CREATE',
    PRODUCERS_INVOICE_UPDATE: 'producers/PRODUCERS_INVOICE_UPDATE',

    PRODUCERS_INVOICE_GENERATE_MANUAL: 'producers/PRODUCERS_INVOICE_GENERATE_MANUAL',

    PRODUCERS_TEMPLATED_INVOICE: 'producers/PRODUCERS_TEMPLATED_INVOICE',
    PRODUCERS_CHECK_CLIENT_INVOICES: 'producers/PRODUCERS_CHECK_CLIENT_INVOICES',
}

export const setProducersFields = (payload) => ({
    type: producersTypes.SET_FIELDS,
    payload,
})

export const producersReferencesReady = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCES_READY,
    ...payload
})

export const producersReferencesGeneration = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCES_GENERATION,
    ...payload
})

export const producersReferencesBrowse = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCES_BROWSE,
    ...payload
})

export const producersReferenceUpdate = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCE_UPDATE,
    ...payload
})

export const producersReferenceUpdateRestricted = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCE_UPDATE_RESTRICTED,
    ...payload
})

export const producersReferenceUpdateMany = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCE_UPDATE_MANY,
    ...payload
})

export const producersReferenceDelete = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCE_DELETE,
    ...payload
})

export const producersReferenceSingle = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCE_SINGLE,
    ...payload
})

export const producersReferencesSendEmails = (payload) => ({
    type: producersTypes.PRODUCERS_REFERENCES_SEND_EMAILS,
    ...payload
})

export const producersInvoicesBrowse = (payload) => ({
    type: producersTypes.PRODUCERS_INVOICES_BROWSE,
    ...payload
})

export const producersInvoiceCreate = (payload) => ({
    type: producersTypes.PRODUCERS_INVOICE_CREATE,
    ...payload
})

export const producersInvoiceUpdate = (payload) => ({
    type: producersTypes.PRODUCERS_INVOICE_UPDATE,
    ...payload
})

export const handleProducersTemplatedInvoice = (payload) => ({
    type: producersTypes.PRODUCERS_TEMPLATED_INVOICE,
    ...payload
})

export const producersCheckClientInvoices = (payload) => ({
    type: producersTypes.PRODUCERS_CHECK_CLIENT_INVOICES,
    ...payload
})

export const producersInvoiceGenerateManual = (payload) => ({
    type: producersTypes.PRODUCERS_INVOICE_GENERATE_MANUAL,
    ...payload
})