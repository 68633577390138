import { Alerts } from "@makedonski/socourt-utilities"
import { setModal } from "actions"
import { history, store } from "config/store"
import { xlsxExport } from "./helpers"

export const generationOnSuccess = ({ stats, errors }) => {
    if (!errors.length) Alerts.success({ title: "Успешно генериране!" })
    else store.dispatch(setModal({
        isOpen: true, type: 'confirmation', props: {
            title: 'Приключило генериране!',
            children: <div className="col" style={{ alignItems: 'center' }}>
                <p>Успешно генерирани: {stats.length}</p>
                <div className="row">
                    <p>Грешки: {errors.length}</p>
                    <div
                        style={{ height: 35, width: 35, border: "2px solid #002157", backgroundColor: "#ffffff", borderRadius: 6, backgroundSize: "70%", marginLeft: 15 }}
                        className="icon icon-export"
                        onClick={() => xlsxExport({
                            fields: [{ label: 'Грешка', value: 'error' }],
                            data: errors.map((error) => ({ error })),
                        })}
                    />
                </div>
            </div>,
        }
    }))
    history.goBack()
}