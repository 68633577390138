import React, { useEffect, useMemo } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { startLoading, setReferencesFields, getReferenceMarket } from 'actions'
import { useQuery } from 'hooks'
import { MarketTables } from './'
import { referenceMarketLength } from 'config/constants'
import './styles.scss'

const Market = ({}) => {
  const dispatch = useDispatch()
  const { reference } = useSelector(({ references }) => references)
  const { period, startDate } = useQuery({ period: referenceMarketLength })

  const payload = {
    from: startDate || moment().startOf(period).toDate(),
    to: moment(startDate).endOf(period).toDate(),
  }

  const fetch = () => dispatch(getReferenceMarket(payload))
  useEffect(() => {
    dispatch(startLoading())
    fetch()
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [period, startDate])

  const groupReference = useMemo(() => {
    const months = reference.reduce((acc, cur) => {
      const month = moment(cur.date).format('MM')
      return { ...acc, [month]: [...(acc[month] || []), cur] }
    }, {})
    const sums = Object.keys(months).map((key) =>
      months[key].reduce(
        (acc, cur) => ({
          ...acc,
          date: moment(key, 'MM').toDate(),
          dailyPriceSum: (acc?.dailyPriceSum || 0) + (cur?.dailyPrice || 0),
          dailyVolumeSum: (acc?.dailyVolumeSum || 0) + (cur?.dailyVolume || 0),
          baseSum: (acc?.baseSum || 0) + (cur?.base || 0),
          peakSum: (acc?.peakSum || 0) + (cur?.peak || 0),
          offPeakSum: (acc?.offPeakSum || 0) + (cur?.offPeak || 0),
          availableDays: months[key].length,
        }),
        {}
      )
    )
    return sums
      .map(({ date, availableDays, dailyPriceSum, dailyVolumeSum, baseSum, peakSum, offPeakSum }) => ({
        date,
        dailyPrice: dailyPriceSum / availableDays,
        dailyVolume: dailyVolumeSum / availableDays,
        base: baseSum / availableDays,
        peak: peakSum / availableDays,
        offPeak: offPeakSum / availableDays,
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date))
  }, [period, reference])

  const props = { reference: period === 'year' ? groupReference : reference }

  return (
    <div className="references-market-container row">
      <div className="references-market-content">
        <div className="card">
          <div className="row row-header">
            <span>Период на доставка: 19.04.2021</span>
            <span>Време: Денонощие</span>
            <div className="icon icon-export" />
          </div>
          <MarketTables.Graph {...props} />
        </div>
        <MarketTables.Main {...props} />
        <MarketTables.Products {...props} />
        <MarketTables.Hourly {...props} />
      </div>
    </div>
  )
}

export default Market
