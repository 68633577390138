import React from "react"
import "./styles.scss"

const TaskBoardPermissions = ({ hide, permissions }) => {
    const [users, userGroups] = permissions.reduce(([users, userGroups], { user, userGroup }) => {
        if (user) return [[...users, user], userGroups]
        if (userGroup) return [users, [...userGroups, userGroup]]
        return [users, userGroups]
    }, [[], []])
    return <div className="card-task-board-permissions-container">
        <div className="card-task-board-permissions-header row">
            <h2>Участници{permissions.reduce((acc, { user, userGroup }) => acc + (user ? 1 : userGroup ? userGroup.users.length : 0), 0)}</h2>
        </div>
        <div className="card-task-board-permissions-container">
            {userGroups.map(({ _id, name, users }) => <div key={_id} className="single-group-container">
                <div className="single-group-header">{name}</div>
                <div className="single-group-content">
                    {users.map(({ _id, fullName, coverPhoto }) => <div key={_id} className="single-user-container row">
                        <div className="icon icon-avatar no-active no-pointer" style={{ backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})` }} />
                        <span>{fullName}</span>
                    </div>)}
                </div>
            </div>)}
            <div className="single-group-container">
                <div className="single-group-header">Други</div>
                <div className="single-group-content">
                    {users.map(({ _id, fullName, coverPhoto }) => <div key={_id} className="single-user-container row">
                        <div className="icon icon-avatar no-active no-pointer" style={{ backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})` }} />
                        <span>{fullName}</span>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
}

export default TaskBoardPermissions