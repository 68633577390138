// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-cells-edit-cell-container {
  width: 100%;
  justify-content: space-between;
}
.client-cells-edit-cell-container .icon-edit.add-margin {
  margin-right: 15px;
}
.client-cells-edit-cell-container .inputs-text-edit-container input,
.client-cells-edit-cell-container .inputs-text-edit-container .react-popper-dropdown {
  height: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Cells/EditCell/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,8BAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AACI;;EAEE,YAAA;AACN","sourcesContent":[".client-cells-edit-cell-container {\r\n  width: 100%;\r\n  justify-content: space-between;\r\n  .icon-edit.add-margin {\r\n    margin-right: 15px;\r\n  }\r\n  .inputs-text-edit-container {\r\n    input,\r\n    .react-popper-dropdown {\r\n      height: 25px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
