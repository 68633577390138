import React, { useEffect, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "hooks"
import { Inputs } from "components"
import { applyUserFilterPreset, createUserFilterPreset, deleteUserFilterPreset, startLoading } from "actions"

const TableFilterPresets = ({ hide, defaultFields, currentFields, setCurrentFields, lockedColumn, setLockedColumn, type, selectedFilterPreset, setPreset }) => {
    const defaultPreset = {
        fields: defaultFields,
        lockedColumn: null,
        search: null,
        searchBy: null,
        sort: null,
        filter: null,
    }
    const dispatch = useDispatch()
    const { search, searchBy, sort, filter } = useQuery()
    const { filterPresets: filters = [] } = useSelector(({ general }) => general?.currentUser) || {}

    const [saveFilter, setSaveFilter] = useState(false)
    const [filterName, setFilterName] = useState('')
    useEffect(() => { setFilterName('') }, [saveFilter])

    return <div className="popup-table-filter-presets-container">
        <div className="row">
            <h4>Филтърна комбинация</h4>
            <div className="icon icon-close" onClick={hide} />
        </div>
        <div className="scroll-container">
            <Inputs.RadioButtons
                buttons={filters?.filter((f) => f.type === type)?.map(({ _id, name }) => ({ value: _id, label: name }))}
                value={selectedFilterPreset?._id}
                onClick={(_id) => {
                    dispatch(startLoading())
                    dispatch(applyUserFilterPreset({ payload: { _id, type } }))
                }}
                col
                renderButton={({ label, value }) => <span
                    style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}
                >
                    {label}
                    <span
                        style={{ display: 'inline-block', width: 20, height: 20, marginLeft: 15 }}
                        className="icon icon-delete"
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(startLoading())
                            dispatch(deleteUserFilterPreset({
                                payload: { _id: value },
                                onSuccess: () => { if (selectedFilterPreset?._id === value) setPreset(defaultPreset) }
                            }))
                        }} />
                </span>}
            />
        </div>
        <div className="buttons">
            <Button.Raised
                text="Запази филтърна комбинация"
                className="btn-show-save"
                onClick={() => setSaveFilter(true)}
            />
            <Button.Raised
                text="Изчисти филтри"
                onClick={() => {
                    dispatch(startLoading())
                    dispatch(applyUserFilterPreset({ payload: { _id: null, type }, onSuccess: () => { dispatch(startLoading()); setPreset(defaultPreset) } }))
                }}
            />
            <Button.Raised text="Покажи всички колони" onClick={() => setCurrentFields(defaultFields)} />

            <div className={`save-filter ${saveFilter && 'show'}`}>
                <h4>Име на филтър</h4>
                <Input.Text value={filterName || ''} onChange={({ target: { value } }) => setFilterName(value)} />
                <div className="row">
                    <Button.Raised text="Откажи" onClick={() => setSaveFilter(false)} />
                    <Button.Raised
                        text="Запази"
                        className="btn-save"
                        onClick={() => {
                            dispatch(startLoading())
                            dispatch(createUserFilterPreset({ payload: { name: filterName, preset: { fields: currentFields, lockedColumn, search, searchBy, sort, filter }, type }, onSuccess: () => { setSaveFilter(false) } }))
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default TableFilterPresets