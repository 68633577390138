export const penaltyTypes = {
    GET_PENALTIES: 'penalties/GET_PENALTIES',

    UPDATE_PENALTY: 'penalties/UPDATE_PENALTY',

    SEND_PENALTIES: 'penalties/SEND_PENALTIES',

    CALCULATE_PENALTY: 'penalties/CALCULATE_PENALTY',
}

export const getPenalties = (payload) => ({
    type: penaltyTypes.GET_PENALTIES,
    ...payload
})

export const updatePenalty = (payload) => ({
    type: penaltyTypes.UPDATE_PENALTY,
    ...payload
})

export const sendPenalties = (payload) => ({
    type: penaltyTypes.SEND_PENALTIES,
    ...payload
})

export const calculatePenalty = (payload) => ({
    type: penaltyTypes.CALCULATE_PENALTY,
    ...payload
})