
import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { commissionsTypes, referencesTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { renderCell, xlsxExport } from 'utilities'
import { commissionsFields } from 'config/constants'
import { mapKeys, omit } from 'lodash'

export const getCommission = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSION),
    debounceTime(300),
    switchMap(({ payload, onSuccess, onError }) =>
        Api.post(`${URL}/commission/getSingle`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                if (onError) onError(err?.response?.error?.message)
                else Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)


export const getCommissions = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSIONS),
    debounceTime(300),
    switchMap(({ payload, onSuccess, forExport }) =>
        Api.post(`${URL}/commission/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                if (forExport) xlsxExport({
                    fields: commissionsFields,
                    data: response.commissions.docs,
                    render: renderCell.commissionsExport
                })
                obs.next({ type: commissionsTypes.GET_COMMISSIONS_SUCCESS, payload: response.commissions })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const getCommissionsReference = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSIONS_REFERENCE),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/browseInquiries`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: response.commissions })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const getCommissionsReferenceDetails = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSIONS_REFERENCE_DETAILS),
    switchMap(({ payload, client, onSuccess }) =>
        Api.post(`${URL}/commission/clientCommissions`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.clientCommissions)
                obs.next({
                    type: commissionsTypes.GET_COMMISSIONS_REFERENCE_DETAILS_SUCCESS, payload: {
                        client,
                        clientCommissions: response.clientCommissions
                    }
                })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const getCommissionsCreatedReference = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSIONS_REFERENCE_CREATED),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/browseCreated`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                obs.next({
                    type: referencesTypes.GET_REFERENCE_SUCCESS, payload: {
                        ...response.commissions,
                        docs: response.commissions.docs.map(d => ({
                            ...omit(d, 'commissions'),
                            objects: d.commissions.map((c, i) => ({ ...c, _id: `${i + 1}` }))
                        }))
                    }
                })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const getCommissionsCreatedPayments = (action$) => action$.pipe(
    ofType(commissionsTypes.GET_COMMISSIONS_REFERENCE_PAYMENTS),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/browsePayments`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                if (payload.user) obs.next({ type: referencesTypes.GET_REFERENCE_COMMISSIONS_PAYMENTS_SUCCESS, payload: response.commissions?.docs?.[0] })
                else obs.next({ type: referencesTypes.GET_REFERENCE_SUCCESS, payload: response.commissions })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const generateCommissions = (action$) => action$.pipe(
    ofType(commissionsTypes.GENERATE_COMMISSIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/generation`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const generateCommissionsManually = (action$) => action$.pipe(
    ofType(commissionsTypes.GENERATE_COMMISSIONS_MANUALLY),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/generateCommissionsManually`, JSON.stringify(payload)).pipe(
            switchMap(() => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess()
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const findCommissionAgent = (action$) => action$.pipe(
    ofType(commissionsTypes.FIND_COMMISSION_AGENT),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/findCommissionAgent`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.users)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const findCommissionClient = (action$) => action$.pipe(
    ofType(commissionsTypes.FIND_COMMISSION_CLIENT),
    debounceTime(300),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/findClient`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.users)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const confirmCommissions = (action$) => action$.pipe(
    ofType(commissionsTypes.CONFIRM_COMMISSIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/confirm`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const createCommission = (action$) => action$.pipe(
    ofType(commissionsTypes.CREATE_COMMISSION),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/createClientAgentCommission`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const editCommissions = (action$) => action$.pipe(
    ofType(commissionsTypes.EDIT_COMMISSIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/commission`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commissions)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const updateCommissionSimple = (action$) => action$.pipe(
    ofType(commissionsTypes.UPDATE_COMMISSION_SIMPLE),
    switchMap(({ path = '/simple', payload, onSuccess, stopLoading: stopLoadingBoolean }) =>
        Api.put(`${URL}/commission${path}`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                if (stopLoadingBoolean) obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const deleteCommission = (action$) => action$.pipe(
    ofType(commissionsTypes.DELETE_COMMISSION),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/commission/deleteClientAgentCommission`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.commission)
                // obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const sendCommissions = (action$) => action$.pipe(
    ofType(commissionsTypes.SEND_COMMISSIONS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/commission/send`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

