import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { clientsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getFilesClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.GET_FILES_CLIENT),
    switchMap(({ payload }) =>
      Api.get(`${URL}/files/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: clientsTypes.GET_FILES_CLIENT_SUCCESS, payload: response.files })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const uploadFileClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.UPLOAD_FILE_CLIENT),
    mergeMap(({ payload }) =>
      Api.post(`${URL}/files`, JSON.stringify(payload)).pipe(
        switchMap(() =>
          ActionsObservable.create((obs) => {
            obs.next({ type: clientsTypes.GET_CLIENT, payload: payload?.client, getAllDetails: true })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const updateFileClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.UPDATE_FILE_CLIENT),
    switchMap(({ payload }) =>
      Api.put(`${URL}/files`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: clientsTypes.GET_CLIENT, payload: response?.file?.client, getAllDetails: true })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const deleteFileClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.DELETE_FILE_CLIENT),
    switchMap(({ payload }) =>
      Api.delete(`${URL}/files`, JSON.stringify(payload)).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: clientsTypes.GET_CLIENT, payload: response?.file?.client, getAllDetails: true })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )
