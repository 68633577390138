import React, { useMemo, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { Inputs } from "components"
import { createUserDaysOff, setModal, startLoading, updateUserDaysOff } from "actions"
import { Portal } from "react-overlays"
import moment from "moment"
import { Alerts } from '@makedonski/socourt-utilities'
import { loadDropdownOptions } from "utilities"
import { holidays, userDaysOffOptions } from "config/constants"

const UserDaysOff = ({ data: dataProp = {}, onClose = () => { }, onSuccess = () => { } }) => {
    const { currentUser } = useSelector(({ general }) => general) || {}
    const isMaster = useMemo(() => currentUser?.roles?.map(({ name }) => name).includes('master'), [currentUser])

    const [data, setData] = useState({ ...dataProp })
    const { _id, from, to, type, description, substitute } = data
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))

    const dispatch = useDispatch()

    const [showInvalid, setShowInvalid] = useState(false)
    const handleSubmit = () => {
        if (!from || !to || !type) setShowInvalid(true)
        else if (_id) {
            dispatch(startLoading())
            dispatch(updateUserDaysOff({
                payload: data,
                onSuccess: () => {
                    Alerts.success({ title: 'Успешно редактирано отсъствие' })
                    onSuccess()
                    onClose()
                }
            }))
        }
        else {
            dispatch(startLoading())
            dispatch(createUserDaysOff({
                payload: data,
                onSuccess: () => {
                    Alerts.success({ title: 'Успешно заявено отсъствие' })
                    onSuccess()
                    onClose()
                }
            }))
        }
    }

    return <div className="modal-user-days-off-container">
        <h2>{_id ? 'Редактиране' : 'Заявяване'} на отсъствие</h2>
        <div className="modal-user-days-off-content">
            {_id && <div className="col">
                <span>Потребител:</span>
                <Input.Text value={data?.user?.fullName} disabled />
            </div>}
            <div className="row">
                <div className="col">
                    <span>От:</span>
                    <Inputs.DatePicker
                        minDate={(isMaster && _id) ? null : new Date()}
                        filterDate={(date) => ![0, 6].includes(moment(date).day()) && !holidays.includes(moment(date).format())}
                        value={from ? moment(from).toDate() : null}
                        onChange={(value) => {
                            const change = { from: moment(value).startOf('day').toDate() }
                            if (to && moment(to).isBefore(change.from)) change.to = undefined
                            handleChange(change)
                        }}
                        className={showInvalid && !from ? 'required' : ''}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                </div>
                <div className="col">
                    <span>До:</span>
                    <Inputs.DatePicker
                        minDate={(isMaster && _id) ? null : new Date()}
                        filterDate={(date) => ![0, 6].includes(moment(date).day()) && !holidays.includes(moment(date).format())}
                        value={to ? moment(to).toDate() : null}
                        onChange={(value) => {
                            const change = { to: moment(value).endOf('day').toDate() }
                            if (from && moment(from).isAfter(change.to)) change.from = undefined
                            handleChange(change)
                        }}
                        className={showInvalid && !to ? 'required' : ''}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                </div>
            </div>
            <div className="col">
                <span>Причина:</span>
                <Inputs.Dropdown
                    options={userDaysOffOptions}
                    value={type}
                    onChange={({ value }) => handleChange({ type: value, description: undefined, ...(value === 'homeOffice' && { substitute: undefined }) })}
                    {...(showInvalid && !type && { className: 'required' })}
                />
                {type === 'other' && <Input.Text value={description ?? ''} onChange={({ target: { value } }) => handleChange({ description: value })} />}
            </div>
            {type !== 'homeOffice' && <div className="col">
                <span>Заместващ:</span>
                <Inputs.DropdownSelect
                    async cacheOptions defaultOptions
                    loadOptions={(fullName) => loadDropdownOptions({ path: '/user/list', payload: { fullName, select: 'fullName' }, transform: ({ users }) => users.filter(({ roles }) => roles?.filter(({ name }) => !['dealer', 'commissioner'].includes(name)).length) })}
                    rawValue={substitute}
                    onChange={(substitute) => handleChange({ substitute })}
                    getOptionValue={({ _id }) => _id}
                    getOptionLabel={({ fullName }) => fullName}
                />
            </div>}
        </div>
        <div className="modal-user-days-off-footer row">
            <Button.Raised className="btn-cancel" text="Откажи " onClick={onClose} />
            <Button.Raised className="btn-save" text={_id ? 'Редактирай' : 'Запази'} onClick={handleSubmit} />
        </div>
    </div>
}

export default UserDaysOff