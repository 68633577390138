import React, { useState } from "react"
import { Input, Button } from '@makedonski/admin-ui'
import "./styles.scss"

const InputText = ({ isTextArea, onRequestClose, close, title = "", text = "", defaultValue = "", textButton = "Продължи", onSuccess = () => { } }) => {
    const [state, setState] = useState(defaultValue)
    return <div className="modal-input-text-container">
        <div className="modal-input-text-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={onRequestClose ?? close} />
        </div>
        <div className="modal-input-text-content">
            {text && <p style={{ textAlign: 'center' }}>{text}</p>}
            {isTextArea
                ? <Input.Textarea value={state} onChange={({ target: { value } }) => setState(value)} rest={{ rows: 5 }} />
                : <Input.Text value={state} onChange={({ target: { value } }) => setState(value)} />}
        </div>
        <div className="modal-input-text-footer row">
            <Button.Raised onClick={() => onSuccess(state)} text={textButton} disabled={!state} />
        </div>
    </div>
}

export default InputText