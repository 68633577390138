import { Shared } from 'components'
import { consumptionTimeFormats } from 'config/constants'
import { useQuery } from 'hooks'
import { reduce, sortBy } from 'lodash'
import moment from 'moment'
import React, { useMemo } from 'react'
import './styles.scss'

const ConsumptionObjects = ({ objectStats = [], defaultType }) => {

  const { type = defaultType } = useQuery()

  const [itns, fields, data] = useMemo(() => {
    return objectStats.reduce(([keys = [], fields = [], allData = []], { itn = "", data = {} }) => [
      [...keys, itn],
      [...new Set([...fields, ...Object.keys(data)])],
      [...allData, data]
    ], [])
  }, [objectStats])


  return (
    <div className="card-consumption-objects-container">
      <h2>Данни за отделни обекти</h2>
      <div className="card-consumption-table row">
        <div className="col">
          <p>.</p>
          {itns?.map((label) => <p key={label} title={label}>{label}</p>)}
        </div>
        <Shared.Table
          columns={sortBy(fields, (f) => moment(f, consumptionTimeFormats[type]))?.map((label) => ({ label, value: label, size: 140 }))}
          data={data}
        />
      </div>
    </div>
  )
}

export default ConsumptionObjects
