import React, { useMemo, useState } from "react"
import { Portal } from 'react-overlays'
import { Button } from '@makedonski/admin-ui'
import moment from "moment"
import { useDispatch } from 'react-redux'
import { setModal, startLoading, exportAjur } from 'actions'
import { Inputs } from 'components'
import { invoicesTypesButtons } from 'config/constants'
import "./styles.scss"

const ExportAjur = ({ month, type: typeProp }) => {
    const dispatch = useDispatch()
    const close = () => dispatch(setModal({ isOpen: false }))

    const [type, setType] = useState(typeProp)
    const [period, setPeriod] = useState(month)
    const { from, to } = useMemo(() => period, [period])

    return <div className="modal-export-ajur-container">
        <div className="modal-export-ajur-header row">
            <h2>Генериране на документи - Ажур</h2>
            <Button.Icon name="plus" size="26px" className="btn-close" onClick={close} />
        </div>
        <div className="modal-export-ajur-content row">
            <div className="col">
                <span>Тип</span>
                <Inputs.RoundedButtons
                    buttons={[...invoicesTypesButtons, { value: 'leaving', label: "Депозити - Напускащи" }]}
                    value={type}
                    onChange={({ value }) => setType(value)}
                />
            </div>
            <div style={{ width: 30 }} />
            <div className="col">
                <span>Месец, за който се отнася фактурата</span>
                <Inputs.DatePicker
                    value={period}
                    onChange={setPeriod}
                    className="month-picker"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    shouldCloseOnSelect={false}
                    popperContainer={({ children }) => (
                        <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
                    )}
                />
            </div>
        </div>
        <div className="modal-export-ajur-footer row">
            <Button.Raised text='Откажи' className="cancel" onClick={close} />
            <div style={{ width: 30 }} />
            <Button.Raised text='Генерирай' disabled={!month || !type} onClick={() => {
                dispatch(startLoading())
                dispatch(exportAjur({
                    payload: { from: moment(period).startOf('month'), to: moment(period).endOf('month'), includes: type === "equalizing", types: ["equalizing"], field: "invoicePeriodStart" },
                    onSuccess: () => close()
                }))
            }} />
        </div>
    </div>
}

export default ExportAjur