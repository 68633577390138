// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-cells-objects-container {
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}
.client-cells-objects-container .icon {
  transition: 0.4s ease;
}
.client-cells-objects-container .icon.rotate {
  transform: rotate(-180deg);
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Cells/Objects/styles.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,qBAAA;AAEJ;AADI;EACE,0BAAA;AAGN","sourcesContent":[".client-cells-objects-container {\r\n  width: 100%;\r\n  justify-content: space-between;\r\n  padding-right: 15px;\r\n  .icon {\r\n    transition: 0.4s ease;\r\n    &.rotate {\r\n      transform: rotate(-180deg);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
