import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, mergeMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { maxBy, omit } from 'lodash'
import { clientsTypes, annexTypes, invoiceGroupsTypes, stopLoading, startLoading, mergedObjectsTypes } from '../actions'
import { xlsxExport, xlsxExportClientsWithObjects, renderCell } from 'utilities'
import { URL } from '../config/settings'

//prettier-ignore
const excludedField = ['group', 'connectedGroup', "existingClient",
  'comments', 'files', 'offers', 'generatedContracts',
  'objectsCount', 'objects',
  'annex', 'annexes',
  'missingGeneratedInvoice', 'invoices',
  'createdAt', 'updatedAt', '__v', 'id',
  'oldId', "employeeOldId",
  'region', 'municipality', 'neighborhood', 'postCode', 'logo',
  '__enc_pin'
]

export const getClientsFilters = (action$) => action$.pipe(
  ofType(clientsTypes.GET_CLIENTS_FILTERS),
  switchMap(() => Api.get(`${URL}/clients/filters`).pipe(
    switchMap(({ response }) => ActionsObservable.create((obs) => {
      obs.next({ type: clientsTypes.GET_CLIENTS_FILTERS_SUCCESS, payload: response })
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      Alerts.error('Error!', err?.response?.error?.message || '')
      obs.next(stopLoading())
      obs.complete()
    }))
  ))
)

export const startGetClientsLoading = (action$) =>
  action$.pipe(
    ofType(clientsTypes.GET_CLIENTS_START),
    debounceTime(300),
    switchMap(({ payload }) =>
      ActionsObservable.create((obs) => {
        obs.next(startLoading())
        obs.next({ type: clientsTypes.GET_CLIENTS, payload })
        obs.complete()
      })
    )
  )

export const getClients = (action$) =>
  action$.pipe(
    ofType(clientsTypes.GET_CLIENTS),
    switchMap(({ payload: { onSuccess, ...payload } }) =>
      Api.post(`${URL}/clients/list`, JSON.stringify({ limit: 20, ...payload })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.payload)
            obs.next({ type: clientsTypes.GET_CLIENTS_SUCCESS, payload: response.payload })
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const getClientsSuccess = (action$) =>
  action$.pipe(
    ofType(clientsTypes.GET_CLIENTS_SUCCESS),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        obs.next(stopLoading())
        obs.complete()
      })
    )
  )

export const getClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.GET_CLIENT),
    mergeMap(({ payload, onSuccess, getAllDetails }) =>
      Api.get(`${URL}/clients/${payload}`).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({ type: clientsTypes.GET_CLIENT_SUCCESS, payload: response.client })
            if (getAllDetails) obs.next({ type: clientsTypes.GET_FILES_CLIENT, payload })
            if (getAllDetails) obs.next({ type: annexTypes.GET_ANNEXES_BY_CLIENT, payload })
            if (getAllDetails) obs.next({ type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT, payload })
            if (getAllDetails) obs.next({ type: mergedObjectsTypes.GET_MERGED_OBJECTS, payload })
            if (onSuccess) onSuccess(response.client)
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const createClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.CREATE_CLIENT),
    switchMap(({ payload: { data, onSuccess } }) => {
      const payload = data?.type === 'Юридическо лице' ? data : { ...omit(data, ['eic']), pin: data?.eic }
      return Api.post(`${URL}/clients`, JSON.stringify(omit(payload, ['comments']))).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next(stopLoading())
            if (onSuccess) onSuccess(response.client)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error(err?.response?.error?.message, data?.eic || data?.pin || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    })
  )

export const updateClient = (action$) =>
  action$.pipe(
    ofType(clientsTypes.UPDATE_CLIENT),
    mergeMap(({ payload: { data, onSuccess, updateDefaultInvoiceGroup } }) =>
      Api.put(`${URL}/clients`, JSON.stringify(omit(data, excludedField))).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.client)
            if (updateDefaultInvoiceGroup) obs.next({ type: clientsTypes.GET_CLIENT_SUCCESS_PARTIAL, payload: { defaultInvoiceGroup: response.client.defaultInvoiceGroup } })
            else obs.next({ type: clientsTypes.GET_CLIENT, payload: data?._id })
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const checkClientExists = (action$) =>
  action$.pipe(
    ofType(clientsTypes.CHECK_CLIENT_EXISTS),
    switchMap(({ payload: { data, getAllDetails, exact, onSuccess } }) =>
      Api.post(`${URL}/clients/search/eic`, JSON.stringify({ search: data || '', getAllDetails, exact })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (onSuccess) onSuccess(response.clients)
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const exportClients = (action$) =>
  action$.pipe(
    ofType(clientsTypes.EXPORT_CLIENTS),
    switchMap(({ payload: { payload, fields }, multi, contacts, invoiceSettings }) =>
      Api.post(`${URL}/clients/list`, JSON.stringify({ ...payload })).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (multi) {
              if (invoiceSettings) fields = {
                clientsFields: [
                  { label: 'ClientID', value: "_id" },
                  { label: 'ЕИК', value: 'eic' },
                  { label: 'ИН по ЗДДС', value: 'bulstat' },
                  { label: 'Име клиент', value: 'fullName' },
                  { label: 'Тип клиент', value: 'clientType' },
                ],
                objectsFields: [
                  { label: 'ObjectID', value: "_id" },
                  { label: 'Тип', value: 'isProducer' },
                  { label: 'ИТН', value: 'itn' },
                  { label: 'Тип обект / описание', value: 'description' },
                  { label: 'Тип ВЕИ', value: 'producerType' },
                  { label: 'ЕРП', value: 'erp' },
                  { label: 'Комисионна към надбавка', value: 'commissionProducer' },
                  { label: 'Фактура за балансиране', value: 'producerInvoiceBalancing' },
                  { label: 'Примерна фактура', value: 'templatedInvoice' },
                  { label: 'Протокол за прихващане', value: 'setOffAgreement' },
                  { label: 'Опростена справка по дни', value: 'producerSimpleByDays' },
                  { label: 'Опростена справка по часове', value: 'producerSimpleByHours' },
                  { label: 'Допълнителна справка Почасово мерене', value: 'producerAdditionalPeriodData' },
                  { label: 'Пълна справка Ф по дни', value: 'producerFullFByDays' },
                  { label: 'Пълна справка Ф по часове', value: 'producerFullFByHours' },
                  { label: 'Пълна справка РБ по дни', value: 'producerFullRBByDays' },
                  { label: 'Пълна справка РБ 15мин', value: 'producerFullRBByHours' },
                  { label: 'ОС с балансиране по дни', value: 'producerSimpleBalancingByDays' },
                  { label: 'ОС с балансиране по часове', value: 'producerSimpleBalancingByHours' },
                  { label: 'Справка за', value: 'producerStatsFor' },
                  { label: 'Банка', value: 'producerBank' },
                  { label: 'IBAN', value: 'producerIBAN' },
                ]
              }
              xlsxExportClientsWithObjects({
                fields,
                data: response.payload.docs,
                render: {
                  clients: (client, value) => renderCell.clientsExport(client, value, { type: payload?.type }),
                  objects: renderCell.objectsExport,
                },
              })
            } else if (contacts) {
              const contactsFields = [
                { label: 'Име клиент', value: 'fullName', size: 300 },
                { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
                { label: 'Вид падеж', value: 'paymentDaysType' },
                { label: 'Дни падеж', value: 'paymentDays' },
                ...maxBy(response.payload.docs, ({ mol }) => mol?.length || 0).mol.reduce((acc, _, i) => [
                  ...acc,
                  { label: `МОЛ ${i + 1} Име и Фамилия`, value: `mol_fullName_${i}` },
                  { label: `МОЛ ${i + 1} Пояснение`, value: `mol_role_${i}` },
                ], []),
                { label: 'Телефон', value: 'phoneNumber' },
                { label: 'Имейл', value: 'email' },
                ...maxBy(response.payload.docs, ({ contacts }) => contacts?.length || 0).contacts.reduce((acc, _, i) => [
                  ...acc,
                  { label: `ДК ${i + 1} Име и Фамилия`, value: `contacts_fullName_${i}` },
                  { label: `ДК ${i + 1} Пояснение`, value: `contacts_description_${i}` },
                  { label: `ДК ${i + 1} Телефон`, value: `contacts_phoneNumber_${i}` },
                  { label: `ДК ${i + 1} Имейл`, value: `contacts_email_${i}` },
                  { label: `ДК ${i + 1} Кореспонденция`, value: `contacts_communication_${i}` },
                  { label: `ДК ${i + 1} Фактура`, value: `contacts_invoices_${i}` },
                ], [])
              ]
              xlsxExport({
                fields: contactsFields,
                data: response.payload.docs,
                render: (client, value) => renderCell.clientsExportContacts(client, value, { type: payload?.type }),
              })
            } else {
              xlsxExport({
                fields: fields.clientsFields,
                data: response.payload.docs,
                render: (client, value) => renderCell.clientsExport(client, value, { type: payload?.type }),
              })
            }
            obs.next(stopLoading())
            obs.complete()
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.error(err)
            Alerts.error('Error!', err?.response?.error?.message || '')
            obs.next(stopLoading())
            obs.complete()
          })
        )
      )
    )
  )

export const clientsRedistributeDealers = (action$) => action$.pipe(
  ofType(clientsTypes.CLIENTS_REDISTRIBUTE_DEALERS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/redistributeDealers`, JSON.stringify(payload)).pipe(
      switchMap(({ }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const clientsRedistributeDealersFile = (action$) => action$.pipe(
  ofType(clientsTypes.CLIENTS_REDISTRIBUTE_DEALERS_FILE),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/redistributeDealersFile`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const massEditClientsPrice = (action$) => action$.pipe(
  ofType(clientsTypes.MASS_EDIT_CLIENTS_PRICE),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/clients/massEditClientsPrice`, JSON.stringify(payload)).pipe(
      switchMap(({ }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)