import { Button } from '@makedonski/admin-ui'
import { Inputs } from "components"
import { producersReferencesOptions } from "config/constants"
import moment from "moment"
import React, { useState } from "react"
import { Portal } from "react-overlays"
import { useDispatch } from "react-redux"
import "./styles.scss"
const ProducersReferencesGenerate = ({ close, title, text, requireFile, data: dataProp, onClick = () => { } }) => {

  const dispatch = useDispatch()
  const [data, setData] = useState({ type: producersReferencesOptions[0].value, ...dataProp, })

  return <div className="modal-producers-references-generate-container">
    <div className="modal-producers-references-generate-header">
      <h2>{title}</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    {text}
    <div className="modal-producers-references-generate-content row">
      <div className="col">
        <span>Тип</span>
        <Inputs.Dropdown
          options={producersReferencesOptions.filter(({ value }) => process.env.REACT_APP_PLATFORM !== 'Synergon' || !['producerAdditionalPeriodData'].includes(value))}
          value={data.type}
          onChange={({ value }) => setData({ ...data, type: value })}
        />
      </div>
      <div style={{ width: 15 }} />
      <div className="col">
        <span>Месец</span>
        <Inputs.DatePicker
          value={moment(data.monthYear, 'MM/YYYY')}
          onChange={(value) => setData({ ...data, monthYear: moment(value).format('MM/YYYY') })}
          dateFormat={'MMMM yyyy'}
          showMonthYearPicker
          showFullMonthYearPicker
          className="month-picker"
          popperContainer={({ children }) => (
            <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>
          )}
        />
      </div>
    </div>
    {requireFile && <div className="modal-producers-references-generate-content row">
      <Button.UploadButton text='Качи файл' accept='xlsx xls' onChange={({ target: { files } }) => setData({ ...data, files })} />
      {data?.files?.length && <div className="row" style={{ marginLeft: 16 }}>{data?.files?.[0]?.name}
        <div className="icon icon-remove" style={{ width: 20, height: 14 }} onClick={() => setData({ ...data, files: undefined })} />
      </div>}
    </div>}
    <div className="modal-producers-references-generate-footer row">
      <Button.Raised
        className='cancel'
        text="Откажи"
        onClick={() => dispatch(setModal({ isOpen: false }))}
      />
      <div style={{ width: 15 }} />
      <Button.Raised
        text="Продължи"
        disabled={requireFile && !data?.files?.length}
        onClick={() => { onClick(data); close() }}
      />
    </div>
  </div>
}

export default ProducersReferencesGenerate