import { connectedGroupsTypes, dataTypes } from '../actions'

const initialState = {
  showCheckboxes: false,
  selectedCheckboxes: [],
}

const data = (state = initialState, { type, payload }) => {
  switch (type) {
    case dataTypes.SET_FIELDS:
      return { ...state, ...payload }
    case dataTypes.SET_FIELD:
      return { ...state, [payload.field]: payload.value }
    case dataTypes.GET_DATA_SUCCESS:
      if (payload.type === 'products') payload.data = payload.data.filter(({ name, forProducer }) => forProducer || [
        'Една тарифа',
        'Две тарифи',
        'Борса',
        ...(['Synergon'].includes(process.env.REACT_APP_PLATFORM) ? ['Отделно балансиране'] : []),
        ...(['RETC', 'TOKI'].includes(process.env.REACT_APP_PLATFORM) ? ['Борса с лв и процент'] : []),
        ...(['W&S', 'TOKI'].includes(process.env.REACT_APP_PLATFORM) ? ['Борса с Под на цената', 'Борса с Таван на цената', 'Борса с Под и Таван на цената'] : []),
      ].includes(name))
      return { ...state, [payload.type]: payload.data }
    case connectedGroupsTypes.CREATE_CONNECTED_GROUP_SUCCESS:
      return { ...state, 'connectedGroups': [...(state?.connectedGroups ?? []), payload] }
    case connectedGroupsTypes.UPDATE_CONNECTED_GROUP_SUCCESS:
      return { ...state, 'connectedGroups': (state?.connectedGroups ?? []).map((g) => g._id === payload._id ? payload : g) }
    case connectedGroupsTypes.DELETE_CONNECTED_GROUP_SUCCESS:
      return { ...state, 'connectedGroups': (state?.connectedGroups ?? []).filter((g) => g._id !== payload._id) }
    default:
      return state
  }
}

export default data
