import { clientsTypes, commentsTypes, invoicesTypes, annexTypes, invoiceGroupsTypes, mergedObjectsTypes } from '../actions'
import { clientsFields } from '../config/constants'

const initialState = {
  clients: [],
  client: {},
  hasNextPage: false,
  nextPage: 1,

  availableSort: [],
  availableFilters: {},

  showCheckboxes: false,
  selectedCheckboxes: [],

  clientsFields: clientsFields,
  lockedColumn: null,

  clientsCount: null,
}

const clients = (state = initialState, { type, payload }) => {
  switch (type) {
    case clientsTypes.SET_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    case clientsTypes.SET_FIELDS:
      return { ...state, ...payload }
    case clientsTypes.GET_CLIENTS_FILTERS_SUCCESS:
      return { ...state, availableSort: payload?.enableSortFor, availableFilters: payload?.filters }
    case clientsTypes.GET_CLIENTS_SUCCESS:
      const newClients = [1, null].includes(payload.page) ? payload.docs : [...(state.clients || []), ...payload.docs]
      return {
        ...state,
        clients: newClients,
        hasNextPage: payload.hasNextPage,
        nextPage: payload.nextPage,
        availableSort: payload.enableSortFor,
        availableFilters: payload.filters,
        clientsCount: payload.totalDocs,
      }
    case clientsTypes.GET_CLIENT_SUCCESS:
      return { ...state, client: payload }
    case clientsTypes.GET_CLIENT_SUCCESS_PARTIAL:
      return { ...state, client: { ...state.client, ...payload } }
    case clientsTypes.GET_FILES_CLIENT_SUCCESS:
      return { ...state, client: { ...state.client, files: payload } }
    case commentsTypes.CREATE_COMMENT_CLIENT_SUCCESS:
      return { ...state, clients: addCommentToClient(state.clients, payload) }
    case annexTypes.GET_ANNEXES_BY_CLIENT_SUCCESS: {
      return { ...state, client: { ...state.client, annexes: payload } }
    }
    case invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT_SUCCESS:
      return { ...state, client: { ...state.client, invoiceGroups: payload } }
    case mergedObjectsTypes.GET_MERGED_OBJECTS:
      return { ...state, client: { ...state.client, mergedObjects: payload } }
    case invoicesTypes.GET_INVOICES_CLIENT_SUCCESS:
      return { ...state, client: { ...state.client, invoices: payload } }
    default:
      return state
  }
}

export default clients

const addCommentToClient = (clients = [], comment) => {
  const index = clients.findIndex(({ _id }) => _id === comment.ref._id)
  const newClients = [...(clients || [])]
  newClients.splice(index, 1, { ...clients[index], comments: [...(clients[index]?.comments || []), comment] })
  return newClients
}
