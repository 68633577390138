import { Button } from '@makedonski/admin-ui'
import { Client, Inputs } from "components"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import "./styles.scss"

const ProducerSettingsFromObject = ({ close, onSuccess = () => { } }) => {
    const { versions, products } = useSelector(({ data }) => data)
    const { client } = useSelector(({ clients }) => clients)

    const [selected, setSelected] = useState('')
    const object = client?.objects?.find(({ _id }) => _id === selected)

    return <div className="modal-producer-settings-from-object-container">
        <div className="modal-producer-settings-from-object-header row">
            <h2>Производство</h2>
            <div className='icon icon-close' />
        </div>
        <div className="modal-producer-settings-from-object-content col">
            <span>Обекти</span>
            <Inputs.Dropdown
                options={client?.objects?.filter(({ isProducer }) => isProducer)?.map(({ _id, itn, description }) => ({ value: _id, label: `${itn} - ${description || "Няма описание"}` }))}
                value={selected}
                onChange={({ value }) => setSelected(value)}
            />
            {object && <Client.Forms.ProducerSettings versions={versions} products={products} editable={false} producerSettings={object?.producerSettings} />}
        </div>
        <div className="modal-producer-settings-from-object-footer row">
            <Button.Raised
                className='cancel'
                text="Откажи"
                onClick={close}
            />
            <div style={{ width: 15 }} />
            <Button.Raised
                text="Продължи"
                disabled={!selected}
                onClick={() => {
                    const object = client?.objects.find(({ _id }) => _id === selected)
                    onSuccess({ ...object.producerSettings, toDeleteFromObjects: true })
                    close()
                }}
            />
        </div>
    </div>
}

export default ProducerSettingsFromObject