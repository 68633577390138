// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-cells-object-previous-client-container {
  width: 100%;
}
.client-cells-object-previous-client-container .icon-folder-custom {
  margin-right: 15px;
  height: 22px;
  width: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Cells/ObjectPreviousClient/styles.scss"],"names":[],"mappings":"AAAA;EAEE,WAAA;AAAF;AACE;EACE,kBAAA;EACA,YAAA;EACA,WAAA;AACJ","sourcesContent":[".client-cells-object-previous-client-container {\r\n  // justify-content: space-between;\r\n  width: 100%;\r\n  .icon-folder-custom {\r\n    margin-right: 15px;\r\n    height: 22px;\r\n    width: 22px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
