import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError, debounceTime } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { communicationTypes, stopLoading } from 'actions'
import { URL } from 'config/settings'

export const getCommunicationPeriod = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_PERIOD),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoPeriod`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )


export const getCommunicationPeriodFiles = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_PERIOD_FILES),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoPeriodFiles`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.autoData)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )

export const getCommunicationNetwork = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_NETWORK),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoNetwork`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )


export const getCommunicationNetworkFiles = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_NETWORK_FILES),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoNetworkFiles`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.autoData)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )

export const getCommunicationCorrectionsFiles = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_CORRECTIONS_FILES),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/corrections/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )

export const applyCommunicationCorrectionsFiles = (action$) =>
    action$.pipe(ofType(communicationTypes.APPLY_COMMUNICATION_CORRECTIONS_FILES),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/corrections/apply`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.data)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )

export const getCommunicationObjects = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_OBJECTS),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoObjects`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )


export const getCommunicationObjectsFiles = (action$) =>
    action$.pipe(ofType(communicationTypes.GET_COMMUNICATION_OBJECTS_FILES),
        debounceTime(300),
        switchMap(({ payload, onSuccess }) => Api.post(`${URL}/imported-files/autoObjectsFiles`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.autoData)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                console.error(err);
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
    )