import { Button } from '@makedonski/admin-ui'
import { getTextForNotificationsAnnexes } from "actions"
import Inputs from "components/Inputs"
import moment from 'moment'
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import "./styles.scss"
import ReactQuill from 'react-quill';

const GenerateNotificationsAnnexes = ({ close, handleGenerate }) => {
    const dispatch = useDispatch()
    const [texts, setTexts] = useState([])
    const [selected, setSelected] = useState("")
    const [text, setText] = useState("")
    const [showRequired, setShowRequired] = useState(false)

    useEffect(() => { dispatch(getTextForNotificationsAnnexes({ onSuccess: ({ docs }) => setTexts(docs) })) }, [dispatch])

    return <div className="modal-generate-notifications-annexes-container">
        <div className="modal-generate-notifications-annexes-header row">
            <h2>Уведомления - генериране на ПДФ-и</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-generate-notifications-annexes-content">
            <p>Последни текстове</p>
            <Inputs.Dropdown
                options={texts?.map(({ _id, text, createdAt }) => ({ value: _id, label: moment(createdAt).format('Текст от DD.MM.YYYY | HH:mm'), text }))}
                value={selected}
                onChange={({ value, text }) => {
                    setText(text)
                    setSelected(value)
                }}
            />
            <p>Текст - <span>{`Трябва да съдържа {{activationDate}} и {{price}}`}</span></p>
            <ReactQuill
                style={showRequired && (!text.includes("{{price}}") || !text.includes("{{activationDate}}")) ? { boxShadow: `0 0 3px #e5214e`, borderColor: 'transparent' } : {}}
                value={text}
                onChange={(e) => setText(e)}
            />
        </div>
        <div className="modal-generate-notifications-annexes-footer row">
            <Button.Raised text="Генерирай" onClick={() => {
                if (!text.includes("{{price}}") || !text.includes("{{activationDate}}")) setShowRequired(true)
                else handleGenerate({ text, saveText: texts.find(({ _id }) => _id === selected)?.text !== text })
            }} />
        </div>
    </div>
}

export default GenerateNotificationsAnnexes