import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { useQuery } from "hooks"
import { useDispatch, useSelector } from "react-redux"
import { Inputs, Shared } from "components"
import moment from "moment"
import { applyCommunicationCorrectionsFiles, getCommunicationCorrectionsFiles, getData, setModal, startLoading } from "actions"
import { fileRequest, mapQuerySearch, renderCell } from "utilities"
import { communicationCorrectionsFields } from "config/constants"
import { Alerts } from "@makedonski/socourt-utilities"
import Popup from "reactjs-popup"

const Corrections = ({ TypeSelector }) => {
  const dispatch = useDispatch()
  const { startDate, erp, handleUrlChangeMultiple } = useQuery()

  const { erps } = useSelector(({ data }) => data)
  useEffect(() => { dispatch(getData('erps')) }, [])

  const [itn, setItn] = useState(undefined)
  const [files, setFiles] = useState({})
  const { docs, totalDocs, nextPage, hasNextPage } = files || {}

  const availableFilters = {
    dataType: { type: 'dropdown', values: ['MSCONS-810', 'MSCONS-860', 'MSCONS-865', 'MSCONS-870', 'INVOIC-910', 'INVOIC-915', 'INVOIC-970'] },
    isFinishing: { type: 'boolean' },
    appliedAt: { type: 'boolean', booleanToExists: true }
  }
  const { sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery()
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])


  const [selected, setSelected] = useState([])
  useEffect(() => { setSelected([]) }, [docs, filter.appliedAt])

  const fetch = useCallback(({ payload = {}, onSuccess } = {}) => {
    if (!erp) return
    dispatch(startLoading())
    dispatch(getCommunicationCorrectionsFiles({
      payload: {
        erp,
        itn,
        from: moment(startDate).startOf('month').toDate(),
        to: moment(startDate).add(1, 'months').startOf('month').toDate(),
        ...payload,
        ...searchQuery
      },
      onSuccess: (res) => {
        if (onSuccess) onSuccess(res)
        else {
          if (res.page !== 1) setFiles(({ docs }) => ({ ...res, docs: [...docs, ...res.docs] }))
          else setFiles(res)
        }
      }
    }))
  }, [dispatch, erp, startDate, itn, searchQuery])

  useEffect(() => { fetch() }, [fetch])


  const handleApply = async (payload) => {
    payload.toApply = selected
    if (payload.download) await fileRequest({ URL: '/corrections/apply', payload, onSuccess: () => { setSelected([]); fetch() } })
    else dispatch(applyCommunicationCorrectionsFiles({ payload, onSuccess: () => { setSelected([]); fetch(); Alerts.success({ title: "Успешно приложени корекции!" }) } }))
  }

  const sortingComponents = communicationCorrectionsFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
    ...acc,
    [value]: <Popup
      trigger={<div className="icon icon-arrow-down" />}
      activeFilters={searchQuery}
      position='bottom center'
      keepTooltipInside='.communication-corrections-content'
      contentStyle={{ width: 'auto' }}
    >
      {close => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={[]}
        availableFilters={availableFilters}
      />}
    </Popup>
  }), {})

  return <div className="communication-corrections-container">
    <div className="communication-corrections-header row">
      <TypeSelector />
      <Inputs.RoundedButtons
        buttons={erps?.map(({ _id, name }) => ({ label: name, value: _id })) || []}
        value={erp}
        onChange={({ value }) => handleUrlChangeMultiple({ erp: value })}
      />
      <Inputs.DatePicker
        selected={new Date(startDate || moment().toDate())}
        onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf('month').toDate(), createdAt: undefined })}
        maxDate={new Date()}
        dateFormat={'MM.yyyy'}
        showMonthYearPicker
        showFullMonthYearPicker
      />
      <div className="row row-itn">
        <span>ИТН: </span>
        <Input.Text value={itn ?? ''} onChange={({ target: { value } }) => setItn(value)} />
      </div>
      {!filter.appliedAt && <div className="row row-buttons">
        <Button.Raised text='Изтегли' disabled={!selected.length} className='btn-download' onClick={() => dispatch(setModal({ isOpen: true, type: 'communicationCorrections', props: { type: 'download', handleApply } }))} />
        <Button.Raised text='Приложи' disabled={!selected.length} onClick={() => dispatch(setModal({ isOpen: true, type: 'communicationCorrections', props: { type: 'apply', handleApply } }))} />
      </div>}
    </div>
    <div className="communication-corrections-content">
      <Shared.Table
        columns={communicationCorrectionsFields}
        data={docs}
        handlePagination={() => hasNextPage && fetch({ payload: { page: nextPage } })}
        renderCell={renderCell.communicationCorrections}
        fixedWidth
        sortingComponent={sortingComponents}
        useCheckboxes={!filter.appliedAt}
        selectedCheckboxes={selected}
        onCheckboxChange={setSelected}
      />
    </div>
    <div className="communication-corrections-footer row">
      <div className="communication-corrections-inner-footer row">
        <p>Файлове: <span>{totalDocs || 0}</span></p>
        <p>Избрани: <span>{selected.length}</span></p>
      </div>
    </div>
  </div>
}

export default Corrections