import React, { useState } from "react"
import { Inputs } from "components"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { Portal } from "react-overlays"
import moment from "moment"
const ExportAjurProducersTemplated = ({ close, useInitialNumber, onSuccess = () => { } }) => {


    const [state, setState] = useState({
        invoiceMinDate: moment().startOf('month').toDate(),
        invoiceMaxDate: moment().endOf('day').toDate(),
    })

    return <div className="modal-export-ajur-producers-templated-container">
        <div className="modal-export-ajur-producers-templated-header row">
            <h2>Експорти - Ажур Доставчици</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-export-ajur-producers-templated-content row">
            <div className="col">
                <span>От:</span>
                <div className="row">
                    <Inputs.DatePicker
                        value={state?.invoiceMinDate}
                        onChange={(invoiceMinDate) => setState((state) => ({ ...state, invoiceMinDate: moment(invoiceMinDate).startOf('day').toDate() }))}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                    <Inputs.DatePicker
                        value={state?.invoiceMinDate}
                        onChange={(invoiceMinDate) => setState((state) => ({ ...state, invoiceMinDate }))}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}><div className="datepicker-time-popper-container">{children}</div></Portal>}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        className="time-picker"
                    />
                </div>
            </div>
            <div className="col">
                <span>До:</span>
                <div className="row">
                    <Inputs.DatePicker
                        value={state?.invoiceMaxDate}
                        onChange={(invoiceMaxDate) => setState((state) => ({ ...state, invoiceMaxDate: moment(invoiceMaxDate).endOf('day').toDate() }))}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                    <Inputs.DatePicker
                        value={state?.invoiceMaxDate}
                        onChange={(invoiceMaxDate) => setState((state) => ({ ...state, invoiceMaxDate }))}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}><div className="datepicker-time-popper-container">{children}</div></Portal>}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        dateFormat="HH:mm"
                        className="time-picker"
                    />
                </div>
            </div>
        </div>
        {useInitialNumber && <div className="modal-export-ajur-producers-templated-content row">
            <div className="col">
                <span>Начален номер:</span>
                <Input.Text
                    value={state?.initialNumber ?? ''}
                    onChange={({ target: { value } }) => setState((state) => ({ ...state, initialNumber: value }))}
                />
            </div>
        </div>}
        <div className="modal-export-ajur-producers-templated-footer">
            <Button.Raised onClick={() => onSuccess(state)} text="Продължи" disabled={useInitialNumber && !state.initialNumber} />
        </div>
    </div>
}

export default ExportAjurProducersTemplated