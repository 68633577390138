import React from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { uploadFileClient, startLoading } from 'actions'
import { Shared } from 'components'
import { clientDocumentsFields } from 'config/constants'
import { uploadFile, renderCell, checkIsDealer } from 'utilities'
import './styles.scss'

const Documents = ({ client }) => {
  const { currentUser } = useSelector(({ general }) => general) || {}
  const isDealer = checkIsDealer()

  const dispatch = useDispatch()
  const handleUpload = (files) => {
    dispatch(startLoading())
    uploadFile({ file: files[0], createId: true }).then(({ location }) => {
      dispatch(uploadFileClient({ payload: { client: client?._id, url: location, name: files[0].name } }))
    })
  }
  return (
    <div className="client-documents-container">
      <div className="client-documents-header row">
        <h2>Файлове</h2>
        {!isDealer && <div className="row row-buttons">
          <Input.DragAndDrop handleDrop={handleUpload}>
            <Button.UploadButton
              text="Добави"
              className="btn-green"
              onChange={({ target: { files } }) => handleUpload(files)}
              accept="*"
            />
          </Input.DragAndDrop>
        </div>}
      </div>
      <div className="client-documents-content">
        <Shared.Table
          fixedWidth
          rowHeight={60}
          data={client?.files?.filter(({ employee }) => !isDealer || employee?._id === currentUser?._id)}
          columns={clientDocumentsFields}
          renderCell={(row, value, options) => renderCell.clientDocuments(row, value, { ...options, isDealer })}
        />
      </div>
    </div>
  )
}

export default Documents
