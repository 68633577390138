import React from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getData, updateClient, setModal, startLoading, updateObject } from '../../../../actions'
import { Inputs } from '../../../'
import { useQuery } from '../../../../hooks'
import './styles.scss'

const EditCell = ({ object, client, field, fetch, showTime }) => {

  const getType = {
    version: 'dropdown',
    contractNumber: 'text',
    registrationNumber: 'text',
    registrationNumberProducers: 'text',
    contractDate: 'date',
    deliveryEnd: 'date',

    entryEnd: 'date',
    executionDate: 'date',
    executionDateProducers: 'date',
    dpiGenerated: 'date',
    contractReceivedDate: 'date',
    contractReceivedDateProducers: 'date',
    dpiSigned: 'date',
    objectedDate: 'date',

    activeFrom: object?.isProducer ? 'date' : 'monthDate',
    inactiveFrom: 'monthDate',
    objectionsDate: 'date'
  }

  const value =
    ['activeFrom', 'inactiveFrom', 'objectionsDate', 'entryEnd'].includes(field) ? object?.[field] :
      ['version'].includes(field) ? client?.contractSettings?.[field] :
        client?.contractSettings?.[field]?.name ||
        client?.generatedContracts?.[field]?.name ||
        client?.contractSettings?.[field] ||
        client?.generatedContracts?.[field] ||
        client?.[field]?.name ||
        client?.[field]

  const dispatch = useDispatch()
  const { versions } = useSelector((state) => state.data)

  const { type } = useQuery()
  const { pathname } = useLocation()
  if (
    ['financial/invoices', 'financial/penalties'].some((path) => pathname.includes(path)) ||
    (!['register'].includes(type) && (['leaving', 'producersLeaving'].includes(type) && !['objectedDate', 'objectionsDate'].includes(field))) ||
    (object && !['documents-received', 'documents-received-missing', 'leaving'].includes(object.status)) && !['entryEnd'].includes(field))
    return ['date', 'monthDate'].includes(getType[field]) && value ? moment(value).format(showTime ? 'DD.MM.YYYY | HH:mm' : 'DD.MM.YYYY') : value?.name || value || '--'

  if (field === 'product')
    return (
      <div className="client-cells-edit-cell-container row">
        {value?.name || value || '--'}
        <div
          className="icon icon-edit add-margin"
          onClick={() => dispatch(setModal({ isOpen: true, type: 'clientsProduct', props: { client, fetch } }))}
        />
      </div>
    )

  return (
    <div className="client-cells-edit-cell-container row">
      <Inputs.TextEdit
        showTime={showTime}
        type={getType[field]}
        value={value}
        getOptions={() => dispatch(getData('versions'))}
        options={versions
          ?.filter(({ type }) => type === client?.contractSettings?.type)
          ?.map(({ _id, name }) => ({ value: _id, label: name }))}
        handleChange={(value) => {
          if (['activeFrom', 'inactiveFrom', 'objectionsDate', 'entryEnd'].includes(field)) {
            dispatch(startLoading())
            dispatch(updateObject({ data: { _id: object._id, [field]: value }, onSuccess: () => fetch({ pageOne: true }), }))
          } else {
            let data = { _id: client?._id, contractSettings: { ...client?.contractSettings, [field]: value } }
            if (['registrationNumber', "registrationNumberProducers", "executionDate", "executionDateProducers", "dpiGenerated", "contractReceivedDate", "contractReceivedDateProducers", "dpiSigned", 'objectedDate'].includes(field)) data = { _id: client?._id, [field]: value }
            dispatch(startLoading())
            dispatch(updateClient({ data, onSuccess: () => fetch({ pageOne: true }), }))
          }
        }}
      />
      {['registrationNumber', "registrationNumberProducers", "executionDate", "executionDateProducers", "dpiGenerated", "contractReceivedDate", "contractReceivedDateProducers", "dpiSigned", 'objectedDate'].includes(field) && value && <div
        className="icon icon-delete"
        onClick={() => {
          dispatch(startLoading())
          dispatch(updateClient({ data: { _id: client?._id, [field]: null }, onSuccess: () => fetch({ pageOne: true }), }))
        }}
      />}
    </div>
  )
}

export default EditCell
