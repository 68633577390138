import { combineEpics } from 'redux-observable'
import * as auth from './auth'
import * as data from './data'
import * as clients from './clients'
import * as objects from './objects'
import * as apis from './apis'
import * as offers from './offers'
import * as contracts from './contracts'
import * as invoices from './invoices'
import * as files from './files'
import * as comments from './comments'
import * as connectedGroups from './connectedGroups'
import * as groups from './groups'
import * as register from './register'
import * as notices from './notices'
import * as references from './references'
import * as imports from './imports'
import * as nomenclatures from './nomenclatures'
import * as annex from './annex'
import * as activity from './activity'
import * as invoiceGroups from './invoiceGroups'
import * as intermediateInvoices from "./intermediateInvoices"
import * as users from "./users"
import * as exports from './exports'
import * as commissions from "./commissions"
import * as usersGroups from "./usersGroups"
import * as compensationSettings from "./compensationSettings"
import * as invoiceNumber from "./invoiceNumber"
import * as consumptionAnalysis from "./consumptionAnalysis"
import * as producers from "./producers"
import * as mergedObjects from "./mergedObjects"
import * as interests from "./interests"
import * as mailer from "./mailer"
import * as communication from "./communication"
import * as penalties from "./penalties"
import * as userDaysOff from "./userDaysOff"
import * as reports from "./reports"

export const rootEpic = combineEpics(
  auth.login,
  auth.logout,
  auth.checkUser,
  data.getData,
  data.createData,
  data.getClientsForDropdown,
  clients.startGetClientsLoading,
  clients.getClients,
  clients.getClientsSuccess,
  clients.getClient,
  clients.createClient,
  clients.updateClient,
  clients.checkClientExists,
  clients.exportClients,
  clients.getClientsFilters,
  clients.clientsRedistributeDealers,
  clients.clientsRedistributeDealersFile,
  clients.massEditClientsPrice,
  objects.createObject,
  objects.updateObject,
  objects.getObjectsDocumentsMissing,
  objects.checkObjectExists,
  objects.removeCloneObject,
  apis.startApisFetch,
  offers.createOffer,
  contracts.createContract,
  contracts.updateContract,
  invoices.getInvoicesFilters,
  invoices.getInvoice,
  invoices.getInvoices,
  invoices.createInvoice,
  invoices.updateInvoice,
  invoices.generateInvoice,
  invoices.getInvoicesClient,
  invoices.updateInvoiceProgress,
  invoices.exportInvoicesGeneratedStats,
  invoices.exportInvoicesGeneratedInfo,
  invoices.exportInvoicesAIGeneratedStats,
  invoices.ignoreObjectForMonth,
  invoices.updateInvoiceSimple,
  invoices.updateInvoiceMany,
  invoices.updateInvoiceManyBonuses,

  intermediateInvoices.getIntermediateInvoices,
  intermediateInvoices.createIntermediateInvoices,

  files.getFilesClient,
  files.uploadFileClient,
  files.updateFileClient,
  files.deleteFileClient,
  comments.createComment,
  comments.updateComment,
  comments.deleteComment,
  connectedGroups.getConnectedGroups,
  connectedGroups.getConnectedGroup,
  connectedGroups.createConnectedGroup,
  connectedGroups.updateConnectedGroup,
  connectedGroups.deleteConnectedGroup,
  groups.getGroups,
  groups.getGroup,
  groups.createGroup,
  groups.updateGroup,
  groups.deleteGroup,
  register.sendDocumentsToERP,
  register.importConfirmationsFromERP,
  register.importCommentsFromERP,
  register.confirmStatusesRegister,
  register.importObligationsFromErp,
  register.sendObligationsEmails,
  notices.getNotices,
  notices.searchNotices,
  references.getReferenceInvoices,
  references.getReferenceImports,
  references.getReferenceClientsGroups,
  references.getReferenceMarket,
  references.getReferenceMarketAverage,
  references.getReferenceNomenclatures,
  references.getReferenceInvoicesDifferences,
  references.getReferenceInvoicesPregeneration,
  references.getReferenceInvoicesProgressMissing,
  references.getReferenceIntermediateInvoicesGenerated,
  references.getReferenceIntermediateInvoicesProgress,
  references.getReferenceObjectsIgnored,
  imports.checkImportsExist,
  imports.checkNetworkImportsExist,
  imports.deleteImport,
  imports.deleteNetworkImport,
  imports.getObjectsCount,
  imports.importMarketData,
  imports.importSTPData,
  imports.importLeaving,
  imports.confirmLeaving,
  imports.importDeposits,
  imports.confirmInterests,
  imports.confirmInterestsPayments,
  imports.importProducersData,
  imports.importAjurNumbers,
  imports.importAjurProducersNumbers,
  nomenclatures.createNomenclature,
  nomenclatures.getNomenclatures,
  annex.createAnnex,
  annex.updateAnnex,
  annex.deleteAnnex,
  annex.getAnnexesByClient,
  annex.createNotificationsAnnexes,
  annex.deleteNotificationsAnnexes,
  annex.getNotificationsAnnexes,
  annex.getNotificationsClients,
  annex.sendNotificationsAnnexes,
  annex.checkUpcomingAnnexes,
  annex.generateNotificationsAnnexes,
  annex.getTextForNotificationsAnnexes,
  activity.getClientActivity,
  invoiceGroups.getInvoiceGroupByClient,
  invoiceGroups.createInvoiceGroup,
  invoiceGroups.updateInvoiceGroup,
  invoiceGroups.deleteInvoiceGroup,
  users.getUser,
  users.getUsers,
  users.updateUser,
  users.getUserRoles,
  users.updateUserRole,
  users.createUser,
  users.getUserFilterPresets,
  users.createUserFilterPreset,
  users.applyUserFilterPreset,
  users.deleteUserFilterPreset,
  exports.exportAjur,
  exports.exportAjurAlternative,
  exports.exportAjurAlternativeProducers,
  exports.exportAjurCompensations,
  exports.exportAjurProducersTemplated,
  exports.exportAjurConvertor,
  exports.exportAjurNumbers,
  exports.exportCustoms,
  exports.exportEasypay,
  exports.exportEmailsErrors,
  exports.exportAjurProducersNumbers,
  commissions.getCommission,
  commissions.getCommissions,
  commissions.getCommissionsReference,
  commissions.getCommissionsReferenceDetails,
  commissions.getCommissionsCreatedReference,
  commissions.getCommissionsCreatedPayments,
  commissions.generateCommissions,
  commissions.generateCommissionsManually,
  commissions.findCommissionAgent,
  commissions.findCommissionClient,
  commissions.confirmCommissions,
  commissions.createCommission,
  commissions.editCommissions,
  commissions.updateCommissionSimple,
  commissions.deleteCommission,
  commissions.sendCommissions,
  usersGroups.getUsersGroups,
  usersGroups.getUsersGroup,
  usersGroups.createUsersGroup,
  usersGroups.updateUsersGroup,
  compensationSettings.getCompensationSettings,
  compensationSettings.createCompensationSettings,
  compensationSettings.updateCompensationSettings,
  compensationSettings.deleteCompensationSettings,
  invoiceNumber.getCurrentInvoiceNumber,
  invoiceNumber.setCurrentInvoiceNumber,
  consumptionAnalysis.getConsumptionAnalysis,
  producers.producersReferencesReady,
  producers.producersReferencesGeneration,
  producers.producersReferencesBrowse,
  producers.producersReferenceUpdate,
  producers.producersReferenceUpdateRestricted,
  producers.producersReferenceUpdateMany,
  producers.producersReferenceSingle,
  producers.producersReferenceDelete,
  producers.producersReferencesSendEmails,
  producers.producersInvoicesBrowse,
  producers.producersInvoiceCreate,
  producers.producersInvoiceUpdate,
  producers.handleProducersTemplatedInvoice,
  producers.producersCheckClientInvoices,
  producers.producersInvoiceGenerateManual,
  mergedObjects.getMergedObjects,
  mergedObjects.createMergedObjects,
  mergedObjects.updateMergedObjects,
  mergedObjects.deleteMergedObjects,
  interests.importInvoicePayments,
  interests.importInterestsPayments,
  interests.generateInterests,
  interests.generateInterestsPayments,
  interests.getInterests,
  interests.getInterest,
  interests.createInterest,
  interests.updateInterest,
  interests.deleteInterest,
  interests.exportInterestsAjur,
  mailer.sendEmails,
  communication.getCommunicationPeriod,
  communication.getCommunicationPeriodFiles,
  communication.getCommunicationNetwork,
  communication.getCommunicationNetworkFiles,
  communication.getCommunicationCorrectionsFiles,
  communication.applyCommunicationCorrectionsFiles,
  communication.getCommunicationObjects,
  communication.getCommunicationObjectsFiles,
  penalties.getPenalties,
  penalties.updatePenalty,
  penalties.sendPenalties,
  penalties.calculatePenalty,
  userDaysOff.createUserDaysOff,
  userDaysOff.getUserDaysOffByMonth,
  userDaysOff.getUserDaysOffByUser,
  userDaysOff.updateUserDaysOff,
  userDaysOff.deleteUserDaysOff,
  reports.getReportsRegistrations,
  reports.getReportsTrade,
  reports.getReportsPortfolio,
)
