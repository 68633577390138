import React, { useState, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { URL } from '../../../config/settings'
import './styles.scss'
import { transformURL } from 'utilities'

const ClientDocumentPreview = ({ title, type, documents }) => {
  const [selectedDocument, setSelectedDocument] = useState(0)

  const url = useMemo(() => {
    return type === 'erps' ? documents[selectedDocument].url : `${URL}/${type}/file/${documents[selectedDocument]._id}`
  }, [type, documents])

  return (
    <div className={`card-client-document-preview-container ${type}`}>
      <div className="card-client-document-preview-header row">
        <h3>{title}</h3>
        {documents?.length > 1 && type !== 'erps' && (
          <>
            <h3>
              {selectedDocument + 1}/{documents?.length ?? 0}
            </h3>
            <div
              className={`icon icon-arrow-right-2 icon-left ${selectedDocument === 0 && 'disabled'}`}
              onClick={() => selectedDocument > 0 && setSelectedDocument(selectedDocument - 1)}
            ></div>
            <div
              className={`icon icon-arrow-right-2 icon-right ${
                selectedDocument === documents?.length - 1 && 'disabled'
              }`}
              onClick={() => selectedDocument < documents?.length - 1 && setSelectedDocument(selectedDocument + 1)}
            ></div>
          </>
        )}
        <div className="icon icon-edit"></div>
        <a href={transformURL(url)} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open"></div>
        </a>

        <Button.Icon name="plus" size={28} />
      </div>
      <div className="card-client-document-preview-content">
        <div className="row">
          <p>Генериран на: {moment(documents[selectedDocument].createdAt).format('DD.MM.YYYY')}</p>
          {/*<Button.Raised text="Потвърди" />*/}
        </div>
        {type === 'erps' && (
          <Button.UnderlinedTab
            buttons={documents.map(({ name }) => name)}
            onChange={(tab) => setSelectedDocument(documents.findIndex(({ name }) => name === tab))}
          />
        )}
        <div className="document">
          <iframe style={{ pointerEvents: 'none' }} src={url} />
        </div>
      </div>
    </div>
  )
}

export default ClientDocumentPreview
