import React, { useCallback, useEffect, useState } from "react"
import "./styles.scss"
import { useDispatch } from "react-redux"
import { checkUser, getUserRoles, setOverlay } from "actions"
import { Shared } from "components"
import { renderCell } from "utilities"
import { Alerts } from "@makedonski/socourt-utilities/lib/Alerts"

const Permissions = () => {
    const dispatch = useDispatch()
    const [roles, setRoles] = useState([])
    const fetch = useCallback(() => dispatch(getUserRoles({ onSuccess: (res) => setRoles(res) })), [])
    useEffect(() => { fetch() }, [fetch])

    return <div className="references-permissions-container">
        <Shared.Table
            fixedWidth
            columns={[{ label: 'Роля', value: 'friendlyName' },]}
            data={roles}
            renderCell={(row, field) => {
                if (field === 'friendlyName') return <div className="row" style={{ width: '100%', paddingRight: 16, justifyContent: 'space-between' }}>
                    <span>{row[field]}</span>
                    <div className="icon icon-edit" onClick={() => dispatch(setOverlay({
                        isOpen: true, type: 'permissions', props: {
                            permission: row, onSuccess: () => {
                                dispatch(fetch())
                                dispatch(checkUser())
                                Alerts.success({ title: 'Успешно редактирана роля' })
                                dispatch(setOverlay({ isOpen: false }))
                            }
                        }
                    }))} />
                </div>
                return renderCell.default(row, field)
            }}
        />
    </div>
}

export default Permissions