import React, { useState } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setModal } from 'actions'
import { Inputs } from 'components'
import './styles.scss'

const ExistingFileWarning = ({ handleParse }) => {
  const dispatch = useDispatch()
  const [dontShowAgain, setDontShowAgain] = useState(false)
  return (
    <div className="modal-existing-file-warning-container">
      <div className="modal-existing-file-warning-header">
        <h2>Презаписване на файлове</h2>
        <Button.Icon
          name="plus"
          size="26px"
          className="btn-close"
          onClick={() => dispatch(setModal({ isOpen: false }))}
        />
      </div>
      <div className="modal-existing-file-warning-content col">
        <p>Всички дублирани файлове ще бъдат презаписани в системата след като потвърдите</p>
        <Inputs.Checkboxes
          buttons={['Не показвай повече това съобщение']}
          value={dontShowAgain ? 'Не показвай повече това съобщение' : ''}
          onClick={() => setDontShowAgain(!dontShowAgain)}
        />
      </div>
      <div className="modal-existing-file-warning-footer">
        <Button.Raised
          text="Потвърди"
          onClick={() => {
            if (dontShowAgain) localStorage.setItem('hideExistingFilesWarning', 'true')
            handleParse()
            dispatch(setModal({ isOpen: false }))
          }}
        />
      </div>
    </div>
  )
}

export default ExistingFileWarning
