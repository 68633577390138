import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Shared } from 'components'
import { referencesEmployeesFields } from 'config/constants'
import { mapQuerySearch, renderCell } from 'utilities'
import './styles.scss'
import { getUserRoles, getUsers, setReferencesFields, startLoading } from 'actions'
import { useQuery } from 'hooks'
import Popup from 'reactjs-popup'



const Employees = ({ }) => {
  const dispatch = useDispatch()
  const { reference: employees } = useSelector(({ references }) => references)

  const [roles, setRoles] = useState([])
  useEffect(() => { dispatch(getUserRoles({ onSuccess: (res) => setRoles(res) })) }, [])
  const availableFilters = useMemo(() => ({
    fullName: { type: 'regex' },
    role: { type: 'idDropdown', values: roles.map(({ friendlyName, name }) => ({ _id: name, name: friendlyName })), single: true },
    userGroups: { type: 'regex' },
  }), [roles])

  const { sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery()
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

  const fetch = () => {
    dispatch(startLoading())
    dispatch(getUsers({
      payload: { ...searchQuery },
      onSuccess: (res) => dispatch(setReferencesFields({ reference: res }))
    }))
  }
  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ reference: [] }))
  }, [searchQuery])

  const sortingComponents = referencesEmployeesFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
    ...acc,
    [value]: <Popup
      contentStyle={{ width: 'auto' }}
      keepTooltipInside='.table-container'
      trigger={<div className="icon icon-arrow-down" />}
      activeFilters={searchQuery}
    >
      {close => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={[]}
        availableFilters={availableFilters}
      />}
    </Popup>
  }), {})

  return (
    <div className="references-employees-container">
      <Shared.Table
        fixedWidth
        columns={referencesEmployeesFields}
        data={employees}
        renderCell={(row, field, options) => renderCell.references.employees(row, field, { ...options, fetch })}
        sortingComponent={sortingComponents}
      />
    </div>
  )
}

export default Employees
