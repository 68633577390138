// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shared-back-button-container {
  flex: 1 1;
  height: 100%;
  max-width: 263px;
  cursor: pointer;
}
.shared-back-button-container > img {
  width: 80%;
  height: auto;
  object-fit: contain;
}
.shared-back-button-container p {
  font-size: 1.12rem;
  line-height: 2.125rem;
}
.shared-back-button-container .icon {
  height: 34px;
  width: 34px;
}
.shared-back-button-container:active {
  transform: scale(0.95);
}`, "",{"version":3,"sources":["webpack://./src/components/Shared/BackButton/styles.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AACJ;AAAI;EACI,UAAA;EACA,YAAA;EACA,mBAAA;AAER;AAAI;EACI,kBAAA;EACA,qBAAA;AAER;AAAI;EACI,YAAA;EACA,WAAA;AAER;AAAI;EACI,sBAAA;AAER","sourcesContent":[".shared-back-button-container {\r\n    flex: 1;\r\n    height: 100%;\r\n    max-width: 263px;\r\n    cursor: pointer;\r\n    > img {\r\n        width: 80%;\r\n        height: auto;\r\n        object-fit: contain;\r\n    }\r\n    p {\r\n        font-size: 1.12rem;\r\n        line-height: 2.125rem;\r\n    }\r\n    .icon {\r\n        height: 34px;\r\n        width: 34px;\r\n    }\r\n    &:active {\r\n        transform: scale(0.95);\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
