import React from 'react'
import { Inputs } from '../../'
import './styles.scss'

const PercentageSplit = ({ value, onChange, ...props }) => {
  return (
    <div className="inputs-percentage-split-container row">
      <Inputs.TextLabeled label="%" value={value} onChange={({ target: { value } }) => onChange(value)} {...props} />
      <Inputs.TextLabeled
        label="%"
        value={value ? 100 - value : ''}
        onChange={({ target: { value } }) => onChange(100 - value)}
        {...props}
      />
    </div>
  )
}

export default PercentageSplit
