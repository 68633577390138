import { default as Main } from './Main'
import { default as MainProfile } from './MainProfile'
import { default as DocumentsAndContract } from './DocumentsAndContract'
import { default as AdditionalContact } from './AdditionalContact'
import { default as Object } from './Object'
import { default as Objects } from './Objects'
import { default as Offer } from './Offer'
import { default as Analysis } from './Analysis'
import { default as Contract } from './Contract'
import { default as ProductPrices } from './ProductPrices'
import { default as IntermediatePayments } from './IntermediatePayments'
import { default as AnnexMain } from './AnnexMain'
import { default as AnnexContract } from './AnnexContract'
import { default as AnnexResignContract } from './AnnexResignContract'
import { default as ClientPricing } from './ClientPricing'
import { default as ProducerSettings } from './ProducerSettings'
import { default as ProducerPricing } from './ProducerPricing'
import { default as ProducersAnnexMain } from './ProducersAnnexMain'
import { default as ProducersAnnexContract } from './ProducersAnnexContract'
import { default as ProducersAnnexResignContract } from './ProducersAnnexResignContract'

const Forms = {
  Main,
  MainProfile,
  DocumentsAndContract,
  AdditionalContact,
  Object,
  Objects,
  Offer,
  Analysis,
  Contract,
  ProductPrices,
  IntermediatePayments,
  AnnexMain,
  AnnexContract,
  AnnexResignContract,
  ClientPricing,
  ProducerSettings,
  ProducerPricing,
  ProducersAnnexMain,
  ProducersAnnexContract,
  ProducersAnnexResignContract,
}

export default Forms
