import { getCompensationSettings } from "actions"
import Shared from "components/Shared"
import { compensationSettingsFields } from "config/constants"
import { useQuery } from "hooks"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { handleCheck, renderCell } from "utilities"
import "./styles.scss"

const CompensationSettings = ({ }) => {
  const dispatch = useDispatch()
  const [data, setData] = useState()
  const fetch = () => dispatch(getCompensationSettings({ onSuccess: setData }))
  useEffect(() => { fetch() }, [])

  const { toFetch, handleUrlChangeMultiple } = useQuery()
  useEffect(() => { if (toFetch) { fetch(); handleUrlChangeMultiple({ toFetch: undefined }); } }, [toFetch])

  return <div className="reference-compensation-settings-container">
    <Shared.Table
      fixedWidth
      columns={compensationSettingsFields}
      data={data}
      renderCell={(row, field) => renderCell.compensationSettings(row, field, { fetch })}
    />
  </div>
}

export default CompensationSettings