import React from 'react'
import { allRegistrationStatuses, clientsStatus } from 'config/constants'
import { useDispatch } from 'react-redux'
import { updateObject, setModal } from 'actions'
import { useQuery } from 'hooks'
import './styles.scss'

const ObjectStatus = ({ object, value, fetch }) => {
  const dispatch = useDispatch()
  const { type } = useQuery()
  const { _id, status } = object || {}

  return (
    <div className="client-cells-object-status-container row">
      {clientsStatus.find((type) => type?.value === value)?.label}
      {['register', 'producersRegistration'].includes(type) && allRegistrationStatuses.includes(status) && (
        <div
          className="icon icon-edit"
          onClick={() => dispatch(setModal({ isOpen: true, type: 'clientsStatus', props: { fetch, object } }))}
        />
      )}
    </div>
  )
}

export default ObjectStatus
