import React, { useEffect, useState, useRef } from "react"
import { Button } from '@makedonski/admin-ui'
import { Alerts } from "@makedonski/socourt-utilities/"
import { useDispatch } from "react-redux"
import { isArray, isEmpty, pick, omit } from "lodash"
import { createComment, createInvoice, getInvoice, getNomenclatures, setModal, startLoading, updateInvoice } from "actions"
import { Invoices } from 'components'
import { useQuery } from "hooks"
import { toNumber } from "utilities"
import "./styles.scss"

const requiredFields = ["invoiceDate", "dateOfPayment", "invoicePeriodStart", "invoicePeriodEnd"]

const InvoicesCreate = () => {
  const dispatch = useDispatch()
  const clientFormRef = useRef(null)
  const { invoice: _id } = useQuery()
  useEffect(() => {
    if (_id) fetch()
    dispatch(getNomenclatures({ onSuccess: (res) => setNomenclatures(res) }))
  }, [])
  const fetch = () => {
    dispatch(startLoading())
    dispatch(getInvoice({
      payload: _id, onSuccess: (res) => {
        setCompensations(res?.compensations)

        setAdditionalCompensations(
          Object.entries(res?.pricing?.additionalInformation || {})
            ?.filter(([key]) => !(key.replaceAll('_', ".")).includes(res?.compensations?.compensationsText))
            ?.filter(([key]) => !(key.replaceAll('_', ".")).includes("Стойност на сделката"))
            ?.filter(([key]) => !(key.replaceAll('_', ".")).includes("Неустойка за"))
            ?.map(([key, value]) => ({ key, value }))
        )

        setInvoice({
          ...pick(res, ["_id", "deletedAt", ...requiredFields, "invoiceGroup", "total", "pricing", 'exportedAjur']),
          client: res.client._id,
          objects: res.objects?.map(({ _id, erp, address, itn, measureType }) => {
            const nameInInvoice = Object.keys(res.results).find(key => key.includes(itn))
            return {
              _id, erp, address, itn, nameInInvoice, measureType,
              results: res.results?.[nameInInvoice],
              tech: res.tech?.[nameInInvoice]
            }
          })
        })
        clientFormRef?.current?.setClient({ ...res.client })
      }
    }))
  }

  const [nomenclatures, setNomenclatures] = useState([])
  const [compensations, setCompensations] = useState()
  const [additionalCompensations, setAdditionalCompensations] = useState([])

  const [invoice, setInvoice] = useState({})
  const handleChange = (payload) => setInvoice({ ...invoice, ...payload })

  const [showRequired, setShowRequired] = useState(false)
  const isValid = (payload) => {
    let flag = true
    const { client, invoiceGroup, results } = payload || {}
    if (requiredFields.some(f => !invoice?.[f])) {
      Alerts.error('Моля попълнете всички полета в "Основна информация"')
      flag = false
    } else if (!client) {
      Alerts.error('Моля изберете клеинт')
      flag = false
    } else if (isArray(invoiceGroup) && !isEmpty(invoiceGroup)) {
      Alerts.error('Моля изберете група фактури')
      flag = false
    } else if (additionalCompensations?.some(({ key, value }) => !key || !value)) {
      Alerts.error('Моля попълнете всички полета в "Компенсации"')
      flag = false
    } else {
      Object.entries(results).forEach(([key, value]) => {
        value.forEach(row => {
          if (["cipher", "erp", "itn", "name", "quantity", "singleUnitPrice", "unit", "value"].some(f => [undefined, null, ""].includes(row?.[f]))) {
            Alerts.error(key, `Моля попълнете липсващите стойности или изтрийте ненужните редове`)
            flag = false
          } else if (["quantity", "singleUnitPrice", "value"].some(f => isNaN(Number(row?.[f])))) {
            Alerts.error(key, `Навалидна стойност`)
            flag = false
          }
        })
      })
    }

    return flag
  }

  const onClear = () => {
    if (_id) fetch()
    else {
      setInvoice({})
      clientFormRef?.current?.setClient({})
    }
    setShowRequired(false)
  }

  const onSave = () => {
    const payload = getPayload()
    if (!isValid(payload)) {
      setShowRequired(true)
      return
    } else {
      dispatch(startLoading())
      payload.invoiceGroup = invoice?.invoiceGroup?._id === "notGrouped" ? undefined : invoice?.invoiceGroup?._id
      if (_id) dispatch(updateInvoice({ payload }))
      else dispatch(createInvoice({ payload }))
    }
  }

  const onDelete = () => {
    dispatch(startLoading())
    dispatch(updateInvoice({ payload: { _id, deletedAt: new Date() } }))
  }

  const onReverseDelete = () => {
    dispatch(startLoading())
    dispatch(updateInvoice({ payload: { _id, $unset: { deletedAt: 1 } } }))
  }

  const onDeleteAndSave = () => {
    const payload = getPayload()
    if (!isValid(payload)) {
      setShowRequired(true)
      return
    } else {
      dispatch(startLoading())
      payload.invoiceGroup = invoice?.invoiceGroup?._id === "notGrouped" ? undefined : invoice?.invoiceGroup?._id
      dispatch(updateInvoice({
        payload: { _id, deletedAt: new Date(), justDelete: false },
        onSuccess: () => {
          dispatch(startLoading())
          dispatch(createInvoice({ payload: { ...omit(payload, ['_id', 'exportedAjur']), canceledInvoice: _id } }))
        }
      }))
    }
  }

  const handleComment = ({ comment, onSuccess } = {}) => {
    if (comment) dispatch(createComment({
      payload: { refType: 'Invoices', ref: _id, text: comment, type: 'default' },
      onSuccess
    }))
    else onSuccess()
  }

  const getPayload = () => ({
    ...invoice,
    objects: invoice?.objects?.map(({ _id }) => _id),
    tech: invoice?.objects?.reduce((acc, obj) => ({
      ...acc,
      [obj.nameInInvoice]: obj?.tech?.filter((t) => Object.keys(t || {}).length > 3)?.map(r => ({ ...r, address: obj.address, itn: obj.itn })) || []
    }), {}),
    results: invoice?.objects?.reduce((acc, obj) => ({
      ...acc,
      [obj.nameInInvoice]: obj?.results?.map(r => ({
        ...r,
        singleUnitPrice: isNaN(Number(r.singleUnitPrice)) ? r.singleUnitPrice : toNumber(r.singleUnitPrice || 0),
        quantity: isNaN(Number(r.quantity)) ? r.quantity : toNumber(r.quantity || 0),
        erp: obj.erp,
        itn: obj.itn
      })) || []
    }), {}),
    pricing: {
      ...invoice?.pricing,
      additionalInformation: {
        'Стойност на сделката': invoice.pricing.withVat,
        ...invoice?.pricing?.additionalInformation,
        ...additionalCompensations?.reduce((acc, { key, value }) => ({ ...acc, [key]: toNumber(value) }), {})
      },
    }
  })

  const props = { invoice, handleChange, showRequired, nomenclatures, compensations, additionalCompensations, setAdditionalCompensations }

  return <div className="screen-invoices-create-container">
    <div className="screen-invoices-create-header row">
      <h2>{_id ? "Редакция" : "Създаване"} на фактура</h2>
      <div className="row row-buttons">
        <Button.Raised className="btn-clear" text="Откажи" onClick={onClear} />
        {invoice.deletedAt
          ? <Button.Raised text='Отанулирай' onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { title: 'Отанулиране', onClick: (comment) => handleComment({ comment, onSuccess: onReverseDelete }) } }))} />
          : <>
            {_id && <>
              <Button.Raised text="Анулирай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDelete }) } }))} />
              <Button.Raised text="Анулирай и преиздай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDeleteAndSave }) } }))} />
            </>}
            <Button.Raised text={_id ? "Редактирай" : "Генерирай"} onClick={onSave} />
          </>}
      </div>
    </div>
    <div className={`screen-invoices-create-content col${invoice.deletedAt ? ' disabled' : ''}`}>
      <Invoices.Forms.Main {...props} />
      <Invoices.Forms.Client ref={clientFormRef} {...props} />
      {!isEmpty(invoice?.invoiceGroup) && <Invoices.Forms.InvoiceGroup {...props} />}
      {(!invoice?.invoiceGroup || !isArray(invoice?.invoiceGroup) || isEmpty(invoice?.invoiceGroup)) && <>
        {invoice?.objects?.map(({ _id }, i) => <Invoices.Forms.Object key={_id} i={i} {...props} />)}
        {invoice.client && <>
          <Invoices.Forms.Total  {...props} />
          {Object.keys(invoice?.pricing?.extras ?? {}).length > 0 && <Invoices.Forms.Deposits {...props} />}
          {Object.keys(invoice?.pricing?.additionalInformation ?? {}).length > 0 && <Invoices.Forms.Interests {...props} />}
          <Invoices.Forms.Compensations {...props} />
          <Invoices.Forms.Pricing {...props} />
        </>}
      </>}
    </div>

  </div>
}

export default InvoicesCreate