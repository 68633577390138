import React, { useMemo } from "react"
import { Inputs } from "components"
import "./styles.scss"
import { flatten, isArray } from "lodash"

const InvoiceGroup = ({ invoice, handleChange, showRequired }) => {
    const { _id, invoiceGroup } = invoice || {}

    const clientObjects = useMemo(() => invoice?.objects, [_id])
    const notInGroups = useMemo(() => {
        if (!invoiceGroup || !isArray(invoiceGroup)) return []
        const inGroups = flatten(invoiceGroup?.map(({ objects }) => (objects)))
        return clientObjects?.map(({ _id }) => _id)?.filter(obj => !inGroups.includes(obj))
    }, [_id])
    const options = useMemo(() => {
        if (!invoiceGroup || !isArray(invoiceGroup)) return []
        const groups = invoiceGroup?.map(({ _id, name, objects }) => ({ value: _id, label: name, objects }))
        if (notInGroups.length) groups.push({ label: "Обекти без група", value: "notGrouped" })
        return groups
    }, [_id])

    return <div className="invoices-forms-invoice-group-container row" style={{ zIndex: (invoice?.objects?.length ?? 0) + 6 }}>
        <h2>Група фактури</h2>
        {_id
            ? <Inputs.Dropdown options={[{ value: invoiceGroup?._id, label: invoiceGroup.name }]} value={invoiceGroup._id} disabled />
            : <Inputs.Dropdown
                options={options}
                value={invoiceGroup?._id || invoiceGroup}
                onChange={({ value, label, objects }) => handleChange({
                    invoiceGroup: value === 'notGrouped' ? { _id: value, name: label } : { _id: value, name: label },
                    objects: clientObjects?.filter(({ _id }) => value === 'notGrouped' ? notInGroups.includes(_id) : objects.includes(_id))
                })}
                className={showRequired && !_id && !invoiceGroup?._id && 'required'}
            />}

    </div>
}

export default InvoiceGroup