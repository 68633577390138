import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Input } from '@makedonski/admin-ui'
import moment from 'moment'
import './styles.scss'
import { deleteComment } from 'actions'
import { useDispatch, useSelector } from 'react-redux'

const Comments = ({ comments, handleSend, handleDelete, disabled }) => {
  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const isAdmin = useMemo(() => {
    const rolesMapped = roles?.map(({ name }) => name)
    return ['admin', 'master'].some((role) => rolesMapped.includes(role))
  }, [roles])

  const dispatch = useDispatch()
  const [text, setText] = useState()

  const scrollRef = useRef()
  useEffect(() => {
    if (scrollRef?.current) scrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [scrollRef?.current, comments])

  return (
    <div className="inputs-comments-container col">
      <div className="comments-scroll-container col">
        {comments?.map(({ _id, owner, createdAt, text }, i) => (
          <div key={`single-comment-${i}`} className="single-comment-container">
            <div className="row row-details">
              <div className="single-member row">
                {owner?.coverPhoto ? (
                  <div className="profile-image" style={{ backgroundImage: `url(${owner?.coverPhoto})` }} />
                ) : (
                  <div className="icon icon-profile no-pointer" />
                )}
                <p className="member-fullName">{owner?.fullName}</p>
              </div>
              <div className="row">
                <p>{createdAt && moment(createdAt).format('HH:mm | DD.MM.YYYY')}</p>
                {isAdmin && <div
                  className="icon icon-delete"
                  onClick={() => dispatch(deleteComment({ payload: { _id }, onSuccess: handleDelete }))}
                />}
              </div>
            </div>
            <div className="row row-text">
              <p>{text}</p>
            </div>
          </div>
        ))}
        <div ref={scrollRef} />
      </div>

      <div className="comment-input row">
        <div className="text-input-outer-container">
          <Input.Text
            disabled={disabled}
            placeholder="Добавете коментар "
            value={text || ''}
            onChange={({ target: { value } }) => setText(value)}
            onKeyDown={({ key }) => {
              if (key === 'Enter' && handleSend && !disabled) {
                handleSend(text)
                setText('')
              }
            }}
          />
          <div
            className="icon icon-arrow-right-2-white ripple"
            onClick={() => {
              if (disabled) return
              if (handleSend) handleSend(text)
              setText('')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Comments
