import React, { useEffect, useMemo, useState } from "react"
import { Button, Input } from "@makedonski/admin-ui"
import "./styles.scss"
import { transformURL, uploadFile } from "utilities"
import { producersReferenceSingle, producersReferenceUpdateRestricted, startLoading, stopLoading } from "actions"
import { useDispatch } from "react-redux"
import { useQuery } from "hooks"

const ProducersTemplatedInvoiceUpload = ({ }) => {

  const dispatch = useDispatch()
  const { _id, client } = useQuery()

  const [error, setError] = useState(!_id || !client ? 'Невалиден линк' : '')
  const [producerStat, setProducerStat] = useState()
  const [file, setFile] = useState()

  useEffect(() => {
    if (!_id || !client) return
    dispatch(startLoading())
    dispatch(producersReferenceSingle({ payload: { _id, client }, onSuccess: setProducerStat, onError: setError }))
  }, [_id, dispatch])

  const fileExtension = useMemo(() => { const splitted = producerStat?.templatedInvoice?.pdfFile?.split('.') ?? []; return splitted[splitted.length - 1] }, [producerStat])

  const handleUpload = async (files) => {
    dispatch(startLoading())
    const { location } = await uploadFile({ file: files[0], createId: true })
    setFile(location)
    dispatch(stopLoading())
  }

  const handleUpdate = () => {
    dispatch(startLoading())
    dispatch(producersReferenceUpdateRestricted({ payload: { _id, updatedTemplatedInvoice: { fileUploadDate: new Date(), pdfFile: file } }, onSuccess: setProducerStat, stopLoading: true }))
  }

  if (error) return <div className="row" style={{ height: '100vh', width: '100vw', justifyContent: 'center', fontWeight: 300, fontSize: 30, textTransform: 'uppercase' }}>{error}</div>

  if (producerStat?.templatedInvoice?.confirmed === 'Върната') return <div className="screen-producers-templated-invoice-upload-container col">
    <div className="screen-producers-templated-invoice-upload-header row">
      <img alt="Logo" src={process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : require('../../assets/images/logo.png')} />
    </div>
    <div className="screen-producers-templated-invoice-upload-content row">
      <div className="col col-preview">
        <p>Стара фактура</p>
        {['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension)
          ? <div className="file-empty-state row">Файлът не може да бъде визуализиран</div>
          : <object data={transformURL(producerStat?.templatedInvoice?.pdfFile)} type="application/pdf" width="100%" height="100%" />}
      </div>
      <div style={{ width: '2vw' }} />
      <div className="col">
        <p>Коригирана фактура</p>
        <div className={`col col-uploaded-container`}>
          {file
            ? <div className="col col-uploaded-content">
              <div className="icon icon-upload-orange" />
              <div className="row row-fileName">
                <p className="fileName">Име на файл: <span>{file.split('/').pop()}</span></p>
                <div className="icon icon-remove" onClick={() => setFile(null)} />
              </div>
            </div>
            : <Input.DragAndDrop handleDrop={(files) => handleUpload(files)}>
              <div className="col col-uploaded-content">
                <div className="icon icon-upload" />
                <div className="row row-button">
                  <p>Постави коригираната фактура тук</p>
                  <span>ИЛИ</span>
                  <Button.UploadButton
                    text="Прикачи"
                    accept='*'
                    onChange={({ target: { files } }) => handleUpload(files)}
                  />
                </div>
              </div>
            </Input.DragAndDrop>
          }
        </div>
        <Button.Raised text="Запази" onClick={handleUpdate} disabled={!file} />
      </div>
    </div>
  </div>

  return <div className="screen-producers-templated-invoice-upload-container col">
    <div className="screen-producers-templated-invoice-upload-header row">
      <img alt="Logo" src={process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : require('../../assets/images/logo.png')} />
    </div>
    <div className="screen-producers-templated-invoice-upload-content row">
      <div className="col col-preview">
        <p>{producerStat?.templatedInvoice?.pdfFile ? 'Фактура' : 'Справка'}</p>
        {['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension)
          ? <div className="file-empty-state row">Файлът не може да бъде визуализиран</div>
          : <object data={transformURL(producerStat?.templatedInvoice?.pdfFile ?? producerStat?.file)} type="application/pdf" width="100%" height="100%" />}
      </div>
      <div style={{ width: '2vw' }} />
      <div className="col">
        <p>&nbsp;</p>
        <div className={`col col-uploaded-container${producerStat?.templatedInvoice?.pdfFile ? ' uploaded' : ''}`}>
          {producerStat?.templatedInvoice?.pdfFile
            ? <div className="col col-uploaded-content">
              <div className="icon icon-success" />
              <p className="success">Вашият файл е изпратен</p>
              <p className="success-sub">Ако желаете да коригирате фактурата, да я замените или имате нужда от съдействие, моля свържете се с нас{process.env.REACT_APP_PLATFORM === 'Synergon' ? ' на vei.finance@synergonenergy.bg' : ''}.</p>
              <div className="row row-fileName">
                <p className="fileName">Име на файл: <span>{producerStat?.templatedInvoice?.pdfFile?.split('/').pop().split('_').slice(1).join('_')}</span></p>
              </div>
            </div>
            : file
              ? <div className="col col-uploaded-content">
                <div className="icon icon-upload-orange" />
                <div className="row row-fileName">
                  <p className="fileName">Име на файл: <span>{file.split('/').pop()}</span></p>
                  <div className="icon icon-remove" onClick={() => setFile(null)} />
                </div>
              </div>
              : <Input.DragAndDrop handleDrop={(files) => handleUpload(files)}>
                <div className="col col-uploaded-content">
                  <div className="icon icon-upload" />
                  <div className="row row-button">
                    <p>Постави файл тук</p>
                    <span>ИЛИ</span>
                    <Button.UploadButton
                      text="Прикачи"
                      accept='*'
                      onChange={({ target: { files } }) => handleUpload(files)}
                    />
                  </div>
                </div>
              </Input.DragAndDrop>
          }
        </div>
        {!producerStat?.templatedInvoice?.pdfFile && <Button.Raised text="Запази" onClick={handleUpdate} disabled={!file} />}
      </div>
    </div>
  </div>
}

export default ProducersTemplatedInvoiceUpload