import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { usersGroupsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getUsersGroups = (action$) => action$.pipe(
    ofType(usersGroupsTypes.GET_USERS_GROUPS),
    switchMap(({ onSuccess }) =>
        Api.get(`${URL}/userGroup`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.userGroups)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    ))


export const getUsersGroup = (action$) => action$.pipe(
    ofType(usersGroupsTypes.GET_USERS_GROUP),
    switchMap(({ payload, onSuccess }) =>
        Api.get(`${URL}/userGroup/${payload}`).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.userGroup)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    ))

export const createUsersGroup = (action$) => action$.pipe(
    ofType(usersGroupsTypes.CREATE_USERS_GROUP),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/userGroup`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.userGroup)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    ))

export const updateUsersGroup = (action$) => action$.pipe(
    ofType(usersGroupsTypes.UPDATE_USERS_GROUP),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/userGroup`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.userGroup)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    ))

