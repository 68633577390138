import { useEffect, useMemo, useState } from "react"
import { useQuery } from "./useQuery"
import { Popup as PopupComponents } from "components"
import Popup from "reactjs-popup"

const { setModal } = require("actions")
const { useDispatch, useSelector } = require("react-redux")

export const useTableColumnsManager = ({ defaultFields, type }) => {
    const dispatch = useDispatch()
    const { handleUrlChangeMultiple } = useQuery()

    const [currentFields, setCurrentFields] = useState(defaultFields)
    const [lockedColumn, setLockedColumn] = useState(null)

    const handleColumnsManager = () => dispatch(setModal({ isOpen: true, type: 'tableColumnsManager', props: { defaultFields, currentFields, onSuccess: setCurrentFields } }))

    const { filterPresets = [] } = useSelector(({ general }) => general?.currentUser) || {}
    const selectedFilterPreset = useMemo(() => filterPresets.filter((f) => f.type === type).find(({ applied }) => applied), [filterPresets])

    const setPreset = (preset) => {
        const { lockedColumn, fields, searchBy, search, sort, filter } = selectedFilterPreset?.preset || {}
        setCurrentFields(fields)
        setLockedColumn(lockedColumn)
        handleUrlChangeMultiple({ searchBy, search, sort, filter })
    }

    useEffect(() => {
        if (selectedFilterPreset) setPreset()
    }, [selectedFilterPreset, type])

    const props = { defaultFields, currentFields, setCurrentFields, lockedColumn, type, setLockedColumn, selectedFilterPreset, setPreset }

    const handleFilterPresets = () => {
        return <Popup
            contentStyle={{ width: 'auto' }}
            trigger={<div className="row">
                <span style={{ marginRight: 5, fontWeight: 700 }} className='selectedFilterPreset'>{selectedFilterPreset?.name}</span>
                <div
                    style={{ border: '1px solid rgba(0, 52, 112, 0.3)', height: 40, width: 40, backgroundSize: '55%', borderRadius: 6 }}
                    className='btn-other-filters icon icon-filter' />
            </div>}>
            {close => <PopupComponents.TableFilterPresets hide={close} {...props} />}
        </Popup>
    }


    return { currentFields, handleColumnsManager, handleFilterPresets, lockedColumn, setLockedColumn }

}