import React from "react"
import "./styles.scss"
import { useQuery } from "hooks"
import moment from "moment"

const ReportsRegistration = () => {
  const { startDate = moment().format('MM/YYYY') } = useQuery()

  return <div className="references-reports-registration-container">ReportsRegistration</div>
}

export default ReportsRegistration