export const dataTypes = {
  SET_FIELD: 'data/SET_FIELD',
  SET_FIELDS: 'data/SET_FIELDS',

  GET_DATA: 'data/GET_DATA',
  GET_DATA_SUCCESS: 'data/GET_DATA_SUCCESS',

  CREATE_DATA: 'data/CREATE_DATA',

  GET_CLIENTS_FOR_DROPDOWN: 'data/GET_CLIENTS_FOR_DROPDOWN',
}

export const setDataField = (payload) => ({
  type: dataTypes.SET_FIELD,
  payload,
})

export const setDataFields = (payload) => ({
  type: dataTypes.SET_FIELDS,
  payload,
})

export const getData = (payload) => ({
  type: dataTypes.GET_DATA,
  payload,
})

export const createData = (payload) => ({
  type: dataTypes.CREATE_DATA,
  payload,
})

export const getClientsForDropdown = (payload) => ({
  type: dataTypes.GET_CLIENTS_FOR_DROPDOWN,
  payload,
})
