// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-clients-field-filter-container {
  min-width: 200px;
  padding: 5px 10px;
  position: relative;
}
.popup-clients-field-filter-container > .icon-container {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(45deg);
}
.popup-clients-field-filter-container .checkboxes-container {
  max-height: 200px;
  overflow-y: auto;
}
.popup-clients-field-filter-container .raised-button-container {
  margin-left: 15px;
}
.popup-clients-field-filter-container .label-container p {
  margin: 6px 0;
  line-height: 1.25rem;
}
.popup-clients-field-filter-container h3 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin: 10px 0;
}
.popup-clients-field-filter-container p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 10px 0;
}
.popup-clients-field-filter-container p.btn-clear {
  cursor: pointer;
}
.popup-clients-field-filter-container p.btn-clear:active {
  transform: scale(0.95);
}
.popup-clients-field-filter-container > div:not(:last-child) {
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Popup/ClientsFieldFilter/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,wBAAA;AAEJ;AACE;EACE,iBAAA;EACA,gBAAA;AACJ;AACE;EACE,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,oBAAA;AAAJ;AAGE;EACE,eAAA;EACA,oBAAA;EACA,gBAAA;EACA,cAAA;AADJ;AAGE;EACE,mBAAA;EACA,oBAAA;EACA,cAAA;AADJ;AAEI;EACE,eAAA;AAAN;AACM;EACE,sBAAA;AACR;AAGE;EACE,mBAAA;AADJ","sourcesContent":[".popup-clients-field-filter-container {\r\n  min-width: 200px;\r\n  padding: 5px 10px;\r\n  position: relative;\r\n  > .icon-container {\r\n    position: absolute;\r\n    top: 0;\r\n    right: 0;\r\n    transform: rotate(45deg);\r\n  }\r\n\r\n  .checkboxes-container {\r\n    max-height: 200px;\r\n    overflow-y: auto;\r\n  }\r\n  .raised-button-container {\r\n    margin-left: 15px;\r\n  }\r\n\r\n  .label-container p {\r\n    margin: 6px 0;\r\n    line-height: 1.25rem;\r\n  }\r\n\r\n  h3 {\r\n    font-size: 1rem;\r\n    line-height: 1.25rem;\r\n    font-weight: 700;\r\n    margin: 10px 0;\r\n  }\r\n  p {\r\n    font-size: 0.875rem;\r\n    line-height: 1.25rem;\r\n    margin: 10px 0;\r\n    &.btn-clear {\r\n      cursor: pointer;\r\n      &:active {\r\n        transform: scale(0.95);\r\n      }\r\n    }\r\n  }\r\n  > div:not(:last-child) {\r\n    margin-bottom: 30px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
