export const reportsTypes = {
    GET_REPORTS_REGISTRATIONS: 'reports/GET_REPORTS_REGISTRATIONS:',
    GET_REPORTS_TRADE: 'reports/GET_REPORTS_TRADE',
    GET_REPORTS_PORTFOLIO: 'reports/GET_REPORTS_PORTFOLIO',
}

export const getReportsRegistrations = (payload) => ({
    type: reportsTypes.GET_REPORTS_REGISTRATIONS,
    ...payload
})

export const getReportsTrade = (payload) => ({
    type: reportsTypes.GET_REPORTS_TRADE,
    ...payload
})

export const getReportsPortfolio = (payload) => ({
    type: reportsTypes.GET_REPORTS_PORTFOLIO,
    ...payload
})