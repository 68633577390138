import React, { useState, useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useQuery } from 'hooks'
import { startLoading, stopLoading, setModal, getClient, setClientsFields, sendDocumentsToERP } from 'actions'
import { Shared } from 'components'
import { clientsObjectsFields } from 'config/constants'
import './styles.scss'
import { renderCell } from 'utilities'

const ObjectsTransfer = ({ }) => {
  const { ids } = useQuery()
  const [clients, setClients] = useState([])
  const [objects, setObjects] = useState()

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(startLoading())
    Promise.all(
      ids?.split(',')?.map((payload) => {
        return new Promise((res) => dispatch(getClient({ payload, onSuccess: (c) => res(c) })))
      })
    ).then((response) => {
      setClients(response)
      dispatch(stopLoading())
    })
    return () => dispatch(setClientsFields({ client: {} }))
  }, [])

  const handleConfirm = () => {
    dispatch(startLoading())
    dispatch(
      sendDocumentsToERP({
        data: {
          toProcess: objects?.map((object) => ({
            client: clients?.find(({ objects: objs }) => objs.some(({ _id }) => _id === object))._id,
            object,
          })),
        },
        onSuccess: () =>
          dispatch(
            setModal({
              isOpen: true,
              type: 'confirmation',
              props: {
                title: 'Изпратени документи',
                children: 'Успешно генерирани и изпратени документи към ЕРП',
                onClick: () => history.goBack(),
              },
            })
          ),
      })
    )
  }

  return (
    <div className="screen-objects-transfer-container">
      <div className="screen-objects-transfer-header row">
        <h2>Избор на точки</h2>
        <Button.Raised text="Продължи" disabled={isEmpty(objects)} onClick={handleConfirm} />
      </div>
      <div className="screen-objects-transfer-content">
        {clients?.map((client) => (
          <div key={client._id} className="client-container">
            <div className="client-header row">
              <p>
                Име на клиент: <span>{client?.fullName}</span>
              </p>
              <p>
                ЕИК/ЕГН: <span>{client?.eic || client?.pin}</span>
              </p>
            </div>
            <div className="client-objects-container">
              <Shared.Table
                columns={clientsObjectsFields?.filter(({ value }) => {
                  return !['status', 'confirmed', 'leaving'].includes(value)
                })}
                data={client?.objects?.filter(({ status }) => status === 'transfer')}
                renderCell={renderCell.objects}
                fixedWidth
                useCheckboxes
                hideSelectAll
                selectedCheckboxes={objects}
                onCheckboxChange={(value) => setObjects(value)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ObjectsTransfer
