import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment'
import { isEmpty, omit } from "lodash"
import { useDispatch, useSelector } from 'react-redux'
import { updateAnnex, getData, getUsers, startLoading, stopLoading } from 'actions'
import { Inputs, Popup as PopupComponents } from 'components'
import { uploadFile, formatAnnexResponse, transformURL } from 'utilities'
import "./styles.scss"
import Popup from "reactjs-popup"

const AnnexResignContract = forwardRef(({
    isClientProfile,
    isApplied,
    editable,
    setEditable,
    setSelectedObject,
    data,
    setData: setDataProp,
    showRequired,
    handleChange: handleChangeProp,
    client,
}, ref) => {
    const { contractSettings } = client || {}

    const dispatch = useDispatch()
    const handleUpload = async (files) => {
        dispatch(startLoading())
        const { location } = await uploadFile({ file: files[0], createId: true })
        dispatch(stopLoading())
        handleChange({ file: { url: location, name: files[0].name } })
    }

    const [edits, setEdits] = useState()
    const handleEdits = (payload) => setEdits({ ...edits, ...payload })
    const handleChange = (payload) => isClientProfile ? handleEdits(payload) : handleChangeProp(payload)
    const setData = (payload) => isClientProfile ? setEdits(payload) : setDataProp(payload)
    const onClear = (mainForm) => {
        setEditable(false)
        setEdits()
        mainForm?.onClear()
    }
    const onSave = (mainForm) => {
        if (edits?.file && isEmpty(edits?.file)) {
            Alerts.error('Моля добавете файл, за да запазите промените!')
            return
        } else {
            dispatch(startLoading())
            dispatch(
                updateAnnex({
                    payload: { _id: data?._id, client: data?.client, ...edits, ...mainForm?.edits },
                    onSuccess: (annex) => {
                        setSelectedObject(formatAnnexResponse(annex))
                        onClear(mainForm)
                    },
                })
            )
        }
    }

    useImperativeHandle(ref, () => ({ onClear, onSave }), [onClear, onSave])

    const { versions } = useSelector(({ data }) => data)
    useEffect(() => { if (!versions) dispatch(getData('versions')) }, [])

    const [dealers, setDealers] = useState([])
    const getUsersPayload = { select: 'fullName coverPhoto', role: 'dealer' }
    const updateDealers = (fullName) => dispatch(getUsers({
        payload: { ...getUsersPayload, fullName },
        onSuccess: (res) => setDealers((dealers) => [...(dealers || []), res])
    }))
    useEffect(() => { dispatch(getUsers({ payload: getUsersPayload, onSuccess: (res) => setDealers(res) })) }, [])

    return <div className="client-forms-annex-resign-contract-container">
        <h2>Данни за договор</h2>
        <div className="row">
            <div className="col">
                <span>Край настоящ договор</span>
                <Inputs.DatePicker value={!isClientProfile && contractSettings?.deliveryEnd} disabled />
            </div>
            <div className="col">
                <span>Дата на договор</span>
                <Inputs.DatePicker
                    value={isClientProfile ? edits?.contractDate || data?.contractDate : data?.contractDate || contractSettings?.contractDate}
                    onChange={(value) => handleChange({ contractDate: value })}
                    disabled={isApplied || !editable}
                />
            </div>
            <div className="col">
                <span>№ на договор</span>
                <Input.Text
                    value={isClientProfile ? edits?.contractNumber ?? data?.contractNumber ?? "" : data?.contractNumber ?? contractSettings?.contractNumber ?? ''}
                    onChange={({ target: { value } }) => handleChange({ contractNumber: value })}
                    disabled={isApplied || !editable}
                />
            </div>
        </div>
        <div className="row">
            <div className="col col-big">
                <span>Тип договор</span>
                <Inputs.RadioButtons
                    buttons={['Комбиниран', 'Стандартен']}
                    value={edits?.type || data?.type || contractSettings?.type}
                    onClick={(value) => setData({ ...omit(isClientProfile ? edits : data, ['version', 'declaration']), type: value, })}
                    disabled={isApplied || !editable}
                />
            </div>
            <div className="col">
                <span>Версия договор</span>
                <Inputs.Dropdown
                    options={versions
                        ?.filter(({ _id, type, deletedAt }) => type === (data?.type || contractSettings?.type) && (!deletedAt || _id === (data?.version || contractSettings?.version)))
                        ?.map(({ _id, name }) => ({ value: _id, label: name }))}
                    value={edits?.version || data?.version || contractSettings?.version}
                    onChange={({ value }) => handleChange({ version: value })}
                    disabled={isApplied || !editable}
                />
            </div>
        </div>
        <div className="row">
            <div className="col col-big">
                <span>Вид срочност</span>
                <Inputs.RadioButtons
                    buttons={['Брой месеци', 'Фиксиран край']}
                    value={edits?.maturity || data?.maturity || contractSettings?.maturity}
                    onClick={(value) => setData({ ...omit(isClientProfile ? edits : data, ['contractDurationMonths', 'deliveryEnd']), maturity: value, })}
                    disabled={isApplied || !editable}
                />
            </div>
            <div className="col">
                <span>Начало на договора</span>
                <Inputs.DatePicker
                    value={edits?.deliveryStart || data?.deliveryStart}
                    onChange={(value) => handleChange({ deliveryStart: moment(value).startOf('month').toDate() })}
                    // minDate={moment().add(1, 'months').startOf('month').toDate()}
                    className={`month-picker ${showRequired && !data.deliveryStart && 'required'}`}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    disabled={isApplied || !editable}
                />
            </div>
            {(edits?.maturity || data?.maturity || contractSettings?.maturity) === 'Брой месеци' && (
                <div className="col col-small">
                    <span>Срок на договор</span>
                    <Input.Text
                        disabled={isApplied || !editable}
                        value={edits?.contractDurationMonths ?? data?.contractDurationMonths ?? ''}
                        onChange={({ target: { value } }) => {
                            if (!/^\d+$/.test(value) && value !== '') return
                            handleChange({
                                contractDurationMonths: value,
                                deliveryEnd: moment(edits?.deliveryStart || data?.deliveryStart).add(value - 1, 'months').endOf('month').toDate(),
                            })
                        }}
                    />
                </div>
            )}
            <div className="col">
                <span>Нов край на договора</span>
                <Inputs.DatePicker
                    disabled={isApplied || !editable || (data?.maturity || contractSettings?.maturity) === 'Брой месеци'}
                    value={edits?.deliveryEnd || data?.deliveryEnd || contractSettings?.deliveryEnd}
                    onChange={(value) => handleChange({ deliveryEnd: moment(value).endOf('month').toDate() })}
                    // minDate={moment().add(1, 'months').startOf('month').toDate()}
                    className={`month-picker`}
                    showMonthYearPicker
                    showFullMonthYearPicker
                />
            </div>
        </div>
        <div className="row">
            <div className="col col-big">
                <span>Дни падеж</span>
                <Inputs.RadioButtons
                    disabled={isApplied || !editable}
                    buttons={['Работни', 'Календарни', 'Ден от месеца']}
                    value={isClientProfile ? edits?.paymentDaysType || data?.paymentDaysType || false : data?.paymentDaysType || contractSettings?.paymentDaysType}
                    onClick={(data) => handleChange({ paymentDaysType: data })}
                />
            </div>
            <div className="col col-small">
                <span></span>
                <Input.Text
                    disabled={isApplied || !editable}
                    value={isClientProfile ? edits?.paymentDays || data?.paymentDays || "" : data?.paymentDays ?? contractSettings?.paymentDays ?? ''}
                    onChange={({ target: { value } }) => {
                        if (!/^\d+$/.test(value) && value !== '') return
                        handleChange({ paymentDays: value })
                    }}
                />
            </div>
        </div>
        <div className="row">
            <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
                <span>Общи условия {isClientProfile && data.terms === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <Button.Switch
                    isOn={isClientProfile ? edits?.terms ?? data?.terms : data?.terms ?? contractSettings?.terms}
                    onChange={() => {
                        if (isApplied || !editable) return
                        if (isClientProfile) handleChange({ terms: edits?.terms ? !edits?.terms : !data?.terms })
                        else {
                            if (![undefined, null].includes(data?.terms) && !data?.terms === contractSettings?.terms) setData(omit(data, ['terms']))
                            else handleChange({ terms: !(data?.terms ?? contractSettings?.terms) })
                        }
                    }}
                />
            </div>
            <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
                <span>Декларация {isClientProfile && data.declaration === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <Button.Switch
                    isOn={isClientProfile ? edits?.declaration ?? data?.declaration : data?.declaration ?? client?.declaration}
                    onChange={() => {
                        if (isApplied || !editable) return
                        if (isClientProfile) handleChange({ declaration: edits?.declaration ? !edits?.declaration : !data?.declaration })
                        else {
                            if (![undefined, null].includes(data?.declaration) && !data?.declaration === client?.declaration) setData(omit(data, ['declaration']))
                            else handleChange({ declaration: !(data?.declaration ?? client?.declaration) })
                        }
                    }}
                />
            </div>
            <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
                <span>Автоматично преподписване {isClientProfile && data.autoSign === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <div className="row row-auto-sign">
                    <Button.Switch
                        isOn={isClientProfile ? ![undefined, null].includes(edits?.autoSign) ? edits?.autoSign : data?.autoSign || data?.autoSignMonths : data?.autoSign ?? contractSettings?.autoSign}
                        onChange={() => {
                            if (isApplied || !editable) return
                            if (isClientProfile) setData({ autoSignMonths: null, autoSign: ![undefined, null].includes(edits?.autoSign) ? !edits?.autoSign : !data?.autoSign })
                            else {
                                if (![undefined, null].includes(data?.autoSign) && !data?.autoSign === contractSettings?.autoSign) setData(omit(data, ['autoSign', 'autoSignMonths']))
                                else setData({ ...omit(data, ['autoSignMonths']), autoSign: !(data?.autoSign ?? contractSettings?.autoSign), })
                            }
                        }}
                    />
                    {(isClientProfile ? ![undefined, null].includes(edits?.autoSign) ? edits?.autoSign : data?.autoSign || data?.autoSignMonths : data?.autoSign ?? contractSettings?.autoSign) && (
                        <Inputs.TextLabeled
                            disabled={isApplied || !editable}
                            label="мес."
                            value={edits?.autoSignMonths ?? data?.autoSignMonths ?? contractSettings?.autoSignMonths ?? ''}
                            onChange={({ target: { value } }) => {
                                if (!/^\d{0,2}$/.test(value) && value !== '') return
                                handleChange({ autoSignMonths: value })
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
        <div className="row">
            <div className={`col col-double ${(isApplied || !editable) && 'disabled'}`}>
                <span>Неустойка {isClientProfile && data.penalty === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <div className="row row-auto-sign">
                    <Button.Switch
                        isOn={isClientProfile ? ![undefined, null].includes(edits?.penalty) ? edits?.penalty : data?.penalty || data?.penaltyPercent || data?.penaltyIsFixed : data?.penalty ?? contractSettings?.penalty}
                        onChange={() => {
                            if (isApplied || !editable) return
                            if (isClientProfile) setData({ penaltyPercent: null, penaltyIsFixed: null, penalty: ![undefined, null].includes(edits?.penalty) ? !edits?.penalty : !data?.penalty })
                            else {
                                if (![undefined, null].includes(data?.penalty) && !data?.penalty === contractSettings?.penalty) setData(omit(data, ['penalty', 'penaltyPercent', 'penaltyIsFixed']))
                                else setData({ ...omit(data, ['penaltyPercent', 'penaltyIsFixed']), penalty: !(data?.penalty ?? contractSettings?.penalty), })
                            }
                        }}
                    />
                    {(isClientProfile ? ![undefined, null].includes(edits?.penalty) ? edits?.penalty : data?.penalty || data?.penaltyPercent || data?.penaltyIsFixed : data?.penalty ?? contractSettings?.penalty) && (
                        <>
                            <Inputs.TextLabeled
                                disabled={isApplied || !editable}
                                label={(isClientProfile ? edits?.penaltyIsFixed ?? data?.penaltyIsFixed : data?.penaltyIsFixed ?? client?.penaltyIsFixed) ? 'лв.' : "%"}
                                value={edits?.penaltyPercent ?? data?.penaltyPercent ?? contractSettings?.penaltyPercent ?? ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d+$/.test(value) && value !== '') return
                                    handleChange({ penaltyPercent: value })
                                }}
                            />
                            <div className={`col ${(isApplied || !editable) && 'disabled'}`} style={{ position: 'relative', marginLeft: 15 }}>
                                <span style={{ position: 'absolute', top: 0, transform: 'translateY(-100%)', left: 0, whiteSpace: 'nowrap' }}>Фикс {isClientProfile && data.penaltyIsFixed === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                                <Button.Switch
                                    isOn={isClientProfile ? edits?.penaltyIsFixed ?? data?.penaltyIsFixed : data?.penaltyIsFixed ?? client?.penaltyIsFixed}
                                    onChange={() => {
                                        if (isApplied || !editable) return
                                        if (isClientProfile) handleChange({ penaltyIsFixed: edits?.penaltyIsFixed ? !edits?.penaltyIsFixed : !data?.penaltyIsFixed })
                                        else {
                                            if (![undefined, null].includes(data?.penaltyIsFixed) && !data?.penaltyIsFixed === client?.penaltyIsFixed) setData(omit(data, ['penaltyIsFixed']))
                                            else handleChange({ penaltyIsFixed: !(data?.penaltyIsFixed ?? client?.penaltyIsFixed) })
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={`col col-double ${(isApplied || !editable) && 'disabled'}`}>
                <span>Депозит напускане {isClientProfile && data.depositLeaving === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <div className="row row-auto-sign">
                    <Button.Switch
                        isOn={isClientProfile ? ![undefined, null].includes(edits?.depositLeaving) ? edits?.depositLeaving : data?.depositLeaving || data?.depositLeavingPercent || data?.depositLeavingIsFixed : data?.depositLeaving ?? contractSettings?.depositLeaving}
                        onChange={() => {
                            if (isApplied || !editable) return
                            if (isClientProfile) setData({ depositLeavingPercent: null, depositLeavingIsFixed: null, depositLeaving: ![undefined, null].includes(edits?.depositLeaving) ? !edits?.depositLeaving : !data?.depositLeaving })
                            else {
                                if (![undefined, null].includes(data?.depositLeaving) && !data?.depositLeaving === contractSettings?.depositLeaving) setData(omit(data, ['depositLeaving', 'depositLeavingPercent', 'depositLeavingIsFixed']))
                                else setData({ ...omit(data, ['depositLeavingPercent', 'depositLeavingIsFixed']), depositLeaving: !(data?.depositLeaving ?? contractSettings?.depositLeaving), })
                            }
                        }}
                    />
                    {(isClientProfile ? ![undefined, null].includes(edits?.depositLeaving) ? edits?.depositLeaving : data?.depositLeaving || data?.depositLeavingPercent || data?.depositLeavingIsFixed : data?.depositLeaving ?? contractSettings?.depositLeaving) && (
                        <>
                            <Inputs.TextLabeled
                                disabled={isApplied || !editable}
                                label={(isClientProfile ? edits?.depositLeavingIsFixed ?? data?.depositLeavingIsFixed : data?.depositLeavingIsFixed ?? client?.depositLeavingIsFixed) ? 'лв.' : "%"}
                                value={edits?.depositLeavingPercent ?? data?.depositLeavingPercent ?? contractSettings?.depositLeavingPercent ?? ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d+$/.test(value) && value !== '') return
                                    handleChange({ depositLeavingPercent: value })
                                }}
                            />
                            <div className={`col ${(isApplied || !editable) && 'disabled'}`} style={{ position: 'relative', marginLeft: 15 }}>
                                <span style={{ position: 'absolute', top: 0, transform: 'translateY(-100%)', left: 0, whiteSpace: 'nowrap' }}>Фикс {isClientProfile && data.depositLeavingIsFixed === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                                <Button.Switch
                                    isOn={isClientProfile ? edits?.depositLeavingIsFixed ?? data?.depositLeavingIsFixed : data?.depositLeavingIsFixed ?? client?.depositLeavingIsFixed}
                                    onChange={() => {
                                        if (isApplied || !editable) return
                                        if (isClientProfile) handleChange({ depositLeavingIsFixed: edits?.depositLeavingIsFixed ? !edits?.depositLeavingIsFixed : !data?.depositLeavingIsFixed })
                                        else {
                                            if (![undefined, null].includes(data?.depositLeavingIsFixed) && !data?.depositLeavingIsFixed === client?.depositLeavingIsFixed) setData(omit(data, ['depositLeavingIsFixed']))
                                            else handleChange({ depositLeavingIsFixed: !(data?.depositLeavingIsFixed ?? client?.depositLeavingIsFixed) })
                                        }
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
                <span>Предизвестие {isClientProfile && data.notice === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
                <div className="row row-auto-sign">
                    <Button.Switch
                        isOn={isClientProfile ? ![undefined, null].includes(edits?.notice) ? edits?.notice : data?.notice || data?.noticeDays : data?.notice ?? contractSettings?.notice}
                        onChange={() => {
                            if (isApplied || !editable) return
                            if (isClientProfile) setData({ noticeDays: null, notice: ![undefined, null].includes(edits?.notice) ? !edits?.notice : !data?.notice })
                            else {
                                if (![undefined, null].includes(data?.notice) && !data?.notice === contractSettings?.notice) setData(omit(data, ['notice', 'noticeDays']))
                                else setData({ ...omit(data, ['noticeDays']), notice: !(data?.notice ?? contractSettings?.notice), })
                            }
                        }}
                    />
                    {(isClientProfile ? ![undefined, null].includes(edits?.notice) ? edits?.notice : data?.notice || data?.noticeDays : data?.notice ?? contractSettings?.notice) && (
                        <Inputs.TextLabeled
                            disabled={isApplied || !editable}
                            label="дни"
                            value={edits?.noticeDays ?? data?.noticeDays ?? contractSettings?.noticeDays ?? ''}
                            onChange={({ target: { value } }) => {
                                if (!/^\d+$/.test(value) && value !== '') return
                                handleChange({ noticeDays: value })
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
        <div className="row">
            <div className={`col ${isClientProfile && !editable && 'disabled'}`}>
                <span>Договор</span>
                {(!edits?.file && data?.file) || edits?.file?.name ? (
                    <div className="row row-file">
                        {(!isClientProfile || editable) && (
                            <Button.Icon
                                name="plus"
                                color="red"
                                onClick={() => {
                                    if (isClientProfile) setEdits({ ...edits, file: {} })
                                    else setData(omit(data, ['file']))
                                }}
                            />
                        )}
                        <a href={transformURL(edits?.file?.url || data?.file?.url || '')} target="_blank" rel="noopener noreferrer">
                            <span>{edits?.file?.name || data?.file?.name}</span>
                        </a>
                    </div>
                ) : (
                    <Button.UploadButton
                        disabled={isClientProfile && !editable}
                        text={<Input.DragAndDrop handleDrop={handleUpload}>
                            <span className="row ripple">Добави файл <div className="icon icon-attach" /></span>
                        </Input.DragAndDrop>}
                        accept="*"
                        onChange={({ target: { files } }) => handleUpload(files)}
                    />
                )}
            </div>
            <div className="col col-double">
                <span>Коментар към преподписването</span>
                <Input.Text
                    disabled={isClientProfile && !editable}
                    value={edits?.comment ?? data?.comment?.text ?? data?.comment ?? ''}
                    onChange={({ target: { value } }) => handleChange({ comment: value })}
                />
            </div>
            <div className="col col-double  col-dealers ">
                <span>Търговци</span>
                <div className="row">
                    <Popup
                        keepTooltipInside='.col-dealers'
                        contentStyle={{ width: 'auto' }}
                        trigger={<div className="icon icon-add" />}
                    >
                        {close => (
                            <PopupComponents.Select
                                hide={close}
                                search
                                handleSearch={updateDealers}
                                value={(edits?.dealers || data?.dealers || client?.dealers)?.map(({ _id }) => _id)}
                                options={dealers?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
                                handleChange={(value) => {
                                    const payload = value.map(_id => dealers.find((d) => d._id === _id))
                                    handleChange({ dealers: payload })
                                }}
                                text={{ main: 'Търси по име', secondary: 'Търговци' }}
                            />
                        )}
                    </Popup>
                    <div className="row">
                        {(isClientProfile ? edits?.dealers ?? data?.dealers : data?.dealers || client?.dealers)?.map(({ _id, fullName, coverPhoto }) => (
                            <div key={_id} className="single-person-container row">
                                <div
                                    className="profile-image-container"
                                    style={{ backgroundImage: `url(${coverPhoto || require('../../../../assets/images/default-user-avatar.jpg')})` }}
                                />
                                <p>{fullName}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
})

export default AnnexResignContract