import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, updateUser } from '../../../actions'
import './styles.scss'

const ChangePassword = ({ }) => {
  const [showPass, setShowPass] = useState({})
  const [data, setData] = useState()
  const { password, newPassword, newPasswordRepeated } = data || {}
  const handleChange = (field, value) => setData({ ...data, [field]: value })

  const dispatch = useDispatch()
  const { _id } = useSelector(({ general }) => general.currentUser)

  return (
    <div className="modal-change-password-container">
      <h2>Промяна на парола</h2>
      <div className="modal-change-password-content">
        {[
          { label: 'Текуща парола', value: 'password' },
          { label: 'Нова парола', value: 'newPassword' },
          { label: 'Повтори нова парола', value: 'newPasswordRepeated' },
        ].map(({ label, value }, i) => (
          <div className="password-input" key={`new-pass-field-${i}`}>
            <p>{label}</p>
            <Input.Text
              autoComplete={value === password ? "current-password" : "new-password"}
              type={!showPass[i] ? 'password' : 'text'}
              onChange={({ target }) => handleChange(value, target?.value)}
              value={data?.[value] || ''}
            />
            {data?.[value] && <div
              className={`icon icon-eye-custom ripple ${!showPass[i] && 'active'}`}
              onClick={() => setShowPass({ ...showPass, [i]: !showPass[i] })}
            />}
          </div>
        ))}
      </div>
      <div className="modal-change-password-footer row">
        <Button.Raised
          className="btn-cancel"
          text="Откажи "
          onClick={() => dispatch(setModal({ isOpen: true, type: 'profile' }))}
        />
        <Button.Raised
          className="btn-save"
          text="Запази промените"
          onClick={() => {
            if (newPassword === newPasswordRepeated) dispatch(updateUser({
              payload: { _id, password, newPassword }, onSuccess: () => {
                dispatch(setModal({ isOpen: true, type: 'profile' }))
                Alerts.success({ title: "Успешно сменена парола!" })
              }
            }))
            else Alerts.error('Грешка', 'Паролите не съвпадат!');
          }}
        />
      </div>
    </div>
  )
}

export default ChangePassword
