import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { isEmpty, isString } from 'lodash'
import { createData, startLoading } from '../../../actions'
import { Inputs } from '../../'
import './styles.scss'

const Select = ({
  children,
  hide,
  value,
  handleChange,
  options,
  createDataType,
  text,
  selectAll,
  search,
  handleSearch,
  single,
  skipApply,
}) => {
  const [data, setData] = useState(value || [])
  const [query, setQuery] = useState(null)

  const dispatch = useDispatch()

  const filteredOptions = options?.filter(
    ({ name, label }) =>
      !query ||
      name?.toLowerCase()?.includes(query?.toLowerCase()) ||
      label?.toLowerCase()?.includes(query?.toLowerCase())
  )

  const handleApply = (value) => {
    handleChange(isString(value) ? value : [...value].filter(Boolean))
    hide()
  }

  return (
    <div className="popup-select-container">
      <div className="row">
        <h4>{text?.main || 'Избери'}</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      {search && (
        <div className="search-container">
          <Input.Text
            placeholder={`Търси ${createDataType ? '/ Добави' : ''}`}
            value={query || ''}
            onChange={({ target: { value } }) => {
              if (handleSearch) handleSearch(value)
              setQuery(value)
            }}
          />
          {createDataType && isEmpty(filteredOptions) && (
            <Button.Icon
              name="plus"
              onClick={() => {
                dispatch(startLoading())
                dispatch(
                  createData({
                    type: createDataType,
                    data: { name: query },
                    onSuccess: (response) => {
                      setQuery('')
                      setData([...data, response?._id])
                    },
                  })
                )
              }}
            />
          )}
        </div>
      )}
      {children}
      {!isEmpty(filteredOptions) && <h4 className="add-margin">{text?.secondary || 'Избери'}</h4>}
      <div className="scroll-container">
        {single ? (
          <Inputs.RadioButtons
            buttons={filteredOptions?.map(({ _id, name, value, label }) => ({
              label: label || name,
              value: _id || value,
            }))}
            value={data}
            onClick={(value) => {
              if (skipApply) handleApply(value)
              else setData(value)
            }}
            col
          />
        ) : (
          <>
            {selectAll && (
              <Inputs.Checkboxes
                buttons={[{ label: 'Избери всички', value: options?.length || 0 }]}
                value={[data?.length]}
                onClick={() => {
                  if (isEmpty(data)) setData(options.map(({ value }) => value))
                  else setData(null)
                }}
              />
            )}
            <Inputs.Checkboxes
              buttons={filteredOptions?.map(({ _id, name, value, label }) => ({
                label: label || name,
                value: _id || value,
              }))}
              value={data}
              onClick={(value) => setData(value)}
              col
            />
          </>
        )}
      </div>
      {!skipApply && <Button.Raised text={text?.button || 'Избери'} onClick={() => handleApply(data)} />}
    </div>
  )
}

export default Select
