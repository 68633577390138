import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setOverlay, updateClient, getClient, setModal, startLoading } from '../../../actions'
import './styles.scss'
import { checkIsDealer } from 'utilities'
import { Alerts } from '@makedonski/socourt-utilities'

const ClientOptions = ({ hide, client }) => {
  const isDealer = checkIsDealer()
  const { _id, isDetailsConfirmed } = client || {}
  const dispatch = useDispatch()
  const history = useHistory()

  const handleCreateAnnex = () => {
    if (client.clientType === 'Консуматор') history.push(`/annex/create?tab=main&eic=${client?.eic || client?.pin}`)
    if (client.clientType === 'Производител') history.push(`/producers/annex/create?tab=main&eic=${client?.eic || client?.pin}`)
    if (client.clientType === 'Смесен') dispatch(setModal({
      isOpen: true,
      type: 'confirmation',
      props: {
        title: 'Създай анекс',
        buttons: <div className="row" style={{ gap: '30px' }}>
          <Button.Raised text='Консумация' onClick={() => { history.push(`/annex/create?tab=main&eic=${client?.eic || client?.pin}`); dispatch(setModal({ isOpen: false })) }} />
          <Button.Raised text='Производство' onClick={() => { history.push(`/producers/annex/create?tab=main&eic=${client?.eic || client?.pin}`); dispatch(setModal({ isOpen: false })) }} />
        </div>
      }
    }))
    hide()
  }
  const handleResign = () => {
    if (client.clientType === 'Консуматор') history.push(`/resign/create?tab=main&eic=${client?.eic || client?.pin}`)
    if (client.clientType === 'Производител') history.push(`/producers/resign/create?tab=main&eic=${client?.eic || client?.pin}`)
    if (client.clientType === 'Смесен') dispatch(setModal({
      isOpen: true,
      type: 'confirmation',
      props: {
        title: 'Преподписване',
        buttons: <div className="row" style={{ gap: '30px' }}>
          <Button.Raised text='Консумация' onClick={() => { history.push(`/resign/create?tab=main&eic=${client?.eic || client?.pin}`); dispatch(setModal({ isOpen: false })) }} />
          <Button.Raised text='Производство' onClick={() => { history.push(`/producers/resign/create?tab=main&eic=${client?.eic || client?.pin}`); dispatch(setModal({ isOpen: false })) }} />
        </div>
      }
    }))
    hide()
  }

  const handleUpdateClientTypeMixed = () => {
    dispatch(setModal({
      isOpen: true,
      type: 'updateClientTypeMixed',
      props: {
        title: 'Промени тип клиент на Смесен',
        client,
        onSubmit: (data) => {
          dispatch(startLoading())
          dispatch(updateClient({
            data: { ...data, clientType: 'Смесен' }, onSuccess: () => {
              Alerts.success({ title: 'Успешно променихте клиента на Смесен' })
              dispatch(setModal({ isOpen: false }))
            }
          }))
        }
      }
    }))
    hide()
  }

  return (
    <div className="popup-client-options-container">
      <div className="row">
        <h4>Преглед на</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'history', props: { client } }))
          hide()
        }}
      >
        История на дейността
      </p>
      <p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'clientComments', props: { client } }))
          hide()
        }}
      >
        Коментари
      </p>
      {isDealer && client?.clientType !== 'Смесен' && <p onClick={handleUpdateClientTypeMixed}>Промени тип клиент на Смесен</p>}
      {/*<p
        onClick={() => {
          dispatch(setOverlay({ isOpen: true, type: 'files', props: { client } }))
          hide()
        }}
      >
        Файлове
      </p>*/}
      {!isDealer && <>
        <h4>Действия</h4>
        {!isDetailsConfirmed && <p onClick={() => {
          dispatch(startLoading())
          dispatch(updateClient({
            data: { _id, isDetailsConfirmed: true },
            onSuccess: () => {
              dispatch(getClient({ payload: _id, getAllDetails: true }))
              hide()
            }
          }))
        }}>Потвърди детайли</p>}
        <p onClick={handleCreateAnnex}>Създай Анекс</p>
        <p onClick={handleResign}>Преподписване</p>
        <p onClick={() => history.push(`/consumption_analysis?clients=${client?._id}`)}>Анализ на потребление</p>
        {client?.contractSettings?.penalty && client?.contractSettings?.penaltyPercent && <p onClick={() => { dispatch(setModal({ isOpen: true, type: 'calculatePenalty', props: { client: client?._id } })); hide() }}>Калкулатор неустойка</p>}
      </>}
    </div >
  )
}

export default ClientOptions
