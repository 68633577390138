import Swal from 'sweetalert2'

export const error = (payload) => Swal.fire({
    icon: 'error',
    title: 'Грешка!',
    text: 'Нещо се обърка!',
    confirmButtonColor: '#0099ff',
    ...payload
})

export const success = (payload) => Swal.fire({
    icon: 'success',
    confirmButtonColor: '#0099ff',
    ...payload
})

export const confirm = ({ onSuccess = () => { }, onCancel = () => { }, ...payload } = {}) => Swal.fire({
    title: 'Внимание!',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#e87a1e',
    confirmButtonText: 'Продължи',
    cancelButtonText: 'Откажи',
    reverseButtons: true,
    ...payload
}).then((result) => {
    if (result.isConfirmed) onSuccess()
    else onCancel()
})