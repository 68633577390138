import moment from "moment"

export const defaultContractVersion = ''
export const specialContractVersions = ['1.24', '3.24', '7.24']
export const specialContractVersionsTerms = ['3.24']
export const defaultContractVersionProducers = 'Г08.24'

export const headerModules = [
  // { label: 'Начало', value: "home", to: '/' },
  { label: 'Клиенти', value: "clients", to: '/clients' },
  { label: 'Задачи', value: "tasks", to: '/tasks' },
  { label: 'Финанси', value: "financial", to: '/financial' },
  { label: 'Справки', value: "references", to: '/references' },
  { label: 'Производители', value: "references-producers", to: '/references-producers' },
  { label: 'Данни', value: "communication", to: '/communication' },
  { label: 'Служители', value: "employees", to: '/employees' },
]

export const financialTabs = [
  { label: 'Фактури', to: '/invoices', value: 'invoices' },
  { label: 'Лихви', to: '/interests', value: 'interests' },
  { label: 'Депозити', to: '/deposits', value: 'deposits' },
  { label: 'Неустойки', to: '/penalties', value: 'penalties' },
  { label: 'Уведомления', to: '/notifications', value: 'notifications' },
  // { label: 'Задължения', to: '/obligations', value: 'obligations' },
  // { label: 'Плащания', to: '/payments', value: 'payments' },
  // { label: 'Известия', to: '/notices', value: 'notices' },
  { label: 'Комисионни', to: '/commissions', value: 'commissions' },
]

export const statusMap = {
  Working: '#00cb5e',
  Away: '#ffb612',
  Offline: '#fa4444',
}

export const mapPriority = { high: '#fa4444', mid: '#ffb612', low: '#00cb5e' }

export const clientsTypesButtons = [
  { value: 'active', label: 'Активни' },
  { value: 'potential', label: 'Потенциални' },
  { value: 'register', label: 'Регистрация' },
  { value: 'leaving', label: 'Напускащи' },
  { value: 'inactive', label: 'Неактивни' },
  { value: 'list', label: 'Всички' },
  { value: 'producers', label: 'Активни Производители' },
  { value: 'producersRegistration', label: 'Регистрация Производители' },
  { value: 'producersLeaving', label: 'Напускащи Производители' },
]

export const clientTypesOptions = ['Консуматор', 'Производител', 'Смесен']

export const clientsStatus = [
  { value: 'registration', label: 'Регистрация' },
  { value: 'preregistration', label: 'Превходиране' },
  { value: 'documents-sent', label: 'Изпратени Документи' },
  { value: 'documents-received', label: 'Потвърдена' },
  { value: 'documents-received-missing', label: 'Непотвърдена' },
  { value: 'transfer', label: 'Пререгистрация' },
  { value: 'leaving', label: 'Напуска' },
  { value: 'active', label: 'Активни' },
  { value: 'inactive', label: 'Неактивни' },
  { value: 'potential', label: 'Потенциални' },
]

export const clientsStatusMap = {
  'registration': 'Регистрация',
  'preregistration': 'Превходиране',
  'documents-sent': 'Изпратени Документи',
  'documents-received': 'Потвърдена',
  'documents-received-missing': 'Непотвърдена',
  'transfer': 'Пререгистрация',
  'leaving': 'Напуска',
  'active': 'Активна',
  'inactive': 'Неактивна',
  'potential': 'Потенциална',
}

export const allRegistrationStatuses = ['registration', 'transfer', 'documents-sent', 'documents-received', 'documents-received-missing', 'preregistration']

export const clientsSearchOptions = [
  { value: 'eic', label: 'ЕИК/ЕГН', client: true },
  { value: 'fullName', label: 'Име на фирма', shortLabel: 'Фирма', client: true },
  { value: 'itn', label: 'ИТН на точка', shortLabel: 'ИТН', client: false },
  { value: 'description', label: 'Име на обект/точка', shortLabel: 'Точка', client: false },
  { value: 'objectAddress', label: 'Адрес на обект/точка', shortLabel: 'Адрес', client: false },
  { value: 'mol', label: 'МОЛ', client: true },
  // { value: '', label: 'Групи на фактура' },
]

export const clientsFields = [
  { label: 'Статус', value: 'status', size: 300, sortable: true },
  { label: 'Тип', value: 'clientType', size: 130, sortable: true },
  { label: 'Пререгистрация', value: 'hasClone', includedIn: ['leaving', 'producersLeaving', 'list'], size: 180 },
  { label: 'Причина за напускане', value: 'reasonForLeaving', includedIn: ['leaving', 'producersLeaving'], size: 180, sortable: true },
  { label: 'Потвърден', value: 'confirmed', includedIn: ['register', 'producersRegistration',], size: 150 },
  { label: 'Потвърдени детайли', value: 'isDetailsConfirmed', includedIn: ['register', 'producersRegistration',], size: 150 },
  { label: 'Коментари', value: 'comments', size: 200 },
  { label: 'Име клиент', value: 'fullName', size: process.env.REACT_APP_PLATFORM === 'W&S' ? 500 : 300 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'ИН по ЗДДС', value: 'bulstat', size: 150 },
  { label: 'Дата списък', value: 'lastLeavingDate', includedIn: ['leaving', 'producersLeaving'], size: 160, sortable: true },
  { label: 'Допълнителни задължения', value: 'additionalCharges', includedIn: ['leaving', 'producersLeaving'], size: 180 },
  { label: 'Град', value: 'city', size: 200 },
  { label: 'Обект/и', value: 'objects', size: 150 },
  { label: 'ЕРП', value: 'erp', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 300, sortable: true },
  { label: 'Тип', value: 'measureType', includedIn: ['active', 'register', 'leaving', 'inactive', 'list'], size: 200, sortable: true },
  { label: 'Тип ВЕИ', value: 'producerType', includedIn: ['list', 'producers', 'producersRegistration', 'producersLeaving'], size: 200, sortable: true },
  { label: 'Ажур', value: 'ajur', size: 200, includedIn: ['list'], sortable: true },
  { label: 'Ажур - производство', value: 'ajurProducer', size: 200, includedIn: ['list'], sortable: true },
  { label: 'Създаден от', value: 'createdBy', size: 200 },
  { label: 'Създаден на', value: 'createdAt', size: 200, sortable: true },
  { label: 'Търговец', value: 'dealers', size: 200, sortable: true },
  { label: 'Отговорник', value: 'responsibleForRegistration', includedIn: ['register'], size: 400, sortable: true },
  { label: 'Отговорник', value: 'responsibleForRegistrationProducers', includedIn: ['producersRegistration',], size: 400, sortable: true },
  // { label: 'Задачи', value: 'tasks', size: 200 },
  { label: 'Свързани клиенти', value: 'connectedGroup', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 250, sortable: true },
  { label: 'Група', value: 'group', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 250, sortable: true },

  { label: 'Анализ', value: '', includedIn: ['potential'], size: 250 },
  { label: 'Оферта', value: 'offer', includedIn: ['potential'], size: 250 },
  { label: 'Договор', value: 'contract', includedIn: ['potential'], size: 250 },

  { label: 'Тип договор', value: 'contractType', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list', 'producersLeaving',], size: 250, sortable: true },
  { label: 'Версия', value: 'version', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 250, sortable: true },
  { label: 'Тип плащане', value: 'intermediateInvoicingType', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list'], size: 250, sortable: true },
  { label: '№ договор', value: 'contractNumber', includedIn: ['register', 'producersRegistration',], size: 250 },
  { label: 'Дата договор', value: 'contractDate', includedIn: ['register', 'producersRegistration',], size: 250 },
  { label: 'Край на входиране', value: 'entryEnd', includedIn: ['register', 'producersRegistration',], size: 250, sortable: true },
  { label: 'Начало на доставка', value: 'deliveryStart', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 210, sortable: true },
  { label: 'Край на доставка', value: 'deliveryEnd', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 210, sortable: true },
  { label: 'Падеж', value: 'paymentDays', size: 200 },
  { label: 'Вид фактуриране', value: 'producerInvoicingType', includedIn: ['producers', 'producersRegistration', 'producersLeaving'], size: 170 },
  { label: 'Продукт', value: 'product', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 200, sortable: true },
  { label: 'Цена', value: 'base', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 150, sortable: true },
  { label: 'Борса', value: 'market', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 150, sortable: true },
  { label: 'Борса база', value: 'marketIsBase', includedIn: ['active', 'list'], size: 150, sortable: true },
  { label: 'Под', value: 'marketFloor', includedIn: ['list', 'producers', 'producersRegistration', 'producersLeaving',], size: 150, sortable: true },
  // { label: 'Таван', value: 'marketCeiling', includedIn: ['list', 'producers', 'producersRegistration', 'producersLeaving',], size: 150, sortable: true },
  { label: 'Такса АО', value: 'taxPerObject', size: 150, sortable: true, platform: ['Zagora Energy'] },
  { label: 'MW последен месец', value: 'lastConsumption', includedIn: ['active', 'list'], size: 250, sortable: true },
  { label: 'Авт. преподписване', value: 'autoSign', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 200 },
  { label: 'Общи условия', value: 'terms', includedIn: ['active', 'register', 'leaving', 'inactive', 'list', 'producers', 'producersRegistration', 'producersLeaving',], size: 200, sortable: true },
  { label: 'Неустойка', value: 'penalty', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list',], size: 200, sortable: true },
  { label: 'Неустойка издадена', value: 'penaltyFile', includedIn: ['leaving'], size: 150 },
  { label: 'Депозит напускане', value: 'depositLeaving', includedIn: ['active', 'leaving',], size: 200, sortable: true },
  { label: 'Депозит издаден', value: 'depositFile', includedIn: ['leaving', 'producersLeaving'], size: 150 },
  { label: 'Предизвестие', value: 'notice', includedIn: ['active', 'leaving',], size: 200, sortable: true },
  { label: 'Краен срок предизвестие', value: 'noticeEnd', includedIn: ['active', 'leaving',], size: 200 },

  { label: 'Получен договор', value: 'contractReceivedDate', includedIn: ['register', 'active', 'list',], size: 250, sortable: true },
  { label: 'Получен договор - П', value: 'contractReceivedDateProducers', includedIn: ['list', 'producers', 'producersRegistration'], size: 250, sortable: true },
  { label: 'Завършено входиране', value: 'executionDate', includedIn: ['register'], size: 160, sortable: true },
  { label: 'Завършено входиране - П', value: 'executionDateProducers', includedIn: ['producersRegistration'], size: 160, sortable: true },
  { label: 'Входящ №', value: 'registrationNumber', includedIn: ['register'], size: 250, sortable: true },
  { label: 'Входящ № - П', value: 'registrationNumberProducers', includedIn: ['producersRegistration'], size: 250, sortable: true },
  { label: 'ДПИ - изготвяне', value: 'dpiGenerated', includedIn: ['register'], size: 250, sortable: true },
  { label: 'ДПИ - статус', value: 'dpiSigned', includedIn: ['register'], size: 250, sortable: true },
  { label: 'Възразен', value: 'objectedDate', includedIn: ['leaving', 'producersLeaving'], size: 250, sortable: true },

  { label: 'Анекс', value: 'annex', includedIn: ['active', 'producers'], size: 250 },
]

export const hideDealerFields = [
  'tasks',
  'responsible',
  'group',
  'contractNumber',
  'contractDate',
  'executionDate',
  'executionDateProducers',
  'registrationNumber',
  'registrationNumberProducers',
  'dpiGenerated',
  'contractReceivedDate',
  'contractReceivedDateProducers',
  'dpiSigned',
  'documentsSentDate',
  'dpiGeneratedDate',
  'dpiSignedDate',
  'penalty',
  'depositLeaving',
  'notice',
  'paymentDays',
  'objectActivate'
]

export const clientsObjectsFields = [
  { label: 'Статус', value: 'status', size: 230 },
  { label: 'Вид', value: 'isProducer', includedIn: ['register', 'list', 'producers', 'producersRegistration', 'leaving', 'producersLeaving'], size: 50 },
  { label: 'Край на входиране', value: 'entryEnd', includedIn: ['register', 'producersRegistration'], size: 200 },
  { label: 'Пререгистрация', value: 'isClone', includedIn: ['register', 'list', 'producersRegistration'], size: 150 },
  { label: 'ИТН Обект', value: 'itn', size: 320 },
  { label: 'Тип на мерене', value: 'measureType', includedIn: ['active', 'potential', 'register', 'leaving', 'inactive', 'list'], size: 100 },
  { label: 'ЕРП', value: 'erp', size: 100 },

  { label: 'Изпратени документи', value: 'documentsSentDate', includedIn: ['register', 'producersRegistration'], size: 220 },
  { label: 'ДПИ - изготвяне', value: 'dpiGeneratedDate', includedIn: ['register'], size: 220 },
  { label: 'ДПИ - статус', value: 'dpiSignedDate', includedIn: ['register'], size: 220 },

  { label: 'Към активни', value: 'objectActivate', includedIn: ['register', 'producersRegistration'], size: 150 },
  { label: 'Нова централа', value: 'producerNewPlant', includedIn: ['producersRegistration'], size: 150 },


  { label: 'Проблем', value: 'issue', includedIn: ["register", 'producersRegistration'], size: 200 },
  { label: 'Задължения', value: 'additionalStatus', includedIn: ["register", 'producersRegistration', 'leaving', 'producersLeaving'], size: 130 },
  { label: 'Крайна дата за възражения', value: 'objectionsDate', includedIn: ['leaving', 'producersLeaving'], size: 200 },
  { label: 'Активна от', value: 'activeFrom', size: 200 },
  { label: 'Неактивна от', value: 'inactiveFrom', size: 200 },
  { label: 'Одобрение', value: 'statusConfirmed', includedIn: ['register', , 'producersRegistration', 'leaving', 'producersLeaving'], size: 130 },
  { label: 'Причина за напускане', value: 'reasonForLeaving', includedIn: ['leaving', 'producersLeaving'], size: 300 },
  { label: 'Създаден на', value: 'createdAt', size: 200 },
  { label: 'Име на обект/точка', value: 'description' },
  { label: 'Град', value: 'city', size: 150 },
  { label: 'Адрес', value: 'address', size: 300 },
  { label: 'Собствена централа', value: 'consumerOwnPlant', includedIn: ['active', 'list'], size: 150 },
  { label: 'Предишен клиент', value: 'previousClient', includedIn: ['register', , 'producersRegistration', 'active', 'list', 'producers'], size: 300 },
  { label: 'Пъти в превходиране', value: 'timesInPreregistration', includedIn: ['register', 'producersRegistration'], size: 140 },
  // { label: 'Потвърждение в списък', value: 'confirmed', includedIn: ['register'], size: 150 },
  { label: 'Продукт', value: 'producerProduct', includedIn: ['list', 'producers', 'producersRegistration'], size: 180 },
  { label: 'Цена', value: 'producerPrice', includedIn: ['list', 'producers', 'producersRegistration'], size: 150 },
  { label: 'Тип ВЕИ', value: 'producerType', includedIn: ['list', 'producers', 'producersRegistration'], size: 110 },
  { label: 'Ново ВЕИ 2021+', value: 'producerAfter2021', includedIn: ['list', 'producers', 'producersRegistration'], size: 150 },
  { label: 'Член', value: 'producerDirectMember', includedIn: ['list', 'producers', 'producersRegistration'], size: 130 },
  { label: 'Преференции', value: 'producerPreferences', includedIn: ['list', 'producers', 'producersRegistration'], size: 150 },
  { label: 'Собствени нужди', value: 'producerOwnNeed', includedIn: ['list', 'producers', 'producersRegistration'], size: 150 },
  { label: 'Инсталирана мощност', value: 'producerProductionPower', includedIn: ['list', 'producers', 'producersRegistration'], size: 150 },
  { label: 'Балансиране', value: 'balancingMethod', includedIn: ['list', 'producers', 'producersRegistration'], size: 280 },
  { label: 'Вид Фактуриране ', value: 'invoicingMethod', includedIn: ['list', 'producers', 'producersRegistration'], size: 280 },
]

export const clientDocumentsFields = [
  { value: 'name', label: 'Документ', size: 300 },
  { value: 'type', label: 'Тип', size: 200 },
  { value: 'createdAt', label: 'Добавен на', size: 200 },
  { value: 'employee', label: 'Служител', size: 200 },
  { value: 'comments', label: 'Коментар', size: 400 },
  { value: 'delete', size: 50 },
]
export const clientInvoicesFields = [
  { value: 'invoiceNumber', label: 'Документ', size: 200 },
  { value: 'createdAt', label: 'Издадена на', size: 200 },
  { value: 'value', label: 'Обща стойност', size: 200 },
  { value: 'status', label: 'Статус', size: 400 },
]

export const productsPricesFields = [
  { types: ['Една тарифа', 'Две тарифи', 'Три тарифи', 'Микс', 'Фикс'], label: 'Цена', value: 'base' },
  { types: ['Две тарифи', 'Три тарифи'], label: 'Нощна', value: 'night' },
  { types: ['Три тарифи'], label: 'Върхова', value: 'max' },
  { types: ['Борса', 'Борса с Таван на цената', 'Борса с Под на цената', 'Борса с Под и Таван на цената', 'Микс', 'Борса минус', 'Борса минус с Под на цената', 'Борса минус с Таван на цената', 'Борса минус с Под и Таван на цената'], label: 'Борса', value: 'market' },
  { types: ['Борса с Под на цената', 'Борса с Под и Таван на цената', 'Борса минус с Под на цената', 'Борса минус с Под и Таван на цената',], label: 'Под', value: 'marketFloor' },
  { types: ['Борса с Таван на цената', 'Борса с Под и Таван на цената', 'Борса минус с Таван на цената', 'Борса минус с Под и Таван на цената',], label: 'Таван', value: 'marketCeiling' },
  { types: ['Микс'], label: 'Цена:Борса', value: 'basePercentage' },

  { types: ['Смесен количество'], label: 'МВтч Фикс', value: 'baseConsumption', innerLabel: 'МВтч' },
  { types: ['Смесен количество'], label: 'Цена фикс', value: 'base' },
  { types: ['Смесен количество'], label: 'Борса минус (остатък)', value: 'market' },

  { types: ['Смесен процент'], label: 'Цена Фикс', value: 'base' },
  { types: ['Смесен процент'], label: 'Цена Борса', value: 'market' },
  { types: ['Смесен процент'], label: '% фикс / % борса', value: 'basePercentage' },

  { types: ['Борса с лв и процент'], label: 'Борса - лв.', value: 'market', innerLabel: 'лв.' },
  { types: ['Борса с лв и процент'], label: 'Борса - %', value: 'marketPercent', innerLabel: '%' },

  { types: ['Отделно балансиране'], label: 'Такса участие', value: 'market' },
]

export const createObjectsFields = [
  { label: 'Незабавна активация', value: 'existingObject', size: 125, type: 'text' },
  { label: 'Тип обект/описание', value: 'description', size: 300, type: 'text' },
  { label: 'ЕРП', value: 'erp', size: 300, type: 'dropdown' },
  { label: 'ИТН', value: 'itn', size: 300, type: 'text' },
  { label: 'ИТН Мерения', value: 'itnMeasure', size: 300, type: 'text' },
  // { label: '', value: '', size: 300, type: 'icons' },
  { label: 'Град', value: 'city', size: 300, type: 'text' },
  { label: 'Адрес', value: 'address', size: 300, type: 'text' },
  // { label: 'Клиентски № ЕРП', value: 'erpClientNumber', size: 300, type: 'text' },
  { label: 'Тип на мерене', value: 'measureType', size: 300, type: 'dropdown' },
  { label: 'СТП профил', value: 'STPMeasure', size: 300, type: 'dropdown' },
  // { label: 'Тарифност', value: 'tariff', size: 300, type: 'dropdown' },
  { label: 'Напрежение', value: 'voltage', size: 300, type: 'dropdown' },
  // { label: 'Пазар', value: 'market', size: 300, type: 'dropdown' },
  { label: 'Предоставена Мощност', value: 'deliveredPower', size: 300, type: 'textLabeled', text: "Kw" },
  { label: 'Прогнозна Месечна Консумация MWh', value: 'averageConsumption', size: 300, type: 'textLabeled', text: "MWh" },
  { label: 'Абонатен Номер', value: 'subscriberNumber', size: 300, type: 'text' },
  { label: 'Номер на електромер', value: 'meterNumber', size: 300, type: 'text' },
  { label: 'Прогнозируемост', value: 'forecast', size: 300, type: 'dropdown' },
  { label: 'Сезонност', value: 'seasoning', size: 300, type: 'dropdown' },
  { label: 'Собствена централа', value: 'consumerOwnPlant', size: 300, type: 'switch' },
  { label: 'Инсталирана мощност', value: 'consumerProductionPower', size: 300, type: 'textLabeled' },
  { label: 'Край на входиране', value: 'entryEnd', size: 300, type: 'date' },
  { label: 'Производител', value: 'isProducer', size: 300, type: 'switch' },
  { label: 'Фактурирай мрежи', value: 'requiresNetworkData', size: 300, type: 'switch' },
  { label: 'Пререгистрация', value: 'transfer', size: 300, type: 'switch' },
  { label: 'Фактура', value: 'invoice', size: 180, type: 'invoice' },
  { label: 'Други Документи', value: 'otherDocuments', size: 300, type: 'documents' },
  { label: 'Коментар', value: 'comment', size: 300, type: 'text' },
  { label: 'Получава енергия от', value: 'activeFrom', size: 300, type: 'date' },
  { label: '', value: '', size: 40, type: 'icons' },
]

export const createObjectsProducerFields = [
  { label: 'Незабавна активация', value: 'existingObject', size: 125, type: 'text' },
  { label: 'Нова централа', value: 'producerNewPlant', size: 150, type: 'switch' },
  { label: 'Тип обект/описание', value: 'description', size: 300, type: 'text' },
  { label: 'ЕРП', value: 'erp', size: 300, type: 'dropdown' },
  { label: 'ИТН', value: 'itn', size: 300, type: 'text' },
  { label: 'ИТН Мерения', value: 'itnMeasure', size: 300, type: 'text' },
  { label: 'Град', value: 'city', size: 300, type: 'text' },
  { label: 'Адрес', value: 'address', size: 300, type: 'text' },
  { label: 'Напрежение', value: 'voltage', size: 300, type: 'dropdown' },
  { label: 'Инсталирана мощност', value: 'producerProductionPower', size: 300, type: 'text' },
  { label: 'Тип ВЕИ', value: 'producerType', size: 300, type: 'dropdown' },
  { label: 'Прогнозиране ВЕИ', value: 'producerForecasting', size: 300, type: 'switch' },
  { label: 'Ново ВЕИ 2021 +', value: 'producerAfter2021', size: 300, type: 'switch' },
  { label: 'Преференции', value: 'producerPreferences', size: 300, type: 'switch' },
  { label: 'Собствени нужди', value: 'producerOwnNeed', size: 300, type: 'switch' },
  { label: 'Член', value: 'producerDirectMember', size: 300, type: 'radio' },
  { label: 'ИТН ММС', value: 'itnMMS', size: 300, type: 'text' },
  { label: 'Край на входиране', value: 'entryEnd', size: 300, type: 'date' },
  { label: 'Пререгистрация', value: 'transfer', size: 300, type: 'switch' },
  { label: 'Фактура', value: 'invoice', size: 180, type: 'invoice' },
  { label: 'Други Документи', value: 'otherDocuments', size: 300, type: 'documents' },
  { label: 'Коментар', value: 'comment', size: 300, type: 'text' },
  { label: 'Получава енергия от', value: 'activeFrom', size: 300, type: 'date' },
  { label: '', value: '', size: 40, type: 'icons' },

]

export const requiredCreateObjectFields = ['erp', 'itn', 'city', 'measureType', 'voltage', 'averageConsumption']
export const requiredCreateObjectProducerFields = ['description', 'erp', 'itn', 'city', 'voltage', 'producerProductionPower', 'producerType']

export const invoicesTypesButtons = [
  { value: 'equalizing', label: 'Крайни' },
  { value: 'notEqualizing', label: 'Авансови' },
]

export const invoicesSearchOptions = [
  { value: 'eic', label: 'ЕИК/ЕГН', client: true },
  { value: 'fullName', label: 'Име на фирма', shortLabel: 'Фирма', client: true },
]

export const invoicesFields = [
  { label: 'Фактура №', value: 'invoiceNumber', size: 180, sortable: true },
  { label: 'Дата фактура ', value: 'invoiceDate', size: 180, sortable: true },
  { label: 'PDF', value: 'pdfFile', size: 100, sortable: true },
  { label: 'Име клиент', value: 'fullName', size: 300 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Тип', value: 'type', sortable: true },
  { label: 'Пореден номер', value: 'consecutiveNumber', size: 120, sortable: true },
  { label: 'Прогнозно потребление', size: 170, value: 'energy', sortable: true },
  { label: 'Прогнознa стойност', size: 170, value: 'energyPrice', sortable: true },
  { label: 'Сума без ДДС', size: 180, value: 'base', sortable: true },
  { label: 'Статус имейл', value: 'invoiceSent', size: 180, sortable: true },
  { label: 'Анулирана', value: 'deletedAt', size: 150, sortable: true },

  ...clientsFields
    .filter(({ value }) =>
      [
        'comments',
        'dealers',
        'deliveryStart',
        'deliveryEnd',
        'product',
      ].includes(value)
    )?.map((o) => ({ ...o, sortable: ['deliveryStart', 'deliveryEnd', 'product'].includes(o.value) })),
  // { label: 'Имейл адрес', value: 'email' },
  // { label: 'Обща сума', value: '' },

]

export const invoicesEqualizingFields = [
  { label: 'Фактура №', value: 'invoiceNumber', size: 180, sortable: true },
  { label: 'Дата фактура ', value: 'invoiceDate', size: 180, sortable: true },
  { label: 'PDF', value: 'pdfFile', size: 100, sortable: true },
  { label: 'Име клиент', value: 'fullName', size: 300 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Общо потребление', value: 'consumptionTotal', size: 150, sortable: true },
  { label: 'Сума без ДДС', value: 'totalWithoutVat', size: 200, sortable: true },
  { label: 'Депозити', value: 'deposit', size: 180, sortable: true },
  { label: 'Лихви', value: 'interest', size: 180, sortable: true },
  { label: 'Компенсации', value: 'compensation', size: 180, sortable: true },
  { label: 'Стойност', value: 'consumptionTotalAmount', size: 200, sortable: true },
  { label: 'Статус имейл', value: 'invoiceSent', size: 180, sortable: true },
  { label: 'Дата генериране ', value: 'createdAt', size: 200, sortable: true },
  { label: 'Анулирана', value: 'deletedAt', size: 150, sortable: true },
  ...clientsFields
    .filter(({ value }) =>
      [
        'comments',
        'objects',
        'measureType',
        'product',
        'dealers',
        'contractType',
        'deliveryStart',
        'deliveryEnd',
        'penalty',
      ].includes(value)
    )?.map((o) => ({ ...o, sortable: ['deliveryStart', 'deliveryEnd', 'product'].includes(o.value) })),
  { label: 'Грапа фактури', value: 'invoiceGroup', size: 200, sortable: true },
  // { label: 'Получени данни от ЕРП', value: '', sortable: false },
  // { label: 'Разминавания', value: '', sortable: false },
  // { label: 'Авансово/междинно', value: '', sortable: false },
  // { label: 'Баланс', value: '', sortable: false },
  // { label: 'Обща сума', value: 'total', sortable: true },
  // { label: 'Издадена на', value: 'createdAt', sortable: true },
  // { label: 'Статус имейл', value: '', sortable: false },
]

export const invoiceSectionFields = [
  { label: 'Код', value: 'cipher' },
  { label: 'Вид', value: 'name', size: 600 },
  { label: 'К-во', value: 'quantity' },
  { label: 'Единична цена', value: 'singleUnitPrice' },
  { label: 'Обща стойност', value: 'value' },
]

export const noticesTypesButtons = [
  { value: 'credit', label: 'Кредитни' },
  { value: 'debit', label: 'Дебитни' },
  // { value: 'deposits', label: 'Депозити' },
]

export const noticesFields = [
  { label: 'Документ №', value: 'documentNumber', size: 150 },
  { label: 'Дата', value: 'createdAt', size: 150 },
  { label: 'Сума', value: 'sum', size: 150 },
  { label: 'ДДС', value: 'vat', size: 150 },
  { label: 'Дължима сума', value: 'total', size: 150 },
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'eic' },
  { label: 'Град', value: 'city' },
  { label: 'Обект/и', value: 'objects' },
  { label: 'ЕРП', value: 'erp' },
  { label: 'Търговец', value: '' },
  { label: 'Начало доставка', value: 'deliveryStart', size: 200 },
  { label: 'Край доставка', value: 'deliveryEnd', size: 200 },
  { label: 'Имейл адрес', value: 'email' },
]

export const referencesTypes = [
  { label: 'Фактури', value: 'invoices' },
  { label: 'Неокончателни фактури', value: 'intermediateInvoices' },
  { label: 'Игнорирани точки', value: 'objectsIgnored' },
  { label: 'Търговски отчети', value: 'reports' },
  { label: 'Служители', value: 'employees' },
  { label: 'Роли', value: 'permissions' },
  { label: 'Импортирания', value: 'imports' },
  { label: 'Борса', value: 'market' },
  { label: 'Комисионни', value: 'commissions' },
  { label: 'Групи клиенти', value: 'clientsGroups' },
  { label: 'Номенклатури', value: 'nomenclatures' },
  { label: 'Компенсации', value: 'compensationSettings' },
  { label: 'Номерация фактури', value: 'invoiceNumber' },
]

export const intermediateInvoicesTypes = [
  { label: 'Междинно плащане 10 дни', value: 'intermediate-10' },
  { label: 'Междинно плащане 15 дни', value: 'intermediate-15' },
  { label: 'Авансово плащане 10 дни', value: 'advance-10' },
  { label: 'Авансово плащане 15 дни', value: 'advance-15' },
  { label: 'Авансово плащане 30 дни', value: 'advance-30' },
]

export const referencesInvoicesTypes = [
  { label: 'Генерирани', value: 'generated' },
  { label: 'Нефактурирани', value: 'not-generated' },
  { label: 'Прогрес', value: 'progress' },
  { label: 'Готови за фактуриране', value: 'ready' },
  { label: 'Разминавания', value: 'differences' },
]

export const referencesInvoicesLength = [
  { label: 'Всички', value: 'all' },
  { label: 'Месец', value: 'month' },
  // { label: 'Ден/Период', value: 'period' },
]

export const referenceIntermediateInvoicesFields = {
  generated: [
    { label: 'Период', value: 'period', size: 150 },
    { label: 'Тотал на фактурите', value: 'totalPrice', size: 200 },
    { label: 'Общо междинни фактури', value: 'totalIntermediate', size: 250 },
    { label: 'Общо авансови фактури', value: 'totalAdvance', size: 250 },
    { label: 'Обща стойност на ДДС ', value: 'vat', size: 250 },
    { label: 'Фактурирани клиенти', value: 'clients', size: 250 },

  ],
  progress: [
    { label: 'Тип плащане', value: 'type' },
    { label: 'Първа фактура', value: 'consecutiveNumber_1' },
    { label: 'Втора фактура', value: 'consecutiveNumber_2' },
    { label: 'Трета фактура', value: 'consecutiveNumber_3' },
  ],
}

export const referenceInvoicesFields = {
  generated: [
    { label: 'Период', value: 'period', size: 150 },
    { label: 'Тотал на фактурите', value: 'totalPrice', size: 200 },
    { label: 'Обща стойност на снабдяване', value: 'deliveryTotalPrice', size: 300 },
    { label: 'Средна цена', value: 'averagePrice', size: 150 },
    { label: 'Обща стойност на 1001', value: 'totalEnergyPrice', size: 250 },
    { label: 'Общо количество на 1001', value: 'totalEnergy', size: 250 },
    { label: '1002 ТЗО сума', value: 'tzoPrice', size: 200 },
    { label: '1003 Акциз', value: 'excisePrice', size: 200 },
    { label: 'Мрежови ЕРМ Запад', value: 'erpWest', size: 200 },
    { label: 'Мрежови ЕР Юг', value: 'erpSouth', size: 200 },
    { label: 'Мрежови ЕРП Север', value: 'erpNorth', size: 200 },
    { label: 'Мрежови ЕРП Златни Пясъци', value: 'erpGoldenSands', size: 250 },
    { label: 'Мрежови ЕСО', value: 'eso', size: 200 },
    { label: 'Приспаднати депозити', value: 'deposits', size: 250 },
    { label: 'Приспаднати авансови', value: 'advances', size: 250 },
    { label: 'Приспаднати междинни', value: 'intermediate', size: 250 },
    { label: 'Общо Лихви', value: '', size: 200 },
    { label: 'Обща стойност на ДДС ', value: 'vat', size: 250 },
    { label: 'Фактурирани клиенти', value: 'clients', size: 250 },
    { label: 'Фактурирани обекти', value: 'objects', size: 250 },
  ],
  'not-generated': clientsFields.filter(({ value }) =>
    [
      'fullName',
      'eic',
      'city',
      'objects',
      'erp',
      'dealers',
      'Отговорник',
      'contractType',
      'intermediateInvoicingType',
      'product',
      'base',
      'night',
      'max',
      'market',
      'marketCeiling',
      'marketFloor',
      'kW последен месец',
      'autoSign',
      'terms',
      'penalty',
    ].includes(value)
  ),
  progress: [
    { label: 'ЕРП', value: 'erp', size: 300 },
    { label: 'Клиенти', value: 'clients', size: 300 },
    { label: 'Точки', value: 'objects', size: 300 },
  ],
  ready: [
    { label: 'Групи фактури', value: 'invoiceGroups' },
    ...clientsFields.filter(({ value }) =>
      ['fullName', 'eic', 'city', 'objects', 'erp', 'product', 'contractType', 'terms', 'email'].includes(value)
    ),
    { label: 'Декларация', value: 'declaration', size: 200 },
  ],
  differences: [
    { label: 'Име на клиент', value: 'fullName', size: 300 },
    { label: 'ИТН', value: 'itn', size: 320 },
    { label: 'Тип на мерене', value: 'measureType', size: 200 },
    { label: 'Сетълмент данни', value: 'periodValue', size: 200 },
    { label: 'Мрежови данни', value: 'networkValue', size: 200 },
    { label: 'Разлика', value: 'diff', size: 200 },
    { label: 'Фактуриране по', value: 'valueKey', size: 400 },
  ],
  errors: [
    { value: 'fullName', label: 'Клиент', size: 200 },
    { value: 'eic', label: 'ЕИК/ЕГН', size: 200 },
    { value: 'itn', label: 'ИТН', size: 200 },
    { value: 'error', label: 'Грешка' },
    { value: 'ignore', label: "", size: 100 }
  ],
  missing: [
    { value: 'erp', label: 'ЕРП', size: 200 },
    { value: 'itn', label: 'ИТН', size: 200 },
    { label: 'В мрежови', value: 'inNetwork', size: 200 },
    { label: 'В почасови', value: 'inPeriod', size: 200 },
    { label: 'Име', value: 'name', size: 200 },
  ],
  invoiceGroups: [
    { label: 'Име', value: 'name', size: 200 },
    { label: 'Обекти', value: 'length', size: 40 },
    { label: 'ИТН', value: 'itn', size: 320 },
  ],
  'not-generated-export': [
    { value: 'Клиент', label: 'Клиент' },
    { value: 'eic', label: 'ЕИК/ЕГН' },
    { value: 'ЕРП', label: 'ЕРП' },
    { value: 'ИТН', label: 'ИТН' },
    { value: 'Тип договор', label: 'Тип договор' },
    { value: 'Декларация', label: 'Декларация' },
    { value: 'Статус', label: 'Статус' },
    { value: 'Грешка', label: 'Грешка' },
    { value: 'Налични мрежови', label: 'Налични мрежови' },
    { value: "Наличeн сетълмент", label: "Наличeн сетълмент" },
    { value: 'Използвай мрежи', label: 'Използвай мрежи' },
    { value: 'Тип на мерене', label: 'Тип на мерене' },
  ]
}

export const invoicesObjectsFields = [
  { label: 'Грешка', value: 'error', size: 500 },
  { label: "", value: 'ignore', size: 140 },
  // { label: 'Мрежови', value: 'hasNetwork', size: 100 },
  // { label: 'Сетълмент', value: 'hasPeriod', size: 120 },
  ...clientsObjectsFields.filter(({ value }) => !['confirmed', 'leaving'].includes(value)),
  // { label: 'Тип на мерене', value: 'measureType', size: 300 },
  { label: 'Декларация', value: 'requiresNetworkData', size: 200 },
  { label: 'Периодични данни', value: 'hasPeriod', size: 300 },
  { label: 'Мрежи', value: 'hasNetwork', size: 300 },
  { label: 'Разминавания', value: 'hasProgress', size: 300 },
  // { label: 'Разпределение', value: 'techData', size: 300 },
]

export const referencesImportsTypes = [
  { label: 'Потребление', value: 'consumption' },
  { label: 'СТП коефициент', value: 'STPCoefficients' },
  { label: 'Списъци ЕРП', value: 'ERPFiles' },
]

export const communicationPeriodFields = [
  { label: 'Файлове', value: 'files' },
  { label: 'Клиенти', value: 'clients' },
  { label: 'ИТН', value: 'itns' },
  { label: 'Точки', value: 'objects' },
  { label: 'Консумация', value: 'total' },
  { label: 'Консумация - реактивно', value: 'totalR' },
  { label: 'Последен импорт', value: 'lastImport' },
]

export const communicationPeriodFilesFields = [
  { label: 'XLSX', value: 'xlsxFile', size: 40 },
  { label: 'XML', value: 'xmlFile', size: 40 },
  { label: 'Име', value: 'filename', size: 200 },
  // { label: 'ЕРП', value: 'erp', size: 200 },
  { label: 'ИТН', value: 'itn', size: 200 },
  { label: 'Брой записи', value: 'entries', size: 130 },
  { label: 'Дата сваляне', value: 'createdAt', size: 170 },
  { label: 'Дата файл', value: 'messageDate', size: 170 },
  { label: 'Първи запис', value: 'firstEntry', size: 170 },
  { label: 'Последен запис', value: 'lastEntry', size: 170 },
  { label: 'Профил', value: 'profile', size: 100, sortable: true },
  { label: 'Общо количество', value: 'total', size: 200 },
  { label: 'Общо количество - реактивно', value: 'totalR', size: 200 },
]

export const communicationNetworkFields = [
  { label: 'Клиенти', value: 'clients' },
  { label: 'ИТН', value: 'itns' },
  { label: 'Точки', value: 'objects' },
  { label: 'Консумация', value: 'total' },
  { label: 'Непълни данни', value: 'dataIncomplete' },
  { label: 'Последен импорт', value: 'lastImport' },
]

export const communicationNetworkFilesFields = [
  { label: 'JSON', value: 'jsonFile', size: 40 },
  { label: 'XML', value: 'xmlFile', size: 40 },
  { label: 'Име', value: 'filename', size: 200 },
  { label: 'ИТН', value: 'itn', size: 200 },
  { label: 'Дата сваляне', value: 'createdAt', size: 170 },
  { label: 'Дата файл', value: 'messageDate', size: 170 },
  { label: 'Първи запис', value: 'firstEntry', size: 170 },
  { label: 'Последен запис', value: 'lastEntry', size: 170 },
  { label: 'Вид', value: 'type', size: 60, sortable: true },
  { label: 'Тип', value: 'messageType', size: 60, sortable: true },
]

export const communicationCorrectionsFields = [
  { label: 'JSON', value: 'jsonFile', size: 40 },
  { label: 'XML', value: 'xmlFile', size: 40 },
  { label: 'Име', value: 'file', size: 200 },
  { label: 'ИТН', value: 'itn', size: 200 },
  { label: 'Дата сваляне', value: 'createdAt', size: 170 },
  { label: 'Дата файл', value: 'messageDate', size: 170 },
  { label: 'Първи запис', value: 'from', size: 170 },
  { label: 'Последен запис', value: 'to', size: 170 },
  { label: 'Тип', value: 'dataType', size: 170, sortable: true },
  { label: 'Общо количество', value: 'total', size: 170 },
  { label: 'ABR', value: 'isFinishing', size: 170, sortable: true },
  { label: 'Приложени', value: 'appliedAt', size: 170, sortable: true },
]

export const communicationObjectsFields = [
  { label: 'Файлове', value: 'files' },
  { label: 'Последен импорт', value: 'lastImport' },
]

export const communicationObjectsFilesFields = [
  { label: 'JSON', value: 'jsonFile', size: 40 },
  { label: 'XML', value: 'xmlFile', size: 40 },
  { label: 'Име', value: 'filename', size: 200 },
  { label: 'Дата сваляне', value: 'createdAt', size: 170 },
  { label: 'Дата файл', value: 'messageDate', size: 170 },
  { label: 'ИТН', value: 'itn', size: 200 },
  { label: 'Тип', value: 'messageType', size: 60, sortable: true },
]

export const referenceImportsFields = {
  consumption: [
    { label: 'Име файл', value: 'name', size: 500, sortable: true },
    { label: 'Тип', value: 'type', size: 150 },
    { label: 'ЕРП', value: 'erp', size: 150, sortable: true },
    { label: 'Качен на', value: 'createdAt', size: 180, sortable: true },
    { label: 'Качен от', value: 'uploadedFrom', size: 180, sortable: true },
    { label: 'Импортирани', value: 'imported', size: 160, sortable: true },
    { label: 'Фигуриращи', value: 'existing', size: 160, sortable: true },
    { label: 'Липсващи', value: 'missing', size: 160, sortable: true },
    { label: 'Без цена', value: 'noPrice', size: 160, sortable: true },
    { label: 'Общо потребление', value: 'totalConsumption', size: 160, sortable: true },
    { label: 'Липсващо потребление', value: 'missingConsumption', size: 160, sortable: true },
    { label: 'Обща сума', value: 'totalPrice', size: 160, sortable: true },
    { label: 'Издадени фактури', value: 'data', size: 200 },
  ],
  STPCoefficients: [
    { label: 'ЕРП', value: '', size: 300 },
    { label: 'СТП профил', value: '', size: 300 },
    { label: 'Качено на', value: 'uploadedDate', size: 300 },
    { label: 'Файл име', value: 'name', size: 500 },
  ],
  ERPFiles: [
    { label: 'ЕРП', value: '', size: 300 },
    { label: 'Тип', value: '', size: 300 },
    { label: 'Качено на', value: 'uploadedDate', size: 300 },
    { label: 'Файл име', value: 'name', size: 500 },
  ],
}

export const referencesEmployeesFields = [
  { label: 'Име на потребител', value: 'fullName', size: 250, sortable: true },
  { label: 'Роля в системата', value: 'role', size: 275, sortable: true },
  { label: 'Група/екип', value: 'userGroups', size: 200, sortable: true },
  { label: 'Добавен на', value: 'createdAt', size: 130 },
  { label: 'Имейл за достъп', value: 'email', size: 300 },
]

export const screenEmployeesFields = [
  { label: 'Име на потребител', value: 'fullName', size: 250 },
  { label: 'Група/екип', value: 'userGroups', size: 250 },
  { label: 'Дни отсъствия за месеца', value: 'daysOffCount' },
]

export const userDaysOffOptions = [
  { label: 'Платен отпуск', value: 'paid' },
  { label: 'Неплатен отпуск', value: 'unpaid' },
  { label: 'Болничен', value: 'sick' },
  { label: 'Хоум офис', value: 'homeOffice' },
  { label: 'Друго', value: 'other' },
]

export const screenEmployeesDetailsFields = [
  { label: 'Период', value: 'period' },
  { label: 'Причина', value: 'type' },
  { label: 'Заместващ', value: 'substitute' }
]

export const referencesReportsTypes = [
  { value: 'registrations', label: 'Регистрации' },
  { value: 'trade', label: 'Отчети' },
  { value: 'portfolio', label: 'Портфолио' },
]

export const referencesReportsLength = [
  { label: 'Всички', value: 'all' },
  { label: 'Година', value: 'year' },
  { label: 'Месец', value: 'month' },
]

export const referenceMarketLength = [
  { value: 'day', label: 'Ден' },
  { value: 'week', label: 'Седмица' },
  { value: 'month', label: 'Месец' },
  { value: 'year', label: 'Година' },
]

export const filePreviewMissingFields = [
  { label: 'Клиент', value: 'client', size: 300 },
  { label: 'ИТН Обект', value: 'itn', size: 320 },
  { label: 'Описание', value: 'description', size: 300 },
  { label: 'ЕРП', value: 'erp', size: 300 },
  { label: 'Град', value: 'city', size: 300 },
  { label: 'Адрес', value: 'address', size: 300 },
]

export const filePreviewPeriodFields = [
  { value: 'name', label: 'Име на клиент', size: 400 },
  { value: 'itn', label: 'ИТН на точка', size: 400 },
  { value: 'from', label: 'От' },
  { value: 'to', label: 'До' },
  { value: 'total', label: 'Общо количество, квтч.' },
]

export const filePreviewProgressFields = referenceInvoicesFields.differences

export const mapESOClients = {
  '0213': '32X001100100176O',
  '0606': '32X0011001001254',
  '0714': '32X001100100390M',
  '0915': '32X0011001003897',
  1208: '32X001100101166M',
  1401: '32X001100100088G',
  1807: '32X001100100392I',
  2338: '32X0011001009089',
  2708: '32X0011001016905',
  2714: '32X001100101734B',
  2717: '32X001100100508P',
  2802: '32X0011001003214',
  2807: '32X001100100100K',
}

export const referenceNomenclaturesFields = [
  { label: 'Код', value: 'code', size: 100 },
  { label: 'Наименование', value: 'name', size: 300 },
  { label: 'Изписани в мрежовите фактури', value: 'nameInInvoice', size: 500 },
  { label: 'Цена', value: 'price', size: 100 },
]

export const exportInvoicesGeneratedStatsFields = [
  { label: 'Клиент', value: 'client' },
  { label: 'ЕИК/ЕГН', value: 'eic' },
  { label: 'ИТН', value: 'itn' },
  { label: 'ЕРП', value: 'erp' },
  { label: 'Номер фактура', value: 'invoiceNumber' },
  { label: 'Дата фактура', value: 'invoiceDate' },
  { label: 'Дата падеж', value: 'dateOfPayment' },
  { label: 'Количество 1001', value: 'totalEnergy' },
  { label: 'Цена 1001', value: 'averagePrice' },
  { label: 'Стойност 1001', value: 'totalEnergyPrice' },
  { label: 'ЗКО 1002', value: 'tzoPrice' },
  { label: 'Акциз 1003', value: 'excisePrice' },
  { label: 'Мрежови тотал', value: 'totalDeliveryPrice' },
  { label: 'Стойност фактура тотал без ДДС', value: 'totalWithoutVat' },
  { label: 'Количество за компенсация мегавати', value: 'compensationsQuantity' },
  { label: 'Стойност компенсация мегават', value: 'compensationsAmount' },
  { label: 'Стойност компенсация тотал', value: 'compensations' },
]

export const exportInvoicesGeneratedInfoFields = [
  { label: 'Клиент', value: 'client' },
  { label: 'ЕИК/ЕГН', value: 'eic' },
  { label: 'Номер фактура', value: 'invoiceNumber' },
  { label: 'Дата фактура', value: 'invoiceDate' },
  { label: 'Дата падеж', value: 'dateOfPayment' },
  { label: 'Количество 1001', value: 'totalEnergy' },
  { label: 'Надбавка', value: 'additions' },
  { label: 'Цена 1001', value: 'averagePrice' },
  { label: 'Стойност 1001', value: 'totalEnergyPrice' },
  { label: 'Акциз', value: 'excise' },
  { label: 'Мрежови тотал', value: 'totalDeliveryPrice' },
  { label: 'Стойност снабдяване и мрежови', value: 'deliveryAndNetworks' },
  { label: 'Приспадания', value: 'deposits' },
  { label: 'Номер + дата фактура приспадане', value: 'depositsInfo' },
  { label: 'Стойност без ДДС', value: 'totalWithoutVat' },
  { label: 'Стойност с ДДС', value: 'totalWithVat' },
  { label: 'Стойност КИ без ДДС', value: 'depositsAmountNotUsed' },
  { label: 'Количество за компенсация мегавати', value: 'compensationsQuantity' },
  { label: 'Цена компенсация на мегават', value: 'compensationsAmount' },
  { label: 'Стойност компенсация тотал', value: 'compensations' },
  { label: 'Лихва', value: 'interests' },
  { label: 'Стойност за плащане', value: 'withVatInterestsAndCompensations' },
  { label: 'Енергия за добро', value: 'donationFond' },
]
  .filter(({ value }) => process.env.REACT_APP_PLATFORM === 'Synergon' || !['donationFond'].includes(value))
  .filter(({ value }) => process.env.REACT_APP_PLATFORM === 'Proakt' || !['excise'].includes(value))

export const exportInvoicesAIGeneratedStatsFields = [
  { label: 'Клиент', value: 'client' },
  { label: 'ЕИК/ЕГН', value: 'eic' },
  { label: 'Тип', value: 'type' },
  { label: 'Номер фактура', value: 'invoiceNumber' },
  { label: 'Дата фактура', value: 'invoiceDate' },
  { label: 'Дата падеж', value: 'dateOfPayment' },
  { label: 'Стойност без ДДС', value: 'totalWithoutVat' },
  { label: 'Стойност с ДДС', value: 'totalWithVat' },
]

export const annexChangeColumns = [
  { label: 'Променлива', value: 'label' },
  { label: 'Преди', value: 'before' },
  { label: 'След', value: 'after' },
]

export const annexChangeFields = (product) => [
  { label: 'Продукт', value: 'product' },
  ...productsPricesFields.filter(({ types }) => types.includes(product)),
  { label: 'Борса в процент', value: 'marketIsPercent' },

  { label: "Вид фактуриране", value: 'intermediateInvoicingType' },
  { label: 'Дата на фактуриране', value: 'advanceFirstDate' },

  { label: 'Дата на договор', value: 'contractDate' },
  { label: '№ на договор', value: 'contractNumber' },

  { label: 'Тип договор', value: 'type' },
  { label: 'Версия', value: 'version' },

  { label: 'Вид срочност', value: 'maturity' },
  { label: 'Начало на договор', value: 'deliveryStart' },
  { label: 'Нов край на договора', value: 'deliveryEnd' },

  { label: 'Дни падеж', value: 'paymentDaysType' },

  { label: 'Общи условия', value: 'terms' },
  { label: 'Декларация', value: 'declaration' },
  { label: 'Автоматично преподписване', value: 'autoSign' },

  { label: 'Неустойка', value: 'penalty' },
  { label: 'Неустойка', value: 'penaltyPercent' },
  { label: 'Неустойка фикс', value: 'penaltyIsFixed' },

  { label: 'Депозит напускане', value: 'depositLeaving' },
  { label: 'Депозит напускане', value: 'depositLeavingPercent' },
  { label: 'Депозит напускане фикс', value: 'depositLeavingIsFixed' },

  { label: 'Предизвестие', value: 'notice' },
  { label: 'Предизвестие', value: 'noticeDays' },

  { label: 'Търговци', value: 'dealers' },

]

export const producersAnnexChangesFields = (product) => [
  { label: 'Продукт', value: 'product' },
  ...productsPricesFields.filter(({ types }) => types.includes(product)),
  { label: 'Борса в процент', value: 'marketIsPercent' },

  { label: "Вид фактуриране", value: 'invoicingMethod' },
  { label: "Балансиране", value: 'balancingMethod' },
  { label: "Под", value: 'balancingFloor' },
  { label: "Таван/Фикс", value: 'balancingCeiling' },
  { label: "Фактура за балансиране", value: 'producerInvoiceBalancing' },
  { label: "Вид фактуриране", value: 'producerInvoicingType' },
  { label: "Фактуриране на", value: 'producerInvoicingIntermediateDate' },

  { label: 'Дата на договор', value: 'contractDate' },
  { label: '№ на договор', value: 'contractNumber' },

  { label: 'Версия', value: 'version' },

  { label: 'Начало на договор', value: 'deliveryStart' },
  { label: 'Нов край на договора', value: 'deliveryEnd' },

  { label: 'Дни падеж', value: 'paymentDays' },

  { label: 'Общи условия', value: 'terms' },
  { label: 'Предизвестие', value: 'notice' },
  { label: 'Предизвестие', value: 'noticeMonths' },
  { label: 'Автоматично преподписване', value: 'autoSign' },
  { label: 'Автоматично преподписване', value: 'autoSignMonths' },
]

export const referencesIntermediateInvoicesTypes = [
  { label: 'Генерирани', value: 'generated' },
  { label: 'Общ преглед', value: 'progress' },
]

export const documentTypes = [
  { value: 'letter of attorney', label: 'Пълномощно' },
  { value: 'contract', label: 'Договор' },
  { value: 'declaration', label: 'Декларация' },
  { value: 'invoice', label: 'Фактура' },
]

export const documentTypesProducers = [
  { value: 'letter of attorney', label: 'Пълномощно' },
  { value: 'contract', label: 'Договор' },
  { value: 'protocol', label: 'Протокол' },
]

export const reasonsForLeaving = [
  'Смяна на доставчик',
  'Изтичане на договор',
  'Изключване от БГ по инициатива на Синергон',
  'Закриване на партида',
  'Несъстоятелност',
  'Демонтиране на СТИ',
  'Пререгистрация',
  'Друго',
]

export const intermediateInvoicesGenerateFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'Коментари', value: 'comments', size: 370 },
  { label: 'Предходна Година', value: 'invoiceLastYear', size: 300 },
  { label: 'Предходен Месец', value: 'invoiceLastMonth', size: 300 },
  { label: 'К-во енергия', value: 'energy', size: 190 },
  { label: 'Цена', value: 'energyPrice', size: 150 },
  { label: 'Стойност', value: 'energyTotal', size: 150 },
  // { label: 'ТЗО', value: 'tzoPrice', size: 130 },
  // { label: 'Акциз', value: 'excisePrice', size: 130 },
  { label: 'Пренос', value: 'delivery', size: 130 },
  { label: 'Предходен Месец', value: 'invoiceLastMonthTotalWithoutVat', size: 150 },
  { label: 'Цена', value: 'energyPricePercent', size: 150 },
  { label: 'Сума без ДДС', value: 'totalWithoutVat', size: 150 },
  { label: 'Крайна сума', value: 'total', size: 150 },
  { label: 'Падеж', value: 'paymentDays', size: 150 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 130 },
  { label: 'Обект/и', value: 'objects', size: 130 },
  { label: 'Тип', value: 'measureTypes', size: 180 },
  { label: 'Тип договор', value: 'type', size: 130 },
  { label: 'Общи условия', value: 'terms', size: 130 },
]

export const intermediateInvoicesGenerateExportFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'Последен коментар', value: 'comments', size: 170 },
  { label: 'Предходна Година - МВТч за калкулация', value: 'invoiceLastYear', size: 300 },
  { label: 'Предходна Година - Тотал МВТч', value: 'invoiceLastYear2', size: 300 },
  { label: 'Стойност МВТч калкулация по цена предходна година', value: 'invoiceLastYear3', size: 300 },
  { label: 'Стойност тотал МВТч предходна година', value: 'invoiceLastYear4', size: 300 },
  { label: 'Предходен месец - МВТч за калкулация', value: 'invoiceLastMonth', size: 300 },
  { label: 'Предходен месец - Тотал МВТч', value: 'invoiceLastMonth2', size: 300 },
  { label: 'Стойност МВТч калкулация по цена предходен месец', value: 'invoiceLastMonth3', size: 300 },
  { label: 'Стойност тотал МВТч предходен месец', value: 'invoiceLastMonth4', size: 300 },
  { label: 'Предходен месец', value: 'invoiceLastMonth5', size: 300 },
  { label: 'К-во енергия', value: 'energy', size: 220 },
  { label: 'Цена', value: 'energyPrice', size: 190 },
  { label: 'Стойност енергия', value: 'energyTotal', size: 190 },
  // { label: 'ТЗО', value: 'tzoPrice', size: 130 },
  // { label: 'Акциз', value: 'excisePrice', size: 130 },
  { label: 'Пренос', value: 'delivery', size: 130 },
  { label: 'Сума без ДДС', value: 'totalWithoutVat', size: 150 },
  { label: 'Крайна сума', value: 'total', size: 150 },
  { label: 'Падеж', value: 'paymentDays', size: 220 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 130 },
  { label: 'Обект/и', value: 'objects', size: 130 },
  { label: 'Тип', value: 'measureTypes', size: 180 },
  { label: 'Тип договор', value: 'type', size: 130 },
  { label: 'Общи условия', value: 'terms', size: 130 },
]

export const depositsLeavingCreateFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 120 },
  { label: 'Обект/и', value: 'objects', size: 120 },
  { label: 'Тип', value: 'measureTypes', size: 150 },
  { label: 'Тип договор', value: 'type', size: 120 },
  { label: 'Общи условия', value: 'terms', size: 90 },

  { label: 'Депозит', value: 'createDepositLeaving', size: 110 },
  { label: 'Последно потребление', value: 'lastConsumption', size: 150 },
  { label: 'Стойност последна ф-ра', value: 'lastTotal', size: 160 },
  { label: 'Депозит процент/фикс', value: 'percent', size: 170 },
  { label: 'Депозит стойност', value: 'value', size: 180 },

  { label: 'Неустойка', value: 'createPenalty', size: 110 },
  { label: 'Средна дневна консумация', value: 'penaltyAverageConsumption', size: 150 },
  { label: 'Средна цена', value: 'penaltyAveragePrice', size: 110 },
  { label: 'Оставащи дни', value: 'penaltyDaysLeft', size: 110 },
  { label: 'Сметка', value: 'penaltyInitial', size: 150 },
  { label: 'Неустойка процент/фикс', value: 'penaltyPercent', size: 170 },
  { label: 'Неустойка стойност', value: 'penaltyValue', size: 180 },

  { label: 'Падеж', value: 'paymentDays', size: 170 },
  { label: 'Допълнителни задължения', value: 'additionalCharges', size: 220 },
]

export const depositsLeavingFields = [
  { label: 'Фактура №', value: 'invoiceNumber', size: 180, sortable: true },
  { label: 'Дата фактура ', value: 'invoiceDate', size: 180, sortable: true },
  { label: 'PDF', value: 'pdfFile', size: 100, sortable: true },
  { label: 'Неустойка', value: 'penalty', size: 120 },
  { label: 'Статус имейл', value: 'invoiceSent', size: 180, sortable: true },
  { label: 'Анулирана', value: 'deletedAt', size: 150, sortable: true },
  { label: 'Коментари', value: 'comments', size: 200 },
  { label: 'Падеж', value: 'dateOfPayment', size: 150, sortable: true },
  { label: 'Стойност', value: 'total', sortable: true, size: 150 },
  { label: 'Допълнителни задължения', value: 'additionalCharges', size: 180 },
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Град', value: 'city', size: 150 },
  { label: 'Обект/и', value: 'objects', size: 150 },
  { label: 'ЕРП', value: 'erp', size: 150 },
  { label: 'Търговец', value: 'dealers', size: 150 },
  { label: 'Начало на доставка', value: 'deliveryStart', size: 210, sortable: true },
  { label: 'Край на доставка', value: 'deliveryEnd', size: 210, sortable: true },
  { label: 'Имейл адрес', value: 'email', size: 200 },
  // { label: 'Статус', value: 'status', sortable: true },
]

export const invoicePricingFields = [
  { label: "Стойност на снабдяване", value: 'networkTotal' },
  { label: "Мрежови услуги", value: 'deliveryTotal' },
  { label: "Депозит", value: 'deposits' },
  { label: "Данъчна основа", value: 'base' },
  { label: "Данъчна ставка", value: 'vat' },
  { label: "Стойност за плащане", value: 'withVat' },
  { label: "Начислени лихви", value: 'interests' },
  { label: "Стойност за плащане след лихва", value: 'withVatAndInterests' },
  { label: "Бонус 'Лоялен партньор'", value: 'bonuses' },
  { label: "Компенсация", value: 'compensations' },
  { label: "Стойност за плащане", value: 'withVatInterestsAndCompensations' }
]

export const notificationsTypesFields = [
  { label: 'Изтичащи', value: 'clients' },
  { label: 'С уведомления', value: 'notifications' },
  { label: 'Архив', value: 'archive' },
]

export const notificationsClientsTableFields = [
  { label: 'Име клиент', value: 'fullName', size: 300 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Тип', value: 'measureType', size: 200 },
  { label: 'СТП профил', value: 'STPMeasure', size: 200 },
  { label: 'Обект/и', value: 'objects', size: 150 },
  { label: 'Сезонност', value: 'seasoning', size: 180 },
  { label: 'Предмет на дейност', value: 'businessType' },
  // { label: 'Тежест по месеци', value: '' },
  { label: 'Търговец', value: 'dealers', size: 200 },
  { label: 'Общи условия', value: 'terms', size: 200 },
  { label: 'Свързани клиенти', value: 'connectedGroup', size: 250 },
  { label: 'Група', value: 'group', size: 250 },
  { label: 'Край на доставка', value: 'deliveryEnd', size: 250 },
  { label: 'Тип плащане', value: 'intermediateInvoicingType', size: 250 },
  { label: 'Дни падеж', value: 'paymentDaysType' },
  { label: 'Авт. преподписване', value: 'autoSign', size: 200 },
  { label: 'Неустойка', value: 'penalty', size: 200 },
  { label: 'Потребление последен месец', value: 'lastConsumption', size: 180 },
  { label: 'Средно потребление', value: 'averageConsumption', size: 180 },
  { label: 'Изчислено за', value: 'averageConsumptionMonths', size: 180 },

  { label: 'Настоящ продукт', value: 'product', size: 180 },
  { label: 'Настояща цена', value: 'currentPrice', size: 180 },
  { label: 'Нов Продукт', value: 'newProduct', size: 300 },
  { label: 'Нова цена', value: 'base', size: 180 },
  { label: 'Борса', value: 'market', size: 180 },
  { label: 'Нощна', value: 'night', size: 180 },
  { label: 'Нов срок', value: 'period', size: 180 },
  { label: 'Разлика', value: 'difference', size: 180 },
  { label: 'Влиза в сила от / DD.MM.YYYY', value: 'activationDate', size: 180 },
  { label: 'Статус', value: 'status', size: 180 },
]

export const notificationsStatusesMap = {
  created: 'Изготвено',
  generated: 'Генерирано',
  send: 'Изпратено',
  applied: 'В сила',
  cancelled: 'Анулирано',
}

export const notificationsGeneratedTableFields = [
  { label: 'Статус', value: 'status', size: 150, sortable: true },
  { label: 'Име клиент', value: 'fullName', size: 300, sortable: true },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150, sortable: true },

  { label: 'PDF', value: 'mailPdf', size: 80, sortable: true },
  { label: 'Имейл изпратен', value: 'emailDate', size: 180, sortable: true },


  { label: 'Общи условия', value: 'terms', size: 90 },
  { label: 'Свързани клиенти', value: 'connectedGroup', size: 180, sortable: true },
  { label: 'Група', value: 'group', size: 180 },
  { label: 'Тип плащане', value: 'intermediateInvoicingType', size: 250 },

  { label: 'Начало старо', value: 'oldDeliveryStart', size: 130, sortable: true },
  { label: 'Край старо', value: 'oldDeliveryEnd', size: 130, sortable: true },
  { label: 'Продукт стар', value: 'oldProduct', size: 130, sortable: true },
  { label: 'Цена старо', value: 'oldPrice', size: 130, sortable: true },
  { label: 'Борса старо', value: 'oldPriceMarket', size: 130, sortable: true },

  { label: 'Начало ново', value: 'deliveryStart', size: 130, sortable: true },
  { label: 'Край нов', value: 'deliveryEnd', size: 130, sortable: true },
  { label: 'Срок нов', value: 'period', size: 130, sortable: true },
  { label: 'Продукт нов', value: 'newProduct', size: 130, sortable: true },
  { label: 'Цена нова', value: 'base', size: 130, sortable: true },
  { label: 'Борса нова', value: 'market', size: 130, sortable: true },
  { label: 'Разлика', value: 'difference', size: 130, sortable: true },

  { label: 'Влиза в сила от / DD.MM.YYYY', value: 'activationDate', size: 180, sortable: true },
  { label: 'Приложен', value: 'applied', size: 180, sortable: true },
]

export const commissionsFields = [
  { label: 'Статус', value: 'status', size: 180, sortable: true },
  { label: 'Комисионер', value: 'userFullName', size: 180, sortable: true },
  { label: 'Вид лице', value: 'userIsCompany', size: 180, sortable: true },
  { label: 'Имейл', value: 'userEmail', size: 180, sortable: true },
  { label: 'Име клиент', value: 'clientFullName', size: 180, sortable: true },
  { label: 'ЕИК/ЕГН', value: 'clientIdentifier', size: 180, sortable: true },
  { label: 'Обект/и', value: 'objects', size: 180, sortable: true },
  { label: 'MW последен месец', value: 'consumption', size: 180, sortable: true },
  { label: 'Последно фактуриране ', value: 'singleUnitPrice', size: 180, sortable: true },
  { label: 'Последна промяна цена', value: 'lastPriceChange', size: 180, sortable: true },
  { label: 'Комисионна', value: 'commission', size: 180, sortable: true },
  { label: 'Коментар', value: 'comment', size: 300, sortable: true }
]

export const commissionsSearchOptions = [
  { value: 'clientIdentifier', label: 'ЕИК/ЕГН' },
  { value: 'clientFullName', label: 'Име клиент', shortLabel: 'Клиент' },
  { value: 'userFullName', label: 'Комисионер' },
]

export const commissionsReferenceGeneralFields = [
  { label: 'Месец', value: 'monthYear', size: 130 },
  { label: 'Файл', value: 'xlsxUrl', size: 60 },
  { label: 'Имейл изпратен', value: 'emailSent', size: 200 },
  // { label: 'Статус', value: 'status', size: 180 },
  { label: 'Комисионер', value: 'userFullName', size: 180 },
  { label: 'Вид лице', value: 'userIsCompany', size: 180 },
  { label: 'Имейл', value: 'userEmail', size: 180 },
  { label: 'Клиенти', value: 'objects', size: 180 },
  { label: 'Общо потребление', value: 'consumptionTotal', size: 180 },
  { label: 'Средна цена комисион', value: 'commissionAvg', size: 180 },
  { label: 'Минимална цена комисион', value: 'commissionMin', size: 180 },
  { label: 'Максимална цена комисион', value: 'commissionMax', size: 180 },
  { label: 'Сума за получаване', value: 'total', size: 180 },
  { label: 'Средна продажна цена', value: 'singleUnitPriceAvg', size: 180 },
  // { label: 'Документи', value: 'files', size: 180 }
]

export const commissionsReferenceCreatedFields = [
  { label: 'Месец', value: 'monthYear', size: 130 },
  { label: 'Комисионер', value: 'userFullName', size: 180, sortable: true },
  { label: 'Вид лице', value: 'userIsCompany', size: 180, sortable: true },
  { label: 'Имейл', value: 'userEmail', size: 180, sortable: true },
  { label: 'Име клиент', value: 'clientFullName', size: 180, sortable: true },
  { label: 'ЕИК/ЕГН клиент', value: 'clientIdentifier', size: 180, sortable: true },

  { label: 'Общо потребление', value: 'consumption', size: 180 },
  { label: 'Сума за получаване', value: 'total', size: 180 },
  { label: 'Средна продажна цена', value: 'singleUnitPrice', size: 180 },

  { label: 'Промени', value: 'objects', size: 180 },
]

export const commissionsReferenceFields = {
  'generated': commissionsReferenceGeneralFields,
  'created': commissionsReferenceCreatedFields
}

export const commissionsReferenceDetailsFields = [
  { label: 'Име клиент', value: 'clientFullName', size: 180 },
  { label: 'ЕИК/ЕГН', value: 'clientIdentifier', size: 180 },
  { label: 'Обект/и', value: 'objects', size: 180 },
  { label: 'Потребление', value: 'consumption', size: 180 },
  { label: 'Комисионна', value: 'commission', size: 180 },
  { label: 'Сума за получаване', value: 'total', size: 180 },
  { label: 'Статус плащане', value: 'invoicePaid', size: 180 },
]

export const commissionsReferenceCreatedDetailsFields = [
  { label: 'Комисионна', value: 'commission' },
  { label: 'Активна от', value: 'activeFrom' },
]

export const commissionStatusMap = {
  invoiced: "Фактурирани",
  confirmed: "Одобрен",
  generated: "Генериран",
  notified: "Уведомени",
  undefined: ""
}

export const compensationSettingsFields = [
  { label: 'Месец', value: 'forMonth', size: 100 },
  { label: 'Текст', value: 'text', size: 300 },
  { label: 'Максимална стойност компенсация', value: 'amount', size: 150 },
  { label: 'Нова версия', value: 'version', size: 120 },
  { label: 'Таван на цената', value: 'baseAmount', size: 150 },
  { label: 'БНЕБ средна цена база', value: 'bnebAverage', size: 150 },
  { label: 'Включено', value: 'enabledForMonth', size: 100 },
  { label: '', value: 'edit', size: 80 },
]

export const referencesCommissionsTypes = [
  { label: 'Генерирани', value: 'generated' },
  { label: 'Добавени', value: 'created' },
  { label: 'Плащания', value: 'payments' },
]

export const invoiceNumberTypes = [
  { label: "Фактури - Крайни", value: 'equalizing' },
  { label: "Фактури - Авансови и междинни", value: 'intermediateAdvance' },
  { label: "Фактури - Балансиране", value: 'producer' },
  { label: "Депозит - напускащи", value: 'leaving' },
  { label: "Уведомления", value: 'notification' },
  { label: "Контрагент ажур", value: 'ajur' },
  { label: "Контрагент ажур Производители", value: 'ajurProducer' },
]

export const editorConfig = {
  toolbar: [
    { name: 'corrections', items: ['Undo', 'Redo'] },
    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
    { name: 'styles', items: ['Format'] },
    { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
    { name: 'links', items: ['Link', 'Unlink'] },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
    { name: 'tools', items: ['Maximize'] },
  ],
  autoGrow_maxHeight: 200,
  resize_enabled: false,
  enterMode: 'ENTER_BR',
  plugins: [
    'basicstyles',
    'clipboard',
    'emoji',
    'enterkey',
    'entities',
    'floatingspace',
    'indentlist',
    'justify',
    'link',
    'list',
    'toolbar',
    'undo',
    'wysiwygarea',
    "maximize",
    'format',
    'resize',
    "autogrow"
  ],
}

export const referenceIgnoredObjectsFields = [
  { label: 'Клиент', value: 'fullName', sortable: true },
  { label: 'ЕИК/ЕГН', value: 'eic', sortable: true },
  { label: 'ИТН', value: 'itn', sortable: true },
  { label: 'Месец', value: 'ignoreMonth', sortable: true, size: 150 },
  { label: 'Производство', value: 'isProducer', sortable: true, size: 150 },
  { label: '', value: 'button', size: 150 },
]

export const modalEmailStatsFields = [
  { label: 'Файл', value: 'description', size: 500 },
  { label: 'Първо отворена', value: 'firstView', size: 250 },
  { label: 'Последно отворена', value: 'lastView', size: 250 },
  { label: 'Отваряния', value: '_views', size: 100 }
]

export const modalEmailStatsViewsFields = [
  { label: 'Дата', value: 'date' },
  { label: 'Браузър', value: 'browser' },
  { label: 'Версия', value: 'browserVersion' },
  { label: 'IP', value: 'ip' },
  { label: 'ОС', value: 'os' },
  { label: 'Платформа', value: 'platform' },
]

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/

export const IBANRegex = /(^LT[0-9]{18}$)|(^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$)/

export const balancingMethodOptions = ['Фикс в лв. / МВТч', 'Включено', 'Методика на СЕ', 'Таван в лв./МВТч', 'Под и Таван в лв./МВТч']

export const invoicingMethodOptions = ['Прогноза Х цена прогноза', 'Прогноза Х цена измерено', 'Измерено Х цена измерено']

export const producerBanksOptions = [
  "Банка ДСК",
  "УниКредит Булбанк",
  "Юробанк България",
  "Обединена българска банка",
  "Кей Би Си Банк България",
  "Първа инвестиционна банка",
  "Инвестбанк",
  "Централна кооперативна банка",
  "Алианц Банк България",
  "Българо - американска кредитна банка",
  "ТИ БИ АЙ Банк",
  "ПроКредит Банк(България)",
  "Интернешънъл Асет Банк",
  "Търговска Банка Д",
  "Българска банка за развитие",
  "Токуда Банк",
  "Тексим Банк",
  "Общинска банка",
  "Айкарт",
  "Изипей",
  "Револют"
]

export const consumptionTimeFormats = {
  minutes: 'HH:mm DD.MM.YYYY',
  hours: 'HH:mm DD.MM.YYYY',
  days: 'DD.MM.YYYY',
  months: 'MM.YYYY',
}

export const referencesProducersTypes = [
  { label: 'Справки', value: 'references' },
  { label: 'Фактури', value: 'invoices' },
  // { label: 'Генерирани', value: 'generated' },
]

export const producersReferencesOptions = [
  { label: 'Опростена справка по дни', value: 'producerSimpleByDays' },
  { label: 'Опростена справка по часове', value: 'producerSimpleByHours' },
  { label: 'Допълнителна справка Почасово мерене', value: 'producerAdditionalPeriodData' },
  { label: 'Пълна справка Ф по дни', value: 'producerFullFByDays' },
  { label: 'Пълна справка Ф по часове', value: 'producerFullFByHours' },
  { label: 'Пълна справка РБ по дни ', value: 'producerFullRBByDays' },
  { label: 'Пълна справка РБ 15мин', value: 'producerFullRBByHours' },
  { label: 'ОС с балансиране по дни', value: 'producerSimpleBalancingByDays' },
  { label: 'ОС с балансиране по часове', value: 'producerSimpleBalancingByHours' },
]

export const defaultContractSettings = {
  type: 'Комбиниран',
  maturity: 'Брой месеци',
  paymentDaysType: 'Работни',
  paymentDays: 5,
  autoSign: true,
  autoSignMonths: 12,
  terms: true,
  penalty: true,
  penaltyPercent: 25,
  depositLeaving: true,
  depositLeavingPercent: 150,
  notice: true,
  noticeDays: 65
}

export const defaultProducerSettings = {
  paymentDays: 20,
  autoSign: true,
  invoicingMethod: 'Измерено Х цена измерено'
}

export const producersReferencesFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'clientIdentifier', size: 150 },
  { label: 'ИТН', value: 'itn', size: 200 },
  { label: 'ЕРП', value: 'erp', size: 200 },
  { label: 'Обект', value: 'object', size: 150 },
  { label: 'Тип', value: 'type', sortable: true },
  { label: 'Дата генериране', value: 'createdAt', size: 150 },
  { label: 'Файл', value: 'file', size: 130 },
  { label: 'HTML', value: 'htmlFile', size: 130 },
  { label: 'ДС', value: 'additionalStat', size: 130 },
  { label: 'Фактура Б', value: 'invoice', size: 140 },
  { label: 'Статус имейл', value: 'statsSent', size: 180 },
  { label: 'Имейл до', value: 'statsSentTo', size: 180 },
  { label: 'Фактура Е', value: 'templatedInvoice', size: 160 },

  { label: 'ФЕ - Проверена', value: 'templatedInvoiceConfirmed', size: 180 },
  { label: 'ФЕ - Качена', value: 'fileUploadDate', size: 220 },
  { label: 'ФЕ - Проверена дата', value: 'timestamp', size: 220 },
  { label: 'ФЕ - Проверена от', value: 'employee', size: 220 },
  { label: 'ФЕ - Номер', value: 'invoiceNumber', size: 220 },
  { label: 'Търговец', value: 'dealers', size: 200 },

  { label: 'Коментар', value: 'comments', size: 180 },
  { label: 'ЕИ', value: 'data.sheet1.summary1.totalProduction', size: 100 },
  { label: 'ЦЕИ', value: 'data.sheet1.summary1.singleUnitPrice', size: 100 },
  { label: 'СЕИ', value: 'data.sheet1.summary1.total', size: 100 },
  { label: 'Продукт', value: 'product', size: 150 },
  { label: 'Цена', value: 'price', size: 100 },
  { label: 'Вид фактуриране', value: 'invoicingMethod', size: 250 },
  { label: 'Балансиране', value: 'balancingMethod', size: 180 },
  { label: 'Цена балансиране - Фикс/Таван', value: 'balancingCeiling', size: 180 },
  { label: 'Цена балансиране - Под', value: 'balancingFloor', size: 180 },
].filter(({ value }) => process.env.REACT_APP_PLATFORM === 'Synergon' || !['additionalStat'].includes(value))

export const producersReferencesExportFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'clientIdentifier' },
  { label: 'ИТН', value: 'itn' },
  { label: 'Обект', value: 'object' },
  { label: 'Инсталирана мощност', value: 'producerProductionPower' },
  { label: 'ЕРП', value: 'erp' },


  { label: 'Количество произведена ел.енергия', value: 'data.sheet1.summary1.totalProduction' },
  { label: 'Чиста борсова цена', value: 'marketPrice' },
  { label: '% надбавка от борсова цена', value: 'price' },
  { label: 'Стойност на надбавката, лв. без ДДС', value: 'marketTotal' },
  { label: 'Ед.цена за фактуриране, лв. без ДДС', value: 'data.sheet1.summary1.singleUnitPrice' },
  { label: 'Обща стойност, лв.без ДДС', value: 'data.sheet1.summary1.total' },
  { label: 'ДДС', value: 'vat' },
  { label: 'Обща стойност, лв.с ДДС', value: 'withVat' },
]

export const interestsFields = [
  { label: 'Име клиент', value: 'clientFullName', size: 450, sortable: true },
  { label: 'Ажур', value: 'clientAjur', sortable: true },
  { label: 'ЕИК/ЕГН', value: 'clientEic', sortable: true },
  { label: 'Сума', value: 'amount', sortable: true },
  { label: 'Не фактурирай лихви', value: 'doNotCalculateInterests', sortable: true },
  { label: 'Да се прилага във фактура', value: 'shouldUse', sortable: true },
  { label: 'Добавена', value: 'manuallyAdded', sortable: true },
  { label: 'Детайли', value: 'details', size: 450 },


]

export const interestsDetailsFields = [
  { label: 'Тип', value: 'type' },
  { label: 'Лихва', value: 'interest' },
  { label: 'Сума', value: 'amount' },
  { label: 'Дата на плащане', value: 'date' },
  { label: 'Номер Фактура', value: 'invoiceNumber' },
  { label: 'Сума фактура', value: 'invoiceAmount' },
  { label: 'Дата на фактура', value: 'invoiceDate' },
  { label: 'Дата на падеж', value: 'dateOfPayment' },
]

export const producersInvoicesFields = [
  { label: 'Фактура №', value: 'invoiceNumber', size: 180, sortable: true },
  { label: 'PDF', value: 'pdfFile', size: 100, sortable: true },
  { label: 'Статус имейл', value: 'invoiceSent', size: 180, sortable: true },
  { label: 'Дата фактура ', value: 'invoiceDate', size: 180, sortable: true },
  { label: 'Падеж', value: 'dateOfPayment', size: 180, sortable: true },
  { label: 'Стойност без ДДС', value: 'base', size: 180, sortable: true },
  { label: 'Стойност', value: 'withVat', size: 180, sortable: true },
  { label: 'Име клиент', value: 'fullName', size: 300 },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Тип справка', value: 'statsType', sortable: true },
]

export const invoiceReferencesOptions = [
  { label: 'Приложение по БНЕБ (по точка; 15мин)', value: 'bnebHourly' },
  { label: 'Приложение по БНЕБ (общо; по ден)', value: 'bnebDaily' },
  { label: 'Приложение по БНЕБ (общо; 15мин)', value: 'bnebHourlyAggregated' },
  { label: 'Товаров профил (общ; 15мин)', value: 'loadProfileTotal' },
  { label: 'Товаров профил (общ; по час)', value: 'loadProfileTotalHourly' },
  { label: 'Товаров профил (по точка; 15мин)', value: 'loadProfileByPoint' },
  { label: 'Товаров профил (по точка; по час)', value: 'loadProfileByPointHourly' },
  { label: 'Допълнителна справка към фактура', value: 'applicationInvestbank' },
]

export const referenceClientsGroupsFields = [
  { label: 'Име', value: 'name', sortable: true },
  { label: 'Създадена на', value: 'createdAt', sortable: true },
  { label: 'Създадена от', value: 'createdBy', sortable: true },
  { label: 'Брой клиенти', value: 'clientsCount', sortable: true, size: 900 },
]

export const referenceClientsGroupsDetailsFields = [
  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'eic' },
]

export const commissionsStatuses = {
  'empty': 'Празен',
  'generated': "Справка",
  'emailSent': 'Изпратена справка',
  'uploadedFile': 'Фактура/Декларация ',
  'paid': 'Платен'
}

export const producerTypeOptions = ['ФтЕЦ', 'ВЕЦ', 'ВтЕЦ', 'БиоГЕЦ', 'Когенерация']

export const defaultEntryEnd = moment().startOf('month').add(9, 'days').add(new Date().getDate() <= 10 ? 0 : 1, 'months').toDate()

export const communicationTypes = [
  { label: 'Периодични', value: 'period' },
  { label: 'Мрежови', value: 'network' },
  { label: 'Корекции', value: 'corrections' },
  { label: 'Точки', value: 'objects' },
]

export const penaltiesFields = [

  { label: 'PDF', value: 'pdfFile', size: 100, sortable: true },
  { label: 'Депозит', value: 'deposit', size: 120 },
  { label: 'Статус имейл', value: 'penaltySent', size: 180, sortable: true },
  { label: 'Анулирана', value: 'deletedAt', size: 150, sortable: true },

  { label: 'Падеж', value: 'dateOfPayment', size: 150, sortable: true },
  { label: 'Стойност', value: 'penaltyValue', size: 150, sortable: true },
  { label: 'Допълнителни задължения', value: 'additionalCharges', size: 180 },

  { label: 'Име клиент', value: 'fullName' },
  { label: 'ЕИК/ЕГН', value: 'eic', size: 150 },
  { label: 'Град', value: 'city', size: 150 },
  { label: 'Обект/и', value: 'objects', size: 150 },
  { label: 'ЕРП', value: 'erp', size: 150 },
  { label: 'Търговец', value: 'dealers', size: 150 },
  { label: 'Начало на доставка', value: 'deliveryStart', size: 210, sortable: true },
  { label: 'Край на доставка', value: 'deliveryEnd', size: 210, sortable: true },
  { label: 'Имейл адрес', value: 'email', size: 200 },
]

export const holidays = [
  "2024-01-01T00:00:00+02:00",
  "2024-03-03T00:00:00+02:00",
  "2024-03-04T00:00:00+02:00",
  "2024-05-01T00:00:00+03:00",
  "2024-05-03T00:00:00+03:00",
  "2024-05-04T00:00:00+03:00",
  "2024-05-06T00:00:00+03:00",
  "2024-05-24T00:00:00+03:00",
  "2024-09-06T00:00:00+03:00",
  "2024-09-22T00:00:00+03:00",
  "2024-09-23T00:00:00+03:00",
  "2024-12-24T00:00:00+02:00",
  "2024-12-25T00:00:00+02:00",
  "2024-12-26T00:00:00+02:00",
  "2025-01-01T00:00:00+02:00",
  "2025-03-03T00:00:00+02:00",
  "2025-04-18T00:00:00+03:00",
  "2025-04-19T00:00:00+03:00",
  "2025-04-21T00:00:00+03:00",
  "2025-05-01T00:00:00+03:00",
  "2025-05-06T00:00:00+03:00",
  "2025-05-24T00:00:00+03:00",
  "2025-05-26T00:00:00+03:00",
  "2025-09-06T00:00:00+03:00",
  "2025-09-08T00:00:00+03:00",
  "2025-09-22T00:00:00+03:00",
  "2025-12-24T00:00:00+02:00",
  "2025-12-25T00:00:00+02:00",
  "2025-12-26T00:00:00+02:00",
  "2026-01-01T00:00:00+02:00",
  "2026-03-03T00:00:00+02:00",
  "2026-04-10T00:00:00+03:00",
  "2026-04-11T00:00:00+03:00",
  "2026-04-13T00:00:00+03:00",
  "2026-05-01T00:00:00+03:00",
  "2026-05-06T00:00:00+03:00",
  "2026-05-24T00:00:00+03:00",
  "2026-05-25T00:00:00+03:00",
  "2026-09-06T00:00:00+03:00",
  "2026-09-07T00:00:00+03:00",
  "2026-09-22T00:00:00+03:00",
  "2026-12-24T00:00:00+02:00",
  "2026-12-25T00:00:00+02:00",
  "2026-12-26T00:00:00+02:00",
  "2026-12-28T00:00:00+02:00"
]
