import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { checkIsDealer } from 'utilities'
import { setModal, logout } from '../../../actions'
import './styles.scss'

const Settings = ({ hide }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isDealer = checkIsDealer()

  return (
    <div className="popup-settings-container">
      <p onClick={() => { dispatch(setModal({ isOpen: true, type: 'profile' })); hide() }} > Профил </p>
      {/* <p onClick={() => { dispatch(setModal({ isOpen: true, type: 'colleagues' })); hide() }} > Колеги </p> */}
      {!isDealer && <><p onClick={() => { history.push(`/resources`); hide() }} > Ресурси </p>
        <p onClick={() => { history.push(`/support`); hide() }} > Помощен център </p>
        <p onClick={() => { history.push(`/tutorials`); hide() }} > Инструкции </p>
        <p onClick={() => { history.push(`/mailer`); hide() }} > Масови имейли </p>
      </>}
      <p className="row" onClick={() => dispatch(logout())}>
        Изход <span className="icon icon-logout no-active" />
      </p>
    </div>
  )
}

export default Settings
