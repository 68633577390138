import { Button } from "@makedonski/admin-ui"
import React, { useEffect, useState } from "react"
import "./styles.scss"
import { Client, Inputs } from "components"
import { useDispatch, useSelector } from "react-redux"
import { getData, massEditClientsPrice, setModal, startLoading } from "actions"
import { productsPricesFields } from "config/constants"
import { loadDropdownOptions } from "utilities"
import moment from "moment"
import { Portal } from "react-overlays"


const MassEditClientsPrice = ({ close, fetch }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({ type: 'Консуматор', priceActiveFrom: moment().startOf('month').toDate(), producerPriceActiveFrom: moment().startOf('month').toDate() })
    const handleChange = (payload) => setData((prev) => ({ ...prev, ...payload }))

    const { products } = useSelector(({ data }) => data)
    useEffect(() => { if (!products) dispatch(getData('products')) }, [])

    const [showRequired, setShowRequired] = useState(false)

    const handleSubmit = () => {
        if (!data.group || !data?.product || productsPricesFields
            .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
            .map(({ value }) => value)
            .some((field) => [undefined, null, 0].includes(data?.price?.[field]))
        ) setShowRequired(true)
        else {
            dispatch(startLoading())
            dispatch(massEditClientsPrice({
                payload: { ...data },
                onSuccess: () => { dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: 'Успешна редакция', onClick: fetch, onRequestClose: fetch } })) }
            }))
        }
    }

    return <div className="modal-mass-edit-clients-price-container">
        <div className="modal-mass-edit-clients-price-header row">
            <h2>Масова редакция цени клиенти</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-mass-edit-clients-price-content">
            <div className="row">
                <div className="col">
                    <span>Група</span>
                    <Inputs.DropdownSelect
                        async paginate cacheOptions defaultOptions
                        loadOptions={(name, _, { page = 1 } = {}) => loadDropdownOptions({ path: '/groups/browse', payload: { page, name, limit: 10 }, paginate: true })}
                        rawValue={data?.group ?? []}
                        onChange={(group) => handleChange({ group })}
                        getOptionValue={({ _id }) => _id}
                        getOptionLabel={({ name }) => name}
                        additional={{ page: 1 }}
                        invalid={showRequired && !data.group}
                    />
                </div>
                <div className="col">
                    <span>Промяна на цена за</span>
                    <Inputs.RadioButtons
                        buttons={[
                            { label: `Консумация (${data?.group?.clients?.filter(({ clientType }) => ['Консуматор', 'Смесен'].includes(clientType))?.length ?? 0}/${data?.group?.clients?.length ?? 0} клиента)`, value: 'Консуматор' },
                            { label: `Производство (${data?.group?.clients?.filter(({ clientType }) => ['Производител', 'Смесен'].includes(clientType))?.length ?? 0}/${data?.group?.clients?.length ?? 0} клиента)`, value: 'Производител' },]}
                        value={data?.type}
                        onClick={(type) => handleChange({ type, product: null, price: null })}
                    />
                </div>
            </div>
            <div className="row">
                {<div className="col">
                    <span>Продукт</span>
                    <Inputs.Dropdown
                        options={products?.filter(({ forProducer }) => forProducer === (data?.type === "Производител"))?.map(({ _id, name }) => ({ label: name, value: _id }))}
                        onChange={({ value }) => {
                            const product = products?.find(({ _id }) => _id === value)?.name
                            setData({
                                ...data,
                                product: value,
                                price: ['Борса минус с Под на цената', 'Борса минус с Таван на цената', 'Борса минус с Под и Таван на цената'].includes(product) ? { marketIsPercent: true } : null
                            })
                        }}
                        value={data?.product?._id || data?.product}
                        className={`${showRequired && !data?.product && 'required'}`}
                    />
                </div>}

                {<Client.Forms.ProductPrices
                    products={products}
                    data={data}
                    handleChange={(payload) => setData({ ...data, price: { ...data?.price, ...payload } })}
                    showRequired={showRequired}
                />}
            </div>
            {data?.type === 'Консуматор' && <div className="row">
                <div className="col">
                    <span>Цена активна от</span>
                    <Inputs.DatePicker
                        value={data?.priceActiveFrom}
                        onChange={(priceActiveFrom) => handleChange({ priceActiveFrom: moment(priceActiveFrom).startOf('month').toDate() })}
                        maxDate={new Date()}
                        dateFormat={'MMMM yyyy'}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        className="month-picker"
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                </div>
            </div>}
            {data?.type === 'Производител' && <div className="row">
                <div className="col">
                    <span>Цена активна от</span>
                    <Inputs.DatePicker
                        value={data?.producerPriceActiveFrom}
                        onChange={(producerPriceActiveFrom) => handleChange({ producerPriceActiveFrom: moment(producerPriceActiveFrom).startOf('month').toDate() })}
                        maxDate={new Date()}
                        dateFormat={'MMMM yyyy'}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        className="month-picker"
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                </div>
            </div>}
        </div>
        <div className="modal-mass-edit-clients-price-footer row">
            <Button.Raised text='Редактирай' onClick={handleSubmit} />
        </div>
    </div>
}

export default MassEditClientsPrice