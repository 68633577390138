import { nanoid } from "nanoid"
import { URL } from "../config/settings"
import { store } from "config/store"
import { setModal, stopLoading } from "actions"
import { asyncForEach } from "./helpers"
import { Alerts, Headers } from "@makedonski/socourt-utilities"

export const uploadFile = async ({ file, location = '', createId } = {}) => {
  let filename = file.name
  filename = filename.split('%').join('').split(' ').join('')
  filename = createId ? [nanoid(), `${location}${filename}`,].join("_") : `${location}${filename}` || nanoid()
  const response = await fetch(`${URL}/cloudServices/s3Upload`, { method: 'POST', headers: Headers.getWithAuth(), body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? '' }) })
  if (!response.ok) throw new Error('Грешка');
  const { presignedUrl, url } = await response.json()
  const response2 = await fetch(presignedUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file?.type ?? '' } })
  if (!response2.ok) throw new Error('Грешка')
  return { location: url }
}
export const uploadFiles = async ({ files = [], location = '', createId } = {}) => {
  const uploaded = []
  await asyncForEach([...files], async (file) => uploaded.push(await uploadFile({ file, location, createId })))
  return uploaded
}

export const exportBatchFiles = async (files) => {
  try {
    const response = await fetch(`${URL}/utilities/exportBatchFiles`, { method: 'POST', headers: Headers.getWithAuth(), body: JSON.stringify(files) })
    if (!response.ok) throw new Error('Грешка')
    const { url, errors } = await response.json()
    if (url) {
      const link = document.createElement("a")
      link.href = url
      link.click()
    }

    if (errors.length) store.dispatch(setModal({
      isOpen: true,
      type: 'confirmation',
      props: {
        title: "Неуспешно свалени",
        hideButton: true,
        children: <div className="col" style={{ maxHeight: 400, overflow: 'auto' }}>
          {errors.map(({ Key }) => <p key={Key}>{Key}</p>)}
        </div>,
      }
    }))

  }
  catch (error) { Alerts.error(error) }
  finally { store.dispatch(stopLoading()) }
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
    if ((encoded.length % 4) > 0) encoded += '='.repeat(4 - (encoded.length % 4));
    resolve(encoded);
  };
  reader.onerror = reject;
});