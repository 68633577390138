import { pick } from 'lodash'
import { invoicesTypes, commentsTypes } from '../actions'
const initialState = {
  invoices: [],
  invoice: {},
  hasNextPage: false,
  nextPage: 1,

  showCheckboxes: false,
  selectedCheckboxes: [],
}

const invoices = (state = initialState, { type, payload }) => {
  switch (type) {
    case invoicesTypes.SET_FIELD:
      return { ...state, [payload?.field]: payload?.value }
    case invoicesTypes.SET_FIELDS:
      return { ...state, ...payload }
    case invoicesTypes.GET_INVOICES_FILTERS_SUCCESS:
      return { ...state, availableSort: payload?.enableSortFor, availableFilters: payload?.filters }
    case invoicesTypes.GET_INVOICES_SUCCESS:
      const newData = payload?.page === 1 ? payload?.docs : [...(state.invoices || []), ...(payload?.docs || [])]
      return {
        ...state,
        invoices: newData,
        availableSort: payload.enableSortFor,
        availableFilters: payload.filters,
        ...pick(payload, ["nextPage", 'hasNextPage', "amountMax", "amountMin", 'sort', 'totalDocs', 'totalWithoutVat'])
      }
    case commentsTypes.CREATE_COMMENT_INVOICE_SUCCESS:
      return { ...state, invoices: addCommentToInvoice(state.invoices, payload) }
    default:
      return state
  }
}

export default invoices

const addCommentToInvoice = (invoices = [], comment) => {
  if (!invoices) return
  const index = invoices.findIndex(({ _id }) => _id === comment.ref._id)
  const newInvoices = [...(invoices || [])]
  newInvoices.splice(index, 1, { ...invoices[index], comments: [...(invoices[index]?.comments || []), comment] })
  return newInvoices
}
