import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { apisTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import Swal from 'sweetalert2'


export const startApisFetch = (action$) => action$.pipe(
  ofType(apisTypes.START_APIS_FETCH),
  switchMap(({ payload: { data, onError, onSuccess } }) => Api.post(`${URL}/cloudServices/apis`, JSON.stringify({ clientIdentifier: data })).pipe(
    switchMap(({ response: { response } }) => ActionsObservable.create((obs) => {
      if (!response.length) {
        if (onError) onError()
        Swal.fire({
          title: 'Няма данни за лицето!',
          icon: 'error',
          html: 'Моля проверете за <a href="https://portal.nra.bg/home.html#/goto:rep.Vatquery.home" target="_blank" rel="noopener noreferrer">регистрация по ДДС в НАП</a>'
        })
      }
      else if (onSuccess) onSuccess(response)
      obs.next(stopLoading())
      obs.complete()
    })),
    catchError((err) => ActionsObservable.create((obs) => {
      Alerts.error('Error!', err?.response?.error?.message || '')
      obs.next(stopLoading())
      obs.complete()
    }))
  ))
)
