import { isEmpty } from "lodash"
import moment from "moment"
import React, { useRef } from "react"
import {
    emit,
    uploadFiles
} from '../helpers'
import "./styles.scss"
import { transformURL } from "utilities"

const Files = ({ task, socket, disabled }) => {
    const {
        _id,
        files
    } = task || {}

    const uploadRef = useRef()
    const handleUpload = async ({ target: { files } }) => {
        if (isEmpty([...files])) return
        const [uploaded] = await uploadFiles({ files, createId: true })
        emit(socket, { type: 'files/create', payload: { taskId: _id, name: files[0].name, url: uploaded.location } })
    }

    return <div className="task-management-files-container col">
        <div className="task-management-files-content">
            {files?.map(({ _id, name, createdAt, author, url }) => <div key={_id} className="task-management-single-file row">
                <div className="col">
                    <p>{name}</p>
                    <p className="details">{moment(createdAt).format('HH:mm | DD.MM.YYYY | ')}{author?.fullName}</p>
                </div>
                <div className="row">
                    {!disabled && <div
                        className="tasks-icon tasks-icon-delete"
                        onClick={() => emit(socket, { type: 'files/deleteOne', payload: { taskId: task._id, fileId: _id } })}
                    />}
                    <a href={transformURL(url || "")} target="_blank" rel="noopener noreferrer">
                        <div className="tasks-icon tasks-icon-download" />
                    </a>
                </div>
            </div>)}

        </div>
        {!disabled && <div className="task-management-files-footer card row">
            <div
                className="upload-button-container"
                onClick={() => uploadRef.current.click()}
            >
                <p>Добави нов</p>
                <input
                    style={{ display: 'none' }}
                    ref={uploadRef}
                    type="file"
                    multiple
                    accept="*"
                    onChange={handleUpload}
                />
            </div>
        </div>}
    </div>
}

export default Files