export const interestsTypes = {
    IMPORT_INVOICE_PAYMENTS: 'interests/IMPORT_INVOICE_PAYMENTS',
    IMPORT_INTERESTS_PAYMENTS: 'interests/IMPORT_INTERESTS_PAYMENTS',
    GENERATE_INTERESTS: 'interests/GENERATE_INTERESTS',
    GENERATE_INTERESTS_PAYMENTS: 'interests/GENERATE_INTERESTS_PAYMENTS',
    GET_INTERESTS: 'interests/GET_INTERESTS',
    GET_INTEREST: 'interests/GET_INTEREST',
    CREATE_INTEREST: 'interests/CREATE_INTEREST',
    UPDATE_INTEREST: 'interests/UPDATE_INTEREST',
    DELETE_INTEREST: 'interests/DELETE_INTEREST',
    EXPORT_INTERESTS_AJUR: 'interests/EXPORT_INTERESTS_AJUR',
}

export const importInvoicePayments = (payload) => ({
    type: interestsTypes.IMPORT_INVOICE_PAYMENTS,
    ...payload
})

export const importInterestsPayments = (payload) => ({
    type: interestsTypes.IMPORT_INTERESTS_PAYMENTS,
    ...payload
})

export const generateInterests = (payload) => ({
    type: interestsTypes.GENERATE_INTERESTS,
    ...payload
})

export const generateInterestsPayments = (payload) => ({
    type: interestsTypes.GENERATE_INTERESTS_PAYMENTS,
    ...payload
})

export const getInterests = (payload) => ({
    type: interestsTypes.GET_INTERESTS,
    ...payload
})

export const getInterest = (payload) => ({
    type: interestsTypes.GET_INTEREST,
    ...payload
})

export const createInterest = (payload) => ({
    type: interestsTypes.CREATE_INTEREST,
    ...payload
})

export const updateInterest = (payload) => ({
    type: interestsTypes.UPDATE_INTEREST,
    ...payload
})

export const deleteInterest = (payload) => ({
    type: interestsTypes.DELETE_INTEREST,
    ...payload
})

export const exportInterestsAjur = (payload) => ({
    type: interestsTypes.EXPORT_INTERESTS_AJUR,
    ...payload
})