import React, { useMemo, useEffect } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { startLoading, stopLoading } from 'actions'
import { Client, Inputs } from 'components'
import { checkIsDealer, uploadFiles } from 'utilities'
import { documentTypes, specialContractVersions, specialContractVersionsTerms } from 'config/constants'
import './styles.scss'

const DocumentsAndContract = ({
  data: client,
  setData: setClientData,
  contractSettings: data,
  setContractSettings: setData,
  handleChange,

  versions,
  products,
  handlePriceChange,

  showRequired,
  editable,
  isProfile,
  originalData,
  isInactive,
  setNewFiles
}) => {
  const dispatch = useDispatch()
  const isRequired = (field) => showRequired && !data?.[field] && 'required'
  const isDealer = checkIsDealer()
  const isChangingClientTypeToMixed = originalData?.clientType === 'Производител' && client?.clientType === 'Смесен'

  const handleRemoveFile = (index) => { const newFiles = data.files.slice(); newFiles.splice(index, 1); handleChange('files', newFiles) }
  const handleUpload = async (files, field) => {
    dispatch(startLoading())
    const uploaded = (await uploadFiles({ files, createId: true }))
    const payload = uploaded.map(({ location }, index) => ({ url: location, name: files[index].name, type: field, new: setNewFiles || isProfile }))
    dispatch(stopLoading())
    handleChange('files', [...(data?.files || []), ...payload])
  }

  const version = useMemo(() => versions?.find(({ _id }) => _id === data?.version)?.name?.split(' ')[0], [data?.version])

  useEffect(() => {
    if (client?.existingClient && data?.maturity === 'Брой месеци') {
      handleChange('deliveryEnd',
        data?.deliveryStart && data?.contractDurationMonths
          ? moment(data?.deliveryStart).add((data?.contractDurationMonths || 1) - 1, 'months').endOf('month').toDate()
          : null
      )
    }
  }, [data?.contractDurationMonths, data?.deliveryStart])


  return (
    <div className="client-forms-documents-and-contract-container">
      <h2>Документи и договор</h2>
      {(!isProfile || isInactive || editable) && <div className="row row-files">
        {documentTypes.map(({ value, label }, i) => {
          const fileIndex = data?.files?.findIndex((file) => file?.type === value)
          const file = data?.files?.map((f, index) => ({ ...f, index }))?.[fileIndex]
          return <div key={`documents-field-${i}`} className="col">
            <span>{label}</span>
            {file ? (
              <div className="row row-file">
                {editable && <Button.Icon name="plus" color="red" onClick={() => handleRemoveFile(file.index)} />}
                <span>{file.name}</span>
              </div>
            ) : (
              <Button.UploadButton
                text={
                  <Input.DragAndDrop handleDrop={(files) => handleUpload(files, value)}>
                    <span className="row ripple">
                      Добави файл <div className="icon icon-attach" />
                    </span>
                  </Input.DragAndDrop>
                }
                className={`${!editable && 'disabled'} ${!isProfile && ['letter of attorney', 'contract'].includes(value) && isRequired(value)} ${!isProfile && ['declaration'].includes(value) && data?.type === 'Комбиниран' && isRequired(value)}`}
                accept="*"
                onChange={({ target: { files } }) => handleUpload(files, value)}
                disabled={!editable}
              />
            )}
          </div>
        })}
        <div className="col">
          <span>Други документи</span>
          {data?.files?.filter(({ type }) => type === 'other')?.length
            ? <div className="row row-other-documents">
              {data.files.map((f, index) => ({ ...f, index })).filter(({ type }) => type === 'other').map((file) => <div key={file.url} className="row row-file">
                {editable && <Button.Icon name="plus" color="red" onClick={() => handleRemoveFile(file.index)} />}
                <span>{file.name}</span>
              </div>)}
            </div>
            : <Button.UploadButton
              text={<Input.DragAndDrop handleDrop={(files) => handleUpload(files, 'other')}>
                <span className="row ripple">Добави файл <div className="icon icon-attach" /></span>
              </Input.DragAndDrop>}
              multiple
              accept="*"
              onChange={({ target: { files } }) => handleUpload(files, 'other')}
              disabled={!editable}
            />}
        </div>
      </div>}
      <div className="row">
        <div className="col">
          <span>Продукт</span>
          <Inputs.Dropdown
            options={products?.filter(({ forProducer }) => !forProducer)?.map(({ _id, name }) => ({ label: name, value: _id, }))}
            onChange={({ value }) => {
              const product = products?.find(({ _id }) => _id === value)?.name
              setClientData({ ...client, product: value, price: ['Борса', 'Отделно балансиране'].includes(product) ? { market: 0 } : null })
            }}
            value={client?.product?._id || client?.product}
            className={`${showRequired && !client?.product && 'required'}`}
            disabled={!editable}
          />
        </div>
        <Client.Forms.ProductPrices
          products={products}
          data={client}
          handleChange={handlePriceChange}
          showRequired={showRequired}
          editable={editable}
        />
      </div>
      <div className="row row-contract-type">
        <div className="col col-radio-buttons">
          <span>Тип договор</span>
          <Inputs.RadioButtons
            buttons={['Комбиниран', 'Стандартен']}
            value={data?.type}
            onClick={(value) => setClientData({ contractSettings: { ...data, type: value, version: null }, declaration: false })}
            disabled={!editable}
          />
        </div>
        <div className="row">
          {isDealer && data?.type === 'Стандартен' && <div className={`col col-small col-switch col-declaration-dealer ${!editable && 'disabled'}`}>
            <span>Декларация</span>
            <Button.Switch
              isOn={client?.declaration}
              onChange={() => editable && setClientData({ declaration: !client?.declaration })}
            />
          </div>}

          <div className="col">
            <span>Версия договор</span>
            <Inputs.Dropdown
              options={versions
                ?.filter(({ _id, type, deletedAt }) => type === data?.type && (!deletedAt || _id === originalData?.contractSettings?.version?._id))
                ?.map(({ _id, name }) => ({ value: _id, label: name }))}
              onChange={({ value, label }) => setData({
                ...data,
                version: value,
                ...(data.notice && { noticeDays: specialContractVersions.some(v => label?.includes(v)) ? 90 : 65 }),
                ...(data.autoSign && data.contractDurationMonths && { autoSignMonths: specialContractVersions.some(v => label?.includes(v)) ? Number(data.contractDurationMonths) + 12 : 12 }),
                ...(specialContractVersionsTerms.some(v => label?.includes(v)) && { terms: true })
              })}
              value={data?.version?._id || data?.version}
              className={`${isRequired('version')}`}
              disabled={!editable}
            />
          </div>
          {!isDealer && <div className="col">
            <span>Дата на договор</span>
            <Inputs.DatePicker
              value={data?.contractDate}
              onChange={(value) => handleChange('contractDate', value)}
              /*className={`${isRequired('contractDate')}`}*/
              disabled={!editable}
            />
          </div>}
          {!isDealer && <div className="col">
            <span>№ на договор</span>
            <Input.Text
              value={data?.contractNumber || ''}
              onChange={({ target: { value } }) => handleChange('contractNumber', value)}
              /*inputClassName={`${isRequired('contractNumber')}`}*/
              disabled={!editable}
            />
          </div>}
        </div>
      </div>
      <div className="row row-contract-length">
        <div className="col col-radio-buttons">
          <span>Вид срочност на договор</span>
          <Inputs.RadioButtons
            buttons={['Брой месеци', 'Фиксиран край']}
            value={data?.maturity}
            onClick={(value) => setData({ ...data, maturity: value, contractDurationMonths: null, deliveryEnd: null })}
            disabled={!editable}
          />
        </div>
        <div className="row">
          {!isChangingClientTypeToMixed && (client?.existingClient || (client?.contractSettings?.deliveryStart && (!isDealer || data?.maturity !== 'Брой месеци'))) && (
            <div className="col">
              <span>Начало на доставка</span>
              <Inputs.DatePicker
                value={data?.deliveryStart}
                onChange={(value) => setClientData({
                  contractSettings: {
                    ...data,
                    deliveryStart: moment(value).startOf('month').toDate(),
                    deliveryEnd: data?.maturity === 'Брой месеци' && data?.contractDurationMonths
                      ? moment(value).startOf('month').add(data.contractDurationMonths, 'months').subtract(1, 'months').endOf('month').toDate()
                      : null
                  }
                })}
                minDate={moment().subtract(10, 'y').toDate()}
                className={`month-picker ${isRequired('deliveryStart')}`}
                showMonthYearPicker
                showFullMonthYearPicker
                disabled={!editable || isDealer}
              />
            </div>
          )}
          {data?.maturity === 'Брой месеци' && (
            <div className="col">
              <span>Срок на договор</span>
              <Input.Text
                value={data?.contractDurationMonths || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  setClientData({
                    contractSettings: {
                      ...data,
                      contractDurationMonths: value,
                      deliveryEnd: value && data.deliveryStart ? moment(data.deliveryStart).startOf('month').add(value, 'months').subtract(1, 'months').endOf('month').toDate() : null,
                      ...(data.autoSign && data.version && { autoSignMonths: specialContractVersions.some(v => version?.includes(v)) ? Number(value) + 12 : 12 })
                    }
                  })
                }}
                inputClassName={`${isRequired('contractDurationMonths')}`}
                disabled={!editable}
              />
            </div>
          )}
          {(!isChangingClientTypeToMixed || data?.maturity !== 'Брой месеци') && (data?.maturity !== 'Брой месеци' || client?.existingClient || isProfile) && (!isInactive || data?.maturity !== 'Брой месеци') && (
            <div className="col">
              <span>Край на доставка</span>
              <Inputs.DatePicker
                value={data?.deliveryEnd}
                onChange={(value) => handleChange('deliveryEnd', moment(value).endOf('month').toDate())}
                minDate={moment().add(1, 'months').startOf('month').toDate()}
                className={`month-picker ${isRequired('deliveryEnd')}`}
                showMonthYearPicker
                showFullMonthYearPicker
                disabled={!editable || (data?.maturity === 'Брой месеци' && client?.existingClient) || (data?.maturity === 'Брой месеци' && isProfile)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row row-contract-expire">
        {!isDealer && <div className="col col-radio-buttons">
          <span>Дни падеж</span>
          <Inputs.RadioButtons
            buttons={['Работни', 'Календарни', 'Ден от месеца']}
            value={data?.paymentDaysType}
            onClick={(data) => handleChange('paymentDaysType', data)}
            disabled={!editable}
          />
        </div>}
        <div className="row">
          {!isDealer && <>
            <div className="col">
              <span></span>
              <Input.Text
                value={data?.paymentDays || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  handleChange('paymentDays', value)
                }}
                inputClassName={`${isRequired('paymentDays')}`}
                disabled={!editable}
              />
            </div>
            <div className={`col col-small col-switch ${(!editable || specialContractVersionsTerms.some(v => v.includes(version))) && 'disabled'}`}>
              <span>Общи условия</span>
              <Button.Switch isOn={data?.terms} onChange={() => editable && !specialContractVersionsTerms.some(v => v.includes(version)) && handleChange('terms', !data?.terms)} />
            </div>
            {data?.type === 'Стандартен' && <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
              <span>Декларация</span>
              <Button.Switch
                isOn={client?.declaration}
                onChange={() => editable && setClientData({ declaration: !client?.declaration })}
              />
            </div>}
          </>}
          <div className={`col col-double col-switch ${!editable && 'disabled'}`}>
            <span>Автоматично преподписване</span>
            <div className="row row-auto-sign">
              <Button.Switch
                isOn={data?.autoSign}
                onChange={() => {
                  if (editable) {
                    setData({ ...data, autoSign: !data?.autoSign, autoSignMonths: null })
                    setTimeout(() => { document.getElementsByClassName('focusAutoSign')[0]?.focus() }, 0)

                  }
                }}
              />
              {data?.autoSign && (
                <Inputs.TextLabeled
                  label="мес."
                  value={data?.autoSignMonths || ''}
                  onChange={({ target: { value } }) => {
                    if (!/^\d{0,2}$/.test(value) && value !== '') return
                    handleChange('autoSignMonths', value)
                  }}
                  inputClassName={`focusAutoSign ${isRequired('autoSignMonths')}`}
                  disabled={!editable}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!isDealer && <div className="row">
        <div className={`col col-double col-switch ${!editable && 'disabled'}`}>
          <span>Неустойка</span>
          <div className="row row-auto-sign">
            <Button.Switch
              isOn={data?.penalty}
              onChange={() => {
                if (editable) {
                  setData({ ...data, penalty: !data?.penalty, penaltyPercent: null, penaltyIsFixed: null })
                  setTimeout(() => { document.getElementsByClassName('focusPenalty')[0]?.focus() }, 0)
                }
              }}
            />
            {data?.penalty && (
              <>
                <Inputs.TextLabeled
                  label={data?.penaltyIsFixed ? 'лв.' : "%"}
                  value={data?.penaltyPercent || ''}
                  onChange={({ target: { value } }) => {
                    if (!/^\d+$/.test(value) && value !== '') return
                    handleChange('penaltyPercent', value)
                  }}
                  inputClassName={`focusPenalty ${isRequired('penaltyPercent')}`}
                  disabled={!editable}
                />
                <div className={`col col-double col-switch ${!editable && 'disabled'}`} style={{ position: 'relative', marginLeft: 15 }}>
                  <span style={{ position: 'absolute', top: 0, transform: 'translateY(-100%)', left: 0, whiteSpace: 'nowrap' }}>Фикс</span>
                  <Button.Switch
                    isOn={data?.penaltyIsFixed}
                    onChange={() => { if (editable) { setData({ ...data, penaltyIsFixed: !data?.penaltyIsFixed }) } }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`col col-double col-switch ${!editable && 'disabled'}`}>
          <span>Депозит напускане</span>
          <div className="row row-auto-sign">
            <Button.Switch
              isOn={data?.depositLeaving}
              onChange={() => {
                if (editable) {
                  setData({ ...data, depositLeaving: !data?.depositLeaving, depositLeavingPercent: null, depositLeavingIsFixed: null })
                  setTimeout(() => { document.getElementsByClassName('focusDepositLeaving')[0]?.focus() }, 0)
                }
              }}
            />
            {data?.depositLeaving && (
              <>
                <Inputs.TextLabeled
                  label={data?.depositLeavingIsFixed ? 'лв.' : "%"}
                  value={data?.depositLeavingPercent || ''}
                  onChange={({ target: { value } }) => {
                    if (!/^\d+$/.test(value) && value !== '') return
                    handleChange('depositLeavingPercent', value)
                  }}
                  inputClassName={`focusDepositLeaving ${isRequired('depositLeavingPercent')}`}
                  disabled={!editable}
                />
                <div className={`col col-double col-switch ${!editable && 'disabled'}`} style={{ position: 'relative', marginLeft: 15 }}>
                  <span style={{ position: 'absolute', top: 0, transform: 'translateY(-100%)', left: 0, whiteSpace: 'nowrap' }}>Фикс</span>
                  <Button.Switch
                    isOn={data?.depositLeavingIsFixed}
                    onChange={() => { if (editable) { setData({ ...data, depositLeavingIsFixed: !data?.depositLeavingIsFixed }) } }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className={`col col-double col-switch ${!editable && 'disabled'}`}>
            <span>Предизвестие</span>
            <div className="row row-auto-sign">
              <Button.Switch
                isOn={data?.notice}
                onChange={() => {
                  if (editable) {
                    setData({ ...data, notice: !data?.notice, noticeDays: null })
                    setTimeout(() => { document.getElementsByClassName('focusNotice')[0]?.focus() }, 0)

                  }
                }}
              />
              {data?.notice && (
                <Inputs.TextLabeled
                  label="дни"
                  value={data?.noticeDays || ''}
                  onChange={({ target: { value } }) => {
                    if (!/^\d+$/.test(value) && value !== '') return
                    handleChange('noticeDays', value)
                  }}
                  inputClassName={`focusNotice ${isRequired('noticeDays')}`}
                  disabled={!editable}
                />
              )}
            </div>
          </div>
          {data?.notice && <div className="col">
            <span>Краен срок предизвестие</span>
            <Inputs.DatePicker
              value={data?.noticeDays && data?.deliveryEnd ? moment(data.deliveryEnd).subtract(data.noticeDays, 'days').toDate() : null}
              disabled
            />
          </div>}
        </div>
      </div>}
      {process.env.REACT_APP_PLATFORM === 'Synergon' && <div className="row">
        <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
          <span>Енергия за добро</span>
          <Button.Switch isOn={data?.donation} onChange={() => editable && setData({ ...data, donation: !data?.donation, donationFond: null })} />
        </div>
        {data?.donation && <div className="col">
          <span>Фонд</span>
          <Inputs.Dropdown
            options={[
              { label: 'Готови за успех', value: 'Готови за успех' },
              { label: 'Лечение за деца', value: 'Лечение за деца' },
              { label: 'Нашите родители', value: 'Нашите родители' },
              { label: 'Подкрепа на жени', value: 'Подкрепа на жени' },
            ]}
            onChange={({ value }) => handleChange('donationFond', value)}
            value={data?.donationFond ?? ''}
            className={`${isRequired('donationFond')}`}
            disabled={!editable}
          />
        </div>}
      </div>}
    </div>
  )
}

export default DocumentsAndContract
