import React, { useEffect, forwardRef, useImperativeHandle, useState } from 'react'
import { Alerts } from '@makedonski/socourt-utilities'
import { Input, Button } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { startLoading, stopLoading, checkClientExists, getData } from 'actions'
import { Client, Inputs } from 'components'
import './styles.scss'

const AnnexMain = forwardRef(({
  isClientProfile,
  isApplied,
  editable,
  data,
  handleChange: handleChangeProp,
  client,
  setClient,
  showRequired
}, ref) => {
  const dispatch = useDispatch()
  const { products } = useSelector(({ data }) => data)
  useEffect(() => { !products && dispatch(getData('products')) }, [])

  const startClientFetch = (payload) => {
    dispatch(startLoading())
    dispatch(
      checkClientExists({
        exact: true,
        getAllDetails: true,
        data: payload || client?.eic,
        onSuccess: (clients) => {
          dispatch(stopLoading())
          if (isEmpty(clients)) Alerts.error(`Няма намерен клиент.`)
          else if (clients[0].clientType === 'Производител') Alerts.error(`Клиентът е производител`)
          else {
            setClient(clients?.at(0))
            handleChange({ client: clients?.at(0)?._id })
          }
        },
      })
    )
  }

  const [edits, setEdits] = useState()
  const handleEdits = (payload) => setEdits({ ...edits, ...payload })
  const handleChange = (payload) => isClientProfile ? handleEdits(payload) : handleChangeProp(payload)

  const onClear = () => setEdits()

  useImperativeHandle(ref, () => ({ edits, onClear, startClientFetch }), [edits, onClear, startClientFetch,])

  return (
    <div className="client-forms-annex-main-container ">
      {!isClientProfile && (
        <>
          <h2>Данни на клиента</h2>
          <div className="row">
            <div className="col">
              <span>ЕИК/ЕГН</span>
              <Input.Text
                value={client?.eic || client?.pin || ''}
                onChange={({ target: { value } }) => {
                  if (!/^\d+$/.test(value) && value !== '') return
                  setClient({ ...client, eic: value })
                }}
                onKeyDown={({ key }) => key === 'Enter' && startClientFetch()}
                disabled={data?.client}
              />
            </div>
            {data?.client ? (
              <div className="col">
                <span>Име на клиент</span>
                <Input.Text value={client?.fullName || ''} disabled />
              </div>
            ) : (
              <div className="col">
                <span></span>
                <Button.Raised text="Извлечи данни" disabled={!client?.eic} onClick={() => startClientFetch()} />
              </div>
            )}
          </div>
        </>
      )}
      {data?.client && (
        <>
          <h2>Продукт и цени</h2>
          <div className="row">
            <div className="col">
              <span>Продукт</span>
              <Inputs.Dropdown
                options={products?.filter(({ forProducer }) => !forProducer)?.map(({ _id, name }) => ({ label: name, value: _id }))}
                onChange={({ value }) => {
                  const product = products?.find(({ _id }) => _id === value)?.name
                  handleChange({ product: value, price: ['Борса', 'Отделно балансиране', 'Борса с Под на цената', 'Борса с Таван на цената', 'Борса с Под и Таван на цената'].includes(product) ? { market: 0, marketIsPercent: false } : null })
                }}
                value={isClientProfile ? edits?.product || data?.product?._id || data?.product : data?.product?._id || data?.product || client?.product?._id || client?.product}
                disabled={!editable || isApplied}
              />
            </div>
            <Client.Forms.ProductPrices
              products={products}
              data={isClientProfile
                ? { price: { ...(edits?.price || data?.price) }, product: edits?.product || data?.product || client?.product }
                : { price: { ...client?.price, ...data?.price }, product: data?.product || client?.product }}
              handleChange={(payload) => handleChange({ price: { ...(edits?.price || data?.price), ...payload } })}
              showRequired={showRequired}
              editable={editable && !isApplied}
            />
          </div>
          <h2>Фактуриране</h2>
          <div className="row">
            <Client.Forms.IntermediatePayments
              data={isClientProfile
                ? {
                  intermediateInvoicing: edits?.hasOwnProperty('intermediateInvoicing')
                    ? edits?.intermediateInvoicing || false
                    : data?.invoiceSettings?.intermediateInvoicingType
                      ? data?.invoiceSettings?.intermediateInvoicing || (client?.invoiceSettings?.intermediateInvoicing || false)
                      : data?.invoiceSettings?.intermediateInvoicing,
                  intermediateInvoicingType: edits?.intermediateInvoicingType || data?.invoiceSettings?.intermediateInvoicingType,
                  advanceFirstDate: edits?.advanceFirstDate || data?.invoiceSettings?.advanceFirstDate,
                } : {
                  intermediateInvoicing: data?.intermediateInvoicing ?? (client?.invoiceSettings?.intermediateInvoicing || false),
                  intermediateInvoicingType: data?.intermediateInvoicingType ?? client?.invoiceSettings?.intermediateInvoicingType,
                  advanceFirstDate: data?.advanceFirstDate ?? client?.invoiceSettings?.advanceFirstDate
                }}
              setData={handleChange}
              editable={editable && !isApplied}
            />
          </div>
        </>
      )}
    </div>
  )
})

export default AnnexMain
