export const invoicesTypes = {
  SET_FIELD: 'invoices/SET_FIELD',
  SET_FIELDS: 'invoices/SET_FIELDS',

  GET_INVOICES_FILTERS: 'invoices/GET_INVOICES_FILTERS',
  GET_INVOICES_FILTERS_SUCCESS: 'invoices/GET_INVOICES_FILTERS_SUCCESS',

  GET_INVOICE: 'invoices/GET_INVOICE',
  GET_INVOICES: 'invoices/GET_INVOICES',
  GET_INVOICES_SUCCESS: 'invoices/GET_INVOICES_SUCCESS',
  SEARCH_INVOICES: 'invoices/SEARCH_INVOICES',
  CREATE_INVOICE: 'invoices/CREATE_INVOICE',
  GENERATE_INVOICE: 'invoices/GENERATE_INVOICE',
  UPDATE_INVOICE: 'invoices/UPDATE_INVOICE',
  UPDATE_INVOICE_SIMPLE: 'invoices/UPDATE_INVOICE_SIMPLE',
  UPDATE_INVOICE_MANY: 'invoices/UPDATE_INVOICE_MANY',
  UPDATE_INVOICE_MANY_BONUSES: 'invoices/UPDATE_INVOICE_MANY_BONUSES',
  // SEND_INVOICE: 'invoices/SEND_INVOICE',

  GET_INVOICES_CLIENT: 'invoices/GET_INVOICES_CLIENT',
  GET_INVOICES_CLIENT_SUCCESS: 'invoices/GET_INVOICES_CLIENT_SUCCESS',

  UPDATE_INVOICE_PROGRESS: 'invoices/UPDATE_INVOICE_PROGRESS',

  EXPORT_INVOICES_GENERATED_STATS: 'invoices/EXPORT_INVOICES_GENERATED_STATS',
  EXPORT_INVOICES_GENERATED_INFO: 'invoices/EXPORT_INVOICES_GENERATED_INFO',
  EXPORT_INVOICES_AI_GENERATED_STATS: 'invoices/EXPORT_INVOICES_AI_GENERATED_STATS',

  IGNORE_OBJECT_FOR_MONTH: 'invoices/IGNORE_OBJECT_FOR_MONTH'
}

export const setInvoicesField = (payload) => ({
  type: invoicesTypes.SET_FIELD,
  payload,
})

export const setInvoicesFields = (payload) => ({
  type: invoicesTypes.SET_FIELDS,
  payload,
})

export const getInvoice = (payload) => ({
  type: invoicesTypes.GET_INVOICE,
  ...payload,
})

export const getInvoicesFilters = (payload) => ({
  type: invoicesTypes.GET_INVOICES_FILTERS,
  ...payload,
})


export const getInvoices = (payload) => ({
  type: invoicesTypes.GET_INVOICES,
  payload,
})

export const createInvoice = (payload) => ({
  type: invoicesTypes.CREATE_INVOICE,
  ...payload,
})

export const generateInvoice = (payload) => ({
  type: invoicesTypes.GENERATE_INVOICE,
  ...payload,
})

export const updateInvoice = (payload) => ({
  type: invoicesTypes.UPDATE_INVOICE,
  ...payload,
})

export const updateInvoiceSimple = (payload) => ({
  type: invoicesTypes.UPDATE_INVOICE_SIMPLE,
  ...payload,
})

export const updateInvoiceMany = (payload) => ({
  type: invoicesTypes.UPDATE_INVOICE_MANY,
  ...payload,
})

export const updateInvoiceManyBonuses = (payload) => ({
  type: invoicesTypes.UPDATE_INVOICE_MANY_BONUSES,
  ...payload,
})

// export const sendInvoice = (payload) => ({
//   type: invoicesTypes.SEND_INVOICE,
//   ...payload,
// })

export const getInvoicesClient = (payload) => ({
  type: invoicesTypes.GET_INVOICES_CLIENT,
  payload,
})

export const updateInvoiceProgress = (payload) => ({
  type: invoicesTypes.UPDATE_INVOICE_PROGRESS,
  ...payload,
})

export const exportInvoicesGeneratedStats = (payload) => ({
  type: invoicesTypes.EXPORT_INVOICES_GENERATED_STATS,
  ...payload,
})

export const exportInvoicesGeneratedInfo = (payload) => ({
  type: invoicesTypes.EXPORT_INVOICES_GENERATED_INFO,
  ...payload,
})

export const exportInvoicesAIGeneratedStats = (payload) => ({
  type: invoicesTypes.EXPORT_INVOICES_AI_GENERATED_STATS,
  ...payload,
})

export const ignoreObjectForMonth = (payload) => ({
  type: invoicesTypes.IGNORE_OBJECT_FOR_MONTH,
  ...payload,
})
