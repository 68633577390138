import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import moment from 'moment'
import { isEmpty, omit } from 'lodash'
import { useDispatch } from 'react-redux'
import { updateAnnex, startLoading, stopLoading } from 'actions'
import { Inputs } from 'components'
import { formatAnnexResponse, transformURL, uploadFile } from 'utilities'
import './styles.scss'
import { Portal } from 'react-overlays'

const ProducersAnnexContract = forwardRef(({
  isClientProfile,
  isApplied,
  editable,
  setEditable,
  setSelectedObject,
  data,
  setData: setDataProp,
  showRequired,
  handleChange: handleChangeProp,
  client,
}, ref) => {
  const { producerSettings } = client || {}

  const [editContractDuration, setEditContractDuration] = useState()
  useEffect(() => {
    if (!isClientProfile) return
    setEditContractDuration(Object.keys(data || {}).some((key) => ['deliveryEnd'].includes(key)))
  }, [data])

  const [annexDuration, setAnnexDuration] = useState()
  useEffect(() => {
    if (!isClientProfile) return
    setAnnexDuration(Object.keys(data || {}).some((key) => ['deactivationDate'].includes(key)))
  }, [data])

  const dispatch = useDispatch()
  const handleUpload = (files) => {
    dispatch(startLoading())
    uploadFile({ file: files[0], createId: true }).then(({ location }) => {
      dispatch(stopLoading())
      const payload = { file: { url: location, name: files[0].name } }
      if (isClientProfile) setEdits({ ...edits, ...payload })
      else handleChange(payload)
    })
  }

  const [edits, setEdits] = useState()
  const handleEdits = (payload) => setEdits({ ...edits, ...payload })
  const handleChange = (payload) => isClientProfile ? handleEdits(payload) : handleChangeProp(payload)
  const setData = (payload) => isClientProfile ? setEdits(payload) : setDataProp(payload)
  const onClear = (mainForm) => {
    setEditable(false)
    setEdits()
    mainForm?.onClear()
  }
  const onSave = (mainForm) => {
    if (edits?.file && isEmpty(edits?.file)) {
      Alerts.error('Моля добавете файл, за да запазите промените!')
      return
    } else {
      dispatch(startLoading())
      dispatch(updateAnnex({
        payload: { _id: data?._id, client: data?.client, annexForProducer: true, ...edits, ...mainForm?.edits },
        onSuccess: (annex) => {
          setSelectedObject(formatAnnexResponse(annex))
          onClear(mainForm)
        },
      }))
    }
  }

  useImperativeHandle(ref, () => ({ onClear, onSave }), [onClear, onSave])

  return <div className="client-forms-producers-annex-contract-container">
    <h2>Данни за договор</h2>
    <div className="row">
      {!isClientProfile && <div className="col">
        <span>Край настоящ договор</span>
        <Inputs.DatePicker value={!isClientProfile && producerSettings?.deliveryEnd} disabled />
      </div>}
      <div className="col">
        <span>Дата на договор</span>
        <Inputs.DatePicker
          value={isClientProfile ? edits?.contractDate || data?.contractDate : data?.contractDate || producerSettings?.contractDate}
          onChange={(value) => handleChange({ contractDate: value })}
          disabled={isApplied || !editable}
          popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
        />
      </div>
      <div className="col">
        <span>№ на договор</span>
        <Input.Text
          value={isClientProfile ? edits?.contractNumber || data?.contractNumber || "" : data?.contractNumber || producerSettings?.contractNumber || ''}
          onChange={({ target: { value } }) => handleChange({ contractNumber: value })}
          disabled={isApplied || !editable}
        />
      </div>

      <div className="col">
        <span>Анекс влиза в сила от</span>
        <Inputs.DatePicker
          // minDate={new Date()}
          value={edits?.activationDate || data?.activationDate}
          onChange={(value) => handleChange({ activationDate: moment(value).startOf('month').toDate() })}
          disabled={isApplied || !editable}
          className={`month-picker ${showRequired && !data.activationDate && 'required'}`}
          showMonthYearPicker
          showFullMonthYearPicker
          popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
        />
      </div>
    </div>
    <div className="row">
      <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
        <span>Редакция срок</span>
        <Button.Switch
          isOn={editContractDuration}
          onChange={() => {
            if (isApplied || !editable) return
            setEditContractDuration(!editContractDuration)
            if (editContractDuration) setData(omit(isClientProfile ? edits : data, ['deliveryEnd']))
          }}
        />
      </div>
      {editContractDuration && (
        <div className="col">
          <span>Нов край на договора</span>
          <Inputs.DatePicker
            disabled={isApplied || !editable}
            value={edits?.deliveryEnd || data?.deliveryEnd || producerSettings?.deliveryEnd}
            onChange={(value) => handleChange({ deliveryEnd: moment(value).endOf('month').toDate() })}
            className={`month-picker`}
            showMonthYearPicker
            showFullMonthYearPicker
            minDate={data?.activationDate ? moment(data.activationDate).toDate() : null}
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
      )}
      <div className={`col ${(isClientProfile) && 'disabled'}`}>
        <span>Срочност</span>
        <Button.Switch
          isOn={annexDuration}
          onChange={() => {
            if (isClientProfile) return
            setAnnexDuration(!annexDuration)
            if (annexDuration) setData(omit(isClientProfile ? edits : data, ['deactivationDate']))
          }}
        />
      </div>
      {annexDuration && (
        <div className="col">
          <span>Анекс излиза от сила от</span>
          <Inputs.DatePicker
            value={edits?.deactivationDate || data?.deactivationDate}
            onChange={(value) => handleChange({ deactivationDate: moment(value).startOf('month').toDate() })}
            disabled={isApplied || !editable}
            className={`month-picker ${showRequired && annexDuration && !data.deactivationDate && 'required'}`}
            showMonthYearPicker
            showFullMonthYearPicker
            minDate={data?.activationDate ? moment(data.activationDate).add(1, 'months').toDate() : null}
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
      )}
    </div>
    <div className="row">
      <div className="col">
        <span>Падеж производство</span>
        <Input.Text
          label="число"
          disabled={isApplied || !editable}
          value={isClientProfile ? edits?.paymentDays || data?.paymentDays || "" : data?.paymentDays ?? producerSettings?.paymentDays ?? ''}
          onChange={({ target: { value } }) => {
            if (!/^\d+$/.test(value) && value !== '') return
            handleChange({ paymentDays: value })
          }}
        />
      </div>
      <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
        <span>Общи условия {isClientProfile && data.terms === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
        <Button.Switch
          isOn={isClientProfile ? edits?.terms ?? data?.terms : data?.terms ?? producerSettings?.terms}
          onChange={() => {
            if (isApplied || !editable) return
            if (isClientProfile) handleChange({ terms: edits?.terms ? !edits?.terms : !data?.terms })
            else {
              if (![undefined, null].includes(data?.terms) && !data?.terms === producerSettings?.terms) setData(omit(data, ['terms']))
              else handleChange({ terms: !(data?.terms ?? producerSettings?.terms) })
            }
          }}
        />
      </div>
      <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
        <span>Предизвестие {isClientProfile && data.notice === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
        <div className="row row-auto-sign">
          <Button.Switch
            isOn={isClientProfile ? edits?.notice ?? data?.notice : data?.notice ?? producerSettings?.notice}
            onChange={() => {
              if (isApplied || !editable) return
              if (isClientProfile) handleChange({ notice: edits?.notice ? !edits?.notice : !data?.notice })
              else {
                if (![undefined, null].includes(data?.notice) && !data?.notice === producerSettings?.notice) setData(omit(data, ['notice']))
                else handleChange({ notice: !(data?.notice ?? producerSettings?.notice) })
              }
            }}
          />
          {(isClientProfile ? ![undefined, null].includes(edits?.notice) ? edits?.notice : data?.notice || data?.noticeMonths : data?.notice ?? producerSettings?.notice) && (
            <Inputs.TextLabeled
              disabled={isApplied || !editable}
              label="мес."
              value={edits?.noticeMonths ?? data?.noticeMonths ?? producerSettings?.noticeMonths ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{0,2}$/.test(value) && value !== '') return
                handleChange({ noticeMonths: value })
              }}
            />
          )}
        </div>
      </div>
      <div className={`col ${(isApplied || !editable) && 'disabled'}`}>
        <span>Автоматично преподписване {isClientProfile && data.autoSign === false && <span style={{ fontWeight: 700 }}>- Изключено</span>}</span>
        <div className="row row-auto-sign">
          <Button.Switch
            isOn={isClientProfile ? ![undefined, null].includes(edits?.autoSign) ? edits?.autoSign : data?.autoSign || data?.autoSignMonths : data?.autoSign ?? producerSettings?.autoSign}
            onChange={() => {
              if (isApplied || !editable) return
              if (isClientProfile) setData({ autoSignMonths: null, autoSign: ![undefined, null].includes(edits?.autoSign) ? !edits?.autoSign : !data?.autoSign })
              else {
                if (![undefined, null].includes(data?.autoSign) && !data?.autoSign === producerSettings?.autoSign) setData(omit(data, ['autoSign', 'autoSignMonths']))
                else setData({ ...omit(data, ['autoSignMonths']), autoSign: !(data?.autoSign ?? producerSettings?.autoSign), })
              }
            }}
          />
          {(isClientProfile ? ![undefined, null].includes(edits?.autoSign) ? edits?.autoSign : data?.autoSign || data?.autoSignMonths : data?.autoSign ?? producerSettings?.autoSign) && (
            <Inputs.TextLabeled
              disabled={isApplied || !editable}
              label="мес."
              value={edits?.autoSignMonths ?? data?.autoSignMonths ?? producerSettings?.autoSignMonths ?? ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{0,2}$/.test(value) && value !== '') return
                handleChange({ autoSignMonths: value })
              }}
            />
          )}
        </div>
      </div>
    </div>
    <div className="row">
      <div className={`col ${isClientProfile && !editable && 'disabled'}`}>
        <span>Анекс</span>
        {(!edits?.file && data?.file) || edits?.file?.name ? (
          <div className="row row-file">
            {(!isClientProfile || editable) && (
              <Button.Icon
                name="plus"
                color="red"
                onClick={() => {
                  if (isClientProfile) setEdits({ ...edits, file: {} })
                  else setData(omit(data, ['file']))
                }}
              />
            )}
            <a href={transformURL(edits?.file?.url || data?.file?.url || '')} target="_blank" rel="noopener noreferrer">
              <span>{edits?.file?.name || data?.file?.name}</span>
            </a>
          </div>
        ) : (

          <Button.UploadButton
            disabled={isClientProfile && !editable}
            text={
              <Input.DragAndDrop handleDrop={handleUpload}>
                <span className="row ripple">
                  Добави файл <div className="icon icon-attach" />
                </span>
              </Input.DragAndDrop>
            }
            accept="*"
            onChange={({ target: { files } }) => handleUpload(files)}
          />
        )}
      </div>
      <div className="col col-double">
        <span>Коментар към анекс</span>
        <Input.Text
          disabled={isClientProfile && !editable}
          value={edits?.comment ?? data?.comment?.text ?? data?.comment ?? ''}
          onChange={({ target: { value } }) => handleChange({ comment: value })}
        />
      </div>
    </div>
  </div>
})

export default ProducersAnnexContract