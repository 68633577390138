export const compensationSettingsTypes = {
    GET_COMPENSATION_SETTINGS: "compensationSettings/GET_COMPENSATION_SETTINGS",
    CREATE_COMPENSATION_SETTINGS: "compensationSettings/CREATE_COMPENSATION_SETTINGS",
    UPDATE_COMPENSATION_SETTINGS: "compensationSettings/UPDATE_COMPENSATION_SETTINGS",
    DELETE_COMPENSATION_SETTINGS: "compensationSettings/DELETE_COMPENSATION_SETTINGS",
}

export const getCompensationSettings = (payload) => ({
    type: compensationSettingsTypes.GET_COMPENSATION_SETTINGS,
    ...payload
})

export const createCompensationSettings = (payload) => ({
    type: compensationSettingsTypes.CREATE_COMPENSATION_SETTINGS,
    ...payload
})

export const updateCompensationSettings = (payload) => ({
    type: compensationSettingsTypes.UPDATE_COMPENSATION_SETTINGS,
    ...payload
})

export const deleteCompensationSettings = (payload) => ({
    type: compensationSettingsTypes.DELETE_COMPENSATION_SETTINGS,
    ...payload
})