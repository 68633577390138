// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  width: 70%;
  position: absolute;
  inset: 30px 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,gBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;EACA,8BAAA;EACA,cAAA;EACA,kBAAA;EACA,aAAA;EACA,aAAA;AACF","sourcesContent":[".modal-container {\r\n  width: 70%;\r\n  position: absolute;\r\n  inset: 30px 50px;\r\n  left: 50%;\r\n  top: 50%;\r\n  transform: translate(-50%, -50%);\r\n  border: none;\r\n  background: rgb(255, 255, 255);\r\n  overflow: auto;\r\n  border-radius: 4px;\r\n  outline: none;\r\n  padding: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
