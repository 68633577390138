// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-forms-product-prices.col-switch.disabled .particles-switch-button {
  cursor: default;
}
.client-forms-product-prices .particles-switch-button {
  height: 38px;
  margin: 10px 0;
  justify-content: flex-start;
}
.client-forms-product-prices .particles-switch-button .switch-label {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Forms/ProductPrices/styles.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AAEI;EACI,YAAA;EACA,cAAA;EACA,2BAAA;AAAR;AACQ;EACI,aAAA;AACZ","sourcesContent":[".client-forms-product-prices {\r\n    &.col-switch.disabled .particles-switch-button {\r\n        cursor: default;\r\n    }\r\n    .particles-switch-button {\r\n        height: 38px;\r\n        margin: 10px 0;\r\n        justify-content: flex-start;\r\n        .switch-label {\r\n            display: none;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
