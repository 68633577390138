export const invoiceGroupsTypes = {
  GET_INVOICE_GROUPS_BY_CLIENT: 'invoiceGroups/GET_INVOICE_GROUPS_BY_CLIENT',
  GET_INVOICE_GROUPS_BY_CLIENT_SUCCESS: 'invoiceGroups/GET_INVOICE_GROUPS_BY_CLIENT_SUCCESS',
  CREATE_INVOICE_GROUP: 'invoiceGroups/CREATE_INVOICE_GROUP',
  UPDATE_INVOICE_GROUP: 'invoiceGroups/UPDATE_INVOICE_GROUP',
  DELETE_INVOICE_GROUP: 'invoiceGroups/DELETE_INVOICE_GROUP',
}

export const getInvoiceGroupByClient = (payload) => ({
  type: invoiceGroupsTypes.GET_INVOICE_GROUPS_BY_CLIENT,
  ...payload,
})

export const createInvoiceGroup = (payload) => ({
  type: invoiceGroupsTypes.CREATE_INVOICE_GROUP,
  ...payload,
})

export const updateInvoiceGroup = (payload) => ({
  type: invoiceGroupsTypes.UPDATE_INVOICE_GROUP,
  ...payload,
})

export const deleteInvoiceGroup = (payload) => ({
  type: invoiceGroupsTypes.DELETE_INVOICE_GROUP,
  ...payload,
})
