import React, { useState, useEffect } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import './styles.scss'
import { emailRegex } from 'config/constants'

const AdditionalContact = ({ clientType, data, handleChange, handleChangeContact, handleRemoveContact, showRequired, editable, children }) => {
  const [showAdditionalContact, setShowAdditionalContact] = useState(data || false)
  const isRequired = (fields, i) => showRequired && fields.every(f => !data?.at(i)?.[f]) && 'required'
  const isEmail = (email) => showRequired && !emailRegex.test(email) && 'required'

  useEffect(() => {
    setShowAdditionalContact(data?.length || false)
  }, [data])

  return (
    <div className="client-forms-additional-contact-container">
      <div className="row row-header">
        <h2>Допълнителен контакт</h2>
        <Button.Switch
          isOn={showAdditionalContact}
          onChange={() => {
            if (!editable) return
            setShowAdditionalContact(!showAdditionalContact)
            if (showAdditionalContact) handleChange('contacts', [])
            else handleChange('contacts', [{}])
          }}
        />
        {showAdditionalContact && editable && <div className="row row-add">Добави <div className="icon icon-add" onClick={() => handleChange('contacts', [...(data || []), []])} /></div>}
      </div>
      {showAdditionalContact && data?.map((contact, i) =>
        <div className="row" key={`contact-${i}`}>
          <div className="col">
            <span>Име и Фамилия</span>
            <Input.Text
              value={contact?.fullName || ''}
              onChange={({ target: { value } }) => handleChangeContact('fullName', value, i)}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Пояснение</span>
            <Input.Text
              value={contact?.description || ''}
              onChange={({ target: { value } }) => handleChangeContact('description', value, i)}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Телефон</span>
            <Input.Text
              value={contact?.phoneNumber || ''}
              onChange={({ target: { value } }) => {
                if (!/^\+?\d*$/.test(value) && value !== '') return
                handleChangeContact('phoneNumber', value, i)
              }}
              inputClassName={`${isRequired(["phoneNumber", 'email'])}`}
              disabled={!editable}
            />
          </div>
          <div className="col">
            <span>Имейл</span>
            <Input.Text
              value={contact?.email || ''}
              onChange={({ target: { value } }) => {
                if (/[\u0400-\u04FF]+/.test(value) && value !== '') return
                handleChangeContact('email', value.trim(), i)
              }}
              inputClassName={`${isRequired(["phoneNumber", 'email']) || (contact?.email && isEmail(contact?.email))}`}
              disabled={!editable}
            />
          </div>
          <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
            <span>Кореспонденция</span>
            <Button.Switch
              isOn={contact?.communication}
              onChange={() => editable && handleChangeContact('communication', !contact?.communication, i)}
            />
          </div>
          <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
            <span>Фактура</span>
            <Button.Switch
              isOn={contact?.invoices}
              onChange={() => editable && handleChangeContact('invoices', !contact?.invoices, i)}
            />
          </div>
          {clientType !== 'Консуматор' && <div className={`col col-small col-switch ${!editable && 'disabled'}`}>
            <span>Справки</span>
            <Button.Switch
              isOn={contact?.stats}
              onChange={() => editable && handleChangeContact('stats', !contact?.stats, i)}
            />
          </div>}
          {editable && <Button.Icon name="plus" color='red' onClick={() => handleRemoveContact(i)} />}
        </div>

      )}
      <div className="row row-footer">{children}</div>
    </div>
  )
}

export default AdditionalContact
