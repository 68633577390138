import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { compensationSettingsTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'

export const getCompensationSettings = (action$) => action$.pipe(
    ofType(compensationSettingsTypes.GET_COMPENSATION_SETTINGS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/compensationSettings/list`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.compensationSettings)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)
export const createCompensationSettings = (action$) => action$.pipe(
    ofType(compensationSettingsTypes.CREATE_COMPENSATION_SETTINGS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/compensationSettings`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.compensationSetting)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                let message = err?.response?.error?.message
                if (message.startsWith('E11000 duplicate key error collection')) message = 'Вече има добавена компенсация за избрания месец!'
                Alerts.error('Error!', message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const updateCompensationSettings = (action$) => action$.pipe(
    ofType(compensationSettingsTypes.UPDATE_COMPENSATION_SETTINGS),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/compensationSettings`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.compensationSetting)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                let message = err?.response?.error?.message
                if (message.startsWith('Plan executor error during findAndModify :: caused by :: E11000 duplicate key error collection')) message = 'Вече има добавена компенсация за избрания месец!'
                Alerts.error('Error!', message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const deleteCompensationSettings = (action$) => action$.pipe(
    ofType(compensationSettingsTypes.DELETE_COMPENSATION_SETTINGS),
    switchMap(({ payload, onSuccess }) =>
        Api.delete(`${URL}/compensationSettings`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.compensationSetting)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)
