import { Shared } from 'components'
import React from 'react'
import './styles.scss'

const fields = [
  { label: 'Общо', value: "total", size: 100 },
  { label: 'Средно', value: "avg", size: 100 },
  { label: 'Мин', value: "min", size: 100 },
  { label: 'Макс', value: "max", size: 100 },
  { label: 'База', value: "base", size: 100 },
  { label: 'Пик', value: "peak", size: 100 },
  { label: 'Офпик', value: "offPeak", size: 100 },
  { label: 'Цена профил/борса', value: "weightedAveragePrice", size: 150 },
  { label: 'Цена база/период', value: "averagePrice", size: 150 },
  { label: 'Разлика', value: "marketDifference", size: 110 },
  { label: 'Разлика %', value: "marketDifferencePercent", size: 110 },
]

const ConsumptionSummary = ({ totalStats = {} }) => {
  return (
    <div className="card-consumption-summary-container">
      <h2>Общо потребление за периода</h2>
      <Shared.Table
        fixedWidth
        columns={fields}
        data={[totalStats]}
        rowHeight={65}
        renderCell={(row, field) => {
          if (typeof row?.[field] === 'string') return row?.[field]
          switch (field) {
            case 'base':
            case 'peak':
            case 'offPeak':
              return <div className='col'>
                <span>{typeof row?.[`${field}Percent`] === 'string' ? row?.[`${field}Percent`] : (row?.[`${field}Percent`] * 100)?.toFixed(2)} %</span>
                <span>{row?.[field]?.toFixed(2)}</span>
              </div>
            case 'marketDifferencePercent':
              return (row?.[field] * 100)?.toFixed(2) + " %"
            case 'marketDifference':
            case 'averagePrice':
            case 'weightedAveragePrice':
              return row?.[field]?.toFixed(2) + " лв/МВтч"
            default:
              return row?.[field]?.toFixed(2)
          }
        }}
      />
    </div>
  )
}

export default ConsumptionSummary
