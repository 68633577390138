import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import './styles.scss'

const FilterTasksList = ({ hide }) => {
  return (
    <div className="popup-filter-tasks-list-container">
      <div className="row">
        <h4>Функции</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <p>Скрий катеогрия</p>
      <p>Редактирай заглавие</p>
      <p>Премести 1 позиция наляво</p>
      <p>Премести 1 позиция надясно</p>
      <p>Изтрий категория</p>

      <h4 className="add-margin">Пореди задачи по</h4>
      <Inputs.RadioButtons
        buttons={['Възходящи', 'Низходящи']}
        value="Низходящи"
        col
      />
    </div>
  )
}

export default FilterTasksList
