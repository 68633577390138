import React, { useState } from "react"
import { Button } from '@makedonski/admin-ui'
import "./styles.scss"
import { Inputs } from "components"

const InputDropdown = ({ close, options = [], title = "", text = "", defaultValue = "", onSuccess = () => { } }) => {
    const [state, setState] = useState(defaultValue)
    return <div className="modal-input-dropdown-container">
        <div className="modal-input-dropdown-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-input-dropdown-content">
            {text && <p style={{ textAlign: 'center' }}>{text}</p>}
            <Inputs.Dropdown options={options} value={state} onChange={({ value }) => setState(value)} />
        </div>
        <div className="modal-input-dropdown-footer row">
            <Button.Raised onClick={() => onSuccess(state)} text="Продължи" disabled={!state} />
        </div>
    </div>
}

export default InputDropdown