import Shared from "components/Shared"
import { modalEmailStatsFields, modalEmailStatsViewsFields } from "config/constants"
import { URL } from "config/settings"
import { Alerts, Headers } from "@makedonski/socourt-utilities"
import moment from "moment"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { renderCell } from "utilities"
import "./styles.scss"

const EmailStats = ({ close, data }) => {
    const tableRef = useRef(null)
    const [docs, setDocs] = useState([])

    const fetchData = useCallback(async ({ identifier } = {}) => {
        try {
            const request = await fetch(`${URL}/cloudServices/emailStats`, {
                method: 'POST',
                headers: Headers.getWithAuth(),
                body: JSON.stringify({ identifier })
            })
            if (!request.ok) throw new Error(`HTTP error! status: ${request.status}`)
            const { data } = await request.json()
            setDocs((docs) => [...docs, ...data?.map((o) => ({ ...o, objects: o._views }))])
        } catch (error) {
            Alerts.error('Error!', error?.message || '')
        }
    }, [])

    useEffect(() => { data.forEach(fetchData) }, [data, fetchData])

    const ViewsTable = useCallback(({ row }) => <Shared.Table
        columns={modalEmailStatsViewsFields}
        data={row?.original?._views}
        renderCell={(row, field) => field === 'date' ? moment(row?.[field]).format('DD.MM.YYYY | HH:mm') : row?.[field]}
        containerStyle={{ maxHeight: 350 }}
        fixedWidth
    />, [])

    return <div className="modal-email-stats-container">
        <div className="modal-email-stats-header row">
            <h2>Статистика имейл</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-email-stats-content">
            <Shared.Table
                ref={tableRef}
                fixedWidth
                columns={modalEmailStatsFields}
                data={docs}
                renderCell={(row, field) => renderCell.modalEmailStats(row, field, { toggleRowExpanded: tableRef.current.toggleRowExpanded })}
                maxExpandHeight={550}
                expandField='_views'
            >{ViewsTable}</Shared.Table>
        </div>
    </div >
}

export default EmailStats