import React, { useEffect } from "react"
import { Inputs } from 'components'
import "./styles.scss"
import { mDivide, mMultiply, mSubtract } from "utilities"

const Leaving = ({ invoice, handleChange, showRequired }) => {
  const { results } = invoice || {}
  const { invoiceLastMonthTotal, leavingRatio } = results || {}

  useEffect(() => {
    const invoiceLastMonthDeposit = mMultiply(invoiceLastMonthTotal, leavingRatio)
    const totalWithoutVat = mDivide(invoiceLastMonthDeposit, 1.2)
    const vat = mSubtract(invoiceLastMonthDeposit, totalWithoutVat)
    handleChange({
      results: {
        ...results,
        invoiceLastMonthDeposit,
        total: invoiceLastMonthDeposit,
        totalWithoutVat
      },
      pricing: { base: totalWithoutVat, vat, withVat: invoiceLastMonthDeposit }
    })
  }, [leavingRatio])

  return <div className="invoices-forms-leaving-container">
    <div className="invoices-forms-leaving-header row">
      <h2>Депозит при напускане</h2>
    </div>
    <div className="invoices-forms-leaving-result">
      <div className="invoices-forms-leaving-result-header row">
        {["Последно потребление", "Стойност последна ф-ра", "Депозит процент", "Депозит стойност",].map(label => (
          <span key={`results-label-${label}`}>{label}</span>
        ))}
      </div>
      <div className="invoices-forms-leaving-result-content row">
        {["invoiceLastMonthConsumption", "invoiceLastMonthTotal", "leavingRatio", "invoiceLastMonthDeposit",].map(value => (
          <div key={`result-col-${value}`} className={`col ${value}`}>
            <Inputs.TextLabeled
              label={['invoiceLastMonthConsumption'].includes(value) ? 'МВтч' : ['leavingRatio'].includes(value) ? '%' : 'лв.'}
              value={['leavingRatio'].includes(value) ? mMultiply(results?.[value], 100) : results?.[value] ?? ''}
              onChange={({ target }) => handleChange({ results: { ...results, [value]: ['leavingRatio'].includes(value) ? mDivide(target.value, 100) : target.value } })}
              inputClassName={showRequired && (!results?.[value] || isNaN(Number(results?.[value]))) && 'required'}
              disabled={!['leavingRatio'].includes(value)}
            />
          </div>
        ))}
      </div>

    </div>
  </div>
}

export default Leaving