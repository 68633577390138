import React from 'react'
import { useDispatch } from 'react-redux'
import { setOverlay } from '../../../../actions'
import './styles.scss'
import { isArray } from 'lodash'

const Groups = ({ field, value }) => {
  const dispatch = useDispatch()
  if (isArray(value)) {
    if (!value.length) return "--"
    return <div className="row" style={{ width: '100%', paddingRight: 14 }}>
      {value?.map(({ _id, name }) => <div key={_id} className="row" style={{ marginRight: 14 }}>
        {name}
        <div
          style={{ height: 16, width: 16 }}
          className="icon icon-info-custom"
          onClick={() => dispatch(setOverlay({ isOpen: true, type: 'clientGroups', props: { type: field, id: _id } }))}
        />
      </div>)}
    </div>
  }
  return (
    <div className="client-cells-connected-client-container row">
      {value?.name || '--'}
      {value && <div
        className="icon icon-arrow-right-2"
        onClick={() => dispatch(setOverlay({ isOpen: true, type: 'clientGroups', props: { type: field, id: value?._id } }))}
      />}
    </div>
  )
}

export default Groups
