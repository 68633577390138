import React, { useState } from "react"
import { Input, Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { useDispatch } from "react-redux"
import { isEmpty, pick } from "lodash"
import { createMergedObjects, deleteMergedObjects, getClient, setModal, updateMergedObjects } from 'actions'
import { Inputs } from 'components'
import "./styles.scss"

const editFields = ['_id', 'client', 'name', 'objects']
const MergedObjects = ({ close, group, client }) => {
    const dispatch = useDispatch()

    const [data, setData] = useState(pick({ ...group, objects: group?.objects?.map(({ _id }) => _id) }, editFields))
    const handleChange = (payload) => setData({ ...data, ...payload })

    const isValid = () => {
        if (!data.name) {
            Alerts.error('Липсва име!')
            return false
        }
        if (isEmpty(data.objects)) {
            Alerts.error('Изберете поне един обект!')
            return false
        }
        return true
    }

    const onSuccess = () => dispatch(getClient({ payload: client._id, getAllDetails: true, onSuccess: close }))

    const objectsNotInGroups = client?.objects?.filter(({ mergedObjects }) => !mergedObjects)
    return <div className="modal-merged-objects-container">
        <div className="modal-merged-objects-header row">
            <h2>{group ? 'Редакция' : 'Създаване'} на сдвояване производители</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-merged-objects-content">
            <span>Име на група</span>
            <Input.Text value={data?.name || ''} onChange={({ target: { value } }) => handleChange({ name: value })} />
            <span>Избор на точки</span>
            <Inputs.Checkboxes
                col
                buttons={[...(group?.objects || []), ...(objectsNotInGroups || [])]?.filter(({ isProducer }) => isProducer)?.map(({ _id, itn, mergedObjects }) => ({
                    label: `Обект: ${itn}${mergedObjects ? ` - ${mergedObjects.name}` : ''}`,
                    value: _id,
                    disabled: data?.objects?.length >= 2 && !data.objects.includes(_id)
                }))}
                value={data?.objects}
                onClick={(value) => handleChange({ objects: value })}
            />
        </div>
        <div className="modal-merged-objects-footer">
            <Button.Raised
                text={`${group ? 'Редактирай' : 'Създай'} група`}
                onClick={() => {
                    if (!isValid()) return
                    else if (group) dispatch(updateMergedObjects({ payload: data, onSuccess }))
                    else dispatch(createMergedObjects({ payload: { ...data, client: client._id }, onSuccess }))
                }}
                disabled={(data?.objects?.length ?? 0) < 2}
            />
            {group && (
                <Button.Raised
                    text="Изтрий"
                    className="btn-delete"
                    onClick={() => dispatch(deleteMergedObjects({ payload: { _id: data?._id }, onSuccess }))}
                />
            )}
        </div>
    </div>
}

export default MergedObjects