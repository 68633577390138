import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { omit, pick } from 'lodash'
import { Client } from 'components'
import { getData, getClient, createObject, startLoading, stopLoading, updateObject, removeCloneObject } from 'actions'
import { createObjectsFields, defaultEntryEnd, } from 'config/constants'
import { mapExcelToObjects, displayMassCheckError, isValidSingleObject, checkIsDealer, asyncForEach } from 'utilities'
import moment from 'moment'
import './styles.scss'

const ObjectsCreate = () => {
  const isDealer = checkIsDealer()
  const { id: clientId } = useParams()
  const history = useHistory()
  const defaultData = { entryEnd: defaultEntryEnd, }
  const [objects, setObjects] = useState([defaultData])
  const handleChange = (field, value, i) => {
    const newObjects = [...(objects || [])]
    newObjects.splice(i, 1, { ...objects[i], [field]: value })
    if (field === 'erp' && data?.erps?.find(({ _id }) => _id === value)?.name === 'ЕСО') {
      const measureType = data?.measureTypes?.find(({ name }) => name === 'Високо')?._id
      const voltage = data?.voltages?.find(({ name }) => name === 'Високо напрежение')?._id
      newObjects.splice(i, 1, { ...objects[i], [field]: value, measureType, voltage })
    } else if (field === 'erp' && objects[i].erp === data?.erps?.find(({ name }) => name === 'ЕСО')?._id) {
      newObjects.splice(i, 1, { ...objects[i], [field]: value, measureType: null, voltage: null })
    }
    setObjects(newObjects)
  }

  const [client, setClient] = useState()
  useEffect(() => { dispatch(getClient({ payload: clientId, onSuccess: (res) => setClient(res) })) }, [])

  const { data } = useSelector((state) => state)
  const dispatch = useDispatch()
  useEffect(() => {
    const dropdowns = ['voltages', 'seasonings', 'forecasts', 'measureTypes', 'erps', 'profiles']
    dropdowns.forEach((dropdown) => !data?.[dropdown] && dispatch(getData(dropdown)))
  }, [])
  const [showRequired, setShowRequired] = useState(false)

  const isValid = () => {
    let valid = true
    const validated = objects.map((object) => isValidSingleObject({ object, data, isMassAdd: true }))
    if (validated.includes(false)) valid = false
    const itns = objects.map(({ itn }) => itn)
    if (new Set(itns).size !== itns.length) {
      Alerts.error('Има дублиращи се точки !')
      valid = false
    }
    return valid
  }

  const showITNMeasure = useMemo(() => { return objects.some((object) => ['ЕСО'].includes(data?.erps?.find(({ _id }) => _id === object?.erp)?.name)) }, [objects])
  const showSTPMeasure = useMemo(() => { return objects.some((object) => ['СТП', 'Битово'].includes(data?.measureTypes?.find(({ _id }) => _id === object?.measureType)?.name)) }, [objects])
  const showActiveFrom = useMemo(() => { return objects?.some(({ status, existingObject }) => status === 'active' || existingObject) }, [objects])
  const showEntryEnd = useMemo(() => { return objects?.some(({ status, existingObject }) => status !== 'active' && !existingObject) }, [objects])
  const showConsumerProductionPower = useMemo(() => { return objects?.some(({ consumerOwnPlant }) => consumerOwnPlant) }, [objects])

  const fields = useMemo(() => {
    return createObjectsFields
      .filter(({ value }) => value !== 'STPMeasure' || showSTPMeasure)
      .filter(({ value }) => value !== 'itnMeasure' || showITNMeasure)
      .filter(({ value }) => value !== 'activeFrom' || showActiveFrom)
      .filter(({ value }) => value !== 'entryEnd' || showEntryEnd)
      .filter(({ value }) => value !== 'consumerProductionPower' || showConsumerProductionPower)
      .filter(({ value }) => value !== 'requiresNetworkData' || client?.declaration)
      .filter(({ value }) => process.env.REACT_APP_PLATFORM !== 'Synergon' ? true : !['meterNumber'].includes(value))
      .filter(({ value }) => !isDealer || !["existingObject", "subscriberNumber", "forecast", 'entryEnd'].includes(value))
      .filter(({ value }) => !['isProducer'].includes(value))
  }, [createObjectsFields, showITNMeasure, showSTPMeasure, showActiveFrom, showEntryEnd, showConsumerProductionPower, client, objects, isDealer])

  const scrollRef = useRef()
  const scrollContainerWidth = useMemo(() => fields?.reduce((acc, { size }) => (acc += size), 20), [fields])

  const handleAdd = async () => {
    if (!isValid()) setShowRequired(true)
    else {
      dispatch(startLoading())
      const promises = objects.map(({ error, ...object }) => ({
        client: clientId,
        ...object,
        status: object?.existingObject ? 'active' : object?.status || 'registration',
        isClone: object.status === 'transfer' && object.previousClient && object.previousClient !== object.client && ['active', 'leaving'].includes(object?.previousStatus)
      }))
      await asyncForEach(promises, async (data) => await new Promise((resolve) => {
        if (!data._id) return dispatch(createObject({ data, onSuccess: resolve }))
        else return dispatch(updateObject({
          data,
          onSuccess: (newObject) => {
            if (data.isClone && data.existingObject) dispatch(removeCloneObject({ payload: { _id: newObject._id } }))
            resolve()
          }
        }))
      }))
      dispatch(stopLoading())
      Alerts.success({ title: `Успешно добавихте ${objects.length} обекта` })
      history.push(`/clients/${clientId}`)
    }
  }

  const objectsRef = useRef({})
  const handleMassCheck = async () => {
    setObjects((objects) => objects.map((obj) => omit(obj, ['_id', "previousStatus", "client", "previousClient", "error"])))
    dispatch(startLoading())
    await Promise.all(Object.values(objectsRef?.current || {}).filter(Boolean).map((obj) => new Promise((resolve) => obj?.handleCheckStart({ onSuccess: resolve, forProducers: false }))))
    dispatch(stopLoading())
    let helper = null
    setObjects((objects) => { helper = objects; return objects })
    displayMassCheckError({ objects: helper, setShowRequired })
  }

  return (
    <div className="screen-objects-create-container">
      <div className="screen-objects-create-header row">
        <h2>{client?.fullName}</h2>
        <p>
          ЕИК/ЕГН: <span>{client?.eic || client?.pin}</span>
        </p>
        <p>
          МОЛ: <span>{client?.mol?.map(({ fullName }) => fullName)?.join('; ')}</span>
        </p>
        <div className="row row-buttons">
          {!isDealer && < Button.UploadButton
            text={<div className="icon icon-add"></div>}
            multiple
            accept={'.csv, .xlsx, .xls'}
            onChange={({ target: { files } }) => mapExcelToObjects({
              forProducers: false, files, client, data,
              onSuccess: (parsed) => {
                setObjects(parsed)
                handleMassCheck()
              }
            })}
          />}
          <Button.Raised
            disabled={!objects.map(({ itn }) => itn).length}
            text="Масова проверка"
            className="mass-add"
            onClick={handleMassCheck} />
          <Button.Raised text="Добави обекти" onClick={handleAdd} />
        </div>
      </div>
      <div className="screen-objects-create-content">
        <div className="screen-objects-create-inner-content">
          <div className="row row-header">
            {fields.map(({ label, size }, i) => (
              <span key={`field-${i}`} style={{ minWidth: `${size - (i === 0 ? 0 : 15)}px` }}>
                {label}
              </span>
            ))}
          </div>
          <div
            className={`scroll-container ${showSTPMeasure && 'showPlaceholderSTP'} ${showActiveFrom && 'showPlaceholderActiveFrom'} ${showITNMeasure && 'showPlaceholderITNMeasure'}  ${showEntryEnd && 'showPlaceholderEntryEnd'} ${showConsumerProductionPower && "showPlaceholderConsumerProductionPower"}`}
            style={{ width: scrollContainerWidth }}
          >
            {objects?.map((object, i) => (
              <Client.Forms.Object
                ref={ref => objectsRef.current[i] = ref}
                key={`object-${i}`}
                data={object}
                handleChange={(field, value) => handleChange(field, value, i)}
                showRequired={showRequired}
                editable
                isMulti
                client={client}
                showInvalid={showRequired && !isValidSingleObject({ object, data, skipAlerts: true, isMassAdd: true })}
                handleChangeMultiple={(payload) => setObjects((objects) => {
                  const newObjects = [...(objects || [])]
                  newObjects.splice(i, 1, { ...objects[i], ...payload })
                  return newObjects
                })}
                index={i}
                options={data}
                handleRemove={() => {
                  const newObjects = [...(objects || [])]
                  newObjects.splice(i, 1)
                  setObjects(newObjects)
                }}
                massCheck
              />
            ))}
            <div ref={scrollRef} />
          </div>
        </div>
        <div className="row row-add">
          <div
            className="icon icon-add ripple"
            onClick={() => {
              setObjects((objects) => [...(objects || []), defaultData])
              setTimeout(() => scrollRef?.current?.scrollIntoView({ behavior: 'smooth' }), 100)
            }}
          />
          <span>Нов ред</span>
          {objects.length > 1 && <span className='number-of-rows'>Общо: {objects.length} реда</span>}
        </div>
      </div>
    </div >
  )
}

export default ObjectsCreate
