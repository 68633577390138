import React, { useState } from "react"
import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import { Portal } from "react-overlays"
import moment from "moment"
import "./styles.scss"

const InputDateRange = ({ close, title, onSuccess = () => { } }) => {

  const [state, setState] = useState({ from: moment().startOf('month').toDate(), to: moment().endOf('day').toDate() })


  return <div className="modal-input-date-range-container">
    <div className="modal-input-date-range-header row">
      <h2>{title}</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    <div className="modal-input-date-range-content row">
      <div className="col">
        <span>От:</span>
        <div className="row">
          <Inputs.DatePicker
            value={state?.from}
            onChange={(from) => setState((state) => ({ ...state, from: moment(from).startOf('day').toDate() }))}
            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
          />
          <Inputs.DatePicker
            value={state?.from}
            onChange={(from) => setState((state) => ({ ...state, from }))}
            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}><div className="datepicker-time-popper-container">{children}</div></Portal>}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            className="time-picker"
          />
        </div>
      </div>
      <div className="col">
        <span>До:</span>
        <div className="row">
          <Inputs.DatePicker
            value={state?.to}
            onChange={(to) => setState((state) => ({ ...state, to: moment(to).endOf('day').toDate() }))}
            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
          />
          <Inputs.DatePicker
            value={state?.to}
            onChange={(to) => setState((state) => ({ ...state, to }))}
            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}><div className="datepicker-time-popper-container">{children}</div></Portal>}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="HH:mm"
            className="time-picker"
          />
        </div>
      </div>
    </div>
    <div className="modal-input-date-range-footer row">
      <Button.Raised onClick={() => onSuccess(state)} text="Продължи" />
    </div>
  </div>
}

export default InputDateRange