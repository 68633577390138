import React, { useState, useEffect, useMemo } from "react"
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { isEmpty, pick } from "lodash"
import { startLoading, getIntermediateInvoices, createIntermediateInvoices, setModal } from 'actions'
import { Inputs, Shared } from "components"
import { depositsLeavingCreateFields } from 'config/constants'
import { useQuery } from 'hooks'
import { mDivide, mMultiply, toNumber } from "utilities"
import "./styles.scss"
import moment from "moment"

const DepositsCreate = ({ }) => {
    const history = useHistory()
    const { ids = "", monthYear, showErrors, handleUrlChangeMultiple } = useQuery()
    const [data, setData] = useState([])
    const [selected, setSelected] = useState([])

    const handleChange = (payload, index) => {
        const newData = [...(data || [])]
        newData.splice(index, 1, { ...newData?.at(index), results: { ...newData?.at(index).results, ...payload } })
        setData(newData)
    }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(startLoading())
        dispatch(getIntermediateInvoices({
            payload: { type: 'leaving', monthYear, clientIds: ids.split(",") },
            onSuccess: (response) => setData(response?.map((o, i) => ({
                _id: `row-${i + 1}`,
                ...o,
                results: {
                    ...o.results,
                    total: o.results.invoiceLastMonthDeposit,
                    totalWithoutVat: mDivide(o.results.invoiceLastMonthDeposit, 1.2)
                }
            })))
        }))
    }, [monthYear])

    const errors = useMemo(() => data?.filter(({ error }) => error) || [], [data])

    const handleConfirm = () => {
        const payload = {
            ...pick(data?.at(0), ['type', 'monthYear', 'consecutiveNumber', 'invoicePeriodStart', 'invoicePeriodEnd']),
            clients: data
                .filter(({ _id }) => selected.includes(_id))
                .filter(({ results }) => (results.createDepositLeaving && (!!results.leavingRatio || !!results.leavingFixed) && !!results.invoiceLastMonthDeposit) || (results.createPenalty && (!!results.penaltyRatio || !!results.penaltyFixed) && !!results.penaltyValue))
                .map((row) => ({
                    ...pick(row, ['client', 'results', 'paymentDays', 'additionalCharges']),
                    defaultInvoiceGroup: row?.defaultInvoiceGroup || 'none',
                    ...(row.results.createPenalty && { penaltyData: row.penaltyData })
                }))
        }
        dispatch(startLoading())
        dispatch(createIntermediateInvoices({
            payload, onSuccess: ({ invoices, penalties }) => dispatch(setModal({
                isOpen: true, type: 'confirmation', props: {
                    title: `Генерирани ${invoices?.length} депозити | ${penalties?.length} неустойки`,
                    buttons: (
                        <div className="row" style={{ justifyContent: 'space-around' }}>
                            <Button.Raised
                                text='Към "Депозити"'
                                className="no-flex"
                                onClick={() => {
                                    history.push(`/financial/deposits?type=leaving&month=${monthYear.replace('/', '.')}`)
                                    dispatch(setModal({ isOpen: false }))
                                }}
                            />
                            <Button.Raised
                                text='Към "Неустойки"'
                                className="no-flex"
                                onClick={() => {
                                    history.push(`/financial/penalties?monthYear=${monthYear}`)
                                    dispatch(setModal({ isOpen: false }))
                                }}
                            />
                        </div>
                    ),
                    onRequestClose: () => history.push(`/financial/deposits?type=leaving&month=${monthYear.replace('/', '.')}`)
                }
            }))
        }))
    }

    const renderCell = (row, field, { index }) => {
        if (showErrors) {
            switch (field) {
                case 'fullName':
                    return <div className="row">
                        <NavLink to={`/clients/${row.client}`}>
                            <div style={{ marginRight: 5 }} className="icon icon-folder-custom" />
                        </NavLink>
                        {row.fullName}
                    </div>
                case 'eic':
                case 'error':
                default:
                    return row[field] || '-'
            }
        }
        switch (field) {
            case 'eic':
                return row?.eic ?? row?.pin ?? ""
            case 'fullName':
                return <div className="row">
                    <NavLink to={`/clients/${row.client}`}>
                        <div style={{ marginRight: 5 }} className="icon icon-folder-custom" />
                    </NavLink>
                    {row.fullName}
                </div>
            case 'measureTypes':
                return row[field]
            case 'objects':
                return row[field] + ' обекта'
            case 'type':
                return row?.contractSettings?.version?.name
            case 'terms':
                return row?.contractSettings?.terms ? "Да" : "Не"
            case 'createDepositLeaving':
                if (row?.contractSettings?.hasDeposit) return 'Издаден'
                return <div style={{ ...(row?.contractSettings?.depositLeavingDisabled && { opacity: 0.5, pointerEvents: 'none' }) }}>
                    <Button.Switch
                        isOn={row?.results?.createDepositLeaving}
                        onChange={() => !row?.contractSettings?.depositLeavingDisabled && handleChange({ createDepositLeaving: !row?.results?.createDepositLeaving }, index)}
                    />
                </div>
            case 'lastConsumption':
                if (!row?.results?.createDepositLeaving) return '--'
                return row?.results?.invoiceLastMonthConsumption + " МВтч"
            case 'lastTotal':
                if (!row?.results?.createDepositLeaving) return '--'
                if (!row?.results?.invoiceLastMonthTotal) return <Inputs.TextLabeledEdit
                    value={row.results.invoiceLastMonthTotal}
                    onChange={(value) => {
                        if (!/^\d+$/.test(value) && value !== '') return
                        if (row?.contractSettings?.depositLeavingIsFixed) handleChange({
                            invoiceLastMonthTotal: toNumber(value),
                        }, index)
                        else handleChange({
                            invoiceLastMonthTotal: toNumber(value),
                            invoiceLastMonthDeposit: mMultiply(value, row?.results?.leavingRatio),
                            total: mMultiply(value, row?.results?.leavingRatio),
                            totalWithoutVat: mDivide(mMultiply(value, row?.results?.leavingRatio), 1.2)
                        }, index)
                    }}
                    label="лв."
                />
                return row?.results?.invoiceLastMonthTotal + " лв." + (row?.results?.invoiceMonth ? ` (${moment(monthYear, 'MM/YYYY').subtract(row?.results?.invoiceMonth === 'last' ? 1 : 2, 'months').format('MMM')})` : "")
            case 'percent':
                if (!row?.results?.createDepositLeaving) return '--'
                if (row?.contractSettings?.depositLeavingIsFixed) return <Inputs.TextLabeledEdit
                    value={row.results.leavingFixed}
                    onChange={(value) => handleChange({
                        leavingFixed: value,
                        invoiceLastMonthDeposit: value,
                        total: value,
                        totalWithoutVat: mDivide(value, 1.2)
                    }, index)}
                    label=' лв.'
                    disabled={row?.contractSettings?.depositLeavingDisabled}
                />
                return <Inputs.TextLabeledEdit
                    value={mMultiply(row.results.leavingRatio, 100)}
                    onChange={(value) => handleChange({
                        leavingRatio: mDivide(value, 100),
                        invoiceLastMonthDeposit: mMultiply(row?.results?.invoiceLastMonthTotal, mDivide(value, 100)),
                        total: mMultiply(row?.results?.invoiceLastMonthTotal, mDivide(value, 100)),
                        totalWithoutVat: mDivide(mMultiply(row?.results?.invoiceLastMonthTotal, mDivide(value, 100)), 1.2)
                    }, index)}
                    label="%"
                    disabled={row?.contractSettings?.depositLeavingDisabled}
                />
            case 'value':
                if (!row?.results?.createDepositLeaving) return '--'
                return <Inputs.TextLabeled
                    value={row.results.invoiceLastMonthDeposit}
                    label="лв." disabled />
            case 'createPenalty':
                if (row?.contractSettings?.hasPenalty) return 'Издадена'
                return <div style={{ ...(row?.contractSettings?.penaltyDisabled && { opacity: 0.5, pointerEvents: 'none' }) }}>
                    <Button.Switch
                        isOn={row?.results?.createPenalty}
                        onChange={() => !row?.contractSettings?.penaltyDisabled && handleChange({ createPenalty: !row?.results?.createPenalty }, index)}
                    />
                </div>
            case 'penaltyAverageConsumption':
                if (!row?.results?.createPenalty) return '--'
                return (row?.results?.[field] ?? 0).toFixed(3) + " МВтч"
            case 'penaltyAveragePrice':
            case 'penaltyInitial':
                if (!row?.results?.createPenalty) return '--'
                return (row?.results?.[field] ?? 0).toFixed(2) + " лв."
            case 'penaltyDaysLeft':
                if (!row?.results?.createPenalty) return '--'
                return row?.results?.[field] ?? 0
            case 'penaltyPercent':
                if (!row?.results?.createPenalty) return '--'
                if (row?.contractSettings?.penaltyIsFixed) return <Inputs.TextLabeledEdit
                    value={row.results.penaltyFixed}
                    onChange={(value) => handleChange({
                        penaltyFixed: value,
                        penaltyValue: value,
                    }, index)}
                    label=' лв.'
                    disabled={row?.contractSettings?.penaltyDisabled}
                />
                return <Inputs.TextLabeledEdit
                    value={mMultiply(row.results.penaltyRatio, 100)}
                    onChange={(value) => handleChange({
                        penaltyRatio: mDivide(value, 100),
                        penaltyValue: mMultiply(row?.results?.penaltyInitial, mDivide(value, 100)),
                    }, index)}
                    label="%"
                    disabled={row?.contractSettings?.penaltyDisabled}
                />
            case 'penaltyValue':
                if (!row?.results?.createPenalty) return '--'
                return <Inputs.TextLabeled
                    value={row.results.penaltyValue}
                    label="лв." disabled />
            case 'paymentDays':
                return <Inputs.TextLabeledEdit
                    value={row?.paymentDays ?? 2}
                    onChange={(value) => {
                        const newData = [...(data || [])]
                        newData.splice(index, 1, { ...newData?.at(index), paymentDays: value })
                        setData(newData)
                    }}
                    label={"Работни"}
                />
            case "additionalCharges":
                return <Inputs.TextLabeledEdit
                    value={row?.additionalCharges || ""}
                    onChange={(value) => {
                        const newData = [...(data || [])]
                        newData.splice(index, 1, { ...newData?.at(index), additionalCharges: value })
                        setData(newData)
                    }}
                    label="лв."
                />
            default:
                return null
        }
    }

    return <div className="screen-deposits-create-container">
        <div className="screen-deposits-create-header row">
            {showErrors ? <h2>Преглед грешки</h2> : <>
                <h2>Депозит напускане</h2>
                <div className="row row-buttons">
                    <span>Месец:</span>
                    <Inputs.DatePicker
                        value={moment(monthYear, 'MM/YYYY').toDate()}
                        onChange={(value) => handleUrlChangeMultiple({ monthYear: moment(value).format('MM/YYYY') })}
                        dateFormat={'MMMM yyyy'}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        className="month-picker"
                    />
                    <Button.Raised text="Потвърди данни" disabled={isEmpty(selected)} onClick={handleConfirm} />
                </div>
            </>}
        </div>
        <div className="screen-deposits-create-content">
            <div className="screen-deposits-create-inner-content">
                {data && <Shared.Table
                    columns={showErrors ? [
                        { label: 'Име клиент', value: 'fullName' },
                        { label: 'ЕИК/ЕГН', value: 'eic' },
                        { label: 'Грешка', value: 'error' },
                    ] : depositsLeavingCreateFields}
                    data={showErrors ? errors : data || []}
                    renderCell={renderCell}
                    rowHeight={60}
                    useCheckboxes={!showErrors}
                    fixedWidth={!!showErrors}
                    selectedCheckboxes={selected}
                    disabledCheckboxes={errors?.map(({ _id }) => _id) ?? []}
                    onCheckboxChange={(value) => setSelected(value)}
                    outlines={data?.filter(({ error, }) => error)?.map(({ _id }) => _id)?.reduce((acc, cur) => ({ ...acc, [cur]: '#fa4444' }), {})}
                    headerWidth={depositsLeavingCreateFields.reduce((a, c) => a + (c.size || 300), 0)}
                />}
            </div>
            <div className="screen-deposits-create-footer row">
                <div className="row">
                    <p> {data?.length} клиента | {data.reduce((acc, { objects }) => acc + objects, 0)} точки</p>
                </div>
                <div className="row">
                    <p>Избрани: {selected.length}</p>
                </div>
                <div className={`row ${errors?.length && "tab-errors"}`} onClick={() => errors?.length && handleUrlChangeMultiple({ 'showErrors': !showErrors })}>
                    <p>Грешки: <span>{errors?.length ?? 0}</span></p>
                    <div className="icon icon-arrow-right-2-white" />
                </div>
            </div>
        </div>

    </div>
}

export default DepositsCreate