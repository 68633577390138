import { Button } from "@makedonski/admin-ui"
import { Alerts } from "@makedonski/socourt-utilities"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import "./styles.scss"
import { useDispatch } from "react-redux"
import { getPenalties, sendPenalties, startLoading } from "actions"
import moment from "moment"
import { useQuery } from "hooks"
import { debounce } from "lodash"
import { mapQuerySearch, renderCell } from 'utilities'
import { invoicesSearchOptions, penaltiesFields } from "config/constants"
import Popup from "reactjs-popup"
import { Inputs, Shared } from "components"

const Penalties = () => {
    const dispatch = useDispatch()
    const [state, setState] = useState({ showCheckboxes: false, selectedCheckboxes: [] })
    const { showCheckboxes, selectedCheckboxes, docs, hasNextPage, nextPage, totalDocs } = state

    const availableSort = ['penaltyValue', 'penaltySent', 'deletedAt']
    const availableFilters = {
        'deliveryStart': { type: 'date', },
        'deliveryEnd': { type: 'date', },
        'dateOfPayment': { type: 'date', },
        'penaltyValue': { type: 'priceRange' },
        'pdfFile': { type: 'boolean' },
        'penaltySent': { type: 'boolean' },
        'deletedAt': { type: 'boolean' },
    }

    const { monthYear = moment().format('MM/YYYY'), sort: sortParam = '{}', filter: filterParam = '{}', searchBy = '', handleUrlChangeMultiple } = useQuery()
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

    const fetch = useCallback((payload) => {
        dispatch(startLoading())
        dispatch(getPenalties({ payload: { monthYear, ...searchQuery, ...payload }, onSuccess: ({ results }) => setState((state) => { if (results.page > 1) results.docs = [...state.docs, ...results.docs]; return ({ ...state, ...results }) }) }))
    }, [dispatch, monthYear, searchQuery])

    const fetchDebounced = debounce(fetch, 300)
    useEffect(() => { fetch() }, [fetch])

    const handleSend = () => {
        dispatch(startLoading())
        dispatch(sendPenalties({
            payload: { ids: selectedCheckboxes },
            onSuccess: () => {
                fetch();
                setState((state) => ({ ...state, showCheckboxes: false, selectedCheckboxes: [] }))
                Alerts.success({ title: 'Успешно изпратени неустойки' })
            }
        }))
    }

    const sortingComponents = penaltiesFields.filter(({ sortable }) => sortable).reduce((acc, { value, ...rest }) => ({
        ...acc,
        [value]: <Popup
            activeFilters={searchQuery}
            trigger={<div className="icon icon-arrow-down" />}
            contentStyle={{ width: 'auto' }}
        >
            {close => <Shared.SortingComponent
                hide={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                sortProp={sortParam}
                filterProp={filterParam}
            />}
        </Popup>
    }), {})

    return <div className="screen-penalties-container">
        <div className="screen-penalties-header row">
            <Inputs.DatePicker
                customInput={<div className='row'>
                    <h2 className="row">{moment(monthYear, 'MM/YYYY').format('м. MMMM')}</h2>
                    <div className="icon icon-calendar-custom" />
                </div>}
                className="month-picker"
                showMonthYearPicker
                showFullMonthYearPicker
                value={moment(monthYear, 'MM/YYYY')}
                onChange={(monthYear) => handleUrlChangeMultiple({ 'monthYear': moment(monthYear).format('MM/YYYY') })}
                minDate={moment().subtract(10, 'years').toDate()}
                maxDate={moment().add(1, 'months').endOf('month').toDate()}
            />
            <Inputs.SearchMulti
                search={filter?.[searchBy] || ''}
                searchBy={searchBy || 'eic'}
                handleChange={({ search, searchBy: newSearchBy }) => {
                    const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
                    handleUrlChangeMultiple({
                        searchBy: search ? newSearchBy : undefined,
                        filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
                    })
                }}
                options={invoicesSearchOptions}
                popupText={{ secondary: ' ' }}
            />
            {showCheckboxes
                ? <div className="row row-buttons">
                    <Button.Raised text='Откажи' className="btn-other-actions" onClick={() => setState((state) => ({ ...state, showCheckboxes: false, selectedCheckboxes: [] }))} />
                    {showCheckboxes?.action === 'send' ? <Button.Raised text="Изпрати" onClick={handleSend} /> : null}
                </div>
                : <div className="row row-buttons">
                    <Popup
                        trigger={<div><Button.Raised text="Други действия" className="btn-other-actions" /></div>}
                    >
                        {close => <div className="popup-send-container">
                            <div className="row">
                                <h4>Изберете действие</h4>
                                <Button.Icon name="plus" onClick={close} />
                            </div>
                            <p onClick={() => { fetch({ pagination: false }); setState((state) => ({ ...state, showCheckboxes: { action: 'send' } })); close() }}>Изпрати имейл</p>
                            <p onClick={() => { fetch({ pagination: false, forExport: true }); close() }}>Експорт</p>
                            <p onClick={() => { handleUrlChangeMultiple({ search: null, searchBy: null, sort: null, filter: null, }); close() }}>Изчисти всички филтри</p>
                        </div>}
                    </Popup>
                </div>}
        </div>
        <div className="screen-penalties-content">
            <Shared.Table
                columns={penaltiesFields}
                data={docs}
                renderCell={(row, field, options) => renderCell.penalties(row, field, { ...options, dispatch, fetch })}
                handlePagination={() => hasNextPage && fetchDebounced({ page: nextPage })}
                useCheckboxes={!!showCheckboxes}
                selectedCheckboxes={selectedCheckboxes}
                onCheckboxChange={(value) => setState((state) => ({ ...state, selectedCheckboxes: value }))}
                disabledCheckboxes={['send'].includes(showCheckboxes?.action) ? docs?.filter(({ penaltySent, pdfFile, deletedAt }) => penaltySent || !pdfFile || deletedAt).map(({ _id }) => _id) :
                    []}
                sortingComponent={sortingComponents}
                outlines={{ ...(docs || []).reduce((acc, { _id, deletedAt }) => deletedAt ? ({ ...acc, [_id]: '#fa4444' }) : acc, {}) }}
            />
        </div>
        <div className="screen-penalties-footer row">
            <div className="screen-penalties-inner-footer row">
                <p className="row">Общо: {totalDocs || 0}</p>
                {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0}</p>}
            </div>
        </div>
    </div>
}

export default Penalties