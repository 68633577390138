import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import './styles.scss'

const FilterShortcuts = ({hide}) => {
  return (
    <div className="popup-filter-shortcuts-container">
      <div className="row">
        <h4>Пореди по</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <Inputs.RadioButtons
        buttons={[
          'Последно използвани',
          'Най-много използвани',
          'Най-малко използвани',
        ]}
        value="Последно използвани"
        col
      />
    </div>
  )
}

export default FilterShortcuts
