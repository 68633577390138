import { default as RadioButtons } from './RadioButtons'
import { default as TextLabeled } from './TextLabeled'
import { default as Checkboxes } from './CheckBoxes'
import { default as Autocomplete } from './Autocomplete'
import { default as Password } from './Password'
import { default as RoundedButtons } from './RoundedButtons'
import { default as DatePicker } from './DatePicker'
import { default as PercentageSplit } from './PercentageSplit'
import { default as Dropdown } from './Dropdown'
import { default as Select } from './Select'
import { default as SearchMulti } from './SearchMulti'
import { default as TextEdit } from './TextEdit'
import { default as Comments } from './Comments'
import { default as TextLabeledEdit } from './TextLabeledEdit'
import { default as NumberRange } from './NumberRange'
import { default as DropdownSelect } from './DropdownSelect'

const Inputs = {
  RadioButtons,
  TextLabeled,
  Checkboxes,
  Autocomplete,
  Password,
  RoundedButtons,
  DatePicker,
  PercentageSplit,
  Dropdown,
  Select,
  SearchMulti,
  TextEdit,
  Comments,
  TextLabeledEdit,
  NumberRange,
  DropdownSelect
}

export default Inputs
