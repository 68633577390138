import { Button, Input } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { createCommission, findCommissionAgent, findCommissionClient, setModal, startLoading, stopLoading } from 'actions'
import { Inputs } from 'components'
import { isEmpty, pick } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"

const AddCommission = ({ close, fetch }) => {
  const { currentUser } = useSelector(({ general }) => general)

  const dispatch = useDispatch()
  const [data, setData] = useState({ activeFrom: moment().startOf('month').toDate() })
  const handleChange = (payload) => setData({ ...data, ...payload })

  const [clients, setClients] = useState([])
  const [commissioners, setCommissioners] = useState([])
  useEffect(() => {
    dispatch(findCommissionClient({ onSuccess: ({ docs }) => setClients(docs) }))
    dispatch(findCommissionAgent({ onSuccess: ({ docs }) => setCommissioners(docs) }))
  }, [])

  return <div className="modal-add-commission-container">
    <div className="modal-add-commission-header row">
      <h2>Добавяне на комисион</h2>
      <div className="icon icon-close" onClick={close} />
    </div>
    <div className="modal-add-commission-content">
      <div className="row">
        <div className="col">
          <span>Клиент</span>
          <Inputs.Select
            text={{ main: 'Клиенти', secondary: 'Избери клиент' }}
            value={data?.client}
            handleChange={(client) => handleChange({ client })}
            options={clients?.map(({ _id, fullName, pin, eic }) => ({ _id, label: `${pin || eic} - ${fullName}`, value: _id }))}
            search
            handleSearch={(name) => dispatch(findCommissionClient({
              payload: { name, clientIdentifier: name },
              onSuccess: ({ docs }) => setClients(docs)
            }))}
            single
            editable
          />
        </div>
        <div className="col">
          <span>Комисионер</span>
          <Inputs.Select
            text={{ main: 'Комисионери', secondary: 'Избери комисионер' }}
            value={data?.user}
            handleChange={(user) => handleChange({ user })}
            options={commissioners?.map(({ _id, fullName }) => ({ _id, label: fullName, value: _id }))}
            search
            handleSearch={(name) => dispatch(findCommissionAgent({
              payload: { name },
              onSuccess: ({ docs }) => setCommissioners(docs)
            }))}
            single
            editable
          />
        </div>
        <div className="col">
          <span>Комисион</span>
          <Inputs.TextLabeled label="лв." value={data.commission || ""} onChange={({ target: { value } }) => {
            if (!/^\d{1,5}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
            handleChange({ commission: value })
          }} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <span>Активен от</span>
          <Inputs.DatePicker
            value={data?.activeFrom || moment().startOf('month').toDate()}
            onChange={(value) => handleChange({ activeFrom: moment(value).startOf('month').toDate() })}
            className={`month-picker`}
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </div>
        <div className="col col-full">
          <span>Коментар</span>
          <Input.Text value={data?.comment || ""} onChange={({ target: { value } }) => handleChange({ comment: value })} />
        </div>
      </div>
    </div>
    <div className="modal-add-commission-footer row">
      <Button.Raised
        text="Запази"
        disabled={['client', 'user', 'commission'].some((f) => !data?.[f])}
        onClick={() => {
          dispatch(startLoading())
          dispatch(createCommission({
            payload: { ...pick(data, ['client', 'user', 'comment',]), commission: { ...pick(data, ['commission', 'activeFrom', 'comment']), user: currentUser._id, updatedAt: new Date(), } },
            onSuccess: ({ errors }) => {
              if (!isEmpty(errors)) dispatch(setModal({ isOpen: true, type: 'confirmation', props: { title: "Грешки", children: <div className="col" style={{ maxHeight: 400, overflow: 'auto' }}>{errors.map(error => <p key={error}>{error}</p>)}</div>, onClick: fetch, } }))
              else Alerts.confirm({ title: "Успешно добавяне на комисион", cancel: 'Продължи въвеждането', confirm: 'Към "Комисионни"', reverseButtons: true, }, (isConfirmed) => { if (isConfirmed) { fetch(); close() } })
            }
          }))
        }} />
    </div>
  </div>
}

export default AddCommission