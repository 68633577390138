import { nanoid } from "nanoid"
import { URL } from "../../config/settings"
import { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Headers } from "@makedonski/socourt-utilities"

export const emit = (socket, payload) => {
    if (!socket) return
    console.log('Socket out: ', payload);
    socket.emit("tasks", payload)
}

export const useQuery = () => {
    const history = useHistory()
    const location = useLocation()
    const query = new URLSearchParams(window.location.search)
    const handleUrlChange = (newValues = {}) => {
        Object.entries(newValues).forEach(([key, value]) => {
            if (!value) query.delete(key)
            else if (query.has(key)) query.set(key, value)
            else query.append(key, value)
        })

        history.replace({ ...location, search: '?' + query.toString() })
    }

    return { ...Array.from(query.keys()).reduce((a, key) => ({ ...a, [key]: query.get(key) }), {}), handleUrlChange }
}

export const uploadFile = async ({ file, location = '', createId } = {}) => {
    let filename = file.name
    filename = filename.split('%').join('').split(' ').join('')
    filename = createId ? [nanoid(), `${location}${filename}`,].join("_") : `${location}${filename}` || nanoid()
    const response = await fetch(`${URL}/cloudServices/s3Upload`, { method: 'POST', headers: Headers.getWithAuth(), body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? '' }) })
    if (!response.ok) throw new Error('Грешка');
    const { presignedUrl, url } = await response.json()
    const response2 = await fetch(presignedUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file?.type ?? '' } })
    if (!response2.ok) throw new Error('Грешка')
    return { location: url }
}
export const uploadFiles = async ({ files = [], location = '', createId } = {}) => {
    const uploaded = []
    await asyncForEach([...files], async (file) => uploaded.push(await uploadFile({ file, location, createId })))
    return uploaded
}
const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

export const useOnClickOutside = (ref, handler, isOpen = true, buttonRef) => {
    useEffect(() => {
        if (!isOpen) return
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) return
            if (buttonRef && buttonRef.contains(event.target)) return
            handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}

export const useCollapsable = (ref, open = false) => {
    const [isOpen, setIsOpen] = useState(open)
    const handleCollapse = () => {
        const elem = ref.current
        if (!elem) return
        elem.style.height = ''
        elem.style.transition = 'none'
        const startHeight = window.getComputedStyle(elem).height
        elem.classList.toggle('active')
        const height = window.getComputedStyle(elem).height
        elem.style.height = startHeight
        requestAnimationFrame(() => {
            elem.style.transition = ''
            requestAnimationFrame(() => (elem.style.height = height))
        })
        elem.addEventListener('transitionend', () => (elem.style.height = ''))
    }

    const firstRender = useRef(true)
    useEffect(() => {
        if (firstRender.current) firstRender.current = false
        else handleCollapse()
    }, [isOpen])

    return [isOpen, setIsOpen]
}
