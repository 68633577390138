import React from 'react'
import { useDispatch } from 'react-redux'
import { updateObject, setModal } from 'actions'
import './styles.scss'

const ObjectConfirmed = ({ object, fetch }) => {
  const dispatch = useDispatch()
  const { _id, status } = object || {}

  return (
    <div className="client-cells-object-confirmed-container row">
      {status === 'documents-received' ? 'Да' : status === 'documents-received-missing' ? 'Не' : '--'}
      {['documents-sent', 'documents-received', 'documents-received-missing'].includes(status) && (
        <div
          className="icon icon-edit"
          onClick={() =>
            dispatch(
              setModal({
                isOpen: true,
                type: 'confirmation',
                props: {
                  title: 'Редакция статус',
                  children: 'Моля потвърдете, че искате да смените статуса на точката',
                  onClick: () =>
                    dispatch(
                      updateObject({
                        data: {
                          _id,
                          status:
                            status === 'documents-received'
                              ? 'documents-received-missing'
                              : 'documents-received',
                        },
                        onSuccess: () => {
                          dispatch(setModal({ isOpen: false }))
                          fetch()
                        },
                      })
                    ),
                },
              })
            )
          }
        />
      )}
    </div>
  )
}

export default ObjectConfirmed
