import { Input, Button } from '@makedonski/admin-ui'
import { Client } from 'components'
import moment from "moment"
import React from "react"
import { useSelector } from 'react-redux'
import "./styles.scss"

const ClientPricing = ({ data }) => {
    const { products } = useSelector(({ data }) => data)

    return <div className="client-forms-client-pricing-container">
        <h2>История договор и цени - Консумация</h2>
        {data?.map(({ _id, activeFrom, data }) => <div key={_id} className="single-pricing">
            <div className="row">
                <div className="col">
                    <span>Активна от</span>
                    <Input.Text value={moment(activeFrom).format("DD.MM.YYYY")} disabled />
                </div>
                <div className="col">
                    <span>Тип договор</span>
                    <Input.Text value={data?.contractSettings?.type} disabled />
                </div>
                <div className="col">
                    <span>Дни падеж</span>
                    <div className="row row-paymentDays">
                        <Input.Text value={data?.contractSettings?.paymentDaysType} disabled />
                        <Input.Text value={data?.contractSettings?.paymentDays} disabled />
                    </div>
                </div>
                <div className="col">
                    <span>Общи условия</span>
                    <Button.Switch isOn={data?.contractSettings?.terms} />
                </div>
                <div className="col">
                    <span>Декларация</span>
                    <Button.Switch isOn={data?.declaration} />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span>Продукт</span>
                    <Input.Text value={products?.find(({ _id }) => _id === data?.product)?.name || ""} disabled />
                </div>
                <Client.Forms.ProductPrices products={products} data={data} editable={false} />
            </div>
            <div className="divider" />
        </div>)}

    </div>
}

export default ClientPricing