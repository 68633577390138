import React from 'react'
import { useDispatch } from 'react-redux'
import { setModal, updateObject } from 'actions'
import './styles.scss'
import { useQuery } from 'hooks'

const additionalStatusMap = { obligations: 'Възразен за задължения', sustained: 'Оттеглено възражение' }

const ObjectAdditionalStatus = ({ object, fetch }) => {
  const { type } = useQuery()
  const dispatch = useDispatch()
  const { _id, status, additionalStatus } = object || {}


  if (['register', 'producersRegistration'].includes(type)) return <div className="client-cells-object-leaving-container row">
    {additionalStatus ? "Да" : "--"}
    <div
      className="icon icon-edit"
      onClick={() => dispatch(setModal({
        isOpen: true, type: 'confirmation', props: {
          title: 'Допълнителен статус',
          children: `Потвърдете, че клиентът${additionalStatus ? ' няма ' : ' има '}задължения`,
          onClick: () => dispatch(updateObject({
            data: { _id, additionalStatus: additionalStatus ? null : 'obligations' },
            onSuccess: () => { fetch({ pageOne: true }) }
          }))
        }
      }))}
    />
  </div>

  return (<div className="client-cells-object-leaving-container row">
    {status === 'leaving' ? additionalStatusMap[additionalStatus] || '--' : '--'}
    {['leaving'].includes(status) && (
      <div
        className="icon icon-edit"
        onClick={() =>
          dispatch(
            setModal({
              isOpen: true,
              type: 'clientsLeaving',
              props: {
                object,
                field: 'additionalStatus',
                options: [
                  { label: 'Възразен за задължения', value: 'obligations' },
                  { label: 'Оттеглено възражение', value: 'sustained' },
                ],
                fetch,
              },
            })
          )
        }
      />
    )}
  </div>
  )
}

export default ObjectAdditionalStatus
