import React, { useState, useEffect, useMemo, useRef } from "react"
import { omit, isEmpty } from 'lodash'
import Inputs from "../Inputs"
import { useOnClickOutside, useCollapsable } from '../helpers'
import "./styles.scss"

const Filter = ({ isOpen, hide, filters: filtersProp, setFilters: setFiltersProp, assignees: allAssignees }) => {
  const containerRef = useRef(null)
  useOnClickOutside(containerRef, hide, isOpen)

  const [filters, setFilters] = useState(filtersProp)
  useEffect(() => { setFilters(filtersProp) }, [filtersProp])

  const collapsableRefAssignees = useRef()
  const [isExpandedAsignees, setExpandedAsignees] = useCollapsable(collapsableRefAssignees)
  const [assigneesQuery, setAssigneesQuery] = useState("")


  const assignees = useMemo(() => allAssignees?.filter(({ fullName }) => fullName?.toLowerCase()?.includes(assigneesQuery?.toLowerCase())), [allAssignees, assigneesQuery])


  return <div className={`task-management-filter-overlay-container ${isOpen && 'open'}`}>
    <div className='task-management-filter-overlay-inner-container' ref={containerRef}>
      <div className='task-management-filter-container col'>
        <div className="task-management-filter-header row">
          <h2>Сортирай по</h2>
          <div className="tasks-icon tasks-icon-close" onClick={hide} ></div>
        </div>
        <div className="task-management-filter-content">
          <div className="">
            <h3>Статус</h3>
            <Inputs.Checkboxes
              col
              buttons={[
                'Предстояща',
                'Активна',
                'Работи се',
                'Просрочена',
                'Завършена',
                'Архивирана',
              ]}
              value={filters.status ?? ['Предстояща', 'Активна', 'Работи се', 'Просрочена', 'Завършена']}
              onClick={(value) => setFilters({ ...filters, status: value })}
            />
          </div>
          <div className="">
            <h3>Пeриод</h3>
            <Inputs.RadioButtons
              col
              buttons={[
                { label: 'Всички дати', value: 'all', },
                { label: 'Избери дата/период', value: 'period', },
              ]}
              value={filters.dueDate || "all"}
              onClick={(value) => value === "all"
                ? setFilters(omit(filters, ["dueDate", "dateStart", "dateEnd"]))
                : setFilters({ ...filters, dueDate: value, dateStart: new Date(), dateEnd: new Date() })
              }
            />
            {filters.dueDate && <Inputs.Datepicker
              selected={filters.dateStart}
              onChange={([dateStart, dateEnd]) => setFilters({ ...filters, dateStart, dateEnd })}
              startDate={filters.dateStart}
              endDate={filters.dateEnd}
              selectsRange
              inline
            />}
          </div>
          <div className="">
            <h3>Приоритет</h3>
            <Inputs.Checkboxes
              col
              buttons={[
                { label: 'Нисък приоритет', value: 'Нисък', color: '#00CB5E' },
                { label: 'Среден приоритет', value: 'Среден', color: "#FFB612" },
                { label: 'Висок приоритет', value: 'Висок', color: "#FA4444" },
              ]}
              value={filters.priority || ["Нисък", "Среден", "Висок"]}
              onClick={(value) => isEmpty(value) ? setFilters(omit(filters, ['priority'])) : setFilters({ ...filters, priority: value })}
            />
          </div>
          <div>
            <div className="row" onClick={() => setExpandedAsignees(!isExpandedAsignees)} style={{ cursor: 'pointer' }}>
              <h3>Назначени</h3>
              <div className={`tasks-icon tasks-icon-arrow-down ${isExpandedAsignees && 'rotate'}`} />
            </div>
            <div className="row-collapse" ref={collapsableRefAssignees}>
              <input
                className="input-text-container"
                placeholder="Търси"
                value={assigneesQuery || ""}
                onChange={({ target: { value } }) => setAssigneesQuery(value)}
              />
              {!isEmpty(assignees) && <Inputs.Checkboxes
                buttons={[{ label: "Избери всички", value: assignees?.length || 0 }]}
                value={[filters?.assignedTo?.length]}
                onClick={() => {
                  if (isEmpty(filters?.assignedTo)) setFilters({ ...filters, assignedTo: assignees.map(({ _id }) => _id) })
                  else setFilters(omit(filters, ['assignedTo']))
                }}
              />}
              <Inputs.Checkboxes
                buttons={assignees?.map(({ _id, fullName, coverPhoto }) => ({ icon: coverPhoto, value: _id, label: fullName, }))}
                value={filters?.assignedTo}
                onClick={(value) => setFilters({ ...filters, assignedTo: value })}
                col
              />
            </div>
          </div>
        </div>
        <div className="task-management-filter-footer card row">
          <div className="raised-button-container clear"
            onClick={() => {
              setFiltersProp({})
              hide()
            }}
          >Изчисти филтри</div>
          <div style={{ width: 30 }} />
          <div className="raised-button-container" onClick={() => {
            setFiltersProp(filters)
            hide()
          }}>Задай</div>
        </div>
      </div>
    </div>
  </div>
}

export default Filter