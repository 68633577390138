import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import React, { useState } from "react"
import "./styles.scss"
import moment from "moment"
import { Portal } from "react-overlays"

const PriceActiveFrom = ({ close, onSaveClient, forConsumers, forProducers }) => {
    const [date, setDate] = useState(moment().startOf('month').toDate())
    const [dateProducers, setDateProducers] = useState(moment().startOf('month').toDate())

    return <div className="modal-price-active-from-container col">
        <div className="modal-price-active-from-header row">
            <h2>Дата влизане в сила</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-price-active-from-content">
            {forConsumers && <>
                <p>Консумация</p>
                <Inputs.DatePicker
                    value={date}
                    onChange={(value) => setDate(moment(value).startOf('month').toDate())}
                    dateFormat={'MMMM yyyy'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                    popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                /></>}
            {forProducers && <>
                <p>Производство</p>
                <Inputs.DatePicker
                    value={dateProducers}
                    onChange={(value) => setDateProducers(moment(value).startOf('month').toDate())}
                    dateFormat={'MMMM yyyy'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                    popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                /></>}
        </div>
        <div className="modal-price-active-from-footer">
            <Button.Raised text="Потвърди" onClick={() => {
                onSaveClient({
                    ...(forConsumers && { priceActiveFrom: date }),
                    ...(forProducers && { producerPriceActiveFrom: dateProducers }),
                }); close()
            }} />
        </div>
    </div>
}

export default PriceActiveFrom