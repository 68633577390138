import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { flatten, isEmpty, groupBy, partition } from 'lodash'
import {
  getData,
  startLoading,
  setReferencesFields,
  getReferenceInvoices,
  getReferenceInvoicesDifferences,
  setModal,
  ignoreObjectForMonth,
} from 'actions'
import { Shared, Inputs } from 'components'
import { referenceInvoicesFields, invoicesObjectsFields } from 'config/constants'
import { useQuery } from 'hooks'
import { renderCell } from 'utilities'
import './styles.scss'
import { NavLink } from 'react-router-dom'

const Invoices = ({ }) => {
  const dispatch = useDispatch()
  const {
    references: {
      reference,
      shouldPreGenerate,
      lastErrorUpdate,
      errors,
      failedClients,
      totalDocs,
      selectedCheckboxes,
      selectedGroups,
      nextPage,
      hasNextPage,
    },
    data: { erps },
  } = useSelector((state) => state)
  const { type, length, startDate, endDate, showErrors, showMissing, expand, handleUrlChange } = useQuery()
  const [erp, setErp] = useState()
  //prettier-ignore
  const fetchPayload = useMemo(() => ({
    type,
    from: length === "all" ? undefined : moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf("month").toDate(),
    to: length === "all" ? undefined : moment(startDate).subtract(!startDate ? 1 : 0, 'months').endOf("month").add(1, 'milliseconds').toDate(),
  }), [type, length, startDate, endDate])

  //prettier-ignore
  const fetch = () => {
    if (!type) return
    dispatch(startLoading())
    if (type === 'differences') {
      if (erp) dispatch(getReferenceInvoicesDifferences({ payload: { ...fetchPayload, erp: erps?.find(({ _id }) => _id === erp).name } }))
    }
    else dispatch(getReferenceInvoices({ payload: fetchPayload }))
  }

  useEffect(() => {
    fetch()
    return () => {
      dispatch(
        setReferencesFields({
          reference: [],
          nextPage: 1,
          hasNextPage: false,
          expandedRow: null,
          selectedCheckboxes: [],
          selectedGroups: [],
          errors: [],
          failedClients: 0,
          shouldPreGenerate: [],
          lastErrorUpdate: null
        })
      )
    }
  }, [fetchPayload])
  useEffect(() => {
    if (!erps) dispatch(getData('erps'))
    else if (!erp) setErp(erps[0]._id)
  }, [erps])
  useEffect(() => {
    if (type === 'differences' && erp) fetch()
  }, [erp])

  const [editing, setEditing] = useState('')
  const [edited, setEdited] = useState({})

  const [query, setQuery] = useState('')

  const invoiceGroups = useCallback((client) => {
    const { objects } = client || {}
    const [inGroups, notInGroups] = partition(objects, ({ invoiceGroup }) => invoiceGroup)
    const grouped = groupBy(inGroups, ({ invoiceGroup }) => invoiceGroup?._id)
    const inGroupsMapped = Object.values(grouped).map((g) => ({
      _id: `${client?._id}:${g?.at(0)?.invoiceGroup?._id}`,
      name: g?.at(0)?.invoiceGroup?.name,
      length: g.length,
      itn: g.map(({ itn }) => itn).join(', '),
      client: client?._id,
    }))
    return notInGroups.length
      ? [
        ...inGroupsMapped,
        {
          _id: `${client?._id}:notGrouped`,
          name: 'Без Група',
          length: notInGroups.length,
          itn: notInGroups.map(({ itn }) => itn).join(', '),
          client: client?._id,
        },
      ]
      : inGroupsMapped
  }, [reference])

  const handlePagination = () => {
    if (!['not-generated'].includes(type) || !hasNextPage || showMissing) return
    dispatch(startLoading())
    dispatch(getReferenceInvoices({ payload: { ...fetchPayload, page: nextPage } }))
  }

  const selectedClients = useMemo(() => {
    return [...new Set(selectedGroups?.map((g) => g?.split(':')?.at(0)))]
  }, [selectedGroups])

  const tableRef = useRef(null)
  const tableChildRef = useRef({})

  const ObjectsTable = useCallback(({ row }) => {
    return (<Shared.Table
      ref={ref => { tableChildRef.current[row.id] = ref }}
      className="objects"
      columns={invoicesObjectsFields.filter(({ includedIn }) => !includedIn)}
      data={row?.original?.objects || []}
      renderCell={(row, field) => renderCell.objects(row, field, { fetch })}
      outlines={row?.original?.objects.filter(({ invoicingErrors }) => !isEmpty(invoicingErrors))?.reduce((acc, { _id }) => ({ ...acc, [_id]: '#fa4444' }), {})}
      fixedWidth={type !== 'ready'}
      containerStyle={type === 'ready' ? { width: invoicesObjectsFields.filter(({ includedIn }) => !includedIn).reduce((a, c) => a + (c.size || 300), 0), maxHeight: 350 } : { maxHeight: 350 }}
      headerWidth={type === 'ready' ? invoicesObjectsFields.filter(({ includedIn }) => !includedIn).reduce((a, c) => a + (c.size || 300), 0) : undefined}
    />)
  }, [])

  const InvoiceGroupsTable =
    useCallback(({ row }) => {
      return (<Shared.Table
        ref={ref => { tableChildRef.current[row.id] = ref }}
        className="invoiceGroups"
        columns={referenceInvoicesFields.invoiceGroups}
        data={invoiceGroups(row.original)}
        renderCell={(row, value) => row[value] ?? ''}
        containerStyle={{ maxHeight: 350 }}
        outlines={selectedGroups?.reduce((acc, cur) => ({ ...acc, [cur]: '#e87a1e' }), {})}
        hideSelectAll
        fixedWidth
        useCheckboxes
        selectedCheckboxes={selectedGroups}
        onCheckboxChange={(value) => dispatch(setReferencesFields({ selectedGroups: value }))}
      />)
    }, [selectedGroups])

  return (
    <div className={`references-invoices-container ${type}`}>
      {type === 'differences' && (
        <div className="row references-invoices-header">
          <Inputs.RoundedButtons
            buttons={erps?.map(({ _id, name }) => ({ label: name, value: _id })) || []}
            value={erp}
            onChange={({ value }) => setErp(value)}
          />
          <Input.Text
            placeholder="Търси по име на клиент или ИТН"
            value={query}
            onChange={({ target: { value } }) => setQuery(value)}
          />
        </div>
      )}
      <div className="references-invoices-content">
        <Shared.Table
          ref={tableRef}
          className={type}
          columns={showErrors ? referenceInvoicesFields.errors : showMissing ? referenceInvoicesFields.missing : referenceInvoicesFields[type]}
          handlePagination={handlePagination}
          data={
            (showErrors || showMissing)
              ? errors
              : (reference || [])
                ?.filter(({ itn, client }) => {
                  return (
                    type !== 'differences' ||
                    !query ||
                    itn?.toLowerCase()?.includes(query?.toLowerCase()) ||
                    client?.fullName?.toLowerCase()?.includes(query?.toLowerCase())
                  )
                })
                ?.map((row) => ({ ...row?.client, ...row }))
          }
          fixedWidth={['ready', 'differences', "progress"].includes(type) || showErrors || showMissing}
          outlines={{
            ...shouldPreGenerate?.reduce((acc, cur) => ({ ...acc, [cur]: '#fa4444' }), {}),
            ...selectedGroups?.map((g) => g?.split(':')?.at(0))?.reduce((acc, cur) => ({ ...acc, [cur]: '#e87a1e' }), {}),
          }}
          useCheckboxes={['ready'].includes(type) && !showErrors}
          selectedCheckboxes={selectedClients}
          onCheckboxChange={(value) => dispatch(setReferencesFields({ selectedCheckboxes: value }))}
          //prettier-ignore
          onCheckboxClick={({ _id, objects }) => {
            dispatch(
              setReferencesFields({
                selectedGroups: selectedClients.includes(_id)
                  ? selectedGroups.filter((g) => {
                    return !objects?.map(({ invoiceGroup }) => `${_id}:${invoiceGroup?._id || 'notGrouped'}`).includes(g)
                  })
                  : [
                    ...selectedGroups,
                    ...new Set(objects?.map(({ invoiceGroup }) => invoiceGroup?._id || 'notGrouped')?.map((g) => `${_id}:${g}`))
                  ].filter(Boolean),
              })
            )
          }}
          onCheckboxClickAll={(options) => {
            if (isEmpty(selectedCheckboxes))
              dispatch(
                setReferencesFields({
                  selectedCheckboxes: options?.map(({ _id }) => _id),
                  selectedGroups: flatten(
                    options?.map(({ _id, objects }) =>
                      //prettier-ignore
                      [...new Set(objects?.map(({ invoiceGroup }) => invoiceGroup?._id || 'notGrouped')?.map((g) => `${_id}:${g}`))].filter(Boolean)
                    )
                  ),
                })
              )
            else dispatch(setReferencesFields({ selectedCheckboxes: [], selectedGroups: [] }))
          }}
          renderCell={(row, field, options) => {
            if (showErrors) {
              if (field === 'eic') return row?.client?.[field] || row?.client?.pin || ''
              if (field === 'fullName') return <div className="row">
                <NavLink to={`/clients/${row?.client?._id || row?._id || ''}`}>
                  <div className="icon icon-folder-custom" style={{ marginRight: 14 }} />
                </NavLink>
                {row?.client?.[field] || row?.[field] || ''}
              </div>
              else if (field === 'ignore') return <Button.Raised text="Игнорирай" className="cancel" onClick={() => dispatch(setModal({
                isOpen: true,
                type: 'confirmation',
                props: {
                  title: "Игнорирай",
                  children: `Точката няма да бъде фактурирана за месец ${moment(startDate ?? undefined).subtract(!!startDate ? 0 : 1, 'month').startOf("month").format('MM.YYYY')}`,
                  buttons: <div className="row" style={{ justifyContent: 'space-between' }}>
                    <Button.Raised text="Откажи" className="cancel" onClick={() => dispatch(setModal({ isOpen: false }))} />
                    <div style={{ heigh: 1, width: 10 }}></div>
                    <Button.Raised text="Потвърди" onClick={() => dispatch(ignoreObjectForMonth({
                      payload: { itn: row.itn, ignoreMonth: moment(startDate ?? undefined).subtract(!!startDate ? 0 : 1, 'month').startOf("month").format('MM/YYYY') },
                      onSuccess: () => {
                        const newErrors = [...errors]
                        newErrors.splice(options.index, 1)

                        dispatch(setModal({ isOpen: false }))
                        dispatch(setReferencesFields({ errors: newErrors }))
                        // fetch()
                      }
                    }))} />
                  </div>
                }
              }))} />
              else return row?.client?.[field] || row?.[field] || ''
            } else if (showMissing) {
              if (["inNetwork", 'inPeriod'].includes(field)) return row?.[field] ? "Да" : "Не"
              return row?.[field] || "--"
            } else return renderCell.references.invoices[type](
              row,
              field,
              {
                isExpanded: options?.isExpanded,
                toggleRowExpanded: tableRef.current.toggleRowExpanded,
                fetch,
                selected: {
                  data: [],
                  set: () => { },
                  editing: editing,
                  setEditing: (value) => setEditing(value),
                  edited: edited,
                  setEdited: (value) => setEdited(value),
                }
              }
            )
          }}
        >
          {expand === 'invs' ? InvoiceGroupsTable : ObjectsTable}
        </Shared.Table>
      </div>
      <div className="row references-invoices-footer">
        {type === 'generated' && (
          <div className="row">
            <p>Общо: {reference?.reduce((acc, { clients }) => acc + clients || 0, 0)} клиента</p>
            <p>
              Сума без ДДС:{' '}
              {reference?.reduce((acc, { totalPrice, vat }) => acc + ((totalPrice || 0) - (vat || 0)), 0).toFixed(2)}{' '}
              лв.
            </p>
          </div>
        )}
        {type === 'not-generated' && (
          <div className="row">
            <p className="row">{totalDocs || 0} клиента</p>
            <p className="row" onClick={() => handleUrlChange('showMissing', !showMissing)}>
              Липсващи: {errors?.length ?? 0} точки
              <span style={{ display: 'block', marginLeft: 15 }} className="icon icon-arrow-right-white" />
            </p>
            {lastErrorUpdate &&
              <p className="row">Грешки от: {moment(lastErrorUpdate).format('HH:mm | DD.MM.YYYY')}
                <span className='icon icon-refresh-white' onClick={() => {
                  dispatch(startLoading())
                  dispatch(getReferenceInvoices({ payload: { ...fetchPayload, refresh: true } }))
                }}></span>
              </p>
            }
          </div>
        )}
        {type === 'ready' && (
          <div className="row">
            <p>Общо: {reference.length}</p>
            <p>Избрани {selectedGroups.length}</p>
            <p className="row" onClick={() => handleUrlChange('showErrors', !showErrors)}>
              Грешки: {failedClients ?? 0} клиента
              <span style={{ display: 'block', marginLeft: 15 }} className="icon icon-arrow-right-white" />
            </p>
            <p className="row" onClick={() => {
              dispatch(startLoading())
              dispatch(getReferenceInvoices({ payload: { ...fetchPayload } }))
            }}>
              Обнови всичко
              <span className='icon icon-refresh-white' />
            </p>
            <p className="row" onClick={() => {
              dispatch(startLoading())
              dispatch(getReferenceInvoices({ payload: { ...fetchPayload, clientsToCheck: errors?.map(({ client: { _id } }) => _id) } }))
            }}>
              Обнови само грешки
              <span className='icon icon-refresh-white' />
            </p>
          </div>
        )}
      </div>
    </div >
  )
}

export default Invoices
