import React, { useEffect } from 'react'
import { Auth } from '@makedonski/socourt-utilities'
import Div100vh from 'react-div-100vh'
import { Router } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkUser, setModal } from './actions'
import { Routes } from './components'
import { history } from './config/store'
import { useEventListener } from 'hooks'

import 'reactjs-popup/dist/index.css';
import 'sweetalert2/src/sweetalert2.scss'
import 'react-quill/dist/quill.snow.css'

import moment from 'moment'
import 'moment/locale/bg'
moment.locale('bg')

import { setDefaultLocale } from 'react-datepicker'
import bg from 'date-fns/locale/bg'
setDefaultLocale(bg)

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => { Auth.isAuthenticated && dispatch(checkUser()) }, [])

  useEventListener('offline', () => dispatch(setModal({
    isOpen: true,
    type: 'confirmation',
    closeButton: false,
    shouldCloseOnOverlayClick: false,
    shouldCloseOnEsc: false,
    overlayStyles: { zIndex: 10001 },
    props: {
      title: 'Загубихте връзка с интернет',
      children: <div className="row" style={{ justifyContent: 'center' }}><div style={{ height: 200, width: 200 }} className='icon icon-offline' /></div>,
      hideButton: true,
      closeButton: false
    },
  })))
  useEventListener('online', () => dispatch(setModal({ isOpen: false })))

  return (
    <Router history={history}>
      <Div100vh>
        <Routes />
      </Div100vh>
    </Router>
  )
}

export default App
