export const commentsTypes = {
  CREATE_COMMENT: 'comments/CREATE_COMMENT',
  UPDATE_COMMENT: 'comments/UPDATE_COMMENT',
  DELETE_COMMENT: 'comments/DELETE_COMMENT',

  CREATE_COMMENT_CLIENT_SUCCESS: 'comments/CREATE_COMMENT_CLIENT_SUCCESS',
  CREATE_COMMENT_INVOICE_SUCCESS: 'comments/CREATE_COMMENT_INVOICE_SUCCESS',
}

export const createComment = (payload) => ({
  type: commentsTypes.CREATE_COMMENT,
  ...payload,
})

export const updateComment = (payload) => ({
  type: commentsTypes.UPDATE_COMMENT,
  ...payload,
})

export const deleteComment = (payload) => ({
  type: commentsTypes.DELETE_COMMENT,
  ...payload,
})
