import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button } from '@makedonski/admin-ui'
import { Alerts } from "@makedonski/socourt-utilities"
import "./styles.scss"
import { useQuery } from "hooks"
import { useDispatch } from "react-redux"
import { createComment, getInvoice, producersInvoiceCreate, producersInvoiceUpdate, setModal, startLoading } from "actions"
import { omit, pick } from "lodash"
import { Inputs, Invoices } from "components"
import { mMultiply } from "utilities"
import moment from "moment"
import { useHistory } from "react-router-dom"

const requiredFields = ["invoiceDate", "dateOfPayment"]

const ProducersInvoiceForm = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const clientFormRef = useRef(null)
  const { invoice: _id } = useQuery()


  const [invoice, setInvoice] = useState({})
  const handleChange = (payload) => setInvoice((prev) => ({ ...prev, ...payload }))
  const fetch = useCallback(() => {
    dispatch(startLoading())
    dispatch(getInvoice({
      payload: _id, onSuccess: (res) => {
        setInvoice({
          ...pick(res, ['_id', "deletedAt", ...requiredFields, 'client', 'pricing', 'objects']),
          invoicePeriodStart: moment(res.monthYear, 'MM/YYYY').startOf('month').toDate(),
          invoicePeriodEnd: moment(res.monthYear, 'MM/YYYY').endOf('month').toDate(),
          client: res.client._id,
          selectedObject: res?.objects?.[0]?._id
        })
        clientFormRef?.current?.setClient({ ...res.client })
      }
    }))
  }, [_id, dispatch])
  useEffect(() => { if (_id) fetch() }, [_id, fetch])

  const [showRequired, setShowRequired] = useState(false)
  const isValid = () => {
    if (requiredFields.some(f => !invoice?.[f])) {
      Alerts.error('Моля попълнете всички полета в "Основна информация"')
      return false
    } else if (!invoice?.client) {
      Alerts.error('Моля изберете клиент')
      return false
    }
    else if (!invoice?.selectedObject) {
      Alerts.error('Моля изберете обект')
      return false
    }
    else if (!invoice?.pricing?.base) {
      Alerts.error('Моля попълнете "Стойност без ДДС"')
      return false
    }
    else return true
  }


  const onClear = () => {
    if (_id) fetch()
    else {
      setInvoice({})
      clientFormRef?.current?.setClient({})
    }
    setShowRequired(false)
  }
  const onSave = () => {
    const payload = getPayload()
    if (!isValid(payload)) {
      setShowRequired(true)
      return
    } else {
      dispatch(startLoading())
      if (_id) dispatch(producersInvoiceUpdate({ payload, onSuccess: () => history.goBack() }))
      else dispatch(producersInvoiceCreate({ payload, onSuccess: () => history.goBack() }))
    }
  }

  const onDelete = () => {
    dispatch(startLoading())
    dispatch(producersInvoiceUpdate({ payload: { _id, deletedAt: new Date() }, onSuccess: () => history.goBack() }))
  }

  const onReverseDelete = () => {
    dispatch(startLoading())
    dispatch(producersInvoiceUpdate({ payload: { _id, $unset: { deletedAt: 1 } } }))
  }

  const onDeleteAndSave = () => {
    const payload = getPayload()
    if (!isValid(payload)) {
      setShowRequired(true)
      return
    } else {
      dispatch(startLoading())
      dispatch(producersInvoiceUpdate({
        payload: { _id, deletedAt: new Date(), justDelete: false },
        onSuccess: () => {
          dispatch(startLoading())
          dispatch(producersInvoiceCreate({ payload: { ...omit(payload, ['_id']), canceledInvoice: _id }, onSuccess: () => history.goBack() }))
        }
      }))
    }
  }

  const handleComment = ({ comment, onSuccess } = {}) => {
    if (comment) dispatch(createComment({
      payload: { refType: 'Invoices', ref: _id, text: comment, type: 'default' },
      onSuccess
    }))
    else onSuccess()
  }

  const getPayload = () => ({
    ...pick(invoice, ['_id', 'client', 'dateOfPayment', 'invoiceDate']),
    client: invoice?.client,
    objects: [invoice.selectedObject],
    type: 'producer',
    monthYear: moment(invoice.invoicePeriodStart).format('MM/YYYY'),
    invoiceAmount: Number(invoice?.pricing?.base),
  })

  const props = { invoice, handleChange, showRequired }


  return <div className="screen-producers-invoice-form-container">
    <div className="screen-producers-invoice-form-header row">
      <h2>{_id ? "Редакция" : "Създаване"} на фактура производител</h2>
      <div className="row row-buttons">
        <Button.Raised className="btn-clear" text="Откажи" onClick={onClear} />
        {invoice.deletedAt
          ? <Button.Raised text='Отанулирай' onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { title: 'Отанулиране', onClick: (comment) => handleComment({ comment, onSuccess: onReverseDelete }) } }))} />
          : <>
            {_id && <>
              <Button.Raised text="Анулирай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDelete }) } }))} />
              <Button.Raised text="Анулирай и преиздай" onClick={() => dispatch(setModal({ isOpen: true, type: 'invoiceComment', props: { onClick: (comment) => handleComment({ comment, onSuccess: onDeleteAndSave }) } }))} />
            </>}
            <Button.Raised text={_id ? "Редактирай" : "Генерирай"} onClick={onSave} />
          </>}
      </div>
    </div>
    <div className={`screen-producers-invoice-form-content col${invoice.deletedAt ? ' disabled' : ''}`}>
      <Invoices.Forms.Main {...props} />
      <Invoices.Forms.Client ref={clientFormRef} producers {...props} />
      {invoice?.client && <>
        <div className={`screen-producers-invoice-form-object-container ${showRequired && !invoice?.selectedObject && 'required'}`} style={{ zIndex: 2 }}>
          <div className="screen-producers-invoice-form-object-header row">
            <h2>Данни обект</h2>
          </div>
          <div className="screen-producers-invoice-form-object-content row">
            <div className="col">
              <span>Обект</span>
              <Inputs.Dropdown
                disabled={_id}
                options={invoice?.objects?.filter(({ isProducer }) => isProducer)?.map(({ _id, itn }) => ({ value: _id, label: `${itn}` }))}
                value={invoice?.selectedObject ?? ''}
                onChange={({ value }) => handleChange({ selectedObject: value })}
              />
            </div>
          </div>
        </div>
        {invoice?.selectedObject && <>
          <div className="screen-producers-invoice-form-pricing-container">
            <div className="screen-producers-invoice-form-pricing-header row">
              <h2>Данни фактура</h2>
            </div>
            <div className="screen-producers-invoice-form-pricing-content row">
              <div className="col">
                <span>Стойност без ДДС</span>
                <Inputs.TextLabeled
                  value={invoice?.pricing?.base ?? ''}
                  onChange={({ target: { value } }) => handleChange({ ...invoice, pricing: { base: value, vat: mMultiply(value, 0.2), withVat: mMultiply(value, 1.2) } })}
                  inputClassName={showRequired && !invoice?.pricing?.base && 'required'}
                />
              </div>
              <div className="col">
                <span>ДДС</span>
                <Inputs.TextLabeled disabled value={invoice?.pricing?.vat ?? ''} inputClassName={showRequired && !invoice?.pricing?.vat && 'required'} />
              </div>
              <div className="col">
                <span>Стойност</span>
                <Inputs.TextLabeled disabled value={invoice?.pricing?.withVat ?? ''} inputClassName={showRequired && !invoice?.pricing?.withVat && 'required'} />
              </div>
            </div>
          </div>
        </>}
      </>}
    </div>
  </div>
}

export default ProducersInvoiceForm