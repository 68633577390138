import React, { useEffect, useMemo, useState } from "react"
import "./styles.scss"
import { useSelector } from "react-redux"
import { emit, useQuery } from "../helpers"
import EmojiPicker, { Emoji } from 'emoji-picker-react';
import Popup from "reactjs-popup";
import { Inputs } from "../";
import { isEmpty, pick } from "lodash";

const BoardForm = ({ socket, boardForm, assignees: allAssignees, userGroups: allUserGroups }) => {
    const { boardId, } = useQuery()
    const { currentUser } = useSelector(({ general }) => general)
    const [data, setData] = useState({
        title: '',
        icon: '1f4c3',
        users: [currentUser._id],
        userGroups: [],
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))

    useEffect(() => {
        if (!isEmpty(boardForm)) setData({
            ...pick(boardForm, ['title', 'icon']),
            users: boardForm.permissions.filter(({ user }) => user).map(({ user }) => user?._id ?? user),
            userGroups: boardForm.permissions.filter(({ userGroup }) => userGroup).map(({ userGroup }) => userGroup?._id ?? userGroup)
        })
    }, [boardForm])

    const [showRequired, setShowRequired] = useState(false)

    const handleButton = () => {
        if (['title',].some((f) => !data?.[f])) setShowRequired(true)
        else {
            const payload = { ...pick(data, ['title', 'icon']), permissions: [...data.users.map((_id) => ({ user: _id })), ...data.userGroups.map((_id) => ({ userGroup: _id }))] }
            if (boardId) emit(socket, { type: 'boards/updateOne', payload: { id: boardId, ...payload } })
            else emit(socket, { type: 'boards/create', payload })
        }
    }

    const [assigneesQuery, setAssigneesQuery] = useState("")
    const assignees = useMemo(() => allAssignees?.filter(({ fullName }) => fullName?.toLowerCase()?.includes(assigneesQuery?.toLowerCase())), [allAssignees, assigneesQuery])

    const [userGroupsQuery, setUserGroupsQuery] = useState("")
    const userGroups = useMemo(() => allUserGroups?.filter(({ name }) => name?.toLowerCase()?.includes(userGroupsQuery?.toLowerCase())), [allUserGroups, userGroupsQuery])

    return <div className="task-management-board-form-container">
        <div className="task-management-board-form-header card row">
            <h2>{boardId ? 'Редакция на' : 'Нова'} група</h2>
            <div className="raised-button-container" onClick={handleButton}>{boardId ? 'Запази' : 'Създай група'}</div>
        </div>
        <div className="task-management-board-form-content col">
            <div className="task-management-board-form-main card">
                <h3>Основна информация</h3>
                <div className="row">
                    <div className="col col-full">
                        <span>Заглавие</span>
                        <input
                            className={`input-text-container ${showRequired && !data.title && 'required'}`}
                            value={data?.title}
                            onChange={({ target: { value } }) => handleChange({ title: value })}
                        />
                    </div>
                    <div className="col col-full">
                        <span>Иконка</span>
                        <Popup
                            trigger={<div>{<Emoji unified={data.icon} size="25" />}</div>}
                            contentStyle={{width: 'auto'}}
                            position='bottom right'
                        >
                            {<EmojiPicker
                                onEmojiClick={({ unified }) => handleChange({ icon: unified })}
                                theme="auto"
                                skinTonesDisabled
                            />}
                        </Popup>
                    </div>
                </div>
            </div>
            <div className="task-management-board-form-assignees card">
                <h3>Участници</h3>
                <div className="col col-big">
                    <span>Екипи</span>
                    <div className="row">
                        <Popup
                            className="task-management-popup-assignees-outer-container"
                            trigger={<div className="tasks-icon tasks-icon-plus"></div>}
                            position="right center"
                            nested
                        >
                            {close => <div className="task-management-popup-assignees-container">
                                <div className="task-management-popup-assignees-header row">
                                    <h2>Екипи</h2>
                                    <div className="tasks-icon tasks-icon-close" onClick={close} />
                                </div>
                                <div className="task-management-popup-assignees-content">
                                    <input
                                        className='input-text-container'
                                        placeholder="Търси"
                                        value={userGroupsQuery || ""}
                                        onChange={({ target: { value } }) => setUserGroupsQuery(value)}
                                    />
                                    <div className="scroll-container">
                                        <Inputs.Checkboxes
                                            buttons={userGroups
                                                ?.map(({ _id, name }) => ({
                                                    value: _id,
                                                    label: name,
                                                }))}
                                            value={data.userGroups}
                                            onClick={(userGroups) => handleChange({ userGroups })}
                                            col
                                            includeCoverPhotos
                                        />
                                    </div>
                                </div>
                            </div>}
                        </Popup>
                        <div className="assignees-container row">
                            {userGroups?.filter(({ _id }) => data?.userGroups?.includes(_id))?.map(({ coverPhoto, name }, i) => (
                                <div key={`assignee-${i}`} className="single-assignee row">
                                    <div
                                        className="profile-image tasks-icon"
                                        style={{ backgroundImage: `url(${coverPhoto || require('../assets/default-user-avatar.jpg')})` }}
                                    />
                                    <p className="assignee-fullName">{name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col col-big">
                    <span>Служители</span>
                    <div className="row">
                        <Popup
                            className="task-management-popup-assignees-outer-container"
                            trigger={<div className="tasks-icon tasks-icon-plus"></div>}
                            position="right center"
                            nested
                        >
                            {close => <div className="task-management-popup-assignees-container">
                                <div className="task-management-popup-assignees-header row">
                                    <h2>Служители</h2>
                                    <div className="tasks-icon tasks-icon-close" onClick={close} />
                                </div>
                                <div className="task-management-popup-assignees-content">
                                    <input
                                        className='input-text-container'
                                        placeholder="Търси"
                                        value={assigneesQuery || ""}
                                        onChange={({ target: { value } }) => setAssigneesQuery(value)}
                                    />
                                    <div className="scroll-container">
                                        <Inputs.Checkboxes
                                            buttons={assignees
                                                ?.map(({ _id, fullName, coverPhoto }) => ({
                                                    icon: coverPhoto,
                                                    value: _id,
                                                    label: fullName,
                                                    disabled: _id === currentUser._id
                                                }))}
                                            value={data.users}
                                            onClick={(users) => handleChange({ users })}
                                            col
                                            includeCoverPhotos
                                        />
                                    </div>
                                </div>
                            </div>}
                        </Popup>
                        <div className="assignees-container row">
                            {assignees?.filter(({ _id }) => data?.users?.includes(_id))?.map(({ coverPhoto, fullName }, i) => (
                                <div key={`assignee-${i}`} className="single-assignee row">
                                    <div
                                        className="profile-image tasks-icon"
                                        style={{ backgroundImage: `url(${coverPhoto || require('../assets/default-user-avatar.jpg')})` }}
                                    />
                                    <p className="assignee-fullName">{fullName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default BoardForm