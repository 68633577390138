import React, { useRef } from 'react'
import moment from 'moment'
import { isBoolean, isString } from 'lodash'
import { useCollapsable } from 'hooks'
import './styles.scss'
import { clientsStatus, commissionsStatuses, intermediateInvoicesTypes } from 'config/constants'
import { transformURL } from 'utilities'

const actionMap = { created: 'Създаде', updated: 'Редактира', deleted: 'Изтри', added: 'Добави' }
const refTypeMap = { Clients: 'клиент', Annex: 'анекс', Objects: 'обект', InvoiceGroups: 'група фактури', CommissionAgentMonth: 'комисион' }

const ClientHistorySingle = ({ activity }) => {
  const { createdAt, action, refType, ref, user, changes, additionalData } = activity || {}

  const collapsableRef = useRef()
  const [isExpanded, setExpanded] = useCollapsable(collapsableRef)

  const renderChange = ({ action, name, old: oldValue, new: newValue, oldRef, newRef, labelField }) => {
    if (oldRef) oldValue = oldRef[labelField]
    if (newRef) newValue = newRef[labelField]

    if (oldValue && isString(oldValue) && oldValue.endsWith('Z') && oldValue.length === 24 && moment(oldValue).isValid()) oldValue = moment(oldValue).format("DD.MM.YYYY | HH:mm")
    if (newValue && isString(newValue) && newValue.endsWith('Z') && newValue.length === 24 && moment(newValue).isValid()) newValue = moment(newValue).format("DD.MM.YYYY | HH:mm")

    if (isBoolean(oldValue)) oldValue = oldValue ? "Да" : "Не"
    if (isBoolean(newValue)) newValue = newValue ? "Да" : "Не"

    if (refType === 'Objects' && action === 'updated' && name === 'Статус') {
      oldValue = clientsStatus.find(({ value }) => value === oldValue)?.label
      newValue = clientsStatus.find(({ value }) => value === newValue)?.label
    }
    if (refType === 'Clients' && action === 'updated' && name === 'Вид фактуриране') {
      oldValue = intermediateInvoicesTypes.find(({ value }) => value === oldValue)?.label
      newValue = intermediateInvoicesTypes.find(({ value }) => value === newValue)?.label
    }

    if (refType === 'Objects' && name === 'Задължения') return null

    if (refType === 'CommissionAgentMonth' && action === 'updated' && name === 'Статус') {
      oldValue = commissionsStatuses[oldValue]
      newValue = commissionsStatuses[newValue]
    }

    if ([oldValue, newValue].every(v => [undefined, null].includes(v))) return null
    if (action === 'deleted') return null

    if (!!oldValue && isString(oldValue) && oldValue?.includes('https')) oldValue = <a href={transformURL(oldValue)} target="_blank" rel="noopener noreferrer">{oldValue}</a>
    if (!!newValue && isString(newValue) && newValue?.includes('https')) newValue = <a href={transformURL(newValue)} target="_blank" rel="noopener noreferrer">{newValue}</a>
    if (typeof oldValue === 'object' || typeof newValue === 'object') return <p>Промяна: <span>
      {oldValue}
      {`${![undefined, null].includes(oldValue) && ![undefined, null].includes(newValue) ? ` -> ` : ``}`}
      {newValue}
    </span></p>

    return <p>Промяна:<span>{`
      ${![undefined, null].includes(oldValue) ? `${oldValue}` : ``}
      ${![undefined, null].includes(oldValue) && ![undefined, null].includes(newValue) ? ` -> ` : ``}
      ${![undefined, null].includes(newValue) ? `${newValue}` : ``}
    `}</span></p>
  }


  return <div className="card-client-history-single-container">
    <p className="time">{moment(createdAt).format('HH:mm | DD.MM.YYYY')}</p>
    <div className="row row-main">
      <p className="name">
        {additionalData ? additionalData.text : `${actionMap[action]} ${refTypeMap[refType]}${refType === 'Objects' ? ` - ${ref?.itn || "Изтрит обект"}` : ""}`}
        {changes.length === 1 && ` - ${actionMap[changes?.at(0)?.action]?.toLowerCase()} ${changes?.at(0)?.name?.toLowerCase()}`}
      </p>
      {changes.length > 1 && (<div className={`icon icon-arrow-down ${isExpanded && 'rotate'}`} onClick={() => setExpanded(!isExpanded)} />)}
    </div>
    {changes.length === 1 && <div className='values'>{renderChange(changes[0])}</div>}
    {changes.length > 1 && (
      <div className="changes-container" ref={collapsableRef}>
        <ul>
          {changes?.map((change, i) => {
            return (<li key={`change-${i}`}>
              <p>{`${actionMap[change.action]} ${change.name?.toLowerCase()}`}</p>
              {renderChange(change)}
            </li>)
          })}
        </ul>
      </div>
    )}
    <p className="from">
      От: <span>{user?.fullName}</span>
    </p>
  </div>
}

export default ClientHistorySingle
