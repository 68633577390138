import React, { useCallback, useEffect, useMemo, useRef } from "react"
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { useQuery } from "hooks"
import { mapQuerySearch, renderCell } from "utilities"
import { getReferenceClientsGroups, setReferencesFields, startLoading } from "actions"
import { initialState } from 'reducers/references'
import Shared from "components/Shared"
import { referenceClientsGroupsDetailsFields, referenceClientsGroupsFields } from "config/constants"
import { mapKeys } from "lodash"
import Popup from "reactjs-popup"

const ClientsGroups = ({ }) => {
    const dispatch = useDispatch()
    const { reference, totalDocs, nextPage, hasNextPage } = useSelector(({ references }) => references)

    const { sort: sortParam = '{}', filter: filterParam = '{}' } = useQuery()
    const availableSort = ['name', 'createdAt', 'clientsCount']
    const availableFilters = {
        'name': { type: 'regex' },
        'createdAt': { type: 'date' },
        'createdBy': { type: 'regex' },
    }
    const keyMap = { 'clients': 'clientsCount' }
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

    const fetch = useCallback((payload) => {
        dispatch(startLoading())
        dispatch(getReferenceClientsGroups({ payload: { ...searchQuery, ...payload } }))
    }, [searchQuery, dispatch])

    useEffect(() => {
        fetch()
        return () => dispatch(setReferencesFields({ initialState }))
    }, [dispatch, fetch])

    const tableRef = useRef(null)
    const DetailsTable = useCallback(({ row }) => <Shared.Table
        fixedWidth
        columns={referenceClientsGroupsDetailsFields}
        data={row?.original.clients.map((c) => ({ ...c, original: row?.original }))}
        containerStyle={{ maxHeight: 350 }}
        renderCell={(row, field, options) => renderCell.references.clientsGroupsDetails(row, field, { ...options, fetch, dispatch })}
    />, [])

    const sortingComponents = referenceClientsGroupsFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
        ...acc,
        [value]: <Popup
            contentStyle={{ width: 'auto' }}
            keepTooltipInside='.table-container'
            trigger={<div className="icon icon-arrow-down" />}
            activeFilters={{ ...mapKeys(searchQuery, (_, key) => keyMap[key] ?? key), sort: mapKeys(searchQuery.sort, (_, key) => keyMap[key] ?? key) }}
        >
            {close => <Shared.SortingComponent
                hide={close}
                column={value}
                availableSort={availableSort}
                availableFilters={availableFilters}
                keyMap={keyMap}
            />}
        </Popup>
    }), {})

    return <div className="references-clients-groups-container">
        <div className="references-clients-groups-content">
            <Shared.Table
                fixedWidth
                ref={tableRef}
                columns={referenceClientsGroupsFields}
                data={reference}
                renderCell={(row, field, options) => renderCell.references.clientsGroups(row, field, {
                    ...options,
                    dispatch,
                    fetch,
                    toggleRowExpanded: tableRef.current.toggleRowExpanded,
                })}
                maxExpandHeight={550}
                expandField="clients"
                handlePagination={() => hasNextPage && fetch({ page: nextPage })}
                sortingComponent={sortingComponents}
            >{DetailsTable}</Shared.Table>
        </div>
        <div className="references-clients-groups-footer row">
            <div className="references-clients-groups-inner-footer row">
                <p className="row">Групи: {totalDocs || 0}</p>
            </div>
        </div>
    </div>
}

export default ClientsGroups