import React, { useState } from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import './styles.scss'

const TextLabeledEdit = ({ label, value: valueProp = "", onChange, ...props }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [value, setValue] = useState(valueProp)

  const handleConfirm = () => {
    if (!value) return
    if (onChange && value !== valueProp) onChange(value)
    setShowEdit(false)
  }

  const handleCancel = () => {
    setShowEdit(false)
    setValue(valueProp)
  }

  const handleEdit = () => {
    setShowEdit(true)
    setValue(valueProp)
  }

  return <div className={`inputs-text-labeled-edit-container row ${showEdit && 'edit'}`}>
    <Input.Text
      disabled={!showEdit}
      value={value ?? ""}
      onChange={({ target }) => setValue(target.value)} {...props}
      onKeyDown={({ key }) => key === 'Enter' && handleConfirm()}
    />
    <div className="row row-buttons">
      {props?.disabled ? null : showEdit ? (
        <>
          <Button.Icon name="plus" color="red" size={15} onClick={handleCancel} />
          <div className={`icon icon-check ${!value && 'disabled'} `} onClick={handleConfirm} />
        </>
      ) : <div className="icon icon-edit" onClick={handleEdit} />
      }
      <span>{label}</span>
    </div>
  </div>
}

export default TextLabeledEdit