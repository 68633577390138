import React, { useState, useMemo, useEffect } from "react"
import { Button } from '@makedonski/admin-ui'
import { isEmpty, pick } from "lodash"
import { useDispatch } from "react-redux"
import { getIntermediateInvoices, createIntermediateInvoices, startLoading, setModal, setOverlay } from "actions"
import { Shared, Inputs } from 'components'
import { intermediateInvoicesGenerateExportFields, intermediateInvoicesGenerateFields } from 'config/constants'
import { useQuery } from "hooks"
import "./styles.scss"
import moment from "moment"
import { NavLink, useHistory } from "react-router-dom"
import { mAdd, mDivide, mMultiply, xlsxExport } from "utilities"

const typeMap = {
  'advance-10': 'Авансова фактура на 10 дни',
  'advance-15': 'Авансова фактура на 15 дни',
  'advance-30': 'Авансова фактура на 30 дни',
  'intermediate-10': 'Междинна фактура на 10 дни',
  'intermediate-15': 'Междинна фактура на 15 дни'
}
const consecutiveNumberMap = { 1: 'Първа фактура', 2: 'Втора фактура', 3: 'Трета фактура' }
const statusMap = { active: 'Активни', leaving: 'Напускащи', registration: 'Регистрации', intermediateInvoicingSpecial: "Специални" }

const IntermediateInvoicesGenerate = ({ }) => {
  const history = useHistory()
  const { type, consecutiveNumber, price, monthYear, status, advanceFirstDate, percentLastMonth, handleUrlChangeMultiple, showErrors } = useQuery()
  const [dataRaw, setDataRaw] = useState()
  const [data, setData] = useState()
  const [selected, setSelected] = useState()

  const handleChange = (payload, index) => {
    const newData = [...(data || [])]
    newData.splice(index, 1, { ...newData?.at(index), results: { ...newData?.at(index).results, ...payload } })
    setData(newData)
  }
  const mapRow = (o, i) => {
    if (percentLastMonth) {
      const selected = 'invoiceLastMonth', energyPrice = '', energyPricePercent = price, tzoPrice = 0, excisePrice = 0, energy = parseFloat(o.results.invoiceLastMonthConsumptionForPeriod || 0), delivery = parseFloat(o.results.invoiceLastMonthDeliveryTotalForPeriod || 0)

      const invoiceLastMonthTotalWithoutVat = mAdd(o.results.invoiceLastMonthConsumptionTotal, o.results.invoiceLastMonthDeliveryTotal)
      const totalWithoutVat = mMultiply(invoiceLastMonthTotalWithoutVat, mDivide(energyPricePercent, 100))
      const total = mMultiply(totalWithoutVat, 1.2)

      return { _id: `row-${i + 1}`, ...o, results: { ...o.results, selected, energy, delivery, energyPrice, tzoPrice, excisePrice, totalWithoutVat, total, percentLastMonth: true, invoiceLastMonthTotalWithoutVat, energyPricePercent } }
    } else {
      const { invoiceLastYear, invoiceLastMonth, invoiceLastYearConsumptionForPeriod, invoiceLastMonthConsumptionForPeriod } = o?.results || {}
      const energyPrice = parseFloat(price), tzoPrice = 0, excisePrice = 0, delivery = 0
      const selected = !invoiceLastYear && !invoiceLastMonth ? "" : invoiceLastYearConsumptionForPeriod > invoiceLastMonthConsumptionForPeriod ? "invoiceLastYear" : "invoiceLastMonth"
      const energy = selected ? parseFloat(o.results[`${selected}ConsumptionForPeriod`] || 0) : ''
      const totalWithoutVat = ((parseFloat(price || 0) + parseFloat(tzoPrice || 0) + parseFloat(excisePrice || 0)) * energy) + delivery
      const total = mMultiply(totalWithoutVat, 1.2)
      return { _id: `row-${i + 1}`, ...o, results: { ...o.results, selected, energy, delivery, energyPrice, tzoPrice, excisePrice, totalWithoutVat, total, } }
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(startLoading())
    dispatch(getIntermediateInvoices({
      payload: { type, consecutiveNumber, advanceFirstDate, monthYear, status },
      onSuccess: (response) => {
        const temp = response?.map(mapRow)
        setDataRaw(temp)
        setData(temp)
      }
    }))
  }, [type, consecutiveNumber, advanceFirstDate, monthYear, status,])

  useEffect(() => {
    if (!isEmpty(data)) setData(data.map(mapRow))
  }, [price, percentLastMonth])

  const errors = useMemo(() => data?.filter(({ error }) => error) || [], [data])

  const handleGenerate = () => {
    const payload = {
      ...pick(data?.at(0), ['type', 'monthYear', 'consecutiveNumber', 'invoicePeriodStart', 'invoicePeriodEnd']),
      clients: data
        .filter(({ _id }) => selected.includes(_id))
        .filter(({ results }) => !!results.energy && (!!results.energyPrice || !!results.energyPricePercent))
        .map((row) => ({
          ...pick(row, ['client', 'results']),
          defaultInvoiceGroup: row?.defaultInvoiceGroup || 'none',
          paymentDays: row.contractSettings.paymentDays,
          paymentDaysType: row.contractSettings.paymentDaysType
        }))
    }
    dispatch(startLoading())
    dispatch(createIntermediateInvoices({
      payload, onSuccess: ({ invoices }) => dispatch(setModal({
        isOpen: true, type: 'confirmation', props: {
          title: `Генерирани ${invoices?.length} фактури`,
          buttons: (
            <div className="row" style={{ justifyContent: 'space-around' }}>
              <Button.Raised
                text='Към "Фактури"'
                className="no-flex"
                onClick={() => {
                  history.push(`/financial/invoices?type=advanced&month=${monthYear.replace('/', '.')}`)
                  dispatch(setModal({ isOpen: false }))
                }}
              />
              <Button.Raised
                text='Към "Прогрес"'
                className="no-flex"
                onClick={() => {
                  history.push('/references/intermediateInvoices?type=generated&length=all')
                  dispatch(setModal({ isOpen: false }))
                }}
              />
            </div>
          ),
          onRequestClose: () => history.push(`/financial/invoices?type=advanced&month=${monthYear.replace('/', '.')}`)
        }
      }))
    }))
  }

  const renderCell = (row, field) => {
    const index = ((showErrors ? errors : data) || []).findIndex((r) => r._id === row._id)
    if (showErrors) {
      switch (field) {
        case 'fullName':
          return <div className="row">
            <NavLink to={`/clients/${row.client}`}>
              <div className="icon icon-folder-custom" />
            </NavLink>
            {row.fullName}
          </div>
        case 'eic':
        case 'error':
        default:
          return row[field] || '-'
      }
    }
    switch (field) {
      case 'fullName':
        return <div className="row">
          <NavLink to={`/clients/${row.client}`}>
            <div className="icon icon-folder-custom" />
          </NavLink>
          {row.fullName}
        </div>
      case 'comments':
        return <div className="row">
          <div
            className="icon icon-details"
            style={{ marginRight: 15 }}
            onClick={() => dispatch(setOverlay({
              isOpen: true, type: 'comments', props: {
                obj: { _id: row.client, comments: row.comments },
                type: 'payments',
                onSuccess: (comment) => {
                  const newData = [...data]
                  newData.splice(index, 1, { ...row, comments: [...(row?.comments || []), comment] })
                  setData(newData)
                }
              }
            }))}
          />
          {row?.comments?.[row?.comments?.length - 1]?.text ?? "-"}
        </div>
      case "objects":
        return `${row?.[field]} Обект${row?.[field]?.length !== 1 ? "a" : ''}`
      case 'type':
        return row?.contractSettings?.type || '-'
      case 'terms':
        return row?.contractSettings?.terms ? 'Да' : "Не"
      case 'invoiceLastYear':
        return <div
          className={`row ${(
            row.results.selected === "invoiceLastYear" ||
            (row.results.selected === 'manual' && row.results.delivery === row.results.invoiceLastYearDeliveryTotalForPeriod)
          ) && 'active'} ${!row.results.invoiceLastYear && 'disabled'}`}
          onClick={() => {
            if (!row.results.invoiceLastYear) return
            const energy = row.results.invoiceLastYearConsumptionForPeriod
            const delivery = row.results.invoiceLastYearDeliveryTotalForPeriod
            const totalWithoutVat = (parseFloat(row.results.energyPrice || 0) + parseFloat(row.results.tzoPrice || 0) + parseFloat(row.results.excisePrice || 0)) * energy + delivery
            const total = mMultiply(totalWithoutVat, 1.2)
            handleChange({ selected: 'invoiceLastYear', energy, delivery, total, totalWithoutVat }, index)
          }}
        >
          <Inputs.RadioButtons
            buttons={[{ value: true }]}
            value={row.results.selected === "invoiceLastYear"}
          />
          <div className="">
            {row.results.invoiceLastYear
              ? <>
                <p>{row.results.invoiceLastYearConsumptionForPeriod.toFixed(2)} МВтч</p>
                <span>от {row.results.invoiceLastYearConsumption.toFixed(2)} МВтч</span>
              </>
              : <p>-- МВтч</p>}
          </div>
          <div className="">
            {row.results.invoiceLastYear
              ? <>
                <p>{row.results.invoiceLastYearTotalForPeriod.toFixed(2)} лв</p>
                <span>от {row.results.invoiceLastYearTotal.toFixed(2)} лв</span>
              </>
              : <p>-- лв</p>}
          </div>
        </div>

      case 'invoiceLastMonth':
        return <div
          className={`row ${(
            row.results.selected === "invoiceLastMonth" ||
            (row.results.selected === 'manual' && row.results.delivery === row.results.invoiceLastMonthDeliveryTotalForPeriod)
          ) && 'active'} ${!row.results.invoiceLastMonth && 'disabled'}`}
          onClick={() => {
            if (!row.results.invoiceLastMonth) return
            const energy = row.results.invoiceLastMonthConsumptionForPeriod
            const delivery = row.results.invoiceLastMonthDeliveryTotalForPeriod
            const totalWithoutVat = (parseFloat(row.results.energyPrice || 0) + parseFloat(row.results.tzoPrice || 0) + parseFloat(row.results.excisePrice || 0)) * energy + delivery
            const total = mMultiply(totalWithoutVat, 1.2)
            handleChange({ selected: 'invoiceLastMonth', energy, delivery, total, totalWithoutVat }, index)
          }}
        >
          <Inputs.RadioButtons
            buttons={[{ value: true }]}
            value={row.results.selected === "invoiceLastMonth"}
          />
          <div className="">
            {row.results.invoiceLastMonth
              ? <>
                <p>{row.results.invoiceLastMonthConsumptionForPeriod.toFixed(2)} МВтч</p>
                <span>от {row.results.invoiceLastMonthConsumption.toFixed(2)} МВтч</span>
              </>
              : <p>-- МВтч</p>}
          </div>
          <div className="">
            {row.results.invoiceLastMonthTotalForPeriod
              ? <>
                <p>{row.results.invoiceLastMonthTotalForPeriod.toFixed(2)} лв
                  {row.results.invoiceMonth && <span style={{ textTransform: 'capitalize' }}>{' '}
                    ({moment(monthYear, 'MM/YYYYY').subtract(row.results.invoiceMonth === 'last' ? 1 : 2, 'months').format("MMM")})
                  </span>}
                </p>
                <span>от {row.results.invoiceLastMonthTotal.toFixed(2)} лв</span>
              </>
              : <p>-- лв</p>}
          </div>
        </div>
      case 'energy':
        return <Inputs.TextLabeledEdit
          key={parseFloat(row.results[field] || 0).toFixed(3) ?? ''}
          value={parseFloat(row.results[field] || 0).toFixed(3) ?? ''}
          onChange={(value) => {
            const energy = parseFloat(value || 0)
            const delivery = parseFloat(row.results.delivery || 0)
            const totalWithoutVat = (parseFloat(row.results.energyPrice || 0) + parseFloat(row.results.tzoPrice || 0) + parseFloat(row.results.excisePrice || 0)) * energy + delivery
            const total = mMultiply(totalWithoutVat, 1.2)
            handleChange({ selected: "manual", energy: value, total, totalWithoutVat }, index)
          }}
          label="МВтч"
        />
      case 'energyPrice':
        return <Inputs.TextLabeledEdit
          key={row.results[field] ?? ''}
          value={row.results[field] ?? ''}
          onChange={(value) => {
            const energy = parseFloat(row.results.energy || 0)
            const delivery = parseFloat(row.results.delivery || 0)
            const totalWithoutVat = (parseFloat(value || 0) + parseFloat(row.results.tzoPrice || 0) + parseFloat(row.results.excisePrice || 0)) * energy + delivery
            const total = mMultiply(totalWithoutVat, 1.2)
            handleChange({ energyPrice: value, delivery, total, totalWithoutVat }, index)
          }}
          label="лв"
        />
      case 'energyPricePercent':
        return <Inputs.TextLabeledEdit
          key={row.results[field] ?? ''}
          value={row.results[field] ?? ''}
          onChange={(value) => {
            const totalWithoutVat = mMultiply(row?.results?.invoiceLastMonthTotalWithoutVat, mDivide(value, 100))
            const total = mMultiply(totalWithoutVat, 1.2)
            handleChange({ energyPricePercent: value, total, totalWithoutVat }, index)
          }}
          label="%"
        />
      case 'energyTotal':
        return <Inputs.TextLabeled disabled value={(parseFloat(row.results.energy || 0) * parseFloat(row.results.energyPrice)).toFixed(2)} label="лв" />
      case 'tzoPrice':
      case 'excisePrice':
        return <Inputs.TextLabeled disabled value={(parseFloat(row.results.energy || 0) * parseFloat(row.results[field])).toFixed(2)} label="лв" />
      case 'delivery':
      case "total":
      case 'totalWithoutVat':
      case 'invoiceLastMonthTotalWithoutVat':
        return <Inputs.TextLabeled disabled value={parseFloat(row.results[field] || 0).toFixed(2)} label="лв" />
      case 'paymentDays':
        return <Inputs.TextLabeledEdit
          value={row.contractSettings[field] ?? 0}
          onChange={(value) => {
            const newData = [...(data || [])]
            newData.splice(index, 1, { ...newData?.at(index), contractSettings: { ...newData?.at(index).contractSettings, [field]: value } })
            setData(newData)
          }}
          label={row.contractSettings.paymentDaysType.slice(0, 3)}
        />
      default:
        return row?.[field] || '-'
    }
  }

  const renderCellExport = (row, field) => {
    if (!row.results.invoiceLastYear && field.includes('invoiceLastYear')) return "-"
    if (!row.results.invoiceLastMonth && field.includes('invoiceLastMonth')) return "-"
    switch (field) {
      case 'comments':
        return row?.comments?.[row?.comments?.length - 1]?.text
      case 'invoiceLastYear':
        return row.results.invoiceLastYearConsumptionForPeriod.toFixed(2) + " МВтч"
      case 'invoiceLastYear2':
        return "от " + row.results.invoiceLastYearConsumption.toFixed(2) + " МВтч"
      case 'invoiceLastYear3':
        return row.results.invoiceLastYearTotalForPeriod.toFixed(2) + " лв"
      case 'invoiceLastYear4':
        return "от " + row.results.invoiceLastYearTotal.toFixed(2) + " лв"
      case 'invoiceLastMonth':
        return row.results.invoiceLastMonthConsumptionForPeriod.toFixed(2) + " МВтч"
      case 'invoiceLastMonth2':
        return "от " + row.results.invoiceLastMonthConsumption.toFixed(2) + " МВтч"
      case 'invoiceLastMonth3':
        return row.results.invoiceLastMonthTotalForPeriod.toFixed(2) + " лв"
      case 'invoiceLastMonth4':
        return "от " + row.results.invoiceLastMonthTotal.toFixed(2) + " лв"
      case 'invoiceLastMonth5':
        return row.results.invoiceMonth ? moment(monthYear, 'MM/YYYYY').subtract(row.results.invoiceMonth === 'last' ? 1 : 2, 'months').format("MMMM") : ""
      case 'energy':
        return (parseFloat(row.results[field] || 0).toFixed(3) ?? '') + "МВтч"
      case 'energyPrice':
        return (row.results[field] ?? '') + "лв."
      case 'energyTotal':
        return ((parseFloat(row.results.energy || 0) * parseFloat(row.results.energyPrice)).toFixed(2)) + 'лв'
      case 'tzoPrice':
      case 'excisePrice':
        return ((parseFloat(row.results.energy || 0) * parseFloat(row.results[field])).toFixed(2)) + 'лв'
      case 'delivery':
      case 'totalWithoutVat':
      case 'total':
        return (parseFloat(row.results[field] || 0).toFixed(2)) + " лв"
      case 'paymentDays':
        return row.contractSettings[field] ?? 0 + ' ' + row.contractSettings.paymentDaysType
      case 'fullName':
        return row?.[field] ?? '--'
      default:
        return renderCell(row, field, {})
    }
  }

  const [filter, setFilter] = useState("")
  const dataFiltered = useMemo(() => {
    return data?.filter(({ fullName, eic, pin }) => {
      return !filter
        || fullName?.toLowerCase().includes(filter?.toLowerCase())
        || pin?.toLowerCase().includes(filter?.toLowerCase())
        || eic?.toLowerCase().includes(filter?.toLowerCase())
    })
  }, [data, filter])

  const [allFields, setAllFields] = useState(intermediateInvoicesGenerateFields)
  const intermediateInvoicesGenerateFieldsFiltered = useMemo(() => {
    return allFields
      .filter(({ hidden }) => !hidden)
      .filter(({ value }) => percentLastMonth
        ? !['invoiceLastYear', 'invoiceLastMonth', 'energy', 'energyPrice', 'energyTotal', 'delivery',].includes(value)
        : !['invoiceLastMonthTotalWithoutVat', 'energyPricePercent'].includes(value)
      )
  }, [allFields, percentLastMonth])
  const sortingComponents = allFields.reduce((acc, { value }, index) => {
    return {
      ...acc,
      [value]: <div className="icon icon-eye-custom" onClick={() => {
        const newFields = [...allFields]
        newFields.splice(index, 1, { ...allFields[index], hidden: true })
        setAllFields(newFields)
      }} />
    }
  }, {})

  return <div className="screen-intermediate-invoices-generate-container">
    <div className="screen-intermediate-invoices-generate-header row">
      {showErrors ? <h2>Преглед грешки</h2> : <>
        <h2>{typeMap[type]}</h2>
        <p>
          Тип: <span>{consecutiveNumberMap[consecutiveNumber]}</span>
        </p>
        <p>
          Месец: <span>{moment(monthYear, 'MM/YYYY').format('MMMM')}</span>
        </p>
        <p>Статус: <span>{statusMap[status]}</span></p>
        <p className="row">
          Единична Цена: <span>{price} {percentLastMonth ? "%" : "лв./МВтч"}</span> <span className="icon icon-edit" onClick={() => dispatch(setModal({
            isOpen: true, type: "intermediateInvoicesPrice", props: {
              title: 'Настройка единична цена',
              data: { date: moment(monthYear, 'MM/YYYY').format('DD.MM.YYYY'), text: price, type, consecutiveNumber, advanceFirstDate, percentLastMonth },
              validate: ({ text }) => text?.length,
              onClick: ({ text: price, date, type, consecutiveNumber, advanceFirstDate, percentLastMonth }) => handleUrlChangeMultiple({ price, monthYear: moment(date, 'DD.MM.YYYY').format('MM/YYYY'), type, consecutiveNumber, advanceFirstDate, percentLastMonth })
            }
          }))
          } />
        </p>
        <div className="row row-buttons">
          {!showErrors && !percentLastMonth && <div className="icon icon-export" onClick={() => xlsxExport({
            fields: intermediateInvoicesGenerateExportFields,
            data: dataRaw,
            render: renderCellExport
          })} />}
          <Button.Raised
            text='Генерирай фактури'
            disabled={isEmpty(selected)}
            onClick={handleGenerate}
          />
        </div>
      </>}
    </div>
    <div className="screen-intermediate-invoices-generate-second-header row">
      <Inputs.RoundedButtons
        buttons={Object.entries(statusMap).map(([value, label]) => ({ value, label }))}
        value={status}
        onChange={({ value }) => handleUrlChangeMultiple({ status: value })}
      />
      <div className="row row-filter-client">
        <span>Клиент: </span>
        <Inputs.TextLabeled
          placeholder="Име / ЕИК / ЕГН"
          value={filter || ""}
          onChange={({ target: { value } }) => setFilter(value)}
        />
        {filter && <div className="icon icon-close" onClick={() => setFilter("")} />}
      </div>
    </div>
    <div className="screen-intermediate-invoices-generate-content">
      {data && <Shared.Table
        columns={showErrors ? [
          { label: 'Име клиент', value: 'fullName' },
          { label: 'ЕИК/ЕГН', value: 'eic' },
          { label: 'Грешка', value: 'error' },
        ] : intermediateInvoicesGenerateFieldsFiltered}
        data={showErrors ? errors : dataFiltered || []}
        renderCell={renderCell}
        rowHeight={60}
        useCheckboxes={!showErrors}
        fixedWidth={!!showErrors}
        selectedCheckboxes={selected}
        disabledCheckboxes={errors?.map(({ _id }) => _id)}
        onCheckboxChange={(value) => setSelected(value)}
        outlines={data
          ?.filter(({ error, results: { energy, energyPrice, energyPricePercent } }) => !energy || (!energyPrice && !energyPricePercent) || error)
          ?.reduce((acc, { _id }) => ({ ...acc, [_id]: '#fa4444' }), {})
        }
        sortingComponent={sortingComponents}
      />}
    </div>
    <div className="screen-intermediate-invoices-generate-footer row">
      <div>  <p>За фактури: <span>{data?.length} клиента</span></p> </div>
      <div>  <p>Избрани: <span>{selected?.length ?? 0} клиента</span></p> </div>
      <div>  <p>Обща стойност: <span>{data?.reduce((acc, r) => acc + (+r?.results?.total ?? 0), 0).toFixed(2)} лв.</span></p> </div>
      <div className={`row ${errors?.length && "tab-errors"}`} onClick={() => errors?.length && handleUrlChangeMultiple({ 'showErrors': !showErrors })}>
        <p>Грешки: <span>{errors?.length ?? 0}</span></p>
        <div className="icon icon-arrow-right-2-white" />
      </div>
    </div>
  </div>
}

export default IntermediateInvoicesGenerate