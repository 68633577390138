import React, { useEffect, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, flatten } from 'lodash'
import {
  setModal,
  exportInvoicesGeneratedStats,
  startLoading,
  getReferenceInvoices,
  getReferenceObjectsIgnored,
  getReferenceNomenclatures,
  exportInvoicesAIGeneratedStats,
} from 'actions'
import { Inputs, References as ReferencesComp } from 'components'
import {
  referencesTypes,
  referencesReportsTypes,
  referencesInvoicesTypes,
  referencesInvoicesLength,
  referenceMarketLength,
  referenceInvoicesFields,
  referencesIntermediateInvoicesTypes,
  invoiceNumberTypes,
  referenceIgnoredObjectsFields,
  referenceNomenclaturesFields,
  invoicesSearchOptions,
} from 'config/constants'
import { useQuery } from 'hooks'
import { handleUploadedData, xlsxExport, renderCell, generateInvoiceInitial } from 'utilities'
import './styles.scss'

const TypeSelector = ({ referencesTypesFiltered }) => {
  const history = useHistory()
  const { type } = useParams()
  return <Inputs.Dropdown
    options={referencesTypesFiltered}
    value={type}
    onChange={({ value }) => history.push(`/references/${value}${value === 'reports' ? '?type=registrations' : ''}`)}
  />
}

const References = ({ }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { type } = useParams()

  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({ ...acc, ...Object.fromEntries(Object.entries(permissions).map(([key]) => [key, flatten(roles.map(({ permissions }) => permissions[key]))])) }), {}), [roles])
  const referencesTypesFiltered = useMemo(() => referencesTypes.filter(({ value }) => !permissions || ['all', value].some(p => permissions?.references?.includes(p))), [permissions])
  useEffect(() => { if (!referencesTypesFiltered.map(({ value }) => value).includes(type)) history.push('/references') }, [referencesTypesFiltered, type])

  const { filter: filterParam = '{}', searchBy = '', type: subType, length, startDate, endDate, period, showErrors, handleUrlChange, handleUrlChangeMultiple, clear } = useQuery()
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])

  const { reference, errors, selectedGroups } = useSelector((state) => state.references)

  if (['commissions'].includes(type)) return <ReferencesComp.Commissions TypeSelector={() => <TypeSelector referencesTypesFiltered={referencesTypesFiltered} />} />

  return (
    <div className={`screen-references-container ${type}`}>
      {showErrors ? (
        <div className="screen-references-header row" style={{}}>
          <h2>Преглед грешки</h2>
          <div
            className="icon icon-export"
            onClick={() =>
              xlsxExport({
                fields: referenceInvoicesFields.errors,
                data: errors,
                render: (row, value) => {
                  if (['eic'].includes(value)) return row?.client?.[value] || row?.client?.pin || ''
                  else if (['fullName'].includes(value)) return row?.client?.[value]
                  else if (['itn', 'error'].includes(value)) return row?.[value]
                  else row?.client?.[value] || row?.[value] || ''
                },
              })
            }
          />
        </div>
      ) : (
        <div className="screen-references-header row">
          <h2>Справка за</h2>
          <TypeSelector referencesTypesFiltered={referencesTypesFiltered} />
          {type === 'employees' && (
            <Button.Raised
              text="Добави потребител"
              className="btn-add-employee"
              onClick={() => dispatch(setModal({ isOpen: true, type: 'newEmployee' }))}
            />
          )}
          {type === 'reports' && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesReportsTypes}
                value={subType}
                onChange={({ value }) => { clear(); handleUrlChangeMultiple({ type: value }) }}
              />
              {subType === 'registrations' && <>
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate, 'MM/YYYY').toDate() : moment().toDate()}
                  onChange={(value) => handleUrlChange('startDate', moment(value).format('MM/YYYY'))}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
              </>}
              {subType === 'trade' && <div className='row' style={{ marginLeft: 'auto' }}>
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate, 'MM/YYYY').toDate() : moment().subtract(2, 'months').toDate()}
                  onChange={(value) => handleUrlChange('startDate', moment(value).format('MM/YYYY'))}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
                <Inputs.DatePicker
                  selected={endDate ? moment(endDate, 'MM/YYYY').toDate() : moment().subtract(1, 'months').toDate()}
                  onChange={(value) => handleUrlChange('endDate', moment(value).format('MM/YYYY'))}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
              </div>}
            </>
          )}
          {type === 'invoices' && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesInvoicesTypes}
                value={subType}
                onChange={({ value }) => {
                  if (value === 'generated') clear()
                  handleUrlChangeMultiple({ type: value, length: value === 'generated' ? 'month' : null })
                }}
              />
              {subType && (
                <>
                  {subType === 'generated' && (
                    <Inputs.RadioButtons
                      buttons={referencesInvoicesLength}
                      value={length}
                      //prettier-ignore
                      onClick={(value) => {
                        handleUrlChange('length', value)
                        if (value === 'all') handleUrlChangeMultiple({ startDate: null, endDate: null })
                        else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: new Date(), endDate: new Date() })
                      }}
                    />
                  )}
                  {[undefined, 'month'].includes(length) && (
                    <Inputs.DatePicker
                      selected={startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()}
                      onChange={(value) => handleUrlChange('startDate', value)}
                      maxDate={new Date()}
                      dateFormat={'MMMM'}
                      showMonthYearPicker
                      showFullMonthYearPicker
                      className="month-picker"
                    />
                  )}
                  <div className="row row-buttons">
                    {subType === 'generated' && length === 'month' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          dispatch(startLoading())
                          dispatch(
                            exportInvoicesGeneratedStats({
                              payload: {
                                from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf("month").toDate(),
                                to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').endOf("month").add(1, 'milliseconds').toDate(),
                              },
                            })
                          )
                        }}
                      />
                    )}
                    {subType === 'not-generated' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          dispatch(startLoading())
                          dispatch(
                            getReferenceInvoices({
                              payload: {
                                type: "not-generated",
                                forExport: true,
                                from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf("month").toDate(),
                                to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').endOf("month").add(1, 'milliseconds').toDate(),
                              },
                            })
                          )
                        }}
                      />
                    )}
                    {subType === 'ready' && (
                      <div
                        className="icon icon-export"
                        onClick={() => {
                          xlsxExport({
                            fields: referenceInvoicesFields.ready,
                            data: reference,
                            render: renderCell.references.invoices.readyExport,
                          })
                        }}
                      />
                    )}
                    {subType === 'differences' && (
                      <div
                        className="icon icon-export"
                        onClick={() =>
                          xlsxExport({
                            fields: referenceInvoicesFields.differences,
                            data: reference,
                            render: renderCell.references.invoices.differencesExport,
                          })
                        }
                      />
                    )}
                    <Button.Raised
                      className="btn-invoices-generate"
                      text="Генерирай"
                      disabled={subType !== 'ready' || isEmpty(selectedGroups)}
                      onClick={() => {
                        if (subType === 'ready') generateInvoiceInitial({
                          ids: selectedGroups,
                          from: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').toDate(),
                          to: moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').add(1, 'months').toDate(),
                          onSuccess: () => history.push(`/financial/invoices?type=equalizing&month=${moment(startDate).subtract(!startDate ? 1 : 0, 'months').startOf('month').format('MM.YYYY')}`)
                        })
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {type === "intermediateInvoices" && (
            <>
              <Inputs.RoundedButtons
                buttons={referencesIntermediateInvoicesTypes}
                value={subType}
                onChange={({ value }) => {
                  if (value === 'generated') clear()
                  handleUrlChangeMultiple({ type: value, length: value === 'generated' ? 'month' : null })
                }}
              />
              {subType && <>
                {subType === 'generated' && (
                  <Inputs.RadioButtons
                    buttons={referencesInvoicesLength}
                    value={length}
                    //prettier-ignore
                    onClick={(value) => {
                      handleUrlChange('length', value)
                      if (value === 'all') handleUrlChangeMultiple({ startDate: null, endDate: null })
                      else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: new Date(), })
                    }}
                  />
                )}
                {[undefined, 'month'].includes(length) && (
                  <Inputs.DatePicker
                    selected={startDate ? new Date(startDate) : moment().toDate()}
                    onChange={(value) => handleUrlChange('startDate', value)}
                    maxDate={new Date()}
                    dateFormat={'MMMM'}
                    showMonthYearPicker
                    showFullMonthYearPicker
                    className="month-picker"
                  />
                )}
              </>}
              <div className="row row-buttons">
                {subType === 'generated' && length === 'month' && <div className="icon icon-export" onClick={() => {
                  dispatch(startLoading())
                  dispatch(exportInvoicesAIGeneratedStats({
                    payload: {
                      from: moment(startDate).startOf("month").toDate(),
                      to: moment(startDate).endOf("month").add(1, 'milliseconds').toDate(),
                    },
                  }))
                }} />}
                <Button.Raised text='Генерирай' onClick={() => dispatch(setModal({
                  isOpen: true, type: 'intermediateInvoicesPrice', props: {
                    title: 'Настройка единична цена',
                    data: { date: startDate || moment().format('DD.MM.YYYY') },
                    validate: ({ text }) => text?.length,
                    onClick: ({ text: price, date: month, type, consecutiveNumber, advanceFirstDate, percentLastMonth }) => {
                      const url = new URLSearchParams()
                      url.append('type', type)
                      url.append('consecutiveNumber', consecutiveNumber)
                      url.append('advanceFirstDate', advanceFirstDate)
                      url.append('price', price)
                      url.append('monthYear', moment(month, 'DD.MM.YYYY').format('MM/YYYY'))
                      url.append('status', 'active')
                      if (percentLastMonth) url.append('percentLastMonth', percentLastMonth)
                      history.push('/intermediate-invoices/generate?' + url.toString())
                    }
                  }
                }))} />
              </div>
            </>
          )}
          {type === 'imports' && (
            <>
              <Inputs.RadioButtons
                buttons={referencesInvoicesLength}
                value={length}
                onClick={(value) => {
                  handleUrlChange('length', value)
                  if (value === 'all') handleUrlChangeMultiple({ startDate: null })
                  else if (['month', 'period'].includes(value)) handleUrlChangeMultiple({ startDate: moment().startOf('month').format('MM/YYYY') })
                }}
              />
              {['month'].includes(length) && (
                <Inputs.DatePicker
                  selected={startDate ? moment(startDate, 'MM/YYYY').toDate() : new Date()}
                  onChange={(value) => handleUrlChange('startDate', moment(value).format('MM/YYYY'))}
                  maxDate={new Date()}
                  dateFormat={'MM.yyyy'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
              )}
            </>
          )}
          {type === 'market' && (
            <>
              <Inputs.RadioButtons
                buttons={referenceMarketLength}
                value={period}
                onClick={(value) =>
                  handleUrlChangeMultiple({
                    period: value,
                    startDate: moment().startOf(value).toDate(),
                    endDate: moment().endOf(value).toDate(),
                  })
                }
              />
              {period === 'year' ? (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  showYearPicker
                  yearItemNumber={3}
                  className="year-picker"
                />
              ) : period === 'month' ? (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                  dateFormat={'MM.yyyy'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              ) : (
                <Inputs.DatePicker
                  selected={new Date(startDate || moment().toDate())}
                  onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf(period).toDate() })}
                  maxDate={new Date()}
                />
              )}
              <Button.UploadButton
                text="Качи файлове"
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type })}
              />
            </>
          )}
          {type === 'nomenclatures' && (
            <div className='row row-buttons'>
              <div
                className="icon icon-export"
                onClick={() => dispatch(getReferenceNomenclatures({
                  onSuccess: ({ details }) => xlsxExport({
                    fields: [{ label: "ЕРП", value: 'erp' }, ...referenceNomenclaturesFields],
                    data: details,
                    render: renderCell.references.nomenclatures,
                  })
                }))}
              />
              <Button.UploadButton
                text="Качи файлове"
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => handleUploadedData({ files, dispatch, history, type })}
              />
            </div>
          )}
          {type === 'compensationSettings' && (
            <div
              className="icon icon-add"
              onClick={() => dispatch(setModal({
                isOpen: true, type: "compensationSettings", props: {
                  onSuccess: () => {
                    dispatch(setModal({ isOpen: false }))
                    handleUrlChangeMultiple({ toFetch: true })
                  }
                }
              }))}
            />
          )}
          {type === 'invoiceNumber' && (
            <>
              <Inputs.Dropdown
                options={invoiceNumberTypes}
                value={subType}
                onChange={({ value }) => {
                  clear()
                  handleUrlChangeMultiple({ type: value })
                }}
              />
              {['ajur', 'ajurProducer'].includes(subType) && <div className='row' style={{ marginLeft: 'auto' }}>
                <Inputs.DatePicker
                  selected={startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()}
                  onChange={(value) => handleUrlChange('startDate', value)}
                  maxDate={new Date()}
                  dateFormat={'MMMM'}
                  showMonthYearPicker
                  showFullMonthYearPicker
                  className="month-picker"
                />
                <Button.UploadButton
                  text="Импорт" accept={'.csv, .xlsx, .xls'}
                  onChange={({ target: { files } }) => {
                    handleUploadedData({
                      files,
                      dispatch,
                      history,
                      type: subType === 'ajur' ? 'ajurNumbers' : 'ajurProducersNumbers',
                      additionalProps: {
                        startDate: startDate ? new Date(startDate) : moment().subtract(1, 'months').toDate()
                      },
                    })
                  }} />
              </div>}
            </>

          )}
          {type === 'objectsIgnored' && (
            <div
              className="icon icon-export"
              onClick={() => {
                dispatch(startLoading())
                dispatch(getReferenceObjectsIgnored({
                  payload: { pagination: false },
                  onSuccess: ({ docs }) => xlsxExport({
                    fields: referenceIgnoredObjectsFields,
                    data: docs,
                    render: renderCell.references.objectsIgnoredExport,
                  })
                }))
              }}
            />
          )}
          {type === 'clientsGroups' && <>
            <Inputs.SearchMulti
              search={filter?.[searchBy] || ''}
              searchBy={searchBy || 'eic'}
              handleChange={({ search, searchBy: newSearchBy }) => {
                const newFilter = { ...filter, [searchBy]: undefined, [newSearchBy]: search || undefined }
                handleUrlChangeMultiple({
                  searchBy: search ? newSearchBy : undefined,
                  filter: Object.values(newFilter).filter(Boolean).length ? JSON.stringify(newFilter) : undefined
                })
              }}
              options={invoicesSearchOptions}
              popupText={{ secondary: ' ' }}
            />
            <div className="row row-buttons">
              <div className="icon icon-add" onClick={() => dispatch(setModal({ isOpen: true, type: 'clientsGroupsForm' }))} />
            </div>
          </>}
        </div>
      )}
      <div className="screen-references-content row">
        {!type && <p className="empty">Изберете вида справка, която искате да визуализирате</p>}
        {type === 'employees' ? (
          <ReferencesComp.Employees />
        ) : type === 'reports' ? (
          subType === 'registrations' ? (
            <ReferencesComp.ReportsRegistration />
          ) : subType === 'trade' ? (
            <ReferencesComp.ReportsTrade />
          ) : subType === 'portfolio' ? (
            <ReferencesComp.ReportsPortfolio />
          ) : null
        ) : type === 'market' ? (
          <ReferencesComp.Market />
        ) : type === 'invoices' ? (
          <ReferencesComp.Invoices />
        ) : type === 'imports' ? (
          <ReferencesComp.Imports />
        ) : type === 'nomenclatures' ? (
          <ReferencesComp.Nomenclatures />
        ) : type === 'intermediateInvoices' ? (
          <ReferencesComp.IntermediateInvoices />
        ) : type === 'compensationSettings' ? (
          <ReferencesComp.CompensationSettings />
        ) : type === 'invoiceNumber' ? (
          <ReferencesComp.InvoiceNumber />
        ) : type === 'objectsIgnored' ? (
          <ReferencesComp.ObjectsIgnored />
        ) : type === 'clientsGroups' ? (
          <ReferencesComp.ClientsGroups />
        ) : type === 'permissions' ? (
          <ReferencesComp.Permissions />
        ) : null}
      </div>
    </div>
  )
}

export default References
