import { Alerts, Api } from "@makedonski/socourt-utilities"
import { producersTypes, stopLoading } from "actions"
import { producersInvoicesFields, producersReferencesExportFields, producersReferencesFields } from "config/constants"
import { URL } from "config/settings"
import moment from "moment"
import { ofType, ActionsObservable } from "redux-observable"
import { catchError, switchMap } from "rxjs/operators"
import { exportBatchFiles, renderCell, transformURL, xlsxExport } from "utilities"



export const producersReferencesReady = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_READY),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/ready`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)


export const producersReferencesGeneration = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_GENERATION),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/generation`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersReferencesBrowse = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_BROWSE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (payload.forExport === 'excel_1') {
                    const indexToInsert = producersReferencesFields.findIndex(({ value }) => value === 'invoiceNumber')
                    const fields = [...producersReferencesFields]
                    fields.splice(indexToInsert + 1, 0, { label: 'ФЕ - Дата', value: 'templatedInvoiceDate' })
                    if (process.env.REACT_APP_PLATFORM === 'Synergon') {
                        fields.push({ label: 'РБ Група', value: 'balancingOverwriteTemp1' },)
                        fields.push({ label: 'Отстъпка', value: 'balancingOverwriteTemp2' },)
                        fields.push({ label: 'РБ Краен', value: 'balancingOverwriteTemp3' },)
                    }
                    xlsxExport({
                        fields,
                        data: response?.results?.docs,
                        render: renderCell.producersReferencesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport === 'excel_2') {
                    xlsxExport({
                        fields: producersReferencesExportFields,
                        data: response?.results?.docs,
                        render: renderCell.producersReferencesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport === 'templatedInvoice') {
                    if (response?.results?.docs?.length) exportBatchFiles(response?.results?.docs?.map((doc) => ({ url: transformURL(doc[payload.forExport].pdfFile), name: `${doc.clientData.fullName}_${doc.templatedInvoice.invoiceNumber}_${doc?.templatedInvoice?.dateOfIssue ? moment(doc?.templatedInvoice?.dateOfIssue).format('DD.MM.YYYY') : moment(doc.monthYear, 'MM/YYYY').endOf('month').format('DD.MM.YYYY')}.${doc[payload.forExport].pdfFile.split('.').pop()}` })))
                    else obs.next(stopLoading())
                } else if (payload.forExport) {
                    if (response?.results?.docs?.length) exportBatchFiles(response?.results?.docs?.map((doc) => ({ url: doc[payload.forExport] })))
                    else obs.next(stopLoading())
                } else {
                    if (onSuccess) onSuccess(response?.results)
                    obs.next(stopLoading())
                    obs.next({ type: producersTypes.PRODUCERS_REFERENCES_BROWSE_SUCCESS, payload: response?.results, })
                }
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersReferenceUpdateRestricted = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_UPDATE_RESTRICTED),
    switchMap(({ payload, onSuccess, stopLoading: stopLoadingBoolean }) =>
        Api.put(`${URL}/producers/editRestricted`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                if (stopLoadingBoolean) obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersReferenceUpdate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_UPDATE),
    switchMap(({ payload, onSuccess, stopLoading: stopLoadingBoolean }) =>
        Api.put(`${URL}/producers/edit`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.producerStat)
                if (stopLoadingBoolean) obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersReferenceUpdateMany = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_UPDATE_MANY),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/producers/editMany`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)


export const producersReferenceSingle = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_SINGLE),
    switchMap(({ payload, onSuccess, onError }) =>
        Api.post(`${URL}/producers/getSingle`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.payload)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                if (onError) onError(err?.response?.error?.message)
                else Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const producersReferenceDelete = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCE_DELETE),
    switchMap(({ payload, onSuccess, onError }) =>
        Api.delete(`${URL}/producers`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                if (onError) onError(err?.response?.error?.message)
                else Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        ))
)

export const producersReferencesSendEmails = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_REFERENCES_SEND_EMAILS),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/send`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersInvoicesBrowse = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICES_BROWSE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/invoices/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (payload.exportExcel) {
                    xlsxExport({
                        fields: [...producersInvoicesFields,
                        { label: 'Ажур (К/Б)', value: 'ajur' },
                        { label: 'Ажур (П)', value: 'ajurProducer' },
                        { label: 'Анулирана', value: 'deletedAt' },
                        { label: 'ИТН', value: 'itn' },
                        { label: 'Име на обект', value: 'description' },
                        ...(process.env.REACT_APP_PLATFORM === 'Synergon' ? [
                            { label: 'Корекция', value: 'correction' },
                            { label: 'Сума за възстановяване', value: 'returnValue' },
                        ] : [])
                        ],
                        data: response?.docs,
                        render: renderCell.producersInvoicesExport
                    })
                    obs.next(stopLoading())
                } else if (payload.forExport) {
                    exportBatchFiles(response?.data?.map(({ url, name }) => ({ url, name: process.env.REACT_APP_PLATFORM === 'Synergon' ? `${name.split("_").at(-2)}.pdf` : name })))
                } else {
                    if (onSuccess) onSuccess(response)
                    obs.next(stopLoading())
                    obs.next({ type: producersTypes.PRODUCERS_INVOICES_BROWSE_SUCCESS, payload: response, })
                }
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            })
            )
        )
    )
)

export const producersInvoiceCreate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICE_CREATE),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/invoiceCreate`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersInvoiceUpdate = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICE_UPDATE),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/producers/invoiceUpdate`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response?.results)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const handleProducersTemplatedInvoice = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_TEMPLATED_INVOICE),
    switchMap(({ payload, onSuccess, onError }) =>
        Api.post(`${URL}/producers/templatedInvoice`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                if (onError) onError(err?.response?.error?.message)
                else Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersCheckClientInvoices = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_CHECK_CLIENT_INVOICES),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/checkClientInvoices`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (!response.success) obs.next(stopLoading())
                if (onSuccess) onSuccess(response)
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const producersInvoiceGenerateManual = (action$) => action$.pipe(
    ofType(producersTypes.PRODUCERS_INVOICE_GENERATE_MANUAL),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/producers/invoiceGenerateManual`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)