import React, { useEffect, useState, useCallback, useMemo } from "react"
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import { getUserDaysOffByUser, startLoading } from "actions"
import moment from "moment"
import { screenEmployeesDetailsFields } from "config/constants"
import { renderCell } from "utilities"
import { Shared } from "components"

const UserDaysOff = ({ hide, user, month }) => {
    const { currentUser } = useSelector(({ general }) => general) || {}
    const isMaster = useMemo(() => currentUser?.roles?.map(({ name }) => name).includes('master'), [currentUser])

    const { _id, fullName } = user ?? {}
    const dispatch = useDispatch()
    const [state, setState] = useState(null)
    const { daysOff, types } = state ?? {}
    const fetch = useCallback(() => { dispatch(startLoading()); dispatch(getUserDaysOffByUser({ payload: { user: _id, month }, onSuccess: setState })) }, [month, _id])
    useEffect(() => { if (!state) { fetch() } }, [state, fetch])

    return <div className="card-user-days-off-container">
        <div className="card-user-days-off-header row">
            <h2>Отсъствия {moment(month, 'MM/YYYY').format('YYYY')} - {fullName}</h2>
            <div className="icon icon-close" onClick={hide} />
        </div>
        <div className="card-user-days-off-subheader row">
            <p>Общо: <span>{Object.entries(types ?? {}).filter(([key]) => !['homeOffice'].includes(key)).reduce((acc, [, cur]) => acc + cur, 0)}</span></p>
            <p>П: <span>{types?.paid ?? '0'}</span></p>
            <p>Н: <span>{types?.unpaid ?? '0'}</span></p>
            <p>Б: <span>{types?.sick ?? '0'}</span></p>
            <p>Х: <span>{types?.homeOffice ?? '0'}</span></p>
            <p>Д: <span>{types?.other ?? '0'}</span></p>
        </div>
        <div className="card-user-days-off-content">
            <Shared.Table
                rowHeight={60}
                columns={screenEmployeesDetailsFields}
                data={daysOff}
                renderCell={(row, field, options) => renderCell.employeesDetails(row, field, { ...options, fetch, isMaster, currentUser, length: 0, toggleRowExpanded: () => { }, })}
                fixedWidth
            />
        </div>
    </div>
}

export default UserDaysOff