import React, { useMemo } from "react"
import "./styles.scss"
import moment from "moment"
import { Shared } from "components"
import { partition } from "lodash"
import { renderCell, xlsxExport } from "utilities"
import { Line } from "react-chartjs-2"

const EsoLoadData = ({ hide, esoLoadData }) => {
    const { data, createdAt, total } = esoLoadData[esoLoadData.length - 1]

    const [table1Data, table2Data] = partition(data, ({ name }) => name.includes('Scada:'))

    const [labels, dataset] = useMemo(() => esoLoadData.reduce(([labels, dataset], { total, createdAt }) => [[...labels, moment(createdAt).format('HH:mm')], [...dataset, total]], [[], []]), [esoLoadData])

    const handleExport = () => xlsxExport({
        fields: [{ label: 'Тотал', value: 'total' }, { label: 'Дата', value: 'createdAt' },],
        data: esoLoadData,
        render: (row, field) => {
            if (field === 'createdAt') return moment(row[field]).format('HH:mm | DD.MM.YYYY')
            return renderCell.default(row, field)
        }
    })

    return <div className="card-eso-load-data-container col">
        <div className="card-eso-load-data-header row">
            <h2>Данни ЕСО</h2>
            <div className="row row-buttons">
                <div className="icon icon-export" onClick={handleExport} />
                <div className="icon icon-close" onClick={hide} />
            </div>
        </div>
        <div className="card-eso-load-data-additional-header row">
            <p>Тотал: <span>{total}</span></p>
            <p>Последно обновено: <span>{moment(createdAt).format('HH:mm | DD.MM.YYYY')}</span></p>
        </div>
        <div className="card-eso-load-data-content">
            <div className="graph-container">
                <Line
                    height={100}
                    data={{ labels, datasets: [{ type: 'line', backgroundColor: '#E87A1E', borderColor: '#E87A1E', data: dataset, },] }}
                    options={{
                        plugins: { legend: { display: false } },
                        scales: {
                            x: { grid: { drawOnChartArea: false } },
                            y: {
                                grid: {
                                    lineWidth: (context) => context.tick.value === 0 ? 2 : 1,
                                    color: (context) => context.tick.value === 0 ? '#E87A1E' : 'rgba(0, 0, 0, 0.1)'
                                }
                            }
                        }
                    }}
                />
            </div>
            <h3>Генерация на ЕЕС</h3>
            <div>
                <Shared.Table
                    columns={[{ label: 'Име', value: 'name' }, { label: 'MW', value: 'value' }]}
                    data={table2Data}
                    fixedWidth
                />
            </div>
            <h3>Износ</h3>
            <div>
                <Shared.Table
                    columns={[{ label: 'Име', value: 'name' }, { label: 'MW', value: 'value' }]}
                    data={table1Data}
                    fixedWidth
                    renderCell={(row, field) => {
                        const nameMap = { 'Scada: Romania': 'Румъния', 'Scada: Serbia': 'Сърбия', 'Scada: Macedonia': 'Македония', 'Scada: Greece': 'Гърция', 'Scada: Turkey': 'Турция', }
                        if (field === 'name') return nameMap[row[field]] ?? row[field]
                        else return renderCell.default(row, field)
                    }}
                />
            </div>
        </div>
    </div>
}

export default EsoLoadData