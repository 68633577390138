import React, { useState, useEffect } from 'react'
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { setOverlay, getClientActivity } from 'actions'
import { ClientHistorySingle } from './'
import './styles.scss'

const ClientHistory = ({ client, activity: activityProp }) => {
  const dispatch = useDispatch()
  const [activity, setActivity] = useState(activityProp ?? [])
  useEffect(() => {
    if (!activityProp) dispatch(getClientActivity({ payload: client?._id, onSuccess: (res) => setActivity(res) }))
  }, [activityProp])

  return (
    <div className="card-client-history-container">
      <div className="card-client-history-header row">
        <h3>История</h3>
        <Button.Icon name="plus" size={28} onClick={() => dispatch(setOverlay({ isOpen: false }))} />
      </div>
      <div className="card-client-history-content">
        <div className="border"></div>
        {activity?.map((activity) => (
          <ClientHistorySingle key={`event-${activity?._id}`} activity={activity} />
        ))}
      </div>
    </div>
  )
}

export default ClientHistory
