import React, { useEffect, useCallback } from "react"
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
    startLoading,
    setReferencesFields,
    getReferenceIntermediateInvoicesGenerated,
    getReferenceIntermediateInvoicesProgress,
} from 'actions'
import { referenceIntermediateInvoicesFields, intermediateInvoicesTypes } from 'config/constants'
import { Shared } from 'components'
import { useQuery } from 'hooks'
import "./styles.scss"
import { initialState } from "reducers/references"
import { renderCell } from "utilities"

const IntermediateInvoices = ({ }) => {
    const dispatch = useDispatch()
    const { reference } = useSelector(({ references }) => references)

    const { type, length, startDate } = useQuery()

    const fetch = useCallback(() => {
        if (!type) return
        dispatch(startLoading())
        if (type === 'generated') dispatch(getReferenceIntermediateInvoicesGenerated({
            payload: {
                type: "AI",
                from: length === "all" ? undefined : moment(startDate).startOf("month").toDate(),
                to: length === "all" ? undefined : moment(startDate).endOf("month").toDate(),
            }
        }))
        if (type === 'progress') dispatch(getReferenceIntermediateInvoicesProgress({
            payload: { "monthYear": moment(startDate).format('MM/YYYY'), }
        }))
    }, [dispatch, type, length, startDate])

    useEffect(() => {
        fetch()
        return () => dispatch(setReferencesFields(initialState))
    }, [fetch])

    return <div className="references-intermediate-invoices-container">
        <div className="references-intermediate-invoices-content">
            <Shared.Table
                fixedWidth
                columns={referenceIntermediateInvoicesFields[type]}
                data={reference || []}
                renderCell={(row, field) => {
                    if (type === 'generated' && ['totalPrice', 'totalIntermediate', 'totalAdvance', 'vat'].includes(field)) return `${row?.[field]} лв.`
                    else if (type === 'progress' && ['type'].includes(field)) return intermediateInvoicesTypes.find(({ value }) => value === row?.[field])?.label
                    else return renderCell.default(row, field)

                }}
            />
        </div>
        <div className="references-intermediate-invoices-footer row">
        </div>
    </div>
}

export default IntermediateInvoices
