import React from 'react'
import { Tasks as TasksComponents } from '../../components'
import { URL } from 'config/settings'
import './styles.scss'
import { useSelector } from 'react-redux'

const Tasks = ({ }) => {
  const { currentUser } = useSelector(({ general }) => general)
  if (!currentUser) return null
  return (
    <div className="screen-tasks-container col">
      <TasksComponents URL={URL} />
    </div>
  )
}

export default Tasks
