// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputs-number-range-container .input-range {
  margin: auto;
  width: 80%;
  padding: 20px 0;
}
.inputs-number-range-container .input-range .input-range__label {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/components/Inputs/NumberRange/styles.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,UAAA;EACA,eAAA;AAAR;AACQ;EACI,WAAA;AACZ","sourcesContent":[".inputs-number-range-container {\r\n    .input-range {\r\n        margin: auto;\r\n        width: 80%;\r\n        padding:  20px 0;\r\n        .input-range__label {\r\n            color: #000;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
