import React, { useState, useEffect, forwardRef, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { useDispatch } from 'react-redux'
import { updateClient, getInvoicesClient } from 'actions'
import { Shared, Client, Inputs } from 'components'
import { clientInvoicesFields, IBANRegex, invoiceReferencesOptions, producerBanksOptions, producersReferencesOptions } from 'config/constants'
import { checkIsDealer, renderCell } from 'utilities'
import './styles.scss'

const Invoices = forwardRef(({ id, client, editable, setEditable }, ref) => {
  const isDealer = checkIsDealer()

  const dispatch = useDispatch()
  const [data, setData] = useState(client?.invoiceSettings)
  const [ajur, setAjur] = useState(client.ajur)
  const [ajurProducer, setAjurProducer] = useState(client.ajurProducer)
  const handleChange = (field, value) => setData({ ...data, [field]: value })
  const handleReferenceChange = (field, value) =>
    handleChange('invoiceReference', { ...data?.invoiceReference, [field]: value })
  useEffect(() => { setData(client?.invoiceSettings) }, [client])
  useEffect(() => { setAjur(client?.ajur) }, [client])
  useEffect(() => { setAjurProducer(client?.ajurProducer) }, [client])

  useEffect(() => { dispatch(getInvoicesClient({ client: id })) }, [])

  const onClear = () => {
    setEditable(false)
    setData(client?.invoiceSettings)
  }


  const [showRequired, setShowRequired] = useState(false)
  const isValid = () => {
    if (client.clientType !== 'Консуматор' && !data.producerIndividualSettingsByObject && producersReferencesOptions.every(({ value }) => !data.invoiceReference?.[value])) {
      Alerts.error('Моля включете поне една справка за производител')
      return
    }
    if (data?.producerIBAN && !IBANRegex.test(data?.producerIBAN)) {
      Alerts.error('Невалиден IBAN')
      return
    }
    return true
  }
  const onSave = () => {
    if (!isValid()) setShowRequired(true)
    else dispatch(updateClient({ data: { ...client, ajur, ajurProducer, invoiceSettings: data }, onSuccess: () => setEditable(false) }))
  }
  const isIBAN = (IBAN) => IBAN && showRequired && !IBANRegex.test(IBAN) && 'required'

  if (ref) ref.current = { onClear, onSave }



  const consumerSettings = () => <div className='client-invoices-consumer-container'>
    <div className="client-invoices-details">
      <h2>Детайли фактури - Консуматор</h2>
      {!isDealer && <div className="row">
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Комисионна към надбавка</span>
          <Button.Switch
            isOn={data?.commission}
            onChange={() => editable && handleChange('commission', !data?.commission)}
          />
        </div>
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Специални условия фактури</span>
          <Button.Switch
            isOn={data?.intermediateInvoicingSpecial}
            onChange={() => editable && handleChange('intermediateInvoicingSpecial', !data?.intermediateInvoicingSpecial)}
          />
        </div>
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Факторинг</span>
          <Button.Switch
            isOn={data?.factoring}
            onChange={() => editable && handleChange('factoring', !data?.factoring)}
          />
        </div>
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Не фактурирай лихви</span>
          <Button.Switch
            isOn={data?.doNotCalculateInterests}
            onChange={() => editable && handleChange('doNotCalculateInterests', !data?.doNotCalculateInterests)}
          />
        </div>
      </div>}
      <Client.Forms.IntermediatePayments
        editable={editable}
        data={data}
        setData={(payload) => setData({ ...data, ...payload })}
      />
    </div>
    {!isDealer && <div className="client-invoices-settings">
      <h2>Справки</h2>
      <div className="row">
        {invoiceReferencesOptions?.map(({ label, value }, i) => (
          <div key={`switch-${i}`} className={`col col-switch ${!editable && 'disabled'}`}>
            <span>{label}</span>
            <Button.Switch
              isOn={data?.invoiceReference?.[value]}
              onChange={() => editable && handleReferenceChange(value, !data?.invoiceReference?.[value])}
            />
          </div>
        ))}
      </div>
    </div>}
  </div>

  const producerSettings = () => <div className="client-invoices-producer-container">
    <div className="client-invoices-details">
      <h2>Детайли фактури - Производител</h2>
      <div className="row">
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Комисионна към надбавка</span>
          <Button.Switch
            isOn={data?.commissionProducer}
            onChange={() => editable && handleChange('commissionProducer', !data?.commissionProducer)}
          />
        </div>
        {!data?.producerIndividualSettingsByObject && <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Фактура за балансиране</span>
          <Button.Switch
            isOn={data?.producerInvoiceBalancing}
            onChange={() => editable && handleChange('producerInvoiceBalancing', !data?.producerInvoiceBalancing)}
          />
        </div>}
        <div className="col">
          <span>Вид фактуриране</span>
          <Inputs.RadioButtons
            buttons={[
              { label: 'Веднъж месечно', value: 'monthly' },
              { label: 'Междинно', value: 'intermediate' },
            ]}
            value={data?.producerInvoicingType}
            onClick={(value) => handleChange('producerInvoicingType', value)}
            disabled={!editable}
          />
        </div>
        {data?.producerInvoicingType === 'intermediate' && <div className="col">
          <span>Фактуриране на</span>
          <Inputs.TextLabeled
            label="число"
            value={data?.producerInvoicingIntermediateDate}
            onChange={({ target: { value } }) => handleChange('producerInvoicingIntermediateDate', value)}
            disabled={!editable}
          />
        </div>}
        <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Примерна фактура</span>
          <Button.Switch
            isOn={data?.templatedInvoice}
            onChange={() => editable && handleChange('templatedInvoice', !data?.templatedInvoice)}
          />
        </div>
        {!isDealer && <div className={`col col-switch ${!editable && 'disabled'}`}>
          <span>Протокол за прихващане</span>
          <Button.Switch
            isOn={data?.setOffAgreement}
            onChange={() => editable && handleChange('setOffAgreement', !data?.setOffAgreement)}
          />
        </div>}
      </div>
    </div>
    {!isDealer && <div className="client-invoices-settings">
      <div className="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
        <h2>Справки</h2>
        <div className={`row col-switch ${!editable && 'disabled'}`}>
          <span>Приложими за всички точки</span>
          <Button.Switch isOn={!data?.producerIndividualSettingsByObject} onChange={() => editable && handleChange('producerIndividualSettingsByObject', !data?.producerIndividualSettingsByObject)} />
        </div>
      </div>
      {!data?.producerIndividualSettingsByObject && <div className="row">
        {producersReferencesOptions?.map(({ label, value }, i) => (
          <div key={`switch-${i}`} className={`col col-switch ${!editable && 'disabled'}`}>
            <span>{label}</span>
            <Button.Switch
              isOn={data?.invoiceReference?.[value]}
              onChange={() => editable && handleReferenceChange(value, !data?.invoiceReference?.[value])}
            />
          </div>
        ))}
      </div>}
    </div>}
    <div className="client-invoices-settings">
      <h2>Настройки справки</h2>
      <div className="row">
        <div className="col">
          <span>Справка за</span>
          <Inputs.RadioButtons
            buttons={[
              { label: 'Измерено', value: 'measured' },
              { label: 'Прогноза', value: 'forecast' },
            ]}
            value={data?.producerStatsFor}
            onClick={(value) => handleChange('producerStatsFor', value)}
            disabled={!editable}
          />
        </div>
      </div>
    </div>
    <div className="client-invoices-settings">
      <h2>Банкова сметка</h2>
      <div className="row">
        <div className="col">
          <span>Банка</span>
          <Inputs.Dropdown
            options={producerBanksOptions.map((value) => ({ label: value, value }))}
            value={data?.producerBank}
            onChange={({ value }) => handleChange('producerBank', value)}
            disabled={!editable}
          />
        </div>
        <div className="col">
          <span>IBAN</span>
          <Input.Text
            value={data?.producerIBAN}
            onChange={({ target: { value } }) => handleChange('producerIBAN', value)}
            disabled={!editable}
            inputClassName={`${isIBAN(data?.producerIBAN)}`}
          />
        </div>
      </div>
    </div>
  </div>

  const ajurSettings = () => <div className='client-invoices-ajur-container'>
    <div className="client-invoices-details">
      <h2>Детайли Ажур</h2>
      <div className="row">
        <div className="col">
          <span>Контрагент в Ажур за доставка на енергия и/или за фактури за балансиране</span>
          <Input.Text value={ajur} onChange={({ target: { value } }) => setAjur(value)} disabled={!editable} />
        </div>
        <div className="col">
          <span>Контрагент в Ажур за производство на енергия</span>
          <Input.Text value={ajurProducer} onChange={({ target: { value } }) => setAjurProducer(value)} disabled={!editable} />
        </div>
      </div>
    </div>
  </div>

  return (
    <div className="client-invoices-container col">
      {!isDealer && ajurSettings()}
      {client?.clientType !== 'Производител' && consumerSettings()}
      {client?.clientType !== 'Консуматор' && producerSettings()}
      <div className="client-invoices-list">
        <h2>Фактури</h2>
        {/*TODO handlePagination*/}
        <Shared.Table
          fixedWidth
          data={client?.invoices?.docs || []}
          columns={clientInvoicesFields}
          renderCell={renderCell.clientInvoices}
        />
      </div>
    </div>
  )
})

export default Invoices
