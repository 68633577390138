import React, { useState } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { isEmpty } from 'lodash'
import { Inputs } from '../../../components'
import './styles.scss'

const ClientsObjectsFieldFilter = ({ hide, sort: sortProp, selected: selectedProp, onSelect, options = [] }) => {

  const [sort, setSort] = useState(sortProp ? (sortProp ? '-1' : '1') : null)
  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState(selectedProp || [])

  return (
    <div className="popup-clients-objects-field-filter-container">
      <Button.Icon name="plus" onClick={hide} />
      <div className="sort">
        <h3>Пореди по</h3>
        <Inputs.RadioButtons
          col
          buttons={[
            { label: 'Възходящи', value: '1' },
            { label: 'Низходящи', value: '-1' },
          ]}
          onClick={(data) => setSort(data)}
          value={sort}
        />
      </div>
      {!isEmpty(options) && (
        <div className="filter">
          <h3>Филтрирай по</h3>
          <Input.Text placeholder="Търси" value={query || ''} onChange={({ target: { value } }) => setQuery(value)} />
          <div className="scroll-container">
            <Inputs.Checkboxes
              buttons={[{ label: 'Избери всички', value: options?.length || 0 }]}
              value={[selected?.length]}
              onClick={() => {
                if (isEmpty(selected)) setSelected(options.map((option) => option?.value || option))
                else setSelected(null)
              }}
            />
            <Inputs.Checkboxes
              buttons={options?.filter(
                (option) => !query || (option?.label || option)?.toLowerCase().includes(query?.toLowerCase())
              )}
              col
              value={selected}
              onClick={(data) => setSelected(data)}
            />
          </div>
        </div>
      )}
      <div className="buttons row">
        <p
          className="btn-clear"
          onClick={() => {
            onSelect(null, null)
            setSort()
            setQuery('')
            setSelected([])
          }}
        >
          Изчисти филтри
        </p>
        <Button.Raised
          text="Задай"
          onClick={() => {
            onSelect(sort, selected)
            hide()
          }}
        />
      </div>
    </div>
  )
}

export default ClientsObjectsFieldFilter
