import React, { useState, useEffect, useRef, useMemo } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Popup as PopupComponents } from '../'
import { useOnClickOutside, usePreviousLocation, useQuery } from 'hooks'
import { Shared } from 'components'
import './styles.scss'
import { flatten } from 'lodash'
import { checkIsDealer } from 'utilities'
import { financialTabs, headerModules } from 'config/constants'
import Popup from 'reactjs-popup'

const Header = ({ socket: supportSocket }) => {
  const { roles } = useSelector(({ general }) => general?.currentUser) || {}
  const permissions = useMemo(() => roles?.reduce((acc, { permissions }) => ({
    ...acc,
    ...Object.fromEntries(Object.entries(permissions).map(([key, value]) => {
      const values = flatten(roles.map(({ permissions }) => permissions[key]))
      return [key, values]
    }))
  }), {}), [roles])
  const [showSubheader, setShowSubheader] = useState(null)

  const isDealer = checkIsDealer()

  const modules = headerModules.map((m) => {
    if (m.value === 'financial') return { ...m, onClick: () => setShowSubheader(showSubheader ? null : 'financial') }
    return m
  })

  const modulesFiltered = useMemo(() => {
    if (!roles) return []
    return modules.filter(({ value }) => value === 'home' || !permissions || ["all", value].some(p => permissions?.modules?.includes(p)))
  }, [permissions])
  const tabs = { financial: financialTabs }
  const filteredTabs = useMemo(() => {
    if (!roles) return []
    return tabs?.[showSubheader]?.filter(({ value }) => !permissions || ["all", value].some(p => permissions?.[showSubheader]?.includes(p)))
  }, [permissions, showSubheader])


  const history = useHistory()
  const location = useLocation()
  const prevLocation = usePreviousLocation(location.pathname, history.action === 'POP')

  let linksRef = useRef({})
  let activeLinkRef = useRef()

  const { handleUrlChangeMultiple } = useQuery()

  const subheaderRef = useRef()
  useOnClickOutside(subheaderRef, () => setShowSubheader(null), showSubheader, linksRef.current['/financial'])
  let subLinksRef = useRef({})
  let activeSubLinkRef = useRef()

  useEffect(() => {
    setTimeout(() => detectRoute(location), 150)
    history.listen((location) => detectRoute(location))
  }, [modulesFiltered])

  const detectRoute = ({ pathname }) => {
    let currentLoc, currentSubLocation
    if (pathname.includes('/financial')) {
      currentLoc = linksRef?.current?.['/financial']
      currentSubLocation = subLinksRef?.current?.[pathname.slice(10)]
    } else currentLoc = linksRef?.current?.[pathname]
    if (currentLoc) {
      const left = currentLoc.offsetLeft
      const { width } = currentLoc.getBoundingClientRect()
      activeLinkRef?.current?.setAttribute('style', `left: ${left}px; width: ${width}px;`)
      if (currentSubLocation) {
        setShowSubheader(null)
        const subLeft = currentSubLocation.offsetLeft
        const { width: subWidth } = currentSubLocation.getBoundingClientRect()
        activeSubLinkRef?.current?.setAttribute('style', `left: ${subLeft}px; width: ${subWidth}px;`)
      } else activeSubLinkRef?.current?.setAttribute('style', `left: ${left}px; width: 0px;`)
    }
  }

  return (
    <div className="header-container row">
      {['showErrors', 'showMissing'].some(p => location.search.includes(p)) ? (
        <Shared.BackButton
          text={location.pathname.includes('intermediate-invoices') ? "Към генерирай" : "Към справки"}
          onClick={() => handleUrlChangeMultiple({ showErrors: undefined, showMissing: undefined })}
        />
      ) : location.pathname.includes('tasks') && ['showForm'].some(p => location.search.includes(p)) ? (
        <Shared.BackButton text="Назад" onClick={() => handleUrlChangeMultiple({ category: undefined, showForm: undefined })} />
      ) : location.pathname.includes('tasks') && ['_id'].some(p => location.search.includes(p)) ? (
        <Shared.BackButton text="Назад" onClick={() => handleUrlChangeMultiple({ _id: undefined })} />
      ) : location.pathname.includes('tasks') && ['board'].some(p => location.search.includes(p)) ? (
        <Shared.BackButton text="Назад" onClick={() => handleUrlChangeMultiple({ board: undefined })} />
      ) : ['clients/', 'annex/', 'resign/'].some(path => location.pathname.includes(path)) ? (
        <Shared.BackButton
          text="Към клиенти"
          onClick={() => {
            if (prevLocation?.includes('/create')) history.go(-2)
            else history.goBack()
          }}
        />
      ) : ['financial/invoices/', 'financial/invoices-intermediate/',].some(p => location.pathname.includes(p)) ? (
        <Shared.BackButton text="Към фактури" onClick={() => history.goBack()} />
      ) : location.pathname.includes('file/preview') ? (
        <Shared.BackButton
          text={prevLocation?.includes('/clients') ? "Към клиенти" : prevLocation?.includes('/financial/invoices') ? "Към фактури" : "Назад"}
          onClick={() => history.goBack()}
        />
      ) : location.pathname.includes('/references-producers/references-generate') ? (
        <Shared.BackButton text="Назад" onClick={() => history.goBack()} />
      ) : location.pathname.includes('/producers/invoices/form') ? (
        <Shared.BackButton text="Назад" onClick={() => history.goBack()} />
      ) : location.pathname.includes('/intermediate-invoices') ? (
        <Shared.BackButton text="Назад" onClick={() => history.goBack()} />
      ) : location.pathname.includes('/financial/interests/form') ? (
        <Shared.BackButton text="Назад" onClick={() => history.goBack()} />
      ) : (
        <div className="header-logo row" onClick={() => history.push('/')}>
          <img alt="Logo" src={process.env.REACT_APP_LOGO ? process.env.REACT_APP_LOGO : require('../../assets/images/logo.png')} />
        </div>
      )}
      <div className="header-links row">
        {modulesFiltered.map(({ label, onClick, ...m }) => {
          if (onClick) return <p key={label} className="row" onClick={onClick} ref={(ref) => { linksRef.current[m.to] = ref }} >
            {label} <span className="icon icon-arrow-down" />
          </p>
          return <NavLink key={label} ref={(ref) => { linksRef.current[m.to] = ref }} {...m}>
            {label}
          </NavLink>
        })}
        <div ref={(ref) => { activeLinkRef.current = ref }} className="active-route-selector" />
      </div>
      <div className="header-buttons row">
        {!isDealer && <PopupComponents.AutoData />}
        <div className="icon icon-support-chat" onClick={() => history.push('/support')}>
          {supportSocket?.state?.tickets?.reduce((acc, { _unreadCustomer }) => acc + _unreadCustomer, 0) > 0 && <div className="support-status" />}
        </div>
        <Popup
          contentStyle={{ width: 'auto' }}
          position='bottom right'
          trigger={<div className="settings-container col ripple">
            <div className="settings-circle" />
            <div className="settings-circle" />
            <div className="settings-circle" />
          </div>}>
          {close => <PopupComponents.Settings hide={close} />}
        </Popup>
      </div>
      <div className={`subheader header-links row ${showSubheader === 'financial' && 'active'}`} ref={subheaderRef}>
        {filteredTabs?.map(({ label, to }) => (<NavLink key={to} to={`/${showSubheader}${to}`} ref={(ref) => { subLinksRef.current[to] = ref }}>
          {label}
        </NavLink>))}
        <div ref={(ref) => { activeSubLinkRef.current = ref }} className="active-route-selector" />
      </div>
    </div>
  )
}

export default Header
