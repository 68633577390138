import React, { useEffect } from "react"
import { flatten, groupBy, omit, pick, } from "lodash"
import { Inputs } from "components"
import { mAdd, mDivide, toNumber } from "utilities"
import "./styles.scss"

const Total = ({ invoice, handleChange, nomenclatures }) => {
  const { objects, total } = invoice || {}
  const { consumption, consumptionTotalAmount, delivery } = total || {}

  useEffect(() => {
    const grouped = groupBy(flatten(objects.map(({ results }) => results)), 'cipher')
    const consumption = sum(Object.values(pick(grouped, ['1001', '1002', '1003'])))
    const consumptionTotal = isNaN(consumption?.at(0)?.quantity) ? 0 : toNumber(consumption?.at(0)?.quantity || 0)
    let delivery = sum(Object.values(omit(grouped, ['1001', '1002', '1003'])))
    delivery = groupBy(delivery, (obj) => nomenclatures.find(({ code }) => code === obj.cipher)?.erp?.name)
    delivery = Object.keys(delivery).map(key => ({
      erp: key,
      data: delivery[key],
      totalQuantity: delivery[key].reduce((acc, cur) => mAdd(acc, (cur?.quantity || 0)), 0),
      total: delivery[key].reduce((acc, cur) => mAdd(acc, (cur?.value || 0)), 0),
    })).filter(({ erp }) => erp !== 'undefined')
    const total = {
      consumption,
      delivery,
      consumptionTotal,
      consumptionTotalAmount: consumption.reduce((acc, cur) => mAdd(acc, (cur?.value || 0)), 0),
      deliveryTotal: flatten(delivery.map(({ data }) => data)).reduce((acc, cur) => mAdd(acc, (cur?.quantity || 0)), 0),
      deliveryTotalAmount: flatten(delivery.map(({ data }) => data)).reduce((acc, cur) => mAdd(acc, (cur?.value || 0)), 0),
    }
    handleChange({ total })
  }, [objects])

  const sum = (array) => array.map(arr => {
    const obj = arr.reduce((acc, cur) => ({
      ...acc,
      ...cur,
      quantity: mAdd((acc?.quantity || 0), (cur?.quantity || 0)),
      value: mAdd((acc?.value || 0), (cur?.value || 0)),
    }), {})
    obj.singleUnitPrice = mDivide((obj?.value || 0), (obj?.quantity || 1))
    return obj
  })


  return <div className="invoices-forms-total-container">
    <div className="invoices-forms-total-header">
      <h2>Тотал</h2>
    </div>
    <div className="invoices-forms-total-consumption">
      <div className="invoices-forms-total-consumption-header row">
        {["Шифър", "Наименование", "М-ка", "Кол", "Ед. цена", "Стойност"].map(label => <span key={`consumption-label-${label}`}>{label}</span>)}
      </div>
      {consumption?.map((c, n) => <div key={`consumption-${n}`} className="invoices-forms-total-single-consumption row">
        {['cipher', 'name', 'unit', 'quantity', 'singleUnitPrice', 'value'].map(value => <div key={`consumption-col-${value}`} className={`col ${value}`}>
          <Inputs.TextLabeled label={['singleUnitPrice', 'value'].includes(value) ? "лв." : ""} value={c?.[value] ?? ''} disabled />
        </div>)}
      </div>)}
      <div className="row row-total">
        <p>Стойност на снабдяване</p>
        <Inputs.TextLabeled label="лв." value={Number(consumptionTotalAmount || 0).toFixed(2) ?? ''} disabled />
      </div>
    </div>
    <div className="invoices-forms-total-delivery">
      {Object.values(groupBy(delivery, 'erp'))?.map(erp => erp?.at(0))?.map(erp => (<div key={`erp-${erp?.erp}`} className="erp-container">
        <div className="invoices-forms-total-delivery-header row">
          {["Шифър", "Наименование", "М-ка", "Кол", "Ед. цена", "Стойност"].map(label => <span key={`delivery-label-${label}`}>{label}</span>)}
        </div>
        {erp?.data?.map((d, n) => <div key={`delivery-${n}`} className="invoices-forms-total-single-delivery row">
          {['cipher', 'name', 'unit', 'quantity', 'singleUnitPrice', 'value'].map(value => <div key={`delivery-col-${value}`} className={`col ${value}`}>
            <Inputs.TextLabeled label={['singleUnitPrice', 'value'].includes(value) ? "лв." : ""} value={d?.[value] ?? ''} disabled />
          </div>)}
        </div>)}
        <div className="row row-total">
          <p>{`Стойност на мрежови услуги ${erp?.erp}`}</p>
          <Inputs.TextLabeled label="лв." value={Number(erp?.total || 0).toFixed(2) ?? ''} disabled />
        </div>
      </div>))}
    </div>
  </div>
}

export default Total