import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { getData, getCommunicationPeriod, getCommunicationPeriodFiles, startLoading, stopLoading } from "actions"
import { Shared, Inputs } from "components"
import { communicationPeriodFields, communicationPeriodFilesFields } from "config/constants"
import { useQuery } from "hooks"
import "./styles.scss"
import { exportBatchFiles, renderCell, mapQuerySearch, fileRequest } from "utilities"
import { nanoid } from 'nanoid'
import Popup from "reactjs-popup"

const Period = ({ TypeSelector }) => {
    const dispatch = useDispatch()

    const { startDate, erp, createdAt, forceOneHour, exportSTP, handleUrlChangeMultiple } = useQuery()

    const { erps } = useSelector(({ data }) => data)
    const erpName = useMemo(() => erps?.find(({ _id }) => _id === erp)?.name, [erps, erp])
    useEffect(() => { if (!erps) dispatch(getData('erps')) }, [])


    const [itn, setItn] = useState(undefined)
    const [data, setData] = useState([])
    const [files, setFiles] = useState({})
    const { docs, totalDocs, nextPage, hasNextPage } = files || {}


    const availableFilters = { profile: { type: 'regex' }, }
    const { sort: sortParam = '{}', filter: filterParam = '{}', } = useQuery()
    const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
    const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
    const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])

    const fetchFiles = useCallback(({ payload = {}, onSuccess, skipLoading } = {}) => {
        if (!erp) return
        dispatch(getCommunicationPeriodFiles({
            payload: { erp, monthYear: moment(startDate).format('MM/YYYY'), createdAt, itn, ...payload, ...searchQuery },
            onSuccess: (res) => {
                if (!skipLoading) dispatch(stopLoading())
                if (onSuccess) onSuccess(res)
                else {
                    if (res.page !== 1) setFiles(({ docs }) => ({ ...res, docs: [...docs, ...res.docs] }))
                    else setFiles(res)
                }
            }
        }))
    }, [dispatch, erp, startDate, createdAt, itn, searchQuery])

    const [showInlineLoading, setShowInlineLoading] = useState(false)
    const fetch = useCallback(() => {
        if (!erp) return
        dispatch(startLoading())
        setShowInlineLoading(true)
        dispatch(getCommunicationPeriod({
            payload: { from: moment(startDate).startOf('month'), to: moment(startDate).add(1, 'months').startOf('month'), erp },
            onSuccess: (payload) => {
                setData(payload ? [{ _id: 1, ...payload }] : [])
                setShowInlineLoading(false)
            }
        }))
    }, [dispatch, startDate, erp])

    useEffect(() => { fetch() }, [fetch])
    useEffect(() => { fetchFiles() }, [fetchFiles])


    const exportFiles = (type) => () => {
        dispatch(startLoading())
        fetchFiles({
            payload: { noPagination: true, select: type },
            skipLoading: true,
            onSuccess: ({ docs }) => exportBatchFiles(docs.map((f) => ({ url: f[type] })))
        })
    }

    const exportStats = async () => await fileRequest({
        URL: '/imported-files/autoPeriod',
        payload: {
            from: moment(startDate).startOf('month'),
            to: moment(startDate).add(1, 'months').startOf('month'),
            erp,
            forExport: true,
            detailed: true,
            forceOneHour,
            exportSTP
        },
        fileName: `export-period-${moment(startDate).format("MM/YYYY")}-${nanoid()}.xlsx`.replaceAll('/', '-').replaceAll('_', '-')
    })

    const sortingComponents = communicationPeriodFilesFields?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
        ...acc,
        [value]: <Popup
            trigger={<div className="icon icon-arrow-down" />}
            activeFilters={searchQuery}
            position='bottom center'
            keepTooltipInside='.communication-period-content'
            contentStyle={{ width: 'auto' }}>
            {close => <Shared.SortingComponent
                hide={close}
                column={value}
                availableSort={[]}
                availableFilters={availableFilters}
            />}
        </Popup>
    }), {})

    return <div className="communication-period-container">
        <div className="communication-period-header row">
            <TypeSelector />
            <Inputs.RoundedButtons
                buttons={erps?.map(({ _id, name }) => ({ label: name, value: _id })) || []}
                value={erp}
                onChange={({ value }) => handleUrlChangeMultiple({ erp: value })}
            />
            <Inputs.DatePicker
                selected={new Date(startDate || moment().toDate())}
                onChange={(value) => handleUrlChangeMultiple({ startDate: moment(value).startOf('month').toDate(), createdAt: undefined })}
                maxDate={new Date()}
                dateFormat={'MM.yyyy'}
                showMonthYearPicker
                showFullMonthYearPicker
            />
            <div className="row row-export">
                <span>Експорт СТП:</span>
                <Button.Switch
                    isOn={exportSTP}
                    onChange={() => handleUrlChangeMultiple({ exportSTP: exportSTP ? undefined : true })}
                />
                <span>Експорт на час:</span>
                <Button.Switch
                    isOn={forceOneHour}
                    onChange={() => handleUrlChangeMultiple({ forceOneHour: forceOneHour ? undefined : true })}
                />
                <div className="icon icon-export" onClick={exportStats} />
            </div>
        </div>
        <div className="communication-period-content">
            <div className={`communication-period-statistics ${showInlineLoading && 'row'}`}>
                {showInlineLoading
                    ? <span className="inline-loader">Loading...</span>
                    : <Shared.Table
                        columns={communicationPeriodFields}
                        data={data}
                        renderCell={(row, field) => {
                            if (['lastImport'].includes(field)) return moment(row[field]).format('DD.MM.YYYY | HH:mm')
                            else return row?.[field] ?? "--"
                        }}
                        fixedWidth
                    />}
            </div>
            <div className="communication-period-files-header row">
                <span>ИТН: </span>
                <Input.Text value={itn ?? ''} onChange={({ target: { value } }) => setItn(value)} />
                <span>Дата:</span>
                <Inputs.DatePicker
                    isClearable
                    value={createdAt}
                    onChange={(createdAt) => handleUrlChangeMultiple({ createdAt })}
                />
                <span className="totalDocs"> За дата: {totalDocs} файла</span>
                <div className="row row-exports">
                    <span>XLSX: </span>
                    <div className="icon icon-export" onClick={exportFiles('xlsxFile')} />
                    <span>XML: </span>
                    <div className="icon icon-export" onClick={exportFiles('xmlFile')} />
                </div>
            </div>
            <div className="communication-period-files">
                <Shared.Table
                    className="files"
                    columns={communicationPeriodFilesFields}
                    data={docs}
                    handlePagination={() => hasNextPage && fetchFiles({ payload: { page: nextPage } })}
                    renderCell={renderCell.communicationFiles}
                    fixedWidth
                    sortingComponent={sortingComponents}
                />
            </div>
        </div>
    </div>
}

export default Period