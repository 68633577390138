import React, { useState } from "react"
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from "react-redux";
import "./styles.scss"
import { setClientsField } from "actions";
import { handleUploadedData } from "utilities";
import { useHistory } from "react-router-dom";

const RedistributeDealersFile = ({ close }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [files, setFiles] = useState(null)
    return <div className="modal-redistribute-dealers-file-container">
        <div className="modal-redistribute-dealers-file-header row">
            <h2>Преразпредели клиенти</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-redistribute-dealers-file-content row">
            <div className="col">
                <span>Файл:</span>
                <Button.UploadButton
                    text={<span className="row ripple">  Добави файл <div className="icon icon-attach" />  </span>}
                    onChange={({ target: { files } }) => setFiles(files)}
                    accept={'.xlsx, .xls'}
                />
            </div>
            <div className="col col-file">
                {files && <div className="row">
                    {files?.[0]?.name}
                    <div className="icon icon-remove" onClick={() => setFiles(null)} />
                </div>}
            </div>
        </div>
        <div className="modal-redistribute-dealers-file-footer row">
            <Button.Raised className='btn-manually' text='Ръчно преразпределяне' onClick={() => { dispatch(setClientsField({ field: 'showCheckboxes', value: { action: 'redistributeDealers' } })); close() }} />
            <Button.Raised text='Продължи' disabled={!files} onClick={() => handleUploadedData({ files, dispatch, history, type: 'redistributeDealersFile' })} />
        </div>
    </div>
}

export default RedistributeDealersFile