import React from 'react'
import { Button } from '@makedonski/admin-ui'
import './styles.scss'

const TaskOptions = ({ hide }) => {
  return (
    <div className="popup-task-options-container">
      <Button.Icon name="plus" onClick={hide} />
      <p>Сподели задачата</p>
      <p>Изтрий задачата</p>
    </div>
  )
}

export default TaskOptions
