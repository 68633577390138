import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { useSelector, useDispatch } from 'react-redux'
import { applyUserFilterPreset, createUserFilterPreset, deleteUserFilterPreset, setClientsFields, startLoading } from '../../../actions'
import { Inputs } from '../../'
import { clientsFields as defaultFields } from '../../../config/constants'
import { useQuery } from '../../../hooks'
import { pick } from 'lodash'
import './styles.scss'

const defaultPreset = {
  clientsFields: defaultFields,
  lockedColumn: null,
  search: null,
  searchBy: null,
  sort: null,
  filter: null,
}

const ClientsFilterOptions = ({ hide }) => {
  const dispatch = useDispatch()

  const { search, searchBy, sort, filter, handleUrlChangeMultiple } = useQuery()
  const { clientsFields, lockedColumn } = useSelector((state) => state.clients)
  const { filterPresets: filters = [] } = useSelector(({ general }) => general?.currentUser) || {}
  const selected = useMemo(() => filters.find(({ applied }) => applied), [filters])

  const [saveFilter, setSaveFilter] = useState(false)
  const [filterName, setFilterName] = useState('')
  useEffect(() => { setFilterName('') }, [saveFilter])

  const setPreset = (preset) => {
    const { lockedColumn, clientsFields, searchBy, search, sort, filter } = preset || {}
    dispatch(setClientsFields({ lockedColumn, clientsFields }))
    handleUrlChangeMultiple({ searchBy, search, sort, filter })
  }

  return (
    <div className="popup-clients-filter-options-container">
      <div className="row">
        <h4>Филтърна комбинация</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>
      <div className="scroll-container">
        <Inputs.RadioButtons
          buttons={filters?.filter(({ type }) => type === 'clients')?.map(({ _id, name }) => ({ value: _id, label: name }))}
          value={selected?._id}
          onClick={(_id) => {
            dispatch(startLoading())
            dispatch(applyUserFilterPreset({ payload: { _id, type: 'clients' } }))
          }}
          col
          renderButton={({ label, value }) => <span
            style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}
          >
            {label}
            <span
              style={{ display: 'inline-block', width: 20, height: 20, marginLeft: 15 }}
              className="icon icon-delete"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(startLoading())
                dispatch(deleteUserFilterPreset({
                  payload: { _id: value },
                  onSuccess: () => { if (selected?._id === value) setPreset(defaultPreset) }
                }))
              }} />
          </span>}
        />
      </div>
      <div className="buttons">
        <Button.Raised
          text="Запази филтърна комбинация"
          className="btn-show-save"
          onClick={() => setSaveFilter(true)}
        />
        <Button.Raised
          text="Изчисти филтри"
          onClick={() => {
            dispatch(startLoading())
            dispatch(applyUserFilterPreset({ payload: { _id: null, type: 'clients' }, onSuccess: () => { dispatch(startLoading()); setPreset(defaultPreset) } }))
          }}
        />
        <Button.Raised
          text="Покажи всички колони"
          onClick={() => dispatch(setClientsFields({ clientsFields: defaultFields }))}
        />

        <div className={`save-filter ${saveFilter && 'show'}`}>
          <h4>Име на филтър</h4>
          <Input.Text value={filterName || ''} onChange={({ target: { value } }) => setFilterName(value)} />
          <div className="row">
            <Button.Raised text="Откажи" onClick={() => setSaveFilter(false)} />
            <Button.Raised
              text="Запази"
              className="btn-save"
              onClick={() => {
                dispatch(startLoading())
                dispatch(createUserFilterPreset({ payload: { name: filterName, preset: { clientsFields, lockedColumn, search, searchBy, sort, filter }, type: 'clients' }, onSuccess: () => { setSaveFilter(false) } }))
              }}
            />
          </div>
        </div>
      </div>
    </div >
  )
}

export default ClientsFilterOptions
