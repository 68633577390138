import React, { useState, useEffect, useRef, forwardRef, useMemo } from 'react'
import { Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { isEmpty, omit } from 'lodash'
import { getData, createObject, updateObject, getClient, startLoading, stopLoading, removeCloneObject, setModal } from 'actions'
import { Client, Inputs } from 'components'
import { useQuery } from 'hooks'
import { mapExcelToObjects, displayMassCheckError, isValidSingleObject, checkIsDealer, asyncForEach } from 'utilities'
import './styles.scss'
import moment from 'moment'
import { producersReferencesOptions } from 'config/constants'
import Popup from 'reactjs-popup'

const Objects = forwardRef(
  (
    {
      client,
      data: dataProp,
      isClientProfile,
      isCreate,
      editable: editableProp,
      setEditable,
      setSelectedObject,
      handleSuccess,
    },
    ref
  ) => {
    const isDealer = checkIsDealer()
    const { client: clientParam, clientType } = useQuery()
    const isPotential = useMemo(() => clientParam === 'new', [clientParam])
    const isProducer = useMemo(() => [client?.clientType, clientType].some((v) => v === 'Производител'), [client, clientType])
    const editable = editableProp === undefined ? true : editableProp
    const defaultData = {
      ...(!isClientProfile && { existingObject: client?.existingClient }),
      ...(isProducer && { isProducer }),
    }
    const initialData = dataProp || [defaultData]
    useEffect(() => { if (!isCreate || !isClientProfile) setData(initialData) }, [dataProp, client])

    const history = useHistory()
    const [data, setData] = useState(initialData)
    const getFormData = () => data
    const handleChange = (field, value, i) => setData((data) => {
      const newData = [...(data || [])]
      newData.splice(i, 1, { ...data[i], [field]: value })
      if (field === 'erp' && options?.erps?.find(({ _id }) => _id === value)?.name === 'ЕСО') {
        const measureType = options?.measureTypes?.find(({ name }) => name === 'Високо')?._id
        const voltage = options?.voltages?.find(({ name }) => name === 'Високо напрежение')?._id
        newData.splice(i, 1, { ...data[i], [field]: value, measureType, voltage })
      } else if (field === 'erp' && data[i].erp === options?.erps?.find(({ name }) => name === 'ЕСО')?._id) {
        newData.splice(i, 1, { ...data[i], [field]: value, measureType: null, voltage: null })
      }
      return newData
    })

    const handleChangeMultiple = (payload, i) => setData((data) => {
      const newData = [...(data || [])]
      newData.splice(i, 1, { ...newData[i], ...payload })
      return newData
    })

    const { data: options } = useSelector((state) => state)
    const dispatch = useDispatch()
    useEffect(() => {
      const dropdowns = ['voltages', 'seasonings', 'forecasts', 'measureTypes', 'erps', 'profiles', 'versions', 'products']
      dropdowns.forEach((dropdown) => !options?.[dropdown] && dispatch(getData(dropdown)))
    }, [])

    const isClientProducer = client?.clientType !== 'Консуматор' && !client?.producerSettings

    const [showRequired, setShowRequired] = useState(false)
    const isValid = () => {
      let valid = true
      const validated = data.map((object) => isValidSingleObject({ object, data: options, validateProducerSettings: isClientProducer }))
      if (validated.includes(false)) valid = false
      const itns = data.map(({ itn }) => itn)
      if (new Set(itns).size !== itns.length) {
        Alerts.error('Има дублиращи се точки !')
        valid = false
      }
      return valid
    }

    const props = { client, options, handleChangeMultiple, showRequired, editable, setEditable }

    const onClear = () => {
      if (isClientProfile && editableProp) setEditable(false)
      if (isClientProfile && !isCreate) ref.current.data = null
      setData(initialData)
      setShowRequired(false)
    }


    const hasProducerPriceOrContractChanged = useMemo(() => {
      if (!dataProp?.[0] || !data?.[0]) return false
      return ['product', 'price', 'paymentDays', 'balancingMethod', 'balancingCeiling', 'balancingFloor', 'invoicingMethod'].some(f => dataProp[0]?.producerSettings?.[f] !== data[0]?.producerSettings?.[f])
    }, [dataProp, data])
    const isClientInactive = useMemo(() => client?.objects?.every(({ status }) => ['inactive'].includes(status)), [dataProp])
    useEffect(() => { if (isClientProfile && isClientInactive) handleChange('producerPriceActiveFrom', hasProducerPriceOrContractChanged ? moment().startOf('month').toDate() : undefined, 0) }, [isClientInactive, hasProducerPriceOrContractChanged])

    const onSave = () => {
      if (!isValid() && (isCreate || client?.clientType !== 'Консуматор')) { setShowRequired(true); return }

      if (isClientProfile && hasProducerPriceOrContractChanged && !data[0].producerPriceActiveFrom) dispatch(setModal({ isOpen: true, type: 'priceActiveFrom', props: { onSaveClient: onSaveObjects, forProducers: true } }))
      else onSaveObjects()
    }

    const onSaveObjects = async ({ producerPriceActiveFrom } = {}) => {
      dispatch(startLoading())
      if (isClientProfile && editableProp) {
        if (producerPriceActiveFrom) data[0].producerPriceActiveFrom = producerPriceActiveFrom
        dispatch(
          updateObject({
            data: omit(data[0], isDealer ? ["existingObject", 'subscriberNumber', 'forecast'] : []),
            onSuccess: (newObject) => {
              setEditable(false)
              dispatch(getClient({ payload: client?._id, getAllDetails: true }))
              setSelectedObject(newObject)
              ref.current.data = null
            },
          })
        )
      } else {
        const promises = data
          ?.map(obj => omit(obj, isDealer ? ["existingObject", 'subscriberNumber', 'forecast'] : []))
          ?.map(({ error, ...object }) => ({
            client: client?._id,
            ...object,
            status: object?.existingObject ? 'active' : !!object.status ? object.status : isPotential ? 'potential' : 'registration',
            isClone: object.status === 'transfer' && object.previousClient && object.previousClient !== object.client && ['active', 'leaving'].includes(object?.previousStatus)
          }))
        await asyncForEach(promises, async (data) => await new Promise((resolve) => {
          if (!data._id) dispatch(createObject({
            data,
            onSuccess: (newObject) => {
              if (isClientProfile) { setSelectedObject({ ...newObject, comments: getFormData()[0].comments }); ref.current.data = null }
              resolve()
            }
          }))
          else dispatch(updateObject({
            data,
            onSuccess: (newObject) => {
              if (isClientProfile) { setSelectedObject({ ...newObject, comments: getFormData()[0].comments }); ref.current.data = null }
              if (data.isClone && data.existingObject) dispatch(removeCloneObject({ payload: { _id: newObject._id } }))
              resolve()
            }
          }))
        }))
        dispatch(stopLoading())
        dispatch(getClient({ payload: client?._id, getAllDetails: true }))
        if (handleSuccess) handleSuccess()
        else history.push(client?._id)
      }
    }

    const objectsRef = useRef({})
    const handleMassCheck = async () => {
      setData((data) => data.map((obj) => omit(obj, ['_id', "status", "previousStatus", "client", "previousClient", "error"])))
      dispatch(startLoading())
      await Promise.all(Object.values(objectsRef?.current || {}).filter(Boolean).map((obj) => new Promise((resolve) => obj?.handleCheckStart({ onSuccess: resolve }))))
      dispatch(stopLoading())
      let helper = null
      setData((data) => { helper = data; return data })
      displayMassCheckError({ objects: helper, setShowRequired })
    }

    if (ref) ref.current = { onClear, onSave, data, setData }

    return (
      <div className="client-forms-objects-container col">
        <div className="client-forms-objects-header row">
          <h2>Информация за обект</h2>
          {isCreate && data.length <= 1 && !isDealer && (
            <Button.Switch
              label="Незабавна активация"
              isOn={!!data?.at(0)?.existingObject}
              onChange={() => handleChange('existingObject', !data?.at(0)?.existingObject, 0)}
            />
          )}
        </div>
        {data?.map((object, i) => {
          const showInvalid = showRequired && !isValidSingleObject({ object, data: options, skipAlerts: true, validateProducerSettings: isClientProducer })
          return <React.Fragment key={`form-${i}`}>
            {isCreate && data.length > 1 && (
              <div className="row row-remove-object">
                <span className="row title">Обект {i + 1}</span>
                {showInvalid && object.error && <Popup
                  trigger={<span className="invalid">!</span>}
                  on={['hover']}
                  position='right center'
                  contentStyle={{ width: 'auto', maxWidth: 300 }}
                >{object.error}</Popup>}
                {!isDealer && <Button.Switch
                  label="Незабавна активация"
                  isOn={!!data?.at(i)?.existingObject}
                  onChange={() => handleChange('existingObject', !data?.at(i)?.existingObject, i)}
                />}
                <Button.Icon
                  name="plus"
                  color="red"
                  onClick={() => {
                    const newData = [...(data || [])]
                    newData.splice(i, 1)
                    setData(newData)
                  }}
                />
              </div>
            )}
            <Client.Forms.Object
              index={i}
              ref={ref => objectsRef.current[i] = ref}
              showInvalid={showInvalid}
              data={object}
              handleChange={(field, value) => handleChange(field, value, i)}
              massCheck={data.length > 1}
              {...props}
            />
            {isClientProducer && object?.isProducer && <Client.Forms.ProducerSettings
              producerSettings={object?.producerSettings}
              setProducerSettings={(newData) => handleChange('producerSettings', newData, i)}
              handleChange={(field, value) => handleChange('producerSettings', { ...object?.producerSettings, [field]: value }, i)}

              versions={options.versions}
              products={options.products}

              showRequired={showRequired}
              editable={editable}
              showInvalid={showInvalid}

              isProfile={isClientProfile}
            />}
            {!isEmpty(object?.producerPricing) && <Client.Forms.ProducerPricing data={object?.producerPricing} />}
            {client?.clientType !== 'Консуматор' && object?.isProducer && client?.invoiceSettings?.producerIndividualSettingsByObject && (
              <div className="row row-producerIndividualSettingsByObject">
                <h2>Справки</h2>
                <div className={`col col-switch ${!editable && 'disabled'}`} style={{ flexBasis: '100%' }}>
                  <span>Фактура за балансиране</span>
                  <Button.Switch
                    isOn={object?.invoiceSettings?.producerInvoiceBalancing}
                    onChange={() => editable && handleChange('invoiceSettings', { ...object?.invoiceSettings, producerInvoiceBalancing: !object?.invoiceSettings?.producerInvoiceBalancing }, i)}
                  />
                </div>
                {producersReferencesOptions?.map(({ label, value }) => (
                  <div key={`switch-${value}`} className={`col col-switch ${!editable && 'disabled'}`}>
                    <span>{label}</span>
                    <Button.Switch
                      isOn={object?.invoiceSettings?.invoiceReference?.[value]}
                      onChange={() => editable && handleChange('invoiceSettings', { ...object?.invoiceSettings, invoiceReference: { ...object?.invoiceSettings?.invoiceReference, [value]: !object?.invoiceSettings?.invoiceReference?.[value] }, }, i)}
                    />
                  </div>
                ))}
                <div className="col" style={{ flexBasis: '100%' }}>
                  <span>Справка за</span>
                  <Inputs.RadioButtons
                    buttons={[
                      { label: 'Измерено', value: 'measured' },
                      { label: 'Прогноза', value: 'forecast' },
                    ]}
                    value={object?.invoiceSettings?.producerStatsFor}
                    onClick={(value) => handleChange('invoiceSettings', { ...object?.invoiceSettings, producerStatsFor: value }, i)}
                    disabled={!editable}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        })}

        {!isClientProfile && (
          <div className="row row-buttons">
            <div className="icon icon-add" onClick={() => setData([...data, defaultData])} />
            <span>Добави друг обект</span>
            {!isDealer && <>
              <Button.UploadButton
                text={<div className="icon icon-add icon-add-mass" />}
                multiple
                accept={'.csv, .xlsx, .xls'}
                onChange={({ target: { files } }) => {
                  const onSuccess = ({ forProducers }) => mapExcelToObjects({
                    forProducers, files, client, data: options,
                    onSuccess: (parsed) => {
                      setData(parsed)
                      handleMassCheck()
                    }
                  })
                  dispatch(setModal({
                    isOpen: true,
                    type: 'confirmation',
                    props: {
                      title: 'Шаблон за:',
                      buttons: <div className="row" style={{ gap: '30px' }}>
                        <Button.Raised text='Консумация' onClick={() => { onSuccess({ forProducers: false }); dispatch(setModal({ isOpen: false })) }} />
                        <Button.Raised text='Производство' onClick={() => { onSuccess({ forProducers: true }); dispatch(setModal({ isOpen: false })) }} />
                      </div>
                    }
                  }))
                }}
              />
              <span>Масово добавяне</span>
            </>}
            <div className="buttons row">
              {handleSuccess && <Button.Raised className="btn-cancel" text="Прескочи" onClick={handleSuccess} />}
              {/* <Button.Raised className="btn-cancel" text="Откажи" onClick={onClear} /> */}
              {data.length > 1 && <Button.Raised text="Масова проверка" className="mass-add" onClick={handleMassCheck} />}
              <Button.Raised className="btn-save" text="Запази и продължи" onClick={onSave} />
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default Objects
