import React, { useMemo, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import "./styles.scss"
import { Inputs } from "components"
import moment from "moment"
import { startLoading, stopLoading, producersReferenceUpdate, producersCheckClientInvoices, setModal } from "actions"
import { Alerts, asyncForEach, transformURL, uploadFile } from "utilities"
import { useDispatch, useSelector } from "react-redux"
import { Portal } from "react-overlays"

const TemplatedInvoiceConfirmed = (props) => {
    const { close, ids, stats, _id, stat, file, monthYear, onSuccess } = props
    const dispatch = useDispatch()
    const { _id: employee } = useSelector(({ general }) => general.currentUser)
    const [data, setData] = useState({
        type: 'ФАКТУРА',
        invoiceNumber: '',
        dateOfIssue: moment(monthYear, 'MM/YYYY').subtract(monthYear ? 0 : 1, 'months').endOf('month').startOf('dat').toDate(),
        pdfFile: file ?? '',
        ...(stat?.templatedInvoice?.fileUploadDate && { fileUploadDate: stat.templatedInvoice.fileUploadDate }),
        ...(stat && { total: stat?.data?.sheet1?.summary1?.total }),
        ...(stats && { total: stats.reduce((acc, stat) => acc + Number(stat?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2) }),
        ...(process.env.REACT_APP_PLATFORM === 'Proakt' && { statsData: (stats ?? [stat]).reduce((acc, { _id, data }) => ({ ...acc, [_id]: { total: data?.sheet1?.summary1?.total ?? 0, totalProduction: data?.sheet1?.summary1?.totalProduction ?? 0 } }), {}) })
    })
    const handleChange = (payload) => setData((data) => ({ ...data, ...payload }))

    const clientInZDDS = (stat ?? stats[0])?.clientData?.clientIdentifier?.startsWith('BG')
    const multipleClientsSelected = stats ? [...new Set(stats.map(({ clientData: { clientIdentifier } }) => clientIdentifier))].length > 1 : false

    const fileExtension = useMemo(() => { const splitted = data?.pdfFile?.split('.') ?? []; return splitted[splitted.length - 1] }, [data])

    const handleUpload = async (files) => {
        dispatch(startLoading())
        const { location } = await uploadFile({ file: files[0], createId: true })
        handleChange({ pdfFile: location })
        dispatch(stopLoading())
    }

    const handleUpdateSingle = () => dispatch(producersReferenceUpdate({
        payload: { _id, templatedInvoice: { ...data, confirmed: 'Проверена', timestamp: new Date(), employee, ...(data?.statsData?.[_id]) } },
        onSuccess: () => { close(); onSuccess(); }
    }))

    const [showConfirmationDate, setShowConfirmationDate] = useState(false)
    const onSubmit = async () => {
        if (process.env.REACT_APP_PLATFORM === 'Synergon' && !showConfirmationDate) { setShowConfirmationDate(true); return }
        dispatch(startLoading())
        if (ids) {
            await asyncForEach(ids, async (_id) => await new Promise((resolve) => dispatch(producersReferenceUpdate({
                payload: { _id, templatedInvoice: { ...data, confirmed: 'Проверена', timestamp: new Date(), employee, ...(data?.statsData?.[_id]) } },
                onSuccess: resolve
            }))))
            close()
            onSuccess()
        } else dispatch(producersCheckClientInvoices({
            payload: { client: stat.client, invoiceNumber: data?.invoiceNumber },
            onSuccess: ({ success }) => {
                if (success) handleUpdateSingle()
                else Alerts.confirm({
                    icon: 'error',
                    text: 'За клиента вече има качена фактура с този номер',
                    confirm: 'Продължи',
                    cancel: 'Откажи',
                    onSuccess: handleUpdateSingle,
                    onCancel: () => dispatch(setModal({ isOpen: false }))
                })
            }
        }))

    }

    const onReturn = () => dispatch(setModal({
        isOpen: true,
        type: 'inputText',
        props: {
            isTextArea: true,
            title: 'Фактура Е - Връщане',
            text: <span style={{ whiteSpace: 'break-spaces' }}>{`Фактурата ще бъде върната и ще бъде изпратен имейл на клиента\nс текст "Моля да коригирате: .....`}</span>,
            onSuccess: (comment) => {
                dispatch(startLoading())
                dispatch(producersReferenceUpdate({
                    payload: { _id, templatedInvoiceCanceled: true, templatedInvoice: { pdfFile: data.pdfFile, confirmed: 'Върната', returnedOn: new Date(), comment, employee } },
                    onSuccess: () => { close(); onSuccess(); }
                }))
            },
            onRequestClose: () => dispatch(setModal({ isOpen: true, type: 'templatedInvoiceConfirmed', modalStyles: { height: '95vh', width: '95vw' }, props }))
        }
    }))

    const renderHeader = () => <div className="modal-templated-invoice-confirmed-header row">
        <h2>Фактура Е - Проверена</h2>
        <div className="icon icon-close" onClick={close} />
    </div>
    const renderForm = () => <>
        <div className={`modal-templated-invoice-confirmed-content${showConfirmationDate ? ' disabled' : ''}`}>
            <div className="row">
                <div className="col">
                    <span>Вид документ</span>
                    <Inputs.RadioButtons
                        buttons={[
                            { label: 'Фактура', value: 'ФАКТУРА' },
                            { label: 'Протокол', value: 'ПРОТОКОЛ' },
                        ]}
                        value={data?.type}
                        onClick={(value) => handleChange({ type: value })}

                    />
                </div>
                <div className="col">
                    <span>Номер на фактура</span>
                    <Input.Text
                        value={data?.invoiceNumber}
                        onChange={({ target: { value } }) => {
                            if (!/^\d+$/.test(value) && value !== '') return
                            if (value.length > 10) return
                            handleChange({ invoiceNumber: value })
                        }}
                    />
                </div>
                <div className="col">
                    <span>Дата на фактура</span>
                    <Inputs.DatePicker
                        value={data?.dateOfIssue}
                        onChange={(value) => handleChange({ dateOfIssue: moment(value).startOf('dat').toDate(), })}
                        popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                    />
                </div>
            </div>
            {process.env.REACT_APP_PLATFORM === 'Proakt'
                ? <>
                    {(stats ?? [stat]).map((stat) => <div key={stat._id} className="row">
                        <div className="col"><span style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{`${stat?.clientData?.object}\n${stat?.clientData?.itn}`}</span></div>
                        <div className="col">
                            <span>Количество</span>
                            <Input.Text
                                value={data?.statsData?.[stat?._id]?.totalProduction ?? ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d+(\.||\.\d{0,5})?$/.test(value) && value !== '') return
                                    setData(({ statsData = {}, ...state }) => ({ ...state, statsData: { ...statsData, [stat._id]: { ...(statsData?.[stat._id] ?? {}), totalProduction: value } } }))
                                }}
                            />
                        </div>
                        <div className="col">
                            <span>Стойност без ДДС</span>
                            <Input.Text
                                value={data?.statsData?.[stat?._id]?.total ?? ''}
                                onChange={({ target: { value } }) => {
                                    if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                                    setData(({ statsData = {}, ...state }) => ({ ...state, statsData: { ...statsData, [stat._id]: { ...(statsData?.[stat._id] ?? {}), total: value } } }))
                                }}
                            />
                        </div>
                    </div>)}
                    <div className="row">
                        <div className="col">
                            <span>СЕИ</span>
                            <Input.Text disabled value={(stats ?? [stat])?.reduce((acc, cur) => acc + Number(data?.statsData?.[cur._id]?.total ?? cur?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2)} />
                        </div>
                        <div className="col">
                            <span>ДДС</span>
                            <Input.Text disabled value={clientInZDDS ? ((stats ?? [stat])?.reduce((acc, cur) => acc + Number(data?.statsData?.[cur._id]?.total ?? cur?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2) * 0.2).toFixed(2) : 0} />
                        </div>
                        <div className="col">
                            <span>Сума за плащане</span>
                            <Input.Text disabled value={clientInZDDS ? ((stats ?? [stat])?.reduce((acc, cur) => acc + Number(data?.statsData?.[cur._id]?.total ?? cur?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2) * 1.2).toFixed(2) : (stats ?? [stat])?.reduce((acc, cur) => acc + Number(data?.statsData?.[cur._id]?.total ?? cur?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2)} />
                        </div>
                    </div>
                </>
                : <div className="row">
                    <div className="col">
                        <span>СЕИ</span>
                        <Input.Text
                            value={data?.total}
                            onChange={({ target: { value } }) => {
                                if (!/^\d+(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                                handleChange({ total: value })
                            }}
                        />
                    </div>
                    <div className="col">
                        <span>ДДС</span>
                        <Input.Text disabled value={clientInZDDS ? ((data?.total ?? 0) * 0.2).toFixed(2) : 0} />
                    </div>
                    <div className="col">
                        <span>Сума за плащане</span>
                        <Input.Text disabled value={clientInZDDS ? ((data?.total ?? 0) * 1.2).toFixed(2) : (data?.total ?? 0)} />
                    </div>
                </div>}
            <div className="row">
                <div className="col col-full">
                    <span>Файл</span>
                    {data?.pdfFile ? (
                        <div className="row row-file">
                            <div className="icon icon-remove" onClick={() => handleChange({ pdfFile: '' })} />
                            <a href={transformURL(data?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
                                <span>{data?.pdfFile?.split('/').pop().split('_').slice(1).join('_')}</span>
                            </a>
                        </div>
                    ) : (

                        <Button.UploadButton
                            text={<span className="row ripple">
                                Добави файл <div className="icon icon-attach" style={{ marginLeft: 8 }} />
                            </span>}
                            accept="*"
                            onChange={({ target: { files } }) => handleUpload(files)}
                        />
                    )}
                </div>
            </div>
        </div>
        {showConfirmationDate
            ? <div className="modal-templated-invoice-confirmed-footer row">
                <p style={{ marginBottom: -10, marginRight: 15 }} className="error">Сигурни ли сте, че датата на фактурата {moment(data?.dateOfIssue).format('DD.MM.YYYY')} е правилна?</p>
                <Button.Raised className='btn-cancel' text='Не' onClick={() => setShowConfirmationDate(false)} />
                <Button.Raised text='Да' className='flex-1' disabled={!data?.invoiceNumber || !data?.pdfFile || multipleClientsSelected} onClick={onSubmit} />
            </div>
            : <div className="modal-templated-invoice-confirmed-footer">
                {multipleClientsSelected && <p className="error">Избрани са справки на повече от един клиент</p>}
                <div className="row">
                    {data?.fileUploadDate && <Button.Raised className='btn-cancel' text='Върни' disabled={multipleClientsSelected} onClick={onReturn} />}
                    <Button.Raised text='Запази' disabled={!data?.invoiceNumber || !data?.pdfFile || multipleClientsSelected} onClick={onSubmit} />
                </div>
            </div>}
    </>

    return <div className="modal-templated-invoice-confirmed-container with-stat-data">
        <Input.DragAndDrop handleDrop={handleUpload}>
            {renderHeader()}
            <div className="modal-templated-invoice-confirmed-inner-container row">
                <div className="modal-templated-invoice-confirmed-left-container col">
                    <h3>Фактура / Протокол</h3>
                    {['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension)
                        ? <div className="file-empty-state row">Файлът не може да бъде визуализиран</div>
                        : data?.pdfFile
                            ? <object data={transformURL(data?.pdfFile)} type="application/pdf" width="100%" height="100%" />
                            : <div className="file-empty-state row">Моля добавете файл</div>}
                </div>
                <div className="modal-templated-invoice-confirmed-right-container col">
                    <div className="client-data">
                        <p>Производител: <span>{(stat ?? stats[0])?.clientData?.fullName}</span></p>
                        <p className="eic">ЕИК: <span>{(stat ?? stats[0])?.clientData?.clientIdentifier}</span></p>
                        {stat && <p>Обект: <span>{stat?.clientData?.object}</span></p>}
                        {stat && <p>ИТН: <span>{stat?.clientData?.itn}</span></p>}
                    </div>
                    {process.env.REACT_APP_PLATFORM !== 'Proakt' && <div className="stat-data col">
                        <div className="row">
                            {stats && <div className="row"><span>Обект</span></div>}
                            {stats && <div className="row"><span>ИТН</span></div>}
                            <div className="row" style={{ flex: 0.5 }}><span>Мярка</span></div>
                            <div className="row"><span>Количество</span></div>
                            <div className="row"><span>Ед. цена без ДДС</span></div>
                            <div className="row"><span>Стойност без ДДС</span></div>
                        </div>
                        {(stats ?? [stat]).map((stat) => <div key={stat._id} className="row">
                            {stats && <div className="row"><span>{stat?.clientData?.object}</span></div>}
                            {stats && <div className="row"><span>{stat?.clientData?.itn}</span></div>}
                            <div className="row" style={{ flex: 0.5 }}><span>MWh</span></div>
                            <div className="row"><span>{stat?.data?.sheet1?.summary1?.totalProduction}</span></div>
                            <div className="row"><span>{stat?.data?.sheet1?.summary1?.singleUnitPrice}</span></div>
                            <div className="row"><span>{stat?.data?.sheet1?.summary1?.total}</span></div>
                        </div>)}
                        {stats && <div className="row">
                            <div className="row"><span>Тотал: </span></div>
                            <div className="row"><span></span></div>
                            <div className="row" style={{ flex: 0.5 }} ><span></span></div>
                            <div className="row"><span>{stats?.reduce((acc, stat) => acc + Number(stat?.data?.sheet1?.summary1?.totalProduction ?? 0), 0).toFixed(5)}</span></div>
                            <div className="row"><span></span></div>
                            <div className="row"><span>{stats?.reduce((acc, stat) => acc + Number(stat?.data?.sheet1?.summary1?.total ?? 0), 0).toFixed(2)}</span></div>
                        </div>}
                    </div>}
                    {renderForm()}
                </div>
            </div>
        </Input.DragAndDrop>
    </div>
}

export default TemplatedInvoiceConfirmed