export const connectedGroupsTypes = {
  GET_CONNECTED_GROUPS: 'connectedGroups/GET_CONNECTED_GROUPS',
  GET_CONNECTED_GROUP: 'connectedGroups/GET_CONNECTED_GROUP',
  CREATE_CONNECTED_GROUP: 'connectedGroups/CREATE_CONNECTED_GROUP',
  CREATE_CONNECTED_GROUP_SUCCESS: 'connectedGroups/CREATE_CONNECTED_GROUP_SUCCESS',
  UPDATE_CONNECTED_GROUP: 'connectedGroups/UPDATE_CONNECTED_GROUP',
  UPDATE_CONNECTED_GROUP_SUCCESS: 'connectedGroups/UPDATE_CONNECTED_GROUP_SUCCESS',
  DELETE_CONNECTED_GROUP: 'connectedGroups/DELETE_CONNECTED_GROUP',
  DELETE_CONNECTED_GROUP_SUCCESS: 'connectedGroups/DELETE_CONNECTED_GROUP_SUCCESS',
}

export const getConnectedGroups = (payload) => ({
  type: connectedGroupsTypes.GET_CONNECTED_GROUPS,
  payload,
})

export const getConnectedGroup = (payload) => ({
  type: connectedGroupsTypes.GET_CONNECTED_GROUP,
  payload,
})

export const createConnectedGroup = (payload) => ({
  type: connectedGroupsTypes.CREATE_CONNECTED_GROUP,
  payload,
})

export const updateConnectedGroup = (payload) => ({
  type: connectedGroupsTypes.UPDATE_CONNECTED_GROUP,
  payload,
})

export const deleteConnectedGroup = (payload) => ({
  type: connectedGroupsTypes.DELETE_CONNECTED_GROUP,
  payload,
})
