import { Input, Button } from '@makedonski/admin-ui'
import { Client, Inputs } from 'components'
import moment from "moment"
import React from "react"
import { useSelector } from 'react-redux'
import "./styles.scss"

const ProducerPricing = ({ data }) => {
    const { products } = useSelector(({ data }) => data)
    return <div className="client-forms-producer-pricing-container">
        <h2>История договор и цени - Производство</h2>
        {data?.map(({ _id, activeFrom, data }) => <div key={_id} className="single-pricing">
            <div className="row">
                <div className="col">
                    <span>Активна от</span>
                    <Input.Text value={moment(activeFrom).format("DD.MM.YYYY")} disabled />
                </div>
                <div className="col">
                    <span>ИН по ЗДДС</span>
                    <Input.Text value={data?.bulstat || ''} disabled />
                </div>
                <div className="col">
                    <span>Падеж производство</span>
                    <Inputs.TextLabeled label="число" value={data?.paymentDays || ''} disabled />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span>Продукт</span>
                    <Input.Text value={products?.find(({ _id }) => _id === data?.product)?.name || ""} disabled />
                </div>
                <Client.Forms.ProductPrices products={products} data={data} editable={false} />
            </div>
            <div className="row">
                <div className="col">
                    <span>Вид Фактуриране</span>
                    <Input.Text value={data?.invoicingMethod} disabled />
                </div>
                <div className="col">
                    <span>Балансиране</span>
                    <Input.Text value={data?.balancingMethod} disabled />
                </div>
                {['Под и Таван в лв./МВТч',].includes(data?.balancingMethod) && <div className="col">
                    <span>Под</span>
                    <Inputs.TextLabeled label="лв./МВтч" value={data?.balancingFloor ?? ""} disabled />
                </div>}
                {['Фикс в лв. / МВТч', 'Таван в лв./МВТч', 'Под и Таван в лв./МВТч',].includes(data?.balancingMethod) && <div className="col">
                    <span>{['Таван в лв./МВТч', 'Под и Таван в лв./МВТч'].includes(data?.balancingMethod) ? 'Таван' : 'Фикс'}</span>
                    <Inputs.TextLabeled label="лв./МВтч" value={data?.balancingCeiling ?? ""} disabled />
                </div>}
            </div>
            <div className="divider" />
        </div>)}
    </div>

}

export default ProducerPricing