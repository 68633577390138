import React, { useEffect, useState } from "react"
import "./styles.scss"
import { useDispatch } from "react-redux"
import { calculatePenalty, startLoading } from "actions"
import Shared from "components/Shared"
import { mMultiply, renderCell } from "utilities"

const CalculatePenalty = ({ close, client }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState(null)
    useEffect(() => { dispatch(startLoading()); dispatch(calculatePenalty({ payload: { client }, onSuccess: setState })) }, [dispatch, client])

    return <div className="modal-calculate-penalty-container col">
        <div className="modal-calculate-penalty-header row">
            <h2>Калкулатор неустойка</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-calculate-penalty-content">
            {state && <Shared.Table
                fixedWidth
                columns={[
                    { label: 'Средна дневна консумация', value: 'penaltyAverageConsumption' },
                    { label: 'Средна цена', value: 'penaltyAveragePrice' },
                    { label: 'Оставащи дни', value: 'penaltyDaysLeft' },
                    { label: 'Сметка', value: 'penaltyInitial' },
                    { label: 'Неустойка процент/фикс', value: 'penaltyPercent' },
                    { label: 'Неустойка стойност', value: 'penaltyValue' },
                ]}
                data={[state]}
                renderCell={(row, field) => {
                    switch (field) {
                        case 'penaltyAverageConsumption':
                            return (row?.[field] ?? 0).toFixed(3) + " МВтч"
                        case 'penaltyAveragePrice':
                        case 'penaltyInitial':
                            return (row?.[field] ?? 0).toFixed(2) + " лв."
                        case 'penaltyDaysLeft':
                            return row?.[field] ?? 0
                        case 'penaltyPercent':
                            if (row.penaltyIsFixed) return (row?.penaltyFixed ?? 0).toFixed(2) + " лв."
                            return mMultiply(row?.penaltyRatio ?? 0, 100) + " %"
                        case 'penaltyValue':
                            return (row?.[field] ?? 0).toFixed(2) + " лв."
                        default:
                            return renderCell.default(row, field)
                    }
                }}
            />}
        </div>
    </div>
}

export default CalculatePenalty