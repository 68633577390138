import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import React, { useState } from "react"
import "./styles.scss"
import moment from "moment"
import { Portal } from "react-overlays"

const ObligationsDate = ({ close, onSuccess }) => {
    const [date, setDate] = useState(moment().add(2, 'days').toDate())

    return <div className="modal-obligations-date-container">
        <div className="modal-obligations-date-header row">
            <h2>Краен срок за отстраняване на задължения</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-obligations-date-content">
            <Inputs.DatePicker
                value={date}
                onChange={setDate}
                minDate={new Date()}
                popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
            />
        </div>
        <div className="modal-obligations-date-footer">
            <Button.Raised text="Потвърди" onClick={() => { onSuccess(date); close() }} />
        </div>
    </div>
}

export default ObligationsDate