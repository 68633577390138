import React, { useState } from 'react'
import { Button } from "@makedonski/admin-ui"
import { isEmpty, partition, sortBy } from 'lodash'
import { Inputs } from '../../'
import './styles.scss'
import { useQuery } from 'hooks'
import moment from 'moment'
import { Portal } from 'react-overlays'
import { clientsStatusMap } from 'config/constants'

const Filter = ({ clientsData, selectedObjects, exclude, fetch, handleExport, defaultType }) => {
  const [expanded, setExpanded] = useState({})

  const {
    from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY'),
    to = moment().startOf('month').format('DD.MM.YYYY'),
    tempType,
    type = defaultType,
    handleUrlChangeMultiple
  } = useQuery()


  const startDate = moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null
  const endDate = moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').subtract(1, 'days').toDate() : null

  const renderSingleClientHeader = ({ _id, fullName, objects, expand = true }) => {
    const [stps, period] = partition(objects, ({ STPMeasure }) => STPMeasure)
    const stpsActive = stps.every(({ _id }) => selectedObjects.includes(_id))
    const periodActive = period.every(({ _id }) => selectedObjects.includes(_id))
    return <div className='single-client-header'>
      <div className="row">
        <Inputs.Checkboxes
          buttons={[{ label: ' ', value: objects?.length ?? 0 }]}
          value={[objects.filter(({ _id }) => selectedObjects.includes(_id)).length > 0 ? objects?.length : 0]}
          onClick={() => handleUrlChangeMultiple({
            exclude: isEmpty(objects.filter(({ _id }) => exclude.includes(_id)))
              ? [...(exclude), ...objects?.map(({ _id }) => _id)]
              : (exclude).filter((_id) => !objects?.map(({ _id }) => _id).includes(_id))
          })}
        />
        <p className="client">{fullName}</p>
        {expand && <div
          className={`icon icon-arrow-right ${!expanded?.[_id] && 'show'}`}
          onClick={() => { setExpanded({ ...expanded, [_id]: !expanded?.[_id] }) }}
        />}
      </div>
      <div className="row" style={{ gap: 5 }}>
        <Inputs.Checkboxes
          buttons={[{ label: 'STP', value: 'stp' }]}
          value={stpsActive ? 'stp' : ''}
          onClick={() => handleUrlChangeMultiple({
            exclude: stpsActive
              ? [...(exclude), ...stps?.map(({ _id }) => _id)]
              : (exclude).filter((_id) => !stps?.map(({ _id }) => _id).includes(_id))
          })}
        />
        <Inputs.Checkboxes
          buttons={[{ label: 'Почасови', value: 'period' }]}
          value={periodActive ? 'period' : ''}
          onClick={() => handleUrlChangeMultiple({
            exclude: periodActive
              ? [...(exclude), ...period?.map(({ _id }) => _id)]
              : (exclude).filter((_id) => !period?.map(({ _id }) => _id).includes(_id))
          })}
        />
      </div>
    </div>
  }

  return (
    <div className="analysis-filter-container">
      <h4>Анализ на потреблението</h4>
      {/* <Inputs.DatePicker
        value={moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null}
        startDate={moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : null}
        endDate={moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').subtract(1, 'days').toDate() : null}
        onChange={([startDate, endDate]) => handleUrlChangeMultiple({
          from: moment(startDate).startOf('month').format('DD.MM.YYYY'),
          to: moment(endDate).add(1, 'month').startOf('month').format('DD.MM.YYYY'),
        })}
        inline
        selectsRange
        showMonthYearPicker
        showFullMonthYearPicker
      /> */}
      <div className="row row-buttons">
        <Button.Raised onClick={fetch} text='Анализ' disabled={selectedObjects.length > 200} />
        <div className="icon icon-export" onClick={handleExport} />
      </div>
      {selectedObjects.length > 200 && <p className='text-disable'>Избрали сте повече от 200 точки. Данните не могат да бъдат визуализирани, само експортирани.</p>}
      <h4>Изглед</h4>
      <Inputs.Dropdown
        options={[
          { label: 'Година', value: 'years' },
          { label: 'Тримесечие', value: 'quarters' },
          { label: 'Месец', value: 'months' },
          { label: 'Ден', value: 'days' },
          { label: 'Час', value: 'hours' },
          { label: '15 Минути', value: 'minutes' },
        ]}
        value={tempType ?? type}
        onChange={({ value }) => handleUrlChangeMultiple({ tempType: value })}
      />

      <h4>Период</h4>
      <div className="row" style={{ width: "100%", justifyContent: 'space-between' }}>
        <div className="col">
          <span>От:</span>
          <Inputs.DatePicker
            value={startDate} startDate={startDate} endDate={endDate}
            onChange={(startDate) => handleUrlChangeMultiple({ from: moment(startDate).startOf('month').format('DD.MM.YYYY'), to })}
            selectsStart
            dateFormat="MMMM yyyy" showMonthYearPicker showFullMonthYearPicker
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
        <div className="col">
          <span>До:</span>
          <Inputs.DatePicker
            value={endDate} startDate={startDate} endDate={endDate}
            onChange={(endDate) => handleUrlChangeMultiple({ to: moment(endDate).add(1, 'month').startOf('month').format('DD.MM.YYYY'), from })}
            selectsEnd
            dateFormat="MMMM yyyy" showMonthYearPicker showFullMonthYearPicker
            popperContainer={({ children }) => (<Portal container={document.getElementById('calendar-portal')}>{children}</Portal>)}
          />
        </div>
      </div>

      {clientsData?.length && <h4>Клиенти</h4>}
      {clientsData?.length > 1 && <div className='single-client-container'>
        {renderSingleClientHeader({ _id: 'all', fullName: 'Всички клиенти', objects: clientsData?.map(({ objects }) => objects)?.flat() ?? [], expand: false })}
      </div>}
      {clientsData?.map(({ _id, fullName, objects }) => <div key={`client-${_id}`} className="single-client-container">
        {renderSingleClientHeader({ _id, fullName, objects })}
        <div className={`single-client-content ${!expanded?.[_id] && 'show'}`}>
          {!isEmpty(objects) ? (
            <Inputs.Checkboxes
              value={selectedObjects}
              onClick={(selected) => handleUrlChangeMultiple({
                exclude: clientsData
                  ?.reduce((acc, { objects }) => [...acc, ...objects.map(({ _id }) => _id)], [])
                  ?.filter((_id) => !selected.includes(_id))
              })}
              buttons={sortBy(objects, ['isProducer', 'status'])?.map(({ _id, itn, measureType, STPMeasure, status, isProducer }) => ({ value: _id, label: [itn, isProducer ? 'Производител' : '', measureType?.name, STPMeasure, clientsStatusMap[status]].filter(Boolean).join(" - "), }))}
              col
            />
          ) : (
            'Клиента няма обекти'
          )}
        </div>
      </div>)}
    </div >
  )
}

export default Filter
