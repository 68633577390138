import { getConsumptionAnalysis, startLoading } from 'actions'
import { useQuery } from 'hooks'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Analysis } from '../../components'
import './styles.scss'
import { fileRequest } from 'utilities'

const ConsumptionAnalysis = ({ }) => {
  const dispatch = useDispatch()

  const [response, setResponse] = useState({})
  const { mainStats, objectStats, totalStats, clientsData, marketPrices } = response || {}

  const defaultType = process.env.REACT_APP_PLATFORM === 'Energy Operations' ? 'minutes' : 'hours'
  const { clients, from, to, tempType, type = defaultType, exclude, handleUrlChangeMultiple } = useQuery()

  const objects = useMemo(() => {
    return JSON.stringify(clientsData
      ?.reduce((acc, { objects }) => [...acc, ...objects], [])
      ?.filter(({ _id }) => !exclude?.split(",")?.includes(_id))
      ?.map(({ _id }) => _id) || [])
  }, [clientsData, exclude])


  const consumptionPayload = useMemo(() => ({
    clients: clients.split(","),
    objects: JSON.parse(objects),
    type: tempType ?? type,
    from: moment(from, 'DD.MM.YYYY').isValid() ? moment(from, 'DD.MM.YYYY').toDate() : undefined,
    to: moment(to, 'DD.MM.YYYY').isValid() ? moment(to, 'DD.MM.YYYY').toDate() : undefined,
  }), [clients, objects, tempType, type, from, to])

  const fetch = useCallback(() => {
    if ((consumptionPayload.from && !consumptionPayload.to)) return
    dispatch(startLoading())
    dispatch(getConsumptionAnalysis({
      payload: consumptionPayload, onSuccess: (res) => {
        setResponse(res)
        if (tempType) handleUrlChangeMultiple({ type: tempType, tempType: undefined })
      }
    }))
  }, [dispatch, consumptionPayload])
  useEffect(() => { fetch() }, [])

  const handleExport = async () => await fileRequest({
    URL: '/consumption/analyze',
    payload: { ...consumptionPayload, forExport: true }
  })

  return (
    <div className="screen-consumption-analysis-container">
      <div className="screen-consumption-analysis-content row">
        <div className="screen-consumption-analysis-filter">
          <Analysis.Filter
            clientsData={clientsData}
            selectedObjects={JSON.parse(objects) || []}
            exclude={exclude?.split(",") || []}
            fetch={fetch}
            handleExport={handleExport}
            defaultType={defaultType}
          />
        </div>
        <div className="screen-consumption-analysis-inner-content col">
          {mainStats ? <>
            <Analysis.ConsumptionGraph mainStats={mainStats} marketPrices={marketPrices} defaultType={defaultType} />
            {type !== 'minutes' && <Analysis.ConsumptionSummary totalStats={totalStats} />}
            <Analysis.ConsumptionObjects objectStats={objectStats} defaultType={defaultType} />
            {/* <Analysis.ConsumptionCalculator /> */}
          </> : <>
            <div className="empty-state row">
              <h2>Моля изберете правилните параметри и натиснете "Анализ"!</h2>
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

export default ConsumptionAnalysis
