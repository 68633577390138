export const communicationTypes = {
    GET_COMMUNICATION_PERIOD: 'communication/GET_COMMUNICATION_PERIOD',
    GET_COMMUNICATION_PERIOD_FILES: 'communication/GET_COMMUNICATION_PERIOD_FILES',

    GET_COMMUNICATION_NETWORK: 'communication/GET_COMMUNICATION_NETWORK',
    GET_COMMUNICATION_NETWORK_FILES: 'communication/GET_COMMUNICATION_NETWORK_FILES',

    GET_COMMUNICATION_CORRECTIONS_FILES: 'communication/GET_COMMUNICATION_CORRECTIONS_FILES',
    APPLY_COMMUNICATION_CORRECTIONS_FILES: 'communication/APPLY_COMMUNICATION_CORRECTIONS_FILES',

    GET_COMMUNICATION_OBJECTS: 'communication/GET_COMMUNICATION_OBJECTS',
    GET_COMMUNICATION_OBJECTS_FILES: 'communication/GET_COMMUNICATION_OBJECTS_FILES',
}

export const getCommunicationPeriod = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_PERIOD,
    ...payload,
})

export const getCommunicationPeriodFiles = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_PERIOD_FILES,
    ...payload,
})

export const getCommunicationNetwork = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_NETWORK,
    ...payload,
})

export const getCommunicationNetworkFiles = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_NETWORK_FILES,
    ...payload,
})

export const getCommunicationCorrectionsFiles = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_CORRECTIONS_FILES,
    ...payload,
})

export const applyCommunicationCorrectionsFiles = (payload) => ({
    type: communicationTypes.APPLY_COMMUNICATION_CORRECTIONS_FILES,
    ...payload,
})

export const getCommunicationObjects = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_OBJECTS,
    ...payload,
})

export const getCommunicationObjectsFiles = (payload) => ({
    type: communicationTypes.GET_COMMUNICATION_OBJECTS_FILES,
    ...payload,
})