import React, { useEffect, forwardRef, useImperativeHandle, useState, useMemo } from 'react'
import { Alerts } from '@makedonski/socourt-utilities'
import { Input, Button } from '@makedonski/admin-ui'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { startLoading, stopLoading, checkClientExists, getData } from 'actions'
import { Client, Inputs } from 'components'
import './styles.scss'
import { NavLink, useLocation } from 'react-router-dom'
import { balancingMethodOptions, invoicingMethodOptions } from 'config/constants'

const ProducersAnnexMain = forwardRef(({
  isClientProfile,
  isApplied,
  editable,
  data,
  handleChange: handleChangeProp,
  client,
  setClient,
  showRequired
}, ref) => {
  const { pathname } = useLocation()
  const isResign = useMemo(() => pathname.includes('resign'), [pathname])

  const dispatch = useDispatch()
  const { products } = useSelector(({ data }) => data)
  useEffect(() => { !products && dispatch(getData('products')) }, [])

  const startClientFetch = (payload) => {
    dispatch(startLoading())
    dispatch(
      checkClientExists({
        exact: true,
        getAllDetails: true,
        data: payload || client?.eic,
        onSuccess: (clients) => {
          dispatch(stopLoading())
          if (isEmpty(clients)) Alerts.error(`Няма намерен клиент.`)
          else if (clients[0].clientType === 'Консуматор') Alerts.error(`Клиентът е консуматор`)
          else {
            setClient(clients?.at(0))
            handleChange({ client: clients?.at(0)?._id })
          }
        },
      })
    )
  }

  const [edits, setEdits] = useState()
  const handleEdits = (payload) => setEdits({ ...edits, ...payload })
  const handleChange = (payload) => isClientProfile ? handleEdits(payload) : handleChangeProp(payload)

  const onClear = () => setEdits()

  useImperativeHandle(ref, () => ({ edits, onClear, startClientFetch }), [edits, onClear, startClientFetch,])

  return <div className="client-forms-producers-annex-main-container">
    {!isClientProfile && (
      <>
        <h2>Данни на клиента</h2>
        <div className="row">
          <div className="col">
            <span>ЕИК/ЕГН</span>
            <Input.Text
              value={client?.eic || client?.pin || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d+$/.test(value) && value !== '') return
                setClient({ ...client, eic: value })
              }}
              onKeyDown={({ key }) => key === 'Enter' && startClientFetch()}
              disabled={data?.client}
            />
          </div>
          {data?.client ? (<>
            <div className="col">
              <span>Име на клиент</span>
              <Input.Text value={client?.fullName || ''} disabled />
            </div>
          </>) : (
            <div className="col">
              <span></span>
              <Button.Raised text="Извлечи данни" disabled={!client?.eic} onClick={() => startClientFetch()} />
            </div>
          )}
        </div>
      </>
    )}
    {data?.client && (
      <>
        <h2>Продукт и цени</h2>
        <div className="row">
          <div className="col">
            <span>Продукт</span>
            <Inputs.Dropdown
              options={products?.filter(({ forProducer }) => forProducer)?.map(({ _id, name }) => ({ label: name, value: _id }))}
              onChange={({ value }) => {
                const product = products?.find(({ _id }) => _id === value)?.name
                handleChange({
                  product: value,
                  price: ['Борса минус с Под на цената', 'Борса минус с Таван на цената', 'Борса минус с Под и Таван на цената'].includes(product) ? { marketIsPercent: true } : ['Борса минус', 'Отделно балансиране',].includes(product) ? { market: 0, marketIsPercent: false } : null
                })
              }}
              value={isClientProfile ? edits?.product || data?.product?._id || data?.product : data?.product?._id || data?.product || client?.producerSettings?.product?._id || client?.producerSettings?.product}
              disabled={!editable || isApplied}
            />
          </div>
          <Client.Forms.ProductPrices
            products={products}
            data={isClientProfile
              ? { price: { ...(edits?.price || data?.price) }, product: edits?.product || data?.product || client?.producerSettings?.product }
              : { price: { ...client?.producerSettings?.price, ...data?.price }, product: data?.product || client?.producerSettings?.product }}
            handleChange={(payload) => handleChange({ price: { ...(edits?.price || data?.price), ...payload } })}
            showRequired={showRequired}
            editable={editable && !isApplied}
          />
        </div>
        <div className="row">
          <div className="col">
            <span>Вид Фактуриране</span>
            <Inputs.Dropdown
              options={invoicingMethodOptions.map((value) => ({ value, label: value }))}
              onChange={({ value }) => handleChange({ invoicingMethod: value })}
              value={isClientProfile ? edits?.invoicingMethod || data?.invoicingMethod : data?.invoicingMethod || client?.producerSettings?.invoicingMethod}
              disabled={!editable || isApplied}
            />
          </div>
          <div className="col">
            <span>Балансиране</span>
            <Inputs.Dropdown
              options={balancingMethodOptions
                .filter((value) => products?.find(({ _id }) => _id === data?.product)?.name !== 'Борса (без надбавка)' || value !== 'Включено')
                .map((value) => ({ value, label: value }))
              }
              onChange={({ value }) => handleChange({ balancingMethod: value, balancingCeiling: null, balancingFloor: null })}
              value={isClientProfile ? edits?.balancingMethod || data?.balancingMethod : data?.balancingMethod || client?.producerSettings?.balancingMethod || ''}
              disabled={!editable || isApplied}
            />
          </div>
          {['Под и Таван в лв./МВТч',].includes(edits?.balancingMethod ?? data?.balancingMethod) && <div className="col">
            <span>Под</span>
            <Inputs.TextLabeled
              label="лв./МВтч"
              value={isClientProfile ? edits?.balancingFloor || data?.balancingFloor || "" : data?.balancingFloor || client?.producerSettings?.balancingFloor || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                handleChange({ balancingFloor: value })
              }}
              disabled={!editable || isApplied}
            />
          </div>}
          {['Фикс в лв. / МВТч', 'Таван в лв./МВТч', 'Под и Таван в лв./МВТч',].includes(edits?.balancingMethod ?? data?.balancingMethod) && <div className="col">
            <span>{['Таван в лв./МВТч', 'Под и Таван в лв./МВТч'].includes(edits?.balancingMethod ?? data?.balancingMethod) ? 'Таван' : 'Фикс'}</span>
            <Inputs.TextLabeled
              label="лв./МВтч"
              value={isClientProfile ? edits?.balancingCeiling || data?.balancingCeiling || "" : data?.balancingCeiling || client?.producerSettings?.balancingCeiling || ''}
              onChange={({ target: { value } }) => {
                if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
                handleChange({ balancingCeiling: value })
              }}
              disabled={!editable || isApplied}
            />
          </div>}
        </div>
        <h2>Фактуриране</h2>
        <div className="row">
          <div className="col">
            <span>Фактура за балансиране</span>
            <Button.Switch
              isOn={isClientProfile ? edits?.producerInvoiceBalancing ?? data?.invoiceSettings?.producerInvoiceBalancing : data?.producerInvoiceBalancing ?? client?.invoiceSettings?.producerInvoiceBalancing}
              onChange={() => editable && handleChange({
                producerInvoiceBalancing: !(
                  isClientProfile
                    ? edits?.producerInvoiceBalancing ?? data?.invoiceSettings?.producerInvoiceBalancing
                    : data?.producerInvoiceBalancing ?? client?.invoiceSettings?.producerInvoiceBalancing
                )
              })}
              disabled={!editable || isApplied}
            />
          </div>
          <div className="col">
            <span>Вид фактуриране</span>
            <Inputs.RadioButtons
              buttons={[
                { label: 'Веднъж месечно', value: 'monthly' },
                { label: 'Междинно', value: 'intermediate' },
              ]}
              value={isClientProfile ? edits?.producerInvoicingType || data?.invoiceSettings?.producerInvoicingType : data?.producerInvoicingType ?? client?.invoiceSettings?.producerInvoicingType}
              onClick={(value) => handleChange({ producerInvoicingType: value })}
              disabled={!editable || isApplied}
            />
          </div>
          {(edits?.producerInvoicingType ?? data?.producerInvoicingType) === 'intermediate' && <div className="col">
            <span>Фактуриране на</span>
            <Inputs.TextLabeled
              label="число"
              value={isClientProfile ? edits?.producerInvoicingIntermediateDate || data?.invoiceSettings?.producerInvoicingIntermediateDate : data?.producerInvoicingIntermediateDate || client?.invoiceSettings?.producerInvoicingIntermediateDate}

              onChange={({ target: { value } }) => handleChange({ producerInvoicingIntermediateDate: value })}
              disabled={!editable || isApplied}
            />
          </div>}
        </div>
      </>
    )}
  </div>
})

export default ProducersAnnexMain