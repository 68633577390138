import React from 'react'
import { isString, isArray } from 'lodash'
import './styles.scss'

const CheckBoxes = ({ buttons, value, onClick, disabled, col, reversed, includeCoverPhotos, }) => {
  return (
    <div className={`checkboxes-container ${col ? 'col' : 'row'}`}>
      {buttons?.map((button, i) => <React.Fragment key={`single-checkbox-${i}`}>
        <div
          className={`single-checkbox ${value?.includes(button?.value || button) && 'selected'} ${reversed && 'reversed'} ${disabled || button.disabled && 'disabled'}`}
          onClick={() => {
            if (disabled || button.disabled || !onClick) return
            if (value && !isArray(value)) {
              onClick()
              return
            }
            if (value?.includes(button?.value || button)) {
              onClick(value.filter((v) => v !== (button?.value || button)))
            } else onClick([...(value || []), button?.value || button])
          }}
        >
          <div className="checkbox-outer">
            <div className={`checkbox-inner`} />
          </div>
          <div className="label-container row">
            {includeCoverPhotos && (
              <div
                className="button-image"
                style={{
                  backgroundImage: `url(${button?.icon ||
                    require('../../../assets/images/default-user-avatar.jpg')

                    })`,
                }}
              />
            )}
            <p>
              {isString(button) ? button : button?.label || button?.value}
            </p>
          </div>
        </div>
        {button?.children}
      </React.Fragment>)}
    </div>
  )
}

export default CheckBoxes
