import React from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Inputs } from '../../../'
import './styles.scss'

const Analysis = ({ }) => {
  const isRequired = (field) => false && field

  return (
    <div className="client-forms-analysis-container">
      <h2>Анализ</h2>
      <div className="row">
        <div className="col col-double">
          <span>ЕРП</span>
          <Inputs.RadioButtons />
        </div>
      </div>
      <div className="row">
        <div className="col ">
          <span>Брой обекти</span>
          <Input.Text
            value=""
            onChange={({ target: { value } }) => { }}
            inputClassName={`${isRequired('')}`}
          />
        </div>
        <div className="col ">
          <span>Застъпващи се ф-ри</span>
          <Input.Text
            value=""
            onChange={({ target: { value } }) => { }}
            inputClassName={`${isRequired('')}`}
          />
        </div>
        <div className="col ">
          <span>Предложена цена МВтч</span>
          <Inputs.TextLabeled
            label="лв./МВтч"
            value=""
            onChange={({ target: { value } }) => { }}
            inputClassName={`${isRequired('')}`}
          />
        </div>
      </div>
      <div className="row row-buttons">
        <Button.Raised className="btn-cancel" text="Откажи" />
        <Button.Raised className="btn-save" text="Запази и продължи" />
      </div>
    </div>
  )
}

export default Analysis
