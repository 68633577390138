import React from 'react'
import * as ModalContainer from 'react-modal'
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from '../../actions'
import { Modals } from './'
import './styles.scss'

ModalContainer.setAppElement('#root')

const Modal = () => {
  const { type, isOpen, props: allProps, overlayStyles = {}, modalStyles = {}, shouldCloseOnOverlayClick = true, shouldCloseOnEsc = true } = useSelector((s) => s.general.modal)
  const dispatch = useDispatch()
  const props = { ...allProps, close: () => dispatch(setModal({ isOpen: false, type: "", props: {} })) }

  const renderContent = () => {
    switch (type) {
      case 'confirmation':
        return <Modals.Confirmation {...props} />
      case 'intermediateInvoicesPrice':
        return <Modals.IntermediateInvoicesPrice {...props} />
      case 'objectStatus':
        return <Modals.ObjectStatus {...props} />
      case 'clientsColumns':
        return <Modals.ClientsColumns {...props} />
      case 'newTask':
        return <Modals.NewTask {...props} />
      case 'task':
        return <Modals.Task {...props} />
      case 'colleagues':
        return <Modals.Colleagues {...props} />
      case 'profile':
        return <Modals.Profile {...props} />
      case 'changePassword':
        return <Modals.ChangePassword {...props} />
      case 'newEmployee':
        return <Modals.NewEmployee {...props} />
      case 'invoicesFileUpload':
        return <Modals.InvoicesFileUpload {...props} />
      case 'connectedClientGroup':
        return <Modals.ConnectedClientGroup {...props} />
      case 'clientsStatus':
        return <Modals.ClientsStatus {...props} />
      case 'clientEditMOL':
        return <Modals.ClientEditMOL {...props} />
      case 'createClientsGroup':
        return <Modals.CreateClientsGroup {...props} />
      case 'clientsProduct':
        return <Modals.ClientsProduct {...props} />
      case 'registerImportDocuments':
        return <Modals.RegisterImportDocuments {...props} />
      case 'existingFileWarning':
        return <Modals.ExistingFileWarning {...props} />
      case 'clientsLeaving':
        return <Modals.ClientsLeaving {...props} />
      case 'invoiceGroups':
        return <Modals.InvoiceGroups {...props} />
      case 'exportAjur':
        return <Modals.ExportAjur {...props} />
      case 'exportCustoms':
        return <Modals.ExportCustoms {...props} />
      case 'responsibleForRegistration':
        return <Modals.ResponsibleForRegistration {...props} />
      case 'exportEasypay':
        return <Modals.ExportEasypay {...props} />
      case 'invoiceComment':
        return <Modals.InvoiceComment {...props} />
      case 'editCommissions':
        return <Modals.EditCommissions {...props} />
      case 'addCommission':
        return <Modals.AddCommission {...props} />
      case 'deleteCommission':
        return <Modals.DeleteCommission {...props} />
      case 'generateInvoices':
        return <Modals.GenerateInvoices {...props} />
      case 'compensationSettings':
        return <Modals.CompensationSettings {...props} />
      case 'priceActiveFrom':
        return <Modals.PriceActiveFrom {...props} />
      case 'generateNotificationsAnnexes':
        return <Modals.GenerateNotificationsAnnexes {...props} />
      case 'redistributeDealers':
        return <Modals.RedistributeDealers {...props} />
      case 'redistributeDealersFile':
        return <Modals.RedistributeDealersFile {...props} />
      case 'emailStats':
        return <Modals.EmailStats {...props} />
      case 'obligationsDate':
        return <Modals.ObligationsDate {...props} />
      case 'producersReferencesGenerate':
        return <Modals.ProducersReferencesGenerate {...props} />
      case 'producerSettingsFromObject':
        return <Modals.ProducerSettingsFromObject {...props} />
      case 'mergedObjects':
        return <Modals.MergedObjects {...props} />
      case 'uploadFiles':
        return <Modals.UploadFiles {...props} />
      case 'massEditClientsPrice':
        return <Modals.MassEditClientsPrice {...props} />
      case 'mailerTestEmail':
        return <Modals.MailerTestEmail {...props} />
      case 'inputText':
        return <Modals.InputText {...props} />
      case 'clientsGroupsForm':
        return <Modals.ClientsGroupsForm {...props} />
      case 'inputDropdown':
        return <Modals.InputDropdown {...props} />
      case 'inputDate':
        return <Modals.InputDate {...props} />
      case 'updateClientTypeMixed':
        return <Modals.UpdateClientTypeMixed {...props} />
      case 'templatedInvoiceConfirmed':
        return <Modals.TemplatedInvoiceConfirmed {...props} />
      case 'exportAjurProducersTemplated':
        return <Modals.ExportAjurProducersTemplated {...props} />
      case 'inputDateRange':
        return <Modals.InputDateRange {...props} />
      case 'tableColumnsManager':
        return <Modals.TableColumnsManager {...props} />
      case 'downloadFiles':
        return <Modals.DownloadFiles {...props} />
      case 'communicationCorrections':
        return <Modals.CommunicationCorrections {...props} />
      case 'userDaysOff':
        return <Modals.UserDaysOff {...props} />
      case 'calculatePenalty':
        return <Modals.CalculatePenalty {...props} />
      default:
        return null
    }
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      onRequestClose={() => {
        dispatch(setModal({ isOpen: false }))
        if (props?.onRequestClose) props.onRequestClose()
      }}
      style={{
        overlay: {
          overflow: 'auto',
          backgroundColor: 'rgba(15, 15, 15, 0.8)',
          zIndex: 10,
          ...overlayStyles
        },
        content: {
          position: 'fixed',
          ...modalStyles
        },
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      className={`modal-container ${type}`}
    >
      {renderContent()}
    </ModalContainer>
  )
}
export default Modal
