export const userDaysOffTypes = {
    CREATE_USER_DAYS_OFF: 'userDaysOff/CREATE_USER_DAYS_OFF',
    UPDATE_USER_DAYS_OFF: 'userDaysOff/UPDATE_USER_DAYS_OFF',
    DELETE_USER_DAYS_OFF: 'userDaysOff/DELETE_USER_DAYS_OFF',
    GET_USER_DAYS_OFF_BY_MONTH: 'userDaysOff/GET_USER_DAYS_OFF_BY_MONTH',
    GET_USER_DAYS_OFF_BY_USER: 'userDaysOff/GET_USER_DAYS_OFF_BY_USER',
}

export const createUserDaysOff = (payload) => ({
    type: userDaysOffTypes.CREATE_USER_DAYS_OFF,
    ...payload
})

export const updateUserDaysOff = (payload) => ({
    type: userDaysOffTypes.UPDATE_USER_DAYS_OFF,
    ...payload
})

export const deleteUserDaysOff = (payload) => ({
    type: userDaysOffTypes.DELETE_USER_DAYS_OFF,
    ...payload
})

export const getUserDaysOffByMonth = (payload) => ({
    type: userDaysOffTypes.GET_USER_DAYS_OFF_BY_MONTH,
    ...payload
})

export const getUserDaysOffByUser = (payload) => ({
    type: userDaysOffTypes.GET_USER_DAYS_OFF_BY_USER,
    ...payload
})