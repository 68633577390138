import { getCommissionsCreatedPayments, getCommissionsCreatedReference, getCommissionsReference, getCommissionsReferenceDetails, setReferencesFields, startLoading, sendCommissions, deleteCommission, setModal } from "actions"
import { Shared, Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import { initialState } from 'reducers/references'
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import "./styles.scss"
import { commissionsReferenceCreatedDetailsFields, commissionsReferenceDetailsFields, commissionsReferenceFields, commissionsStatuses, referencesCommissionsTypes, commissionsReferenceCreatedFields, } from "config/constants"
import { mapQuerySearch, renderCell, asyncForEach, xlsxExport } from "utilities"
import { useQuery } from "hooks"
import { ScrollSync } from "react-scroll-sync"
import { mapKeys } from "lodash"
import Popup from "reactjs-popup"

const Commissions = ({ TypeSelector }) => {
  const { type, startDate, endDate, sort: sortParam = '{}', filter: filterParam = '{}', clear, handleUrlChange, handleUrlChangeMultiple } = useQuery({ type: referencesCommissionsTypes })
  const getMonths = (start, end) => Array.from({ length: moment(end).diff(moment(start), 'month') + 1 }).map((_, index) => moment(start).add(index, 'month').format('MM/YYYY'),);
  commissionsReferenceFields.payments = [
    { label: 'Комисионер', value: 'userFullName', sortable: true },
    { label: 'Дължимо без ДДС', value: 'unpaid', size: 150, sortable: true },
    getMonths(startDate ?? moment().subtract(3, 'months').startOf('month').toDate(), endDate ?? moment().endOf('month').toDate()).map((monthYear) => [
      { label: `${monthYear} - Статус`, value: `${monthYear} - status`, size: 300, sortable: true },
      { label: `${monthYear} - Имейл изпратен`, value: `${monthYear} - emailSent`, size: 190, sortable: true },
      { label: `${monthYear} - Ф/Д`, value: `${monthYear} - uploadedFileDate`, size: 230, sortable: true },
    ]).flat()
  ].flat()


  const dispatch = useDispatch()
  const { reference, totalDocs, hasNextPage, nextPage, showCheckboxes, selectedCheckboxes } = useSelector(({ references }) => references)

  const availableSort = ["consumption", "total", "singleUnitPrice", 'unpaid']
  const availableFilters = {
    userFullName: { type: 'regex' },
    userIsCompany: { type: 'dropdown', values: [{ value: "true", label: "Юридическо лице" }, { value: 'false', label: 'Физическо лице' },], single: true },
    userEmail: { type: 'regex' },
    clientFullName: { type: 'regex' },
    clientIdentifier: { type: 'regex' },
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('status')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'dropdown', values: Object.entries(commissionsStatuses).map(([key, value]) => ({ value: key, label: value })) } }), {}),
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('emailSent')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'date', } }), {}),
    ...commissionsReferenceFields.payments.filter(({ value }) => value.includes('uploadedFileDate')).reduce((acc, { value }) => ({ ...acc, [value]: { type: 'date', } }), {}),
    unpaid: { type: 'number' },
    unpaidWitVat: { type: 'number' }
  }
  const keyMap = {
    "commissionClientMonth.consumption": "consumption",
    "commissionClientMonth.total": "total",
    "commissionClientMonth.singleUnitPrice": "singleUnitPrice"
  }
  const filter = useMemo(() => JSON.parse(filterParam), [filterParam])
  const sort = useMemo(() => JSON.parse(sortParam), [sortParam])
  const searchQuery = useMemo(() => ({ ...mapQuerySearch(sort, filter, undefined, availableFilters) }), [sortParam, filterParam])


  const fetch = useCallback((payload, onSuccess = () => { }) => {
    dispatch(startLoading())
    if (type === 'generated') dispatch(getCommissionsReference({ payload: { monthYear: startDate ? moment(startDate).format("MM/YYYY") : moment().subtract(1, 'months').format("MM/YYYY"), ...payload }, onSuccess }))
    else if (type === 'created') dispatch(getCommissionsCreatedReference({ payload: { ...searchQuery, ...payload }, onSuccess }))
    else if (type === 'payments') dispatch(getCommissionsCreatedPayments({ payload: { from: startDate && moment(startDate), to: endDate && moment(endDate).add(1, 'seconds'), ...searchQuery, ...payload }, onSuccess }))
  }, [type, startDate, endDate, searchQuery])

  const fetchDetails = (row) => {
    if (!row.objects) {
      dispatch(startLoading())
      dispatch(getCommissionsReferenceDetails({ payload: { ids: row.clientCommissions }, client: row._id }))
    }
  }

  useEffect(() => {
    fetch()
    return () => dispatch(setReferencesFields({ initialState }))
  }, [fetch])

  const tableRef = useRef(null)
  const DetailsTable = useCallback(({ row }) => {
    if (type === 'generated') return <Shared.Table
      columns={commissionsReferenceDetailsFields}
      headerWidth={commissionsReferenceDetailsFields.reduce((a, c) => a + (c.size || 300), 0)}
      data={row.original.objects}
      renderCell={renderCell.commissionsReferenceDetails}
      containerStyle={{ maxHeight: 350 }}
    />

    else if (type === 'created') return <Shared.Table
      columns={commissionsReferenceCreatedDetailsFields}
      headerWidth={commissionsReferenceCreatedDetailsFields.reduce((a, c) => a + (c.size || 300), 0)}
      data={row.original.objects}
      renderCell={renderCell.commissionsReferenceCreatedDetails}
      containerStyle={{ maxHeight: 350 }}
    />
  }, [reference])


  const sortingComponents = commissionsReferenceFields[type]?.filter(({ sortable }) => sortable).reduce((acc, { value }) => ({
    ...acc,
    [value]: <Popup
      contentStyle={{ width: 'auto' }}
      keepTooltipInside='.table-container'
      trigger={<div className="icon icon-arrow-down" />}
      activeFilters={{ ...mapKeys(searchQuery, (_, key) => keyMap[key] ?? key), sort: mapKeys(searchQuery.sort, (_, key) => keyMap[key] ?? key) }}
    >
      {close => <Shared.SortingComponent
        hide={close}
        column={value}
        availableSort={availableSort}
        availableFilters={availableFilters}
        keyMap={keyMap}
      />}
    </Popup>
  }), {})

  return <div className={`screen-references-container commissions`}>
    <div className="screen-references-header row">
      <h2>Справка за</h2>
      {TypeSelector()}
      <Inputs.RoundedButtons
        buttons={referencesCommissionsTypes}
        value={type}
        onChange={({ value }) => {
          clear()
          handleUrlChangeMultiple({ type: value })
          dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
        }}
      />
      {type === 'generated' && <div className="row row-options">
        <Inputs.DatePicker
          selected={startDate ? moment(startDate).toDate() : moment().subtract(1, 'months').toDate()}
          onChange={(value) => {
            handleUrlChange('startDate', value)
            dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          }}
          maxDate={new Date()}
          dateFormat={'MMMM'}
          showMonthYearPicker
          showFullMonthYearPicker
          className="month-picker"
        />
        {showCheckboxes
          ? <div className='row row-buttons'>
            <Button.Raised className="btn-cancel" text="Откажи" onClick={() => dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
            <Button.Raised text="Изпрати"
              onClick={() => {
                dispatch(startLoading())
                dispatch(sendCommissions({ payload: { ids: selectedCheckboxes }, onSuccess: () => { fetch(); dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] })) } }))
              }}
            />
          </div>
          : <Button.Raised text="Изпрати"
            onClick={() => {
              dispatch(startLoading())
              fetch({ noPagination: true })
              dispatch(setReferencesFields({ showCheckboxes: { action: 'emails' } }))
            }}
          />
        }
      </div>}
      {type === 'created' && (showCheckboxes
        ? <div className="row row-buttons">
          <Button.Raised className="btn-cancel" text="Откажи" onClick={() => dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
          <Button.Raised text="Изтрий"
            disabled={!selectedCheckboxes.length}
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'deleteCommission', props: {
                onSuccess: async (monthYear) => {
                  dispatch(startLoading())
                  await asyncForEach(selectedCheckboxes, async (_id) => await new Promise((resolve) => dispatch(deleteCommission({ payload: { _id, monthYear }, onSuccess: resolve }))))
                  dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
                  fetch()
                }
              }
            }))
            }
          />
        </div>
        : <div className="row row-buttons">
          <Button.Raised text="Изтрий"
            onClick={() => {
              dispatch(startLoading())
              fetch({ noPagination: true })
              dispatch(setReferencesFields({ showCheckboxes: { action: 'deleteCommission' } }))
            }}
          />
          <div
            className='icon icon-export'
            onClick={() => {
              dispatch(startLoading())
              fetch({ noPagination: true }, ({ docs }) => xlsxExport({
                fields: commissionsReferenceCreatedFields.filter(({ value }) => !['objects'].includes(value)),
                data: docs,
                render: renderCell.commissionsReference.createdExport,
              }))
            }}
          />
        </div>)}
      {type === 'payments' && <div className='row' style={{ marginLeft: 'auto' }}>
        <Inputs.DatePicker
          selected={startDate ? moment(startDate).toDate() : moment().subtract(3, 'months').startOf('month').toDate()}
          onChange={(value) => {
            handleUrlChange('startDate', moment(value).startOf('month').toDate())
            dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          }}
          maxDate={new Date()}
          dateFormat={'MMMM'}
          showMonthYearPicker
          showFullMonthYearPicker
          className="month-picker"
        />
        <Inputs.DatePicker
          selected={endDate ? moment(endDate).toDate() : moment().endOf('month').toDate()}
          onChange={(value) => {
            handleUrlChange('endDate', moment(value).endOf('month').toDate())
            dispatch(setReferencesFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          }}
          maxDate={new Date()}
          dateFormat={'MMMM'}
          showMonthYearPicker
          showFullMonthYearPicker
          className="month-picker"
        />
      </div>}
    </div>
    <div className="screen-references-content row">
      <div className="references-commissions-container">
        <div className="references-commissions-content">
          <Shared.ConditionalWrapper
            condition={type === 'payments'}
            wrapper={(children) => <ScrollSync>
              <div className="row row-locked">
                <Shared.Table
                  fixedWidth
                  columns={commissionsReferenceFields[type].slice(0, 1)}
                  data={reference}
                  renderCell={(row, field, options) => renderCell.commissionsReference[type](row, field, {
                    ...options,
                    toggleRowExpanded: tableRef.current.toggleRowExpanded,
                    dispatch,
                    fetch,
                    fetchDetails
                  })}
                  sortingComponent={sortingComponents}
                  joinRightSide
                  containerStyle={{ position: 'sticky', top: 0, left: 0, backgroundColor: '#fff', zIndex: 1 }}
                  useSyncScroll
                />
                {children}
              </div>
            </ScrollSync>}
          >
            <Shared.Table
              ref={tableRef}
              columns={commissionsReferenceFields[type]?.filter(({ value }) => type !== 'payments' || value !== 'userFullName')}
              data={reference}
              renderCell={(row, field, options) => renderCell.commissionsReference[type](row, field, {
                ...options,
                toggleRowExpanded: tableRef.current.toggleRowExpanded,
                dispatch,
                fetch,
                fetchDetails
              })}
              headerWidth={commissionsReferenceFields[type]?.filter(({ value }) => type !== 'payments' || value !== 'userFullName')?.reduce((acc, { size = 300 }) => acc + size, 0)}
              maxExpandHeight={550}
              handlePagination={() => hasNextPage && fetch({ page: nextPage })}
              sortingComponent={sortingComponents}
              useCheckboxes={!!showCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              onCheckboxChange={(value) => dispatch(setReferencesFields({ selectedCheckboxes: value }))}
              disabledCheckboxes={showCheckboxes?.action === 'emails' ? reference?.filter(({ emailSent }) => emailSent)?.map(({ _id }) => _id) : []}
              joinLeftSide={type === 'payments'}
              useSyncScroll={type === 'payments'}
            >
              {DetailsTable}
            </Shared.Table>
          </Shared.ConditionalWrapper>
        </div>
        <div className="references-commissions-footer row">
          <div className="references-commissions-inner-footer row">
            <p className="row">Комисионни: {totalDocs || 0}</p>
            {type === 'payments' && <>
              <p className="row">Дължимо без ДДС: {Number(reference?.[0]?.totalUnpaid ?? 0)?.toFixed(2)} лв.</p>
            </>}
            {showCheckboxes && <p className="row">Избрани: {selectedCheckboxes?.length || 0}</p>}
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Commissions