import React, { Fragment, useEffect, useState } from "react"
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Filter from './Filter'
import Column from './Column'
import "./styles.scss"
import { emit, useQuery } from "../helpers"
import { pick } from "lodash"
import Popup from "reactjs-popup"
import { useDispatch } from "react-redux"
import { setOverlay } from "actions"

const Board = (props) => {
    const dispatch = useDispatch()
    const { socket, availableFilters, columns, setColumns, boardForm } = props
    const { board, handleUrlChange } = useQuery()

    const [showNew, setShowNew] = useState(false)
    const [columnName, setColumnName] = useState('')
    const handleCreateColumn = () => {
        emit(socket, { type: 'categories/create', payload: { title: columnName, board } })
        setColumnName("")
        setShowNew(false)
    }

    const [showFilter, setShowFilter] = useState(false)
    const [filters, setFilters] = useState({})
    useEffect(() => {
        const payload = pick(filters, ['status', 'priority', 'assignedTo', 'dateStart', 'dateEnd'])
        emit(socket, { type: 'tasks/getMany', payload: { filters: payload, board } })
    }, [socket, filters])

    useEffect(() => () => setColumns([]), [])

    const reorder = (list, start, end) => {
        const reordered = [...list]
        const [removed] = reordered.splice(start, 1);
        reordered.splice(end, 0, removed);
        return reordered
    }

    const handleColumnsPositioning = ({ type, source, destination, ...r }) => {
        if (!destination) return
        if (source.droppableId === destination.droppableId && source.index === destination.index) return
        if (type === 'COLUMN') {
            const reordered = reorder(columns, source.index, destination.index)
            setColumns(reordered)
            emit(socket, { type: 'categories/reorder', payload: reordered.map(([c]) => c._id) })
            return;
        }

        const [, current] = columns.find(([c]) => c._id === source.droppableId)
        const currentIndex = columns.findIndex(([c]) => c._id === source.droppableId)
        const [, next] = columns.find(([c]) => c._id === destination.droppableId)
        const nextIndex = columns.findIndex(([c]) => c._id === destination.droppableId)
        const target = current[source.index];

        if (source.droppableId === destination.droppableId) { // moving to same list
            const reordered = reorder(current, source.index, destination.index);
            const newColumns = [...columns]
            newColumns.splice(currentIndex, 1, [columns[currentIndex][0], reordered])
            emit(socket, { type: 'tasks/reorder', payload: reordered.map(({ _id }) => _id) })
            setColumns(newColumns)
            return
        } else { //moving to different list
            const newColumns = [...columns]
            const newCurrent = [...current]
            newCurrent.splice(source.index, 1)
            newColumns.splice(currentIndex, 1, [columns[currentIndex][0], newCurrent])
            const newNext = [...next]
            newNext.splice(destination.index, 0, target)
            newColumns.splice(nextIndex, 1, [columns[nextIndex][0], newNext])
            emit(socket, {
                type: "tasks/changeCategory", payload: {
                    oldCategoryId: source.droppableId,
                    newCategoryId: destination.droppableId,
                    taskId: target._id,
                    newOrder: newNext.map(({ _id }) => _id)
                }
            })
            setColumns(newColumns)
        }
    }

    return <div className="task-management-board-outer-container">
        <div className="task-management-board-header card row">
            <h2>{boardForm.title}</h2>
            <div className="row row-buttons">
                <div className="row row-permissions" onClick={() => dispatch(setOverlay({ isOpen: true, type: 'taskBoardPermissions', props: { permissions: boardForm.permissions } }))}>
                    {boardForm?.permissions?.map(({ user, userGroup }, index, array) => {
                        if (array.length > 5) {
                            if (index === array.length - 1) return <span key="additional">+{array.length - 5}</span>
                            if (index >= 5) return null
                        }
                        if (user) return <Fragment key={user._id}><Popup trigger={<div className="icon icon-avatar" style={{ backgroundImage: `url(${user.coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})` }} />} contentStyle={{ width: 'auto' }} on={['hover']} >{user.fullName}</Popup></Fragment>
                        if (userGroup) return <Fragment key={userGroup._id}>
                            <Popup trigger={<div className="icon icon-avatar row">{userGroup.name.split(' ').map((str) => str[0]).join('')}</div>} contentStyle={{ width: 'auto' }} on={['hover']} >{userGroup.users.map(({ coverPhoto, fullName, _id }) => <div className="row">
                                <div className="icon icon-avatar" style={{ backgroundImage: `url(${coverPhoto || require('../../../assets/images/default-user-avatar.jpg')})` }} />
                                <span>{fullName}</span>
                            </div>)}</Popup>
                        </Fragment>
                    })}
                </div>
                <Popup
                    trigger={<div className="tasks-icon tasks-icon-tasks-settings" />}>
                    {close => <div className="task-management-popup-column-container">
                        <div className="task-management-popup-column-header row">
                            <h2>Моля изберете</h2>
                            <div className="tasks-icon tasks-icon-close" onClick={close} />
                        </div>
                        <div className="row row-action" onClick={() => handleUrlChange({ showForm: 'board', boardId: boardForm._id, board: undefined })}><p>Редактирай група</p><div className="tasks-icon tasks-icon-pen"></div></div>
                        <div className="line" />
                        <div className="row row-action" onClick={() => { }}><p>Изтрий група</p><div className="tasks-icon tasks-icon-delete"></div></div>
                    </div>}
                </Popup>
            </div>
        </div>
        <div className="task-management-board-container row">
            <DragDropContext onDragEnd={handleColumnsPositioning}>
                <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                    {(provided) => <div className="row-droppable" ref={provided.innerRef} {...provided.droppableProps}>
                        {columns?.map(([column, tasks], i) => <Column key={column._id} index={i} column={{ ...column, tasks }} setShowFilter={setShowFilter} {...props} />)}
                        {provided.placeholder}
                        <div className="new-column-container">
                            {showNew ? <div className="new-column-inner-container">
                                <span>Заглавие</span>
                                <input className="input-text-container" value={columnName || ''} onChange={({ target: { value } }) => setColumnName(value)} />
                                <div className="row row-buttons">
                                    <div className="raised-button-container cancel" onClick={() => setShowNew(false)} ><span>Откажи</span></div>
                                    <div className={`raised-button-container ${!columnName && 'disabled'}`} onClick={handleCreateColumn} ><span>Добави</span></div>
                                </div>
                            </div> : <div className="card btn-add-column" onClick={() => setShowNew(true)}><span>Добави категория</span> </div>}
                        </div>
                    </div>}
                </Droppable>
            </DragDropContext>


            <Filter isOpen={showFilter} hide={() => setShowFilter(false)} filters={filters} setFilters={setFilters} assignees={availableFilters?.assignedTo} />
        </div>
    </div>
}

export default Board