import React, { useState, useEffect } from "react"
import { Button } from '@makedonski/admin-ui'
import { useDispatch } from 'react-redux'
import { isEmpty } from "lodash"
import { setModal, getUsers, startLoading, updateClient } from 'actions'
import { Popup as PopupComponents } from 'components'
import "./styles.scss"
import Popup from "reactjs-popup"

const ResponsibleForRegistration = ({ producers, clients, currentlyResponsible, selected: selectedProp, onSuccess }) => {
    const dispatch = useDispatch()
    const close = () => dispatch(setModal({ isOpen: false }))

    const [options, setOptions] = useState([])
    const getOptionsPayload = { select: 'fullName coverPhoto' }
    const updateOptions = (fullName) => dispatch(getUsers({
        payload: { ...getOptionsPayload, fullName },
        onSuccess: (res) => setOptions((options) => [...(options || []), res])
    }))
    useEffect(() => { dispatch(getUsers({ payload: getOptionsPayload, onSuccess: (res) => setOptions(res) })) }, [])

    const [selected, setSelected] = useState(selectedProp || null)


    return <div className="modal-responsible-for-registration-container">
        <div className="modal-responsible-for-registration-header row">
            <h2>Разпредели отговорници</h2>
            <Button.Icon name="plus" size="26px" className="btn-close" onClick={close} /></div>
        <div className="modal-responsible-for-registration-content row">

            <span>Отговорник:</span>
            <div className="add-icon-container row">
                <Popup
                    contentStyle={{ width: 'auto', maxWidth: 400 }}
                    position='right center'
                    trigger={<div className="icon icon-add" />}
                >
                    {close => <PopupComponents.Select
                        hide={close}
                        search
                        handleSearch={updateOptions}
                        value={selected?.map(({ _id }) => _id)}
                        options={options?.map(({ fullName, _id }) => ({ label: fullName, value: _id }))}
                        handleChange={(value) => setSelected(value.map((user) => options.find(({ _id }) => user === _id)))}
                        text={{ main: 'Търси по име', secondary: 'Потребители' }}
                    />}
                </Popup>
                {!isEmpty(selected) && selected.map(({ fullName }, i) => (<div key={`user-${i}`} className="row row-client">
                    <Button.Icon name="plus" color="red" onClick={() => {
                        const newSelected = [...selected]
                        newSelected.splice(i, 1)
                        setSelected(newSelected)
                    }} />
                    <span>{fullName}</span>
                </div>))}
            </div>

        </div>
        <div className="modal-responsible-for-registration-footer row">
            <Button.Raised text='Откажи' className="cancel" onClick={close} />
            <div style={{ width: 30 }} />
            <Button.Raised text='Разпредели' onClick={() => {
                dispatch(startLoading())
                clients.forEach((_id, i) => {
                    let payload = selected.map(({ _id }) => _id)
                    if (currentlyResponsible) payload = [...new Set([...payload, ...currentlyResponsible[_id].map(({ _id }) => _id)])]
                    dispatch(updateClient({
                        data: producers ? { _id, responsibleForRegistrationProducers: payload } : { _id, responsibleForRegistration: payload },
                        onSuccess: () => {
                            if (i !== clients.length - 1) return
                            onSuccess()
                            close()
                        }
                    }))
                })
            }} />
        </div>
    </div >
}

export default ResponsibleForRegistration