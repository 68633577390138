import React, { useMemo } from 'react'
import { Input } from '@makedonski/admin-ui'
import { Popup as PopupComponents, Shared } from '../../'
import './styles.scss'
import { isArray } from 'lodash'
import Popup from 'reactjs-popup'

const Select = ({ className = '', editable, value, showRequired, options = [], useTooltip, ...rest }) => {
  const isRequired = () => showRequired && !value?.length && 'required'
  const popupProps = { value: rest.single && isArray(value) ? value[0] : value, options, ...rest }
  const text = useMemo(() => {
    if (rest.single) {
      const selected = options.find((opt) => opt?._id === value || opt?.value === value)
      return selected?.name || selected?.label || ""
    }
    return value?.map((type) => options?.find(({ _id }) => type?._id === _id || type === _id)?.name).join('; ') || ''
  }, [value, options])
  return (
    <Shared.ConditionalWrapper
      condition={useTooltip && text}
      wrapper={(children) => <Popup
        trigger={children}
        on={['hover']}
      >{text}</Popup>}
    >
      <div className={`inputs-select-container ${className}`}>
        {editable && <Popup
          trigger={<div className="activity-icon-container row">
            <div className="icon icon-arrow-right" />
          </div>}
        >
          {close => <PopupComponents.Select hide={close} {...popupProps} />}
        </Popup>}
        <Input.Text value={text} disabled inputClassName={`${isRequired(value)}`} />
      </div>
    </Shared.ConditionalWrapper >
  )
}

export default Select
