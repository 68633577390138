import React from 'react'
import { Button, Input } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import './styles.scss'

const clients = [
  { _id: '1', companyName: 'aaa' },
  { _id: '2', companyName: 'bbb' },
  { _id: '3', companyName: 'aaa' },
  { _id: '4', companyName: 'bbb' },
  { _id: '5', companyName: 'aaa' },
  { _id: '6', companyName: 'bbb' },
  { _id: '7', companyName: 'aaa' },
  { _id: '8', companyName: 'bbb' },
  { _id: '9', companyName: 'aaa' },
  { _id: '10', companyName: 'bbb' },
]

const NewTaskClient = ({ hide }) => {
  return (
    <div className="popup-new-task-client-container">
      <div className="row">
        <h4>Търси по име/ЕИК/ЕГН</h4>
        <Button.Icon name="plus" onClick={hide} />
      </div>

      <Input.Text placeholder="Търси" />
      <h4 className="add-margin">Клиент</h4>
      <div className="scroll-container">
        <Inputs.RadioButtons
          buttons={clients?.map(({ _id, companyName }) => ({
            value: _id,
            label: companyName,
          }))}
          value="1"
          col
        />
      </div>

      <Button.Raised text="Задай" className="btn-apply" onClick={() => {}} />
    </div>
  )
}

export default NewTaskClient
