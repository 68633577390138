import React from 'react'
import { Button } from '@makedonski/admin-ui'
import { productsPricesFields } from '../../../../config/constants'
import { Inputs } from '../../../'
import './styles.scss'
import { checkIsDealer } from 'utilities'

const ProductPrices = ({ products = [], data, handleChange, showRequired, editable = true }) => {
  const isDealer = checkIsDealer()
  const isRequired = (field) => showRequired && [undefined, null, 0].includes(data?.price?.[field]) && 'required'

  const floorAndCeilingPerPeriod = <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
    <span>На месец</span>
    <Button.Switch
      isOn={data?.price?.floorAndCeilingPerPeriod}
      onChange={() => editable && handleChange({ 'floorAndCeilingPerPeriod': !(data?.price?.floorAndCeilingPerPeriod) })}
    />
  </div>

  const caps = <>
    <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
      <span>{`Без такса при БНЕБ < таксата`}</span>
      <Button.Switch
        isOn={data?.price?.noDeductionIfMarketPriceIsLower}
        onChange={() => editable && handleChange({ 'noDeductionIfMarketPriceIsLower': !(data?.price?.noDeductionIfMarketPriceIsLower) })}
      />
    </div>
    <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
      <span>{`Капа до 0`}</span>
      <Button.Switch
        isOn={data?.price?.capAtZero}
        onChange={() => editable && handleChange({ 'capAtZero': !(data?.price?.capAtZero) })}
      />
    </div>
  </>

  const marketIsPercent = <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
    <span>В процент</span>
    <Button.Switch
      isOn={data?.price?.marketIsPercent}
      onChange={() => editable && handleChange({
        'marketIsPercent': !(data?.price?.marketIsPercent),
        ...(data?.price?.noDeductionIfMarketPriceIsLower && { 'noDeductionIfMarketPriceIsLower': !(data?.price?.noDeductionIfMarketPriceIsLower) }),
        ...(data?.price?.capAtZero && { 'capAtZero': !(data?.price?.capAtZero) }),
      })}
    />
  </div>

  const marketIsBase = <div className={`col col-mini client-forms-product-prices col-switch ${!editable && 'disabled'}`}>
    <span>Борса База</span>
    <Button.Switch
      isOn={data?.price?.marketIsBase}
      onChange={() => editable && handleChange({ 'marketIsBase': !(data?.price?.marketIsBase) })}
    />
  </div>

  const taxPerObject = <div className="col col-mini">
    <span>Такса административно обслужване</span>
    <Inputs.TextLabeled
      label="лв./точка"
      value={data?.price?.taxPerObject ?? ''}
      onChange={({ target: { value } }) => {
        if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(value) && value !== '') return
        handleChange({ 'taxPerObject': value })
      }}
      disabled={!editable}
    />
  </div>

  return productsPricesFields.map(({ types, value, label, innerLabel }) => {
    const tempProduct = products?.find(({ _id }) => data?.product?._id === _id || data?.product === _id)
    const type = tempProduct?.name
    if (!type || !types?.includes(type)) return null
    return (
      <React.Fragment key={`price-field-${value}`}>
        <div className="col col-mini">
          <span>{label}</span>
          {['Микс', 'Смесен процент'].includes(type) && value === 'basePercentage' ? (
            <Inputs.PercentageSplit
              value={data?.price?.basePercentage ?? ''}
              onChange={(value) => {
                if (value > 100 || (!/^\d+$/.test(value) && value !== '')) return
                handleChange({ basePercentage: value })
              }}
              inputClassName={`${isRequired('basePercentage')}`}
              disabled={!editable}
            />
          ) :
            [
              'Борса',
              'Борса с Под на цената',
              'Борса с Таван на цената',
              'Борса с Под и Таван на цената',
              'Отделно балансиране',
              'Борса минус',
              'Борса минус с Под на цената',
              'Борса минус с Таван на цената',
              'Борса минус с Под и Таван на цената',
            ].includes(type)
              && !['marketFloor', 'marketCeiling'].includes(value)
              && data?.price?.marketIsPercent
              ? (
                <Inputs.TextLabeled
                  label="%"
                  value={data?.price?.[value] ?? ''}
                  onChange={({ target }) => {
                    if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(target?.value) && target?.value !== '') return
                    if (Number(target?.value) > 100) return
                    handleChange({ [value]: target.value })
                  }}
                  inputClassName={`${isRequired(value)}`}
                  disabled={!editable}
                />
              ) : (
                <Inputs.TextLabeled
                  label={innerLabel ?? "лв./МВтч"}
                  value={data?.price?.[value] ?? ''}
                  onChange={({ target }) => {
                    if (!/^\d{0,3}(\.||\.\d{0,2})?$/.test(target?.value) && target?.value !== '') return
                    handleChange({ [value]: target.value })
                  }}
                  inputClassName={`${isRequired(value)}`}
                  disabled={!editable}
                />
              )}
        </div>
        {['Борса', 'Борса минус', 'Отделно балансиране'].includes(type) && marketIsPercent}
        {!isDealer && ['Борса с Под на цената', 'Борса с Таван на цената', 'Борса с Под и Таван на цената'].includes(type) && ['market'].includes(value) && marketIsPercent}
        {!isDealer && ['Борса минус', 'Отделно балансиране'].includes(type) && tempProduct?.forProducer && !data?.price?.marketIsPercent && caps}
        {!isDealer && ['Борса', 'Отделно балансиране'].includes(type) && !tempProduct?.forProducer && marketIsBase}
        {['Борса'].includes(type) && ['Zagora Energy', 'TOKI'].some((platform) => (process.env.REACT_APP_PLATFORM ?? '').includes(platform)) && taxPerObject}
        {!isDealer && ['Борса минус с Под на цената',].includes(type) && ['marketFloor'].includes(value) && <>{floorAndCeilingPerPeriod}{caps}</>}
        {!isDealer && ['Борса минус с Таван на цената', 'Борса минус с Под и Таван на цената'].includes(type) && ['marketCeiling'].includes(value) && <>{floorAndCeilingPerPeriod}{caps}</>}
      </React.Fragment>)
  })
}

export default ProductPrices
