export const invoiceNumberTypes = {
    GET_CURRENT_INVOICE_NUMBER: 'invoiceNumber/GET_CURRENT_INVOICE_NUMBER',
    SET_CURRENT_INVOICE_NUMBER: 'invoiceNumber/SET_CURRENT_INVOICE_NUMBER',
}

export const getCurrentInvoiceNumber = (payload) => ({
    type: invoiceNumberTypes.GET_CURRENT_INVOICE_NUMBER,
    ...payload
})

export const setCurrentInvoiceNumber = (payload) => ({
    type: invoiceNumberTypes.SET_CURRENT_INVOICE_NUMBER,
    ...payload
})
