import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { isEmpty, isObject, groupBy, pick, isNumber, uniq } from 'lodash'
import {
  setOverlay,
  createComment,
  getClient,
  updateComment,
  deleteFileClient,
  deleteImport,
  startLoading,
  updateInvoiceProgress,
  setModal,
  ignoreObjectForMonth,
  updateFileClient,
  getData,
  updateInvoiceSimple,
  updateClient,
  deleteInterest,
  updateInterest,
  producersReferenceUpdate,
  deleteGroup,
  updateGroup,
  updateCommissionSimple,
  updateObject,
  confirmStatusesRegister,
  updatePenalty,
  updateUser,
  deleteUserDaysOff
} from 'actions'
import { documentTypes, intermediateInvoicesTypes, clientsStatus, allRegistrationStatuses, commissionStatusMap, notificationsStatusesMap, producersReferencesOptions, commissionsStatuses, userDaysOffOptions } from 'config/constants'
import { Client, Inputs, Invoices } from 'components'
import { store, history } from 'config/store'
import { checkIsDealer, getClientStatus, getStatusConfirmationModal, mAdd, mDivide, mMultiply, mSubtract, transformURL, uploadFile } from 'utilities'
import { NavLink } from 'react-router-dom'
import Popup from 'reactjs-popup'

const objects = (object, value, options) => {
  const { fetch, client } = options || {}
  const { dispatch } = store
  const query = new URLSearchParams(location.search)
  const startDate = query.get("startDate")
  const props = { object, field: value, value: object[value], fetch }

  const { product, price, balancingMethod, invoicingMethod, balancingCeiling, balancingFloor } = client?.producerSettings ?? object?.producerSettings ?? {}

  const isDealer = checkIsDealer()
  if (isDealer && ['status', 'issue', 'additionalStatus', 'previousClient', 'statusConfirmed', 'objectionsDate', 'entryEnd', 'activeFrom', 'inactiveFrom', "documentsSentDate", "dpiGeneratedDate", "dpiSignedDate",].includes(value)) return objectsExport(object, value, options)

  switch (value) {
    case 'confirmed':
      return <Client.Cells.ObjectConfirmed {...props} />
    case 'additionalStatus':
      return <Client.Cells.ObjectAdditionalStatus {...props} />
    case 'reasonForLeaving':
      return <Client.Cells.ObjectLeavingReason {...props} />
    case 'status':
      return <Client.Cells.ObjectStatus {...props} />
    case 'requiresNetworkData':
    case 'periodData':
    case 'networkData':
    case 'hasPeriod':
    case 'hasNetwork':
    case 'hasProgress':
      return object[value] ? 'Да' : 'Не'
    case 'isClone':
      return object[value] ? 'Нов клиент' : object?.hasClone ? "Стар клиент" : 'Не'
    case 'statusConfirmed':
      return object?.confirmedOn ? `Да` : "--"
    case 'error':
      return object?.invoicingErrors?.at(0)?.error || '--'
    case 'previousClient':
      return <Client.Cells.ObjectPreviousClient {...props} />
    case 'objectionsDate':
    case 'activeFrom':
    case 'inactiveFrom':
    case 'entryEnd':
      return <Client.Cells.EditCell {...props} />
    // return object[value] ? moment(object[value]).format("DD.MM.YYYY") : "--"
    case "documentsSentDate":
    case "dpiGeneratedDate":
    case "dpiSignedDate":
      return <Client.Cells.ObjectExecutionDate {...props} />
    case 'issue':
      return <Client.Cells.ObjectIssue row={object} field={value} fetch={fetch} />
    case 'ignore':
      return object?.invoicingErrors?.at(0)?.error ?
        <Button.Raised text="Игнорирай" className="cancel" onClick={() => dispatch(setModal({
          isOpen: true,
          type: 'confirmation',
          props: {
            title: "Игнорирай",
            children: `Точката няма да бъде фактурирана за месец ${moment(startDate ?? undefined).subtract(!!startDate ? 0 : 1, 'months').startOf("month").format('MM.YYYY')}`,
            buttons: <div className="row" style={{ justifyContent: 'space-between' }}>
              <Button.Raised text="Откажи" className="cancel" onClick={() => dispatch(setModal({ isOpen: false }))} />
              <div style={{ heigh: 1, width: 10 }}></div>
              <Button.Raised text="Потвърди" onClick={() => dispatch(ignoreObjectForMonth({
                payload: { itn: object.itn, ignoreMonth: moment(startDate ?? undefined).subtract(!!startDate ? 0 : 1, 'months').startOf("month").format('MM/YYYY') },
                onSuccess: () => {
                  dispatch(setModal({ isOpen: false }))
                  fetch()
                }
              }))} />
            </div>
          }
        }))} />
        : null
    case 'producerProduct':
      if (!object?.isProducer) return "--"
      return product?.name ?? '--'
    case 'producerPrice':
      if (!object?.isProducer) return "--"
      if (product?.name === 'Борса минус') return `${price?.market} ${price?.marketIsPercent ? '%' : 'лв./МВтч'}`
      if (product?.name === 'Отделно балансиране') return `${price?.market} ${price?.marketIsPercent ? '%' : 'лв./МВтч'}`
      if (product?.name === 'Фикс') return `${price?.base} ${price?.baseIsPercent ? '%' : 'лв./МВтч'}`
      if (product?.name === 'Смесен количество') return `${price?.baseConsumption} МВтч | ${price?.base} лв./МВтч | ${price?.market} лв./МВтч`
      if (product?.name === 'Смесен процент') return `${price?.base} лв./МВтч | ${price?.market} лв./МВтч | ${price?.basePercentage} % фикс`
      return "--"
    case 'producerAfter2021':
    case 'producerNewPlant':
    case 'producerPreferences':
    case 'producerOwnNeed':
      return object?.isProducer ? object?.[value] ? 'Да' : 'Не' : "--"
    case 'producerDirectMember':
      return object?.isProducer ? object?.[value] ? 'Пряк' : 'Непряк' : "--"

    case 'balancingMethod':
      if (!object?.isProducer) return "--"
      if (['Фикс в лв. / МВТч', 'Таван в лв./МВТч'].includes(balancingMethod)) return `${balancingMethod} - ${balancingCeiling} лв./МВтч`
      if (['Под и Таван в лв./МВТч'].includes(balancingMethod)) return `${balancingMethod} - ${balancingFloor} - ${balancingCeiling} лв./МВтч`
      return balancingMethod ?? '--'
    case 'invoicingMethod':
      if (!object?.isProducer) return "--"
      return invoicingMethod ?? '--'
    case 'isProducer':
      return object?.isProducer ? 'П' : 'К'
    case 'objectActivate':
      if (['leaving', 'producersLeaving', 'active', 'inactive', 'potential',].includes(object?.status)) return '--'
      return <Button.Raised text='Активирай' onClick={() => dispatch(setModal({
        isOpen: true, type: 'inputDate', props: {
          title: 'Активиране на точка',
          text: 'Моля изберете дата, от която точката ще започне да получава енергия. Това действие ще промени статуса на "Активни", ще добави дата "Активна от" и ще одобри точката',
          startOfDay: true,
          startOfMonth: !object?.isProducer,
          defaultValue: moment().startOf('month').toDate(),
          onSuccess: (activeFrom) => {
            dispatch(startLoading())
            dispatch(updateObject({
              data: { _id: object._id, status: 'documents-received', activeFrom },
              onSuccess: () => dispatch(confirmStatusesRegister({
                payload: { ids: [object._id] },
                onSuccess: ({ toActive, toBeActive, toPreregistration, toBePreregistration }) => {
                  try {
                    getStatusConfirmationModal({ toActive, toBeActive, toPreregistration, toBePreregistration, fetch })
                  } catch (error) { console.error(error); }
                }
              }))
            }))
          }
        }
      }))} />
    case 'invoiceGroup':
      return object?.invoiceGroup?.name ?? '--'
    case 'createdAt':
      return object?.createdAt ? moment(object.createdAt).format("DD.MM.YYYY") : "--"
    case 'consumerOwnPlant':
      if (!object?.consumerOwnPlant) return 'Не'
      return `${object.consumerProductionPower} Mw`
    default:
      return !object[value] ? '--' : object[value]?.name || object[value]
  }
}

const objectsExport = (object, value, options) => {
  const invoiceSettings = object?.invoiceSettings ?? options?.client?.invoiceSettings
  switch (value) {
    case 'status':
      return clientsStatus.find((status) => status?.value === object[value])?.label || "--"
    case 'previousClient':
      return [object?.[value]?.fullName, object?.[value]?.eic].filter(Boolean).join("; ") || '--'
    case 'statusConfirmed':
      return object?.confirmedOn ? `Да` : "--"
    case 'objectionsDate':
    case 'activeFrom':
    case 'inactiveFrom':
    case 'entryEnd':
      return object[value] ? moment(object[value]).format("DD.MM.YYYY") : "--"
    case "documentsSentDate":
    case "dpiGeneratedDate":
    case "dpiSignedDate":
      return object[value] ? moment(object[value]).format("DD.MM.YYYY | HH:mm") : "--"

    case 'producerProduct':
    case 'producerPrice':
    case 'producerAfter2021':
    case 'producerNewPlant':
    case 'producerPreferences':
    case 'producerOwnNeed':
    case 'producerDirectMember':
    case 'balancingMethod':
    case 'invoicingMethod':
    case 'createdAt':
    case 'consumerOwnPlant':
      return objects(object, value, options)
    case 'comment':
      return object?.comments?.[0]?.text
    case 'producerSimpleByDays':
    case 'producerSimpleByHours':
    case 'producerAdditionalPeriodData':
    case 'producerFullFByDays':
    case 'producerFullFByHours':
    case 'producerFullRBByDays':
    case 'producerFullRBByHours':
    case 'producerSimpleBalancingByDays':
    case 'producerSimpleBalancingByHours':
      return (invoiceSettings?.invoiceReference?.[value]?.[value] ?? invoiceSettings?.invoiceReference?.[value]) ? 'Да' : 'Не'
    case 'producerBank':
    case 'producerIBAN':
      return invoiceSettings?.[value]
    case 'commissionProducer':
    case 'producerInvoiceBalancing':
    case 'templatedInvoice':
    case 'setOffAgreement':
      return invoiceSettings?.[value] ? 'Да' : 'Не'
    case 'producerStatsFor':
      return invoiceSettings?.producerStatsFor === 'measured' ? 'Измерено' : 'Прогноза'
    case 'isProducer':
      return object?.[value] ? 'Производител' : 'Консуматор'
    case 'objectActivate':
      return ''
    default:
      return !object[value] ? '--' : object[value]?.name || object[value]
  }
}

const invoices = (invoice, value, options) => {
  const { fetch } = options || {}
  const { dispatch } = store
  const query = new URLSearchParams(location.search)
  const type = query.get("type")
  switch (value) {
    case 'invoiceSent':

      return invoice[value]
        ? <div className="row row-invoiceSend">
          {moment(invoice[value]).format('HH:mm | DD.MM.YYYY')}
          {invoice?.emailStats?.length && <div
            className="icon icon-info-custom"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'emailStats', props: { data: invoice?.emailStats } }))}
          />}
        </div>
        : <div className="row row-invoiceSend">
          Не
          <Button.Raised
            text="Изпратен"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Изпратен имейл",
                children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(updateInvoiceSimple({ payload: { _id: invoice._id, invoiceSent: new Date() }, onSuccess: fetch }))
              }
            }))
            }
          />
        </div>
    case 'pdfFile':
      return invoice[value] && invoice[value].includes('https') ? (
        <div className="row row-pdfFile">
          Да
          <a href={transformURL(invoice[value] || '')} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" />
          </a>
        </div>
      ) : (
        'Не'
      )
    case 'fullName':
      return (
        <div className="row">
          {type === 'equalizing' && <NavLink to={`/clients/${invoice.client._id}`}>
            <div className="icon icon-folder-custom" />
          </NavLink>}
          {invoice?.client?.[value]}
        </div>
      )
    case 'comments':
      return (
        <div className="row">
          {`${invoice?.[value]?.length} Коментар${invoice[value]?.length !== 1 ? 'a' : ''}`}
          <div
            className="icon icon-arrow-right-2"
            onClick={() =>
              dispatch(setOverlay({ isOpen: true, type: 'comments', props: { obj: invoice, refType: 'Invoices' } }))
            }
          />
        </div>
      )
    case 'invoiceGroup':
      return invoice?.[value]?.name || '-'
    case "consumptionTotal":
      return invoice?.total?.[value] + " МВтч" || '-'
    case "consumptionTotalAmount":
      return invoice?.total?.[value]?.toFixed(2) + " лв." || '-'
    case 'deposit':
      return invoice.pricing?.['deposits']?.toFixed(2) || "-"
    case 'interest':
      return invoice.pricing?.['interests']?.toFixed(2) || "-"
    case 'compensation':
      return invoice.pricing?.['compensations']?.toFixed(2) || "-"
    case 'totalWithoutVat':
    case 'base':
      return invoice.pricing?.['base']?.toFixed(2) + ' лв.' ?? "-"
    case 'energy':
      return Number(invoice?.results?.[value])?.toFixed(2) + " МВтч" || '-'
    case 'invoiceDate':
    case 'dateOfPayment':
      return invoice[value] ? moment(invoice[value]).format('DD.MM.YYYY') : '--'
    case 'invoiceNumber':
      return (
        <div className="row">
          {invoice[value]?.toString()?.padStart(10, '0')}
          <div
            className="icon icon-arrow-right-2"
            onClick={() => dispatch(setOverlay({ isOpen: true, type: 'invoice', props: { invoice: invoice._id } }))}
          />
        </div>
      )

    case 'type':
      const typeMap = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
      return (
        <div className="row">
          <NavLink to={`/clients/${invoice.client._id}`}>
            <div className="icon icon-folder-custom" />
          </NavLink>
          {typeMap[invoice?.[value]]}
        </div>
      )
    case 'consecutiveNumber':
    case 'status':
      return invoice[value] || '-'
    case 'energyPrice':
      if (invoice?.results?.energyPricePercent) return Number(invoice?.results?.energyPricePercent)?.toFixed(2) + "%" || '-'
      return Number(invoice?.results?.[value])?.toFixed(2) + " лв." || '-'
    case 'deletedAt':
      return invoice?.deletedAt ? moment(invoice.deletedAt).format("DD.MM.YYYY | HH:mm") : "Не"
    case 'createdAt':
      return invoice?.createdAt ? moment(invoice.createdAt).format("DD.MM.YYYY") : "--"
    default:
      return clients({ ...invoice.client, ...pick(invoice, ['_id', 'objects']) }, value, options)
  }
}

const clients = (client, value, options) => {
  const { dispatch } = store
  const { objects, generatedContracts, contractSettings } = client || {}
  const props = { client, field: value, value: client[value], ...options }
  const contractValue = contractSettings?.[value] || generatedContracts?.[value]
  // if (client[value] instanceof Object) return ''

  const isDealer = checkIsDealer()
  if (isDealer && ['responsibleForRegistration', 'responsibleForRegistrationProducers', 'version', 'deliveryEnd', 'product', 'contractNumber', 'contractDate'].includes(value)) return clientsExport(client, value, options)

  switch (value) {
    case 'status':
      return <Client.Cells.Status {...props} />
    case 'comments':
      return <Client.Cells.Comments {...props} />
    case 'objects':
      return <Client.Cells.Objects {...props} />
    case 'tasks':
      return <Client.Cells.Tasks {...props} />
    case 'connectedGroup':
    case 'group':
      return <Client.Cells.Groups {...props} />
    case 'invoiceGroups':
      return <Client.Cells.InvoiceGroups {...props} />
    case 'deliveryEnd':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.deliveryEnd ? moment(client?.producerSettings?.deliveryEnd).format('DD.MM.YYYY') : 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.deliveryEnd ? moment(client?.producerSettings?.deliveryEnd).format('DD.MM.YYYY') : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.filter(Boolean)?.map((value) => moment(value).format('DD.MM.YYYY'))?.join(', ') ?? '--'
      }
    case 'version':
    case 'contractNumber':
    case 'product':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value]?.name ?? client?.producerSettings?.[value] ?? '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value]?.name ?? producerSettings?.[value])?.join(', ') ?? '--'
      }
    case 'contractDate':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] ? moment(client?.producerSettings?.[value]).format('DD.MM.YYYY') : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.filter(Boolean)?.map((value) => moment(value).format('DD.MM.YYYY'))?.join(', ') ?? '--'
      }
    case 'registrationNumber':
    case 'registrationNumberProducers':
      return <Client.Cells.EditCell {...props} />
    // case 'annex':
    //   return <Client.Cells.Annex {...props} />
    case 'offer':
    case 'contract':
    case 'analysis':
      return <Client.Cells.Document {...props} />
    case 'documentsERP':
      return <Client.Cells.DocumentsERP {...props} />

    case "executionDate":
    case "executionDateProducers":
      return client?.[value]
        ? <div className="row" style={{ width: '100%', justifyContent: 'space-between', marginRight: 14 }}>
          Да
          <div className="icon icon-delete" onClick={() => {
            dispatch(startLoading())
            dispatch(updateClient({ data: { _id: client?._id, [value]: null }, onSuccess: () => options.fetch({ pageOne: true }), }))
          }} />
        </div>
        : 'Не'
    case "contractReceivedDate":
    case "contractReceivedDateProducers":
      if (client[value] === '-') return ""
    case "dpiGenerated":
    case "dpiSigned":
    case "objectedDate":
      return <Client.Cells.ExecutionDate {...props} />
    case 'eic':
      return client?.eic || client?.pin || ''
    case 'businessType':
      return !isEmpty(client?.[value])
        ? [...new Set(client?.[value].map(({ name }) => name))].filter(Boolean).join(', ')
        : '--'
    case 'erp':
      return !isEmpty(objects)
        ? [...new Set(objects
          .filter(({ status }) => options?.type !== 'register' || allRegistrationStatuses.includes(status))
          .map((object) => object[value]?.name))].filter(Boolean).join(', ')
        : '--'
    case 'measureType':
    case 'seasoning':
      return !isEmpty(objects)
        ? [...new Set(objects.map((object) => object[value]?.name))].filter(Boolean).join(', ')
        : '--'
    case 'producerType':
    case 'STPMeasure':
      return !isEmpty(objects)
        ? [...new Set(objects.map((object) => object[value]))].filter(Boolean).join(', ')
        : '--'
    case 'reasonForLeaving':
      const reasons = [...new Set(objects.map((object) => object[value]))].filter(Boolean).join(', ')
      return reasons || '--'
    case 'contractType':
      return client?.contractSettings?.type || '--'
    case 'paymentDaysType':
      return `${client?.contractSettings?.paymentDays} ${client?.contractSettings?.paymentDaysType} дни`
    case 'responsibleForRegistration':
    case 'responsibleForRegistrationProducers':
      return <div className="row">
        {client?.[value]?.map(({ fullName }) => fullName).filter(Boolean).join(", ") || "-"}
        <div className="icon icon-edit" onClick={() => dispatch(setModal({
          isOpen: true, type: 'responsibleForRegistration', props: {
            clients: [client?._id], selected: client?.[value], producers: value === 'responsibleForRegistrationProducers', onSuccess: () => options.fetch({ pageOne: true })
          }
        }))} />
      </div>
    case 'dealers':
      return client?.dealers?.map(({ fullName }) => fullName)?.filter(Boolean)?.join(", ") || "--"
    case 'createdBy':
      return client?.[value]?.fullName || "--"
    case 'createdAt':
    case "lastLeavingDate":
      return client[value] ? moment(client[value]).format('DD.MM.YYYY | HH:mm') : '--'
    case 'entryEnd':
      return uniq(client?.objects?.map(({ entryEnd }) => entryEnd ? moment(entryEnd).format('DD.MM.YYYY') : undefined).filter(Boolean)).join(", ")
    case 'deliveryStart':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.deliveryStart ? moment(client?.producerSettings?.deliveryStart).format('DD.MM.YYYY') : 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] ? moment(client?.producerSettings?.[value]).format('DD.MM.YYYY') : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.filter(Boolean)?.map((value) => moment(value).format('DD.MM.YYYY'))?.join(', ') ?? '--'
      }
      return contractValue ? moment(contractValue).format('DD.MM.YYYY') : '--'
    case 'intermediateInvoicingType':
      const mapType = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
      return mapType[client?.invoiceSettings?.[value]] || 'Стандартно'
    case 'confirmed':
      const objectsWithDocuments = client.objects.filter(({ status }) => ['documents-received', 'documents-received-missing'].includes(status))
      return !objectsWithDocuments.length ? "--"
        : objectsWithDocuments.every(({ status }) => status === 'documents-received') ? 'Да'
          : client.objects.some(({ status }) => status === 'documents-received') ? "Частично"
            : "Не"
    case 'autoSign':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') {
        if (!client?.producerSettings) return 'Индивидуално'
        return client?.producerSettings?.autoSign
          ? client?.producerSettings.autoSignMonths
            ? `${client?.producerSettings.autoSignMonths} месеца`
            : 'Не са избрани месеци'
          : 'Не'
      }
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.autoSign ? client?.producerSettings.autoSignMonths ? `${client?.producerSettings.autoSignMonths} месеца` : 'Не са избрани месеци' : 'Не'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.autoSign ? producerSettings.autoSignMonths ? `${producerSettings.autoSignMonths} месеца` : 'Не са избрани месеци' : 'Не')?.join(', ') ?? '--'
      }
      return contractValue
        ? client?.contractSettings.autoSignMonths
          ? `${client?.contractSettings.autoSignMonths} месеца`
          : 'Не са избрани месеци'
        : 'Не'
    case 'terms':
    case 'declaration':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.[value] === true ? 'Да' : client?.producerSettings?.[value] === false ? 'Не' : 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] === true ? 'Да' : client?.producerSettings?.[value] === false ? 'Не' : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value] === true ? 'Да' : producerSettings?.[value] === false ? 'Не' : '--')?.join(', ') ?? '--'
      }
      return contractValue ? 'Да' : 'Не'
    case 'penalty':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.[value] === true ? 'Да' : client?.producerSettings?.[value] === false ? 'Не' : 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] === true ? 'Да' : client?.producerSettings?.[value] === false ? 'Не' : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value] === true ? 'Да' : producerSettings?.[value] === false ? 'Не' : '--')?.join(', ') ?? '--'
      }
      return contractValue ? ((client?.contractSettings?.penaltyPercent ?? '--') + (client?.contractSettings?.penaltyIsFixed ? ' лв.' : ' %')) : 'Не'
    case 'depositLeaving':
      if (!client?.contractSettings?.[value]) return 'Не'
      return (client?.contractSettings?.depositLeavingPercent ?? '--') + (client?.contractSettings?.depositLeavingIsFixed ? ' лв.' : ' %')
    case 'notice':
      if (!client?.contractSettings?.[value]) return 'Не'
      return client?.contractSettings?.noticeDays + ' дни'
    case 'noticeEnd':
      return client?.contractSettings?.notice && client?.contractSettings?.noticeDays && client?.contractSettings?.deliveryEnd ? moment(client.contractSettings.deliveryEnd).subtract(client.contractSettings.noticeDays, 'days').format('DD.MM.YYYY') : ''
    case 'market':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.price?.[value] ? `${client?.producerSettings?.price?.[value]} ${client?.producerSettings?.price?.marketIsPercent ? "%" : "лв/Мвтч"}` : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.price?.[value] ? `${producerSettings?.price?.[value]} ${producerSettings?.price?.marketIsPercent ? "%" : "лв/Мвтч"}` : '--')?.join(', ') ?? '--'
      }
      return client?.price?.[value] ? `${client?.price[value]} ${client?.price?.marketIsPercent ? "%" : "лв/Мвтч"}` : '--'
    case 'base':
    case 'night':
    case 'max':
    case 'marketCeiling':
    case 'marketFloor':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.price?.[value] ? `${client?.producerSettings?.price[value]} лв/Мвтч` : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.price?.[value] ? `${producerSettings?.price?.[value]} лв/Мвтч` : '--')?.join(', ') ?? '--'
      }
      return client?.price?.[value] ? `${client?.price[value]} лв/Мвтч` : '--'
    case 'marketIsBase':
      return client?.price?.[value] ? 'Да' : 'Не'
    case 'taxPerObject':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.price?.[value] ? `${client?.producerSettings?.price[value]} лв./точка` : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.price?.[value] ? `${producerSettings?.price?.[value]} лв./точка` : '--')?.join(', ') ?? '--'
      }
      return client?.price?.[value] ? `${client?.price[value]} лв./точка` : '--'
    case 'depositFile':
      return client?.invoices?.[0]?.pdfFile && client?.invoices?.[0]?.pdfFile.includes('https') ? (
        <div className="row row-pdfFile" style={{ width: '100%', paddingRight: 15 }}>
          Да
          <a href={transformURL(client?.invoices?.[0]?.pdfFile || '')} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
            <div className="icon icon-open" style={{ width: 20, height: 20, }} />
          </a>
        </div>
      ) : (
        'Не'
      )
    case 'penaltyFile':
      return client?.penalties?.[0]?.pdfFile && client?.penalties?.[0]?.pdfFile.includes('https') ? (
        <div className="row row-pdfFile" style={{ width: '100%', paddingRight: 15 }}>
          Да
          <a href={transformURL(client?.penalties?.[0]?.pdfFile || '')} target="_blank" rel="noopener noreferrer" style={{ marginLeft: 'auto' }}>
            <div className="icon icon-open" style={{ width: 20, height: 20, }} />
          </a>
        </div>
      ) : (
        'Не'
      )
    case 'additionalCharges':
      const charge = client?.invoices?.filter(({ type }) => type === 'leaving')?.at(-1)?.additionalCharges
      return charge ? charge + " лв." : "--"

    case 'annex':
      return !isEmpty(client?.annexes) ? <div className="row">
        {moment(client?.annexes[0].activationDate).format('DD.MM.YYYY')}
        {client?.annexes[0].file && <a href={transformURL(client?.annexes[0].file.url)} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open" style={{ height: 20, width: 20, marginLeft: 15 }} />
        </a>}
      </div> : 'Не'

    case 'hasClone':
      const objectsWithClones = client.objects.reduce((acc, { hasClone }) => acc + (hasClone ? 1 : 0), 0)
      const clones = client.objects.reduce((acc, { isClone }) => acc + (isClone ? 1 : 0), 0)

      if (clones) return "Нов клиент"
      else if (objectsWithClones) return "Стар клиент"
      else return "Не"

    case "paymentDays":
      if (client?.clientType === 'Производител' || options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.paymentDays
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.join(', ')
      }
      else if (client?.clientType === 'Консуматор') return `${client?.contractSettings?.paymentDays} ${client?.contractSettings?.paymentDaysType}`
      else return `K - ${client?.contractSettings?.paymentDays} ${client?.contractSettings?.paymentDaysType} | П - ${client?.producerSettings ? client?.producerSettings?.paymentDays : client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.join(', ') ?? '--'}`
    case "producerInvoicingType":
      if (client?.clientType === 'Консуматор') return "--"
      return client?.invoiceSettings?.[value] === 'monthly' ? 'Веднъж месечно'
        : client?.invoiceSettings?.[value] === 'intermediate' ? 'Междинно'
          : "--"
    case 'isDetailsConfirmed':
      return client?.[value] ? 'Да' : 'Не'
    case 'donationFond':
      return client?.contractSettings?.[value]
    default:
      return client[value]?.name || client[value] || '--'
  }
}

const clientsExport = (client, value, options) => {
  const { type } = options || {}
  const commentsLength = (client?.comments?.length ?? 0) + (client?.objects?.reduce((acc, { comments }) => acc + (comments?.length ?? 0), 0) ?? 0)

  switch (value) {
    case 'status':
      return getClientStatus(client, type)
    case 'comments':
      return `${commentsLength || '0'} Коментар${commentsLength !== 1 ? 'a' : ''}`
    case 'objects':
      return `${client[value].filter(({ status }) => type !== 'register' || allRegistrationStatuses.includes(status))?.length || '0'} Обект${client[value].filter(({ status }) => type !== 'register' || allRegistrationStatuses.includes(status))?.length !== 1 ? 'a' : ''}`
    case 'tasks':
      return `${client[value]?.length || '0'} Задач${client[value]?.length !== 1 ? 'и' : 'а'}`
    case 'invoiceGroups':
      const invoiceGroups = Object.keys(groupBy(client?.objects, ({ invoiceGroup }, key) => invoiceGroup?._id || 'notGrouped'))
      return `${invoiceGroups?.length || '0'} Груп${invoiceGroups?.length !== 1 ? 'и' : 'а'}`
    case 'connectedGroup':
    case 'group':
      return client[value]?.name || ''
    case 'product':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value]?.name ?? client?.producerSettings?.[value] ?? '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value]?.name ?? producerSettings?.[value])?.join(', ') ?? '--'
      }
      return client[value]?.name || ''
    case 'version':
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value]?.name ?? client?.producerSettings?.[value] ?? '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value]?.name ?? producerSettings?.[value])?.join(', ') ?? '--'
      }
      return client.contractSettings?.[value]?.name || ''
    case 'registrationNumber':
    case 'registrationNumberProducers':
      return client?.[value] | ''
    case 'contractType':
      return client?.contractSettings?.type || '--'
    case "contractReceivedDate":
    case "contractReceivedDateProducers":
      if (client[value] === "-") return ""
      else if (client[value] === null) return "Не"
      else return client?.[value] ? moment(client?.[value]).format('DD.MM.YYYY | HH:mm') : ''
    case "executionDate":
    case "executionDateProducers":
    case "dpiGenerated":
    case "dpiSigned":
    case "objectedDate":
      return client?.[value] ? moment(client?.[value]).format('DD.MM.YYYY | HH:mm') : ''
    case 'annex':
      return !isEmpty(client?.annexes) ? moment(client?.annexes[0].activationDate).format('DD.MM.YYYY') : 'Не'
    case 'annexFile':
      return !isEmpty(client?.annexes && client?.annexes[0]?.file?.url) ? "Да" : 'Не'
    case 'offer':
      return client.offers?.length ? 'Да' : 'Не'
    case 'contract':
      return client.generatedContracts && [client.generatedContracts]?.length ? 'Да' : 'Не'
    case 'analysis':
      return client.analysis?.length ? 'Да' : 'Не'
    case 'documentsERP':
      const docs = client?.files?.filter(({ type }) => type === 'erp-registration')
      return docs?.length ? 'Да' : 'Не'
    case 'contractNumber':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.[value] ?? 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] ?? '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.filter(Boolean)?.join(', ') ?? '--'
      }
      return client?.contractSettings?.[value] || ''
    case 'responsibleForRegistration':
    case 'responsibleForRegistrationProducers':
      return client?.[value]?.map(({ fullName }) => fullName).filter(Boolean).join(", ") || ''
    case 'deliveryStart':
    case 'deliveryEnd':
    case 'contractDate':
      if (options?.type === 'list' && client.clientType === 'Смесен') return 'Смесен'
      if (options?.type === 'list' && client.clientType === 'Производител') return client?.producerSettings?.[value] ? moment(client?.producerSettings?.[value]).format('DD.MM.YYYY') : 'Индивидуално'
      if (options?.type?.includes('producer')) {
        if (client?.producerSettings) return client?.producerSettings?.[value] ? moment(client?.producerSettings?.[value]).format('DD.MM.YYYY') : '--'
        else return client?.objects?.map(({ producerSettings }) => producerSettings?.[value])?.filter(Boolean)?.map((value) => moment(value).format('DD.MM.YYYY'))?.join(', ') ?? '--'
      }
      return client?.contractSettings?.[value] ? moment(client?.contractSettings?.[value]).format('DD.MM.YYYY') : ''
    case 'confirmed':
      const objectsWithDocuments = client.objects.filter(({ status }) => ['documents-received', 'documents-received-missing'].includes(status))
      return !objectsWithDocuments.length ? "--"
        : objectsWithDocuments.every(({ status }) => status === 'documents-received') ? 'Да'
          : client.objects.some(({ status }) => status === 'documents-received') ? "Частично"
            : "Не"


    case 'bnebHourly':
    case 'bnebDaily':
    case 'bnebHourlyAggregated':
    case 'loadProfileTotal':
    case 'loadProfileTotalHourly':
    case 'loadProfileByPoint':
    case 'loadProfileByPointHourly':
      return client?.invoiceSettings?.invoiceReference?.[value] ? 'Да' : 'Не'
    default:
      return clients(client, value, options)
  }
}

const clientsExportContacts = (row, field) => {
  if (['paymentDaysType', 'paymentDays',].includes(field)) return row?.contractSettings?.[field]

  if (['contacts', 'mol'].some(opt => field.includes(opt))) {
    const [array, subField, index] = field.split("_")
    if (['communication', 'invoices'].includes(subField) && ![null, undefined].includes(row?.[array]?.[index]?.[subField])) return row?.[array]?.[index]?.[subField] ? 'Да' : 'Не'
    return row?.[array]?.[index]?.[subField]
  }

  return clientsExport(row, field)
}

const objectsDocumentsMissing = (object, value) => {
  if (value === 'eic') return object?.client?.eic || object?.client?.pin
  if (value === 'additionalStatus') return object.additionalStatus === 'obligations' ? "Да" : "Не"
  else return object?.client?.[value] || object?.[value]?.name || object?.[value] || '--'
}

const referenceInvoicesGenerated = (invoice, value) => {
  if (invoice[value] instanceof Object) return ''
  switch (value) {
    case 'period':
    case 'clients':
    case 'objects':
      return invoice[value] || '--'
    case 'totalEnergy':
      return invoice[value] ? `${invoice[value] + ' MW'}` : '--'
    default:
      return ![null, undefined].includes(invoice[value]) ? `${Number(invoice[value]).toFixed(3) + ' лв.'}` : '--'
  }
}

const referenceInvoicesProgress = (row, value) => {
  switch (value) {
    case 'clients':
      return `${row?.clients} / ${row?.allClients}`
    case 'objects':
      return `${row?.objects} / ${row?.allObjects}`
    default:
      return row[value] || '--'
  }
}

const differencesExport = (row, field) => {
  if (['fullName'].includes(field)) return row?.client?.[field]
  else if (['measureType'].includes(field)) return row?.object?.[field]?.name
  else if (['valueKey'].includes(field)) return row[field] === 'periodValue' ? 'Почасови данни' : 'Мрежови данни'
  else return row[field].toString() || '--'
}

const filePreview = (row, value, options) => {
  const { selected, fetch } = options || {}
  const { dispatch } = store
  if (['from', 'to', 'от дата', 'Date'].includes(value)) return moment(row[value]).format('DD.MM.YYYY')
  else if (['Дата', 'Час', 'Време', 'Дата и Час'].includes(value)) return moment(row[value]).format('DD.MM.YYYY HH:mm')
  else if (['total'].includes(value)) return `${Number(row[value]).toFixed(3)}`
  else if (isObject(row[value])) return row[value]?.fullName || row[value]?.name || '--'
  else if (['periodValue'].includes(value))
    return <span className={`row ${row[value] > row['networkValue'] && 'orange'}`}>{row[value] ?? '--'}</span>
  else if (['networkValue'].includes(value))
    return <span className={`row ${row[value] > row['periodValue'] && 'orange'}`}>{row[value] ?? '--'}</span>
  else if (['measureType'].includes(value)) return row?.object?.[value]?.name
  else if (['fullName'].includes(value)) return row?.client?.[value]
  else if (['valueKey'].includes(value))
    return (
      <div className="row row-edit">
        <Inputs.RadioButtons
          buttons={[
            { value: 'periodValue', label: 'Почасови данни' },
            { value: 'networkValue', label: 'Мрежови данни' },
          ]}
          value={
            selected.editing === row._id
              ? selected?.edited?.valueKey || row[value]
              : selected.data[row?._id] || row[value]
          }
          onClick={(value) => {
            if (!fetch) selected.set({ ...selected?.data, [row._id]: value })
            else if (selected.editing) selected.setEdited({ _id: row?._id, valueKey: value })
            else {
              dispatch(startLoading())
              dispatch(
                updateInvoiceProgress({
                  payload: { updates: [{ _id: row?._id, valueKey: value }] },
                  onSuccess: () => fetch(),
                })
              )
            }
          }}
          disabled={fetch && row?.invoice && selected.editing !== row?._id}
        />
        {row?.invoice && (
          <div className="row row-buttons">
            {!fetch ? null : selected.editing === row?._id ? (
              <>
                <div
                  className={`icon icon-check ${isEmpty(selected?.edited) && 'disabled'} `}
                  onClick={() => {
                    dispatch(startLoading())
                    dispatch(
                      updateInvoiceProgress({
                        payload: { updates: [selected?.edited] },
                        onSuccess: () => {
                          selected?.setEditing('')
                          selected?.setEdited({})
                          fetch()
                        },
                      })
                    )
                  }}
                />
                <Button.Icon
                  name="plus"
                  color="red"
                  size={25}
                  onClick={() => {
                    selected?.setEditing('')
                    selected?.setEdited({})
                  }}
                />
              </>
            ) : (
              <div
                className="icon icon-edit"
                onClick={() => {
                  selected?.setEditing(row?._id)
                  selected?.setEdited({})
                }}
              />
            )}
          </div>
        )}
      </div>
    )
  else return row[value] || '--'
}

const invoiceSection = (row, value) => {
  if (!row[value]) return '--'
  switch (value) {
    case 'quantity':
      return Number(row[value]).toFixed(2) + 'МВтч'
    case 'singleUnitPrice':
    case 'value':
      return Number(row[value]).toFixed(2) + 'лв.'
    default:
      return row[value]
  }
}

const clientDocuments = (doc, value, { isDealer }) => {
  const { dispatch } = store

  switch (value) {
    case 'name':
      return (
        <a href={transformURL(doc?.url || '')} target="_blank" rel="noopener noreferrer">
          <span>{doc[value]}</span>
        </a>
      )
    case 'createdAt':
      return moment(doc[value]).format('DD.MM.YYYY')
    case 'employee':
      return doc[value]?.fullName || '--'
    case 'type':
      return <Inputs.Dropdown
        disabled={isDealer}
        options={[
          { label: 'Добавен', value: 'standard' },
          { label: 'Генериран', value: 'erp-registration' },
          { label: 'Протокол', value: 'protocol' },
          { label: 'Приложение 1', value: 'application1' },
          ...documentTypes,
          { value: 'other', label: 'Други документи' },
        ]}
        value={doc[value]}
        onChange={({ value }) => dispatch(updateFileClient({ _id: doc._id, type: value }))
        }
      />
    case 'comments':
      return (
        <div className="row">
          <Inputs.TextEdit
            disabled={isDealer}
            value={doc?.comments?.[0]?.text}
            file={doc}
            handleChange={(text) => {
              dispatch(startLoading())
              if (isEmpty(doc.comments)) dispatch(createComment({
                payload: { refType: 'Files', ref: doc?._id, text },
                stopLoading: false,
                onSuccess: ({ ref }) => dispatch(getClient({ payload: ref?.client, getAllDetails: true }))
              }))
              else dispatch(updateComment({
                payload: { _id: doc.comments[0]._id, text },
                stopLoading: false,
                onSuccess: ({ ref }) => dispatch(getClient({ payload: ref?.client, getAllDetails: true })),
              }))
            }}

          />
        </div>
      )
    case 'delete':
      return isDealer ? null : < div className="icon icon-delete" onClick={() => dispatch(deleteFileClient({ _id: doc._id }))} />
    default:
      return doc?.[value] || '--'
  }
}

const clientInvoices = (invoice, value) => {
  const { dispatch } = store

  switch (value) {
    case 'createdAt':
      return moment(invoice[value]).format('DD.MM.YYYY')
    case 'value':
      const total = Math.max(...Object.values(invoice?.pricing || {}).filter(v => isNumber(v)))?.toFixed(2)
      return `${total} лв.`
    case 'status':
      const statusMap = { generated: 'Неплатена', sent: 'Платена' }
      return (
        <div className="row">
          {invoice?.deletedAt ? "Анулирана" : statusMap[invoice?.[value]] || '--'}
          <div
            className="icon icon-arrow-right-2"
            onClick={() => dispatch(setOverlay({ isOpen: true, type: 'invoice', props: { invoice: invoice._id } }))}
          />
        </div>
      )
    default:
      return invoice?.[value] || '--'
  }
}

const imports = (file, value, options) => {
  const { fetch } = options || {}
  const { dispatch } = store

  switch (value) {
    case 'erp':
      return file[value] ? file[value]?.name : '--'
    case 'data':
      return (
        <>
          <span>{file?.[value]?.length ? 'Да' : 'Не'}</span>
          <Button.Icon
            name="plus"
            color="red"
            onClick={() => {
              dispatch(startLoading())
              dispatch(deleteImport({ payload: { _id: file?._id }, onSuccess: () => fetch() }))
            }}
          />
        </>
      )
    case 'uploadedDate':
    case 'createdAt':
      return file[value] ? moment(file[value]).format('DD.MM.YYYY HH:mm') : '--'
    case 'uploadedFrom':
      return file?.[value]?.fullName || '--'
    default:
      return file[value] ?? '--'
  }
}

const employees = (employee, value, { fetch }) => {
  const { dispatch, getState } = store

  const { currentUser } = getState().general
  const roles = currentUser?.roles?.map(({ name }) => name) ?? []
  const isMaster = ['master'].some((role) => roles.includes(role))

  switch (value) {
    case 'fullName':
      return (
        <div className="row fullName">
          {isMaster && <>
            <div
              style={{ height: 20, width: 20 }}
              className='icon icon-edit'
              onClick={() => dispatch(setModal({
                isOpen: true,
                type: 'newEmployee',
                props: {
                  employee: {
                    ...pick(employee, ['_id', 'firstName', 'lastName', 'email', 'isCompany']),
                    userGroups: employee?.userGroups?.map(({ _id }) => _id),
                    roles: employee?.roles?.map(({ _id }) => _id),
                  },
                  onSuccess: fetch
                }
              }))} />
            <div
              style={{ height: 20, width: 20 }}
              className='icon icon-delete'
              {...(employee._id === currentUser?._id && { style: { opacity: '0.5', pointerEvents: 'none' } })}
              onClick={() => dispatch(setModal({
                isOpen: true, type: 'confirmation', props: {
                  title: 'Сигурни ли сте, че искате да изтриете потребител?',
                  onClick: () => {
                    dispatch(startLoading())
                    dispatch(updateUser({ payload: { _id: employee._id, deletedAt: new Date(), deletedBy: currentUser._id }, onSuccess: fetch }))
                  }
                }
              }))}
            />
          </>}
          <span>{employee[value]}</span>
        </div>
      )
    case "role":
      return employee?.roles?.map(({ friendlyName }) => friendlyName).join(", ") || "--"
    case "userGroups":
      if (!employee?.userGroups?.length) return '--'
      return <div className="row" style={{ width: '100%', paddingRight: 14 }}>
        {employee?.userGroups?.map(({ _id, name }) => <div key={_id} className='row' style={{ marginRight: 5 }}>
          <span>{name}</span>
          <div
            className="icon icon-info-custom"
            style={{ height: 20, width: 20, marginLeft: 5 }}
            onClick={() => dispatch(setOverlay({ isOpen: true, type: 'usersGroups', props: { _id } }))}
          />
        </div>)}
      </div>
    case 'createdAt':
      return employee?.[value] ? moment(employee?.[value]).format("DD.MM.YYYY") : ""
    case 'email':
      return employee?.[value] || "--"
    default:
      return null
  }
}

const notices = (notice, value) => {
  switch (value) {
    case 'sum':
    case 'vat':
    case 'total':
      return `${Number(notice[value]).toFixed(2)} лв.`
    case 'deliveryStart':
    case 'deliveryEnd':
    case 'createdAt':
      return notice[value] ? moment(notice[value]).format('DD.MM.YYYY') : '--'
    case 'eic':
      return notice?.client?.eic || notice?.client?.pin
    case 'fullName':
      const clientFullName = notice?.client?.[value]
      return (
        <div className="row">
          {clientFullName && <NavLink to={`/clients/${notice.client._id}`}>
            <div className="icon icon-folder-custom" />
          </NavLink>}
          {clientFullName}
        </div>
      )
    case 'objects':
      const objectsLength = notice?.[value]?.length || 0
      return `${objectsLength} Обект${objectsLength !== 1 ? 'a' : ''}`
    case 'erp':
      return !isEmpty(notice?.objects)
        ? [...new Set(notice.objects.map((object) => object?.[value]?.name))].join(', ')
        : '--'
    case 'dealers':
      return notice?.client?.dealers?.map(({ fullName }) => fullName)?.filter(Boolean)?.join(", ") || "--"
    default:
      return notice.client?.[value] || notice[value] || '--'
  }
}

const nomenclatures = (nomenclature, value) => {
  switch (value) {
    case 'price':
      return nomenclature?.percent ? `${nomenclature?.percent}%` : nomenclature?.[value]
    case 'pricePersonal':
      return nomenclature?.percentPersonal ? `${nomenclature?.percentPersonal}%` : nomenclature?.[value]
    case 'erp':
      return nomenclature?.erp?._id
    default:
      return nomenclature?.[value]?.name || nomenclature?.[value] || '--'
  }
}

const exportInvoicesGeneratedStats = (row, value) => {
  const typeMap = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})

  if (value === 'eic') return row?.eic || row?.pin
  else if (value === 'type') return typeMap[row?.[value]]
  else return row?.[value] ?? ''
}

const annexChange = (row, field, options) => {
  const { products, versions } = options || {}
  if (field === 'label') return row?.label
  const typeMap = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})

  switch (row.value) {
    case 'product':
      return products.find(({ _id }) => row[field] === _id)?.name || '-'

    case 'version':
      return versions.find(({ _id }) => row[field] === _id)?.name || '-'
    case 'contractDate':
    case 'deliveryStart':
    case 'deliveryEnd':
      return moment(row[field]).format('DD.MM.YYYY')
    case 'producerInvoiceBalancing':
    case 'terms':
    case 'declaration':
    case 'autoSign':
    case 'notice':
    case 'marketIsPercent':
    case 'penalty':
    case 'penaltyIsFixed':
    case 'depositLeaving':
    case 'depositLeavingIsFixed':
      return row[field] ? 'Да' : 'Не'
    case 'autoSignMonths':
    case 'noticeMonths':
    case 'depositLeavingPercent':
    case 'penaltyPercent':
    case 'noticeDays':


    case 'type':
    case 'contractNumber':
    case 'maturity':
    case 'paymentDaysType':
    case 'advanceFirstDate':
    case "paymentDays":

    case 'invoicingMethod':
    case 'balancingMethod':
    case 'balancingFloor':
    case 'balancingCeiling':
    case 'producerInvoiceBalancing':
    case 'producerInvoicingIntermediateDate':

    case 'base':
    case "night":
    case "max":
    case "market":
    case "marketPercent":
    case 'taxPerObject':
    case "marketCeiling":
    case "marketFloor":
    case "basePercentage":

      return row[field]
    case 'intermediateInvoicingType':
      return typeMap[row[field]] || 'Веднъж месечно'
    case 'producerInvoicingType':
      return row[field] === 'monthly' ? 'Веднъж месечно' : row[field] === 'intermediate' ? 'Междинно' : ''
    default:
      return '-'
  }
}

export const depositsLeaving = (row, field, { fetch } = {}) => {
  switch (field) {
    case 'fullName':
      return <div className="row">
        <NavLink to={`/clients/${row?.client?._id}`}>
          <div className="icon icon-folder-custom" />
        </NavLink>
        {row?.client?.fullName}
      </div>
    case 'eic':
    case 'city':
    case "deliveryStart":
    case "deliveryEnd":
    case 'dealers':
    case 'email':
    case 'erp':
      return renderCell.clients(row.client, field)
    case 'objects':
      return (row?.client?.[field]?.length ?? 0) + ' обекта'
    case 'total':
      return row?.results?.total + " лв."
    case "additionalCharges":
      return <Invoices.Cells.AdditionalCharges row={row} fetch={fetch} />
    case 'invoiceNumber':
    case 'invoiceDate':
    case 'dateOfPayment':
    case 'invoiceSent':
    case 'deletedAt':
    case 'comments':
    case 'pdfFile':
      return renderCell.invoices(row, field, { fetch })
    case 'penalty':
      return row?.penalty?.[0]?.pdfFile && row?.penalty?.[0]?.pdfFile.includes('https') ? (
        <div className="row row-pdfFile">
          Да
          <a href={transformURL(row?.penalty?.[0]?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" />
          </a>
        </div>
      ) : (
        'Не'
      )
    // status
    default:
      return null
  }
}

export const notificationsClients = (row, field, { changes, setChanges, products } = {}) => {
  const { dispatch } = store

  const newProduct = products?.find(({ _id }) => _id === changes?.product)
  switch (field) {
    case 'fullName':
      return <div className="row">
        <NavLink to={`/clients/${row._id}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 8 }} />
        </NavLink>
        {row.fullName}
      </div>
    case 'currentPrice':
      if (['Борса', 'Отделно балансиране'].includes(row?.product?.name)) return `${row?.price?.market} ${row?.price?.marketIsPercent ? "%" : "лв/Мвтч"}`
      else if (row?.product?.name === 'Една тарифа') return row?.price?.base + " лв/Мвтч"
    case 'product':
      return row?.[field]?.name
    case 'eic':
    case 'measureType':
    case 'STPMeasure':
    case 'seasoning':
    case 'businessType':
    case 'dealers':
    case 'terms':
    case 'connectedGroup':
    case 'intermediateInvoicingType':
    case 'group':
    case 'deliveryEnd':
    case 'paymentDaysType':
    case 'autoSign':
    case 'penalty':
      return renderCell.clients(row, field)
    case 'objects':
      return (row?.[field]?.length ?? 0) + ' обекта'
    case 'newProduct':
      return <Inputs.TextEdit
        type='dropdown'
        value={newProduct}
        getOptions={() => dispatch(getData('products'))}
        options={products?.filter(({ forProducer }) => !forProducer)?.map(({ _id, name }) => ({ value: _id, label: name }))}
        handleChange={(value) => setChanges({ product: value })}
      />
    case 'base':
    case 'market':
    case 'night':
      return <Inputs.TextLabeledEdit
        value={changes?.price?.[field]}
        onChange={(value) => setChanges({ price: { ...changes?.price, [field]: value } })}
        label='лв.'
      />
    case 'lastConsumption':
      return (row[field] ?? 0) + ' МВтч'
    case 'averageConsumption':
      return mDivide(row?.invoices?.reduce((acc, { total }) => acc + total.consumptionTotal, 0), row?.invoices?.length || 1) + " МВтч"
    case 'averageConsumptionMonths':
      return row?.invoices?.length + ' месеца'
    case 'difference':
      return <Inputs.TextLabeled
        value={changes?.product && changes?.product !== row.product._id ? ""
          : row.product?.name === 'Една тарифа' && changes?.price?.base ? mSubtract(changes?.price?.base, row.price.base)
            : ['Борса', 'Отделно балансиране'].includes(row?.product?.name) && changes?.price?.market ? mSubtract(changes?.price?.market, row.price.market) : ""}
        disabled
        label='лв.' />
    case 'period':
      return <Inputs.TextLabeledEdit
        value={changes?.autoSignMonths}
        onChange={(value) => {
          if (!/^\d{0,2}$/.test(value) && value !== '') return
          setChanges({ autoSignMonths: value })
        }}
        label='месеца'
      />
    case 'activationDate':
      return moment(changes?.activationDate).isValid() ? moment(changes?.activationDate).format('DD.MM.YYYY') : ""
    default:
      return null
  }
}

export const notifications = (row, field, { products }) => {
  const { dispatch } = store
  switch (field) {
    case "status":
      if (row?.deletedAt) return notificationsStatusesMap.cancelled
      if (row?.applied) return notificationsStatusesMap.applied
      if (row?.emailDate) return notificationsStatusesMap.send
      if (row?.mailPdf) return notificationsStatusesMap.generated
      return notificationsStatusesMap.created
    case "base":
    case "market":
    case "night":
      return clients(row.changes, field)
    case "period":
      if (!row?.changes?.annexNotificationOldData_period) return "--"
      return row?.changes?.annexNotificationOldData_period + " месеца"
    case "difference":
      if ([null, undefined, ""].includes(row?.changes?.annexNotificationOldData_difference)) return "--"
      return (row?.changes?.annexNotificationOldData_difference ?? 0) + " лв."
    case "mailPdf":
      return row.mailPdf ? (
        <div className="row row-pdf">
          Да
          <a style={{ marginLeft: 15 }} href={transformURL(row.mailPdf || '')} target="_blank" rel="noopener noreferrer"><div className="icon icon-open" /></a>
        </div>
      ) : 'Не'
    case "emailDate":
      return row?.[field]
        ? <div className="row row-invoiceSend">
          {moment(row[field]).format('HH:mm | DD.MM.YYYY')}
          {row?.emailStats?.length && <div
            className="icon icon-info-custom"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'emailStats', props: { data: row?.emailStats } }))}
          />}
        </div>
        : 'Не'
    case 'oldDeliveryStart':
      return row?.changes?.annexNotificationOldData_oldDeliveryStart && moment(row?.changes?.annexNotificationOldData_oldDeliveryStart).format('DD.MM.YYYY')
    case 'oldDeliveryEnd':
      return row?.changes?.annexNotificationOldData_oldDeliveryEnd && moment(row?.changes?.annexNotificationOldData_oldDeliveryEnd).format('DD.MM.YYYY')
    case 'oldProduct':
      return row?.changes?.annexNotificationOldData_oldProduct && products.find(({ _id }) => _id === row?.changes?.annexNotificationOldData_oldProduct)?.name
    case 'oldPrice':
      return row?.changes?.annexNotificationOldData_oldPrice?.base || "--"
    case 'oldPriceMarket':
      return row?.changes?.annexNotificationOldData_oldPrice?.market || "--"
    case 'newProduct':
      return row?.changes?.product && products?.find(({ _id }) => _id === row?.changes?.product?._id)?.name || "--"
    case 'deliveryStart':
      return row?.changes?.contractSettings_deliveryStart ? moment(row?.changes?.contractSettings_deliveryStart).format('DD.MM.YYYY') : '--'
    case 'deliveryEnd':
      return row?.changes?.contractSettings_deliveryEnd ? moment(row?.changes?.contractSettings_deliveryEnd).format('DD.MM.YYYY') : '--'
    case 'activationDate':
      return row?.activationDate ? moment(row?.activationDate).format('DD.MM.YYYY') : '--'
    case 'applied':
      return row?.applied ? "Да" : "Не"
    default:
      return notificationsClients(row.client, field)
  }
}

const notificationsGeneratedExport = (row, field) => {
  const products = store.getState().data.products
  switch (field) {
    case "fullName":
      return row.client.fullName
    case "connectedGroup":
    case "group":
      return row.client?.[field]?.name || ''
    case "pdfFile":
      return row?.file?.url || "Не"
    default:
      return notifications(row, field, { products })
  }
}

export const notificationsExport = (row, field) => {
  switch (field) {
    case 'fullName':
    case 'eic':
      return row[field] || '-'
    case 'measureType':
    case 'seasoning':
      return [...new Set(row?.objects?.map((object) => object?.[field]?.name))]?.filter(Boolean)?.join(', ')
    case 'STPMeasure':
      return [...new Set(row?.objects?.map((object) => object?.[field]))]?.filter(Boolean)?.join(', ')
    case 'businessType':
      return row?.[field]?.map(({ name }) => name).filter(Boolean).join(', ')
    case 'dealers':
      return row?.[field]?.map(({ fullName }) => fullName).filter(Boolean).join(', ')
    case 'intermediateInvoicingType':
      const typeMap = intermediateInvoicesTypes.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
      return typeMap[row.invoiceSettings[field]] || 'Веднъж месечно'
    case 'deliveryEnd':
      return moment(row.contractSettings[field]).format('DD.MM.YYYY')
    case 'paymentDaysType':
      return `${row?.contractSettings?.paymentDays} ${row?.contractSettings?.paymentDaysType} дни`
    case 'terms':
    case 'autoSign':
    case 'penalty':
      return row.contractSettings[field] ? "Да" : "Не"
    case 'group':
    case 'connectedGroup':
    case 'product':
      return row?.[field]?.name
    case 'currentPrice':
      if (['Борса', 'Отделно балансиране'].includes(row?.product?.name)) return row?.price?.market
      else if (row?.product?.name === 'Една тарифа') return row?.price?.base
    case 'objects':
      return (row?.[field]?.length ?? 0) + ' обекта'
    case 'lastConsumption':
      return (row[field] ?? 0) + ' МВтч'
    case 'averageConsumption':
      return mDivide(row.invoices.reduce((acc, { total }) => acc + total.consumptionTotal, 0), row?.invoices?.length || 1) + " МВтч"
    case 'averageConsumptionMonths':
      return row?.invoices?.length + ' месеца'
    case "period":
      return row?.contractSettings?.autoSignMonths?.toString() || '12'
    case "activationDate":
      return moment(row.contractSettings.deliveryEnd).add(1, 'days').startOf('month').format('DD.MM.YYYY')
    default:
      return null
  }
}

const commissions = (row, field, { fetch }) => {
  switch (field) {
    case 'status':
      return commissionStatusMap[row?.[field]]
    case 'userIsCompany':
      return row?.[field] ? "Юридическо лице" : "Физическо лице"
    case 'objects':
      return `${row?.[field] || 0} Обект${row?.[field] !== 1 ? "a" : ""}`
    case 'total':
    case 'singleUnitPrice':
      return `${row?.[field] || 0} лв.`
    case 'lastPriceChange':
      return row?.[field] ? <div className="row">
        {moment(row?.[field]).format('DD.MM.YYYY')}
        <Popup
          trigger={<div style={{ marginLeft: 15 }} className="icon icon-eye-custom" />}
          position='bottom center'
          keepTooltipInside='.table-container'
          contentStyle={{ width: 'auto' }}
        >
          {close => (
            <div className="">
              Уведомление за смяна на цената
              <div className="row" style={{ justifyContent: "space-between" }}>
                Стара: <span></span>
                Нова: <span></span>
              </div>
            </div>
          )}
        </Popup>
      </div> : ""
    case "commission":
      // case "comment":
      return < Client.Cells.EditCommission row={row} field={field} fetch={fetch} />
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const commissionsExport = (row, field,) => {

  switch (field) {
    case 'status':
      return commissionStatusMap[row?.[field]]
    case 'userIsCompany':
      return row?.[field] ? "Юридическо лице" : "Физическо лице"
    case 'objects':
      return `${row?.[field] || 0} Обект${row?.[field] !== 1 ? "a" : ""}`
    case 'total':
    case "commission":
    case 'singleUnitPrice':
      return `${row?.[field] || 0} лв.`
    case 'lastPriceChange':
      return row?.[field] ? moment(row?.[field]).format('DD.MM.YYYY') : ""
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const commissionsReferenceGeneral = (row, field, options) => {
  const { toggleRowExpanded, isExpanded, fetchDetails, fetch, dispatch } = options || {}
  switch (field) {
    case 'objects':
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
        {row?.clientCommissions?.length} клиента
        <div
          style={{ marginRight: 14 }}
          className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
          onClick={() => {
            fetchDetails(row)
            toggleRowExpanded(row?._id)
          }}
        />
      </div>
    case "monthYear":
      return <span style={{ textTransform: 'capitalize' }}>{moment(row?.[field], "MM/YYYY").format("MMM YYYY")}</span>
    case 'userIsCompany':
      return row?.[field] ? "Юридическо лице" : "Физическо лице"
    case 'consumptionTotal':
      return `${row?.[field]} МВтч`
    case 'commissionAvg':
    case 'commissionMin':
    case 'commissionMax':
      return `${row?.[field]} лв./МВтч`
    case 'total':
    case 'singleUnitPriceAvg':
      return `${row?.[field]} лв.`
    case 'xlsxUrl':
      return <a href={transformURL(row?.[field] || '')} target="_blank" rel="noopener noreferrer"><div style={{ height: 20, width: 20 }} className="icon icon-open"></div></a>
    case 'emailSent':
      return row[field]
        ? <div className="row row-emailSent">
          {moment(row[field]).format('HH:mm | DD.MM.YYYY')}
          {row?.emailStats?.length && <div
            className="icon icon-info-custom"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'emailStats', props: { data: row?.emailStats } }))}
          />}
        </div>
        : <div className="row row-emailSent">
          Не
          <Button.Raised
            text="Изпратен"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Изпратен имейл",
                children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(updateCommissionSimple({ payload: { _id: row._id, emailSent: new Date() }, onSuccess: () => fetch() }))
              }
            }))}
          />
        </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}
const commissionsReferenceDetails = (row, field) => {
  switch (field) {
    case 'clientFullName':
      return <div className="row">
        <NavLink to={`/clients/${row?.client._id}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 15 }} />
        </NavLink>
        {row.client.fullName}
      </div>
    case 'objects':
      return `${row?.[field] || 0} Обект${row?.[field] !== 1 ? "a" : ""}`
    case 'consumption':
      return `${row?.[field]} МВтч`
    case 'commission':
      return `${row?.[field]} лв./МВтч`
    case 'total':
      return `${row?.[field]} лв.`
    case 'invoicePaid':
      return row?.[field] ? "Платен" : "Непотвърден"
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const communicationFiles = (row, field) => {
  switch (field) {
    case "xlsxFile":
    case "xmlFile":
    case "jsonFile":
      if (!row?.[field]) return '--'
      return <a href={transformURL(row?.[field] || '')} target="_blank" rel="noopener noreferrer"><div style={{ height: 20, width: 20 }} className="icon icon-open"></div></a>
    case "firstEntry":
      if (row?.firstEntryRaw) return moment(row.firstEntryRaw).format("DD.MM.YYYY | HH:mm")
    case "lastEntry":
      if (row?.lastEntryRaw) return moment(row.lastEntryRaw).format("DD.MM.YYYY | HH:mm")
    case 'messageDate':
    case 'createdAt':
      return row?.[field] ? moment(row?.[field]).format("DD.MM.YYYY | HH:mm") : ""
    case 'erp':
      return row?.[field]?.name || ""
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const compensationSettings = (row, field, { fetch }) => {
  switch (field) {
    case "forMonth":
      return <span style={{ textTransform: 'capitalize' }}>{moment(row[field], 'MM/YYYY').format('MMM YYYY')}</span>
    case "bnebAverage":
    case "baseAmount":
      return row?.version === 2 ? `${row[field] ?? 0} лв.` : '--'
    case "amount":
      return `${row[field] ?? 0} лв.`
    case "enabledForMonth":
      return row[field] ? "Да" : "Не"
    case "edit":
      return <div
        className="icon icon-edit"
        onClick={() => store.dispatch(setModal({
          isOpen: true, type: 'compensationSettings', props: {
            compensationSettings: row, onSuccess: (title) => store.dispatch(setModal({
              isOpen: true, type: 'confirmation', props: { title, onClick: fetch, onRequestClose: fetch }
            }))
          }
        }))}
      />
    case 'version':
      return row?.[field] === 2 ? "Да" : row?.[field] === 1 ? "Не" : '--'
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const commissionsReferenceCreated = (row, field, options) => {
  const commissionClientMonth = row?.commissionClientMonth?.[0]
  switch (field) {
    case "monthYear":
      return commissionClientMonth?.[field]
        ? <span style={{ textTransform: 'capitalize' }}>{moment(commissionClientMonth?.[field], "MM/YYYY").format("MMM YYYY")}</span>
        : "--"
    case 'userFullName':
      return row.user.fullName
    case 'userIsCompany':
      return row.user.isCompany ? "Юридическо лице" : "Физическо лице"
    case 'userEmail':
      return row.user.email
    case 'clientFullName':
      return <div className="row">
        <NavLink to={`/clients/${row.client?._id}`}>
          <div className="icon icon-folder-custom" style={{ height: 20, width: 20, marginRight: 10 }} />
        </NavLink>
        <span>{row?.client?.fullName}</span>
      </div>
    case 'clientIdentifier':
      return row.client?.pin || row.client?.eic || ""
    case 'consumption':
      return commissionClientMonth?.[field] ? `${commissionClientMonth?.[field]} МВтч` : "--"
    case 'total':
    case 'singleUnitPrice':
      return commissionClientMonth?.[field] ? `${commissionClientMonth?.[field]} лв.` : "--"
    case 'objects':
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
        {row?.objects?.length} промени
        <div
          style={{ marginRight: 14 }}
          className={`icon icon-arrow-down ${options.isExpanded && 'rotate'}`}
          onClick={() => options.toggleRowExpanded(row?._id)}
        />
      </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const commissionsReferenceCreatedExport = (row, field,) => {
  const commissionClientMonth = row?.commissionClientMonth?.[0]
  switch (field) {
    case 'monthYear':
      return commissionClientMonth?.[field] ? moment(commissionClientMonth?.[field], "MM/YYYY").format("MMM YYYY") : ""
    case 'clientFullName':
      return row?.client?.fullName
    default:
      return commissionsReferenceCreated(row, field)
  }
}

const commissionsReferenceCreatedDetails = (row, field) => {
  switch (field) {
    case "activeFrom":
      return moment(row?.[field]).format("DD.MM.YYYY")
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const objectsIgnored = (row, field, { handleIgnore } = {}) => {
  switch (field) {
    case 'fullName':
      return <div className="row">
        <NavLink to={`/clients/${row.client._id}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 8 }} />
        </NavLink>
        {row?.client?.[field] ?? '--'}
      </div>
    case 'eic':
      return row?.client?.[field] ?? row?.client?.pin ?? '--'
    case 'isProducer':
      return row?.isProducer ? 'Да' : 'Не'
    case 'button':
      return <Button.Raised text="Отигнорирай" onClick={() => handleIgnore(row._id)} />
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const objectsIgnoredExport = (row, field) => {
  switch (field) {
    case 'fullName':
      return row?.client?.[field] ?? '--'
    case 'eic':
      return row?.client?.[field] ?? row?.client?.pin ?? '--'
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const clientsGroups = (row, field, { dispatch, fetch, isExpanded, toggleRowExpanded }) => {
  switch (field) {
    case 'clientsCount':
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
        <span> {row?.clients?.length} клиента</span>
        <div className="row">
          {row?.clients?.length && <div
            style={{ marginRight: 14 }}
            className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
            onClick={() => toggleRowExpanded(row?._id)}
          />}
          <div
            style={{ marginRight: 14 }}
            className="icon icon-edit"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'clientsGroupsForm', props: { group: row } }))}
          />
          <div
            style={{ marginRight: 14 }}
            className="icon icon-delete"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: 'Сигурни ли сте, че искате да изтриете групата?',
                onClick: () => dispatch(deleteGroup({ payload: { _id: row._id, }, onSuccess: () => fetch() }))
              }
            }))}
          />
        </div>
      </div>
    case 'createdAt':
      return row?.[field] ? moment(row?.[field]).format('DD.MM.YYYY') : "--"
    case 'createdBy':
      return row?.[field]?.fullName ?? '--'
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const clientsGroupsDetails = (row, field, { dispatch, fetch }) => {
  switch (field) {
    case 'fullName':
      return <div className="row" style={{ width: '100%', paddingRight: 14 }}>
        <NavLink to={`/clients/${row?._id}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 8 }} />
        </NavLink>
        {row?.[field] ?? '--'}
      </div>
    case 'eic':
      return <div className="row" style={{ width: '100%', paddingRight: 14 }}>
        {row?.eic ?? row?.pin ?? "--"}
        <div className="row" style={{ marginLeft: 'auto' }}>
          <div
            style={{ marginRight: 14 }}
            className="icon icon-delete"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: 'Сигурни ли сте, че искате да премахнете клиент от групата?',
                onClick: () => dispatch(updateGroup({
                  payload: { _id: row.original._id, clients: row?.original?.clients?.filter(({ _id }) => _id !== row._id) },
                  onSuccess: () => fetch()
                }))
              }
            }))}
          />
        </div>
      </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const modalEmailStats = (row, field, { toggleRowExpanded }) => {
  switch (field) {
    case 'description':
      return <div className="row">
        <a href={transformURL(row.url || '')} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open" style={{ marginRight: 8 }} />
        </a>
        {row?.metadata?.description}
      </div>
    case 'firstView':
    case 'lastView':
      return row[field] ? moment(row[field]).format('DD.MM.YYYY | HH:mm') : ""
    case '_views':
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
        {row?._views?.length}
        {!!row?._views?.length && <div
          style={{ marginRight: 14 }}
          className="icon icon-arrow-down"
          onClick={() => toggleRowExpanded(row?._id)}
        />}
      </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const producersReferences = (row, field, { dispatch, fetch } = {}) => {
  const producerSettings = row?.currentProducerSettings ?? row?._client?.producerSettings ?? row?._objects?.[0]?.producerSettings
  const product = producerSettings?.product?.name
  switch (field) {
    case 'fullName':
      return <div className="row">
        <NavLink to={`/clients/${row?.client}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 14 }} />
        </NavLink>
        {row?.clientData?.[field] ?? '--'}
      </div>
    case 'clientIdentifier':
    case 'itn':
    case 'object':
      return row?.clientData?.[field] ?? "--"
    case 'createdAt':
      return row?.[field] ? moment(row?.[field]).format('DD.MM.YYYY') : '--'
    case 'type':
      return producersReferencesOptions?.find(({ value }) => value === row?.[field])?.label
    case 'htmlFile':
    case 'file':
      return row?.[field] && row?.[field].includes('https') ? <div className="row row-pdfFile">
        Да
        <a href={transformURL(row?.[field] || '')} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open" style={{ marginLeft: 15 }} />
        </a>
      </div>
        : 'Не'
    case 'additionalStat':
      return row?.[field] && row?.[field]?.file.includes('https') ? <div className="row row-pdfFile">
        Да
        <a href={transformURL(row?.[field]?.file || '')} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open" style={{ marginLeft: 15 }} />
        </a>
      </div>
        : 'Не'
    case 'invoice':
      return row?.[field] && row?.[field]?.pdfFile?.includes('https') ? <div className="row row-pdfFile">
        Да
        <a href={transformURL(row?.[field]?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
          <div className="icon icon-open" style={{ marginLeft: 15 }} />
        </a>
      </div>
        : 'Не'
    case 'templatedInvoice':
      return <div className="row row-pdfFile" style={{ width: '100%', paddingRight: 14 }}>
        {row?.[field] && row?.[field]?.pdfFile?.includes('https') ? <div className="row row-pdfFile">
          Да
          <a href={transformURL(row?.[field]?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" style={{ marginLeft: 15 }} />
          </a>
          <div className="icon icon-delete" style={{ marginLeft: 15 }} onClick={() => {
            if (process.env.REACT_APP_PLATFORM === 'Synergon') dispatch(setModal({
              isOpen: true, type: 'inputText', props: {
                title: 'Сигурни ли сте, че искате да изтриете файла?',
                text: 'Причина за изтриване на фактурата',
                textButton: 'Изтрий',
                onSuccess: (comment) => dispatch(producersReferenceUpdate({ payload: { _id: row._id, templatedInvoiceDeleted: { comment, invoiceNumber: row?.templatedInvoice?.invoiceNumber, timestamp: row?.templatedInvoice?.timestamp }, $unset: { templatedInvoice: 1 } }, onSuccess: () => { dispatch(setModal({ isOpen: false })); fetch() } }))
              }
            }))
            else dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: 'Сигурни ли сте, че искате да изтриете файла?',
                onClick: () => dispatch(producersReferenceUpdate({ payload: { _id: row._id, $unset: { templatedInvoice: 1 } }, onSuccess: () => fetch() }))
              }
            }))
          }} />
        </div>
          : 'Не'}
        <Popup
          contentStyle={{ width: 'auto', padding: '5px 10px' }}
          trigger={<div className="icon icon-options" style={{ marginLeft: row?.[field] && row?.[field]?.pdfFile?.includes('https') ? '5px' : 'auto' }} />}
        >
          <p><a href={`/producers-templated-invoice?_id=${row._id}&client=${row.client}`} target="_blank" rel="noopener noreferrer">Линк за генериране на фактура</a></p>
          <p><a href={`/producers-upload?_id=${row._id}&client=${row.client}`} target="_blank" rel="noopener noreferrer">Линк за качване на фактура</a></p>
        </Popup>
      </div>
    case 'templatedInvoiceConfirmed':
      if (row?.templatedInvoice?.confirmed) return <div className="row row-templatedInvoiceConfirmed" style={{ justifyContent: 'space-between' }}>
        {row?.templatedInvoice?.confirmed}
        <Popup
          trigger={<div style={{ margin: 0 }} className='icon icon-info-custom' title={moment(row?.templatedInvoice?.timestamp).format('DD.MM.YYYY | HH:mm')} />}
          position='bottom center'
          keepTooltipInside='.table-container'
          contentStyle={{ width: 'auto' }}
        >
          {() => <div className="col">
            <p style={{ margin: '4px' }}>Дата: <span style={{ fontWeight: 700 }}>{row?.templatedInvoice?.confirmed === 'Върната' ? moment(row?.templatedInvoice?.returnedOn).format('DD.MM.YYYY | HH:mm') : moment(row?.templatedInvoice?.timestamp).format('DD.MM.YYYY | HH:mm')}</span></p>
            <p style={{ margin: '4px' }}>Служител: <span style={{ fontWeight: 700 }}>{row?.templatedInvoice?.employee?.fullName}</span></p>
            <p style={{ margin: '4px' }}>Номер на фактура: <span style={{ fontWeight: 700 }}>{row?.templatedInvoice?.invoiceNumber}</span></p>
            <p style={{ margin: '4px' }}>Дата на фактура: <span style={{ fontWeight: 700 }}>{
              row?.templatedInvoice?.dateOfIssue
                ? moment(row?.templatedInvoice?.dateOfIssue).format('DD.MM.YYYY')
                : moment(row?.monthYear, 'MM/YYYY').endOf('month').format('DD.MM.YYYY')
            }</span></p>
            {row?.templatedInvoice?.comment && <p style={{ margin: '4px', maxWidth: 300 }}>Коментар: <span style={{ fontWeight: 700 }}>{row?.templatedInvoice?.comment}</span></p>}
          </div>}
        </Popup>
      </div>
      else return <div className="row row-templatedInvoiceConfirmed">
        <Button.Raised text="Потвърди" onClick={() => dispatch(setModal({ isOpen: true, type: 'templatedInvoiceConfirmed', props: { _id: row?._id, stat: row, file: row?.templatedInvoice?.pdfFile, monthYear: row?.monthYear, onSuccess: fetch }, modalStyles: { height: '95vh', width: '95vw' } }))} />
      </div>
    case 'timestamp':
    case 'fileUploadDate':
      return row?.templatedInvoice?.[field] ? moment(row?.templatedInvoice?.[field]).format('DD.MM.YYYY | HH:mm') : '--'
    case 'invoiceNumber':
      return row?.templatedInvoice?.invoiceNumber ?? '--'
    case 'employee':
      return row?.templatedInvoice?.employee?.fullName ?? '--'
    case 'dealers':
      return row?._client?.dealers?.map(({ fullName }) => fullName)?.join(', ') || '--'
    case 'statsSent':
      return row[field]
        ? <div className="row row-invoiceSend">
          {moment(row[field]).format('HH:mm | DD.MM.YYYY')}
          {row?.emailStats?.length && <div
            className="icon icon-info-custom"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'emailStats', props: { data: row?.emailStats } }))}
          />}
        </div>
        : <div className="row row-invoiceSend">
          Не
          <Button.Raised
            text="Изпратен"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Изпратен имейл",
                children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(producersReferenceUpdate({ payload: { _id: row._id, statsSent: new Date() }, onSuccess: () => fetch() }))
              }
            }))}
          />
        </div>
    case 'statsSentTo':
      return [row?._client?.email, ...(row?._client?.contacts?.filter(({ stats, email }) => stats && email)?.map(({ email }) => email) ?? [])].filter(Boolean).join(', ')
    case 'comments':
      return <div className="row" style={{ width: '100%', paddingRight: 14, justifyContent: 'space-between' }}>
        {`${row?.[field]?.length} Коментар${row?.[field]?.length !== 1 ? 'a' : ''}`}
        <div
          style={{}}
          className="icon icon-arrow-right-2"
          onClick={() => dispatch(setOverlay({ isOpen: true, type: 'comments', props: { obj: row, refType: 'ProducerStats', onSuccess: () => fetch() } }))}
        />
      </div>
    case 'data.sheet1.summary1.totalProduction':
    case 'data.sheet1.summary1.singleUnitPrice':
    case 'data.sheet1.summary1.total':
      return row?.data?.sheet1?.summary1?.[field.split('.').at(-1)] ?? "--"
    case 'product':
      return product ?? "--"
    case 'price':
      if (![undefined, null].includes(row?.data?.priceOverwrite)) return `${row?.data?.priceOverwrite}лв. (МР)`
      if (['Борса минус', 'Отделно балансиране'].includes(product)) return `${producerSettings?.price?.market}${producerSettings?.price?.marketIsPercent ? '%' : 'лв.'}`
      if (['Фикс'].includes(product)) return `${producerSettings?.price?.base}лв.`
      return "--"
    case 'marketPrice':
      if (['Борса минус', 'Отделно балансиране'].includes(product)) {
        if (producerSettings?.price?.marketIsPercent) return mDivide(row?.data.sheet1.summary1.singleUnitPrice, mSubtract(1, mDivide(producerSettings?.price?.market, 100)))
        else return mAdd(row?.data.sheet1.summary1.singleUnitPrice, producerSettings?.price?.market)
      }
      if (['Фикс'].includes(product)) return producerSettings?.price?.base
      return "--"
    case 'marketTotal':
      if (['Борса минус', 'Отделно балансиране'].includes(product)) {
        if (producerSettings?.price?.marketIsPercent) return mSubtract(mMultiply(mDivide(row?.data.sheet1.summary1.singleUnitPrice, mSubtract(1, mDivide(producerSettings?.price?.market, 100))), row?.data.sheet1.summary1.totalProduction), row?.data?.sheet1?.summary1?.total)
        else return mSubtract(mMultiply(mAdd(row?.data.sheet1.summary1.singleUnitPrice, producerSettings?.price?.market), row?.data.sheet1.summary1.totalProduction), row?.data?.sheet1?.summary1?.total)
      }
      if (['Фикс'].includes(product)) return mMultiply(producerSettings?.price?.base, row?.data.sheet1.summary1.totalProduction)
      return "--"
    case 'invoicingMethod':
    case 'balancingMethod':
    case 'balancingCeiling':
    case 'balancingFloor':
      return producerSettings?.[field] ?? '--'

    case 'producerProductionPower':
      return row?._objects?.[0]?.[field]
    case 'erp':
      return row?._objects?.[0]?.[field]?.name
    case 'vat':
      return mMultiply(row?.data?.sheet1?.summary1?.total, 0.2)
    case 'withVat':
      return mMultiply(row?.data?.sheet1?.summary1?.total, 1.2)
    default: return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const producersReferencesExport = (row, field) => {
  switch (field) {
    case 'fullName':
      return row?.clientData?.[field]
    case 'file':
    case 'htmlFile':
      return row?.[field]
    case 'invoice':
      return row?.[field]?.pdfFile
    case 'additionalStat':
      return row?.[field]?.file
    case 'statsSent':
      return row?.[field] ? moment(row[field]).format('HH:mm | DD.MM.YYYY') : ''
    case 'comments':
      return `${row?.[field]?.length} Коментар${row?.[field]?.length !== 1 ? 'a' : ''}`
    case 'templatedInvoice':
      return row?.[field]?.pdfFile
    case 'templatedInvoiceConfirmed':
      return row?.templatedInvoice?.confirmed
    case 'templatedInvoiceDate':
      return row?.templatedInvoice
        ? row?.templatedInvoice?.dateOfIssue
          ? moment(row?.templatedInvoice?.dateOfIssue).format('DD.MM.YYYY')
          : moment(row?.monthYear, 'MM/YYYY').endOf('month').format('DD.MM.YYYY')
        : ''
    case 'balancingOverwriteTemp1':
      return row?.data?.balancingOverwrite?.temp1
    case 'balancingOverwriteTemp2':
      return row?.data?.balancingOverwrite?.temp2
    case 'balancingOverwriteTemp3':
      return row?.data?.balancingOverwrite?.temp3
    case 'clientIdentifier':
    case 'itn':
    case 'object':
    case 'createdAt':
    case 'type':
    case 'data.sheet1.summary1.totalProduction':
    case 'data.sheet1.summary1.singleUnitPrice':
    case 'data.sheet1.summary1.total':
    case 'product':
    case 'price':
    case 'invoicingMethod':
    case 'balancingMethod':
    case 'balancingCeiling':
    case 'balancingFloor':

    case 'producerProductionPower':
    case 'erp':
    case 'vat':
    case 'withVat':
    case 'overheadWithoutVat':

    case 'marketPrice':
    case 'marketTotal':

    case 'timestamp':
    case 'invoiceNumber':
    case 'dealers':
    case 'employee':
    case 'fileUploadDate':

    case 'statsSentTo':
      return producersReferences(row, field)
    default:
      return ''
  }
}

const producersInvoices = (row, field, { dispatch, fetch } = {}) => {
  switch (field) {
    case 'invoiceNumber':
      return <div className="row">
        {row[field]}
        <div
          className="icon icon-arrow-right-2"
          style={{ marginLeft: 14, height: 20, width: 20 }}
          onClick={() => dispatch(setOverlay({ isOpen: true, type: 'invoice', props: { invoice: row._id } }))}
        />
      </div>
    case 'invoiceDate':
    case 'dateOfPayment':
    case 'deletedAt':
      return row[field] ? moment(row[field]).format('DD.MM.YYYY') : '--'
    case 'pdfFile':
      return row[field] && row[field].includes('https')
        ? <div className="row row-pdfFile">
          Да
          <a href={transformURL(row[field] || '')} target="_blank" rel="noopener noreferrer">
            <div style={{ marginLeft: 14, height: 20, width: 20 }} className="icon icon-open" />
          </a>
        </div> : 'Не'
    case 'fullName':
      return <div className="row">
        <NavLink to={`/clients/${row.client._id}`}>
          <div style={{ marginRight: 14, height: 20, width: 20 }} className="icon icon-folder-custom" />
        </NavLink>
        {row?.client?.[field]}
      </div>
    case 'eic':
      return row?.client?.eic ?? row?.client?.pin ?? "--"
    case 'statsType':
      const typeMap = producersReferencesOptions.reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})
      return typeMap[row?.[field]] ?? '--'
    case 'base':
    case 'withVat':
      return !['', null, undefined].includes(row.pricing?.[field]) ? row.pricing?.[field]?.toFixed(2) + ' лв.' : "-"
    case 'invoiceSent':
      return row[field]
        ? <div className="row row-invoiceSend">
          {moment(row[field]).format('HH:mm | DD.MM.YYYY')}
          {row?.emailStats?.length && <div
            className="icon icon-info-custom"
            onClick={() => dispatch(setModal({ isOpen: true, type: 'emailStats', props: { data: row?.emailStats } }))}
          />}
        </div>
        : <div className="row row-invoiceSend">
          Не
          <Button.Raised
            text="Изпратен"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Изпратен имейл",
                children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(updateInvoiceSimple({ payload: { _id: row._id, invoiceSent: new Date() }, onSuccess: fetch }))
              }
            }))}
          />
        </div>
    default: return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const producersInvoicesExport = (row, field) => {
  switch (field) {
    case 'correction':
      return row?.pricing?.producerBalancing?.find(({ text }) => text.includes('Корекция'))?.total
    case 'returnValue':
      return row?.pricing?.additionalInformation?.['Стойност за възстановяване']
    case 'itn':
    case 'description':
      return row?.objects?.map((obj) => obj[field]).join(', ')
    case 'invoiceNumber':
    case 'pdfFile':
      return row?.[field]
    case 'invoiceSent':
      return row?.[field] ? moment(row[field]).format('HH:mm | DD.MM.YYYY') : ""
    case 'fullName':
    case 'ajur':
    case 'ajurProducer':
      return row?.client?.[field]
    case 'base':
    case 'withVat':
      return row?.pricing?.[field]
    case 'invoiceDate':
    case 'dateOfPayment':
    case 'eic':
    case 'statsType':
    case 'deletedAt':
      return producersInvoices(row, field)
    default:
      return ''
  }
}

const interests = (row, field, { toggleRowExpanded, isExpanded, fetch }) => {
  switch (field) {
    case 'clientFullName':
      return <div className="row">
        <NavLink to={`/clients/${row?.clientId}`}>
          <div className="icon icon-folder-custom" style={{ marginRight: 14 }} />
        </NavLink>
        {row?.[field] ?? '--'}
      </div>
    case 'details':
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between' }}>
        {row?.details?.length ?? 0} елемента
        <div className="row">
          {row?.details?.length && <div
            style={{ marginRight: 14 }}
            className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
            onClick={() => toggleRowExpanded(row?._id)}
          />}
          <div
            style={{ marginRight: 14 }}
            className="icon icon-edit"
            onClick={() => history.push(`/financial/interests/form?_id=${row?._id}`)}
          />
          <div
            style={{ marginRight: 14 }}
            className="icon icon-delete"
            onClick={() => store.dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: 'Внимание!',
                children: 'Сигурни ли сте, че искате да изтриете лихвата?',
                onClick: () => {
                  store.dispatch(startLoading())
                  store.dispatch(deleteInterest({ payload: { id: row?._id }, onSuccess: () => fetch() }))
                }
              }
            }))}
          />
        </div>
      </div>
    case 'amount':
      return row?.[field]?.toFixed(2) ?? "--"
    case 'manuallyAdded':
    case 'doNotCalculateInterests':
    case 'shouldUse':
      return row?.[field] ? 'Да' : 'Не'
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}
const interestsDetails = (row, field, { index, fetch, dispatch }) => {
  const typeMap = {
    invoice: 'Фактура',
    interest: 'Плащане по лихва',
    lastMonthInterest: 'Лихва предходен месец'
  }
  switch (field) {
    case 'type':
      return typeMap[row?.type]
    case 'date':
    case 'dateOfPayment':
    case 'invoiceDate':
      return row?.[field] ? moment(row?.[field]).format('DD.MM.YYYY') : '--'
    case 'invoiceNumber':
      return row?.[field] ? <div className="row">
        {row?.[field]}
        <div
          className="icon icon-arrow-right-2"
          onClick={() => dispatch(setOverlay({ isOpen: true, type: 'invoice', props: { invoice: row.invoice } }))}
        />
      </div> : '--'
    case 'interest':
      return <div style={{ maxWidth: '100%' }}>
        <Inputs.TextLabeledEdit
          value={row?.[field] ?? ''}
          onChange={(value) => {
            if (!/^-?\d+(?:\.\d{1,2})?$/.test(value) && value !== '') return
            dispatch(startLoading())
            dispatch(updateInterest({
              payload: {
                _id: row?.parentRow?._id,
                [`details.${index}.interest`]: Number(value),
                [`details.${index}.lastEdited`]: new Date(),
                amount: row?.parentRow?.details?.map((row, currentIndex) => currentIndex === index ? { ...row, interest: Number(value) } : row).reduce((acc, { interest = 0 }) => acc + interest, 0)
              },
              onSuccess: () => fetch()
            }))
          }}
          label="лв"
        />
      </div>
    case 'amount':
    case 'invoiceAmount':
      return row?.[field]?.toFixed(2) ?? "--"
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const commissionsReferencePayments = (row, field, { dispatch, fetch }) => {
  const monthYear = field.split(' - ')[0]

  if (['unpaid'].includes(field)) return [null, undefined].includes(row?.[field]) ? '--' : Number(row?.[field])?.toFixed(2)

  if (field.includes('status')) {
    if (!row?.[`${monthYear} - _id`]) return "Няма справка"
    return <div className="row" style={{ width: '100%', paddingRight: 14, justifyContent: 'space-between' }}>
      {commissionsStatuses[row?.[field]] ?? "Няма справка"}
      <div className="row row-buttons">
        {row?.[field] !== 'paid' && <div
          className="icon icon-dollar"
          style={{ marginLeft: 8, height: 20, width: 20 }}
          onClick={() => {
            dispatch(startLoading())
            dispatch(updateCommissionSimple({ payload: { _id: row[`${monthYear} - _id`], status: 'paid' }, onSuccess: () => fetch({ user: row?.user }) }))
          }}
        />}
        {!isEmpty(row?.[`${monthYear} - activity`]) && <div
          className="icon icon-history"
          style={{ marginLeft: 8, height: 20, width: 20 }}
          onClick={() => dispatch(setOverlay({ isOpen: true, type: 'history', props: { activity: row?.[`${monthYear} - activity`] } }))}
        />}
        <div
          style={{ marginLeft: 8, height: 20, width: 20 }}
          className="icon icon-edit"
          onClick={() => dispatch(setModal({
            isOpen: true,
            type: 'inputDropdown',
            props: {
              title: '',
              text: '',
              options: Object.entries(commissionsStatuses).map(([key, value]) => ({ value: key, label: value })),
              defaultValue: row?.[field],
              onSuccess: (status) => {
                dispatch(startLoading())
                dispatch(updateCommissionSimple({ payload: { _id: row[`${monthYear} - _id`], status }, onSuccess: () => { dispatch(setModal({ isOpen: false })); fetch({ user: row?.user }) } }))
              }
            }
          }))}
        />
      </div>
    </div>
  }

  if (field.includes('emailSent')) {
    if (!row?.[`${monthYear} - _id`]) return "--"
    return <div className="row" style={{ width: '100%', paddingRight: 14, justifyContent: 'space-between' }}>
      {row?.[`${monthYear} - emailSent`]
        ? moment(row?.[`${monthYear} - emailSent`]).format('HH:mm | DD.MM.YYYY')
        : <Button.Raised
          text="Изпратен"
          onClick={() => dispatch(setModal({
            isOpen: true, type: 'confirmation', props: {
              title: "Изпратен имейл",
              children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
              buttonText: "Потвърди",
              onClick: () => dispatch(updateCommissionSimple({ payload: { _id: row?.[`${monthYear} - _id`], emailSent: new Date() }, onSuccess: () => fetch({ user: row?.user }) }))
            }
          }))}
        />}
      <div className="row row-buttons">
        {(row?.[`${monthYear} - xlsxUrl`] && row?.[`${monthYear} - xlsxUrl`]?.includes('https')) && <a href={transformURL(row[`${monthYear} - xlsxUrl`] || '')} target="_blank" rel="noopener noreferrer" >
          <div style={{ marginLeft: 14, height: 20, width: 20 }} className="icon icon-money" />
        </a>}
      </div>
    </div>
  }

  if (field.includes('uploadedFileDate')) {
    if (!row?.[`${monthYear} - _id`]) return "--"

    return <div className="row" style={{ width: '100%', paddingRight: 14, justifyContent: 'space-between' }}>
      {row?.[`${monthYear} - uploadedFileDate`]
        ? moment(row?.[`${monthYear} - uploadedFileDate`]).format('HH:mm | DD.MM.YYYY')
        : <Button.UploadButton
          text="Качи файл"
          onChange={async ({ target: { files } }) => {
            dispatch(startLoading())
            const { location } = await uploadFile({ file: files[0], createId: true })
            dispatch(updateCommissionSimple({ path: '/uploadedFile', payload: { _id: row?.[`${monthYear} - _id`], uploadedFile: location, uploadedFileDate: new Date(), status: 'uploadedFile' }, onSuccess: () => fetch({ user: row?.user }) }))
          }}
          accept="*"
        />}
      {row?.[`${monthYear} - uploadedFile`] && row?.[`${monthYear} - uploadedFile`]?.includes('https') && <div className="row row-buttons">
        <a href={transformURL(row[`${monthYear} - uploadedFile`] || '')} target="_blank" rel="noopener noreferrer" >
          <div style={{ marginLeft: 14, height: 20, width: 20 }} className="icon icon-document" />
        </a>
        <div
          className="icon icon-delete"
          style={{ marginLeft: 14, height: 20, width: 20 }}
          onClick={() => dispatch(setModal({
            isOpen: true, type: 'confirmation', props: {
              title: 'Сигурни ли сте, че искате да премахнете файла?',
              onClick: () => dispatch(updateCommissionSimple({ payload: { _id: row?.[`${monthYear} - _id`], uploadedFile: null, uploadedFileDate: null, status: 'emailSent' }, onSuccess: () => fetch({ user: row?.user }) }))
            }
          }))}
        />
      </div>}
    </div>
  }

  return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
}

const communicationCorrections = (row, field) => {
  switch (field) {
    case "from":
      if (row?.fromRaw) return moment(row.fromRaw).format("DD.MM.YYYY | HH:mm")
    case "to":
      if (row?.toRaw) return moment(row.toRaw).format("DD.MM.YYYY | HH:mm")
      return row?.[field] ? moment(row?.[field]).format("DD.MM.YYYY | HH:mm") : ""
    case "appliedAt":
      return row?.[field] ? moment(row?.[field]).format("DD.MM.YYYY | HH:mm") : "Не"
    case "isFinishing":
      return row?.[field] ? 'Да' : 'Не'
    default:
      return renderCell.communicationFiles(row, field)
  }
}

const penalties = (row, field, { dispatch, fetch, forExport }) => {
  switch (field) {

    case 'fullName':
      if (forExport) return row?._client?.fullName ?? ''
    case "deliveryEnd":
      if (forExport) return row?._client?.contractSettings?.deliveryEnd ? moment(row?._client?.contractSettings?.deliveryEnd).format('DD.MM.YYYY') : ''
    case 'eic':
    case 'city':
    case "deliveryStart":
    case 'dealers':
    case 'email':
    case 'erp':
    case 'objects':
      return renderCell.depositsLeaving({ client: row._client }, field)
    case 'dateOfPayment':
      return row[field] ? moment(row[field]).format('DD.MM.YYYY') : '--'
    case 'penaltyValue':
      return row?.results?.penaltyValue + " лв."
    case "additionalCharges":
      if (forExport) return row?.additionalCharges ?? 'Не'
      return <Invoices.Cells.AdditionalCharges row={row} fetch={fetch} />
    case 'pdfFile':
      if (forExport) return row?.pdfFile && row?.pdfFile.includes('https') ? 'Да' : 'Не'
      return row?.pdfFile && row?.pdfFile.includes('https') ? (
        <div className="row row-pdfFile">
          Да
          <a href={transformURL(row?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" />
          </a>
        </div>
      ) : (
        'Не'
      )
    case 'deposit':
      if (forExport) return row?.deposit?.[0]?.pdfFile && row?.deposit?.[0]?.pdfFile.includes('https') ? 'Да' : 'Не'
      return row?.deposit?.[0]?.pdfFile && row?.deposit?.[0]?.pdfFile.includes('https') ? (
        <div className="row row-pdfFile">
          Да
          <a href={transformURL(row?.deposit?.[0]?.pdfFile || '')} target="_blank" rel="noopener noreferrer">
            <div className="icon icon-open" />
          </a>
        </div>
      ) : (
        'Не'
      )
    case 'penaltySent':
      if (forExport) return row?.penaltySent ? moment(row?.penaltySent).format('HH:mm | DD.MM.YYYY') : 'Не'
      return row?.penaltySent
        ? <div className="row row-penaltySent">
          {moment(row?.penaltySent).format('HH:mm | DD.MM.YYYY')}
        </div>
        : <div className="row row-penaltySent">
          Не
          <Button.Raised
            text="Изпратен"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Изпратен имейл",
                children: <p style={{ textAlign: 'center' }}>Използвай тази функционалност ако си изпратил писмото ръчно извън системата. Моля потвърди, че писмото е изпратено успешно извън системата</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(updatePenalty({ payload: { _id: row._id, penaltySent: new Date() }, onSuccess: fetch }))
              }
            }))
            }
          />
        </div>
    case 'deletedAt':
      if (forExport) return row?.deletedAt ? moment(row?.deletedAt).format('HH:mm | DD.MM.YYYY') : 'Не'
      return row?.deletedAt
        ? <div className="row row-deletedAt">
          {moment(row?.deletedAt).format('HH:mm | DD.MM.YYYY')}
        </div>
        : <div className="row row-deletedAt">
          Не
          <Button.Raised
            text="Анулирай"
            onClick={() => dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: "Анулиране",
                children: <p style={{ textAlign: 'center' }}>Моля потвърди, че искате да анулирате неустойката</p>,
                buttonText: "Потвърди",
                onClick: () => dispatch(updatePenalty({ payload: { _id: row._id, deletedAt: new Date() }, onSuccess: fetch }))
              }
            }))
            }
          />
        </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const employeesScreen = (row, field, options) => {
  const { toggleRowExpanded, isExpanded, handleOverlay, isMaster, currentUser } = options || {}
  switch (field) {
    case 'fullName':
      return <div className="row" style={{ width: '100%', paddingRight: 14 }} >
        <div className="icon icon-avatar" style={{ backgroundImage: `url(${row.coverPhoto || require('../assets/images/default-user-avatar.jpg')})` }} />
        {row.fullName}
      </div>
    case 'userGroups':
      if (!row?.userGroups?.length) return '--'
      return <div className="row" style={{ width: '100%', paddingRight: 14 }}>
        {row?.userGroups?.map(({ _id, name }) => <div key={_id} className='row' style={{ marginRight: 5 }}>
          <span>{name}</span>
          <div
            className="icon icon-info-custom"
            style={{ height: 20, width: 20, marginLeft: 5 }}
            onClick={() => store.dispatch(setOverlay({ isOpen: true, type: 'usersGroups', props: { _id } }))}
          />
        </div>)}
      </div>
    case 'daysOffCount':
      const daysOffCount = Object.entries(row.types).filter(([key]) => !['homeOffice'].includes(key)).reduce((acc, [, cur]) => acc + cur, 0)
      return <div className="row" style={{ width: '100%', justifyContent: 'space-between', paddingRight: 14 }}>
        <div className="row">
          <p>Общо: <span style={{ fontWeight: 700 }}>{daysOffCount}</span></p>
          {row.daysOff.length > 0 && <>
            <p style={{ marginLeft: 10 }}>П: <span style={{ fontWeight: 700 }}>{row.types?.paid ?? '0'}</span></p>
            <p style={{ marginLeft: 10 }}>Н: <span style={{ fontWeight: 700 }}>{row.types?.unpaid ?? '0'}</span></p>
            <p style={{ marginLeft: 10 }}>Б: <span style={{ fontWeight: 700 }}>{row.types?.sick ?? '0'}</span></p>
            <p style={{ marginLeft: 10 }}>Х: <span style={{ fontWeight: 700 }}>{row.types?.homeOffice ?? '0'}</span></p>
            <p style={{ marginLeft: 10 }}>Д: <span style={{ fontWeight: 700 }}>{row.types?.other ?? '0'}</span></p>
            <div
              style={{ marginLeft: 10 }}
              className={`icon icon-arrow-down ${isExpanded && 'rotate'}`}
              onClick={() => toggleRowExpanded(row?._id)}
            />
          </>}
        </div>
        {(isMaster || currentUser?._id === row._id) && <div
          className='icon icon-info-custom'
          onClick={() => handleOverlay(row)}
        />}
      </div>
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

const employeesDetails = (row, field, { fetch, isMaster, currentUser, length, toggleRowExpanded }) => {
  if (field === 'period') return <div className='row' style={{ width: '100%', justifyContent: 'space-between', padding: 14 }}>
    <span>{uniq([moment(row.from).format('DD.MM.YYYY'), moment(row.to).format('DD.MM.YYYY')]).join(' - ')}</span>
    {(isMaster || (currentUser?._id === row.user._id && moment().isBefore(moment(row.from), 'days'))) && <div className="row row-buttons">
      <div className='icon icon-edit' onClick={() => store.dispatch(setModal({
        isOpen: true,
        type: 'userDaysOff',
        props: { data: row, onClose: () => store.dispatch(setModal({ isOpen: false })), onSuccess: () => fetch() }
      }))} />
      <div className='icon icon-delete' style={{ marginLeft: 10 }} onClick={() => store.dispatch(setModal({
        isOpen: true,
        type: 'confirmation',
        props: {
          title: 'Сигурни ли сте, че искате да изтриете отсъствие?',
          onClick: () => { store.dispatch(startLoading()); store.dispatch(deleteUserDaysOff({ payload: { _id: row._id, }, onSuccess: () => { fetch(); if (length === 1) toggleRowExpanded(row.user._id) } })) }
        }
      }))} />
    </div>}
  </div>
  else if (field === 'type') {
    if (row.type === 'other') return row.description ?? 'Друго'
    else return userDaysOffOptions.find(({ value }) => value === row.type)?.label
  } else if (field === 'substitute') {
    if (!row.substitute) return '--'
    return <div className="row" style={{ width: '100%', paddingRight: 14 }} >
      <div className="icon icon-avatar" style={{ backgroundImage: `url(${row.substitute.coverPhoto || require('../assets/images/default-user-avatar.jpg')})` }} />
      {row.substitute.fullName}
    </div>
  }
}

const template = (row, field) => {
  switch (field) {
    default:
      return ['string', 'number'].includes(typeof row?.[field]) ? row?.[field] : '--'
  }
}

export const renderCell = {
  objects,
  objectsExport,
  invoices,
  notices,
  clients,
  clientsExport,
  clientsExportContacts,
  objectsDocumentsMissing,
  filePreview,
  references: {
    invoices: {
      generated: referenceInvoicesGenerated,
      'not-generated': clients,
      'not-generated-export': clientsExport,
      progress: referenceInvoicesProgress,
      ready: clients,
      readyExport: clientsExport,
      differences: filePreview,
      differencesExport: differencesExport,
    },
    employees,
    imports,
    nomenclatures,
    objectsIgnored,
    objectsIgnoredExport,
    clientsGroups,
    clientsGroupsDetails
  },
  invoiceSection,
  clientDocuments,
  clientInvoices,
  exportInvoicesGeneratedStats,
  annexChange,
  depositsLeaving,
  notifications: {
    clients: notificationsClients,
    notifications,
    notificationsExport
  },
  commissions,
  commissionsExport,
  notificationsGeneratedExport,
  commissionsReference: {
    generated: commissionsReferenceGeneral,
    created: commissionsReferenceCreated,
    createdExport: commissionsReferenceCreatedExport,
    payments: commissionsReferencePayments
  },
  commissionsReferenceDetails,
  commissionsReferenceCreatedDetails,
  compensationSettings,
  modalEmailStats,
  producersReferences,
  producersInvoices,
  producersInvoicesExport,
  interests,
  interestsDetails,
  producersReferencesExport,
  communicationFiles,
  communicationCorrections,
  penalties,
  employees: employeesScreen,
  employeesDetails,
  default: template
}
