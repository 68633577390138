import React, { useEffect, useState } from "react"
import { Button, Input } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import "./styles.scss"
import { Client } from "components"
import { useDispatch, useSelector } from "react-redux"
import { getData } from "actions"
import { IBANRegex, defaultContractSettings, defaultContractVersionProducers, defaultProducerSettings, productsPricesFields } from "config/constants"
import { checkIsDealer, isValidProducerSettings } from "utilities"

const UpdateClientTypeMixed = ({ close, client, onSubmit, title }) => {
    const isDealer = checkIsDealer()
    const { clientType } = client

    const [data, setData] = useState({
        ...client,
        ...(client.clientType === 'Консуматор' && { producerSettings: defaultProducerSettings }),
        ...(client.clientType === 'Производител' && { contractSettings: defaultContractSettings }),
    })
    const handleChange = (field, value) => setData({ ...data, [field]: value })
    const handlePriceChange = (payload) => handleChange('price', { ...data?.price, ...payload })

    const { products, versions } = useSelector(({ data }) => data)
    const dispatch = useDispatch()
    useEffect(() => {
        !products && dispatch(getData('products'))
        !versions && dispatch(getData('versions'))
    }, [])

    useEffect(() => {
        if (clientType !== 'Консуматор') return
        const updates = {}
        if (versions && data?.producerSettings?.version === undefined) updates.version = versions?.find(({ name }) => name === defaultContractVersionProducers)?._id
        if (isDealer && data?.producerSettings?.balancingMethod === undefined) updates.balancingMethod = 'Методика на СЕ'
        if (isDealer && data?.producerSettings?.terms === undefined) updates.terms = true
        if (isDealer && data?.producerSettings?.autoSign === undefined) updates.autoSign = true
        if (isDealer && data?.producerSettings?.autoSignMonths === undefined) updates.autoSignMonths = 12
        if (Object.keys(updates).length) setData({ ...data, producerSettings: { ...data.producerSettings, ...updates } })
    }, [versions, data])

    const [showRequired, setShowRequired] = useState(false)

    const isValidProducer = () => {
        if
            (!data?.invoiceSettings?.producerBank || !IBANRegex.test(data?.invoiceSettings?.producerIBAN)) {
            Alerts.error('Невалидна Банка/IBAN')
            return false
        }

        if (data?.producerSettings && !data?.producerSettings?.disabled) {
            const { isValid, error } = isValidProducerSettings({ data, products, isDealer })
            if (!isValid) {
                Alerts.error(error)
                return false
            }
        }
        return true
    }
    const isValidConsumer = () => {
        const requiredContractFields = ['version']
        const files = data?.contractSettings?.files?.map(({ type }) => type)
        if (
            !data?.product ||
            productsPricesFields
                .filter(({ types }) => types.includes(products?.find(({ _id }) => data?.product === _id)?.name))
                .map(({ value }) => value)
                .some((field) => [undefined, null, 0].includes(data?.price?.[field]))
        ) {
            Alerts.error('Моля попълнете продукт и всички цени')
            return false
        } else if (
            requiredContractFields.some((field) => !data?.contractSettings?.[field]) ||
            (!data?.contractSettings?.contractDurationMonths && !data?.contractSettings?.deliveryEnd) ||
            (data?.contractSettings?.autoSign && !data?.contractSettings?.autoSignMonths) ||
            (data?.contractSettings?.depositLeaving && !data?.contractSettings?.depositLeavingPercent) ||
            (data?.contractSettings?.penalty && !data?.contractSettings?.penaltyPercent) ||
            (data?.contractSettings?.notice && !data?.contractSettings?.noticeDays) ||
            (data?.contractSettings?.donation && !data?.contractSettings?.donationFond)
        ) {
            Alerts.error('Моля попълнете всички задължителни полета в документи и договор')
            return false
        } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && ['letter of attorney', 'contract'].some((val) => !files?.includes(val))) {
            Alerts.error('Моля добавете пълномощно и договор')
            return false
        } else if (process.env.REACT_APP_PLATFORM !== 'Proakt' && data?.contractSettings?.type === 'Комбиниран' && !files?.includes('declaration')) {
            Alerts.error('При комбиниран договор е необходимо да прикачите Декларация')
            return false
        }
        return true
    }

    const handleSubmit = () => {
        if (clientType === 'Консуматор') {
            if (!isValidProducer()) setShowRequired(true)
            else onSubmit(data)
        }
        else if (clientType === 'Производител') {
            if (!isValidConsumer()) setShowRequired(true)
            else onSubmit(data)
        }
    }

    return <div className="modal-update-clientType-mixed-container">
        <div className="modal-update-clientType-mixed-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-update-clientType-mixed-content">
            {clientType === 'Консуматор' && <>
                <Client.Forms.ProducerSettings
                    producerSettings={data?.producerSettings}
                    setProducerSettings={(newData) => handleChange('producerSettings', newData)}
                    handleChange={(field, value) => handleChange('producerSettings', { ...data?.producerSettings, [field]: value })}

                    versions={versions}
                    products={products}

                    showRequired={showRequired}
                    editable={true}

                    isClient
                    setNewFiles

                    showInvoiceSettings
                    invoiceSettings={data?.invoiceSettings}
                    handleChangeInvoiceSettings={(field, value) => handleChange('invoiceSettings', { ...data?.invoiceSettings, [field]: value })}
                />
            </>}
            {clientType === 'Производител' && <Client.Forms.DocumentsAndContract
                data={data}
                setData={(payload) => setData({ ...data, ...payload })}
                contractSettings={data?.contractSettings}
                setContractSettings={(newData) => handleChange('contractSettings', newData)}
                handleChange={(field, value) => handleChange('contractSettings', { ...data?.contractSettings, [field]: value })}

                versions={versions}
                products={products}
                handlePriceChange={handlePriceChange}

                showRequired={showRequired}
                editable={true}
                setNewFiles
            />}
        </div>
        <div className="modal-update-clientType-mixed-footer row">
            <Button.Raised text='Потвърди' onClick={handleSubmit} />
        </div>
    </div>
}

export default UpdateClientTypeMixed