import React from "react"
import DatePicker from "react-datepicker";
import "./styles.scss"
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = (props) => {
    return <div className="task-management-inputs-datepicker-container">
        <DatePicker {...props} />
        <div className="tasks-icon tasks-icon-calendar"></div>
    </div>
}

export default Datepicker