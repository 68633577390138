import React, { useCallback, useEffect, useState } from "react"
import "./styles.scss"
import { useDispatch } from "react-redux"
import { getReportsPortfolio, startLoading } from "actions"

const ReportsPortfolio = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState()
  const fetch = useCallback(() => { dispatch(startLoading()); dispatch(getReportsPortfolio({ payload: {}, onSuccess: () => { } })) }, [dispatch])
  useEffect(() => { fetch() }, [fetch])
  
  return <div className="references-reports-portfolio-container">ReportsPortfolio</div>
}

export default ReportsPortfolio