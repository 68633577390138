export const commissionsTypes = {
    SET_FIELDS: 'commissions/SET_FIELDS',

    GET_COMMISSION: 'commissions/GET_COMMISSION',

    GET_COMMISSIONS: 'commissions/GET_COMMISSIONS',
    GET_COMMISSIONS_SUCCESS: 'commissions/GET_COMMISSIONS_SUCCESS',

    GET_COMMISSIONS_REFERENCE: 'commissions/GET_COMMISSIONS_REFERENCE',

    GET_COMMISSIONS_REFERENCE_DETAILS: 'commissions/GET_COMMISSIONS_REFERENCE_DETAILS',
    GET_COMMISSIONS_REFERENCE_DETAILS_SUCCESS: 'commissions/GET_COMMISSIONS_REFERENCE_DETAILS_SUCCESS',

    GET_COMMISSIONS_REFERENCE_CREATED: 'commissions/GET_COMMISSIONS_REFERENCE_CREATED',
    GET_COMMISSIONS_REFERENCE_PAYMENTS: 'commissions/GET_COMMISSIONS_REFERENCE_PAYMENTS',

    GENERATE_COMMISSIONS: 'commissions/GENERATE_COMMISSIONS',
    GENERATE_COMMISSIONS_MANUALLY: 'commissions/GENERATE_COMMISSIONS_MANUALLY',

    FIND_COMMISSION_AGENT: "commissions/FIND_COMMISSION_AGENT",
    FIND_COMMISSION_CLIENT: "commissions/FIND_COMMISSION_CLIENT",
    CONFIRM_COMMISSIONS: "commissions/CONFIRM_COMMISSIONS",
    CREATE_COMMISSION: "commissions/CREATE_COMMISSION",
    EDIT_COMMISSIONS: "commissions/EDIT_COMMISSIONS",
    DELETE_COMMISSION: "commissions/DELETE_COMMISSION",

    SEND_COMMISSIONS: "commissions/SEND_COMMISSIONS",

    UPDATE_COMMISSION_SIMPLE: "commissions/UPDATE_COMMISSION_SIMPLE",
}

export const setCommissionsFields = (payload) => ({
    type: commissionsTypes.SET_FIELDS,
    payload,
})

export const getCommission = (payload) => ({
    type: commissionsTypes.GET_COMMISSION,
    ...payload,
})

export const getCommissions = (payload) => ({
    type: commissionsTypes.GET_COMMISSIONS,
    ...payload,
})

export const getCommissionsReference = (payload) => ({
    type: commissionsTypes.GET_COMMISSIONS_REFERENCE,
    ...payload,
})

export const getCommissionsReferenceDetails = (payload) => ({
    type: commissionsTypes.GET_COMMISSIONS_REFERENCE_DETAILS,
    ...payload,
})

export const generateCommissions = (payload) => ({
    type: commissionsTypes.GENERATE_COMMISSIONS,
    ...payload,
})
export const generateCommissionsManually = (payload) => ({
    type: commissionsTypes.GENERATE_COMMISSIONS_MANUALLY,
    ...payload,
})

export const findCommissionAgent = (payload) => ({
    type: commissionsTypes.FIND_COMMISSION_AGENT,
    ...payload,
})

export const findCommissionClient = (payload) => ({
    type: commissionsTypes.FIND_COMMISSION_CLIENT,
    ...payload,
})

export const confirmCommissions = (payload) => ({
    type: commissionsTypes.CONFIRM_COMMISSIONS,
    ...payload,
})

export const createCommission = (payload) => ({
    type: commissionsTypes.CREATE_COMMISSION,
    ...payload,
})

export const editCommissions = (payload) => ({
    type: commissionsTypes.EDIT_COMMISSIONS,
    ...payload,
})

export const updateCommissionSimple = (payload) => ({
    type: commissionsTypes.UPDATE_COMMISSION_SIMPLE,
    ...payload,
})

export const deleteCommission = (payload) => ({
    type: commissionsTypes.DELETE_COMMISSION,
    ...payload,
})

export const sendCommissions = (payload) => ({
    type: commissionsTypes.SEND_COMMISSIONS,
    ...payload,
})

export const getCommissionsCreatedPayments = (payload) => ({
    type: commissionsTypes.GET_COMMISSIONS_REFERENCE_PAYMENTS,
    ...payload
})

export const getCommissionsCreatedReference = (payload) => ({
    type: commissionsTypes.GET_COMMISSIONS_REFERENCE_CREATED,
    ...payload
})