import React from 'react'
import { Input, Button } from '@makedonski/admin-ui'
import { Inputs } from '../../'
import { times } from 'lodash'
import './styles.scss'

const fields = ['Общо', 'Средно', 'Макс', 'База', 'Пик', 'Офпик']

const ConsumptionCalculator = ({}) => {
  return (
    <div className="card-consumption-calculator-container">
      <h1>КАЛКУЛАЦИЯ</h1>
      <div className="card-consumption-calculator-header row">
        <h2>Визуализация на потреблението</h2>
        <Inputs.Dropdown />
      </div>
      <div className="card-consumption-calculator-graph"></div>
      <h2>Калкулатор</h2>
      <div className="card-consumption-calculator-table row">
        <div className="fields">
          <p className="placeholder">{'.'}</p>
          {fields.map((field) => (
            <div key={`field-${field}`} className="row">
              <p>{field}</p>
            </div>
          ))}
        </div>
        <div className="row row-data">
          {times(6).map((day) => (
            <div className="col">
              <p>0{day + 5}.2021</p>
              <div className="">
                <Inputs.Dropdown />
              </div>
              <div className="">
                <Input.Text />
              </div>
              <div className="">
                <Input.Text />
              </div>
              <div className="">
                <Inputs.TextLabeled label="лв./МВтч" />
              </div>
              <div className="">
                <Inputs.TextLabeled label="лв./МВтч" />
              </div>
              <div className="">
                <Inputs.TextLabeled label="лв./МВтч" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="card-consumption-calculator-total">
        <h2>Обща калкулация</h2>
        <div className="row ">
          {[
            'База',
            'Баланзиране',
            'Финансов разход',
            'Печалба',
            'Цена Клиент',
          ].map((col) => (
            <React.Fragment>
              <div className="col">
                <p>{col}</p>
                <Inputs.TextLabeled
                  label={col === 'База' ? 'МВтч' : 'лв./МВтч'}
                />
              </div>
              {col === 'Печалба' && <Button.Raised text="Калкулирай" />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ConsumptionCalculator
