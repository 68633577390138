import React, { useEffect, useState } from "react"
import moment from "moment"
import { Inputs } from "../"
import Checklist from './Checklist'
import Comments from './Comments'
import Activity from './Activity'
import Files from './Files'
import { emit, useQuery } from '../helpers'
import "./styles.scss"

const mapPriority = {
    'Нисък': '#00CB5E',
    'Среден': '#FFB612',
    'Висок': '#FA4444',
    'completed': '#8E8E8E'
}

const Task = ({ task, setTask, socket }) => {
    const { handleUrlChange } = useQuery()
    const { _id, priority, title, completedAt, description, assignedTo, dateStart, dateEnd, createdAt, author } = task || {}

    useEffect(() => () => setTask({}), [])

    const descriptionTabs = ['Описание', 'Чеклист']
    const [selectedDescriptionTab, setSelectedDescriptionTab] = useState(descriptionTabs[0])
    const renderDescriptionContent = {
        Описание: <div className="description-container" dangerouslySetInnerHTML={{ __html: description }} />,
        Чеклист: <Checklist task={task} socket={socket} />,
    }


    const detailsTabs = ['Коментари', 'Активност', 'Файлове']
    const [selectedDetailsTab, setSelectedDetailsTab] = useState(detailsTabs[0])
    const renderDetailsContent = {
        Коментари: <Comments task={task} socket={socket} disabled={completedAt}/>,
        Активност: <Activity task={task} />,
        Файлове: <Files task={task} socket={socket} disabled={completedAt}/>
    }

    return <div className="task-management-task-container">
        <div className="task-management-task-header row card"
            style={{ borderColor: mapPriority[completedAt ? 'completed' : priority] }}
        >
            <h2>{title}</h2>
            <div className="row row-buttons">
                {!completedAt && <div
                    className="tasks-icon tasks-icon-pen"
                    onClick={() => handleUrlChange({ showForm: 'task' })}
                />}
                <div
                    className={`raised-button-container ${completedAt && 'completed'}`}
                    onClick={() => emit(socket, { type: 'tasks/toggleStatus', payload: { taskId: _id } })}
                >
                    {completedAt ? "Отвори задачата" : "Завърши"}
                </div>
            </div>
        </div>
        <div className="task-management-task-content row">
            <div className="task-management-task-main card col">
                <div className="task-management-task-general-information card">
                    <h3>За задачата</h3>
                    <div className="row">
                        <p><span>Изпълнител: </span></p>
                        <div className="assignees-container row">
                            {assignedTo?.map(({ _id, coverPhoto, fullName }) => (
                                <div key={_id} className="single-assignee row">
                                    <div
                                        className="profile-image tasks-icon"
                                        style={{ backgroundImage: `url(${coverPhoto || require('../assets/default-user-avatar.jpg')})` }}
                                    />
                                    <p className="assignee-fullName">{fullName}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`row ${completedAt && 'completed card'}`}>
                        <p>Начало: <span>{moment(dateStart).format('DD.MM.YYYY')}</span></p>
                        <div className="dot" />
                        {completedAt
                            ? <p>Завършеа на: <span>{moment(completedAt).format('DD.MM.YYYY')}</span></p>
                            : <>
                                <p>Край:<span>{moment(dateEnd).format('DD.MM.YYYY')}</span></p>
                                <div className="dot" />
                                <p>Добавена на: <span>{moment(createdAt).format('DD.MM.YYYY')}</span></p>
                                <div className="dot" />
                                <p>Възложена от: <span>{author?.fullName}</span></p>
                            </>}
                    </div>
                </div>
                <div className="task-management-task-description card">
                    <Inputs.UnderlinedTab buttons={descriptionTabs} onChange={(tab) => setSelectedDescriptionTab(tab)} />
                    <div className="task-management-task-inner-description">{renderDescriptionContent[selectedDescriptionTab]}</div>
                </div>
            </div>
            <div className="task-management-task-details card">
                <Inputs.UnderlinedTab buttons={detailsTabs} onChange={(tab) => setSelectedDetailsTab(tab)} />
                <div className="task-management-task-inner-details">{renderDetailsContent[selectedDetailsTab]}</div>
            </div>
        </div>
    </div>
}

export default Task