// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-management-underlined-tab-container {
  position: relative;
}
.task-management-underlined-tab-container .underlined-tab {
  cursor: pointer;
  padding: 10px 16px;
  font-size: 14px;
}
.task-management-underlined-tab-container .active-underlined-selector-container {
  background-color: #efeeed;
  width: 100%;
  height: 5px;
}
.task-management-underlined-tab-container .active-underlined-selector {
  position: absolute;
  height: 0px;
  width: 150px;
  bottom: 0px;
  left: 0px;
  transition: all 0.3s ease-in-out;
  background-color: var(--main-color);
}
.task-management-underlined-tab-container .active-underlined-selector.selector-add-height {
  height: 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tasks/Inputs/UnderlinedTab/styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,eAAA;EACA,kBAAA;EACA,eAAA;AAER;AAAI;EACI,yBAAA;EACA,WAAA;EACA,WAAA;AAER;AAAI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,gCAAA;EACA,mCAAA;AAER;AADQ;EACI,WAAA;AAGZ","sourcesContent":[".task-management-underlined-tab-container {\r\n    position: relative;\r\n    .underlined-tab {\r\n        cursor: pointer;\r\n        padding: 10px 16px;\r\n        font-size: 14px;\r\n    }\r\n    .active-underlined-selector-container {\r\n        background-color: #efeeed;\r\n        width: 100%;\r\n        height: 5px;\r\n    }\r\n    .active-underlined-selector {\r\n        position: absolute;\r\n        height: 0px;\r\n        width: 150px;\r\n        bottom: 0px;\r\n        left: 0px;\r\n        transition: all 0.3s ease-in-out;\r\n        background-color: var(--main-color);\r\n        &.selector-add-height {\r\n            height: 5px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
