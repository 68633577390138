export const usersGroupsTypes = {
    GET_USERS_GROUPS: "usersGroups/GET_USERS_GROUPS",
    GET_USERS_GROUP: "usersGroups/GET_USERS_GROUP",
    CREATE_USERS_GROUP: "usersGroups/CREATE_USERS_GROUP",
    UPDATE_USERS_GROUP: "usersGroups/UPDATE_USERS_GROUP",
}

export const getUsersGroups = (payload) => ({
    type: usersGroupsTypes.GET_USERS_GROUPS,
    ...payload
})

export const getUsersGroup = (payload) => ({
    type: usersGroupsTypes.GET_USERS_GROUP,
    ...payload
})

export const createUsersGroup = (payload) => ({
    type: usersGroupsTypes.CREATE_USERS_GROUP,
    ...payload
})

export const updateUsersGroup = (payload) => ({
    type: usersGroupsTypes.UPDATE_USERS_GROUP,
    ...payload
})