// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.client-forms-intermediate-payments-container.row {
  align-items: flex-start;
}
.client-forms-intermediate-payments-container > div {
  flex: 1 1;
}
.client-forms-intermediate-payments-container .col-switch.disabled .single-radio-button {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/Client/Forms/IntermediatePayments/styles.scss"],"names":[],"mappings":"AACI;EACI,uBAAA;AAAR;AAEI;EACI,SAAA;AAAR;AAEI;EACI,eAAA;AAAR","sourcesContent":[".client-forms-intermediate-payments-container {\r\n    &.row {\r\n        align-items: flex-start;\r\n    }\r\n    > div {\r\n        flex: 1;\r\n    }\r\n    .col-switch.disabled .single-radio-button {\r\n        cursor: default;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
