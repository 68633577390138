import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { penaltyTypes, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { renderCell, xlsxExport } from 'utilities'
import { penaltiesFields } from 'config/constants'

export const getPenalties = (action$) => action$.pipe(
    ofType(penaltyTypes.GET_PENALTIES),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/penalties/browse`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                if (payload.forExport) xlsxExport({
                    fields: penaltiesFields,
                    data: response.results.docs,
                    render: (row, field) => renderCell.penalties(row, field, { forExport: true }),
                })
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const updatePenalty = (action$) => action$.pipe(
    ofType(penaltyTypes.UPDATE_PENALTY),
    switchMap(({ payload, onSuccess }) =>
        Api.put(`${URL}/penalties`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const sendPenalties = (action$) => action$.pipe(
    ofType(penaltyTypes.SEND_PENALTIES),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/penalties/send`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

export const calculatePenalty = (action$) => action$.pipe(
    ofType(penaltyTypes.CALCULATE_PENALTY),
    switchMap(({ payload, onSuccess }) =>
        Api.post(`${URL}/invoices/calculateClientPenalty`, JSON.stringify(payload)).pipe(
            switchMap(({ response }) => ActionsObservable.create((obs) => {
                if (onSuccess) onSuccess(response.data)
                obs.next(stopLoading())
                obs.complete()
            })),
            catchError((err) => ActionsObservable.create((obs) => {
                Alerts.error('Error!', err?.response?.error?.message || '')
                obs.next(stopLoading())
                obs.complete()
            }))
        )
    )
)

