import React, { useState } from "react"
import { Button } from '@makedonski/admin-ui'
import "./styles.scss"
import Inputs from "components/Inputs"
import moment from "moment"
import { Portal } from "react-overlays"

const CommunicationCorrections = ({ close, type, handleApply }) => {
    const [state, setState] = useState({ download: type === 'download' })
    const [changeMonth, setChangeMonth] = useState(false)
    const handleChange = (payload) => setState((state) => ({ ...state, ...payload }))


    return <div className="modal-communication-corrections-container">
        <div className="modal-communication-corrections-header row">
            <h2>Данни корекции - {type === 'download' ? 'Изтегли' : 'Приложи'}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-communication-corrections-content row">
            {type === 'download'
                ? <div className="col">
                    <span>Сглоби на база съществуващи данни</span>
                    <Button.Switch isOn={state.useExistingNetworkForDownload} onChange={() => handleChange({ useExistingNetworkForDownload: !state.useExistingNetworkForDownload })} />
                </div>
                : <>
                    <div className="col">
                        <span>Приложи за различен месец</span>
                        <Button.Switch isOn={changeMonth} onChange={() => { setChangeMonth(!changeMonth); handleChange({ monthYear: undefined }) }} />
                    </div>
                    {changeMonth && <div className="col">
                        <span>Месец</span>
                        <Inputs.DatePicker
                            value={state.monthYear ? moment(state.monthYear, 'MM/YYYY') : null}
                            onChange={(value) => handleChange({ monthYear: moment(value).format('MM/YYYY') })}
                            dateFormat={'MM.yyyy'}
                            showMonthYearPicker
                            showFullMonthYearPicker
                            popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                        />
                    </div>}
                </>}
        </div>
        <div className="modal-communication-corrections-footer row">
            <Button.Raised text={type === 'download' ? 'Изтегли' : 'Приложи'} disabled={type = 'apply' && changeMonth && !state.monthYear} onClick={() => { handleApply(state); close() }} />
        </div>
    </div>
}

export default CommunicationCorrections