export const annexTypes = {
  CREATE_ANNEX: 'annex/CREATE_ANNEX',
  UPDATE_ANNEX: 'annex/UPDATE_ANNEX',
  DELETE_ANNEX: 'annex/DELETE_ANNEX',
  GET_ANNEXES_BY_CLIENT: 'annex/GET_ANNEXES_BY_CLIENT',
  GET_ANNEXES_BY_CLIENT_SUCCESS: 'annex/GET_ANNEXES_BY_CLIENT_SUCCESS',

  CREATE_NOTIFICATIONS_ANNEXES: 'annex/CREATE_NOTIFICATIONS_ANNEXES',
  DELETE_NOTIFICATIONS_ANNEXES: 'annex/DELETE_NOTIFICATIONS_ANNEXES',
  GET_NOTIFICATIONS_CLIENTS: 'annex/GET_NOTIFICATIONS_CLIENTS',
  GET_NOTIFICATIONS_ANNEXES: 'annex/GET_NOTIFICATIONS_ANNEXES',
  GET_NOTIFICATIONS_ANNEXES_SUCCESS: 'annex/GET_NOTIFICATIONS_ANNEXES_SUCCESS',
  SEND_NOTIFICATIONS_ANNEXES: 'annex/SEND_NOTIFICATIONS_ANNEXES',
  GENERATE_NOTIFICATIONS_ANNEXES: 'annex/GENERATE_NOTIFICATIONS_ANNEXES',
  GET_TEXT_FOR_NOTIFICATIONS_ANNEXES: 'annex/GET_TEXT_FOR_NOTIFICATIONS_ANNEXES',

  CHECK_UPCOMMING_ANNEXES: 'annex/CHECK_UPCOMMING_ANNEXES'
}

export const createAnnex = (payload) => ({
  type: annexTypes.CREATE_ANNEX,
  ...payload,
})

export const updateAnnex = (payload) => ({
  type: annexTypes.UPDATE_ANNEX,
  ...payload,
})

export const deleteAnnex = (payload) => ({
  type: annexTypes.DELETE_ANNEX,
  ...payload,
})

export const getAnnexesByClient = (payload) => ({
  type: annexTypes.GET_ANNEXES_BY_CLIENT,
  ...payload,
})


export const createNotificationsAnnexes = (payload) => ({
  type: annexTypes.CREATE_NOTIFICATIONS_ANNEXES,
  ...payload,
})


export const deleteNotificationsAnnexes = (payload) => ({
  type: annexTypes.DELETE_NOTIFICATIONS_ANNEXES,
  ...payload,
})

export const getNotificationsClients = (payload) => ({
  type: annexTypes.GET_NOTIFICATIONS_CLIENTS,
  ...payload,
})

export const getNotificationsAnnexes = (payload) => ({
  type: annexTypes.GET_NOTIFICATIONS_ANNEXES,
  ...payload,
})

export const sendNotificationsAnnexes = (payload) => ({
  type: annexTypes.SEND_NOTIFICATIONS_ANNEXES,
  ...payload,
})

export const generateNotificationsAnnexes = (payload) => ({
  type: annexTypes.GENERATE_NOTIFICATIONS_ANNEXES,
  ...payload
})

export const getTextForNotificationsAnnexes = (payload) => ({
  type: annexTypes.GET_TEXT_FOR_NOTIFICATIONS_ANNEXES,
  ...payload
})

export const checkUpcomingAnnexes = (payload) => ({
  type: annexTypes.CHECK_UPCOMMING_ANNEXES,
  ...payload,
})
