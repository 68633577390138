import React from "react"
import { Input, Button } from '@makedonski/admin-ui'
import { Inputs } from "components"
import { mAdd, mMultiply, toNumber } from "utilities"
import "./styles.scss"

const ObjectForm = ({ invoice, handleChange, nomenclatures, showRequired, i }) => {
    const object = invoice?.objects?.at(i) || {}
    const { nameInInvoice, tech, results } = object || {}


    const handleArrayChange = (payload, array, n) => {
        const newArray = [...(object[array] || [])]
        newArray.splice(n, 1, { ...object[array].at(n), ...payload })
        const newObjects = [...(invoice?.objects || [])]
        newObjects.splice(i, 1, { ...invoice?.objects?.at(i), [array]: newArray })
        handleChange({ objects: newObjects })
    }

    const handleRemoveElement = (array, n) => {
        const newArray = [...(object[array] || [])]
        newArray.splice(n, 1)
        const newObjects = [...(invoice?.objects || [])]
        newObjects.splice(i, 1, { ...invoice?.objects?.at(i), [array]: newArray })
        handleChange({ objects: newObjects })
    }
    const handleAddElement = (array) => {
        const newArray = [...(object[array] || []), {}]
        const newObjects = [...(invoice?.objects || [])]
        newObjects.splice(i, 1, { ...invoice?.objects?.at(i), [array]: newArray })
        handleChange({ objects: newObjects })
    }


    return <div className="invoices-forms-object-container" style={{ zIndex: invoice?.objects?.length - i + 5 }}>
        <div className="invoices-forms-object-header row">
            <h2>Обект: </h2>
            <Input.Text value={nameInInvoice || ''} disabled />
        </div>
        <div className="invoices-forms-object-tech">
            <div className="invoices-forms-object-tech-header row">
                {["От", "До", "Скала", "Старо показание", "Ново показание", "Константа", "Количество"].map(label => <span key={`tech-label-${label}`}>{label}</span>)}
                <div className="placeholder"></div>
            </div>
            {tech?.map((t, n) =>
                <div key={`tech-${n}`} className="invoices-forms-object-single-tech row">
                    {['from', 'to', 'scale', 'oldEntry', 'newEntry', 'constant', 'totalQ'].map((value) =>
                        <div key={`tech-col-${value}`} className="col">
                            <Input.Text value={t?.[value] || ''} onChange={({ target }) => handleArrayChange({ [value]: target.value }, 'tech', n)} />
                        </div>
                    )}
                    {n === 0 ? <div className="icon icon-add" onClick={() => handleAddElement('tech')}></div> : <Button.Icon name="plus" color='red' onClick={() => handleRemoveElement('tech', n)} />}
                </div>
            )}
        </div>
        <div className="invoices-forms-object-results">
            <div className="invoices-forms-object-results-header row">
                {["Шифър", "Наименование", "М-ка", "Кол", "Ед. цена", "Стойност"].map(label => <span key={`results-label-${label}`}>{label}</span>)}
                <div className="placeholder"></div>
            </div>
            {results?.map((r, n) =>
                <div key={`result-${n}`} className="invoices-forms-object-single-results row">
                    <div className="col">
                        <Input.Text inputClassName={showRequired && !r?.cipher && 'required'} value={r?.cipher || ''} disabled />
                    </div>
                    <div className="col">
                        <Inputs.Dropdown
                            className={showRequired && !r?.name && 'required'}
                            options={nomenclatures
                                ?.filter(({ erp }) => !erp || erp._id === object.erp)
                                ?.map(({ code, nameInInvoice, price }) => ({ label: `${code} - ${nameInInvoice}`, value: code, nameInInvoice, price }))}
                            value={r?.cipher}
                            onChange={({ value: code, nameInInvoice, price: singleUnitPrice }) => handleArrayChange({
                                cipher: code,
                                name: nameInInvoice,
                                singleUnitPrice: singleUnitPrice || r?.singleUnitPrice,
                                value: ![undefined, null].includes(singleUnitPrice) ? mMultiply((singleUnitPrice || 0), (r?.quantity || 0)) : toNumber(r?.value)
                            }, 'results', n)}
                        />
                    </div>
                    <div className="col">
                        <Inputs.Dropdown
                            options={["MWh", "MW", "MW/d"].map(value => ({ value, label: value }))}
                            className={showRequired && !r?.unit && 'required'}
                            value={r?.unit || ''}
                            onChange={({ value: unit }) => handleArrayChange({ unit }, 'results', n)}
                        />
                    </div>
                    <div className="col">
                        <Input.Text
                            inputClassName={showRequired && (!r?.quantity || isNaN(Number(r?.quantity))) && 'required'}
                            value={r?.quantity ?? ''}
                            onChange={({ target: { value } }) => handleArrayChange({
                                quantity: value,
                                value: mMultiply((value || 0), (r.singleUnitPrice || 0))
                            }, 'results', n)}
                        />
                    </div>
                    <div className="col">
                        <Inputs.TextLabeled
                            inputClassName={showRequired && (!r?.singleUnitPrice || isNaN(Number(r?.singleUnitPrice))) && 'required'}
                            label='лв.'
                            value={r?.singleUnitPrice ?? ''}
                            onChange={({ target: { value } }) => handleArrayChange({
                                singleUnitPrice: value,
                                value: mMultiply((value || 0), (r.quantity || 0))
                            }, 'results', n)}
                        />
                    </div>
                    <div className="col">
                        <Inputs.TextLabeled
                            inputClassName={showRequired && (!r?.value || isNaN(Number(r?.value))) && 'required'}
                            label='лв.'
                            value={r?.value ?? ''}
                            disabled={r?.unit !== 'MW/d'}
                            onChange={({ target: { value } }) => handleArrayChange({ value }, 'results', n)}
                        />
                    </div>
                    {n === 0 ? <div className="icon icon-add" onClick={() => handleAddElement('results')}></div> : <Button.Icon name="plus" color='red' onClick={() => handleRemoveElement('results', n)} />}
                </div>
            )}
            <div className="row row-total">
                <p>Междинна стойност</p>
                <Inputs.TextLabeled label="лв." value={results?.reduce((acc, cur) => mAdd(acc, (cur.value || 0)), 0) ?? 0} disabled />
            </div>
        </div>

    </div>
}

export default ObjectForm

