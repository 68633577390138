import React, { useState } from "react"
import { Input, Button } from "@makedonski/admin-ui"
import "./styles.scss"
import { Inputs } from "components"
import { loadDropdownOptions } from "utilities"
import { useDispatch } from "react-redux"
import { createGroup, getReferenceClientsGroups, startLoading, updateGroup } from "actions"


const ClientsGroupsForm = ({ close, group, fetch }) => {
    const defaultValues = { name: '', clients: [] }
    const dispatch = useDispatch()

    const [data, setData] = useState(group ?? defaultValues)
    const handleChange = (payload) => setData((prev) => ({ ...prev, ...payload }))

    const onSuccess = () => { close(); dispatch(getReferenceClientsGroups()) }
    const handleCreate = () => { dispatch(startLoading()); dispatch(createGroup({ payload: data, onSuccess })) }
    const handleEdit = () => { dispatch(startLoading()); dispatch(updateGroup({ payload: data, onSuccess })) }

    return <div className="modal-clients-groups-form-container">
        <div className="modal-clients-groups-form-header row">
            <h2>{group ? 'Редакция' : 'Създаване'} на група клиенти</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-clients-groups-form-content">
            <span>Име група</span>
            <Input.Text value={data?.name ?? ''} onChange={({ target: { value } }) => handleChange({ name: value })} />
            <span>Клиенти</span>
            <Inputs.DropdownSelect
                async paginate cacheOptions defaultOptions isMulti
                loadOptions={(fullName, _, { page = 1 } = {}) => loadDropdownOptions({ path: '/clients/list', payload: { fullName, page, limit: 10 }, paginate: true })}
                rawValue={data?.clients ?? []}
                onChange={(value) => handleChange({ clients: value })}
                getOptionValue={({ _id }) => _id}
                getOptionLabel={({ fullName }) => fullName}
                closeMenuOnSelect={false}
                fixedHight={false}
                additional={{ page: 1 }}
            />
        </div>
        <div className="modal-clients-groups-form-footer row">
            <Button.Raised
                text={group ? 'Редактирай' : 'Създай'}
                onClick={group ? handleEdit : handleCreate}
                disabled={!data?.name || !data?.clients?.length}
            />
        </div>
    </div>
}

export default ClientsGroupsForm