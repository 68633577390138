import * as XLSX from 'xlsx'
import * as Async from 'awaity'
import { Auth, Headers, Alerts } from '@makedonski/socourt-utilities'
import { Button } from '@makedonski/admin-ui'
import moment from 'moment'
import { isEmpty, flatten, isObject, isDate } from 'lodash'

import { startLoading, setDataField, stopLoading, setModal, generateInvoice } from 'actions'
import { fileParser, renderCell } from 'utilities'

import { URL } from 'config/settings'
import { clientsStatus, allRegistrationStatuses } from 'config/constants'
import { store } from 'config/store'

export const scaleNumber = (num, in_min, in_max, out_min, out_max) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
}

export const combineDateAndTime = (date = new Date(), time = new Date()) => {
  const dateString = moment(date).format('DD.MM.YYYY')
  const timeString = moment(time).format('HH:mm')
  return moment(`${dateString} ${timeString}`, 'DD.MM.YYYY HH:mm').toDate()
}

export const xlsxExport = ({ fields, data, render = renderCell.default, fileName = 'export' }) => {
  const wb = XLSX.utils.book_new()
  const headers = fields.map(({ value }) => value)
  const formattedData = data.map((row) => headers.map((key) => render(row, key)?.toString()?.replace('--', '')))
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([fields.map(({ label }) => label), ...formattedData]))
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export const xlsxExportClientsWithObjects = ({ fields, data, render, fileName = 'export' }) => {
  const wb = XLSX.utils.book_new()
  const clientFields = fields.clientsFields.filter(({ value }) => !['comments', 'tasks'].includes(value))
  const objectFields = fields.objectsFields
  const formattedData = flatten(data.map((client) => client.objects.map(object => ({ ...object, client }))))
    .map((row) => ({
      objects: objectFields.map(({ value }) => value).map((key) => {
        const a = render.objects(row, key, { client: row?.client })
        return a?.toString()?.replace('--', '')
      }), client: row.client
    }))
    .map(row => [...row.objects, ...clientFields.map(({ value }) => value).map((key) => render.clients(row.client, key)?.toString()?.replace('--', ''))])

  const ws = XLSX.utils.aoa_to_sheet([[...objectFields, ...clientFields].map(({ label }) => label), ...formattedData])
  XLSX.utils.book_append_sheet(wb, ws)
  XLSX.writeFile(wb, `${fileName}.xlsx`)
}

export const handleUploadedData = async ({ files, dispatch, history, type, additionalProps, transformParsed = (data) => data }) => {
  dispatch(startLoading())
  try {
    const parsed = (
      await Async.reduce([...files], async (files, file) => [...files, await parseSingleFile(file, type)], [])
    ).filter((file) => !isEmpty(file))
    if (!isEmpty(parsed)) {
      dispatch(setModal({ isOpen: false }))
      dispatch(setDataField({ field: 'parsed', value: { type, files: transformParsed(parsed), ...additionalProps } }))
      history.push(`/file/preview`)
    }
  } catch (e) {
    console.error(e)
    Alerts.error('Грешка при обработването на файловете!')
  } finally {
    dispatch(stopLoading())
  }
}

export const parseSingleFile = async (file, type) =>
  new Promise((resolve) => {
    fileParser[type](file, async (parsedData, rawData) => {
      if (isEmpty(parsedData)) {
        Alerts.error('Грешка!', `Неправилно форматиран файл ${file.name}`)
        return resolve(null)
      } else return resolve({ fileName: file.name, parsedData, rawData })
    })
  })

export const getClientStatus = (client, type) => {
  let statuses
  if (!client?.objects?.length) return 'Няма добавени точки'
  switch (type) {
    case 'active':
      statuses = [type]
      if (client?.objects?.some(({ status }) => ['leaving'].includes(status))) statuses.push('leaving')
      if (client?.objects?.some(({ status }) => allRegistrationStatuses.includes(status))) statuses.push('registration')
      break
    case 'register':
      statuses = [...new Set(client.objects.map(({ status }) => status).filter(s => ['preregistration', 'registration'].includes(s)))].reverse()
      if (client?.objects?.some(({ isClone, status }) => !isClone && status.includes('documents-'))) statuses = [...new Set([...statuses, 'registration'])]
      if (client?.objects?.some(({ isClone }) => isClone)) statuses = [...new Set([...statuses, 'transfer'])]
      if (client?.objects?.some(({ status }) => ['leaving', 'active'].includes(status))) statuses.push('active')
      break
    case 'potential':
    case 'inactive':
      statuses = [type]
      break
    default:
      statuses = [...new Set(client?.objects?.map(({ status }) => status))]
  }
  return statuses?.map((status) => clientsStatus?.find(({ value }) => value === status)?.label)?.join(', ')
}

export const handleSendInvoice = async ({ selected, showNotPaid, onSuccess, dispatch }) => {
  dispatch(startLoading())
  try {
    // const chunks = chunk(selected, 100)
    // const sent = await Async.reduce([...chunks], async (sent, invoice) => [...sent, await handleSendSingleInvoice(invoice)], [])
    const sent = await handleSendSingleInvoice(selected, showNotPaid)
    if (onSuccess) onSuccess(sent)
  } catch (e) {
    console.error(e)
    Alerts.error('Грешка при изпращането на фактурите!')
  } finally {
    dispatch(stopLoading())
  }
}

const handleSendSingleInvoice = async (ids, showNotPaid) =>
  new Promise(async (resolve, reject) => {
    const url = `${URL}/invoices/send`
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(),
        body: JSON.stringify({ ids, showNotPaid }),
      })
      const { error, invoice } = await response.json()
      if (error) throw new Error()
      else return resolve(invoice)
    } catch (e) {
      console.error(e)
      return reject()
    }
  })

export const genericFetch = async (url, options) => new Promise((resolve, reject) => fetch(`${URL}${url}`, { method: 'POST', headers: Auth.isAuthenticated ? Headers.getWithAuth() : Headers.get(), ...options }).then((res) => res.json()).then(resolve).catch(reject))

export const addBusinessDays = (originalDate, numDaysToAdd) => {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.clone();

  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate;
}

export const getTextForConfirmationModal = (arr) => <div className="col" style={{ alignItems: 'center', textAlign: 'center' }}>
  {arr.map(({ text, inner }, i) => <p key={`row-${i}`}>{text} <span style={{ fontWeight: '700' }}>{inner}</span> </p>)}
</div>

export const getStatusConfirmationModal = ({ toActive, toBeActive, toPreregistration, toBePreregistration, fetch }) => store.dispatch(setModal({
  isOpen: true,
  type: 'confirmation',
  props: {
    title: 'Одобрение пристигащи',
    children: getTextForConfirmationModal([
      { text: 'Към активни:', inner: `${toActive?.length} точки / ${[...new Set(toActive?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
      { text: 'Изчакващи към активни:', inner: `${toBeActive?.length} точки / ${[...new Set(toBeActive?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
      { text: 'Превходиране:', inner: `${toPreregistration?.length} точки / ${[...new Set(toPreregistration?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
      { text: 'Изчакващи към превходиране:', inner: `${toBePreregistration?.length} точки / ${[...new Set(toBePreregistration?.filter(Boolean)?.map(({ client }) => client)) || []].length} клиента` },
    ]),
    onClick: () => fetch({ pageOne: true }),
    onRequestClose: () => fetch({ pageOne: true }),
  }
}))

export const exportCustomsExcel = ({
  household,
  householdBase,
  householdQuantity,
  householdSum,
  nonHousehold,
  nonHouseholdBase,
  nonHouseholdQuantity,
  nonHouseholdSum
}) => {
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([
    [`Справка към ЕАДД № 0000001595 Предназначение на стоката по код 47 доставка на електроенергия на крайни клиенти`],
    Object.keys(nonHousehold?.at(0) || {}),
    ...nonHousehold.map(row => Object.values(row)),
    ["", "", "", "", "Общо", nonHouseholdBase, nonHouseholdQuantity, nonHouseholdSum],
    [],
    [`Справка към ЕАДД № 0000001596 Предназначение на стоката по код 47 доставка на електроенергия на крайни клиенти`],
    Object.keys(household?.at(0) || {}),
    ...household.map(row => Object.values(row)),
    ["", "", "", "", "Общо", householdBase, householdQuantity, householdSum],
    [], [],
    ["", "", "", "", "Общо", householdBase + nonHouseholdBase, householdQuantity + nonHouseholdQuantity, householdSum + nonHouseholdSum],
  ]))
  XLSX.writeFile(wb, `export.xlsx`)
}

export const formatAnnexResponse = ({ changes, ...annex }) => ({
  ...annex,
  changes: Object.entries(changes || {}).reduce((acc, [key, value]) => {
    if (key.includes('invoiceSettings_')) return { ...acc, invoiceSettings: { ...acc?.invoiceSettings, [key.split('_').at(-1)]: value } }
    else if (key.includes('contractSettings_')) return { ...acc, contractSettings: { ...acc?.contractSettings, [key.split('_').at(-1)]: value } }
    else if (key.includes('producerSettings_')) return { ...acc, producerSettings: { ...acc?.producerSettings, [key.split('_').at(-1)]: value } }
    // else if (key.includes('annexNotificationOldData_')) return { ...acc, contractSettings: { ...acc?.contractSettings, [key.split('_').at(-1)]: value } }
    else return { ...acc, [key]: value }
  }, {})
})

export const generateInvoiceInitial = ({ ids, from, to, onSuccess }) => {
  const dispatch = store.dispatch
  dispatch(setModal({
    isOpen: true, type: 'generateInvoices', props: {
      onConfirm: (helperDate) => {
        dispatch(startLoading())
        dispatch(generateInvoice({
          payload: { ids, from, to, helperDate },
          onSuccess: ({ errors }) => {
            if (!errors.length) { onSuccess(); Alerts.success({ title: "Успешно генериране!" }) }
            else dispatch(setModal({
              isOpen: true, type: 'confirmation', props: {
                title: 'Грешки при генерирането!',
                children: <div style={{ maxHeight: 1000, overflow: 'auto' }}>
                  {errors?.map(({ error, stack }, i) => <div key={i} style={{ margin: '10px 0' }}>
                    <div className="row" style={{ justifyContent: 'space-between', paddingRight: 10 }}>
                      <span style={{ fontWeight: 500 }}>{error}</span>
                      <div className="icon icon-eye" onClick={() => {
                        const el = document.getElementById(`error-${i}`)
                        if (el.style.display === 'none') el.style.display = 'block'
                        else el.style.display = 'none'
                      }} />
                    </div>
                    <div style={{ display: 'none', fontWeight: 300 }} id={`error-${i}`}>{stack}</div>
                  </div>)}
                </div>,
                buttons: <div className="row">
                  <div className="icon icon-export" onClick={() => xlsxExport({
                    fields: [{ label: 'Грешка', value: 'error' }, { label: '', value: 'stack' },],
                    data: errors,
                    render: (row, field) => row?.[field]
                  })} />
                  <div style={{ width: 20, height: 20 }}></div>
                  <Button.Raised text="Продължи" onClick={() => { onSuccess(); dispatch(setModal({ isOpen: false })) }} />
                </div>,
                onRequestClose: onSuccess
              }
            }))
          },
        }))
      }
    }
  }))
}

export const checkIsDealer = () => {
  const { currentUser } = store.getState().general
  const roles = currentUser?.roles?.map(({ name }) => name) ?? []
  return roles.length === 1 && roles.includes('dealer')
}

export const fileRequest = async ({ URL: path, payload = {}, fileName = 'export.xlsx', onSuccess = () => { } }) => {
  const { dispatch } = store
  try {
    dispatch(startLoading())
    const response = await fetch(`${URL}${path}`, {
      method: 'POST',
      headers: Headers.getWithAuth(),
      body: JSON.stringify({ ...payload }),
    })
    if (!response.ok) throw new Error('Грешка')
    const blob = await response.blob()
    const uril = window.URL.createObjectURL(blob)
    let link = document.createElement("a");
    link.href = uril;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onSuccess()
  }
  catch (error) { Alerts.error(error?.message) }
  finally { dispatch(stopLoading()) }
}

export const loadDropdownOptions = ({ method = 'POST', path, payload, paginate, transform } = {}) => new Promise((resolve, reject) => {
  window.fetch(`${URL}${path}`, { method, headers: Headers.getWithAuth(), ...(method !== 'GET' && { body: JSON.stringify({ ...payload }), }) })
    .then((res) => res.json())
    .then((res) => {
      if (transform) resolve(transform(res))
      else if (paginate) resolve({ options: res?.payload?.docs ?? [], hasMore: res?.payload?.hasNextPage, additional: { page: res?.payload?.nextPage } })
      else resolve(res?.payload?.docs ?? [])
    })
    .catch((error) => reject(error))
})

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export const transformURL = (url = '') => url.split('+').join('%2B')

export const deepCopyObject = (obj = {}) => JSON.parse(JSON.stringify(obj))

export const cleanObject = (o, keepEmptyString = false) => {
  const object = deepCopyObject(o)
  Object.entries(object).forEach(([k, v]) => {
    if (isObject(v)) cleanObject(v);
    if ((isObject(v) && !isDate(v) && !Object.keys(v).length) || v === null || v === undefined || (!keepEmptyString && v === '')) {
      if (Array.isArray(object)) object.splice(k, 1);
      else delete object[k];
    }
  });
  return object;
}