import React, { useEffect, useState } from "react"
import { Button } from '@makedonski/admin-ui'
import { Alerts } from '@makedonski/socourt-utilities'
import "./styles.scss"
import { useQuery } from "hooks"
import { useDispatch } from "react-redux"
import { handleProducersTemplatedInvoice, producersCheckClientInvoices, startLoading } from "actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const ProducersTemplatedInvoice = ({ }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { _id, client: clientId } = useQuery()

    const [error, setError] = useState(!_id || !clientId ? 'Невалиден линк' : '')
    const [data, setData] = useState({})
    const { stat: { templatedInvoice, client } = {}, template } = data
    const clientInZDDS = client?.bulstat?.startsWith('BG')

    useEffect(() => {
        if (!_id || !clientId) return
        dispatch(startLoading())
        dispatch(handleProducersTemplatedInvoice({ payload: { _id, client: clientId }, onSuccess: setData, onError: setError }))
    }, [_id, clientId])


    const isValid = ({ type, invoiceNumber, agreement, agreement_2 }) => {
        if (!type) Alerts.error('Моля изберете тъп на документа!')
        else if (!invoiceNumber) Alerts.error('Моля въведете номер на фактура!')
        else if (type === 'ФАКТУРА' && invoiceNumber?.length !== 10) Alerts.error('Номер на фактура трябва да е 10 символа!')
        else if (type === 'ПРОТОКОЛ' && invoiceNumber?.length > 10) Alerts.error('Номер на протокол трябва да е до 10 символа!')
        else if (!agreement) Alerts.error('Моля потвърдете данните!')
        else if (clientInZDDS && !agreement_2) Alerts.error('Моля потвърдете данните!')
        else return true
    }

    const handleSubmit = () => {
        const payload = Object.fromEntries(new FormData(document.getElementById('form')))
        if (data.type) payload.type = data.type
        if (!isValid(payload)) return
        dispatch(startLoading())
        dispatch(producersCheckClientInvoices({
            payload: { client: clientId, invoiceNumber: payload.invoiceNumber, isClient: true },
            onSuccess: () => dispatch(handleProducersTemplatedInvoice({
                payload: { _id, client: clientId, templatedInvoice: payload }, onSuccess: (response) => {
                    setData(response)
                    Alerts.success({ title: 'Успешно изпратена фактура!', text: 'Ще получите копие по имейла.' })
                }
            }))
        }))
    }

    if (templatedInvoice?.pdfFile && templatedInvoice?.confirmed !== 'Системна') history.push(`/producers-upload?_id=${_id}&client=${clientId}`)

    if (error) return <div className="row" style={{ height: '100vh', width: '100vw', padding: '0 10vw', justifyContent: 'center', fontWeight: 300, fontSize: 30, textTransform: 'uppercase', textAlign: 'center' }}>{error}</div>


    return <div className="screen-producers-templated-invoice-container col">
        <form id='form' className="screen-producers-templated-invoice-content">
            {templatedInvoice && <div className="success-text">
                <p className="title">Вашата фактура е генерирана успешно.</p>
                <p>Ако желаете да коригирате фактурата, да я замените или имате нужда от съдействие, моля свържете се с нас{process.env.REACT_APP_PLATFORM === 'Synergon' ? ' на vei.finance@synergonenergy.bg' : ''}.</p>
            </div >}
            <div className="screen-producers-templated-invoice" dangerouslySetInnerHTML={{ __html: template }} />
            
            {!templatedInvoice && <>
                <div className="instructions">
                    <p>Инструкция: <br /><br /><span>Ако   желаете   да   използвате   възможността   да   генерирате   фактура   през   нашата
                        система,   моля {!data.type ? 'изберете   тип   на   документа   -   "Протокол/Фактура",   както   и   ' : ''} попълнете
                        номер на документа в оказаното поле, в съответствие с Вашата счетоводна поредност.
                        Моля   уверете   се,   че   данните   на   Доставчик,   както   и   посочената   банкова   сметка,   на
                        която следва да получите плащане от наша страна, са коректни.<br /><br />
                        След като попълните примерната фактура, използвайте бутон „Изпрати“. С
                        натискането му, фактурата ще бъде изпратена до нас и до Вас.</span></p>
                </div>
                <div className="row row-agreement">
                    <input type="checkbox" id="agreement" name="agreement" />
                    <label htmlFor="agreement">Потвърждавам, че данните на Доставчик,
                        както и посочената банкова сметка, на която следва да получа плащане от Ваша
                        страна, са коректни</label>
                </div>
                {clientInZDDS && <div className="row row-agreement">
                    <input type="checkbox" id="agreement_2" name="agreement_2" />
                    <label htmlFor="agreement_2">Потвърждавам, че дружеството "{client?.fullName}" е регистрирано по ДДС</label>
                </div>}
            </>}
            {!templatedInvoice && <Button.Raised text='Изпрати' onClick={handleSubmit} />}
        </form>
    </div>
}

export default ProducersTemplatedInvoice