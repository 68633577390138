// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.task-management-inputs-datepicker-container {
  position: relative;
}
.task-management-inputs-datepicker-container .react-datepicker-wrapper {
  width: 100%;
}
.task-management-inputs-datepicker-container .react-datepicker-wrapper input {
  border: 1px solid rgba(0, 52, 112, 0.3);
  display: block;
  width: 100%;
  border-radius: 5px;
  padding: 9px 11px;
  margin: 10px 0px;
}
.task-management-inputs-datepicker-container .react-datepicker-wrapper input:active, .task-management-inputs-datepicker-container .react-datepicker-wrapper input:focus {
  outline: none;
}
.task-management-inputs-datepicker-container .tasks-icon-calendar {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  height: 18px;
  width: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tasks/Inputs/Datepicker/styles.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,uCAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;AAER;AADQ;EAEI,aAAA;AAEZ;AACI;EACI,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;EACA,YAAA;EACA,WAAA;AACR","sourcesContent":[".task-management-inputs-datepicker-container {\r\n    position: relative;\r\n    .react-datepicker-wrapper {\r\n        width: 100%;\r\n    }\r\n    .react-datepicker-wrapper input {\r\n        border: 1px solid rgba(0, 52, 112, 0.3);\r\n        display: block;\r\n        width: 100%;\r\n        border-radius: 5px;\r\n        padding: 9px 11px;\r\n        margin: 10px 0px;\r\n        &:active,\r\n        &:focus {\r\n            outline: none;\r\n        }\r\n    }\r\n    .tasks-icon-calendar {\r\n        position: absolute;\r\n        top: 50%;\r\n        right: 10px;\r\n        transform: translateY(-50%);\r\n        height: 18px;\r\n        width: 18px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
