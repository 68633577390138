import React, { useState } from "react"
import { Inputs } from "components"
import { Button } from '@makedonski/admin-ui'
import "./styles.scss"
import { Portal } from "react-overlays"
import moment from "moment"

const InputDate = ({ close, title = "", text = "", defaultValue = "", onSuccess = () => { }, startOfDay, startOfMonth }) => {
    const [state, setState] = useState(defaultValue)
    return <div className="modal-input-date-container">
        <div className="modal-input-date-header row">
            <h2>{title}</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-input-date-content">
            {text && <p style={{ textAlign: 'center' }}>{text}</p>}
            <Inputs.DatePicker
                value={state}
                onChange={setState}
                popperContainer={({ children }) => <Portal container={document.getElementById('calendar-portal')}>{children}</Portal>}
                {...{
                    ...(startOfDay && {
                        onChange: (value) => setState(moment(value).startOf('day').toDate()),
                    })
                }}
                {...{
                    ...(startOfMonth && {
                        onChange: (value) => setState(moment(value).startOf('month').toDate()),
                        dateFormat: 'MMMM yyyy',
                        showMonthYearPicker: true,
                        showFullMonthYearPicker: true,
                        className: "month-picker"
                    })
                }}
            />
        </div>
        <div className="modal-input-date-footer row">
            <Button.Raised onClick={() => onSuccess(state, close)} text="Продължи" disabled={!state} />
        </div>
    </div>
}

export default InputDate