import { ofType, ActionsObservable } from 'redux-observable'
import { switchMap, catchError } from 'rxjs/operators'
import { Api, Alerts } from '@makedonski/socourt-utilities'
import { exportsTypes, setModal, stopLoading } from '../actions'
import { URL } from '../config/settings'
import { exportCustomsExcel, renderCell, xlsxExport } from 'utilities'
import { omit } from 'lodash'
import moment from 'moment'

export const exportAjur = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/generate`, JSON.stringify(payload)).pipe(
      switchMap(({ response: { RDET, RTOT, cancelled } }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = []
        if (RDET) files.push({ name: 'RDET.eid', contents: RDET })
        if (RTOT) files.push({ name: 'RTOT.eid', contents: RTOT })
        if (cancelled) files.push({ name: 'Cancelled.txt', contents: cancelled })
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurAlternative = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_ALTERNATIVE),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/generateAlternative`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = Object.entries(omit(response, 'success')).map(([key, value]) => ({ name: `${key}.txt`, contents: value }))
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurAlternativeProducers = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_ALTERNATIVE_PRODUCERS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/generateProducerStatsAlt`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = Object.entries(omit(response, 'success')).map(([key, value]) => ({ name: `${key}.txt`, contents: value }))
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurConvertor = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_CONVERTOR),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/epayToAlternative`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = Object.entries(omit(response, 'success')).map(([key, value]) => ({ name: `${key}.txt`, contents: value }))
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurNumbers = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_NUMBERS),
  switchMap(({ payload: { forExport, ...payload }, onSuccess }) =>
    Api.post(`${URL}/ajur/downloadMissing`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.missing)
        if (forExport && response?.missing?.length) {
          xlsxExport({
            fields: [
              { label: '_id', value: '_id' },
              { label: 'Име клиент', value: 'fullName' },
              { label: 'ЕИК/ЕГН', value: 'eic' },
              { label: 'ИН по ЗДДС', value: 'bulstat' },
              { label: 'Ажур', value: 'ajur' }
            ],
            data: response?.missing,
            render: (row, field, options) => {
              if (field === 'eic') return row?.pin ?? row?.eic ?? ''
              else return renderCell.default(row, field, options)
            }
          })
        }
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurProducersNumbers = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_PRODUCERS_NUMBERS),
  switchMap(({ payload: { forExport, ...payload }, onSuccess }) =>
    Api.post(`${URL}/ajur/downloadMissingProducers`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response.missing)
        if (forExport && response?.missing?.length) {
          xlsxExport({
            fields: [
              { label: '_id', value: '_id' },
              { label: 'Име клиент', value: 'fullName' },
              { label: 'ЕИК/ЕГН', value: 'eic' },
              { label: 'ИН по ЗДДС', value: 'bulstat' },
              { label: 'Ажур', value: 'ajur' }
            ],
            data: response?.missing,
            render: (row, field, options) => {
              if (field === 'eic') return row?.pin ?? row?.eic ?? ''
              else return renderCell.default(row, field, options)
            }
          })
        }
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurProducersTemplated = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_PRODUCERS_TEMPLATED),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/generateProducerStats`, JSON.stringify(payload)).pipe(
      switchMap(({ response: { SDET, STOT } }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = []
        if (SDET) files.push({ name: `SDET${moment().format('_DD_MM_YYYY')}.isd`, contents: SDET })
        if (STOT) files.push({ name: `STOT${moment().format('_DD_MM_YYYY')}.isd`, contents: STOT })
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportAjurCompensations = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_AJUR_COMPENSATIONS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/ajur/generateCompensations`, JSON.stringify(payload)).pipe(
      switchMap(({ response: { RDET, RTOT, cancelled } }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()

        const files = []
        if (RDET) files.push({ name: 'RDET.eid', contents: RDET })
        if (RTOT) files.push({ name: 'RTOT.eid', contents: RTOT })
        if (cancelled) files.push({ name: 'Cancelled.txt', contents: cancelled })
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportCustoms = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_CUSTOMS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/customs/generate`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()
        exportCustomsExcel(response)
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportEasypay = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_EASYPAY),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/invoices/getForEPay`, JSON.stringify(payload)).pipe(
      switchMap(({ response: { file } }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess()


        const files = []
        if (file) files.push({ name: 'easypay.csv', contents: file })
        obs.next(setModal({ isOpen: true, type: 'downloadFiles', props: { files } }))

        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)

export const exportEmailsErrors = (action$) => action$.pipe(
  ofType(exportsTypes.EXPORT_EMAILS_ERRORS),
  switchMap(({ payload, onSuccess }) =>
    Api.post(`${URL}/exports/emailsErrors`, JSON.stringify(payload)).pipe(
      switchMap(({ response }) => ActionsObservable.create((obs) => {
        if (onSuccess) onSuccess(response?.errors)
        try {
          if (response?.errors?.length) {
            xlsxExport({
              fields: ['_id', 'from', 'fromName', 'to', 'replyTo', 'cc', 'bcc', 'subject', 'processed', 'mailerResult'].map((value) => ({ value, label: value })),
              data: response?.errors,
              render: renderCell.default
            })
          } else Alerts.success({ title: 'Няма грешки!' })
        } catch (error) {
          console.error(error)
        }
        obs.next(stopLoading())
        obs.complete()
      })),
      catchError((err) => ActionsObservable.create((obs) => {
        Alerts.error('Error!', err?.response?.error?.message || '')
        obs.next(stopLoading())
        obs.complete()
      }))
    )
  )
)
