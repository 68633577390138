import React from "react"
import iconv from 'iconv-lite'
import { saveAs } from 'file-saver'
import "./styles.scss"

const DownloadFiles = ({ close, files = [] }) => {
    return <div className="modal-download-files-container">
        <div className="modal-download-files-header row">
            <h2>Файлове за изтегляне</h2>
            <div className="icon icon-close" onClick={close} />
        </div>
        <div className="modal-download-files-content col">
            {files.length ? files.map(({ name, contents, encode = 'win1251', type = 'text/plain;charset=windows-1251' }, index) => <div
                key={`file-${index}`}
                className="row"
                onClick={() => saveAs(new Blob([iconv.encode(contents, encode)], { type }), name)}
            >
                <p>{name}</p>
                <div className="icon icon-open" />
            </div>)
                : <p className="empty-state">Няма налични файлове за сваляне!</p>}
        </div>
    </div>
}

export default DownloadFiles