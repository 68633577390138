import { Button } from '@makedonski/admin-ui'
import { Alerts, Headers } from "@makedonski/socourt-utilities"
import { createNotificationsAnnexes, getData, getNotificationsClients, setClientsFields, startLoading, stopLoading } from "actions"
import * as Async from 'awaity'
import { Inputs, Shared } from "components"
import { notificationsClientsTableFields, notificationsTypesFields } from "config/constants"
import { useQuery } from "hooks"
import { chunk, differenceBy, isEmpty, mapKeys, mapValues, omit, pick } from "lodash"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fileParser, mSubtract, renderCell, xlsxExport } from "utilities"
import { URL } from "config/settings"

const NotificationsClients = ({ }) => {
  const dispatch = useDispatch()
  const { type, deliveryEnd: deliveryEndProp, handleUrlChangeMultiple } = useQuery()
  const deliveryEnd = useMemo(() => deliveryEndProp || moment().add(2, 'months').endOf('month').toDate(), [deliveryEndProp])

  const { clients, hasNextPage, nextPage, clientsCount, showCheckboxes, selectedCheckboxes } = useSelector(({ clients }) => clients)

  const { products } = useSelector(({ data }) => data)
  useEffect(() => { !products && dispatch(getData('products')) }, [])

  const [changes, setChanges] = useState({})

  const fetchClients = () => {
    dispatch(startLoading())
    dispatch(getNotificationsClients({ payload: { page: nextPage, deliveryEnd } }))
  }

  useEffect(() => { fetchClients() }, [deliveryEnd])

  const handleGenerate = () => {
    const missingProductErrors = [], missingPriceErrors = []
    if (selectedCheckboxes.some((client) => ['product', 'price'].every(f => !changes?.[client]?.[f]))) Alerts.error("Моля въведете информация за всички избрани клиенти")
    else {
      const payload = Object.entries(changes)
        .filter(([key]) => selectedCheckboxes.includes(key))
        .map(([key, value]) => {
          const currentClient = clients.find(({ _id }) => _id === key)
          const activationDate = value.activationDate
            ? moment(value.activationDate, 'DD.MM.YYYY').toDate()
            : moment(currentClient.contractSettings.deliveryEnd).add(1, 'days').startOf('month').toDate()

          const difference = value?.product && value?.product !== currentClient.product._id ? ""
            : currentClient.product?.name === 'Една тарифа' && value?.price?.base ? mSubtract(value?.price?.base, currentClient.price.base)
              : ['Борса', 'Отделно балансиране'].includes(currentClient.product?.name) && value?.price?.market ? mSubtract(value?.price?.market, currentClient.price.market) : ""
          const oldProduct = currentClient?.product?._id
          if (!oldProduct) missingProductErrors.push(currentClient?.pin || currentClient?.eic)
          const oldPrice = currentClient?.price
          if (!oldPrice) missingPriceErrors.push(currentClient?.pin || currentClient?.eic)
          const result = {
            client: key,
            activationDate,
            changes: {
              ...pick(value, ['product', 'price']),
              deliveryStart: activationDate,
              deliveryEnd: moment(activationDate).add((value.autoSignMonths - 1) || 11, 'months').endOf('month').toDate(),
              difference,
              oldProduct,
              oldPrice,
              oldDeliveryStart: currentClient?.contractSettings?.deliveryStart,
              oldDeliveryEnd: currentClient?.contractSettings?.deliveryEnd,
              period: value.autoSignMonths
            },
          }
          if ([0, undefined].some(v => v === value.autoSignMonths)) result.changes = { ...omit(result.changes, ['deliveryStart', 'deliveryEnd', 'period']) }
          return result
        })
      if (!isEmpty(missingProductErrors)) Alerts.error("Липсва избран продукт", `ЕИК/ЕГН: ${missingProductErrors.join(',\n')}`)
      else if (!isEmpty(missingPriceErrors)) Alerts.error("Липсва цена", `ЕИК/ЕГН: ${missingPriceErrors.join(',\n')}`)
      else {
        dispatch(startLoading())
        dispatch(createNotificationsAnnexes({
          payload,
          onSuccess: () => {
            setChanges({})
            dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
          }
        }))
      }
    }
  }

  const handleExport = () => {
    dispatch(startLoading())
    dispatch(getNotificationsClients({
      payload: { deliveryEnd, pagination: false, },
      onSuccess: ({ docs }) => {
        try {
          xlsxExport({
            fields: notificationsClientsTableFields.filter(({ label }) => !['Разлика', 'Статус уведомление', 'Статус'].includes(label)),
            data: docs,
            render: renderCell.notifications.notificationsExport
          })
        } catch (error) {
          console.error(error);
        }
      }
    }))
  }

  const handleImport = async ({ target: { files } }) => {
    try {
      dispatch(startLoading())
      const editFields = ['Нов Продукт', 'Нова цена', 'Борса', 'Нощна']
      const { products } = await fetch(`${URL}/data/products`).then(res => res.json())
      const file = [...files].at(0)
      const parsed = await new Promise((res) => fileParser.createObjects(file, (data) => res(data)))
      const editedRows = parsed.filter((c) => editFields.some(f => c[f]))
      const clientErrors = []

      const chunks = chunk(editedRows, 200)
      const editedClients = await Async.reduce([...chunks], async (data, chunk) => {
        return [...data, ...(await Promise.all(chunk.map(row => new Promise(async (resolve) => {
          let client = clients.find(({ eic, pin }) => [eic, pin].some(f => f === row['ЕИК/ЕГН'].toString()))
          if (!client) client = (await fetch(`${URL}/annex/notifications/ready`, {
            method: 'POST',
            headers: Headers.getWithAuth(),
            body: JSON.stringify({ deliveryEnd, eic: row['ЕИК/ЕГН'], exactMatch: true })
          }).then(res => res.json())).clients.docs.at(0)
          if (!client) clientErrors.push(row['ЕИК/ЕГН'])
          resolve(client || { notFound: true })
        }))))]
      }, [])

      if (editedClients.some(({ notFound }) => notFound)) {
        Alerts.error("Невалиден клиент", `ЕИК/ЕГН: ${clientErrors.join(',\n')}`)
        dispatch(stopLoading())
        return
      }
      const missingClient = differenceBy(editedClients, clients, '_id')
      const allClients = [...missingClient, ...clients]
      const productErrors = [], pricingErrors = [], otherErrors = []
      const newChanges = editedRows
        .reduce((acc, r) => {
          const temp = allClients.find(({ eic, pin }) => [eic, pin].some(f => f === r['ЕИК/ЕГН'].toString()))
          if (!temp) otherErrors.push(r['ЕИК/ЕГН'])

          let { _id, product: currentProduct } = temp || {}
          const product = products.find(({ name }) => name === r['Нов Продукт'])

          if (!!r['Нов Продукт'] && !product) productErrors.push(r['ЕИК/ЕГН'])
          if (r['Нов Продукт'] === 'Една тарифа' && (r['Борса'] || !r['Нова цена'])) pricingErrors.push(r['ЕИК/ЕГН'])
          if (!r['Нов Продукт'] && currentProduct?.name === 'Една тарифа' && r['Борса']) pricingErrors.push(r['ЕИК/ЕГН'])
          if (r['Нов Продукт'] === 'Борса' && (r['Нова цена'] || !r['Борса'])) pricingErrors.push(r['ЕИК/ЕГН'])
          if (!r['Нов Продукт'] && currentProduct?.name === 'Борса' && r['Нова цена']) pricingErrors.push(r['ЕИК/ЕГН'])

          const price = mapValues(mapKeys(pick(r, ['Нова цена', 'Борса', 'Нощна']), (value, key) => notificationsClientsTableFields.find(({ label }) => label === key).value))
          const autoSignMonths = r?.['Нов срок']
          const activationDate = r?.['Влиза в сила от / DD.MM.YYYY']
          return ({ ...acc, [_id]: { product: product?._id, price, autoSignMonths, activationDate } })
        }, {})

      if (!isEmpty(productErrors)) Alerts.error("Невалиден продукт", `ЕИК/ЕГН: ${productErrors.join(', ')}`)
      else if (!isEmpty(pricingErrors)) Alerts.error("Невалидени цени", `ЕИК/ЕГН: ${pricingErrors.join(', ')}`)
      else if (!isEmpty(otherErrors)) Alerts.error("Грешка", `ЕИК/ЕГН: ${otherErrors.join(', ')}`)
      else {
        setChanges(() => newChanges)
        dispatch(setClientsFields({
          showCheckboxes: { action: 'notifications' },
          selectedCheckboxes: editedClients.map(({ _id }) => _id),
          clients: allClients.sort((a, { _id }) => editedClients.map(({ _id }) => _id).includes(_id) ? 1 : -1)
        }))
      }
      dispatch(stopLoading())
    } catch (error) {
      console.error(error);
    }
  }

  return <div className="screen-notifications-container">
    <div className="screen-notifications-inner-container">
      <div className="screen-notifications-header row">
        <Inputs.RoundedButtons
          buttons={notificationsTypesFields}
          value={type}
          onChange={({ value }) => {
            dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))
            handleUrlChangeMultiple({ type: value, deliveryEnd: undefined })
          }}
        />
        <div className="row row-buttons">
          <span>Kрайна дата:</span>
          <Inputs.DatePicker
            value={deliveryEnd}
            onChange={(value) => handleUrlChangeMultiple({ deliveryEnd: moment(value).endOf('month').toDate() })}
            dateFormat={'MMMM yyyy'}
            showMonthYearPicker
            showFullMonthYearPicker
            className="month-picker"
          />
          {showCheckboxes ? <>
            <Button.Raised text='Откажи' className="btn-cancel" onClick={() => dispatch(setClientsFields({ showCheckboxes: false, selectedCheckboxes: [] }))} />
            {showCheckboxes.action === 'notifications' && <Button.Raised text='Генерирай' onClick={handleGenerate} disabled={isEmpty(changes) || isEmpty(selectedCheckboxes)} />}
          </> : <>
            <div className="icon icon-export" onClick={handleExport} />
            <Button.UploadButton text='Импорт' accept={'.xlsx, .xls'} onChange={handleImport} />
          </>
          }
        </div>
      </div>
      <div className="screen-notifications-content">
        <Shared.Table
          columns={notificationsClientsTableFields.filter(({ value }) => {
            if (showCheckboxes?.action !== 'notifications') return !['newProduct', 'base', 'market', 'night', 'period', 'difference', 'activationDate', 'status',].includes(value)
            return !["status"].includes(value)
          })}
          data={clients}
          renderCell={(row, value, options) => renderCell.notifications?.clients?.(row, value, {
            ...options, changes: changes?.[row?._id],
            setChanges: (value) => setChanges({ ...changes, [row?._id]: { ...changes?.[row?._id], ...value } }),
            products
          })}
          handlePagination={() => hasNextPage && fetchClients()}
          useCheckboxes={!!showCheckboxes}
          selectedCheckboxes={selectedCheckboxes}
          onCheckboxChange={(value) => dispatch(setClientsFields({ selectedCheckboxes: value }))}
        />
      </div>
      <div className="screen-notifications-footer row">
        <div className="screen-notifications-inner-footer row">
          <p className="row">Общо клиенти: <span> {clientsCount ?? <span className="inline-loader"></span>}</span></p>
          {showCheckboxes && <p className="row">Избрани: <span> {selectedCheckboxes.length}</span>
          </p>}
        </div>
      </div>
    </div>
  </div>
}

export default NotificationsClients