import React, { useEffect } from "react"
import { Inputs } from 'components'
import "./styles.scss"
import { mAdd, mDivide, mMultiply, mSubtract } from "utilities"

const Intermediate = ({ invoice, handleChange, showRequired }) => {
  const { results } = invoice || {}
  const { energy, energyPrice, tzoPrice, excisePrice, delivery, percentLastMonth, invoiceLastMonthTotalWithoutVat, energyPricePercent } = results || {}

  useEffect(() => {
    if (percentLastMonth) {
      const totalWithoutVat = mMultiply(invoiceLastMonthTotalWithoutVat, mDivide(energyPricePercent, 100))
      const total = mMultiply(totalWithoutVat, 1.2)
      const vat = mSubtract(total, totalWithoutVat)
      handleChange({ results: { ...results, invoiceLastMonthTotalWithoutVat, energyPricePercent, totalWithoutVat, total }, pricing: { base: totalWithoutVat, vat, withVat: total } })
    }
    else {
      const energyTotal = mMultiply(energy, energyPrice)
      const totalWithoutVat = mAdd(mMultiply(mAdd(energyPrice, mAdd(tzoPrice, excisePrice)), energy), delivery)
      const total = mMultiply(totalWithoutVat, 1.2)
      const vat = mSubtract(total, totalWithoutVat)
      handleChange({ results: { ...results, energyTotal, total, totalWithoutVat, }, pricing: { base: totalWithoutVat, vat, withVat: total } })
    }
  }, [energy, energyPrice, tzoPrice, excisePrice, delivery, percentLastMonth, invoiceLastMonthTotalWithoutVat, energyPricePercent])

  if (percentLastMonth) return <div className="invoices-forms-intermediate-container">
    <div className="invoices-forms-intermediate-header row">
      <h2>Междинно плащане за ел.енергия: </h2>
    </div>
    <div className="invoices-forms-intermediate-result">
      <div className="invoices-forms-intermediate-result-header row">
        {['Предходен Месец', 'Цена', 'Сума без ДДС', 'Крайна сума'].map(label => (
          <span key={`results-label-${label}`}>{label}</span>
        ))}
      </div>
      <div className="invoices-forms-intermediate-result-content row">
        {["invoiceLastMonthTotalWithoutVat", "energyPricePercent", "totalWithoutVat", "total"].map(value => (
          <div key={`result-col-${value}`} className={`col ${value}`}>
            <Inputs.TextLabeled
              label={['invoiceLastMonthTotalWithoutVat'].includes(value) ? "МВтч" : ['energyPricePercent'].includes(value) ? "%" : "лв."}
              value={results?.[value] ?? ''}
              onChange={({ target }) => handleChange({ results: { ...results, [value]: target.value } })}
              inputClassName={showRequired && value !== 'energyTotal' && (!results?.[value] || isNaN(Number(results?.[value]))) && 'required'}
              disabled={!['energyPricePercent'].includes(value)}
            />
          </div>
        ))}
      </div>
    </div>
  </div>

  return <div className="invoices-forms-intermediate-container">
    <div className="invoices-forms-intermediate-header row">
      <h2>Междинно плащане за ел.енергия: </h2>
    </div>
    <div className="invoices-forms-intermediate-result">
      <div className="invoices-forms-intermediate-result-header row">
        {['К-во енергия', 'Цена', 'Стойност енергия', 'ТЗО', 'Акциз', 'Пренос'].map(label => (
          <span key={`results-label-${label}`}>{label}</span>
        ))}
      </div>
      <div className="invoices-forms-intermediate-result-content row">
        {["energy", "energyPrice", "energyTotal", "tzoPrice", "excisePrice", "delivery"].map(value => (
          <div key={`result-col-${value}`} className={`col ${value}`}>
            <Inputs.TextLabeled
              label={['energy'].includes(value) ? "МВтч" : "лв."}
              value={results?.[value] ?? ''}
              onChange={({ target }) => handleChange({ results: { ...results, [value]: target.value } })}
              inputClassName={showRequired && value !== 'energyTotal' && (!results?.[value] || isNaN(Number(results?.[value]))) && 'required'}
              disabled={['energyTotal'].includes(value)}
            />
          </div>
        ))}
      </div>
    </div>
  </div>
}

export default Intermediate
